enum RoutesEnum {
  //Calendar Presets
  CALENDAR_PRESETS = "global/calendar-presets",

  //Users
  USER_DETAILS = "user/details",

  //User Management
  USER_CREATE_OR_UPDATE = "user-management/create-user",
  USER_AUTH0_DETAIL = "user-management/user-auth0-details",
  USER_RESEND_INVITE = "user-management/user-resend-invite",
  USER_LIST = "user-management/user-list",
  USER_DELETE = "user-management/delete",

  //Commissions
  BROKER_TAG_DETAILS = "commissions/broker-tag-details",
  BROKER_TAGS_COMMISSION_UPDATE = "commissions/update-broker-tag-commissions",

  //Reports dashboard
  DASHBOARD_CATEGORY_LIST = "reports/category",
  ADD_REMOVE_FAVORITES = "reports/add-remove-favorite",

  //Filter Options
  BROKER_TAGS_BY_REPORT_ID = "reports/broker-tags-by-report-id",
  BUYER_LIST = "reports/buyer-details",
  SHIPPER_LIST = "reports/shipper-details",
  EVENT_LIST = "reports/event-details",
  VENUE_LIST = "reports/venue-details",
  DATE_RANGE = "reports/date-ranges",
  CARD_LIST = "reports/card-details",
  ALERT_LIST = "reports/alert-details",
  CURRENCY_LIST = "reports/currency-details",
  REPORT_LIST = "reports/report-details",
  RECIPIENT_LIST = "reports/recipient-details",
  SALE_CATEGORY_LIST = "reports/sale-category-details",
  CUSTOMER_CLASSIFICATION_LIST = "reports/customer-classification-details",
  STATE_LIST = "reports/all-states",
  INVENTORY_CANCELLATION_TYPE_LIST = "reports/all-inventory-cancellation-types",
  SEAT_INTELLIGENCE_DETAILS = "reports/seat-intelligence-details",
  BROKER_USER_LIST = "reports/broker-list",
  BUYER_USER_LIST = "reports/buyer-list",
  LISTER_USER_LIST = "reports/lister-list",
  SEAT_MAP_CREATOR_USER_LIST = "reports/seat-map-creator-list",
  CHECKER_USER_LIST = "reports/checker-list",
  VENUE_DATE_PRESETS = 'reports/venues-date-presets',
  // LISTER_USER_BY_BROKER = "reports/lister-list-by-broker",
  BROKER_TEAM_BY_BROKER = "reports/broker-team-by-broker-id",
  AGING_PERIODS_LIST = "reports/get-all-aging-periods",
  FULFILLMENT_STATUSES_LIST = "reports/get-all-fulfillment-statuses",
  SHOW_CATEGORIES = "filters/show-categories",
  SCRAPING_STATUS_DETAILS = "reports/scraping-status-details",
  VENUE_SCRAPING_TYPES = "filters/venue-scraping-type",

  // Profitability Reports
  GROSS_PROFIT = "reports/gross-profit",
  GROSS_PROFIT_CANCELLED = "reports/gross-profit-cancelled",
  PROFIT_DASHBOARD_SCORE = "reports/profit-dashboard/score",
  PROFIT_DASHBOARD_BROKER_SUMMARY = "reports/profit-dashboard/broker-summary",
  PROFIT_DASHBOARD_GROSSPROFIT_GRAPH = "reports/profit-dashboard/grossprofit-graphdata",
  PROFIT_DASHBOARD_BROKER_COMMISSION_GRAPH = "reports/profit-dashboard/brokercommission-graphdata",
  PROFIT_DASHBOARD_GROSSPROFIT_PERIOD_BREAKUP = "reports/profit-dashboard/grossprofit-period-breakup",
  PROFIT_DASHBOARD_GROSSPROFIT_BROKER_COMMISSION_PERIOD_BREAKUP = "reports/profit-dashboard/grossprofit-broker-commission-period-breakup",
  PROFIT_DASHBOARD_TOP_EVENTS = "reports/profit-dashboard/profit-dashboard-top-events",
  BROKER_TAG_ISSUES = "reports/broker-tags-issues",
  CONSIGNMENT_SALES = "reports/consignment-sales",
  SHORTS_PENDING = "reports/orders-pending-delivery",
  CONSIGNMENT_PENDING_BUY_IN = "reports/consignment-pending-buy-in",
  INVOICES_NEEDING_ATTENTION = "reports/invoices-needing-attention",
  RECEIVABLES_DETAILS = "reports/gross-profit/receivables-wholesale-consignments",
  RECEIVABLES_RETAIL = "reports/gross-profit/receivables-retail",
  RECEIVABLES_DETAILS_OLD = "reports/gross-profit/receivables-wholesale-consignments-old",
  RECEIVABLES_RETAIL_OLD = "reports/gross-profit/receivables-retail-old",
  ACTIVE_ZONE_LISTING = "reports/active-zone-listing",
  OTHER_COSTS_AND_CREDITS = "reports/gross-profit/other-costs-and-credits",

  SOLD_INVENTORY_ISSUE_RESOLUTION = "reports/save_sold_inventory_resolve_issue",

  LONG_INVENTORY = "reports/inventory/long",
  LONG_INVENTORY_NOT_LISTED = "reports/inventory/long/not-listed",
  LONG_INVENTORY_SCORECARD = "reports/scorecard",
  SKYBOX_USER_LIST = "reports/skybox-user-details",
  SKYBOX_VENDORS = "reports/skybox-vendors",
  ROLE_LIST = "reports/role-details",
  SPLIT_TYPE_LIST = "reports/split-type-details",
  BUYER_TAG_LIST = "reports/buyer-tag-details",
  LISTER_TAG_LIST = "reports/lister-tag-details",
  TICKETBASH_BROKER_LIST = "reports/ticketbash-broker-details",
  PRICING_RESOLUTIONS = "/reports/resolve-pricing-master",
  SAVE_PRICING_RESOLUTIONS = "/reports/save_long_rpt_resolve_pricing_issue",

  //Schedule
  CREATE_SCHEDULE = "scheduler/create",
  GET_SCHEDULE_LIST = "scheduler/schedule-list",
  GET_SCHEDULE_BY_ID = "scheduler/schedule-by-id",
  GET_SCHEDULE_RECIPIENTS = "scheduler/recipients",
  TOGGLE_SCHEDULE_STATUS = "scheduler/toggle-status",
  DELETE_SCHEDULE = "scheduler/delete-schedule",
  FORCE_RUN_SCHEDULE = "scheduler/force-run",

  
  LOG = "log",

  //Venue
  VENUE_DETAILS = "venue/details",
  VENUE_ADD_REMOVE_FAVORITES = "venue/add-remove-favorites",
  VENUE_EDIT = "venue/edit",
  VENUE_BULK_ASSIGN_BROKER = "venue/bulk-assign-venues",
  VENUE_BUYER_LIST = "venue/buyer-list/{venue_id}",

  //Event
  EVENT_DETAILS = "event/details",

  //Event Metadata
  EVENT_METADATA = "event/metadata",
  EVENT_METADATA_AUDIT_HISTORY = "event/metadata-audit-history",
  EVENT_METADATA_TREND_DATA = "event/zone-trend",
  EVENT_METADATA_SAVE = "event/metadata",
  EVENT_LIST_FOR_COPY_DOWN_METADATA = "event/get-events-for-copy-down-metadata/{event_id}",
  EVENT_SAVE_COPY_DOWN_METADATA = "event/save-copy-down-metadata/{event_id}",
  SEAT_MAP_TBD_DROPDOWN_DATA = "/event/seat-map-tbd-dropdown-data",
  EVENT_REFRESH_MVW = "event/refresh-mvw-for-upcoming-events",
  SPLIT_METADATA = "event/save-split-metadata",

  //Event Create New Zone Listing
  EVENT_METADATA_FOR_NEW_ZONE_LISTING = "event/metadata-for-create-zone-listing",

  //Housekeeping
  ACTIVE_INVENTORY = "inventory/active",
  SOLD_INVENTORY = "inventory/sold",
  SOLD_INVENTORY_LIST_ITEM = "inventory/sold-item",
  UNFILLED_ZONES = "inventory/unfilled-zones",
  UNFILLED_ZONE_LIST_ITEM = "inventory/unfilled-zone-item/",
  CANCEL_INVENTORY = "cancel-inventory",
  CANCEL_INVOICE = "cancel-invoice",
  CANCELLED_INVENTORY = "inventory/cancelled-inventory",
  VENUES_WITH_UNMAPPED_EVENT = "event-mapping/venues",
  EVENTS_FOR_MAPPING = "event-mapping/get-events-for-mapping",
  UPDATE_SCRAPING_EVENTS = "event-mapping/map-events",

  //Zones
  ZONE_DETAILS_BY_ID = "zones/zone-details-for-zone-listing",
  ZONE_CREATE_NEW_LISTING = "zones/create-listing/{event_id}",
  ZONE_REFRESH_MVW_FOR_PURCHASE = "zones/purchase/refresh-mvw-for-purchase",
  ZONE_LISTING_DETAILS_BY_EVENT = "/zones/zone-listing-details-by-event",
  ZONE_ACTIVE_LISTINGS_BY_EVENT = "/zones/active-listings-by-event",
  ZONE_FORCE_UPDATE_PURCHASE = "zones/purchase/force-update/{purchase_id}",
  ZONE_FORCE_UPDATE_REFRESHED_MVW_CONFIG = "/zones/update-configuration-master-refreshing-mvw-off",
  // All Zone Listings
  ZONE_ALL_ZONE_LISTINGS = "/zones/events-with-active-zone-listings",
  HIGH_RISK_LISTINGS= "/zones/get-high-risk-listings",
  ZONE_HIGH_RISK_LISTINGS = "/zones/events-with-high-risk-listings",
  // Pending Fills
  ZONE_PENDING_ZONE_FILLS = "/zones/get-pending-zone-fills-listings",
  ZONE_PAYMENT_STATUS_LIST = "/zones/get-all-payment-statuses",
  ZONE_ACTIVE_LISTINGS_UPDATE_BROADCAST_STATUS = "/zones/active-listings-update-broadcast-status/{inventory_id}",
  ZONE_INVENTORY_FORCE_SYNCED = "/zones/zone-inventory/force-synced",
  ZONE_GET_EVENTS_CREATE_LISTING_BULK_PLACEMENT = "/zones/get-events-create-listing-bulk-placement",
  ZONE_ACTIVE_LISTING_CANCEL = "/zones/active-listing-cancel/purchases/{purchase_id}/lines/{line_id}",
  ZONE_PENDING_ZONE_FILLS_PURCHASE_ASSIGNMENT = "/zones/pending-fills-zone-assignment",
  ZONE_ACTIVE_LISTINGS_SPLIT_INVENTORY = "/zones/zone-inventory/split-inventory/{inventory_id}/{numberOfTickets}",
  ZONE_PURCHASE_LINE_FORCE_SYNCED = "/zones/purchase/line/force-update/{purchase_line_id}",
  ZONE_PENDING_ZONE_FILLS_SAVE_PURCHASE_ASSIGNMENT = "/zones/save-purchase-assignments",
  // Split Inventory Data
  ZONE_GET_SPLIT_INVENTORY_DATA = "/zones/get-split-inventory-data",
  // Edit Listing
  ZONE_LISTING_UPDATE_SECTION_ROW_PRICE = "/zones/edit-listing-section-row-price",
  ZONE_FORCE_UPDATE_INVENTORIES = "/zones/force-update-platform-skybox-inventories",
  ZONE_BULK_UPDATE_BROADCAST_STATUS = "/zones/bulk-update-listing-broadcast-status",

  // Remap Listing with Zone
  ZONE_REMAP_LISTING_WITH_ZONE = "/zones/remap-listings-with-zones",

  
  // Dashboard
  DASHBOARD_LISTER_ALERTS_NEW_SHOWS_COUNT = "/dashboard/lister/alerts/new-shows-count",
  DASHBOARD_LISTER_INSIGHTS_EVENTS_COUNT = "/dashboard/lister/insights/events-count",
  DASHBOARD_LISTER_INSIGHTS_VENUES_COUNT = "/dashboard/lister/insights/venues-count",
  DASHBOARD_LISTER_KPIS_NEW_LISTINGS_GRAPH = "/dashboard/lister/kpis/new-listings",
  DASHBOARD_LISTER_INSIGHTS_ZONE_LISTINGS_COUNT = "/dashboard/lister/insights/zone-listings-count",
  DASHBOARD_LISTER_NEW_SHOWS_NO_LISTING_DATA = "/dashboard/lister/all-events-data",
  DASHBOARD_LISTER_UN_BROADCASTS_GRAPH = "/dashboard/lister/kpis/un-broadcasts",
  DASHBOARD_LISTER_SALES_OVER_TIME_GRAPH = "/dashboard/lister/kpis/sales-over-time",

  DASHBOARD_CHECKER_SEAT_AVAILABILITY = "/dashboard/checker/seat-availability",
  DASHBOARD_CHECKER_KPIS_SEAT_AVAILABILITY_UPDATE_GRAPH = "/dashboard/checker/kpis/seat-availability-update-over-time",
  DASHBOARD_CHECKER_ALERTS_NEW_SHOWS_COUNT = "/dashboard/checker/alerts/shows-count",

  DASHBOARD_SEAT_MAP_CREATOR_ALERTS_SHOWS_COUNT = "/dashboard/seat-map-creator/alerts/shows-count",
  DASHBOARD_SEAT_MAP_CREATOR_SEAT_STRUCTURE_DATA = "/dashboard/seat-map-creator/seat-structure-data",
  DASHBOARD_SEATMAP_SEATING_STRUCTURE_CREATED_GRAPH = "/dashboard/seat-map-creator/kpis/seating-structure-created",

  DASHBOARD_OPERATIONS_ALERTS_HIGH_RISK_LISTINGS = "/dashboard/operations/get-high-risk-listings",
  DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_COUNTS = "/dashboard/operations/get-pending-zone-fills-widget-counts",
  DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_SALES = "/dashboard/operations/get-pending-zone-fills-widget-sales",
  DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_BROKERS = "/dashboard/operations/get-pending-zone-fills-widget-brokers",
  DASHBOARD_BOT_PERFORMANCE_DATA = "/bot-performance-data",
  
  DASHBOARD_PERFORMANCE_ANALYSIS_CREATED_SECTIONS = "/dashboard/performance-analysis/created-sections",
  DASHBOARD_PERFORMANCE_ANALYSIS_CHECKED_SECTIONS = "/dashboard/performance-analysis/checked-sections",
  DASHBOARD_PERFORMANCE_ANALYSIS_UPDATED_GA_EVENTS_SECTIONS = "/dashboard/performance-analysis/updated-ga-events",
  DASHBOARD_PERFORMANCE_ANALYSIS_SPLIT_INFO = "/dashboard/performance-analysis/split-count",
  DASHBOARD_PERFORMANCE_ANALYSIS_VENUE_ASSIGNMENT_INFO = "/dashboard/performance-analysis/venue-assignment",
  DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_EVENTS_INFO = "/dashboard/performance-analysis/listed-events",
  DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_SECTIONS_INFO = "/dashboard/performance-analysis/listed-sections",
  DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_GA_EVENTS_INFO = "/dashboard/performance-analysis/listed-ga-events",
  DASHBOARD_PERFORMANCE_ANALYSIS_LISTER_CONVERSION_RATE_INFO = "/dashboard/performance-analysis/lister-conversion-rate",
  DASHBOARD_PERFORMANCE_ANALYSIS_LISTER_MARGIN_OF_PRICING_INFO = "/dashboard/performance-analysis/lister-margin-of-pricing",

  // Purchase Queue
  PURCHASE_QUEUE_MASTER_DATA = "/queue/purchase/purchase-queue-master-data",
  PURCHASE_QUEUE_INVOICE_DETAIL_MODAL_DATA = "/queue/purchase/get-invoice-detail-modal-data",
  PURCHASE_QUEUE_GET_SWIMLANE_MASTER_LIST = "/queue/purchase/get-purchase-swimlane-master-data",
  PURCHASE_QUEUE_GET_SWIMLANE_INVOICE_LIST = "/queue/purchase/get-purchase-swimlane-invoice-list",
  PURCHASE_QUEUE_MOVE_TO_ON_SALE = "/queue/purchase/move-to-onsale",
  PURCHASE_QUEUE_MOVE_TO_PURCHASED = "/queue/purchase/move-to-purchased",
  PURCHASE_QUEUE_MOVE_TO_NEED_TO_BUY = "/queue/purchase/move-to-need-to-buy",
  PURCHASE_QUEUE_MOVE_TO_NEED_TO_BUY_LOW_PRIORITY = "/queue/purchase/move-to-need-to-buy-low-priority",
  PURCHASE_QUEUE_MOVE_TO_ADD_NOTES = "/queue/purchase/add-notes",
  PURCHASE_QUEUE_CHANGE_BUYER = "/queue/purchase/change-buyer",
  PURCHASE_QUEUE_MOVE_TO_ISSUES = "/queue/purchase/move-to-issues",
  PURCHASE_QUEUE_GET_KEBAB_MENU_OPTIONS = "/queue/purchase/get-purchase-queue-menu-options",
  PURCHASE_QUEUE_CHANGE_PRIORITY = "/queue/purchase/change-priority",
  PURCHASE_QUEUE_MOVE_TO_SHIPPING = "/queue/purchase/move-to-shipping",
  PURCHASE_QUEUE_GET_AUDIT_HISTORY = "/queue/purchase/audit-history/{invoice_id}",
  PURCHASE_QUEUE_GET_NOTES_HISTORY = "/queue/purchase/notes-history/{invoice_id}",
  PURCHASE_QUEUE_SAVE_ISSUE_REMINDER = "/queue/purchase/set-issue-reminder",
  PURCHASE_QUEUE_UPDATE_ISSUE = "/queue/purchase/update-issue",
  PURCHASE_QUEUE_CLOSE_ISSUE = "/queue/purchase/close-issue",
  PURCHASE_QUEUE_CHANGE_ONSALE_STATUS = "/queue/purchase/change-onsale-status",
  PURCHASE_QUEUE_SAVE_ONSALE_REMINDER = "/queue/purchase/set-onsale-reminder",

  //Shipping Queue
  SHIPPING_QUEUE_MOVE_TO_ISSUES = "/queue/shipping/move-to-issues",
  SHIPPING_QUEUE_CLOSE_ISSUE = "/queue/shipping/close-issue",
  SHIPPING_QUEUE_MOVE_TO_ADD_NOTES = "/queue/shipping/add-notes",
  SHIPPING_QUEUE_CHANGE_SHIPPER = "/queue/shipping/change-shipper",
  SHIPPING_QUEUE_UPDATE_ISSUE = "/queue/shipping/update-issue",
  SHIPPING_QUEUE_MARK_AS_DELIVERED = "/queue/shipping/mark-as-delivered",
  SHIPPING_QUEUE_MARK_AS_NOT_DELIVERED = "/queue/shipping/mark-as-not-delivered",
  SHIPPING_QUEUE_MOVE_TO_SELL_OUT = "/queue/shipping/move-to-sell-out",
  DELIVERED_TICKETS = "/delivered-tickets",

  //Competitor Prices
  COMPETITOR_PRICES = "/competitor-prices",
  SECONDARY_MARKETPLACE_MASTER = "/competitor-prices/secondary-marketplace-master",
}

export { RoutesEnum };
