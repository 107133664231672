import { Col, Layout, Pagination, Row } from "antd";
import CustomError from "controls/CustomError/CustomError";
import {
  IDeliveredTickets,
  IDeliveredTicketsQueryParams,
  IFilterOptions,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useCallback, useEffect, useState } from "react";
import { DeliveredTicketsService } from "services/deliveredTicketsService/deliveredTickets.service";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { formatThousandSeparator } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import { deliveredTicketsHeaders } from "./DeliveredTicketsUtils";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const DeliveredTicketsTable: React.FC<{
  searchQuery: string | null;
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  outerLoader: boolean;
  setOuterLoader: Function;
}> = ({
  searchQuery,
  filterObject,
  appliedFilterValues,
  outerLoader,
  setOuterLoader,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "delivery_date",
    sort_order: "desc",
    keywords: "",
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [deliveredTickets, setDeliveredTickets] =
    useState<IDeliveredTickets | null>(null);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams((prevQueryParams: any) => ({
        ...defaultQueryParams,
        ...prevQueryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      }));
    }
  }, [defaultQueryParams, filterObject, searchQuery]);

  const getDeliveredTicketsReportData = useCallback(
    async (queryParams: IDeliveredTicketsQueryParams) => {
      setOuterLoader(false);
      setShowLoader(true);
      try {
        const response =
          await new DeliveredTicketsService().getDeliveredTicketsReport(
            queryParams
          );
        setDeliveredTickets(response.data.data);
        setShowLoader(false);
        setDataFetchError(null);
      } catch (error: any) {
        setShowLoader(false);
        await new LoggerService().log({
          payload: error,
          function_name: "getDeliveredTicketsReportData",
        });
        setDataFetchError({
          ...error,
          refreshAction: getDeliveredTicketsReportData,
        });
      }
    },
    [setOuterLoader]
  );

  useEffect(() => {
    if (queryParams) {
      getDeliveredTicketsReportData(queryParams);
    }
  }, [getDeliveredTicketsReportData, queryParams]);

  const getViewTable = () => {
    if (!showLoader && deliveredTickets && deliveredTickets?.total) {
      return (
        <ul className="innerHeaderRight">
          <li>
            <h4>
              {deliveredTickets?.total === 1
                ? deliveredTickets?.total + " Record Found"
                : formatThousandSeparator(deliveredTickets?.total) +
                  " Records Found"}
            </h4>
          </li>
          <li>
            <Pagination
              className="pagination"
              hideOnSinglePage={true}
              current={queryParams?.page_index}
              total={deliveredTickets?.total}
              pageSize={queryParams?.page_size}
              onChange={(pageNumber) =>
                setQueryParams({
                  ...queryParams,
                  page_index: pageNumber,
                })
              }
              showSizeChanger={false}
            />
          </li>
        </ul>
      );
    }
    return <div style={{ height: 21 }}></div>;
  };
  return (
    <LoadingSpinner isLoading={outerLoader}>
      <React.Fragment>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          queryParams && (
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="flx justifyContentBetween alignItemsCenter">
                  <Col xs={24} sm={24} md={14} className="gpAppliedFilter">
                    <AppliedFilters appliedFilters={appliedFilterValues} />
                  </Col>
                  <Col xs={24} sm={24} md={10}>
                    {getViewTable()}
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                    <CommonReportTable
                      // tableClass="customTbl scroll-x-y fixedHeaderFooter"
                      onSort={(
                        sortIndex: string,
                        sortOrder: "asc" | "desc"
                      ) => {
                        setQueryParams({
                          ...queryParams,
                          sort_column: sortIndex,
                          sort_order: sortOrder,
                        });
                      }}
                      sortField={{
                        dataIndex: queryParams?.sort_column,
                        order: queryParams?.sort_order,
                      }}
                      headers={deliveredTicketsHeaders}
                      data={
                        deliveredTickets
                          ? deliveredTickets?.deliveredTicketsReport
                          : null
                      }
                      tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                      loading={showLoader}
                    />
                  </div>
              
            </Content>
          )
        )}
      </React.Fragment>
    </LoadingSpinner>
  );
};

export default DeliveredTicketsTable;
