import { Form, Button, Checkbox } from "antd";
import { IListElement } from "models/interfaces";

const KanbanLaneFilterForm: React.FC<{
  doClose: Function;
  purchaseIssueTypeMaster?: IListElement[] | null;
  shippingIssueTypeMaster?: IListElement[] | null;
  onApply?: Function;
}> = ({
  doClose,
  onApply,
  purchaseIssueTypeMaster,
  shippingIssueTypeMaster,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    if (onApply) {
      onApply(values);
      doClose();
    }
  };

  const getFilterBodyJsx = (data: IListElement[]) => {
    return data.map((eachType) => {
      return (
        <div className="inputWrap">
          <div className="checkboxGroup">
            <Checkbox value={eachType.value}>{eachType.label}</Checkbox>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="dropdownFilterWrap kanbanLaneFilterWrap"
      style={{ minWidth: "300px" }}
    >
      <div className="dropdownFilterHeader">
        <span>FILTER OPTIONS</span>
        <span className="da icon-close-white" onClick={() => doClose()}></span>
      </div>
      <Form form={form} onFinish={onFinish}>
        <div className="dark">
          {purchaseIssueTypeMaster && (
            <Form.Item name="purchase_issue_type">
              <Checkbox.Group>
                {getFilterBodyJsx(purchaseIssueTypeMaster)}
              </Checkbox.Group>
            </Form.Item>
          )}

          {shippingIssueTypeMaster && (
            <Form.Item name="shipping_issue_type">
              <Checkbox.Group>
                {getFilterBodyJsx(shippingIssueTypeMaster)}
              </Checkbox.Group>
            </Form.Item>
          )}

          <div className="formEnd">
            <div className="btnsRight">
              <Button
                className="linkBtn"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                RESET
              </Button>
              <Button
                className="btnOk"
                onClick={() => {
                  form.submit();
                }}
              >
                APPLY
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default KanbanLaneFilterForm;
