import { IQueryParams, IReportQueryString } from "models/interfaces";
import { filterCurrencyValue } from "utils/formatCurrency";
import {
  formatThousandSeparator,
  getEncodedData,
} from "utils/commonFunction";

const commonWidth = 50;

export const generateEncodedUrl = (
  params: IQueryParams,
  reportData: IReportQueryString
) => {
  return `/reports/inventory-long?report=${getEncodedData(
    reportData
  )}&filter=${getEncodedData({
    alert_Id: params.alert_Id,
    broker_Id:
      params.broker_Id || params.broker_Id == "0" ? [params.broker_Id] : null,
    buyer_Id: params.buyer_Id,
    event_date_from: "2020-01-01",
  })}`;
};

export const BrokerHeaders = [
  {
    title: "Buyer",
    dataIndex: "buyer",
    thClassName: "text-left",
    width: commonWidth,
  },
  {
    title: "Active Listings",
    dataIndex: "active_listings",
    thClassName: "text-right thTxtWrap activeListingCol",
    dataRender: (data: any, all: any) =>
      all.otherData.hasPermissions ? (
        <div className="text-right">
          <a
            target="_blank"
            rel="noreferrer"
            href={generateEncodedUrl(
              {
                broker_Id: all.otherData.broker_Id,
                buyer_Id: all.data.singleData.buyerid,
              },
              all.otherData.reportData
            )}
          >
            {data}
          </a>
        </div>
      ) : (
        <div className="text-right">
          <p>{data}</p>
        </div>
      ),
    dataFooterRender: (data: any, rowData: any) =>
      rowData.otherData.hasPermissions ? (
        <div className="text-right">
          <a
            target="_blank"
            rel="noreferrer"
            href={generateEncodedUrl(
              { broker_Id: rowData.otherData.broker_Id },
              rowData.otherData.reportData
            )}
          >
            {data}
          </a>
        </div>
      ) : (
        <div className="text-right">
          <p>{data}</p>
        </div>
      ),
  },
  {
    title: "Potential Earnings",
    dataIndex: "potential_earnings",
    thClassName: "text-right thTxtWrap potentialEarningsCol",
    dataRender: (data: any) => (
      formatRenderCurrency(data, false)
    ),
    dataFooterRender: (data: any) => (
      formatRenderCurrency(data, true)
    ),
    tooltipIndex: "potential_earnings",
  },
  {
    title: "Priced Inventory",
    dataIndex: "priced_inventory",
    thClassName: "text-right thTxtWrap pricedInventoryCol",
    dataRender: (data: any) => (
      formatRenderCurrency(data, false)
    ),
    dataFooterRender: (data: any) => (
      formatRenderCurrency(data, true)
    ),
    tooltipIndex: "priced_inventory",
  },
  {
    title: "Cost of Priced Inventory",
    dataIndex: "cost_priced_inventory",
    thClassName: "text-right thTxtWrap costOfpricedInventoryCol",
    dataRender: (data: any) => (
      formatRenderCurrency(data, false)
    ),
    dataFooterRender: (data: any) => (
      formatRenderCurrency(data, true)
    ),
    tooltipIndex: "cost_priced_inventory",
  },
  {
    title: "Cost of Unpriced Inventory",
    dataIndex: "cost_unpriced_inventory",
    thClassName: "text-right thTxtWrap costOfUnpricedInventoryCol",
    dataRender: (data: any) => (
      formatRenderCurrency(data, false)
    ),
    dataFooterRender: (data: any) => (
      formatRenderCurrency(data, true)
    ),
    tooltipIndex: "cost_unpriced_inventory",
  },
  {
    title: "Capital Applied",
    dataIndex: "capital_applied",
    thClassName: "text-right thTxtWrap capitalAppliedCol",
    dataRender: (data: any) => (
      formatRenderCurrency(data, false)
    ),
    dataFooterRender: (data: any) => (
      formatRenderCurrency(data, true)
    ),
    tooltipIndex: "capital_applied",
  },
  {
    title: "<15 Days",
    dataIndex: "less_than_fifteen",
    thClassName: "text-right thTxtWrap fifteenDaysCol",
    dataRender: (data: any) => (
      formatRenderNormal(data)
    ),
    dataFooterRender: (data: any) => (
      formatRenderNormal(data)
    ),
    tooltipIndex: "less_than_fifteen",
  },
  {
    title: "Cost Missing",
    dataIndex: "costing_issue",
    thClassName: "text-right thTxtWrap costMissingCol",
    dataRender: (data: any) => (
      formatRenderNormal(data)
    ),
    dataFooterRender: (data: any) => (
      formatRenderNormal(data)
    ),
    tooltipIndex: "costing_issue",
  },
  {
    title: "Pricing Issue",
    dataIndex: "pricing_issue",
    thClassName: "text-right thTxtWrap pricingIssueCol",
    dataRender: (data: any) => (
      formatRenderNormal(data)
    ),
    dataFooterRender: (data: any) => (
      formatRenderNormal(data)
    ),
    tooltipIndex: "pricing_issue",
  },
  {
    title: "Card Details Missing",
    dataIndex: "card_missing",
    thClassName: "text-right thTxtWrap cardMissingCol",
    dataRender: (data: any) => (
      formatRenderNormal(data)
    ),
    dataFooterRender: (data: any) => (
      formatRenderNormal(data)
    ),
    tooltipIndex: "card_missing",
  },
];

const formatRenderCurrency = (
  data: any,
  showCurrencySymbol: boolean = false
) => {
  return (
    <div className={`text-right ${parseFloat(data?.split(' ')[1]) < 0 ? "text-danger" : ""}`}>
      {filterCurrencyValue(
        data,
        showCurrencySymbol
      )}
    </div>
  );
}

const formatRenderNormal = (data: any) => {
  return (
    <div className={`text-right ${parseInt(data) < 0 ? "text-danger" : ""}`}>
      {data ? formatThousandSeparator(data) : "-"}
    </div>
  );
}
