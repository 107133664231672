import React, { useEffect, useState } from "react";
import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { usePageTitle } from "hooks";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { IFilterOptions } from "models/interfaces";
import CreateSeatStructureData from "./CreateSeatStructure/CreateSeatStructureData";
import CommonCountsComponent from "../CommonComponents/CommonCountsComponent/CommonCountsComponent";
import { TBDWidgetCounts } from "../CommonComponents/TBDWidget/TBDWidget";
import { colsCount } from "../CommonComponents/CommonFile/commonFile";
import SeatingStructureCreated from "./KPIs/SeatingStructureCreated";

const SeatMapCreatorDashboardPage: React.FC = () => {
  usePageTitle("Seat Map Creator Dashboard");

  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    dispatch(actions.getSeatIntelligenceData());

    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (
      hasPermissions([
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getSeatMapCreatorUserData());
    }
  }, [dispatch]);

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  return (
    <div className="fixedFilterArea">
      <div className="mainHeading">
        <div className="dashboardTopBar">
          <div className="flx alignItemsFlexEnd">
            <h2>SEAT MAP CREATOR DASHBOARD</h2>
          </div>

          <FilterDrawer
            onApplyFilter={filterChanged}
            showCustomDatePicker={true}
            showBrokerUserDropdown={hasPermissions([
              ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
            ])}
            brokerUserDropdownType="single"
            showSeatMapCreatorDropdown={hasPermissions([
              ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
              ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
            ])}
            seatMapCreatorDropdownType="single"
          />
        </div>
      </div>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Alerts</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="NEW SHOWS (NO SEAT STRUCTURE)"
                paramObject={{ show_category_id: 1, exclude_sold_out_events: true, exclude_cancelled_events: true }}
                apiName="getSeatMapCreatorShowsCount"
                otherFilterObjects={{ new_shows_only: true }}
                serviceType="seatMapCreator"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="showsCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="ALL OTHER SHOWS (NO SEAT STRUCTURE)"
                paramObject={{ show_category_id: 4, exclude_sold_out_events: true, exclude_cancelled_events: true }}
                apiName="getSeatMapCreatorShowsCount"
                otherFilterObjects={{ new_shows_only: false }}
                serviceType="seatMapCreator"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="showsCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="SHOWS (SEAT MAP TBD)"
                paramObject={{ show_category_id: 9, exclude_sold_out_events: true, exclude_cancelled_events: true }}
                apiName="getSeatMapCreatorShowsCount"
                otherFilterObjects={{ shows_seatmap_tbd: true }}
                serviceType="seatMapCreator"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="showsCount"
                labelColor="text-danger"
              />
            </Row>
          </div>
          <CreateSeatStructureData
            filterObject={filterObject}
            cols={3}
          />
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Insights</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="VENUES"
                paramObject={{}}
                apiName="getListerVenuesCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_VENUES"
                dataKey="venuesCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="EVENTS"
                paramObject={{}}
                apiName="getListerEventsCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_EVENTS"
                dataKey="eventsCount"
              />
              <TBDWidgetCounts
                label="Widget TBD"
                cols={colsCount.insights}
                textColorClass="text-danger"
              />
            </Row>
          </div>
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap kpisGraphsWrap">
            <h3>KPIs</h3>
            <Row className="removeRowBtmSpc">
              <SeatingStructureCreated
                filterObject={filterObject}
                graphType="bar"
                serviceType="seatmap"
                apiName="getKPIsSeatingStructureCreatedGraphData"
                label="Seating Structure Created"
                cols={colsCount.kpis}
                tooltipHeaders={["Seat Structure Created"]}
                cId={2}
                typeOfData="New Structure Created :"
              />
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default SeatMapCreatorDashboardPage;
