enum DayOfMonthEnum {
  "1st" = 1,
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "Last Day",
}

enum DayOfWeekEnum {
  "1st" = 1,
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
}

enum DayOfWeekNamesEnum {
  "Sunday" = 1,
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
}

enum FrequencyEnum {
  "Daily" = 1,
  "Weekly",
  "Monthly",
}

enum OccurrenceEnum {
  "Never" = 1,
  "On",
  "After",
}

export {
  DayOfMonthEnum,
  DayOfWeekEnum,
  DayOfWeekNamesEnum,
  FrequencyEnum,
  OccurrenceEnum,
};
