import { message, Typography } from "antd";
import { ISoldInventoryDatum } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { HouseKeepingService } from "services";
import { AppSetting } from "utils/appSettings";
import CancelInvoiceModal from "./CancelInvoiceModal";

const CancelInvoice: React.FC<{ poLineId: number[]; invLineId: number; initModalData: ISoldInventoryDatum}> = ({
  poLineId,
  invLineId,
  initModalData
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<ISoldInventoryDatum>({...initModalData});
  const [isCancelPending, setIsCancelPending] = useState(false);

  useEffect(()=>{
    if(modalData?.is_invoice_cancel_in_progress || modalData?.is_purchase_cancel_in_progress) {
      setIsCancelPending(true);
    }
    else {
      setIsCancelPending(false);
    }
  }, [modalData]);

  const clickHandler = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    const response = await new HouseKeepingService().getSoldInventoryListItem({
      clientZone: AppSetting.CLIENT_ZONE,
      poLineId,
      invLineId,
    });
    
    
    if(response.data.data.soldInventoryItemDetails) {
      setModalData((prev)=>({...prev, ...response.data.data.soldInventoryItemDetails}));
    }
    if (
      response.data.data.soldInventoryItemDetails &&
      response.data.data.soldInventoryItemDetails.is_invoice_cancel_in_progress ||
      response.data.data.soldInventoryItemDetails.is_purchase_cancel_in_progress
    ) {
        setIsModalVisible(false);
        setIsCancelPending(true);
      message.warning({
        content:
          "A previous cancel request is already in progress. Please refresh page to update.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
    else {
        setIsModalVisible(true);
        setIsCancelPending(false);
    }
  };

  return (
    <React.Fragment>
      <CancelInvoiceModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setIsCancelPending={setIsCancelPending}
        modalData={modalData}
      />
      <div className="cancelInvoice">
        {!isCancelPending && 
          <Typography.Link underline={true} onClick={(e) => clickHandler(e)}>
            Cancel Invoice
          </Typography.Link>
        }
        {isCancelPending &&
          <span>Cancel Pending</span>
        }
      </div>
    </React.Fragment>
  );
};

export default CancelInvoice;
