import { Dropdown } from "antd";
import { ISwimlaneMaster } from "models/interfaces";
import { useState } from "react";
import KanbanLaneSortForm from "./KanbanLaneSortForm";

const KanbanLaneSort: React.FC<{
    swimlaneData: ISwimlaneMaster;
    currentSortOption: {sort_key: string; sort_order: string};
    setCurrentSortOption: Function;
}> = ({swimlaneData, currentSortOption, setCurrentSortOption}) => {
    const [showForm, setShowForm] = useState(false);
    const [showRedDot, setShowRedDot] = useState(false);

    return (
        <Dropdown
            overlay={
                <KanbanLaneSortForm 
                    swimlaneData={swimlaneData}
                    currentSortOption={currentSortOption}
                    doClose={()=>setShowForm(false)}
                    setCurrentSortOption={setCurrentSortOption}
                    setShowRedDot={setShowRedDot}
                />
            }
            placement="bottomRight"
            trigger={["click"]}
            visible={showForm}
            onVisibleChange={(data) => setShowForm(data)}
        >
            <span className="clickFilter" onClick={(e) => e.preventDefault()}>
                {showRedDot && 
                    <div className="kanbanLaneFilterRedDot"></div>
                }
                <i className="da icon-black-sorting"></i>
            </span>
        </Dropdown>
    )
};

export default KanbanLaneSort;