import { DatePicker, Input, Radio, RadioChangeEvent, Space } from "antd";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { FrequencyEnum, OccurrenceEnum } from "models/enums/schedule";
import {
  IScheduleListResponse,
  ISchedulerDatum,
} from "models/interfaces/Scheduler/scheduler.model";
import moment, { Moment } from "moment";
import "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppSetting } from "utils/appSettings";
import {
  getCustomDate,
  getDateObject,
  getDateTimeInCustomTimeZone,
  getDisabledDate,
  getOffsetDate,
} from "utils/formatTimePeriod";

const OccurrenceControlTooltip: React.FC<{
  scheduleData: ISchedulerDatum;
  setScheduleData: Function;
  reset: boolean;
  setResetToDefaults: Function;
  existingScheduleData: IScheduleListResponse | null;
}> = ({
  scheduleData,
  setScheduleData,
  reset,
  setResetToDefaults,
  existingScheduleData,
}) => {
  const [datePickerValue, setDatePickerValue] = useState<Moment|undefined|null>(
    getDateObject(getCustomDate())
  );
  const [selectedOption, setSelectedOption] = useState<any>({
    occurrenceId: 1,
  });
  const [inputData, setInputData] = useState<number>();
  const displayDateFormat = "DD, MMMM YYYY";
  const dbDateFormat = "YYYY-MM-DD HH:mm:ssZ";
  const [displayString, setDisplayString] = useState<string>(" Never");
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState<number>(1);

  useEffect(() => {
    initDefault();
    setResetToDefaults(false);
  }, [reset === true]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          displayTooltip
        ) {
          setDisplayTooltip(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, displayTooltip]);
  }

  useOutsideAlerter(wrapperRef);

  const initDefault = () => {
    switch (scheduleData?.frequencyId) {
      case FrequencyEnum.Daily:
        setDatePickerValue(
          existingScheduleData?.scheduleDetails.endDate
            ? getDateObject(getCustomDate(existingScheduleData?.scheduleDetails.endDate))
            : getDateObject(getOffsetDate(OffsetMode.ADD, 30, OffsetType.DAYS))
        );
        setInputData(
          existingScheduleData?.scheduleDetails.noOfOccurrence
            ? existingScheduleData?.scheduleDetails.noOfOccurrence
            : 30
        );
        break;
      case FrequencyEnum.Weekly:
        setDatePickerValue(
          existingScheduleData?.scheduleDetails.endDate
            ? moment(existingScheduleData?.scheduleDetails.endDate)
            : moment().add(13, "weeks")
        );
        setInputData(
          existingScheduleData?.scheduleDetails.noOfOccurrence
            ? existingScheduleData?.scheduleDetails.noOfOccurrence
            : 13
        );
        break;
      case FrequencyEnum.Monthly:
        setDatePickerValue(
          existingScheduleData?.scheduleDetails.endDate
            ? moment(existingScheduleData?.scheduleDetails.endDate)
            : moment().add(12, "months")
        );
        setInputData(
          existingScheduleData?.scheduleDetails.noOfOccurrence
            ? existingScheduleData?.scheduleDetails.noOfOccurrence
            : 12
        );
        break;
    }
    setSelectedOption({
      occurrenceId: existingScheduleData?.scheduleDetails.occurrenceId
        ? existingScheduleData?.scheduleDetails.occurrenceId
        : 1,
    });
    setSelectedRadioButton(
      existingScheduleData?.scheduleDetails.occurrenceId
        ? existingScheduleData?.scheduleDetails.occurrenceId
        : 1
    );
  };

  const radioChangeHandler = (e: RadioChangeEvent) => {
    setSelectedRadioButton(e.target.value);
  };

  const dateChangeHandler = (date: Moment | null) => {
    if (date !== null) {
      setDatePickerValue(date);
    }
  };

  function disabledDate(current: any) {
    // Can not select days before today
    return current && current < getDisabledDate();
  }

  useEffect(() => {
    switch (selectedRadioButton) {
      case OccurrenceEnum.Never:
        setSelectedOption({
          ...selectedOption,
          occurrenceId: 1,
          endDate: null,
          noOfOccurrence: null,
        });
        setDisplayString(` ${OccurrenceEnum[1]}`);
        break;
      case OccurrenceEnum.On:
        setSelectedOption({
          ...selectedOption,
          occurrenceId: 2,
          endDate: getDateTimeInCustomTimeZone(
            datePickerValue,
            AppSetting.TIMEZONE
          ).format(dbDateFormat),
          noOfOccurrence: null,
        });
        setDisplayString(
          ` on ${moment(datePickerValue).format(displayDateFormat)}`
        );
        break;
      case 3:
        setSelectedOption({
          ...selectedOption,
          occurrenceId: 3,
          endDate: null,
          noOfOccurrence: inputData ? inputData : 1,
        });
        setDisplayString(` after ${inputData ? inputData : 1} occurrences`);
        break;
    }
  }, [selectedRadioButton, datePickerValue, inputData]);

  useEffect(() => {
    initDefault();
  }, [scheduleData?.frequencyId]);

  useEffect(() => {
    setScheduleData({ ...scheduleData, ...selectedOption });
  }, [selectedOption]);

  return (
    <React.Fragment>
      <span className="customTooltipWrap">
        <Link
          to="#/"
          onClick={(e) => {
            e.preventDefault();
            setDisplayTooltip((prev) => !prev);
          }}
        >
          {displayString}
        </Link>{'.'}
        <div
          className="customTooltip"
          style={displayTooltip ? { display: "block" } : { display: "none" }}
          id="occurrenceTooltip"
          ref={wrapperRef}
        >
          <h6>Ends</h6>
          <Radio.Group
            onChange={(e) => radioChangeHandler(e)}
            className="radioStyleDark"
            value={selectedRadioButton}
          >
            <Space direction="vertical">
              <Radio value={1} defaultChecked={true}>
                <span>Never</span>
              </Radio>
              <div className="groupCont">
                <Radio value={2}>
                  <span>On</span>
                </Radio>
                <DatePicker
                  allowClear={false}
                  disabledDate={disabledDate}
                  size="small"
                  style={{ width: "auto" }}
                  disabled={selectedRadioButton === 2 ? false : true}
                  bordered={false}
                  defaultPickerValue={
                    datePickerValue ? datePickerValue : undefined
                  }
                  value={datePickerValue}
                  onChange={dateChangeHandler}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement as HTMLInputElement
                  }
                />
              </div>

              <Radio value={3} className="removePad">
                <div className="groupCont">
                  <span>After</span>&nbsp;&nbsp;
                  <Input
                    type={"number"}
                    min={1}
                    size="small"
                    style={{ minWidth: "56px", paddingRight: "0" }}
                    onChange={(e) => setInputData(parseInt(e.target.value))}
                    defaultValue={inputData}
                    value={inputData ? inputData : 1}
                    required={selectedRadioButton === 3 ? true : false}
                    disabled={selectedRadioButton === 3 ? false : true}
                  />
                  &nbsp;&nbsp;
                  <span className="occurrences" style={{ cursor: "default" }}>
                    Occurrences
                  </span>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </span>
    </React.Fragment>
  );
};

export default OccurrenceControlTooltip;
