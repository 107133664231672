import React, { useState, useEffect } from "react";
import { Col } from "antd";
import {
  IFilterOptions,
  IListerKPIsNewListingGraphData,
  IListerKPIsGraphQueryParams
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { LoggerService } from "sharedServices";
import {
  defaultParams,
  getUsableService,
  widgetClassPerCol
} from "../../CommonComponents/CommonFile/commonFile";
import moment from "moment";
import CustomError from "controls/CustomError/CustomError";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import KpisBaseBarGraph from "../../CommonComponents/KPIs/KpisBaseBarGraph";

const SeatingStructureCreated: React.FC<{
  filterObject: IFilterOptions | null;
  graphType: string;
  serviceType: string;
  apiName: string;
  label: string;
  cols: number;
  tooltipHeaders: string[];
  cId: number;
  typeOfData: string;
}> = ({
  filterObject,
  graphType,
  serviceType,
  apiName,
  label,
  cols,
  tooltipHeaders,
  cId,
  typeOfData
}) => {

    const [queryParams, setQueryParams] = useState<
      IListerKPIsGraphQueryParams | null
    >(null);

    const [newGraphData, setNewGraphData] = useState<
      IListerKPIsNewListingGraphData | null
    >(null);

    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);
    const [dateRange, setDateRange] = useState<number>(30)

    const [defaultQueryParams] = useState<IListerKPIsGraphQueryParams>({
      custom_date_from: null,
      custom_date_to: null,
      ...defaultParams
    });

    const getKPIsGraphData = async () => {
      try {
        setShowLoader(true);

        const response = await getUsableService(serviceType)[apiName]({
          ...queryParams,
        });
        setNewGraphData(response.data.data);

        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);

        await new LoggerService().log({
          payload: error,
          function_name: "getKPIsGraphData",
        });

        setDataFetchError({ ...error, refreshAction: getKPIsGraphData });
      }
    };

    useEffect(() => {
      if (queryParams) {
        setDateRange(moment(queryParams.custom_date_to).diff(moment(queryParams.custom_date_from), 'days') + 1);
        getKPIsGraphData();
      }
    }, [queryParams]);

    useEffect(() => {
      if (filterObject !== null && defaultQueryParams) {
        setQueryParams({
          ...defaultQueryParams,
          ...filterObject
        });
      }
    }, [defaultQueryParams, filterObject]);

    const countFormat = (totalCount: number) => {
      return `${typeOfData} ${totalCount.toLocaleString('en-US')}`
    }

    const getGraphListingCountsView = (totalCount: number) => {
      return !showLoader ? (
        <div className="titleLerge">
          {countFormat(totalCount)}
        </div>
      ) : (
        <div style={{ height: 35 }}></div>
      )
    }

    return (
      <Col className={widgetClassPerCol[cols]}>
        <div className="dashboardChartCard">
          {dataFetchError ? (
            <CustomError {...dataFetchError} />
          ) : (
            <div className="dashboardCard mainChartWidget" style={{ minHeight: 460 }}>
              <LoadingSpinner isLoading={showLoader}>
                <div className="chartTopbar">
                  <h5>{label}</h5>
                  <div className="graphDetail">
                    {getGraphListingCountsView(newGraphData?.total_count ?? 0)}
                  </div>
                </div>

                {graphType === "bar" && (
                  <KpisBaseBarGraph
                    data={newGraphData?.graph_data}
                    cId={cId}
                    tooltipHeader={tooltipHeaders[0]}
                    dateRange={dateRange}
                  />
                )}

              </LoadingSpinner>
            </div>
          )}
        </div>
      </Col>
    )
  }

export default SeatingStructureCreated;
