import { Row, Col } from "antd";
import { usePageTitle } from "hooks";
import CheckersPerformanceAnalysis from "./CheckersPerformanceAnalysis/CheckersPerformanceAnalysis";
import SeatmapCreatorsPerformanceAnalysis from "./SeatmapCreatorsPerformanceAnalysis/SeatmapCreatorsPerformanceAnalysis";
import ListersPerformanceAnalysis from "./ListersPerformanceAnalysis/ListersPerformanceAnalysis";
import { ISectionInfo } from "models/interfaces";
import moment from "moment";


export const filterInactiveUser = (data: ISectionInfo[], key: 'zone_section_count' | 'ga_events_count' | 'split_inventory_count' | 'venue_assignment_count' | 'events_count' | 'sections_count' | 'margin_of_pricing') => {
  return data.filter(({ data }) => {
    const total_count = data.reduce((count, currentValue) => count + (currentValue[key] ?? 0), 0)
    return Boolean(total_count)
  })
}

export const getTooltipTitle = (start_date: string, end_date: string) => {
  return moment(start_date).format('ll') + ' - ' + moment(end_date).format('ll');
}

export const widget_information = (
    data: ISectionInfo[], 
    key: 'zone_section_count' | 'ga_events_count' | 'split_inventory_count' | 'venue_assignment_count' | 'events_count' | 'sections_count' | 'margin_of_pricing',
    is_percentage: boolean = false
  ) => {
  const _data = data.filter(({ data }) => {
    const total_count = data.reduce((count, currentValue) => count + (currentValue[key] ?? 0), 0)
    return Boolean(total_count)
  });
  let __data: any[] = [];
  let footerData: any[] = [];
  _data.map(({ first_name, last_name, data }, _index) => {
    const rowInfo = {
      'user': first_name + ' ' + last_name
    };
    data.forEach((info, index) => {
      rowInfo[index] = info[key] ? info[key] + (is_percentage ? '%' : '') : 0;
    })
    if (first_name === 'TOTAL') {
      footerData = [...footerData, rowInfo]
    } else {
      __data = [...__data, rowInfo]
    }
  })
  return {
    "data": __data,
    "footerData": footerData
  }
}

export const getTotal = (data: Array<{ [key: string]: any }>) => {
  if (data.length === 0) {
    return [];
  }
  const total = {
    'user': 'TOTAL'
  };
  data.map(information => {
    for (const [key, value] of Object.entries(information)) {
      if (key !== 'user') {
        if (key in total) {
          if (key)
            total[key] += value
        } else {
          total[key] = value
        }
      }
    }
  })
  return [total];
}

const PerformanceAnalysisPage: React.FC = () => {

  usePageTitle("Performance Analysis Dashboard");

  return (
    <div>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col className="ant-col-sm-18 ant-col-xxl-12">
            <h2>Performance Analysis DASHBOARD</h2>
          </Col>
        </Row>
      </div>
      <SeatmapCreatorsPerformanceAnalysis />
      <CheckersPerformanceAnalysis />
      <ListersPerformanceAnalysis />
    </div>
  );
};

export default PerformanceAnalysisPage;
