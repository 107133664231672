import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const LoadingSpinner: React.FC<{ isLoading: boolean; delay?: number; }> = (props) => {
  return (
    <div>
      <Spin
        className="spinLoader"
        indicator={antIcon}
        spinning={props.isLoading}
        delay={props.delay ? props.delay : 0}
      >
        {props.children}
      </Spin>
    </div>
  );
};

export default LoadingSpinner;