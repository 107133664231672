enum LeftNavRoutesEnum {
    DASHBOARD_MANAGEMENT = "/dashboard/management",
    DASHBOARD_OPERATIONS = "/dashboard/operations",
    DASHBOARD_BOT_PERFORMANCE = "/dashboard/bot-performance",
    DASHBOARD_FINANCE = "/dashboard/finance",
    DASHBOARD_BROKER = "/dashboard/broker",
    DASHBOARD_LISTER = "/dashboard/lister",
    DASHBOARD_BUYER = "/dashboard/buyer",
    DASHBOARD_CHECKER = "/dashboard/checker",
    DASHBOARD_SEATMAPCREATOR = "/dashboard/seat-map-creator", 
    DASHBOARD_PERFORMANCE_ANALYSIS = "/dashboard/performance-analysis",
    //Zones
    ZONES_LISTING = "/zones/zone-listings",
    ZONES_ACTIVE_LISTING = "/zones/zone-listings/active-listings",
    ZONES_LISTING_EDIT_EVENT_AVAILABILITY = "/zones/zone-listings/edit-event-metadata",
    ZONE_LISTINGS_ACTIVE_LISTINGS = "/zones/zone-listings/active-listings", //NEW
    ZONES_PENDING_FILLS = "/zones/pending-fills",
    ZONES_INSIGHTS = "/zones/insights",                 //will change later
    //Housekeeping
    HOUSEKEEPING_CANCELLED_SHOWS = "/housekeeping/cancelled-shows",     
    HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE = "/housekeeping/cancelled-shows/active",    
    HOUSEKEEPING_CANCELLED_SHOWS_SOLD = "/housekeeping/cancelled-shows/sold",   
    HOUSEKEEPING_CANCELLED_SHOWS_UNFILLED = "/housekeeping/cancelled-shows/unfilled", // NEW  
    HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED = "/housekeeping/cancelled-shows/cancelled",
    HOUSEKEEPING_EVENT_MAPPING = "/housekeeping/event-mapping",
    //Trading 
    TRADING_VENUES = "/trading/venues",
    TRADING_EVENTS = "/trading/events",
    TRADING_EVENTS_CREATE_NEW_ZONE_LISTING = "/trading/events/create-new-zone-listing",
    TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_BULK_PLACEMENT = "/trading/events/create-new-zone-listing/bulk-placement",
    TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_WITHOUT_METADATA = "/trading/events/create-new-zone-listing-without-metadata",
    INSIGHTS_REPORTS = "/insights/reports",
    INSIGHTS_SCHEDULES = "/insights/schedules",
    MESSAGES_INBOX = "/messages/inbox",
    MESSAGES_ARCHIVES = "/messages/archives",
    MESSAGES_SENT = "/messages/sent",
    //Purchases
    PURCHASE_QUEUE = "/purchases/purchase-queue",
    //Shipping
    SHIPPING_QUEUE = "/shipping/shipping-queue",
    DELIVERED_TICKETS_REPORT = "/shipping/delivered-tickets",
    //Settings
    SETTINGS_USERS = "/settings/users",
    SETTINGS_ROLES = "/settings/roles",
    SETTINGS_PRIVILEGES = "/settings/privileges",
    SETTINGS_COMMISSIONS = "/settings/commissions",
}

enum LeftNavMenuKeysEnum {
    DASHBOARD = "/dashboard",
    ZONES = "/zones",
    TRADING = "/trading",
    PURCHASES = "/purchases",
    SHIPPING = "/shipping",
    HOUSEKEEPING = "/housekeeping",
    INSIGHTS = "/insights",
    MESSAGES = "/messages",
    SETTINGS = "/settings",
}

export { LeftNavRoutesEnum, LeftNavMenuKeysEnum };