import { RoutesEnum } from "models/enums";
import { GrossProfitData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class GrossProfitCancelledService {
    public getGrossProfitData = async (grossProfitRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.GROSS_PROFIT_CANCELLED, {
            params: grossProfitRequest
        });
    };
}

export { GrossProfitCancelledService };