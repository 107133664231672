import { Tooltip, Typography, Badge } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { checkValidUrl, getEncodedData } from "utils/commonFunction";

const EventCardActions: React.FC<{
  eachEventData: any;
}> = ({ eachEventData }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const kebabMenuClickHandler = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const viewSeatClickHandler = () => {
    window.open(
      eachEventData?.seat_map_url ? eachEventData?.seat_map_url : "",
      "_blank"
    );
  };

  const editMetadataClickHandler = () => {
    window.open(
      `${
        LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY
      }?event=${getEncodedData({
        event_id: eachEventData?.event_id
      })}`,
      "_blank"
    );
  };

  return (
    <div className="actions">
      {isMobile ? (
        <div className="kebabMenu">
          <button className="kebabMenuToggler" onClick={kebabMenuClickHandler}>
            <i className="da icon-kebab-v"></i>
          </button>
          {isMenuVisible && (
            <div className="kebabMenuDropdown">
              {
                eachEventData?.venue_fees &&
                <div>
                  <Tooltip className="ml0" title={`Venue Fees:$${eachEventData?.venue_fees}`}><i className="da icon-venue-fees" /></Tooltip>
                </div>
              }
              <Tooltip title="View Seating Info">
                <Typography.Link
                  disabled={
                    eachEventData?.seat_map_url === undefined ||
                    eachEventData?.seat_map_url === "" ||
                    eachEventData?.seat_map_url === null ||
                    !checkValidUrl(eachEventData?.seat_map_url)
                  }
                  onClick={viewSeatClickHandler}
                  className="ml10"
                >
                  <i className="da icon-th" />
                </Typography.Link>
              </Tooltip>
              <RBAC allowedPermissions={[ERbacPermissions.EVENT_METADATA_EDIT]}>
                <Tooltip title="Edit Event Metadata">
                  <Typography.Link onClick={editMetadataClickHandler}>
                    <i className="da icon-pencil-o" />
                  </Typography.Link>
                </Tooltip>
              </RBAC>
              <Badge count={0} size={"small"} color="#9F2533">
                <Tooltip title="Notifications">
                  <Typography.Link disabled className="cardNotificationIcon">
                    <i className="da icon-comment" />
                  </Typography.Link>
                </Tooltip>
              </Badge>
            </div>
          )}
        </div>
      ) : (
        <React.Fragment>
          {
            eachEventData?.venue_fees &&
            <div>
              <Tooltip title={`Venue Fees:$${eachEventData?.venue_fees}`}><i className="da icon-venue-fees" /></Tooltip>
            </div>
          }
          <Tooltip title="View Seating Info">
            <Typography.Link
              disabled={
                eachEventData?.seat_map_url === undefined ||
                eachEventData?.seat_map_url === "" ||
                eachEventData?.seat_map_url === null ||
                !checkValidUrl(eachEventData?.seat_map_url)
              }
              onClick={viewSeatClickHandler}
            >
              <i className="da icon-th" />
            </Typography.Link>
          </Tooltip>
          <RBAC allowedPermissions={[ERbacPermissions.EVENT_METADATA_EDIT]}>
            <Tooltip title="Edit Event Metadata">
              <Typography.Link
                disabled={isMobile}
                onClick={editMetadataClickHandler}
              >
                <i className="da icon-pencil-o" />
              </Typography.Link>
            </Tooltip>
          </RBAC>
          <Badge count={0} size={"small"} color="#9F2533">
            <Tooltip title="Notifications">
              <Typography.Link disabled className="cardNotificationIcon">
                <i className="da icon-comment" />
              </Typography.Link>
            </Tooltip>
          </Badge>
        </React.Fragment>
      )}
    </div>
  );
};

export default EventCardActions;
