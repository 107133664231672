import { Layout } from "antd";
import { useAppSelector } from "app/hooks";
import CustomError from "controls/CustomError/CustomError";
import { dataFetchError, hasError } from "models/Slices/EventManagementSlice";
import AuditHistoryTable from "./AuditHistoryTable";
import AvailabilityTrendGraph from "./AvailabilityTrendGraph";

const AvailabilityTrend = () => {
  const error = useAppSelector(dataFetchError);
  const isError = useAppSelector(hasError);
  
  return (
    <Layout className="mainInnerLayout btmSpaceEditEvent">
      <div className="ant-row">
        {isError ? <CustomError {...error} /> : <AvailabilityTrendGraph />}
        <AuditHistoryTable />
      </div>
    </Layout>
  );
};

export default AvailabilityTrend;
