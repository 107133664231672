import { useAppDispatch } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { actions } from "models/Slices/FilterSlice";
import React, { useEffect } from "react";
import Kpis from "./KPIS/Kpis";
import ListingAlerts from "./ListingAlerts/ListingAlerts";

const InventoryTrading: React.FC = () => {

  const dispatch = useAppDispatch();
  const { hasPermissions } = useRbac();

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }

    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getListerUserData());
    }
  }, []);

  return (
    <React.Fragment>
      <ListingAlerts/>
      <Kpis />
    </React.Fragment>

  );
};

export default InventoryTrading;
