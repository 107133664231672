import { RoutesEnum } from "models/enums";
import {
  IBrokerSummary,
  IGraphData,
  IPeriodBreakup,
  IProfitDashboardBaseType,
  IProfitDashboardType,
  IProfitScore,
  ITopEvents,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class ProfitDashboardService {
  public getScorecardData = async (scoreCardRequest: IProfitDashboardType) => {
    return axiosClient.get<IApiBaseResponse<IProfitScore>>(
      RoutesEnum.PROFIT_DASHBOARD_SCORE,
      {
        params: scoreCardRequest,
      }
    );
  };

  public getBrokerSummary = async (
    brokerSummaryRequest: IProfitDashboardType
  ) => {
    return axiosClient.get<IApiBaseResponse<IBrokerSummary>>(
      RoutesEnum.PROFIT_DASHBOARD_BROKER_SUMMARY,
      {
        params: brokerSummaryRequest,
      }
    );
  };

  public getGrossProfitGraphData = async (
    grossProfitGraphRequest: IProfitDashboardType
  ) => {
    return axiosClient.get<IApiBaseResponse<IGraphData>>(
      RoutesEnum.PROFIT_DASHBOARD_GROSSPROFIT_GRAPH,
      {
        params: grossProfitGraphRequest,
      }
    );
  };

  public getBrokerCommissionGraphData = async (
    brokerCommissionGraphRequest: IProfitDashboardType
  ) => {
    return axiosClient.get<IApiBaseResponse<IGraphData>>(
      RoutesEnum.PROFIT_DASHBOARD_BROKER_COMMISSION_GRAPH,
      {
        params: brokerCommissionGraphRequest,
      }
    );
  };

  public getGrossProfitPeriodBreakup = async (
    grossProfitPeriodBreakupRequest: IProfitDashboardBaseType
  ) => {
    return axiosClient.get<IApiBaseResponse<IPeriodBreakup>>(
      RoutesEnum.PROFIT_DASHBOARD_GROSSPROFIT_PERIOD_BREAKUP,
      {
        params: grossProfitPeriodBreakupRequest,
      }
    );
  };

  public getBrokerCommissionPeriodBreakup = async (
    brokerCommisionPeriodBreakupRequest: IProfitDashboardBaseType
  ) => {
    return axiosClient.get<IApiBaseResponse<IPeriodBreakup>>(
      RoutesEnum.PROFIT_DASHBOARD_GROSSPROFIT_BROKER_COMMISSION_PERIOD_BREAKUP,
      {
        params: brokerCommisionPeriodBreakupRequest,
      }
    );
  };

  public getTopEvents = async (topEventsRequest: IProfitDashboardBaseType) => {
    return axiosClient.get<IApiBaseResponse<ITopEvents>>(
      RoutesEnum.PROFIT_DASHBOARD_TOP_EVENTS,
      {
        params: topEventsRequest,
      }
    );
  };
}

export { ProfitDashboardService };
