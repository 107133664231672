import { ISwimlaneMaster } from "models/interfaces";

const KanbanLaneSortForm: React.FC<{
    swimlaneData: ISwimlaneMaster;
    currentSortOption: {sort_key: string; sort_order: string}
    doClose: Function;
    setCurrentSortOption: Function;
    setShowRedDot: Function;
}> = ({
    swimlaneData,
    currentSortOption,
    doClose,
    setCurrentSortOption,
    setShowRedDot,
}) => {
        const changeSort = (filed_sort_key: string, filed_sort_data_type: string) => {
            let _sort_order = 'asc';
            if(filed_sort_key === currentSortOption.sort_key){
                _sort_order = currentSortOption.sort_order === 'asc' ? 'desc' : 'asc';
            }

            setCurrentSortOption({
                sort_key: filed_sort_key,
                sort_data_type: filed_sort_data_type,
                sort_order: _sort_order
            });
            setShowRedDot(true);
            doClose();
        };

        const getSortIcon = (current_sort_key: string, filed_sort_key: string, current_sort_order: string) => {
            if(current_sort_key === filed_sort_key && current_sort_order === 'asc'){
                return <i className="da icon-sort-white-desc"></i>
            }
            else if(current_sort_key === filed_sort_key && current_sort_order === 'desc'){
                return <i className="da icon-sort-white-asc"></i>
            }
            else{
                return <i className="da icon-sort-white"></i>
            }
        }

        return (
            <div
                className="dropdownFilterWrap kanbanLaneFilterWrap sortingDd"
                style={{ minWidth: "300px" }}
            >
                <div className="dropdownFilterHeader">
                    <span>SORT OPTIONS</span>
                    <span className="da icon-close-white" onClick={() => doClose()}></span>
                </div>

                <div className="dark">
                    <ul>
                        {swimlaneData.sort_list?.map(eachSortData => {
                            return (
                                <li onClick={()=> changeSort(eachSortData.sort_key, eachSortData.sort_data_type)}>
                                    <div className={eachSortData.sort_key === currentSortOption.sort_key ? 'sortOption active' : 'sortOption'}>
                                        {eachSortData.sort_name} {getSortIcon(currentSortOption.sort_key, eachSortData.sort_key, currentSortOption.sort_order)}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        );
    };

export default KanbanLaneSortForm;
