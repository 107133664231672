import React from 'react'
import { usePageTitle } from 'hooks';
import { Layout } from 'antd';
import UserListingTable from './UserListingTable';
import { ReportLayout } from 'views';

const Users : React.FC = () => {
    usePageTitle("User Management");
    return (
        <React.Fragment>
            <div className="mainHeading">
                <div>
                    <h2>User Management</h2>
                </div>
            </div>
            <ReportLayout>
                <Layout className="mainInnerLayout reduceTopSpace pb-0">
                    <UserListingTable/>
                </Layout>
            </ReportLayout>
        </React.Fragment>
    )
}

export default Users
