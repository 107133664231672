import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import { GrossProfitCancelledService } from "services";
import CommonReportTable from "utils/table/CommonReportTable";
import { grossProfitCancelledHeaders } from "./grossProfitCancelledUtils";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { LoggerService } from "sharedServices/api/logger.service";
import { GrossProfitData, IFilterOptions } from "models/interfaces";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";
import { formatReportCount, formatThousandSeparator, getHeaderData } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const GrossProfitTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
}> = ({ filterObject, appliedFilterValues, searchQuery }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "pl_date",
    sort_order: "desc",
    keywords: "",
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [grossProfit, setGrossProfit] = useState<GrossProfitData | null>(null);
  const [reportData, setReportData] = useState<any | null>();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  useEffect(() => {
    if (queryParams != null) {
      const getGrossProfitCancelledData = async (query: any) => {
        setShowLoader(true);
        try {
          let response;
          let responseData;

          response = await new GrossProfitCancelledService().getGrossProfitData(
            {
              ...query,
            }
          );

          responseData = response.data.data;
          responseData.grossProfitCancelledReport = handleHighlightedRow(
            response.data.data.grossProfitCancelledReport
          );
          setReportData(responseData.grossProfitCancelledReport);

          setShowLoader(false);

          setGrossProfit(responseData);
          setDataFetchError(null);
        } catch (error: any) {
          setShowLoader(false);
          await new LoggerService().log({
            payload: error,
            function_name: "getGrossProfitData",
          });
          setDataFetchError({
            ...error,
            refreshAction: getGrossProfitCancelledData,
          });
        }
      };
      getGrossProfitCancelledData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      });
    }
  }, [searchQuery]);

  const handleHighlightedRow = (responseReportData: any) => {
    if (responseReportData) {
      responseReportData = responseReportData.map((eachReportData: any) => {
        return {
          ...eachReportData,
          activeSelectedClass: +eachReportData?.has_row_alert
            ? "redSelected"
            : null,
        };
      });
    }

    return responseReportData;
  };

  const setData = () => {
    if (grossProfit) {
      return reportData;
    }
    return null;
  }

  return (
    <React.Fragment>
      {dataFetchError ? (
        <CustomError {...dataFetchError} />
      ) : (
        queryParams && (
          <Content className="mainContainer">
            <div className="innerHeader">
              <Row className="flx justifyContentBetween alignItemsCenter">
                <Col span={14}>
                  <AppliedFilters appliedFilters={appliedFilterValues} />
                </Col>
                <Col span={10}>
                  {Boolean(!showLoader && grossProfit && grossProfit?.total) && (
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>
                          {formatThousandSeparator(grossProfit?.total ?? 0)}
                          {formatReportCount(grossProfit?.total ?? 0)}
                        </h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          hideOnSinglePage={true}
                          current={queryParams?.page_index}
                          total={grossProfit?.total}
                          pageSize={queryParams?.page_size}
                          onChange={(pageNumber) =>
                            setQueryParams({
                              ...queryParams,
                              page_index: pageNumber,
                            })
                          }
                          showSizeChanger={false}
                        />
                      </li>
                    </ul>
                  )}
                  {!Boolean(!showLoader && grossProfit && grossProfit?.total) && (
                    <div style={{ height: 21 }}></div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="removeTblLRSpc">
              <CommonReportTable
                onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                  setQueryParams({
                    ...queryParams,
                    sort_column: sortIndex,
                    sort_order: sortOrder,
                  });
                }}
                sortField={{
                  dataIndex: queryParams?.sort_column,
                  order: queryParams?.sort_order,
                }}
                headers={grossProfitCancelledHeaders}
                data={setData()}
                headerData={getHeaderData(grossProfit, "pl_date")}
                tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                loading={showLoader}
              />
            </div>
          </Content>
        )
      )}
    </React.Fragment>
  );
};

export default GrossProfitTable;
