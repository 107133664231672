export const getCharCountByCardSize = (ref: any) => {
  if (ref.current) {
    if (window.innerWidth < 1600) {
      return parseInt((ref.current.offsetWidth / 8).toString());
    } else {
      return parseInt((ref.current.offsetWidth / 7).toString());
    }
  } else {
    return 30;
  }
};
