import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Spin } from "antd";
import ReportCategories from "./ReportCategories";
import { IReportDashboardCategoriesData } from "models/interfaces";
import CustomError from "controls/CustomError/CustomError";
import { ReportDashboardService } from "services";
import { ICustomError } from "models/interfaces/ICustomError";
import { LoggerService } from "sharedServices/api/logger.service";
import { usePageTitle } from "hooks";
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch, } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";

const { Content } = Layout;
const loaderIcon = <LoadingOutlined spin />;
const AllReportsPage: React.FC = () => {
  usePageTitle("Reports");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.getDateRangeFilterData());
  }, [dispatch]);

  const [reportCategoriesData, setReportCategoriesData] =
    useState<IReportDashboardCategoriesData | null>(null);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const [categoryFailedFetch, setCategoryFailedFetch] =
    useState<ICustomError | null>(null);
  const favoriteCategoryId = 1;

  async function getReportsData() {
    try {
      setIsServiceLoading(true);
      const reportDashboardResponseData =
        await new ReportDashboardService().getAllReports();
      setReportCategoriesData(reportDashboardResponseData.data.data);

      setIsServiceLoading(false);
      setCategoryFailedFetch(null);
    } catch (error: any) {
      setIsServiceLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getReportsData",
      });
      setCategoryFailedFetch({ ...error, refreshAction: getReportsData });
    }
  }

  const hasFavorite = (itemId: number): boolean => {
    if (reportCategoriesData) {
      const favoritesCategoryList = reportCategoriesData.category.find(
        (category) => category.category_id === favoriteCategoryId
      );
      if (
        favoritesCategoryList &&
        favoritesCategoryList.reports.find((item) => item.id === itemId)
      ) {
        return true;
      }
    }
    return false;
  };

  const favoriteHandler = async (id: number) => {
    const favArray = reportCategoriesData?.category.filter(
      (element) => element.category_id === favoriteCategoryId
    );
    let isFavorite;
    if (favArray?.length) {
      isFavorite =
        favArray[0].reports.filter((element: any) => element.id === id).length >
        0
          ? true
          : false;
    } else {
      isFavorite = false;
    }

    try {
      const operationType = isFavorite ? 2 : 1;
      await new ReportDashboardService().addRemoveFavorite(
        id,
        operationType,
        favoriteCategoryId
      );
      getReportsData();
    } catch (error) {
      setIsServiceLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getReportsData();
  }, []);

  return (
    <React.Fragment>
      <h2>Reports</h2>
      <Layout className="mainInnerLayout">
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row>
              <Col span={24}>
                <h3>
                  <span>All Reports{" "}{isServiceLoading && <Spin indicator={loaderIcon} />}</span>
                </h3>
              </Col>
            </Row>
          </div>
          {categoryFailedFetch ? (
            // <CustomError code={categoryFailedFetch?.code} message ={categoryFailedFetch.message} refreshAction={getReportsData } />
            <CustomError {...categoryFailedFetch} />
          ) : (
            reportCategoriesData?.category.map((category) => (
              <ReportCategories
                key={category?.category_id}
                categoryName={category?.category_name}
                categoryIcon={category?.category_icon}
                categoryId={category?.category_id}
                reports={category?.reports}
                favoriteUnfavoriteReport={favoriteHandler}
                hasFavorite={hasFavorite}
              ></ReportCategories>
            ))
          )}
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default AllReportsPage;
