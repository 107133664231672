import React, { useRef, useState } from "react";
import { Button, Calendar, message, Modal, Tooltip, Typography } from "antd";
import {
  IPurchaseQueueMasterData,
  IPurchaseQueueMenuOption,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import { addToolTip, daysToEventColorCheck, titleCase } from "utils/commonFunction";
import InvoiceDetailModal from "views/KanbanBoard/KebabMenu/SubMenuComponents/InvoiceDetail/InvoiceDetailModal";
import KebabMenu from "views/KanbanBoard/KebabMenu/KebabMenu";
import { formatDateGivenFormatString, getCustomDate, getDateDiff, getDateObject, getOffsetDate } from "utils/formatTimePeriod";
import { AppSetting } from "utils/appSettings";
import { getCharCountByCardSize } from "../KanbanBoard.utils";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { dateType } from "models/types";
import { LoggerService } from "sharedServices";
import { PurchaseAndShippingService } from "services";
import { Moment } from "moment";
import NotesHistory from "../KebabMenu/SubMenuComponents/NotesHistory";

const SwimlaneShippingCard: React.FC<{
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  kebabMenuOptions: IPurchaseQueueMenuOption[] | null;
}> = ({
  eachInvoiceData,
  swimlaneData,
  requestToReloadTheLanes,
  purchaseQueueMaster,
  kebabMenuOptions,
}) => {
  const [ifShowInvoiceDetailModal, setIfShowInvoiceDetailModal] =
    useState<boolean>(false);

  const [showNotesHistory, setShowNotesHistory] = useState(false);

  const [issueReminderDate, setIssueReminderDate] = useState<dateType>(
    eachInvoiceData.issues_reminder_date
      ? eachInvoiceData.issues_reminder_date
      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
  );

  const [showIssueReminderDatePicker, setShowIssueReminderDatePicker] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getZoneAvailabilityClass = (zoneAvailabilityId: number) => {
    let className = "purchaseCardTopBar ";
    if (zoneAvailabilityId === 0) {
      className = className + "none";
    } else if (zoneAvailabilityId === 1) {
      className = className + "success";
    } else if (zoneAvailabilityId === 2) {
      className = className + "warning";
    } else if (zoneAvailabilityId === 3) {
      className = className + "danger";
    } else if (zoneAvailabilityId === 4) {
      className = className + "tbd";
    }

    return className;
  };

  const getDaysToEventTagJsx = () => {
    if ([5, 6, 7, 8].includes(swimlaneData.id)) {
      return (
        <div
          className={
            "rankingInner " +
            daysToEventColorCheck(eachInvoiceData.days_to_event, "grid")
              .className
          }
        >
          <Tooltip
            placement="top"
            title={
              daysToEventColorCheck(eachInvoiceData.days_to_event, "grid")
                .toolTipText
            }
          >
            <span className="rankNumber">{eachInvoiceData.days_to_event}d</span>
          </Tooltip>

          <div>To event</div>
        </div>
      );
    }
  };

  const getEventNameJsx = () => {
    return (
      <Typography.Link
        className="extarnalLink"
        href={AppSetting.EVENT_URL + eachInvoiceData?.event_id}
        target="_blank"
      >
        {eachInvoiceData?.event_name.length > getCharCountByCardSize(ref)
          ? addToolTip(
              eachInvoiceData?.event_name,
              "top",
              "pointer",
              getCharCountByCardSize(ref),
              false,
              { textTransform: "capitalize" }
            )
          : eachInvoiceData?.event_name}
      </Typography.Link>
    );
  };

  const getVenueUrlCta = () => {
    if (eachInvoiceData.venue_url) {
      return (
        <Typography.Link
          className="extarnalLink"
          href={eachInvoiceData.venue_url}
          target="_blank"
        >
          <Tooltip title="Go to primary website address">
            <i className="da icon-external-link"></i>
          </Tooltip>
        </Typography.Link>
      );
    }
    return "";
  };

  const getInvoiceIdCta = () => {
    return (
      <Typography.Link
        className="extarnalLink"
        href={AppSetting.INVOICE_ID_URL + eachInvoiceData.invoice_id}
        target="_blank"
      >
        <Tooltip title="Go to Skybox">
          <i className="da icon-external-link"></i>
        </Tooltip>
      </Typography.Link>
    );
  };

  const getEventDateTimeJsx = () => {
    return (
      <div className="invoiceCardEventDateTime">
        {`${formatDateGivenFormatString(
          eachInvoiceData.event_date_and_time,
          "ddd, MMM DD, YYYY"
        )} at ${formatDateGivenFormatString(
          eachInvoiceData.event_date_and_time,
          "h:mm A"
        )}`}
      </div>
    );
  };

  const getDaysFromInHandDateTagJsx = () => {
    if (
      [5, 6, 7, 8].includes(swimlaneData.id) &&
      eachInvoiceData.days_from_in_hand_date > 0
    ) {
      return (
        <div className="rankingInner liteGreen">
          <span className="rankNumber">
            {eachInvoiceData.days_from_in_hand_date}d
          </span>
          <div>In-hand</div>
        </div>
      );
    }
  };

  const handleIssueReminderChange = (newValue: Moment) => {
    setIssueReminderDate(newValue?.format("YYYY-MM-DD"));
  };

  const updateIssueReminder = async () => {
    const saveIssueReminder = "saveIssueReminder";
    setIsLoading(true);
    try {
      const dateDiff = getDateDiff(
        getCustomDate(issueReminderDate),
        eachInvoiceData.event_date
      );
      if (dateDiff && dateDiff > 0) {
        setIsLoading(false);
        message.error({
          content: "Reminder date cannot exceed event date.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
      } else {
        await new PurchaseAndShippingService().saveIssueReminder({
          invoice_id: eachInvoiceData.invoice_id,
          reminder_date: issueReminderDate,
        });
        setIsLoading(false);
        setShowIssueReminderDatePicker(false);
        message.success({
          content: `Issue Reminder for Invoice# ${eachInvoiceData.invoice_id} updated successfully.`,
          duration: 5,
          key: saveIssueReminder,
          className: "toastMsg savedSuccess",
        });
        requestToReloadTheLanes([swimlaneData.id]);
      }
    } catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "updateIssueReminder",
      });
      message.error({
        key: saveIssueReminder,
        content: "Failed to update issue reminder.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      setIsLoading(false);
      setShowIssueReminderDatePicker(false);
    }
  };

  const getIssuesReminderTagJsx = () => {
    if ([7].includes(swimlaneData.id)) {
      return (
        <React.Fragment>
          <div
            className={`rankingInner ${
              showIssueReminderDatePicker ? "" : "error"
            }`}
          >
            <span
              className="rankNumber issueReminderTag"
              onClick={() => setShowIssueReminderDatePicker((prev) => !prev)}
            >
              {eachInvoiceData.issues_reminder_date_formatted}
            </span>
            <div
              className="issueReminderLabel"
              onClick={() => setShowIssueReminderDatePicker((prev) => !prev)}
            >
              Reminder
            </div>
          </div>
          <Modal
            closable={false}
            width={300}
            title={false}
            visible={showIssueReminderDatePicker}
            centered
            bodyStyle={{ minHeight: 300 }}
            destroyOnClose={true}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setShowIssueReminderDatePicker(false);
                  setIssueReminderDate(
                    eachInvoiceData.issues_reminder_date
                      ? eachInvoiceData.issues_reminder_date
                      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                  );
                }}
                disabled={isLoading}
                className="discardBtn"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={updateIssueReminder}
              >
                Save
              </Button>,
            ]}
          >
            <Calendar
              fullscreen={false}
              className="datePickerCal"
              onSelect={handleIssueReminderChange}
              value={getCalendarIssueDateValue()}
              validRange={[
                getDateObject(
                  getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                ),
                getDateObject(eachInvoiceData.event_date),
              ]}
            />
          </Modal>
        </React.Fragment>
      );
    }
  };

  const getCalendarIssueDateValue = () => {
    if (issueReminderDate) {
      return getDateObject(issueReminderDate);
    } else if (eachInvoiceData.issues_reminder_date) {
      return getDateObject(eachInvoiceData.issues_reminder_date);
    } else {
      return getDateObject(getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS));
    }
  };

  const getInvoiceNotesHistory = () => {
    return (
      <NotesHistory
      eachInvoiceData={eachInvoiceData}
      />
    );
  };

  const getShipperAssigneeJsx = () => {
    if (eachInvoiceData?.shipper_name) {
      return (
        <div>
          <Tooltip title="Shipper">
            <i className="da icon-user-role" />
          </Tooltip>
          <Tooltip title={eachInvoiceData?.inactive_shipper? <s>{eachInvoiceData.shipper_name}</s> : eachInvoiceData.shipper_name}>
            <span>{eachInvoiceData?.inactive_shipper ? <s>{eachInvoiceData.shipper_name_initial}</s> : eachInvoiceData.shipper_name_initial}.</span>
          </Tooltip>
        </div>
      );
    }
    return (
      <div>
        <Tooltip title="Shipper">
          <i className="da icon-user-role" />
        </Tooltip>
        <Tooltip title={"Unknown"}>
          <span>{"Unknown"}</span>
        </Tooltip>
      </div>
    );
  };

  const getBrokerAssigneeJsx = () => {
    if (eachInvoiceData?.broker_name?.length) {
      return (
        <div>
          <Tooltip title="Broker">
            <i className="da icon-user-o" />
          </Tooltip>
          <Tooltip title={eachInvoiceData.broker_name?.toString()}>
            <span>{eachInvoiceData.broker_name_initial?.toString()}</span>
          </Tooltip>
        </div>
      );
    }
    return (
      <div>
        <Tooltip title="Broker">
          <i className="da icon-user-o" />
        </Tooltip>
        <Tooltip title={"Unknown"}>
          <span>{"Unknown"}</span>
        </Tooltip>
      </div>
    );
  };

  const getIssueReasonJsx = () => {
    if ([7].includes(swimlaneData.id) && eachInvoiceData.shipping_issue_reason) {
      return (
        <Tooltip
          title={eachInvoiceData.notes ? eachInvoiceData.notes : ""}
          placement={"right"}
        >
          <span className="ant-tag danger tagSmall issueReason" style={{margin: '3px 0'}}>
            {eachInvoiceData.shipping_issue_reason
              ? `${eachInvoiceData.shipping_issue_reason}`
              : ""}
          </span>
        </Tooltip>
      );
    }
  };

  const getInvoiceTagJsx = () => {
    if([5,6,8].includes(swimlaneData.id) && eachInvoiceData.invoice_tags) {
      return eachInvoiceData.invoice_tags?.split(' ').map((each)=>{
        return (
          <span className="ant-tag success tagSmall laneTag" style={{margin: '3px 0'}}>
            {each}
          </span>
        )
      });
    }
  };

  const getFulfillmentStatusJsx = () => {
    if([5,6,7,8].includes(swimlaneData.id)) {
      return (
        <div>
          Fulfillment Status:{" "}
          <strong>{titleCase(eachInvoiceData.fulfillment_status)}</strong>
        </div>
      );
    }
  };

  const getTbTagJsx = () => {
    if([6,8].includes(swimlaneData.id) && eachInvoiceData.buyer_id) {
      return (
        <div>
          <span className="ant-tag warning tagSmall issueReason" style={{margin: '3px 0'}}>
            TB2.0
          </span>
        </div>
      );
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="purchaseCard" ref={ref}>
      <div
        className={getZoneAvailabilityClass(
          eachInvoiceData.zone_availability_id
        )}
      />
      <div className="purchaseCardTop">
        <div className="purchaseCardTopContents">
          {eachInvoiceData.is_high_priority === true && (
            <Tooltip title="High priority">
              <span className="isHighPriority">!&nbsp;</span>
            </Tooltip>
          )}
          <Typography.Link
            target={"_blank"}
            rel={"noreferrer"}
            className="purcheseId"
            onClick={() => {
              setIfShowInvoiceDetailModal(!ifShowInvoiceDetailModal);
            }}
          >
            {eachInvoiceData.invoice_id}
          </Typography.Link>
          {getInvoiceIdCta()}
          {(eachInvoiceData.show_notes_history || showNotesHistory) && getInvoiceNotesHistory()}
          <InvoiceDetailModal
            ifShowInvoiceDetailModal={ifShowInvoiceDetailModal}
            setIfShowInvoiceDetailModal={setIfShowInvoiceDetailModal}
            invoiceId={eachInvoiceData.invoice_id}
          />
        </div>
        <KebabMenu
          swimlaneData={swimlaneData}
          eachInvoiceData={eachInvoiceData}
          requestToReloadTheLanes={requestToReloadTheLanes}
          purchaseQueueMaster={purchaseQueueMaster}
          kebabMenuOptions={kebabMenuOptions}
          setShowNotesHistory={setShowNotesHistory}
        />
      </div>
      {getInvoiceTagJsx()}
      {getIssueReasonJsx()}
      {getTbTagJsx()}
      {getFulfillmentStatusJsx()}
      <div className="purchaseCardMid">
        {getEventNameJsx()}
        {getVenueUrlCta()}
        {getEventDateTimeJsx()}
      </div>
      <div className="purcheseTags">
        <div className="ranking">
          {getDaysToEventTagJsx()}
          {getDaysFromInHandDateTagJsx()}
          {getIssuesReminderTagJsx()}
        </div>
      </div>
      <div className="eventCardFooter">
        {getBrokerAssigneeJsx()}
        {getShipperAssigneeJsx()}</div>
    </div>
  );
};

export default SwimlaneShippingCard;
