import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { ILoggerData } from "models/interfaces/ILogger";
import axiosClient from "sharedServices/api/axios.services";


class LoggerService {
  public log = async (loggerData: ILoggerData) => {

    let msg = 'Error from Frontend';

    if (loggerData.payload instanceof Error) {
        msg = loggerData.payload.message;
    }
    let logData: ILoggerData = {
        type: loggerData.type ? loggerData.type : 'Error',
        message: loggerData.message ? loggerData.message : msg,
        stage: process.env.REACT_APP_ENVIRONMENT,
        function_name: loggerData.function_name ? loggerData.function_name : '',
        db_query: '',
        user_agent: window.navigator.userAgent,
        severity: loggerData.severity ? loggerData.severity : 'Low',
        payload: loggerData.payload ? loggerData.payload : ''
    }

    return axiosClient.post<IApiBaseResponse<boolean>>(
      RoutesEnum.LOG,
      {
        ...logData,
      }
    );
  };


}

export { LoggerService };