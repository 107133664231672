import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { usePageTitle } from "hooks";
import { IdleTimerProvider } from "react-idle-timer";
import {
  decodeQueryStringData,
  getEncodedData,
  urlQueryParamsToObject,
} from "utils/commonFunction";
import CreateNewZoneListingTable from "./CreateNewZoneListingTable";
import { Button } from "antd";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { IEventMetadata, IListingFormData } from "models/interfaces";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { Link } from "react-router-dom";
import { AppSetting } from "utils/appSettings";

const CreateNewZoneListingPage: React.FC = () => {
  usePageTitle("Create New Listing");
  const location = useLocation();
  const {hasPermissions} = useRbac();
  const [requestedEventId, setRequestedEventId] = useState<number | null>(null);
  const [zones, setZones] = useState<IListingFormData[]>([]);
  const [eventMetadata, setEventMetadata] = useState<IEventMetadata | null>(
    null
  );
  const [isLoadingData, setIsLoadingData] = useState(false);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes("event")) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.event) {
        let filterQueryString = decodeQueryStringData(queryObject.event);
        if (filterQueryString.event_id) {
          setRequestedEventId(filterQueryString.event_id);
        }
      }
      dispatch(actions.getSplitTypeData());
    }
  }, []);

  const getSelectedZones = () => {
    const zone_sections = zones.filter(eachZone => eachZone.is_selected).map(eachZone => eachZone.zone_section);
    return zone_sections;
  };

  return (
    <React.Fragment>
      <div className="mainHeading">
        <div>
          <h2>Create New Listing</h2>
        </div>
        <div className="flx alignItemsCenter">
          <Link to={LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_BULK_PLACEMENT +
                  "?event=" +
                  getEncodedData({event_id: requestedEventId, zone_sections: getSelectedZones()})}>
            <Button
              className="btnPrimary btnBulkPlacement" 
              disabled={isLoadingData || eventMetadata?.zone?.length === 0 || !hasPermissions([ERbacPermissions.EVENT_BULK_CREATE_NEW_LISTING])}>
                Bulk Placement
            </Button>
          </Link>
        </div>
      </div>
      {requestedEventId && (
        <IdleTimerProvider
          timeout={1000 * AppSetting.IDLE_TIME_TIMEOUT_IN_SECONDS}
        >
          <CreateNewZoneListingTable
            zones={zones}
            setZones={setZones}
            requestedEventId={requestedEventId}
            eventMetadata={eventMetadata}
            setEventMetadata={setEventMetadata}
            isLoadingData={isLoadingData}
            setIsLoadingData={setIsLoadingData}
          />
        </IdleTimerProvider>
      )}
    </React.Fragment>
  );
};

export default CreateNewZoneListingPage;
