import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import {
  GrossProfitService,
  BrokerTagsService,
  ConsignmentSalesService,
  ShortsPendingService,
  ConsignmentPendingBuyInService,
} from "services";
import CommonReportTable from "utils/table/CommonReportTable";
import { grossProfitHeaders } from "./grossProfitUtils";
import { useLocation } from "react-router-dom";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { LoggerService } from "sharedServices/api/logger.service";
import { GrossProfitData, IFilterOptions } from "models/interfaces";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";
import { usePageTitle } from "hooks";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const GrossProfitTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
  outerLoader: boolean;
  setOuterLoader: Function;
}> = ({ 
  filterObject, 
  appliedFilterValues, 
  searchQuery,
  outerLoader,
  setOuterLoader
}) => {
  const pathName = useLocation().pathname;

  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    // client_zone: AppSetting.TIMEZONE,
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "pl_date",
    sort_order: "desc",
    keywords: "",
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [grossProfit, setGrossProfit] = useState<GrossProfitData | null>(null);
  const [reportData, setReportData] = useState<any | null>();
  const [title, setTitle] = useState<string>();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  usePageTitle(title);

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  useEffect(() => {
    if (queryParams != null) {
      const getGrossProfitData = async (query: any) => {
        setOuterLoader(false);
        setShowLoader(true);
        try {
          let response;
          let responseData;

          if (pathName.endsWith("issues")) {
            document.title = "Broker Tags Issues";
            setTitle("Broker Tags Issues");
            response = await new BrokerTagsService().getBrokerTagsData({
              ...query,
            });
            responseData = response.data.data;
            responseData.brokerTagsIssuesReports = handleHighlightedRow(
              response.data.data.brokerTagsIssuesReports
            );
            setReportData(responseData.brokerTagsIssuesReports);
          } else if (pathName.endsWith("sales")) {
            setTitle("Consignment Sales");
            response =
              await new ConsignmentSalesService().getConsignmentSalesData({
                ...query,
              });
            responseData = response.data.data;
            responseData.consignmentSalesReports = handleHighlightedRow(
              response.data.data.consignmentSalesReports
            );
            setReportData(responseData.consignmentSalesReports);
          } else if (pathName.endsWith("delivery")) {
            setTitle("Orders Pending Delivery");
            response = await new ShortsPendingService().getShortsPendingData({
              ...query,
            });
            responseData = response.data.data;
            responseData.shortsPendingReports = handleHighlightedRow(
              response.data.data.shortsPendingReports
            );
            setReportData(responseData.shortsPendingReports);
          } else if (pathName.endsWith("in")) {
            setTitle("Consigment Pending Buy-in");
            response =
              await new ConsignmentPendingBuyInService().getConsignmentPendingBuyInData(
                {
                  ...query,
                }
              );
            responseData = response.data.data;
            responseData.consignmentPendingBuyInReports = handleHighlightedRow(
              response.data.data.consignmentPendingBuyInReports
            );
            setReportData(responseData.consignmentPendingBuyInReports);
          } else {
            setTitle("Gross Profit");
            response = await new GrossProfitService().getGrossProfitData({
              ...query,
            });
            responseData = response.data.data;
            responseData.grossProfitsReports = handleHighlightedRow(
              response.data.data.grossProfitsReports
            );
            setReportData(responseData.grossProfitsReports);
          }

          setShowLoader(false);

          setGrossProfit(responseData);
          setDataFetchError(null);
        } catch (error: any) {
          setShowLoader(false);
          await new LoggerService().log({
            payload: error,
            function_name: "getGrossProfitData",
          });
          setDataFetchError({ ...error, refreshAction: getGrossProfitData });
        }
      };
      getGrossProfitData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      });
    }
  }, [searchQuery]);

  const handleHighlightedRow = (responseReportData: any) => {
    if (responseReportData) {
      responseReportData = responseReportData.map((eachReportData: any) => {
        return {
          ...eachReportData,
          activeSelectedClass: eachReportData?.has_row_alert
            ? "redSelected"
            : null,
        };
      });
    }

    return responseReportData;
  };

  const getViewTable = () => {
    if (!showLoader && grossProfit && grossProfit?.total) {
      return (
        <ul className="innerHeaderRight">
          <li>
            <h4>
              {grossProfit?.total === 1
                ? grossProfit?.total + " Record Found"
                : formatThousandSeparator(grossProfit?.total) + " Records Found"}
            </h4>
          </li>
          <li>
            <Pagination
              className="pagination"
              hideOnSinglePage={true}
              current={queryParams?.page_index}
              total={grossProfit?.total}
              pageSize={queryParams?.page_size}
              onChange={(pageNumber) =>
                setQueryParams({
                  ...queryParams,
                  page_index: pageNumber,
                })
              }
              showSizeChanger={false}
            />
          </li>
        </ul>
      );
    } 
    return (
      <div style={{ height: 21 }}></div>
    );
  }

  return (
    <LoadingSpinner isLoading={outerLoader}>
      <React.Fragment>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          queryParams && (
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="flx justifyContentBetween alignItemsCenter">
                  <Col xs={24} sm={24} md={14} className="gpAppliedFilter">
                    <AppliedFilters appliedFilters={appliedFilterValues} />
                  </Col>
                  <Col  xs={24} sm={24} md={10}>
                    { getViewTable() }
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <CommonReportTable
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    setQueryParams({
                      ...queryParams,
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                    });
                  }}
                  sortField={{
                    dataIndex: queryParams?.sort_column,
                    order: queryParams?.sort_order,
                  }}
                  headers={grossProfitHeaders}
                  data={(() => { return grossProfit ? reportData : null })()}
                    headerData={grossProfit?.aggregate.map((data) => {
                        return { ...data, pl_date: "Total" };
                      })
                    }
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  loading={showLoader}
                />
              </div>
            </Content>
          )
        )}
      </React.Fragment>
    </LoadingSpinner>
  );
};

export default GrossProfitTable;
