import {Fragment} from "react";
import { Route,RouteProps } from "react-router";
import { Switch } from "react-router-dom";
import { AuthenticateRoute, AnonymousRoute } from "./RouteConfig";
import { routes } from "./OtherRoute";
import { NotFound} from "./NotFound";
const Routes: React.FC<RouteProps> = () => {
  return (
    <Fragment>
      <Switch>
        {routes?.map((route) =>
          route.auth ? (
            <AuthenticateRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
              allowedPermissions={route.allowedPermissions}
            />
          ) : (
            <AnonymousRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          )
        )}
        <Route component={NotFound} ></Route>
      </Switch>
    </Fragment>
  );
};

export default Routes;
