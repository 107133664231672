import { Modal, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
    eventManagementActions,
    eventMetadata,
    isLoadingMetadata  } from "models/Slices/EventManagementSlice";
import React, { useEffect } from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip, titleCase } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import EditEventAvailability from "../EditEventAvailability";

const EventQuickUpdateModal: React.FC<{
    eventId: number;
    hideModal: Function;
}> = ({ eventId, hideModal }) => {
    const dispatch = useAppDispatch();
    const metadata = useAppSelector(eventMetadata);
    const loadingMetadata = useAppSelector(isLoadingMetadata);

    const fetchMetadata = () => {
        dispatch(eventManagementActions.getEventMetadata({
          event_id: eventId,  
          functionName: fetchMetadata.toString(),
        }));
    };

    useEffect(()=>{
        fetchMetadata();
    }, [])

    return (
        <React.Fragment>

            <Modal
                closable={false}
                width={1000}
                title={false}
                footer={false}
                visible={true}
                centered
                className="editModal"
            >
                <div className="modalHeader">
                    {/* <div className="modalHeadingMain">Purchase Assignment Form</div> */}
                    {!loadingMetadata && metadata !== null &&
                    <div className="modalHeaderChild eventDt">
                        <div className="eventDtLft">
                            <a
                                target={"_blank"}
                                rel="noopener noreferrer"
                                href={
                                    AppSetting.EVENT_URL +
                                    metadata.event_id
                                }
                            >
                                {metadata.event_name && metadata.event_name.length > 40 ?
                                    addToolTip(
                                        `${titleCase(metadata.event_name)}`, "right", "pointer", 37) :
                                    `${titleCase(metadata.event_name)}`}
                            </a>
                            {Boolean(metadata.event_date) &&
                                <span>
                                    {` (${formatDateGivenFormatString(
                                        metadata.event_date,
                                        "ddd, MMM DD, YYYY hh:mm A"
                                    )})`}
                                </span>
                            }
                        </div>
                        <div>
                            {Boolean(metadata.venue_location) && (
                                <React.Fragment>
                                    {(metadata.venue_name + ", " + metadata.venue_location).length > 60 ?
                                        addToolTip(
                                            `${titleCase(metadata.venue_name)}, ${metadata.venue_location}`, "right", "default", 57
                                        ) :
                                        `${titleCase(metadata.venue_name)}, ${metadata.venue_location}`
                                    }
                                </React.Fragment>
                            )}

                            {!Boolean(
                                metadata.venue_location) &&
                                Boolean(
                                    metadata.venue_name &&
                                    metadata.venue_name.trim().toLowerCase() !== 'to be determined') && (
                                    <React.Fragment>
                                        {metadata.venue_name && metadata.venue_name.length > 60 ?
                                            addToolTip(titleCase(metadata?.venue_name), "right", "pointer", 57) :
                                            titleCase(metadata?.venue_name)
                                        }
                                    </React.Fragment>
                                )}
                        </div>
                        <div>
                            {
                                metadata?.venue_fees &&
                                <div>
                                    <Tooltip className="ml0" title='Fees have been manually added from Edit Venue screen'><i className="da icon-venue-fees" /></Tooltip>
                                    {`Venue Fees: $${metadata?.venue_fees}`}
                                </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                
                <div className="modalCustomBody">
                    <EditEventAvailability eventId={eventId} isFromQuickEdit={true} 
                    onCancelMetadataUpdate={()=>{
                        hideModal(false);
                    }} 
                    onSuccessMetadataUpdate={()=>{
                        hideModal(false);
                    }}
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default EventQuickUpdateModal;