import { RoutesEnum } from "models/enums";
import { GrossProfitData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class ConsignmentPendingBuyInService {
    public getConsignmentPendingBuyInData = async (consignmentPendingBuyInRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.CONSIGNMENT_PENDING_BUY_IN, {
            params: consignmentPendingBuyInRequest
        });
    };
}

export { ConsignmentPendingBuyInService };