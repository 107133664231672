import { Button, message, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  IIssuesData,
  IMenuOption,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { formatThousandSeparator } from "utils/commonFunction";

const shipping_issues_swimlane_id = 7;

const UpdateIssueShipping: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster,
}) => {
  const [issuesData, setIssuesData] = useState<IIssuesData>({
    invoice_id: eachInvoiceData.invoice_id,
    swimlane_id: shipping_issues_swimlane_id,
    issue_type: eachInvoiceData.shipping_issue_type,
    notes: eachInvoiceData.notes,
  });
  const [isValid, setIsValid] = useState(false);

  const issueTypeChangeHandler = (value: string) => {
    if (value)
      setIssuesData((prev) => ({
        ...prev,
        issue_type: parseInt(value),
      }));
  };
  const notesChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssuesData((prev) => ({
      ...prev,
      notes: e.currentTarget.value,
    }));
  };

  const cancelHandler = () => {
    setSelectedMenuId(null);
    setIssuesData((prev) => ({
      ...prev,
      issue_type: eachInvoiceData.shipping_issue_type,
      notes: eachInvoiceData.notes,
    }));
  };

  const updateIssuesData = async () => {
    const updateIssueKey = "updateIssueKey";
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Updating issue details for invoice...`,
      duration: 0,
      key: updateIssueKey,
      className: "toastMsg loadingMsg",
    });
    if (isValid) {
      const res = await new PurchaseAndShippingService().updateIssueShipping(issuesData);
      if (res.data.data) {
        setIsValid(false);
        message.success({
          content: `Issue details updated for invoice# ${eachInvoiceData.invoice_id}.`,
          duration: 5,
          key: updateIssueKey,
          className: "toastMsg savedSuccess",
        });
      } else {
        message.error({
          content: "Failed to update issue reason.",
          duration: 5,
          key: updateIssueKey,
          className: "toastMsg savedFailed",
        });
      }
      requestToReloadTheLanes([shipping_issues_swimlane_id]);
    }
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  useEffect(() => {
    if (
      issuesData?.issue_type === null ||
      issuesData?.issue_type === undefined ||
      (eachInvoiceData.shipping_issue_type === issuesData?.issue_type && eachInvoiceData.notes === issuesData?.notes)
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [issuesData]);

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <div className="moveOnSaleWrap">
          <div className="moveOnSaleRow">
            <Select
              defaultValue="Select"
              options={purchaseQueueMaster?.shippingIssuesTypeMasterData.map((each)=>({...each, value: each.value.toString()}))}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLInputElement
              }
              value={issuesData?.issue_type
                  ? issuesData?.issue_type.toString()
                  : "Select"
              }
              onChange={issueTypeChangeHandler}
            />
          </div>
          <div className="moveOnSaleRow">
            <TextArea
              placeholder="Note"
              className="moveToIssuesTxtArea"
              maxLength={1000}
              showCount={{
                formatter: ({ count, maxLength }) =>
                  maxLength ?
                    `${formatThousandSeparator((maxLength ?? 0) - count)} Remaining` :
                    "",
              }}
              rows={4}
              value={issuesData?.notes ? issuesData.notes : ""}
              onChange={notesChangeHandler}
              allowClear
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </div>
          <div className="moveOnSaleRow btnGroupFooter">
            <Button className="discardBtn" onClick={cancelHandler}>
              <span>CANCEL</span>
            </Button>
            <Button
              className="btnUpdate"
              disabled={!isValid}
              onClick={updateIssuesData}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateIssueShipping;
