import { ISplitDatum } from "models/interfaces";
import { addToolTip, twoDecimalPlacesNumber } from "utils/commonFunction";

const ActiveListingPoLineCard: React.FC<{
  splitData: ISplitDatum;
}> = ({ splitData }) => {
  
  const getZoneSeatingWrapLeftContent = () => {
    return (
      <div>
        <h2>{`$${twoDecimalPlacesNumber(splitData?.list_price)}`}</h2>
        <p>Max Cost: ${twoDecimalPlacesNumber(splitData?.cost_price)}</p>
        {splitData?.cost_price !== undefined &&
        splitData?.list_price !== undefined &&
        splitData?.cost_price > splitData?.list_price ? (
          <p className="text-danger">
            <strong>List Price less than Cost</strong>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  };

  const getQuantityJsx = () => {
    return (
      <div className="rankingInner yellow poCardQty">
        <span className="rankNumber">{splitData?.inventory_quantity}</span>
        <div>Qty</div>
      </div>
    );
  };

  const getSectionJsx = () => {
    if (splitData?.section && splitData?.section.length >= 10) {
      return addToolTip(splitData?.section, "right", "default", 20);
    }
    return splitData?.section;
  };

  const getRowJsx = () => {
    if (splitData?.row && splitData?.row.length > 3) {
      return addToolTip(splitData?.row, "right", "default", 3);
    }
    return splitData?.row;
  };

  const getSeatsJsx = () => {
    if (splitData?.seat_numbers && splitData?.seat_numbers.length >= 10) {
      return addToolTip(splitData?.seat_numbers, "right", "default", 10);
    }
    return splitData?.seat_numbers;
  };

  return (
    <div className="activeListingLast">
      <div className="eventCardWrap activeListing noTopbar splitCard">
        <div className="eventCardContent">
          <div className="zoneSeatingWrap">
            {getZoneSeatingWrapLeftContent()}
            <div className="zoneSeating">
              <span>
                Zone
                <br />
                Seating
              </span>
              {splitData?.section && <i className="da icon-checked-circle" />}
            </div>
          </div>

          <div className="eventCardBody">
            <div className="ranking">
              {getQuantityJsx()}
              <div className="rankingInner yellow">
                <span className="rankNumber">{getSectionJsx()}</span>
                <div>Section</div>
              </div>
              <div className="rankingInner yellow poCardRow">
                <span className="rankNumber">{getRowJsx()}</span>
                <div>Row</div>
              </div>
              <div className="rankingInner yellow">
                <span className="rankNumber">{getSeatsJsx()}</span>
                <div>Seats</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveListingPoLineCard;
