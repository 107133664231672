import { RoutesEnum } from "models/enums";
import { GrossProfitData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class ConsignmentSalesService {
    public getConsignmentSalesData = async (consignmentSalesRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.CONSIGNMENT_SALES, {
            params: consignmentSalesRequest
        });
    };
}

export { ConsignmentSalesService };