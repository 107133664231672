import { Tooltip } from "antd";
import { InfoOutlined } from "@ant-design/icons";

const PerformanceAnalysisInformationTooltip: React.FC<{
    information: string;
}> = ({
    information
}) => {
        return (
            <Tooltip title={information} placement="top">
                <i className='da icon-info-fill infoTooltip'></i>
            </Tooltip>
        );
    };

export default PerformanceAnalysisInformationTooltip;
