import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  message,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { IBrokerTags } from "models/interfaces";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { filterSortByRanking, getRandomValue, twoDecimalPlacesNumber } from "utils/commonFunction";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title?: any;
  inputType: "number" | "text";
  record: IBrokerTags;
  index?: number;
  onKeyDown: any;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  inputType,
  record,
  onKeyDown,
  children,
  ...restProps
}) => {
  const [value, setValue] = React.useState<string | number>(0.0);
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        value={value}
        onChange={(_value)=>{
          if(_value){
            setValue(_value);
          }
        }}
        placeholder="Commission"
        precision={2}
        min={0}
        max={100}
        onKeyDown={onKeyDown}
        className="text-right commPer"
        controls={false}
        addonAfter="%"
      />
    ) : (
      record?.name
    );

  let rules: any = [];

  if (inputType === "number") {
    rules = [
      {
        required: true,
        message: ``,
        pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
      },
    ];
  }

  return (
    <td {...restProps} className="editZoneArea">
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CommissionTable: React.FC<{
  tagsWithCommission: IBrokerTags[];
  tagsWithoutCommission: IBrokerTags[];
  setAllowSave: Function;
  originData: IBrokerTags[];
  setOriginData: Function;
}> = ({
  tagsWithCommission,
  tagsWithoutCommission,
  setAllowSave,
  originData,
  setOriginData,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const [firstTimeReceivedOriginData, setFirstTimeReceivedOriginData] =
    useState(false);
  const isEditing = (record: IBrokerTags) => record.key === editingKey;
  const [brokerTagDropdownOptions, setBrokerTagDropdownOptions] = useState<any>(
    []
  );
  const [newTagCommission, setNewTagCommission] = useState<IBrokerTags | null>(
    null
  );
  const [inputValue, setInputValue] = useState("");
  const handleOnSearch = (value: React.SetStateAction<string>) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (editingKey !== "") {
      setAllowSave(false);
    } else {
      setAllowSave(true);
    }
  }, [editingKey, setAllowSave]);

  useEffect(() => {
    if (tagsWithoutCommission && tagsWithoutCommission?.length) {
      let temp = tagsWithoutCommission.map((eachTag) => {
        return {
          label: eachTag.name,
          value: eachTag.id,
          key: eachTag.id,
        };
      });
      setBrokerTagDropdownOptions(temp);
    }
  }, [tagsWithoutCommission]);

  const edit = useCallback(
    (record: any) => {
      form.setFieldsValue({
        name: "",
        comm_per: "",
        ...record,
      });
      setEditingKey(record.key);
      setNewTagCommission(record);
    },
    [form]
  );

  const cancelEdit = () => {
    setEditingKey("");
  };
  const commissionTableHeaders = [
    {
      title: "BROKER TAG",
      dataIndex: "name",
      className: "text-left",
      editable: editingKey?.length ? false : true,
      // width: '46%',
      render: (name: any) => {
        if (typeof name === "string") {
          return <div className="ant-tag info">{name}</div>;
        } else {
          const alreadyAddedTagIds = originData.map((each) => each.id);
          let _tags = brokerTagDropdownOptions.filter((each: any) => {
            return !alreadyAddedTagIds.includes(each.value);
          });

          return (
            <Select
              showSearch
              placeholder="Select Broker Tag"
              options={_tags}
              allowClear={false}
              onChange={(value: number) => {
                const _newTagWithCommission = tagsWithoutCommission.find(
                  (each) => each.id === value
                );
                _newTagWithCommission &&
                  setNewTagCommission(_newTagWithCommission);
              }}
              onSelect={()=>setInputValue("")}
              onDropdownVisibleChange={(open)=>open && setInputValue("")}
              onSearch={handleOnSearch}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
            />
          );
        }
      },
    },
    {
      title: "COMMISSION",
      dataIndex: "comm_per",
      width: "150px",
      className: "text-right",
      editable: true,
      render: (comm_per: any) => (
        <div className="text-right">{`${twoDecimalPlacesNumber(
          comm_per
        )} %`}</div>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      className: "text-right",
      width: "120px",
      render: (_: any, record: IBrokerTags) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="text-right">
            <Typography.Link
              onClick={() => {
                if (newTagCommission && newTagCommission.id > 0) {
                  save(record.key);
                } else {
                  message.error({
                    content: `Broker tag cannot be empty.`,
                    duration: 5,
                    className: "toastMsg savedFailed",
                  });
                }
              }}
              style={{ marginRight: 8 }}
            >
              <Tooltip title="Keep">
                <i className="da icon-save"></i>
              </Tooltip>
            </Typography.Link>
            <Typography.Link
              onClick={() =>
                record.id === -1 ? deleteRow(record) : cancelEdit()
              }
              style={{ marginRight: 8 }}
            >
              <Tooltip title="Cancel">
                <i className="da icon-cancel"></i>
              </Tooltip>
            </Typography.Link>
          </div>
        ) : (
          <div className="text-right">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              <Tooltip title="Edit">
                <i className="da icon-pencil-o"></i>
              </Tooltip>
            </Typography.Link>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => deleteRow(record)}
              style={{ marginRight: 8 }}
            >
              <Tooltip title="Delete">
                <i className="da icon-trash-o"></i>
              </Tooltip>
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns = commissionTableHeaders.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IBrokerTags) => ({
        record,
        inputType: col.dataIndex === "name" ? "JSX.Element" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const save = async (key: any) => {
    try {
      const row = (await form.validateFields()) as IBrokerTags;
      let newData = [...originData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        const findSameValue = newData.find((eachItem) => {
          const eachBrokerTagName =
            typeof eachItem.name === "string"
              ? eachItem.name.toLocaleLowerCase().trim()
              : "";
          const currentBrokerTagName = newTagCommission?.name;

          if (
            eachItem.key !== key &&
            eachBrokerTagName === currentBrokerTagName
          ) {
            return true;
          }
          return false;
        });

        if (!findSameValue) {
          newData.splice(index, 1, {
            ...item,
            ...row,
            id:
              !item.id || item.id < 0
                ? newTagCommission
                  ? newTagCommission.id
                  : 0
                : item.id,
            name: newTagCommission ? newTagCommission.name : item.name,
          });
          setOriginData(newData);
          setNewTagCommission(null);
          setEditingKey("");
        } else {
          message.error({
            content: `Commission value for broker tag already exists.`,
            duration: 5,
            className: "toastMsg savedFailed",
          });
        }
      } else {
        newTagCommission && newData.push(newTagCommission);
        setOriginData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validation Failed:", errInfo);
    }
  };

  const createNewRow = useCallback(() => {
    const currentKey =
      new Date().getTime().toString() + getRandomValue("number");
    const prevOriginData = [...originData];
    const newRow = {
      id: -1,
      name: brokerTagDropdownOptions,
      key: currentKey,
      comm_per: 0.0,
    };

    prevOriginData.push(newRow);

    setOriginData(prevOriginData);
    edit(newRow);
  }, [edit, originData]);

  const deleteRow = (tag: IBrokerTags) => {
    const deleteConfirmHeader = (
      <span>
        Are you sure you want to <strong>delete</strong>?
      </span>
    );

    const deleteConfirmBody = (
      <div className="confirmBody">
        <div className="deleteConfirmBodyTableWrap">
          <table>
            <tbody className="deleteConfirmBodyTable">
              <tr>
                <th>Broker Tag:</th>
                <td>{typeof tag.name === "string" ? tag.name : ""}</td>
              </tr>
              <tr>
                <th>Commission:</th>
                <td>{tag.comm_per}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
    if (tag.id !== undefined && tag.id >= 0) {
      showConfirm(
        deleteConfirmHeader,
        deleteConfirmBody,
        tag,
        deleteConfirmCommission,
        () => {
          return;
        }
      );
    } else {
      deleteConfirmCommission(tag);
    }
  };

  const deleteConfirmCommission = (tag: IBrokerTags) => {
    const prevOriginData = [...originData];
    const findZone = prevOriginData.find((eachTag) => eachTag.key === tag.key);

    if (findZone) {
      prevOriginData.splice(prevOriginData.indexOf(findZone), 1);
      setOriginData(prevOriginData);
      setEditingKey("");
    }
  };

  useEffect(() => {
    if (!firstTimeReceivedOriginData) {
      createNewRow();
      setFirstTimeReceivedOriginData(true);
    }
  }, [
    tagsWithCommission,
    tagsWithoutCommission,
    firstTimeReceivedOriginData,
    setOriginData,
    setFirstTimeReceivedOriginData,
    createNewRow,
  ]);

  return (
    <React.Fragment>
      <div
        className="customTbl scroll-y fixedHeaderFooter"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <Form form={form} component={false}>
          <Table
            dataSource={originData}
            columns={mergedColumns}
            pagination={false}
            className="main-table editCommissionArea"
            rowKey="ranking"
            rowClassName="editable-row"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </div>
      {editingKey === "" && (
        <div className="ant-col ant-col-xl-14">
          <div className="addRow">
            <Typography.Link
              onClick={() => {
                setNewTagCommission(null);
                createNewRow();
                setTimeout(() => {
                  document?.getElementById("input_zone_section")?.focus();
                }, 300);
              }}
              style={{ textDecoration: "underline" }}
            >
              NEW ROW
            </Typography.Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CommissionTable;
