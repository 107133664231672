import React from "react";
import { Col } from "antd";
import { widgetClassPerCol } from "../CommonFile/commonFile";

export const TBDWidgetCounts: React.FC<{
  label: string;
  cols: number;
  textColorClass: string;
}> = ({ label, cols, textColorClass }) => {
  return (
    <Col className={widgetClassPerCol[cols]}>
      <div className="dashboardCard2">
        <div className="dashboardCard comingsoon">
          <h2 className={textColorClass}>-</h2>
          <div className="withoutHref">{label}</div>
        </div>
      </div>
    </Col>
  )
}

export const TBDWidgetEventsDataView: React.FC<{
  label: string;
  cols: number;
  textColorClass: string;
}> = ({ label, cols, textColorClass }) => {
  return (
    <Col className={widgetClassPerCol[cols]}>
      <div className="dashboardCard2">
        <div className={`dashboardCardHeader ${textColorClass}`}>
          <span>{label}</span>
        </div>
        <div className="dashboardCardBody comingsoon comingsoonLrg"></div>
      </div>
    </Col>
  )
}

export const TBDWidgetKpis: React.FC<{
  label: string;
  cols: number;
}> = ({ label, cols }) => {
  return (
    <Col className={widgetClassPerCol[cols]}>
      <div className="dashboardChartCard">
        <div className="dashboardCard mainChartWidget" style={{ minHeight: 460 }}>
          <div className="chartTopbar">
            <h5>{label}</h5>
          </div>
          <div className="comingsoon comingsoonLrg"></div>
        </div>
      </div>
    </Col>
  )
}
