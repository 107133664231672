import React from 'react'
import { Col, DatePicker } from "antd";
const { RangePicker } = DatePicker;

const ListingsByEventDate: React.FC<{
    barChart: string;
}> = ({ barChart }) => {
    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
            <div className="dashboardChartCard">
                <div className="dashboardCard mainChartWidget">
                    <div className="chartTopbar">

                        <div className="graphDetail">
                            <div className="titleLerge alignSelfStart">
                                <h5>Listings by Event Date</h5>
                                {/* 187 Listings */}
                            </div>
                            {/* <div className="text-right">
                                <label>Event date range</label>
                                <RangePicker className="chartDateRange" />
                            </div> */}
                        </div>
                    </div>
                    <div className='comingsoon comingsoonLrg' style={{height:250}}></div>
                    {/* <div className="chartBody">
                        <div>
                            <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                            />
                        </div>
                    </div>
                    <div className="chartFooter">
                        <div className="chartBtmBar">
                            <ul>
                                <li>
                                    <a href="#" className="active">
                                        Day
                                    </a>
                                </li>
                                <li>
                                    <a href="#">Week</a>
                                </li>
                                <li>
                                    <a href="#">Month</a>
                                </li>
                                <li>
                                    <a href="#">Quarter</a>
                                </li>
                                <li>
                                    <a href="#">Year</a>
                                </li>
                            </ul>
                            <div className="chartIconList">
                                <button>
                                    <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                    <i className="da icon-bar-chart-dark"></i>
                                </button>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </Col>
    )
}

export default ListingsByEventDate