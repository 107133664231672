import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGlobal } from '../interfaces/Iglobal';
import { RootState } from '../../app/store';
import { UserService } from 'services';
import { CalendarPresetService } from 'services/calendarPresets/calendarPreset.service';

const initialState: IGlobal = {
  isLeftPanelVisible: false,
  isFilterDrawerVisible: false,
  isUserDataLoading: true,
  userData: null,
  calendarPresets: null,
  isHamMenuVisible: false,
};

const getUserDataAction = createAsyncThunk(
  "global/getUserDataAction",
  async () => {
    const response = await new UserService().getUserDetails();
    return response.data.data.userData;
  }
);

const getCalendarPresetsAction = createAsyncThunk(
  "global/getCalendarPresets",
  async() => {
    const response = await new CalendarPresetService().getCalendarPresetData();
    
    return response.data.data.calendarPresets;
  }
)

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleLeftPanel: (state, action: PayloadAction<boolean>) => {
      state.isLeftPanelVisible = !action.payload;
    },
    toggleFilterDrawer: (state, action: PayloadAction<boolean>) => {
      state.isFilterDrawerVisible = action.payload;
    },
    toggleHamMenu: (state, action: PayloadAction<boolean>) => {
      state.isHamMenuVisible = !action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDataAction.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.isUserDataLoading = false;
    });
    builder.addCase(getCalendarPresetsAction.fulfilled, (state, action) => {
      state.calendarPresets = action.payload;
    })
  }
});



export const actions = {
  getUserDataAction,
  getCalendarPresetsAction,
};

const isLeftPanelVisible = (state: RootState) => state.global.isLeftPanelVisible;
const isFilterDrawerVisible = (state: RootState) => state.global.isFilterDrawerVisible;
const userData = (state: RootState) => state.global.userData;
const isUserDataLoading = (state: RootState) => state.global.isUserDataLoading;
const calendarPresets = (state: RootState) => state.global.calendarPresets;
const isHamMenuVisible = (state: RootState) => state.global.isHamMenuVisible;
export {
  isLeftPanelVisible,
  isFilterDrawerVisible,
  userData,
  isUserDataLoading,
  calendarPresets,
  isHamMenuVisible,
};
export const { toggleLeftPanel, toggleFilterDrawer, toggleHamMenu } = globalSlice.actions;
export default globalSlice.reducer;