import React, { useEffect, useState } from 'react';
import { Col } from "antd";
import {
  IFilterOptions,
  IDashboardAlertsQueryParams,
  IDashboardCommonDatum,
  IdType
} from "models/interfaces";
import { LoggerService } from "sharedServices";
import { SeatMapCreatorDashboardService } from "services";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import {
  widgetClassPerCol,
  defaultParams
} from "views/Dashboard/CommonComponents/CommonFile/commonFile";
import SeatAvailabilityCommon from 'views/Dashboard/CheckerDashboard/SeatAvailability/SeatAvailabilityCommon';

const CreateSeatStructureCommon: React.FC<{
  filterObject: IFilterOptions | null;
  isRefreshed: boolean;
  setIsRefreshed: Function;
  newShowsOnly?: boolean;
  showsSeatMapTbd?: boolean;
  label: string;
  apiName: string;
  cols: number;
  tagStyleType?: string;
  labelColorClass: string;
  paramObject: {
    show_category_id: IdType;
    exclude_sold_out_events?: boolean;
    exclude_cancelled_events?: boolean;
  };
}> = ({
  filterObject,
  isRefreshed,
  setIsRefreshed,
  newShowsOnly,
  showsSeatMapTbd,
  label,
  apiName,
  cols,
  tagStyleType = "noOfDays",
  labelColorClass,
  paramObject
}) => {
    const [queryParams, setQueryParams] = useState<IDashboardAlertsQueryParams | null>(null);
    const [createSeatStructureData, setCreateSeatStructureData] = useState<IDashboardCommonDatum[] | null | undefined>(null);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);
    const [ifLengthExceeds, setIfLengthExceeds] = useState<boolean>(false);

    const [defaultQueryParams] = useState<IDashboardAlertsQueryParams>({
      ...defaultParams,
      new_shows_only: newShowsOnly,
      shows_seatmap_tbd: showsSeatMapTbd
    });

    const getCreateSeatStructureData = async (query: IDashboardAlertsQueryParams) => {
      try {
        setShowLoader(true);
        const response = await new SeatMapCreatorDashboardService()[apiName]({
          ...query,
        });

        const data = response.data.data;
        setIfLengthExceeds(data.if_length_exceeds);
        setCreateSeatStructureData(data.eventsData && data.eventsData.length > 0 ? [...data.eventsData] : null);

        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);
        await new LoggerService().log({
          payload: error,
          function_name: "getCreateSeatStructureData",
        });

        setDataFetchError({ ...error, refreshAction: getCreateSeatStructureData });
      }
    };

    useEffect(() => {
      queryParams && getCreateSeatStructureData(queryParams);
    }, [queryParams]);

    useEffect(() => {
      if (isRefreshed && queryParams) {
        setIsRefreshed(false);
        getCreateSeatStructureData(queryParams);
      }
    }, [isRefreshed]);

    useEffect(() => {
      if (filterObject !== null && defaultQueryParams) {
        setQueryParams({
          ...defaultQueryParams,
          ...filterObject
        });
      }
    }, [defaultQueryParams, filterObject]);

    return (
      <Col className={widgetClassPerCol[cols]}>
        {dataFetchError && (
          <CustomError {...dataFetchError} />
        )}
        {!dataFetchError && (
          <SeatAvailabilityCommon
            eventData={createSeatStructureData}
            showLoader={showLoader}
            queryParams={queryParams}
            label={label}
            textLimits={{ browser: 60, mobile: 19 }}
            tagStyleType={tagStyleType}
            labelColorClass={labelColorClass}
            ifLengthExceeds={ifLengthExceeds}
            ifShowingHyperlinkedLabel={false}
            paramObject={paramObject}
          />
        )}
      </Col>
    )
  }

export default CreateSeatStructureCommon;
