import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import FilterDrawer from 'utils/commonFilter/FilterDrawer';
import ReceivablesDetailsTable from './ReceivablesDetailsTable';
import { useLocation } from 'react-router';
import CreateScheduler from 'utils/commonScheduler/createScheduler';
import { IFilterOptions, IReportQueryString } from 'models/interfaces';
import { usePageTitle } from 'hooks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { actions, dateRange } from 'models/Slices/FilterSlice';
import ReportLayout from '../../ReportLayout';
import { ERbacPermissions } from 'features/rbac/rbacPermissionsList';
import { RBAC, useRbac } from 'features/rbac/rbac';
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from 'utils/commonFunction';

const ReceivablesDetailsReportPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(dateRange);
  const { hasPermissions } = useRbac();
  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    dispatch(actions.getRecipientFilterData());
  }, [dispatch]);

  const location = useLocation();
  const path = location.pathname;
  const queryParams = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report,
  );

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [ifShowFilterDrawer, setIfShowFilterDrawer] = useState(false);
  const [filterQueryString, setFilterQueryString] = useState<any>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);
  const [outerLoader, setOuterLoader] = useState(false);
  const [title, setTitle] = useState<string>('');
  usePageTitle(title);

  useEffect(() => {
    if (path.endsWith('retail')) {
      setTitle('Receivables Retail');
    } else {
      setTitle('Receivables Details');
    }
  }, []);

  useEffect(() => {
    setOuterLoader(true);
    if (location.search.includes('filter')) {
      setFilterQueryString(decodeQueryStringData(queryParams.filter));
    }
  }, []);

  useEffect(() => {
    if (dates.length > 0) {
      if (!filterQueryString) {
        setDefaultDateRange({
          preFilledValues: {
            invoice_date_from: dates[0].invoice_date_from,
            invoice_date_to: dates[0].invoice_date_to,
          },
          defaultValues: {
            invoice_date: {
              defaultFrom: dates[0].invoice_date_from,
              defaultTo: dates[0].invoice_date_to,
            },
          },
        });
        setIfShowFilterDrawer(true);
      } else {
        setDefaultDateRange({
          preFilledValues: {
            invoice_date_from:
              filterQueryString?.invoice_date_from ??
              dates[0].invoice_date_from,
            invoice_date_to:
              filterQueryString?.invoice_date_to ?? dates[0].invoice_date_to,
          },
          defaultValues: {
            invoice_date: {
              defaultFrom: dates[0].invoice_date_from,
              defaultTo: dates[0].invoice_date_to,
            },
          },
        });
        setIfShowFilterDrawer(true);
      }
    }
  }, [dates]);

  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch('');
    setSearchQuery('');
  };

  const getSchedulerPermission = (p: string) => {
    if (p.endsWith('retail')) {
      return [ERbacPermissions.REPORT_RECEIVABLES_RETAIL_VIEW_SCHEDULE];
    }
    return [ERbacPermissions.REPORT_RECEIVABLES_DETAILS_VIEW_SCHEDULE];
  };

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col span={9}>
            <h2>{reportQueryString.current_report}</h2>
          </Col>
          <Col span={6}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search invoice id, po number, or event name..."
                  value={search ? search : ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col span={9}>
            <div className="rightFilter">
              <RBAC allowedPermissions={getSchedulerPermission(path)}>
                <CreateScheduler
                  schedulerTitle={'Create Schedule'}
                  reportData={reportQueryString}
                  filterData={filterObject}
                />
              </RBAC>
              {ifShowFilterDrawer && (
                <FilterDrawer
                  current_report_id={reportQueryString.current_report_id}
                  showInvoiceDatePicker={true}
                  showBrokerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_BROKER_TAGS_VIEW_ALL,
                    ERbacPermissions.FILTER_BROKER_TAGS_VIEW_TEAM,
                  ])}
                  showSaleCategoryDropdown={
                    path.endsWith('retail') ? false : true
                  }
                  showCustomerClassificationDropdown={true}
                  showNoTicketCheckbox={true}
                  showAgingPeriodDropdown={true}
                  showFulfillmentStatusDropdown={true}
                  onApplyFilter={filterChanged}
                  onApplyFilterWithValues={(e: any) =>
                    setFilterAppliedWithValues(e)
                  }
                  brokerDropdownType="single"
                  saleCategoryDropdownType="single"
                  customerClassificationDropdownType="single"
                  agingPeriodDropdownType="multiple"
                  defaultAndValues={defaultDateRange}
                  fulfillmentStatusDropdownType="single"
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <ReceivablesDetailsTable
            filterObject={filterObject}
            appliedFilterValues={filtersAppliedWithValues}
            searchQuery={searchQuery}
            outerLoader={outerLoader}
            setOuterLoader={setOuterLoader}
          />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default ReceivablesDetailsReportPage;
