import { chartFilterType } from "models/types/Reports/chartFilterType";
import { Link } from "react-router-dom";

const PerformanceAnalysisGraphFilters: React.FC<{
    chartFilterHandler: Function;
    activeFilter: chartFilterType;
    isLoading: boolean
}> = ({
    chartFilterHandler,
    activeFilter,
    isLoading
}) => {
        return (
            <div className="chartBtmBar flx justifyContentBetween">
                <ul>
                    <li>
                        <Link
                            to="#/"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!isLoading) {
                                    chartFilterHandler("week");
                                }
                            }}
                            className={activeFilter === "week" ? "active" : ""}
                        >
                            Week
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="#/"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!isLoading) {
                                    chartFilterHandler("month");
                                }
                            }}
                            className={activeFilter === "month" ? "active" : ""}
                        >
                            Month
                        </Link>
                    </li>
                </ul>
                <div className="chartIconList">

                </div>
            </div>
        );
    };

export default PerformanceAnalysisGraphFilters;
