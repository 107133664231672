import { Layout, Row, Col, Card } from "antd";
import { Content } from "antd/lib/layout/layout";
import { withRbac } from "features/rbac/rbac";

const ManagementPage: React.FC = () => {

  return (
      <Layout className="mainInnerLayout">
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row>
              <Col span={24}>
                <h3>Coming Soon</h3>
              </Col>
            </Row>
          </div>
            <Card className="customCard">
              <div className="customCardBody">
              </div>
            </Card>
        </Content>
      </Layout>
  );
};

export default withRbac(ManagementPage);
