import { message } from "antd";
import { IMenuOption, ISwimlaneInvoiceList, ISwimlaneMaster } from "models/interfaces";
import React from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";

const target_swimlane_id = 8;

const MoveToNotDeliveredShipping: React.FC<{
  menuData: IMenuOption
  swimlaneData: ISwimlaneMaster;
  eachInvoiceData: ISwimlaneInvoiceList;
  setShowMenu: Function;
  setSelectedMenuId: Function;
  requestToReloadTheLanes: Function;
}> = ({
  menuData,
  swimlaneData,
  eachInvoiceData,
  setShowMenu,
  setSelectedMenuId,
  requestToReloadTheLanes
}) => {
  const moveToNotDelivered = async () =>{
    const moveToNotDeliveredKey = "moveToNotDeliveredKey";
    setSelectedMenuId(null);
    setShowMenu(false);

    message.loading({
      content: `Moving Invoice from ${swimlaneData.name} to Not Delivered queue...`,
      duration: 0,
      key: moveToNotDeliveredKey,
      className: "toastMsg loadingMsg",
    });
    try {
      await new PurchaseAndShippingService()
        .markAsNotDeliveredShipping({
          invoice_id: eachInvoiceData.invoice_id,
          swimlane_id: target_swimlane_id
        });
        requestToReloadTheLanes([swimlaneData.id, target_swimlane_id]);

        message.success({
          content: `Invoice #${eachInvoiceData.invoice_id} moved from ${swimlaneData.name} to Not Delivered Queue.`,
          duration: 5,
          key: moveToNotDeliveredKey,
          className: "toastMsg savedSuccess",
        });
    } catch (error: any) {
      message.error({
        key: moveToNotDeliveredKey,
        content: "Failed to update.",
        duration: 5,
        className: "toastMsg savedFailed",
      });

      new LoggerService().log({
        payload: error,
        function_name: "moveToNotDelivered",
      });
    }

    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 500);
  }

  return (
    <React.Fragment>
      <div className="accHead" onClick={moveToNotDelivered}>{menuData.name}</div>
    </React.Fragment>
  );
};

export default MoveToNotDeliveredShipping;
