import { Row, Col, Layout } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RBAC, useRbac } from 'features/rbac/rbac';
import { ERbacPermissions } from 'features/rbac/rbacPermissionsList';
import { usePageTitle } from 'hooks';
import { IReportQueryString, IFilterOptions } from 'models/interfaces';
import { actions, dateRange } from 'models/Slices/FilterSlice';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import FilterDrawer from 'utils/commonFilter/FilterDrawer';
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from 'utils/commonFunction';
import CreateScheduler from 'utils/commonScheduler/createScheduler';
import ReportLayout from '../../ReportLayout';
import OtherCostsAndCreditsTable from './OtherCostsAndCreditsTable';

const OtherCostsAndCreditsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(dateRange);
  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    dispatch(actions.getRecipientFilterData());
  }, [dispatch]);

  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report,
  );

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [ifShowFilterDrawer, setIfShowFilterDrawer] = useState(false);
  const [filterQueryString, setFilterQueryString] = useState<any>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);
  const [outerLoader, setOuterLoader] = useState(false);
  usePageTitle('Other Costs And Credits Report');

  useEffect(() => {
    setOuterLoader(true);
    if (location.search.includes('filter')) {
      setFilterQueryString(decodeQueryStringData(queryParams.filter));
    }
  }, []);

  useEffect(() => {
    if (dates.length > 0) {
      if (!filterQueryString) {
        setDefaultDateRange({
          preFilledValues: {
            invoice_date_from: dates[0].invoice_date_from,
            invoice_date_to: dates[0].invoice_date_to,
            other_cost_date_from: dates[0].other_cost_date_from,
            other_cost_date_to: dates[0].other_cost_date_to,
          },
          defaultValues: {
            invoice_date: {
              defaultFrom: dates[0].invoice_date_from,
              defaultTo: dates[0].invoice_date_to,
            },
            other_cost_date: {
              defaultFrom: dates[0].other_cost_date_from,
              defaultTo: dates[0].other_cost_date_to,
            },
          },
        });
        setIfShowFilterDrawer(true);
      } else {
        setDefaultDateRange({
          preFilledValues: {
            invoice_date_from:
              filterQueryString?.invoice_date_from ??
              dates[0].invoice_date_from,
            invoice_date_to:
              filterQueryString?.invoice_date_to ?? dates[0].invoice_date_to,
            other_cost_date_from:
              filterQueryString?.other_cost_date_from ??
              dates[0].other_cost_date_from,
            other_cost_date_to:
              filterQueryString?.other_cost_date_to ??
              dates[0].other_cost_date_to,
          },
          defaultValues: {
            invoice_date: {
              defaultFrom: dates[0].invoice_date_from,
              defaultTo: dates[0].invoice_date_to,
            },
            other_cost_date: {
              defaultFrom: dates[0].other_cost_date_from,
              defaultTo: dates[0].other_cost_date_to,
            },
          },
        });
        setIfShowFilterDrawer(true);
      }
    }
  }, [dates]);

  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch('');
    setSearchQuery('');
  };

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col span={9}>
            <h2>{reportQueryString.current_report}</h2>
          </Col>
          <Col span={6}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search invoice id..."
                  value={search ? search : ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col span={9}>
            <div className="rightFilter">
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.REPORT_OTHER_COSTS_AND_CREDITS_VIEW_SCHEDULE,
                ]}
              >
                <CreateScheduler
                  schedulerTitle={'Create Schedule'}
                  reportData={reportQueryString}
                  filterData={filterObject}
                />
              </RBAC>
              {ifShowFilterDrawer && (
                <FilterDrawer
                  current_report_id={reportQueryString.current_report_id}
                  showInvoiceDatePicker={true}
                  showOtherCostDatePicker={true}
                  onApplyFilter={filterChanged}
                  onApplyFilterWithValues={(e: any) =>
                    setFilterAppliedWithValues(e)
                  }
                  defaultAndValues={defaultDateRange}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <OtherCostsAndCreditsTable
            filterObject={filterObject}
            appliedFilterValues={filtersAppliedWithValues}
            searchQuery={searchQuery}
            outerLoader={outerLoader}
            setOuterLoader={setOuterLoader}
          />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default OtherCostsAndCreditsPage;
