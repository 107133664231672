import { Skeleton } from "antd";

const CardSkeleton: React.FC<{ isLoading: boolean; }> = ({ isLoading }) => {
  return (
    <div className="eventCardWrap activeListing noTopbar">
      <div className="eventCardContent">
        <Skeleton active loading={isLoading} />
      </div>
    </div>
  );
};

export default CardSkeleton;
