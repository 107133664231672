import { Tooltip } from "antd";
import {
  CommonTableHeader,
  IPeriodBreakup,
  IProfitDashboardBaseType,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import CommonReportTable from "utils/table/CommonReportTable";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { AppSetting } from "utils/appSettings";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const BrokerCommissionPeriodTable: React.FC<{
  queryParams: IProfitDashboardBaseType | null;
}> = ({ queryParams }) => {
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [
    brokerCommissionPeriodBreakupHeaders,
    setBrokerCommissionPeriodBreakupHeaders,
  ] = useState<CommonTableHeader[]>([]);
  const [
    brokerCommissionPeriodBreakupData,
    setBrokerCommissionPeriodBreakupData,
  ] = useState<any>();
  const [brokerCommissionPeriodAggregate, setBrokerCommissionPeriodAggregate] =
    useState<any>();
  const [brokerCommissionTableError, setBrokerCommissionTableError] = useState<ICustomError | null>(null)

  const getBrokerCommissionPeriodData = async () => {
    try {
      if (!queryParams) return false;
      setIsServiceLoading(true);
      const brokerCommissionPeriodBreakupResponseData =
        await new ProfitDashboardService().getBrokerCommissionPeriodBreakup({
          ...queryParams,
          ...{ client_zone: AppSetting.TIMEZONE },
        });
      formatPeriodBreakupData(
        brokerCommissionPeriodBreakupResponseData.data.data
      );
      setIsServiceLoading(false);
      setBrokerCommissionTableError(null)
    } catch (error: any) {
      setIsServiceLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getBrokerCommissionPeriodData'
      })
      setBrokerCommissionTableError({...error, refreshAction: getBrokerCommissionPeriodData})
    }
  };

  const formatPeriodBreakupData = (allData: IPeriodBreakup) => {
    const headers = [
      {
        title: "Broker",
        thClassName: "text-left",
        dataIndex: "broker",
        key: "broker",
        dataRender: (data: string, otherData: any) => {
          let text;
          switch (otherData.data.singleData.brokerId) {
            case 0:
              text = (
                <span>No Inventory tags are available for the records</span>
              ); //no broker
              break;
            case -1:
              text = (
                <span>
                  Inventory tag matches with multiple existing broker tags
                </span>
              ); //mismatch
              break;
            case -2:
              text = (
                <span>
                  Inventory tag does not match with any existing broker tag
                </span>
              ); //no match
              break;
          }
          return (
            <React.Fragment>
              {otherData.data.singleData.brokerId <= 0 ? (
                <Tooltip placement="top" title={text}>
                  <span
                    style={{ textTransform: "capitalize", cursor: "default" }}
                  >
                    {data}
                  </span>
                </Tooltip>
              ) : (
                <span
                  className={"ant-tag info text-left"}
                  style={{ textTransform: "capitalize" }}
                >
                  {data}
                </span>
              )}
            </React.Fragment>
          );
        },
        dataFooterRender: (data: string) => (
          <div className={`text-left`}>{data}</div>
        ),
      },
    ];
    const footer: any =
      allData.periodRanks[0].currencyCode === "" ? [{}] : [{ broker: "TOTAL" }];
    allData.periodRanks.forEach((rank) => {
      headers.push({
        title: rank.label,
        thClassName: rank.type === "year" ? "yearYTDHeader text-right" : "text-right",
        dataIndex: rank.period_rank.toString(),
        key: rank.period_rank.toString(),
        dataRender: (data: string, _otherData: any) => {
          if(rank.period_rank === 6 || rank.period_rank === 7) {
            return (
              <div
            className={`text-right ${parseInt(data) < 0 ? "text-danger" : ""} colorDark`}
          >
            {parseInt(data) === 0 ? '-' : data}
          </div>
            )
          }
          else {
            return (
              <div
            className={`text-right ${parseInt(data) < 0 ? "text-danger" : ""}`}
          >
            {parseInt(data) === 0 ? '-' : data}
          </div>
            )
          }
        },
        dataFooterRender: (data: string) => (
          <div className={`text-right`}>
            {FormatCurrencyUnit(data, rank.currencyCode)}
          </div>
        ),
        
      });
      footer[0][rank?.period_rank.toString()] =
        rank?.value === "" ? "" : rank?.value;
    });

    setBrokerCommissionPeriodAggregate(footer);

    setBrokerCommissionPeriodBreakupHeaders(headers);

    let rows: {}[] = [];
    allData.brokerRanks.forEach((rank) => {
      const row = {};
      Object.assign(row, {
        broker: rank.broker,
        brokerId: rank.brokerId,
      });
      rank.breakupData.forEach((item) => {
        const key = item?.period_rank.toString();
        const value = item?.value === "" ? "-" : item?.value;
        row[key] = value;
      });
      rows.push(row);
    });
    setBrokerCommissionPeriodBreakupData(rows);
  };

  const formatData = (data: any) => {
    const normalData = data?.filter((item: any) => item.brokerId > 0);
    const flaggedData = data?.filter((item: any) => item.brokerId <= 0);
    return normalData && flaggedData ? normalData?.concat(flaggedData) : null;
  };

  useEffect(() => {
    getBrokerCommissionPeriodData();
  }, [queryParams?.currency_Id]);

  return (
    brokerCommissionTableError
    ?
    <CustomError {...brokerCommissionTableError} />
    :
    <div className="innerComponent tblStyle2">
      <h3>Broker Commission - Last 6 Months</h3>
      <LoadingSpinner isLoading={isServiceLoading}>
        <CommonReportTable
          headers={brokerCommissionPeriodBreakupHeaders}
          data={formatData(brokerCommissionPeriodBreakupData)}
          footerData={brokerCommissionPeriodAggregate}
          tableClass={"customTbl"}
        />
      </LoadingSpinner>
    </div>
  );
};

export default BrokerCommissionPeriodTable;
