import { Row, Col } from "antd";
import { usePageTitle } from "hooks";
import InventoryTrading from "./InventoryAndTrading/InventoryTrading";
import PurchaseShipping from "./PurchaseAndShipping/PurchaseShipping";

const OperationsPage: React.FC = () => {

  usePageTitle("Operations Dashboard");

  return (
    <div>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col className="ant-col-sm-18 ant-col-xxl-12">
            <h2>Operations DASHBOARD</h2>
            <h3 className="text-upper sectionHeading">Inventory and Trading</h3>
          </Col>
          {/* <Col className="ant-col-sm-6 ant-col-xxl-12">
            <div className="rightFilter">
              <a href="#">
                <i className="da icon-listview listviewIcon"></i>
              </a>
            </div>
          </Col> */}
        </Row>
      </div>
      <InventoryTrading />
      <div className="mainHeading">
        <h3 className="text-upper sectionHeading">Purchase and Shipping</h3>
      </div>
      <PurchaseShipping />
    </div>

  );
};

export default OperationsPage;
