import React, { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import {
  IPendingZoneFillsAllBrokersDatum,
  IPendingZoneFillsBrokersDatum,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CustomError from "controls/CustomError/CustomError";
import { getEncodedData } from "utils/commonFunction";
import { getWidth, formatValues } from "./commonWidgetFunctions";
import { Tooltip, Typography } from "antd";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";

const WidgetListingsBrokers: React.FC<{
  defaultFilterParams: Object;
}> = ({ defaultFilterParams }) => {
  const [widgetBrokersdata, setWidgetBrokersData] =
    useState<IPendingZoneFillsAllBrokersDatum | null>(null);
  const [showLoader, setShowLoader] = useState(true);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [largestCount, setLargestCount] = useState(0);

  const getPendingFillsWidgetData = async () => {
    try {
      setShowLoader(true);
      const response =
        await new OperationsDashboardService().getPendingZoneFillsWidgetsBrokers();

      setWidgetBrokersData(response.data.data.widgetBrokersData);

      findLargest(response.data.data.widgetBrokersData);
      setShowLoader(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getPendingFillsWidgetData",
      });

      setDataFetchError({ ...error, refreshAction: getPendingFillsWidgetData });

      setShowLoader(false);
    }
  };

  const findLargest = (brokersData: any) => {
    const num1 = brokersData?.top4Brokers
        ? brokersData?.top4Brokers[0].broker_wise_total
        : 0,
      num2 = brokersData?.otherBrokers
        ? brokersData?.otherBrokers[0].broker_wise_total
        : 0;

    if (num1 >= num2) {
      setLargestCount(num1);
    } else {
      setLargestCount(num2);
    }
  };

  useEffect(() => {
    getPendingFillsWidgetData();
  }, []);

  const newFiltersApplied = (param: any, title: string) => {
    if (param) {
      return getEncodedData({ ...defaultFilterParams, title: title, ...param });
    }
  };

  const getBrokerDetailsView = (
    item: IPendingZoneFillsBrokersDatum,
    index: number
  ) => {
    return (
      <span id={`salesBroker${index}`} className="progress-text haveTooltip">
        <Tooltip
          overlayStyle={{ textTransform: "none" }}
          overlayInnerStyle={{
            textTransform: "none",
          }}
          placement={"right"}
          title={item.broker_name}
          arrowPointAtCenter={true}
        >
          <span style={{ cursor: "pointer" }}>
            {item.broker_name_initials.length > 10
              ? `${item.broker_name_initials.slice(0, 10).trim()}...`
              : item.broker_name_initials}
          </span>
        </Tooltip>
      </span>
    );
  };

  const getBrokerWidgetView = () => {
    return widgetBrokersdata && widgetBrokersdata?.total > 0 ? (
      <div
        className="zoneInsightsCardInnerWrap"
        style={{
          justifyContent: !widgetBrokersdata?.ifAll
            ? "center"
            : "space-between",
        }}
      >
        <div className="zoneInsightsCardLeft">
          <Typography.Link
            id="totalSalesBrokers"
            target="_blank"
            rel="noreferrer"
            href={`${
              LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
            }?filter=${newFiltersApplied({ broker: "All" }, "SALES (Brokers)")}&isDisabled=${getEncodedData({
              disabled: true
            })}`}
            underline={false}
            className="zoneInsightsCount primary active"
          >
            <span>${formatValues(widgetBrokersdata?.total)}</span>
          </Typography.Link>
        </div>
        {widgetBrokersdata?.ifAll && (
          <div className="zoneInsightsCardRight">
            {widgetBrokersdata?.top4Brokers &&
              widgetBrokersdata?.top4Brokers.map((item, index) => (
                <React.Fragment key={index}>
                  {item.broker_wise_total > 0 ? (
                    <div className="progress-bar progress-dark">
                      <Typography.Link
                        target="_blank"
                        rel="noreferrer"
                        href={`${
                          LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
                        }?filter=${newFiltersApplied({
                          broker_user_Id: item.user_ids,
                          broker: item.broker_name
                        }, "SALES (Brokers)")}&isDisabled=${getEncodedData({
                          disabled: true
                        })}`}
                        underline={false}
                        className="progress-text haveTooltip"
                      >
                        {getBrokerDetailsView(item, index)}
                      </Typography.Link>

                      <div className="progress-outer">
                        <div
                          className="progress-bg"
                          style={{
                            width: getWidth(
                              item.broker_wise_total,
                              largestCount
                            ),
                          }}
                        ></div>
                      </div>
                      <span className="progress-value">
                        {formatValues(item.broker_wise_total)}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            {widgetBrokersdata?.otherBrokers &&
              widgetBrokersdata?.otherBrokers[0]?.broker_wise_total > 0 && (
                <div className="progress-bar progress-dark">
                  <Typography.Link
                    target="_blank"
                    rel="noreferrer"
                    href={`${
                      LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
                    }?filter=${newFiltersApplied({
                      except_broker_user_ids: JSON.stringify(
                        widgetBrokersdata?.otherBrokers &&
                          widgetBrokersdata?.top4BrokerIds
                      ),
                      broker: "Others"
                    }, "SALES (Brokers)")}&isDisabled=${getEncodedData({
                      disabled: true
                    })}`}
                    underline={false}
                    className="progress-text haveTooltip"
                  >
                    <span
                      id="salesBeokerOthers"
                      className="progress-text haveTooltip"
                    >
                      {widgetBrokersdata?.otherBrokers[0]?.broker_name}
                    </span>
                  </Typography.Link>

                  <div className="progress-outer">
                    <div
                      className="progress-bg"
                      style={{
                        width: getWidth(
                          widgetBrokersdata?.otherBrokers[0]?.broker_wise_total,
                          largestCount
                        ),
                      }}
                    ></div>
                  </div>
                  <span className="progress-value">
                    {formatValues(
                      widgetBrokersdata?.otherBrokers[0]?.broker_wise_total
                    )}
                  </span>
                </div>
              )}
          </div>
        )}
      </div>
    ) : (
      <div className="zoneInsightsCardInnerWrap cardCenter">
        {/* cardCenter */}
        <div className="zoneInsightsCardNoData">No Data Found</div>
      </div>
    );
  };

  return (
    <LoadingSpinner isLoading={showLoader}>
      <div className="zoneInsightsCard">
        <h3>SALES (Brokers)</h3>
        {!showLoader && (
          <React.Fragment>
            {dataFetchError ? (
              <CustomError {...dataFetchError} />
            ) : (
              <React.Fragment>{getBrokerWidgetView()}</React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default WidgetListingsBrokers;
