import { Col } from "antd";

const NeedToBuy = () => {
    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className="dashboardChartCard">
                <div className="dashboardCard mainChartWidget">
                    <div className="chartTopbar">
                        <h5 className="uppercase">Need to Buy</h5>
                    </div>
                    {/* <div className="chartBody">
                        <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                            />
                        </div>
                    </div> */}
                    <div className='comingsoon comingsoonLrg' style={{height:168}}></div>

                </div>
            </div>
        </Col>
    )
}

export default NeedToBuy