import { Tooltip } from "antd";
import { FixedColumnDirection } from "models/enums/table";
import { trigger } from "sharedServices/customEvents/events";
import { AppSetting } from "utils/appSettings";
import { twoDecimalPlacesNumber, addToolTip, formatAccountingValues, formatNumericValues } from 'utils/commonFunction'
import { getCustomDate, formatDateGivenFormatString } from 'utils/formatTimePeriod';

const commonWidth = 130;

export const longInventoryHeaders = [
  {
    title: "PO Date",
    dataIndex: "po_date",
    canSort: true,
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    dataRender: (poDate: string) =>
    poDate && (
        <span className="fontMedium">{getCustomDate(poDate, AppSetting.REPORT_DATE_FORMAT)}</span>
      ),
  },
  {
    title: "PO Number",
    dataIndex: "po_no",
    canSort: true,
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    dataRender: (poNumber: any) =>
      isNaN(poNumber) ? (
        <div>{poNumber}</div>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={AppSetting.PURCHASE_ID_URL + poNumber}
        >
          {poNumber}
        </a>
      ),
  },
  {
    title: "Alert",
    dataIndex: "alert_tag",
    fixed: FixedColumnDirection.left,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (alert: any, otherData: any) => {
      return (
        <div className="flx">
          {alert &&
            alert.map((eachTag: any) => {
              const tagName = eachTag.name;
              const alertId = eachTag.id;
              let className = "";

              if (alertId == 1) className = "ant-tag danger";
              else if (alertId == 2) className = "ant-tag warning";
              else if (alertId == 3) className = "ant-tag danger-outline";
              else if (alertId == 4) className = "ant-tag secondary-outline";
              else if (alertId == 5) className = "ant-tag danger-outline-fill";
              else if (eachTag.pr_issue_res_popup)
                className = "ant-tag lightGreenFill";
              if (eachTag.pr_issue_res_popup || eachTag.pr_issue_popup) {
                return (
                  <Tooltip placement="top" title={eachTag.tooltip}>
                    <span
                      onClick={() => {
                        console.log(otherData.data.singleData);
                        trigger("pricingIssue:click", {
                          action: "edit",
                          data: otherData.data.singleData,
                        });
                      }}
                      className={className}
                      style={{ cursor: "pointer" }}
                    >
                      {tagName.replace(" days", "d")}
                    </span>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip placement="top" title={eachTag.tooltip}>
                    <span className={className} style={{ cursor: "default" }}>
                      {tagName.replace(" days", "d")}
                    </span>
                  </Tooltip>
                );
              }
            })}
        </div>
      );
    },
  },
  {
    title: "Consignment",
    dataIndex: "consignment_status",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
  },
  {
    title: "Broker",
    dataIndex: "broker",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (broker: any) => <div>{broker}</div>,
  },
  {
    title: "Buyer",
    dataIndex: "buyer",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
  },
  {
    title: "PO Tag",
    dataIndex: "po_tags",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (poTags: any) => <div>{poTags}</div>,
  },

  {
    title: "Event Name",
    dataIndex: "event_name",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.eventId}
        className="text-capitalize"
      >
        {event?.length > 30 ? addToolTip(event,"right", "pointer", 30, false, {textTransform: "capitalize"}): event}
      </a>
    ),
  },
  {
    title: "Event Date & Time",
    dataIndex: "event_date",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (eventDate: string) =>
    eventDate && formatDateGivenFormatString(eventDate, AppSetting.EVENT_DATE_FORMAT),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (daysToEvent: any) => {
      let className = "";
      if (daysToEvent !== null) {
        if (daysToEvent > 30) className = "ant-tag success";
        else if (daysToEvent >= 15) className = "ant-tag warning";
        else if (daysToEvent <= 14) className = "ant-tag danger";
      }
      return (
        <div className={`${className}`} style={{ float: "right" }}>
          {daysToEvent.toLocaleString()}
        </div>
      );
    },
  },
  {
    title: "Venue Name",
    dataIndex: "venue_name",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (venueName: any) =>
      (venueName && venueName.length) > 20 ? (
        addToolTip(venueName,"right", "default", 20, false, {textTransform: "capitalize"})
      ) : (
        <div>{venueName}</div>
      ),
  },
  {
    title: "Venue Location",
    dataIndex: "venue_location",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (venueLocation: any) =>
      (venueLocation && venueLocation.length) > 20 ? (
        addToolTip(venueLocation,"right", "default", 20, false, {textTransform: "capitalize"})
      ) : (
        <div>{venueLocation}</div>
      ),
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (section: any) => (
      <div className="text-capitalize">
        {section?.length > 20 ? addToolTip(section,"right", "default", 20, false, {textTransform: "capitalize"}) : section}
      </div>
    ),
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-upper",
    dataRender: (row: any) => (
      <div>
        {row}
      </div>
    ),
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (seats: any) => (
      <div className="text-capitalize">
        {seats?.length > 20 ? addToolTip(seats,"right", "default", 20) : seats}
      </div>
    ),
  },
  {
    title: "In-hand Date",
    dataIndex: "inHandDate",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: 50,
    thClassName: "text-right",
    dataRender: (qty: number) => <div className={`text-right ${qty < 0 ? 'text-danger' : ''}`}>{formatNumericValues(qty)}</div>,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (currency: any) => <div className="text-upper">{currency}</div>,
  },
  {
    title: "Unit Cost",
    dataIndex: "cost_price",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (unitCost: number) => <div className={`text-right ${unitCost < 0 ? 'text-danger' : ''}`}>{formatAccountingValues(unitCost)}</div>,
  },
  {
    title: "List Price",
    dataIndex: "list_price",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (listPrice: number) => (
      <div className="text-right">
        <span className={`${listPrice < 1 && "ant-tag danger"}`}>
          {twoDecimalPlacesNumber(listPrice).toLocaleString()}
        </span>
      </div>
    ),
  },
  {
    title: "Invoice Mapping ID",
    dataIndex: "invoice_mapping_id",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (invoiceMappingId: any) =>
      isNaN(invoiceMappingId) ? (
        <div>{invoiceMappingId}</div>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={AppSetting.INVOICE_ID_URL + invoiceMappingId}
        >
          {invoiceMappingId}
        </a>
      ),
  },
  {
    title: "Internal Notes",
    dataIndex: "internal_notes",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Credit Card Group",
    dataIndex: "creditCardGroup",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (creditCardGroup: any) =>
      (creditCardGroup && creditCardGroup.length) > 20 ? (
        addToolTip(creditCardGroup)
      ) : (
        <div className='text-capitalize'>{creditCardGroup}</div>
      ),
  },
  {
    title: "Credit Card",
    dataIndex: "creditCard",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (creditCard: any) =>
      (creditCard && creditCard.length) > 20 ? (
        addToolTip(creditCard)
      ) : (
        <div className='text-capitalize'>{creditCard}</div>
      ),
  },
];
