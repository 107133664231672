import React from "react";
import CardSkeleton from "../CardSkeleton";
import { IEventsWithActiveZoneListingsResponse } from "models/interfaces";
import ListingEventCard from "../ListingEventCard";

const AllZoneListingGridView: React.FC<{
  isLoadingData: boolean;
  listings: IEventsWithActiveZoneListingsResponse | undefined;
  getHeightOfAllZoneListings: Function;
}> = ({ isLoadingData, listings, getHeightOfAllZoneListings }) => {
  return (
    <React.Fragment>
      {isLoadingData ? (
        <div className="zonesGridView">
          <div className="listRow">
            <CardSkeleton isLoading={isLoadingData} />
            <CardSkeleton isLoading={isLoadingData} />
            <CardSkeleton isLoading={isLoadingData} />
            <CardSkeleton isLoading={isLoadingData} />
            <CardSkeleton isLoading={isLoadingData} />
          </div>
        </div>
      ) : (
        <div
          className="zonesGridView zoneListingCardScroll"
          style={{ maxHeight: getHeightOfAllZoneListings() }}
        >
          {Boolean(listings?.events && listings.count > 0) && 
            <div className="listRow">
              {listings?.events.map((eachEvent, i) => {
                return (
                  <ListingEventCard
                    key={eachEvent.event_id + "_" + i}
                    eachEventData={eachEvent}
                  />
                );
              })}
            </div>
          }
          {!Boolean(listings?.events && listings.count > 0) && 
            <div className="noResultFoundBlock">No Result Found</div>
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default AllZoneListingGridView;
