import axiosClient from "sharedServices/api/axios.services";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { LongInventoryData } from "models/interfaces";
import { RoutesEnum } from "models/enums";
import { longInventoryType } from "models/types";

class LongInventoryNotListedService {
    public longInventoryData = async (longInventoryRequest: longInventoryType) => {
        return axiosClient.get<IApiBaseResponse<LongInventoryData>
        >(RoutesEnum.LONG_INVENTORY_NOT_LISTED, {
            params: longInventoryRequest
        })
    }
}

export { LongInventoryNotListedService }