import { Button, message, Select } from "antd";
import {
  IListElement,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
  ISwimlaneMaster
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const ChangeShipperShipping: React.FC<{
  eachInvoiceData: ISwimlaneInvoiceList;
  handleMenuSelection: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
}> = ({
  eachInvoiceData,
  handleMenuSelection,
  requestToReloadTheLanes,
  purchaseQueueMaster,
  swimlaneData,
  setShowMenu
}) => {
  const [shipperList, setShipperList] = useState<IListElement[] | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [newShipperId, setNewShipperId] = useState<string>(
    eachInvoiceData.shipper_id
  );

  const doUpdateChangeShipper = async () => {
    if (eachInvoiceData.shipper_id === newShipperId) {
      message.error({
        content: "Can't re-assign to the same shipper.",
        duration: 5,
        className: "toastMsg savedFailed"
      });
      return false;
    }
    setShowLoader(true);
    const updatingShipperKey = "moveToIssuesKey";
    message.loading({
      content: `Updating shipper...`,
      duration: 0,
      key: updatingShipperKey,
      className: "toastMsg loadingMsg"
    });

    try {
      await new PurchaseAndShippingService().changeShipper({
        invoice_id: eachInvoiceData.invoice_id,
        shipper_id: newShipperId,
        swimlane_id: swimlaneData.id,
      });
      message.success({
        content: `Invoice #${eachInvoiceData.invoice_id} re-assigned from ${shipperList?.find(each => each.value === eachInvoiceData.shipper_id)?.label} to ${shipperList?.find(each => each.value === newShipperId)?.label}`,
        duration: 5,
        key: updatingShipperKey,
        className: "toastMsg savedSuccess",
      });
      handleMenuSelection(null);
      setShowMenu(null);
      requestToReloadTheLanes([swimlaneData.id]);
      setTimeout(() => {
        requestToReloadTheLanes(null);
      }, 100);
    } catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "doUpdateChangeShipper"
      });
      message.error({
        key: updatingShipperKey,
        content: "Failed to change shipper.",
        duration: 5,
        className: "toastMsg savedFailed"
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    purchaseQueueMaster?.shipperMasterData &&
      setShipperList(purchaseQueueMaster.shipperMasterData);
    return () => {
      setShipperList(null);
    };
  }, [purchaseQueueMaster]);

  return (
    <React.Fragment>
      <LoadingSpinner isLoading={showLoader}>
        <div className="accBody">
          <div className="moveOnSaleWrap" style={{ minHeight: 30 }}>
            {shipperList && (
              <React.Fragment>
                <Select
                  options={shipperList}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement as HTMLInputElement
                  }
                  value={newShipperId ? newShipperId : 'Select'}
                  onChange={(value) => {
                    setNewShipperId(value);
                  }}
                />

                <div className="moveOnSaleRow btnGroupFooter">
                  <Button
                    className="discardBtn"
                    onClick={() => {
                      handleMenuSelection(null);
                    }}
                  >
                    <span>CANCEL</span>
                  </Button>
                  <Button
                    className="btnUpdate"
                    disabled={!newShipperId || eachInvoiceData.shipper_id === newShipperId}
                    onClick={doUpdateChangeShipper}
                  >
                    Update
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </LoadingSpinner>
    </React.Fragment>
  );
};

export default ChangeShipperShipping;
