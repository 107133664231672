import { useAppDispatch } from "app/hooks";
import { reset } from "models/Slices/FilterSlice";
import { useEffect } from "react";

const ScheduleWrapper: React.FC = (props) => {

  const dispatch = useAppDispatch();
  useEffect(() => {
    return function cleanup() {
      dispatch(reset());
    };
  }, []);
  return (<div>{props.children}</div>);
};

export default ScheduleWrapper;
