import { Tooltip } from "antd";
import { trigger } from "sharedServices";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import ResendInviteLink from "./Actions/ResendInviteLink";
import NewUser from "./NewUser";
import UserImage from "./../../../assets/images/user-img.png";
import { UserDetailsData } from "models/interfaces";
import React from "react";

const commonWidth = 130;

export const userListingHeaders = [
  {
    title: "Name & Tag",
    dataIndex: "user_name",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (data: any, allData: {data: {singleData: UserDetailsData}}) => {
      const { is_active, email_verified, other_details, profile_picture_public_path } =
        allData.data.singleData;

      let brokerTagName:any = null;
      if (
        other_details &&
        other_details.length &&
        other_details[0] &&
        other_details[0]?.brokerName
      ) {
        brokerTagName = other_details[0]?.brokerName;
      }

      return (
        <div className="flx alignItemsCenter">
          <span className="userImgIcon">

            {profile_picture_public_path &&
              <img src={profile_picture_public_path} alt=""/>
            }
            {!profile_picture_public_path &&
              <img src={UserImage} alt=""/>
            }
            
            </span>
          <strong className="pr5 nowrapTxt">{data}</strong>
          {brokerTagName?.length > 1 && (
            <Tooltip title={`${brokerTagName.map((each: any) => each)}`} className="nowrapTxt">
              <span className="ant-tag info" style={{ cursor: "default" }}>{`${
                brokerTagName[0]
              }`}</span><span style={{verticalAlign: '-3px', cursor: "default"}}>{`+ ${brokerTagName.length - 1} more...`}</span>
            </Tooltip>
          )}
          {brokerTagName?.length === 1 &&
            brokerTagName.map((element: any) => (
              <span className="ant-tag info">{element}</span>
            ))}
          {is_active && !email_verified && (
            <ResendInviteLink userId={allData.data.singleData.id} />
          )}
        </div>
      );
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (data: string) => (
      <a className="userEmail" href={`mailto:${data}`}>
        {data}
      </a>
    ),
  },
  {
    title: "Role",
    dataIndex: "roles",
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (data: any) => {
      return (
        <div className="flx">
          {data && data.map((d: any) => d.role_name).join(", ")}
        </div>
      );
    },
  },
  {
    title: "Broker Team",
    dataIndex: "team_user",
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (data: any, allData: any) => {
      const { id } = allData.data.singleData;

      let brokerTeamData = [];
      if(data){
        brokerTeamData = data.filter((eachData:any)=>eachData.id !== id);
      }

      if (brokerTeamData && brokerTeamData.length === 1) {
        return <div className="">{brokerTeamData[0].user_name}</div>;
      } else if (brokerTeamData && brokerTeamData.length > 1) {
        return (
          <Tooltip
            title={brokerTeamData
              .map((el: any) => `${el.user_name}`)
              .join(", ")} className="nowrapTxt">
            <span style={{ cursor: "default" }}>
              {`${brokerTeamData[0].user_name} + ${brokerTeamData.length - 1} more`}
            </span>
          </Tooltip>
        );
      }
      return <div></div>;
    },
  },
  {
    title: "Status",
    dataIndex: "is_active",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (data: any) => <div>{data ? "Active" : "Inactive"}</div>,
  },
  {
    title: "",
    dataIndex: "id",
    thClassName: "thModifyUser",
    width: 80,
    dataRender: (data: any, allData: any) => {
      const id = data;
      const { userId, maxTicketsAllowed } = allData.otherData;
      const { is_active } = allData.data.singleData;
      
      return (
        <React.Fragment>
        {id !== 'auth0|62ecde542de218f9a7564001' &&
        <div className="tblActions text-left">
          {is_active && (
            <RBAC allowedPermissions={[ERbacPermissions.USER_EDIT]}>
              <NewUser
                maxTicketsAllowed={maxTicketsAllowed}
                userDataOnEdit={allData.data.singleData}
                onSuccessUserSaved={(success: boolean) => {
                  trigger("userAction:click", {
                    action: "updatedUser",
                    data: {
                      success: success,
                    },
                  });
                }}
              />
            </RBAC>
          )}

          {id !== userId && is_active && (
            <RBAC allowedPermissions={[ERbacPermissions.USER_DELETE]}>
              <Tooltip title="Delete" placement="top">
                <div
                  className="pr10"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    trigger("userAction:click", {
                      action: "delete",
                      data: {
                        userData: allData.data.singleData,
                      },
                    });
                  }}
                >
                  <i className="da icon-trash-o" />
                </div>
              </Tooltip>
            </RBAC>
          )}
        </div>
        }
        </React.Fragment>
      );
    },
  },
];
