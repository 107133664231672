import React, { useState, useEffect } from "react";
import { usePageTitle } from "hooks";
import { useLocation } from "react-router";
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from "utils/commonFunction";
import PendingZoneFillsListings from "./PendingZoneFillsListings/PendingZoneFillsListings";
import { Col, Row } from "antd";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { getOffsetDate } from "utils/formatTimePeriod";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { useRbac } from "features/rbac/rbac";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { actions, dateRange } from "models/Slices/FilterSlice";
import { AppSetting } from "utils/appSettings";

let defaultQueryParams: any = {
  client_zone: AppSetting.TIMEZONE,
  page_size: AppSetting.PAGE_SIZE,
  keywords: null,
  sort_column: "invoice_date",
  sort_order: "desc",
};

const PendingFillsPage: React.FC = () => {
  usePageTitle("Pending Fills");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasPermissions } = useRbac();
  const dates = useAppSelector(dateRange);
  const [widgetFilterParams, setWidgetFilterParams] = useState({});
  const [queryEvent, setQueryEvent] = useState(null);
  const [showPendingFillsPage, setShowPendingFillsPage] = useState(false);
  const [isFilterDrawerDisabled, setIsFilterDrawerDisabled] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isPageReady, setIsPageReady] = useState(false);
  const [isDefaultReset, setIsDefaultReset] = useState<boolean>(false);
  const [defaultRange, setDefaultRange] = useState<any>(null);
  const [queryParams, setQueryParams] = useState<any>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [addonFilters, setAddonFilters] = useState<any>(null);

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch("");
    setSearchQuery("");
    defaultQueryParams.keywords = null;
  };

  const filterChanged = (filters: any, isDefault: boolean = false) => {
    let _queryParams = isDefault
      ? {
          ...defaultQueryParams,
          ...filters,
          page_index: AppSetting.DEFAULT_PAGE_INDEX,
        }
      : {
          ...queryParams,
          ...filters,
          page_index: AppSetting.DEFAULT_PAGE_INDEX,
        };

    if (!widgetFilterParams && isDefaultReset) {
      setSearch("");
      _queryParams = {
        ...defaultQueryParams,
        page_index: AppSetting.DEFAULT_PAGE_INDEX,
      };
      setIsDefaultReset(false);
    } else {
      _queryParams = {
        ...defaultQueryParams,
        ...widgetFilterParams,
        ...filters,
        page_index: AppSetting.DEFAULT_PAGE_INDEX,
      };
      setIsDefaultReset(false);
    }
    setQueryParams({
      ..._queryParams,
    });
  };
  useEffect(() => {
    if (dates.length > 0 && isPageReady === false) {
      setDefaultRange({
        preFilledValues: {
          invoice_date_from: dates[0].invoice_date_from,
          invoice_date_to: dates[0].invoice_date_to,
          event_date_from: getOffsetDate(
            OffsetMode.SUBTRACT,
            1,
            OffsetType.DAYS
          ),
          event_date_to: getOffsetDate(OffsetMode.ADD, 1, OffsetType.YEARS),
        },
        defaultValues: {
          invoice_date: {
            defaultFrom: dates[0].invoice_date_from,
            defaultTo: dates[0].invoice_date_to,
          },
          event_date: {
            defaultFrom: `${getOffsetDate(
              OffsetMode.SUBTRACT,
              1,
              OffsetType.DAYS
            )}`,
            defaultTo: `${getOffsetDate(OffsetMode.ADD, 1, OffsetType.YEARS)}`,
          },
        },
      });
      setIsPageReady(true);
    }
  }, [dates, isPageReady]);

  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
  }, [dispatch]);

  useEffect(() => {
    const locationSearch = location.search;
    const queryObject = urlQueryParamsToObject(locationSearch);

    try {
      if (
        queryObject &&
        queryObject.isDisabled &&
        decodeQueryStringData(queryObject.isDisabled).disabled === true
      ) {
        setIsFilterDrawerDisabled(true);
      }
      if (locationSearch && locationSearch.includes("search")) {
        if (queryObject.search) {
          const decodedQuery = decodeQueryStringData(queryObject.search);
          setSearch(decodedQuery.event_id + "");
          defaultQueryParams.keywords = decodedQuery.event_id;
          defaultQueryParams.invoice_date = decodedQuery.invoice_date;
        }
      }
      if (locationSearch && locationSearch.includes("filter")) {
        if (queryObject.filter) {
          const decodedQuery = decodeQueryStringData(queryObject.filter);
          const addonFilter = [];
          addonFilter.push({
            filter_key: "from_page",
            filter_value: decodedQuery.title,
            filter_label: "Pending Fills Type",
            filter_label_value: decodedQuery.title,
          });

          for (const property in decodedQuery) {
            if (property === "days_to_event") {
              addonFilter.push({
                filter_key: property,
                filter_value: decodedQuery[property],
                filter_label: "Days to Event",
                filter_label_value: decodedQuery.days,
              });
            } else if (
              property === "broker_user_Id" ||
              property === "except_broker_user_ids"
            ) {
              addonFilter.push({
                filter_key: property,
                filter_value: decodedQuery[property],
                filter_label: "Broker",
                filter_label_value: decodedQuery.broker,
              });
            }
          }
          setAddonFilters(addonFilter);
        }
      }
      setShowPendingFillsPage(true);
    } catch (e) {
      setShowPendingFillsPage(true);
    }
  }, []);

  return showPendingFillsPage ? (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={18} sm={8} md={8} xl={5} className="mb12">
            <h2 id="inventoryReportTables">All Pending Fills</h2>
          </Col>
          <Col xs={6} sm={4} md={4} xl={2} className="switchAlignment"></Col>
          <Col xs={24} sm={12} md={12} xl={5}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search by Invoice ID, Event Name, Venue"
                  value={search ? search : ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <ul className="innerHeaderRight">
              <li>
                {isPageReady && (
                  <FilterDrawer
                    disabled={isFilterDrawerDisabled ? true : false}
                    showEventDatePicker={isFilterDrawerDisabled ? false : true}
                    showInvoiceDatePicker={
                      isFilterDrawerDisabled ? false : true
                    }
                    showPaymentStatusDropdown={true}
                    paymentStatusDropdownType="single"
                    showBrokerUserDropdown={
                      isFilterDrawerDisabled
                        ? false
                        : hasPermissions([
                            ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                          ])
                    }
                    brokerUserDropdownType="single"
                    onApplyFilter={filterChanged}
                    onApplyFilterWithValues={(e: any) =>
                      setFilterAppliedWithValues(e)
                    }
                    defaultAndValues={defaultRange}
                    addonFilters={addonFilters}
                    setAddonFilters={setAddonFilters}
                  />
                )}
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <PendingZoneFillsListings
        searchQuery={searchQuery}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        filtersAppliedWithValues={filtersAppliedWithValues}
        widgetFilterParams={widgetFilterParams}
        isFilterDrawerDisabled={isFilterDrawerDisabled}
        queryEvent={queryEvent}
      />
    </React.Fragment>
  ) : (
    <></>
  );
};

export default PendingFillsPage;
