import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { usePageTitle } from "hooks";
import VenuesTable from "./VenuesTable";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { useRbac } from "features/rbac/rbac";
import {
  reset,
  venueManagementActions,
  venuesToBeAssigned,
} from "models/Slices/VenuesManagementSlice";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from "utils/commonFunction";
import { useLocation } from "react-router";

const Venues: React.FC = () => {
  usePageTitle("Venues");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasPermissions } = useRbac();

  const selectedVenues = useAppSelector(venuesToBeAssigned);

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    dispatch(actions.getSeatIntelligenceData());

    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL]))
      dispatch(actions.getBrokerUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getListerUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getSeatMapCreatorUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getCheckerUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_VENUE_SELECTION_ALL,
        ERbacPermissions.FILTER_VENUE_SELECTION_OWN,
      ])
    )
      dispatch(actions.getVenuesDatePresets());
  }, [dispatch]);

  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [search, setSearch] = useState<string | null>(null);

  const filterChanged = (filters: any) => {
    getVenueDetails({ page_index: 1, ...filters });
  };

  const [filterQueryObject, setFilterQueryObject] = useState<{
    broker_user_Id: string | null;
    lister_user_Id: string | null;
    checker_user_id: string | null;
    seat_map_creator_user_id: string | null;
    venue_scraping_type_id: number | null
  }>({
    broker_user_Id: null,
    lister_user_Id: null,
    checker_user_id: null,
    seat_map_creator_user_id: null,
    venue_scraping_type_id: null
  });

  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes("filter")) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.filter) {
        let filterQueryString = decodeQueryStringData(queryObject.filter);
       
        if(
            filterQueryString.broker_user_Id || 
            filterQueryString.lister_user_Id || 
            filterQueryString.checker_user_id || 
            filterQueryString.seat_map_creator_user_id
          ) {
          setFilterQueryObject({
            broker_user_Id: filterQueryString.broker_user_Id ? filterQueryString.broker_user_Id : null,
            lister_user_Id: filterQueryString.lister_user_Id ? filterQueryString.lister_user_Id : null,
            checker_user_id: filterQueryString.checker_user_id ? filterQueryString.checker_user_id : null,
            seat_map_creator_user_id: filterQueryString.seat_map_creator_user_id ? filterQueryString.seat_map_creator_user_id : null,
            venue_scraping_type_id: filterQueryString.venue_scraping_type_id ? filterQueryString.venue_scraping_type_id : null
          });
        }
      }
    }
    setIsPageReady(true);
    return function cleanup() {
      dispatch(reset());
    };
  }, []);

  const handleSearch = (e: any) => {
    e.preventDefault();
    getVenueDetails({ page_index: 1, search_text: search });
  };

  const closeSearch = () => {
    setSearch("");
    getVenueDetails({ page_index: 1, search_text: null });
  };

  const getVenueDetails = async (query: any) => {
    dispatch(
      venueManagementActions.getVenueListData({
        query: query,
        functionName: "getVenueDetails",
      })
    );
  };

  return (
    <>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={24} xl={7} xxl={9} className="mb12">
            <h2>Venues</h2>
          </Col>
          <Col xs={20} xl={8} xxl={6}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search event, venue, broker, city, state..."
                  value={search ? search : ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={4} xl={9} xxl={9}>
            <div className="rightFilter">
              {selectedVenues.length > 0 && (
                <span>
                  {selectedVenues.length}{" "}
                  {selectedVenues.length === 1 ? "venue" : "venues"} selected
                </span>
              )}
              {isPageReady && (
                <FilterDrawer
                  defaultAndValues={{
                    preFilledValues: { ...filterQueryObject },
                  }}
                  onApplyFilter={filterChanged}
                  showBrokerUserDropdown={hasPermissions([
                    ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                  ])}
                  brokerUserDropdownType="single"
                  showListerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
                  ])}
                  listerDropdownType="single"
                  showSeatMapCreatorDropdown={hasPermissions([
                    ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
                  ])}
                  seatMapCreatorDropdownType="single"
                  showCheckerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
                  ])}
                  checkerDropdownType="single"
                  showStateDropdown={true}
                  stateDropdownType="single"
                  showNewVenuesDropdown={hasPermissions([
                    ERbacPermissions.FILTER_VENUE_SELECTION_ALL,
                    ERbacPermissions.FILTER_VENUE_SELECTION_OWN,
                  ])}
                  newVenuesDropdownType="single"
                  showSeatIntelligenceDropdown={true}
                  showVenueScrapingTypeDropdown={true}
                  venueScrapingTypeDropdownType="single"
                  showOnlyFavoritesCheckbox={true}
                  onApplyFilterWithValues={(e: any) =>
                    setFilterAppliedWithValues(e)
                  }
                  seatIntelligenceDropdownType="single"
                />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Layout className="mainInnerLayout pb-0 reduceTopSpace">
        <VenuesTable
          appliedFilterValues={filtersAppliedWithValues}
          getVenueDetails={getVenueDetails}
        />
      </Layout>
    </>
  );
};

export default Venues;
