import { useEffect, useState, memo } from "react";
import { Button, Tooltip } from "antd";
import RefreshIcon from "../../assets/images/reload-icon-white2.svg";

const timerCounterConstantVal = 60 * 5;
let _timerCounter = timerCounterConstantVal;
let _startTimer = false;
let _numberOfReloadLane = 0;

const AutoReloadCounter: React.FC<{
  isResetTimer: boolean;
  doReloadLanes: Function;
}> = ({ isResetTimer, doReloadLanes }) => {
  /** For Auto or Force Refresh */
  const [timerCounter, setTimerCounter] = useState(timerCounterConstantVal);
 // const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (_startTimer && _numberOfReloadLane > 0) {
        _timerCounter = _timerCounter - 1;
        setTimerCounter(_timerCounter);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      _timerCounter = timerCounterConstantVal;
      _numberOfReloadLane = 0;
    };
  }, []);

  useEffect(() => {
    if (timerCounter === 0) {
      forceRefresh();
    }
  }, [timerCounter]);

  const resetTimer = () => {
    _timerCounter = timerCounterConstantVal;
    setTimerCounter(timerCounterConstantVal);
    _numberOfReloadLane++;
    _startTimer = true;
  };

  useEffect(() => {
    if (isResetTimer) {
      resetTimer();
    }
  }, [isResetTimer]);

  const forceRefresh = () => {
    doReloadLanes(true);
    _startTimer = false;
  };

  const displayTimer = () => {
    let minutes = parseInt((timerCounter / 60).toString(), 10);
    let seconds = parseInt((timerCounter % 60).toString(), 10);
    const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;
    const secondsFormatted = seconds < 10 ? "0" + seconds : seconds;
    const textContent =
      (_startTimer && timerCounter >= 0 || _numberOfReloadLane === 0)
        ? minutesFormatted + ":" + secondsFormatted
        : "00:00";
    return <span className={"shippingQueueTimer"}>{textContent}</span>;
  };

  return (
    <div
      className={
        timerCounter <= 60
          ? "shippingAutoReloadArea shippingAutoReloadAreaDanger"
          : "shippingAutoReloadArea"
      }
    >
      <Tooltip title="Force Refresh">
        <Button
          type="primary"
          className="danger"
          size="small"
          onClick={() => forceRefresh()}
          icon={
            <img
              src={RefreshIcon}
              alt="Refresh"
              style={{ marginLeft: "-7px" }}
            />
          }
        >
          {displayTimer()}
        </Button>
      </Tooltip>
    </div>
  );
};

export default memo(AutoReloadCounter);
