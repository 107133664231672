import axiosClient from "sharedServices/api/axios.services";
import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import {
  IChangeBuyerBodyData,
  IChangePriorityData,
  IChangeShipperBodyData,
  IInvoiceDetailModalData,
  IIssueReminderData,
  IIssueResData,
  IIssuesData,
  IKebabMenuData,
  IMarkAsDeliveredPostData,
  IMarkAsNotDeliveredPostData,
  IMoveToAddNotesPostData,
  IMoveToNeedToBuyPostData,
  IMoveToSellOutPostData,
  IMoveToShippingData,
  IOnSaleChangeData,
  IOnSaleData,
  IOnSaleReminderData,
  IPurchaseBody,
  IPurchaseQueueAuditHistory,
  IPurchaseQueueMasterData,
  IPurchaseQueueNotesHistory,
  ISwimlaneInvoiceData,
  ISwimlaneMasterList,
} from "models/interfaces";

export class PurchaseAndShippingService {
  public getSwimLaneMaster = (queue_type: number) => {
    return axiosClient.get<IApiBaseResponse<ISwimlaneMasterList>>(
      RoutesEnum.PURCHASE_QUEUE_GET_SWIMLANE_MASTER_LIST,
      {
        params: {
          queue_type
        }
      }
    );
  };

  public getSwimLaneInvoiceList = (swimlaneId: number, queryParams: any) => {
    return axiosClient.get<IApiBaseResponse<ISwimlaneInvoiceData>>(
      RoutesEnum.PURCHASE_QUEUE_GET_SWIMLANE_INVOICE_LIST,
      {
        params: { swimlane_id: swimlaneId, ...queryParams },
      }
    );
  };

  public getInvoiceDetailModalData = (invoice_id: number) => {
    return axiosClient.get<IApiBaseResponse<IInvoiceDetailModalData>>(
      RoutesEnum.PURCHASE_QUEUE_INVOICE_DETAIL_MODAL_DATA,
      {
        params: { invoice_id },
      }
    );
  };

  public moveToOnSale = (onSaleData: IOnSaleData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_ON_SALE,
      { ...onSaleData }
    );
  };

  public moveToPurchased = (purchasedBody: IPurchaseBody) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_PURCHASED,
      { ...purchasedBody }
    );
  };

  public changeBuyer = (bodyData: IChangeBuyerBodyData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_CHANGE_BUYER, bodyData
    );
  };

  public getPurchaseQueueMasterData = () => {
    return axiosClient.get<IApiBaseResponse<IPurchaseQueueMasterData>>(
      RoutesEnum.PURCHASE_QUEUE_MASTER_DATA,
      {
        params: null,
      }
    );
  };

  public moveToIssues = (issuesData: IIssuesData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_ISSUES,
      { ...issuesData }
    );
  };

  public getKebabMenuOptions = () => {
    return axiosClient.get<IApiBaseResponse<IKebabMenuData>>(
      RoutesEnum.PURCHASE_QUEUE_GET_KEBAB_MENU_OPTIONS,
      {
        params: null,
      }
    )
  }

  public changePriority = (priorityData: IChangePriorityData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_CHANGE_PRIORITY,
      { ...priorityData }
    );
  }

  public moveToShipping = (shippingData: IMoveToShippingData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_SHIPPING,
      { ...shippingData }
    );
  }

  public moveToNeedToBuy = (postData: IMoveToNeedToBuyPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_NEED_TO_BUY,
      postData
    );
  }

  public moveToNeedToBuyLowPriority = (postData: IMoveToNeedToBuyPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_NEED_TO_BUY_LOW_PRIORITY,
      postData
    );
  }

  public moveToAddNotes = (postData: IMoveToAddNotesPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_MOVE_TO_ADD_NOTES,
      postData
    );
  }

  public getAuditHistory = (invoiceId: number) => {
    const URL = RoutesEnum.PURCHASE_QUEUE_GET_AUDIT_HISTORY.replace(
      "{invoice_id}",
      invoiceId.toString()
    );
    return axiosClient.get<IApiBaseResponse<IPurchaseQueueAuditHistory[]>>(URL);
  }

  public getNotesHistory = (invoiceId: number) => {
    const URL = RoutesEnum.PURCHASE_QUEUE_GET_NOTES_HISTORY.replace(
      "{invoice_id}",
      invoiceId.toString()
    );
    return axiosClient.get<IApiBaseResponse<IPurchaseQueueNotesHistory[]>>(URL);
  }

  public saveIssueReminder = (issueReminderData: IIssueReminderData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_SAVE_ISSUE_REMINDER,
      { ...issueReminderData }
    );
  }

  public updateIssue = (issuesData: IIssuesData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_UPDATE_ISSUE,
      { ...issuesData }
    );
  }

  public closeIssue = (issueResData: IIssueResData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_CLOSE_ISSUE,
      { ...issueResData }
    );
  }

  public closeIssueShipping = (issueResData: IIssueResData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_CLOSE_ISSUE,
      { ...issueResData }
    );
  }

  public changeOnSaleStatus = (onSaleChangeData: IOnSaleChangeData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_CHANGE_ONSALE_STATUS,
      { ...onSaleChangeData }
    );
  }

  public saveOnSaleReminder = (onSaleReminderData: IOnSaleReminderData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.PURCHASE_QUEUE_SAVE_ONSALE_REMINDER,
      { ...onSaleReminderData }
    );
  }


  //Shipping services
  public moveToIssuesShipping = (issuesData: IIssuesData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_MOVE_TO_ISSUES,
      { ...issuesData }
    );
  };

  public moveToAddNotesShipping = (postData: IMoveToAddNotesPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_MOVE_TO_ADD_NOTES,
      postData
    );
  }

  public changeShipper = (bodyData: IChangeShipperBodyData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_CHANGE_SHIPPER, bodyData
    );
  };

  public updateIssueShipping = (issuesData: IIssuesData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_UPDATE_ISSUE,
      { ...issuesData }
    );
  }

  public markAsDeliveredShipping = (postData: IMarkAsDeliveredPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_MARK_AS_DELIVERED,
      { ...postData }
    );
  };

  public markAsNotDeliveredShipping = (postData: IMarkAsNotDeliveredPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_MARK_AS_NOT_DELIVERED,
      { ...postData }
    );
  };

  public moveToSellOutShipping = (issuesData: IMoveToSellOutPostData) => {
    return axiosClient.post<IApiBaseResponse>(
      RoutesEnum.SHIPPING_QUEUE_MOVE_TO_SELL_OUT,
      { ...issuesData }
    );
  };
}
