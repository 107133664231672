import { Row, Col, Pagination, Modal, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import CustomError from "controls/CustomError/CustomError";
import {
  ICustomEventData,
  IFilterOptions,
  IScheduleListResponse,
  IScheduleListResponseData,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import {
  deleteScheduleQueryType,
  scheduleListType,
  toggleScheduleQueryType,
} from "models/types";
import React, { useEffect, useRef, useState } from "react";
import { SchedulerService } from "services/schedulerServices/scheduler.service";
import { LoggerService } from "sharedServices/api/logger.service";
import { off, on } from "sharedServices/customEvents/events";
import { AppSetting } from "utils/appSettings";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { formatReportCount, formatThousandSeparator } from "utils/commonFunction";
import CommonReportTable from "utils/table/CommonReportTable";
import { schedulerListHeaders } from "./SchedulerListUtils";

const { confirm } = Modal;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;
let currentCustomEventData: ICustomEventData | null = null;

const ScheduleListTable: React.FC<{ filterObject: IFilterOptions | null }> = ({
  filterObject,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<scheduleListType>({
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "nextRunAt",
    sort_order: "asc",
    search_text: "",
  });
  const [queryParams, setQueryParams] = useState<scheduleListType | null>(null);
  const [scheduleListResponse, setScheduleListResponse] =
    useState<IScheduleListResponseData | null>(null);
  const [scheduleListData, setScheduleListData] = useState<
    IScheduleListResponse[] | null
  >(null);
  const [search, setSearch] = useState("");
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const actionRef = useRef<HTMLDivElement | null>(null);

  const getScheduleListData = async (query: scheduleListType) => {
    try {
      setShowLoader(true);
      const response = await new SchedulerService().getScheduleList({
        ...query,
      });
      setScheduleListResponse(response?.data?.data);
      let responseData = response?.data?.data;
      responseData.scheduleListResponse = handleHighlightedRow(
        response.data.data.scheduleListResponse
      );
      setScheduleListData(responseData.scheduleListResponse);
      setShowLoader(false);
      setDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getScheduleListData",
      });
      setDataFetchError({ ...error, refreshAction: getScheduleListData });
    }
  };

  const handleHighlightedRow = (
    responseReportData: IScheduleListResponse[]
  ) => {
    if (responseReportData) {
      responseReportData = responseReportData.map(
        (eachReportData: IScheduleListResponse) => {
          return {
            ...eachReportData,
            activeSelectedClass: +eachReportData?.status ? null : "inactive",
          };
        }
      );
    }

    return responseReportData;
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setQueryParams({
      ...queryParams,
      page_index: DEFAULT_PAGE_INDEX,
      search_text: search,
    });
  };

  const closeSearch = () => {
    setSearch("");
    setQueryParams({
      ...queryParams,
      page_index: DEFAULT_PAGE_INDEX,
      search_text: "",
    });
  };

  /***********************| ACTION CLICK HANDLER|***********************/
  const actionHandler = async (
    scheduleTableData: IScheduleListResponse[] | null
  ) => {
    if (currentCustomEventData !== null && scheduleTableData !== null) {
      switch (currentCustomEventData.action) {
        case "toggle":
          const response = await toggleScheduleStatus({
            scheduleId: currentCustomEventData.data.id,
            status: !currentCustomEventData.data.status,
          });

          setScheduleListData(
            handleHighlightedRow(
              scheduleTableData.map((eachSchedule) => {
                if (eachSchedule?.id === currentCustomEventData?.data?.id) {
                  return {
                    ...eachSchedule,
                    status: !currentCustomEventData?.data?.status,
                    nextRunAt: response ? response : null,
                  };
                }
                return eachSchedule;
              })
            )
          );
          break;
        case "delete":
          const header = (
            <span>
              Are you sure you want to <strong>delete</strong> this schedule?
            </span>
          );
          const scheduleName = currentCustomEventData?.data.title;
          const scheduleId = currentCustomEventData?.data.id;
          showConfirm(
            header,
            scheduleName,
            { schedule_id: scheduleId, is_forced: false },
            deleteSchedule,
            cancelHandler
          );
          break;
        case "edit":
          handleCaseEdit();
          break;
      }
    }
  };

  const handleCaseEdit = () => {
    const editKey = "saveSchedule";
    if (currentCustomEventData?.data) {
      setQueryParams({ ...queryParams, page_index: DEFAULT_PAGE_INDEX });
      return;
    }
    message.error({
      content: "Failed to save schedule.",
      duration: 5,
      key: editKey,
      className: "toastMsg savedFailed",
    });
  }

  /*********************| TOGGLE ACTIONS |***********************/

  const toggleScheduleStatus = async (query: toggleScheduleQueryType) => {
    try {
      const response = await new SchedulerService().toggleSchedule({
        ...query,
      });
      return response.data.data.next_scheduled_time;
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "toggleStatus",
      });
      message.error({
        content: "Failed to activate/deactivate schedule.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
  };

  /*********************| DELETE ACTIONS |***********************/

  const cancelHandler = (_data: any) => {
    console.log("CANCELLED");
  };

  const deleteSchedule = async (query: deleteScheduleQueryType) => {
    const deleteKey = "deleteSchedule";
    try {
      message.loading({
        content: "Deleting schedule...",
        duration: 50,
        key: deleteKey,
        className: "toastMsg loadingMsg",
      });
      const response = await new SchedulerService().deleteSchedule({
        ...query,
      });
      if (response.data.data) {
        setQueryParams({ ...queryParams, page_index: DEFAULT_PAGE_INDEX });
        message.success({
          content: "Schedule has been successfully deleted.",
          key: deleteKey,
          duration: 5,
          className: "toastMsg deletedSuccess",
        });
      }
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "deleteSchedule",
      });
      message.error({
        content: "Failed to delete schedule.",
        duration: 5,
        key: deleteKey,
        className: "toastMsg deletedFailed",
      });
    }
  };
  /**************************************************************/

  /********| CUSTOM EVENT HANDLER |*******/
  useEffect(() => {
    const actionHandlerForToggling = (data: CustomEvent) => {
      currentCustomEventData = data.detail;
      actionRef?.current?.click();
    };
    on("scheduleAction:click", actionHandlerForToggling);
    return () => {
      off("scheduleAction:click", actionHandlerForToggling);
    };
  }, []);

  /********| FILTER DATA CHANGE HANDLER |*******/
  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  /*******| TABLE DATA FETCH HANDLER |******/
  useEffect(() => {
    if (queryParams !== null) {
      getScheduleListData(queryParams);
    }
  }, [queryParams]);

  const pageSize = queryParams ? queryParams.page_size : 1;
  const tableData = scheduleListData ? scheduleListData : null;

  return (
    <React.Fragment>
      <div
        ref={actionRef}
        style={{ display: "none" }}
        onClick={() => actionHandler(scheduleListData)}
      >
        Actions
      </div>
      {dataFetchError ? (
        <CustomError {...dataFetchError} />
      ) : (
        queryParams && (
          <Content className="mainContainer">
            <div className="innerHeader">
              <Row className="flx justifyContentBetween alignItemsCenter">
                <Col span={5}>
                  <h3>Scheduler</h3>
                </Col>
                <Col span={7}>
                  <div className="search">
                    <form onSubmit={handleSearch}>
                      <input
                        type="search"
                        placeholder="Search by Report Name, Report Title, Recipients name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      {search.length > 0 && (
                        <span onClick={closeSearch}>&times;</span>
                      )}
                    </form>
                  </div>
                </Col>
                <Col span={12}>
                  {Boolean(!showLoader &&
                    scheduleListResponse &&
                    scheduleListResponse?.total) &&
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>
                          {formatThousandSeparator(scheduleListResponse?.total ?? 0)}
                          {formatReportCount(scheduleListResponse?.total ?? 0)}
                        </h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          hideOnSinglePage={true}
                          current={queryParams?.page_index}
                          total={scheduleListResponse?.total}
                          pageSize={pageSize}
                          onChange={(pageNumber) =>
                            setQueryParams({
                              ...queryParams,
                              page_index: pageNumber,
                            })
                          }
                          showSizeChanger={false}
                        />
                      </li>
                    </ul>
                  }
                  {!Boolean(!showLoader &&
                    scheduleListResponse &&
                    scheduleListResponse?.total) &&
                    <div style={{ height: 21 }}></div>
                  }
                </Col>
              </Row>
            </div>
            <div className="removeTblLRSpc">
              <div className="reportSchedules">
                <CommonReportTable
                  tableClass="customTbl scroll-y fixedHeaderFooter"
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    setQueryParams({
                      ...queryParams,
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                    });
                  }}
                  sortField={{
                    dataIndex: queryParams?.sort_column
                      ? queryParams.sort_column
                      : "nextRunAt",
                    order: queryParams?.sort_order
                      ? queryParams.sort_order
                      : "asc",
                  }}
                  headers={schedulerListHeaders}
                  data={tableData}
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  loading={showLoader}
                  enableRowSelect={false}
                />
              </div>
            </div>
          </Content>
        )
      )}
    </React.Fragment>
  );
};

export default ScheduleListTable;
