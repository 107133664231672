import ReactDOM from "react-dom";
import App from "App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "app/store";
import Auth0ProviderWithHistory from "auth/auth0ProviderWithHistory";
import "index.css";
import PageLoader from "utils/sharedComponents/PageLoader";

ReactDOM.render(<PageLoader/>, document.getElementById("root"));
ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById("root")
);
