import { HighRiskCategory } from "models/enums/highRiskCategory";
import { IHighRiskListings } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import ListingAlertsCard from "./ListingAlertsCard";

const LowAvailability: React.FC<{
  widgetId: number;
}> = ({ widgetId }) => {
  const [isLoadingLowAvailability, setIsLoadingLowAvailability] =
    useState(false);
  const [lowAvailabilityFetchError, setLowAvailabilityFetchError] =
    useState<ICustomError | null>(null);
  const [lowAvailabilityData, setLowAvailabilityData] =
    useState<IHighRiskListings | null>(null);

  const getLowAvailability = async () => {
    setIsLoadingLowAvailability(true);
    try {
      const response =
        await new OperationsDashboardService().getHighRiskListings({
          risk_type: HighRiskCategory.low_availability,
        });
      setLowAvailabilityData(response.data.data);
      setIsLoadingLowAvailability(false);
      setLowAvailabilityFetchError(null);
    } catch (error: any) {
      setIsLoadingLowAvailability(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getLowAvailability",
      });
      setLowAvailabilityFetchError({
        ...error,
        refreshAction: getLowAvailability,
      });
    }
  };

  useEffect(() => {
    getLowAvailability();
  }, []);

  return (
    <div className="highRiskCardCol">
      <ListingAlertsCard
        errorObj={lowAvailabilityFetchError}
        title={"LOW AVAILABILITY"}
        isLoading={isLoadingLowAvailability}
        data={lowAvailabilityData}
        id={widgetId}
      />
    </div>
  );
};

export default LowAvailability;
