import { Empty, Table } from "antd";
import { useAppSelector } from "app/hooks";
import CustomError from "controls/CustomError/CustomError";
import {
  dataFetchError,
  hasError,
  isLoadingAuditHistory,
  metadataAuditData,
  metadataTrendData,
} from "models/Slices/EventManagementSlice";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { auditHistoryTableHeaders } from "./AuditHistoryTableUtils";

const AuditHistoryTable: React.FC = () => {
  const dataSource = useAppSelector(metadataAuditData);
  const trendMetadata = useAppSelector(metadataTrendData);
  const isLoading = useAppSelector(isLoadingAuditHistory);
  const error = useAppSelector(hasError);
  const errorObj = useAppSelector(dataFetchError);
  const getHeight = () => {
    return trendMetadata?.trendData?.length ? trendMetadata?.trendData?.length * 80 + 30 : 490;
  }
  return (
    <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-7 ant-col-xl-7 revisionHistory">
      <h3>Revision History</h3>
      {error ? (
        <CustomError {...errorObj} />
      ) : (
        <LoadingSpinner isLoading={isLoading}>
          <div
            className="customTbl scroll-y revisionHistoryTbl"
            style={{ maxHeight:`${getHeight()}px` }}
          >
            <Table
              id="metadata_audit_history_table"
              dataSource={dataSource}
              columns={auditHistoryTableHeaders}
              pagination={false}
              className="main-table"
              showHeader={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data found"
                  />
                ),
              }}
              rowClassName={(_record, index)=>`row${index}`}
            />
          </div>
        </LoadingSpinner>
      )}
    </div>
  );
};

export default AuditHistoryTable;
