import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import FilterDrawer from 'utils/commonFilter/FilterDrawer';
import ActiveZoneListingReportTable from './ActiveZoneListingReportTable';
import { useLocation } from 'react-router';
import CreateScheduler from 'utils/commonScheduler/createScheduler';
import { IFilterOptions, IReportQueryString } from 'models/interfaces';
import { usePageTitle } from 'hooks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { actions, dateRange } from 'models/Slices/FilterSlice';
import ReportLayout from '../../ReportLayout';
import { RBAC, useRbac } from 'features/rbac/rbac';
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from 'utils/commonFunction';
import { getCurrentYear } from 'utils/formatTimePeriod';
import { ERbacPermissions } from 'features/rbac/rbacPermissionsList';

const ActiveZoneListingReportPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(dateRange);
  const { hasPermissions } = useRbac();
  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    dispatch(actions.getRecipientFilterData());
  }, [dispatch]);

  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report,
  );

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [ifShowFilterDrawer, setIfShowFilterDrawer] = useState(false);
  const [filterQueryString, setFilterQueryString] = useState<any>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);
  const [outerLoader, setOuterLoader] = useState(false);

  usePageTitle('Active Zone Listing Report');

  useEffect(() => {
    setOuterLoader(true);
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }

    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getListerUserData());
    }
    if (location.search.includes('filter')) {
      setFilterQueryString(decodeQueryStringData(queryParams.filter));
    }
  }, []);

  useEffect(() => {
    if (dates.length > 0) {
      if (!filterQueryString) {
        setDefaultDateRange({
          preFilledValues: {
            po_date_from: getCurrentYear()[0],
            po_date_to: getCurrentYear()[1],
          },
          defaultValues: {
            po_date: {
              defaultFrom: getCurrentYear()[0],
              defaultTo: getCurrentYear()[1],
            },
          },
        });
        setIfShowFilterDrawer(true);
      } else {
        setDefaultDateRange({
          preFilledValues: {
            po_date_from:
              filterQueryString?.po_date_from ?? getCurrentYear()[0],
            po_date_to: filterQueryString?.po_date_to ?? getCurrentYear()[1],
          },
          defaultValues: {
            po_date: {
              defaultFrom: getCurrentYear()[0],
              defaultTo: getCurrentYear()[1],
            },
          },
        });
        setIfShowFilterDrawer(true);
      }
    }
  }, [dates]);

  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch('');
    setSearchQuery('');
  };

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col span={9}>
            <h2>{reportQueryString.current_report}</h2>
          </Col>
          <Col span={6}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search event name, venue name, state or po number..."
                  value={search ? search : ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col span={9}>
            <div className="rightFilter">
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.REPORT_ACTIVE_ZONE_LISTING_VIEW_SCHEDULE,
                ]}
              >
                <CreateScheduler
                  schedulerTitle={'Create Schedule'}
                  reportData={reportQueryString}
                  filterData={filterObject}
                />
              </RBAC>
              {ifShowFilterDrawer && (
                <FilterDrawer
                  current_report_id={reportQueryString.current_report_id}
                  showPODatePicker={true}
                  showBrokerUserDropdown={hasPermissions([
                    ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                  ])}
                  brokerUserDropdownType="single"
                  showListerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
                  ])}
                  listerDropdownType="single"
                  onApplyFilter={filterChanged}
                  onApplyFilterWithValues={(e: any) =>
                    setFilterAppliedWithValues(e)
                  }
                  defaultAndValues={defaultDateRange}
                  showTbPlacementCheckbox={true}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <ActiveZoneListingReportTable
            filterObject={filterObject}
            appliedFilterValues={filtersAppliedWithValues}
            searchQuery={searchQuery}
          />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default ActiveZoneListingReportPage;
