import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { usePageTitle } from "hooks";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import {
  IFilterOptions,
  IBotPerformanceChartList,
} from "models/interfaces";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { getCurrentDate, getOffsetDate } from "utils/formatTimePeriod";
import { BotPerformanceDashboardService } from "services/dashboardServices/botPerformanceDashboard.service";
import BotPerformanceWidgets from "./Widgets/BotPerformanceWidgets";

const BotPerformanceDashboardPage: React.FC = () => {
  usePageTitle("Bot Performance Dashboard");
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>({
    custom_date_from: getOffsetDate(OffsetMode.SUBTRACT, 90, OffsetType.DAYS),
    custom_date_to: getCurrentDate(),
  });
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  const [chartData, setChartData] = useState<any>(null);

  const getBotPerformanceData = async () => {
    let res =
      await new BotPerformanceDashboardService().getBotPerformanceData();
    setChartData(res.data.data.data);
  };

  useEffect(() => {
    getBotPerformanceData();
    return () => {};
  }, []);

  return (
    <div className="fixedFilterArea">
      <div className="mainHeading">
        <div className="dashboardTopBar">
          <div className="flx alignItemsFlexEnd">
            <h2>BOT PERFORMANCE DASHBOARD</h2>
          </div>
          <FilterDrawer
            customDatePickerLabel={"SCRAPING DATE"}
            onApplyFilter={filterChanged}
            showCustomDatePicker={true}
            onApplyFilterWithValues={setFilterAppliedWithValues}
          />
        </div>
      </div>
      <div className="innerHeader">
        <Row className="flx justifyContentBetween alignItemsCenter">
          <Col xl={12} xxl={16}>
            <AppliedFilters appliedFilters={filtersAppliedWithValues} />
          </Col>
        </Row>
      </div>
      {chartData && chartData.chart_rows && chartData.chart_rows.length > 0 && (
        <React.Fragment>
          {chartData.chart_rows.map(
            (chartRows: IBotPerformanceChartList, index: number) => (
              <BotPerformanceWidgets chartRows={chartRows} key={`bot_health_${index}`} filterData={filterObject}/>
            )
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default BotPerformanceDashboardPage;
