import { Col } from "antd";

const InvoicesDelivered = () => {
    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
            <div className="dashboardChartCard">
                <div className="dashboardCard mainChartWidget">
                    <div className="chartTopbar">
                        <h5>Invoices Delivered</h5>
                        {/* <div className="titleLerge text-left">55 Invoices</div> */}
                    </div>
                    <div className='comingsoon comingsoonLrg' style={{height:250}}></div>
                    {/* <div className="chartBody">
                        <div>
                            <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                            />
                        </div>
                    </div>
                    <div className="chartFooter">
                        <div className="chartBtmBar">
                            <ul>
                                <li>
                                    <a href="#" className="active">
                                        Day
                                    </a>
                                </li>
                                <li>
                                    <a href="#/">Week</a>
                                </li>
                                <li>
                                    <a href="#/">Month</a>
                                </li>
                                <li>
                                    <a href="#/">Quarter</a>
                                </li>
                                <li>
                                    <a href="#/">Year</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                     */}
                </div>
            </div>
        </Col>
    )
}

export default InvoicesDelivered