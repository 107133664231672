import React from "react";
import { Tag, Tooltip } from "antd";
import { IEachEvent } from "models/interfaces";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { showBrokerOrSeatMapCreatorCheckerLister } from "./eventsUtils";
import { Link } from "react-router-dom";
import {
  addToolTip,
  daysToEventColorCheck,
  getEncodedData,
  getSeatAvailabilityDetails,
  getUserName
} from "utils/commonFunction";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { AppSetting } from "utils/appSettings";
import EventCardActions from "utils/sharedComponents/EventCardActions";

const EventEachCard: React.FC<{ eachEventData: IEachEvent }> = ({
  eachEventData
}) => {
  const { hasPermissions } = useRbac();

  const getLastUpdatedBy = (updatedBy: string) => {
    const lastUpdatedData = JSON.parse(updatedBy),
      userName = getUserName(lastUpdatedData);

    if (lastUpdatedData.compare_date > 1) {
      return (
        <>
          Last updated {lastUpdatedData.compare_date} days ago by {userName}
        </>
      );
    } else if (lastUpdatedData.compare_date === 1) {
      return (
        <>
          Last updated {lastUpdatedData.compare_date} day ago by {userName}
        </>
      );
    } else if (lastUpdatedData.compare_date === 0) {
      return <>Last updated today by {userName}</>;
    }
    return <></>;
  };

  const getSeatAvailabilityTopBar = (seat_availability_score: string) => {
    const availabilityDetails = getSeatAvailabilityDetails(
      seat_availability_score
    );

    return (
      <Tooltip placement="top" title={availabilityDetails.title}>
        <div
          className={`seatAvailableLogicTopBar ${availabilityDetails.color}`}
          style={{ padding: "0" }}
        ></div>
      </Tooltip>
    );
  };

  return (
    <div className="eventCardWrap">
      <div className="eventCardContent">
        {getSeatAvailabilityTopBar(eachEventData.seat_availability_score)}
        <div className="lastUpdated">
          <span> {getLastUpdatedBy(eachEventData.updated_by)} </span>
        </div>
        <div className="eventCardBody">
          <div className="eventCardBodyInnerGrid">
            <div className="eventDesc text-capitalize">
              <p>
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href={AppSetting.EVENT_URL + eachEventData.event_id}
                  className="zoneEventName"
                >
                  <strong>
                    {eachEventData.event_name?.length > 18
                      ? addToolTip(
                          eachEventData.event_name,
                          "right",
                          "cursor",
                          18,
                          false,
                          { textTransform: "capitalize" }
                        )
                      : eachEventData.event_name}
                  </strong>
                </a>
                &nbsp;
                {eachEventData.is_new_event && (
                  <Tag className="success">New</Tag>
                )}
                {eachEventData.is_sold_out && (
                  <Tag className="danger tagSmall">Sold Out</Tag>
                )}
                {eachEventData.is_event_cancelled && (
                  <Tag className="danger tagSmall">Cancelled</Tag>
                )}
                {eachEventData.event_id < 0 && (
                  <Tag className="danger tagSmall">Not on POS</Tag>
                )}
                <br />
                {/* Sat, Aug 28, 2021 at 7:30 PM */}
                {formatDateGivenFormatString(
                  eachEventData.event_date,
                  "ddd, MMM DD, YYYY h:mm A"
                )}
              </p>
            </div>
            <EventCardActions eachEventData={eachEventData} />
          </div>
          <p className="text-capitalize">
            {eachEventData.venue_name?.length > 30
              ? addToolTip(
                  eachEventData.venue_name,
                  "right",
                  "cursor",
                  30,
                  false,
                  { textTransform: "capitalize" }
                )
              : eachEventData.venue_name}
            <br />
            {eachEventData.venue_location}
          </p>
          <div className="ranking">
            <div
              className={
                "rankingInner " +
                daysToEventColorCheck(eachEventData.days_to_event, "grid")
                  .className
              }
            >
              <Tooltip
                placement="top"
                title={
                  daysToEventColorCheck(eachEventData.days_to_event, "grid")
                    .toolTipText
                }
              >
                <span className="rankNumber" style={{ cursor: "default" }}>
                  {eachEventData.days_to_event}
                </span>
              </Tooltip>
              <div>
                Days <br />
                to event
              </div>
            </div>
            <div className="rankingInner default">
              {eachEventData.active_listings > 0 && (
                <React.Fragment>
                  <span className="rankNumber">
                    {eachEventData.active_listings}
                  </span>
                  {hasPermissions([
                    ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW
                  ]) ? (
                    <Link
                      to={`${
                        LeftNavRoutesEnum.ZONE_LISTINGS_ACTIVE_LISTINGS
                      }?event=${getEncodedData({
                        event_id: eachEventData.event_id
                      })}`}
                      target={"_blank"}
                    >
                      Active Listings
                    </Link>
                  ) : (
                    <span>Active Listings</span>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="rankingInner positive disabled">
              <span className="rankNumber">
                <i className="da icon-chart-up-white"></i>
              </span>
              <a href="#/">
                Seating
                <br />
                Info
              </a>
            </div>
            <RBAC
              allowedPermissions={[ERbacPermissions.EVENT_CREATE_NEW_LISTING]}
            >
              {eachEventData.broker_name.length > 0 &&
              eachEventData.has_seat_structure &&
              eachEventData.event_id > 0 ? (
                <div className="rankingInner primary-lite">
                  <Tooltip title="Create New Listing" placement={"top"}>
                    <span className="rankNumber" style={{ cursor: "pointer" }}>
                      <i className="da icon-add-layer"></i>
                    </span>
                  </Tooltip>
                  <Link
                    to={`${
                      LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING
                    }?event=${getEncodedData({
                      event_id: eachEventData.event_id
                    })}`}
                    target={"_blank"}
                  >
                    New
                    <br />
                    Listing
                  </Link>
                </div>
              ) : (
                <div className="rankingInner positive disabled">
                  <Tooltip
                    title={
                      eachEventData.event_id < 0
                        ? "Not on POS"
                        : "Venue must be associated with a broker"
                    }
                    placement={"top"}
                  >
                    <span
                      className="rankNumber"
                      style={{ cursor: "not-allowed" }}
                    >
                      <i className="da icon-add-layer"></i>
                    </span>
                  </Tooltip>
                  <span>
                    New
                    <br />
                    Listing
                  </span>
                </div>
              )}
            </RBAC>
          </div>
        </div>
      </div>
      <div className="eventCardFooter">
        <div>
          <Tooltip title="Broker">
            <i className="da icon-user-o"></i>
          </Tooltip>
          {showBrokerOrSeatMapCreatorCheckerLister(eachEventData.broker_name)}
        </div>
        <div>
          <Tooltip title="Lister">
            <i className="da icon-user-role"></i>
          </Tooltip>
          {showBrokerOrSeatMapCreatorCheckerLister(eachEventData.lister_name)}
        </div>
      </div>
    </div>
  );
};

export default EventEachCard;
