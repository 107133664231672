import React from "react";
import { Link } from "react-router-dom";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

const InventoryTabs: React.FC<{ path: string }> = ({ path }) => {
  const { hasPermissions } = useRbac();

  const tabs = [
    {
      id: "active",
      name: "Active Inventory",
      path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE,
      hasPermission: hasPermissions([
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
      ]),
    },
    {
      id: "sold",
      name: "Sold Inventory",
      path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_SOLD,
      hasPermission: hasPermissions([
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM,
      ]),
    },
    {
      id: "unfilled",
      name: "Unfilled Zones",
      path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_UNFILLED,
      hasPermission: hasPermissions([
        ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_TEAM,
      ]),
    },
    {
      id: 'cancelled',
      name: 'Cancelled Inventory',
      path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED,
      hasPermission: hasPermissions([
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_TEAM,
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_OWN,
      ]),
    },
  ];

  return (
    <ul className="tabsNav">
      {tabs
        .filter((tab) => tab.hasPermission)
        .map((tab) => (
          <li className={`${path === tab.path ? "active" : ""}`} key={tab.id}>
            <Link to={tab.path}>{tab.name}</Link>
          </li>
        ))}
    </ul>
  );
};

export default InventoryTabs;
