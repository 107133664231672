import { RoutesEnum } from "models/enums";
import { IOtherCostAndCredits } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class OtherCostAndCreditsService {
    public getOtherCostsAndCredits = async(otherCostAndCreditsDto: IOtherCostAndCredits) => {
        return axiosClient.get<IApiBaseResponse
        >(RoutesEnum.OTHER_COSTS_AND_CREDITS, {
            params: otherCostAndCreditsDto
        })
    }
}

export { OtherCostAndCreditsService }