import React, { useRef, useState } from "react";
import { Button, Calendar, message, Modal, Tooltip, Typography } from "antd";
import {
  IOnSaleChangeData,
  IPurchaseQueueMasterData,
  IPurchaseQueueMenuOption,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import { addToolTip, daysToEventColorCheck } from "utils/commonFunction";
import { Moment } from "moment";
import {
  formatDateGivenFormatString,
  getCustomDate,
  getDateDiff,
  getDateObject,
  getOffsetDate,
} from "utils/formatTimePeriod";
import { LoggerService } from "sharedServices";
import { PurchaseAndShippingService } from "services";
import InvoiceDetailModal from "views/KanbanBoard/KebabMenu/SubMenuComponents/InvoiceDetail/InvoiceDetailModal";
import KebabMenu from "views/KanbanBoard/KebabMenu/KebabMenu";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { dateType } from "models/types";
import { ChangeOnSaleOptions } from "../KebabMenu/SubMenuComponents";
import { AppSetting } from "utils/appSettings";
import { getCharCountByCardSize } from "../KanbanBoard.utils";
import NotesHistory from "../KebabMenu/SubMenuComponents/NotesHistory";

const SwimlanePurchaseCard: React.FC<{
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  kebabMenuOptions: IPurchaseQueueMenuOption[] | null;
}> = ({
  eachInvoiceData,
  swimlaneData,
  requestToReloadTheLanes,
  purchaseQueueMaster,
  kebabMenuOptions,
}) => {
  const [ifShowInvoiceDetailModal, setIfShowInvoiceDetailModal] =
    useState<boolean>(false);
  const [showNotesHistory, setShowNotesHistory] = useState(false);
  const [showIssueReminderDatePicker, setShowIssueReminderDatePicker] =
    useState(false);
  const [showOnSaleReminderDatePicker, setShowOnSaleReminderDatePicker] =
    useState(false);
  const [showChangeOnSale, setShowChangeOnSale] = useState(false);
  const [onSaleChangeData, setOnSaleChangeData] =
    useState<IOnSaleChangeData | null>({
      invoice_id: eachInvoiceData.invoice_id,
      event_date: eachInvoiceData.event_date,
      on_sale_type: eachInvoiceData.on_sale_type,
      on_sale_date: eachInvoiceData.on_sale_date,
    });
  const [isValid, setIsValid] = useState(false);
  const swimlane_id = 3;

  const [issueReminderDate, setIssueReminderDate] = useState<dateType>(
    eachInvoiceData.issues_reminder_date
      ? eachInvoiceData.issues_reminder_date
      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
  );
  const [onSaleReminderDate, setOnSaleReminderDate] = useState<dateType>(
    eachInvoiceData.on_sale_reminder_date
      ? eachInvoiceData.on_sale_reminder_date
      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
  );
  const [isLoading, setIsLoading] = useState(false);

  const getZoneAvailabilityClass = (zoneAvailabilityId: number) => {
    let className = "purchaseCardTopBar ";
    if (zoneAvailabilityId === 0) {
      className = className + "none";
    } else if (zoneAvailabilityId === 1) {
      className = className + "success";
    } else if (zoneAvailabilityId === 2) {
      className = className + "warning";
    } else if (zoneAvailabilityId === 3) {
      className = className + "danger";
    } else if (zoneAvailabilityId === 4) {
      className = className + "tbd";
    }
    return className;
  };

  const getOnSaleReminderTooltipText = () => {
    if (eachInvoiceData.on_sale_reminder_date_formatted === "N/A") {
      return "Reminder N/A";
    } else if (eachInvoiceData.on_sale_type === 2) {
      return "Reminder will be sent Once a Week, on Monday, to Broker & Buyer";
    } else if (eachInvoiceData.days_to_event > 14) {
      return "Reminder will be sent Once a Week to Broker & Buyer";
    } else if (
      eachInvoiceData.days_to_event <= 14 &&
      eachInvoiceData.days_to_event > 5
    ) {
      return "Reminder will be sent Twice a week to Broker & Buyer";
    } else if (
      eachInvoiceData.days_to_event <= 5 &&
      eachInvoiceData.days_to_event > 0
    ) {
      return "Reminder will be sent Everyday to Broker & Buyer";
    } else {
      return "Reminder N/A";
    }
  };

  const getOnSaleTooltipText = () => {
    if (eachInvoiceData.on_sale_date) {
      return "A reminder will be sent weekly, then every 2 days starting at 2 weeks before the event and every day in the last 5 days of the event to the broker and buyer.";
    } else {
      return "A reminder will be sent weekly to the broker and buyer.";
    }
  };

  const changeOnSaleCancelHandler = () => {
    setShowChangeOnSale(false);
    setOnSaleChangeData({
      invoice_id: eachInvoiceData.invoice_id,
      event_date: eachInvoiceData.event_date,
      on_sale_type: eachInvoiceData.on_sale_type,
      on_sale_date: eachInvoiceData.on_sale_date,
    });
  };

  const updateChangeOnSaleDataHandler = async () => {
    const moveToOnSaleKey = "moveToOnSaleKey";
    setShowChangeOnSale(false);
    message.loading({
      content: `Updating on sale details...`,
      duration: 0,
      key: moveToOnSaleKey,
      className: "toastMsg loadingMsg",
    });
    try {
      if (isValid && onSaleChangeData) {
        const res = await new PurchaseAndShippingService().changeOnSaleStatus(
          onSaleChangeData
        );
        if (res.data.statusCode === 200) {
          setOnSaleChangeData(null);
        }
        message.success({
          content: `On sale info updated for Invoice# ${eachInvoiceData.invoice_id}.`,
          duration: 5,
          key: moveToOnSaleKey,
          className: "toastMsg savedSuccess",
        });
        setIsValid(false);
      }
    } catch (error: any) {
      message.error({
        content: "Failed to move invoice.",
        duration: 5,
        key: moveToOnSaleKey,
        className: "toastMsg savedFailed",
      });
      new LoggerService().log({
        payload: error,
        function_name: "updateOnSaleData",
      });
    }
    requestToReloadTheLanes([swimlane_id]);
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  const getOnSaleTagJsx = () => {
    if (
      [3].includes(swimlaneData.id) &&
      eachInvoiceData.on_sale_date_formatted
    ) {
      return (
        <React.Fragment>
          <div className="rankingInner">
            <Tooltip title={getOnSaleTooltipText()}>
              <span
                className="rankNumber onSaleTag"
                onClick={() => setShowChangeOnSale((prev) => !prev)}
              >
                {eachInvoiceData.on_sale_date_formatted}
              </span>
            </Tooltip>
            <div
              className="onSaleLabel"
              onClick={() => setShowChangeOnSale((prev) => !prev)}
            >
              On Sale
            </div>
          </div>
          <Modal
            closable={false}
            width={300}
            title={<div>Change On-Sale Status</div>}
            visible={showChangeOnSale}
            centered
            destroyOnClose={true}
            className="onSaleCtaModal"
            footer={[
              <Button
                key="back"
                onClick={changeOnSaleCancelHandler}
                className="discardBtn"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                className="btnUpdate"
                disabled={!isValid}
                onClick={updateChangeOnSaleDataHandler}
              >
                Update
              </Button>,
            ]}
          >
            <ChangeOnSaleOptions
              purchaseQueueMaster={purchaseQueueMaster}
              onSaleChangeData={onSaleChangeData}
              setOnSaleChangeData={setOnSaleChangeData}
              eachInvoiceData={eachInvoiceData}
              setIsValid={setIsValid}
            />
          </Modal>
        </React.Fragment>
      );
    }
  };

  const getDaysToEventTagJsx = () => {
    if ([1, 2, 3, 4, 9].includes(swimlaneData.id)) {
      return (
        <div
          className={
            "rankingInner " +
            daysToEventColorCheck(eachInvoiceData.days_to_event, "grid")
              .className
          }
        >
          <Tooltip
            placement="top"
            title={
              daysToEventColorCheck(eachInvoiceData.days_to_event, "grid")
                .toolTipText
            }
          >
            <span className="rankNumber">{eachInvoiceData.days_to_event}d</span>
          </Tooltip>

          <div>To event</div>
        </div>
      );
    }
  };

  //days from current date to invoice in-hand date
  const getDaysFromInHandDateTagJsx = () => {
    if (
      [1, 2, 4].includes(swimlaneData.id) &&
      eachInvoiceData.days_from_in_hand_date > 0
    ) {
      return (
        <div className="rankingInner liteGreen">
          <span className="rankNumber">
            {eachInvoiceData.days_from_in_hand_date}d
          </span>
          <div>In-hand</div>
        </div>
      );
    }
  };

  const getDaysOverdueTagJsx = () => {
    if (
      [1, 2, 9].includes(swimlaneData.id) &&
      eachInvoiceData.days_to_overdue > 1
    ) {
      return (
        <div className="rankingInner error">
          <span className="rankNumber">{eachInvoiceData.days_to_overdue}d</span>
          <div>Overdue</div>
        </div>
      );
    }
  };

  const getOnSaleReminderTagJsx = () => {
    if ([3].includes(swimlaneData.id)) {
      return (
        <React.Fragment>
          <div
            className={`rankingInner ${
              showOnSaleReminderDatePicker ? "" : "error"
            }`}
          >
            <Tooltip title={getOnSaleReminderTooltipText()}>
              <span
                className="rankNumber onSaleReminderTag"
                onClick={() => setShowOnSaleReminderDatePicker((prev) => !prev)}
              >
                {eachInvoiceData.on_sale_reminder_date_formatted}
              </span>
            </Tooltip>
            <div
              className="onSaleReminderLabel"
              onClick={() => setShowOnSaleReminderDatePicker((prev) => !prev)}
            >
              Reminder
            </div>
          </div>
          <Modal
            closable={false}
            width={300}
            title={false}
            visible={showOnSaleReminderDatePicker}
            centered
            bodyStyle={{ minHeight: 300 }}
            destroyOnClose={true}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setShowOnSaleReminderDatePicker(false);
                  setOnSaleReminderDate(
                    eachInvoiceData.on_sale_reminder_date
                      ? eachInvoiceData.on_sale_reminder_date
                      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                  );
                }}
                disabled={isLoading}
                className="discardBtn"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={updateOnSaleReminder}
              >
                Save
              </Button>,
            ]}
          >
            <Calendar
              fullscreen={false}
              className="datePickerCal"
              onSelect={handleOnSaleReminderChange}
              value={getCalendarOnSaleDateValue()}
              validRange={[
                getDateObject(
                  getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                ),
                getDateObject(eachInvoiceData.event_date),
              ]}
            />
          </Modal>
        </React.Fragment>
      );
    }
  };

  const getCalendarOnSaleDateValue = () => {
    if (onSaleReminderDate) {
      return getDateObject(onSaleReminderDate);
    } else if (eachInvoiceData.on_sale_reminder_date) {
      return getDateObject(eachInvoiceData.on_sale_reminder_date);
    } else {
      return getDateObject(getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS));
    }
  };

  const getIssueReasonJsx = () => {
    if ([4].includes(swimlaneData.id)) {
      return (
        <Tooltip
          title={eachInvoiceData.notes ? eachInvoiceData.notes : ""}
          placement={"right"}
        >
          <span className="ant-tag danger tagSmall issueReason" style={{margin: '3px 0px 0px 0px'}}>
            {eachInvoiceData.issue_reason
              ? `${eachInvoiceData.issue_reason}`
              : ""}
          </span>
          {/* <span className="issueReason text-danger">
            {eachInvoiceData.issue_reason
              ? ` (${eachInvoiceData.issue_reason})`
              : ""}
          </span> */}
        </Tooltip>
      );
    }
  };

  const getLaneTagJsx = () => {
    if ([1].includes(swimlaneData.id) && eachInvoiceData.lane_tag) {
      return (
        <Tooltip
          title={eachInvoiceData.notes ? eachInvoiceData.notes : ""}
          placement={"right"}
        >
          <span className="ant-tag success tagSmall laneTag" style={{margin: '3px 0 0px 0'}}>
            {eachInvoiceData.lane_tag}
          </span>
        </Tooltip>
      );
    }
  };

  const getIssuesReminderTagJsx = () => {
    if ([4].includes(swimlaneData.id)) {
      return (
        <React.Fragment>
          <div
            className={`rankingInner ${
              showIssueReminderDatePicker ? "" : "error"
            }`}
          >
            <span
              className="rankNumber issueReminderTag"
              onClick={() => setShowIssueReminderDatePicker((prev) => !prev)}
            >
              {eachInvoiceData.issues_reminder_date_formatted}
            </span>
            <div
              className="issueReminderLabel"
              onClick={() => setShowIssueReminderDatePicker((prev) => !prev)}
            >
              Reminder
            </div>
          </div>
          <Modal
            closable={false}
            width={300}
            title={false}
            visible={showIssueReminderDatePicker}
            centered
            bodyStyle={{ minHeight: 300 }}
            destroyOnClose={true}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setShowIssueReminderDatePicker(false);
                  setIssueReminderDate(
                    eachInvoiceData.issues_reminder_date
                      ? eachInvoiceData.issues_reminder_date
                      : getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                  );
                }}
                disabled={isLoading}
                className="discardBtn"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={updateIssueReminder}
              >
                Save
              </Button>,
            ]}
          >
            <Calendar
              fullscreen={false}
              className="datePickerCal"
              onSelect={handleIssueReminderChange}
              value={getCalendarIssueDateValue()}
              validRange={[
                getDateObject(
                  getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS)
                ),
                getDateObject(eachInvoiceData.event_date),
              ]}
            />
          </Modal>
        </React.Fragment>
      );
    }
  };

  const getCalendarIssueDateValue = () => {
    if (issueReminderDate) {
      return getDateObject(issueReminderDate);
    } else if (eachInvoiceData.issues_reminder_date) {
      return getDateObject(eachInvoiceData.issues_reminder_date);
    } else {
      return getDateObject(getOffsetDate(OffsetMode.ADD, 1, OffsetType.DAYS));
    }
  };

  const getAssigneeJsx = () => {
    if (eachInvoiceData?.buyer_name) {
      return (
        <div>
          <Tooltip title="Buyer">
            <i className="da icon-user-o" />
          </Tooltip>
          <Tooltip title={eachInvoiceData?.inactive_buyer ? <s>{eachInvoiceData.buyer_name}</s> : eachInvoiceData.buyer_name}>
            <span>{eachInvoiceData?.inactive_buyer ? <s>{eachInvoiceData.buyer_name_initial}</s> : eachInvoiceData.buyer_name_initial}.</span>
          </Tooltip>
        </div>
      );
    }
    return (
      <div>
        <Tooltip title="Buyer">
          <i className="da icon-user-o" />
        </Tooltip>
        <Tooltip title="Unknown">
          <span>Unknown</span>
        </Tooltip>
      </div>
    );
  };

  const getEventNameJsx = () => {
    return (
      <Typography.Link
        className="extarnalLink"
        href={AppSetting.EVENT_URL + eachInvoiceData?.event_id}
        target="_blank"
      >
        {eachInvoiceData?.event_name.length > getCharCountByCardSize(ref)
          ? addToolTip(
              eachInvoiceData?.event_name,
              "top",
              "pointer",
              getCharCountByCardSize(ref),
              false,
              { textTransform: "capitalize" }
            )
          : eachInvoiceData?.event_name}
      </Typography.Link>
    );
  };

  const getVenueUrlCta = () => {
    if (eachInvoiceData.venue_url) {
      return (
        <Typography.Link
          className="extarnalLink"
          href={eachInvoiceData.venue_url}
          target="_blank"
        >
          <Tooltip title="Go to primary website address">
            <i className="da icon-external-link"></i>
          </Tooltip>
        </Typography.Link>
      );
    }
    return "";
  };

  const getInvoiceNotesHistory = () => {
    return <NotesHistory eachInvoiceData={eachInvoiceData} />;
  };

  const getInvoiceIdCta = () => {
    return (
      <Typography.Link
        className="extarnalLink"
        href={AppSetting.INVOICE_ID_URL + eachInvoiceData.invoice_id}
        target="_blank"
      >
        <Tooltip title="Go to Skybox">
          <i className="da icon-external-link"></i>
        </Tooltip>
      </Typography.Link>
    );
  };

  const getEventDateTimeJsx = () => {
    return (
      <div className="invoiceCardEventDateTime">
        {`${formatDateGivenFormatString(
          eachInvoiceData.event_date_and_time,
          "ddd, MMM DD, YYYY"
        )} at ${formatDateGivenFormatString(
          eachInvoiceData.event_date_and_time,
          "h:mm A"
        )}`}
      </div>
    );
  };

  const getSectionRowQuantityJsx = () => {
    return (
      <div className="invoiceCardSectionRowQuantity">
        <div className="pqInvSec">
          <span>Sec:</span>
          <span>{eachInvoiceData.section}</span>
        </div>
        <div className="pqInvRow">
          <span>Row:</span>
          <span>{eachInvoiceData.row}</span>
        </div>
        <div className="pqInvQty">
          <span>Qty:</span>
          <span>{eachInvoiceData.quantity}</span>
        </div>
      </div>
    );
  };

  const handleIssueReminderChange = (newValue: Moment) => {
    setIssueReminderDate(newValue?.format("YYYY-MM-DD"));
  };

  const handleOnSaleReminderChange = (newValue: Moment) => {
    setOnSaleReminderDate(newValue?.format("YYYY-MM-DD"));
  };

  const updateIssueReminder = async () => {
    const saveIssueReminder = "saveIssueReminder";
    setIsLoading(true);
    try {
      const dateDiff = getDateDiff(
        getCustomDate(issueReminderDate),
        eachInvoiceData.event_date
      );
      if (dateDiff && dateDiff > 0) {
        setIsLoading(false);
        message.error({
          content: "Reminder date cannot exceed event date.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
      } else {
        await new PurchaseAndShippingService().saveIssueReminder({
          invoice_id: eachInvoiceData.invoice_id,
          reminder_date: issueReminderDate,
        });
        setIsLoading(false);
        setShowIssueReminderDatePicker(false);
        message.success({
          content: `Issue Reminder for Invoice# ${eachInvoiceData.invoice_id} updated successfully.`,
          duration: 5,
          key: saveIssueReminder,
          className: "toastMsg savedSuccess",
        });
        requestToReloadTheLanes([swimlaneData.id]);
      }
    } catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "updateIssueReminder",
      });
      message.error({
        key: saveIssueReminder,
        content: "Failed to update issue reminder.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      setIsLoading(false);
      setShowIssueReminderDatePicker(false);
    }
  };

  const updateOnSaleReminder = async () => {
    const saveOnSaleReminder = "saveOnSaleReminder";
    setIsLoading(true);
    try {
      const dateDiff = getDateDiff(
        getCustomDate(onSaleReminderDate),
        eachInvoiceData.event_date
      );
      if (dateDiff && dateDiff > 0) {
        setIsLoading(false);
        message.error({
          content: "Reminder date cannot exceed event date.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
      } else {
        await new PurchaseAndShippingService().saveOnSaleReminder({
          invoice_id: eachInvoiceData.invoice_id,
          reminder_date: onSaleReminderDate,
        });
        setIsLoading(false);
        setShowOnSaleReminderDatePicker(false);
        message.success({
          content: `On Sale Reminder for Invoice# ${eachInvoiceData.invoice_id} updated successfully.`,
          duration: 5,
          key: saveOnSaleReminder,
          className: "toastMsg savedSuccess",
        });
        requestToReloadTheLanes([swimlaneData.id]);
      }
    } catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "updateOnSaleReminder",
      });
      message.error({
        key: saveOnSaleReminder,
        content: "Failed to update on sale reminder.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      setIsLoading(false);
      setShowOnSaleReminderDatePicker(false);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="purchaseCard" ref={ref}>
      <div
        className={getZoneAvailabilityClass(
          eachInvoiceData.zone_availability_id
        )}
      />
      <div className="purchaseCardTop">
        <div className="purchaseCardTopContents">
          {eachInvoiceData.is_high_priority === true && (
            <Tooltip title="High priority">
              <span className="isHighPriority">!&nbsp;</span>
            </Tooltip>
          )}
          <Typography.Link
            target={"_blank"}
            rel={"noreferrer"}
            className="purcheseId"
            onClick={() => {
              setIfShowInvoiceDetailModal(!ifShowInvoiceDetailModal);
            }}
          >
            {eachInvoiceData.invoice_id}
          </Typography.Link>
          {getInvoiceIdCta()}
          {(eachInvoiceData.show_notes_history || showNotesHistory) && getInvoiceNotesHistory()}
          <InvoiceDetailModal
            ifShowInvoiceDetailModal={ifShowInvoiceDetailModal}
            setIfShowInvoiceDetailModal={setIfShowInvoiceDetailModal}
            invoiceId={eachInvoiceData.invoice_id}
          />
        </div>
        <KebabMenu
          swimlaneData={swimlaneData}
          eachInvoiceData={eachInvoiceData}
          requestToReloadTheLanes={requestToReloadTheLanes}
          purchaseQueueMaster={purchaseQueueMaster}
          kebabMenuOptions={kebabMenuOptions}
          setShowNotesHistory={setShowNotesHistory}
        />
      </div>
      {getLaneTagJsx()}
      {getIssueReasonJsx()}
      <div className="purchaseCardMid">
        {getEventNameJsx()}
        {getVenueUrlCta()}
        {getEventDateTimeJsx()}
        {getSectionRowQuantityJsx()}
      </div>
      <div className="purcheseTags">
        <div className="ranking">
          {getOnSaleTagJsx()}
          {getDaysToEventTagJsx()}
          {getDaysFromInHandDateTagJsx()}
          {getDaysOverdueTagJsx()}
          {getOnSaleReminderTagJsx()}
          {getIssuesReminderTagJsx()}
        </div>
      </div>
      <div className="eventCardFooter">{getAssigneeJsx()}</div>
    </div>
  );
};

export default SwimlanePurchaseCard;
