import { Modal, Tooltip, Typography } from "antd";
import CustomError from "controls/CustomError/CustomError";
import {
  IPurchaseQueueNotesHistory,
  ISwimlaneInvoiceList,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useCallback, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import { getCustomDateTime } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const NotesHistory: React.FC<{
  eachInvoiceData: ISwimlaneInvoiceList;
}> = ({ eachInvoiceData }) => {
  const [isModalVisible19, setIsModalVisible19] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const [purchaseNotesHistoryList, setPurchaseNotesHistoryList] = useState<
    IPurchaseQueueNotesHistory[] | null
  >(null);
  const showNotesHistoryModal = () => {
    getNotesHistory();
    setIsModalVisible19(true);
  };
  const cancelNotesHistoryModal = useCallback(() => {
    setIsModalVisible19(false);
  },[isModalVisible19]);

  const getNotesHistory = async () => {
    setDataFetchError(null);
    try {
      setShowLoader(true);
      const response = await new PurchaseAndShippingService().getNotesHistory(
        eachInvoiceData.invoice_id
      );
      const responseData = response.data.data ?? [];
      setPurchaseNotesHistoryList(responseData);

      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      setDataFetchError({ ...error, refreshAction: getNotesHistory });
      new LoggerService().log({
        payload: error,
        function_name: "getNotesHistory",
      });
    }
  };

  const getNotesHistoryJsx = () => {
    if (dataFetchError) {
      return <CustomError {...dataFetchError} />;
    } else if (!purchaseNotesHistoryList?.length) {
      return (
        <div className="text-center">
          <h3>No History Found</h3>
        </div>
      );
    } else {
      return (
        <div
          className="customTbl scroll-y fixedHeaderFooter textVTop"
          style={{ maxHeight: "400px" }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ width: 150 }}>Name</th>
                <th style={{ width: 180 }}>Date</th>
                <th>Notes description</th>
              </tr>
            </thead>
            <tbody>
              {purchaseNotesHistoryList?.map((note) => {
                return (
                  <tr key={`${note.notes_id}`}>
                    <td>
                      {
                        note.inactive_user ? <s><strong>{note.user_name}</strong></s> : <strong>{note.user_name}</strong>
                      }
                    </td>
                    <td>{`${getCustomDateTime(
                      note.created_on,
                      "ddd, MMM D, YYYY"
                    )} , ${getCustomDateTime(note.created_on, "hh:mm A")}`}</td>
                    <td style={{ whiteSpace: "pre-line" }}>{note.notes}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Typography.Link className="extarnalLink">
        <Tooltip title="Go to Notes History">
          <i
            className="da icon-copy"
            onClick={showNotesHistoryModal}
            style={{ width: "12px" }}
          ></i>
        </Tooltip>
      </Typography.Link>
      <Modal
        // closable={true}
        maskClosable={false}
        onCancel={cancelNotesHistoryModal}
        width={800}
        title={false}
        footer={false}
        visible={isModalVisible19}
      >
        <div className="modalHeader">Notes History</div>
        <div>
          <label className="mb-0">Invoice ID:</label>&nbsp;
          <a
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
            href={AppSetting.INVOICE_ID_URL + eachInvoiceData.invoice_id}
          >
            {eachInvoiceData.invoice_id}
          </a>
        </div>
        <p>
          <strong>{eachInvoiceData.event_name}</strong> (
          {`${getCustomDateTime(
            eachInvoiceData.event_date_and_time,
            "ddd, MMM D, YYYY"
          )} ${getCustomDateTime(eachInvoiceData.event_date_and_time, "hh:mm A")}`}
          )
        </p>

        <div className="modalCustomBody">
          <LoadingSpinner isLoading={showLoader}>
            {!showLoader && getNotesHistoryJsx()}
          </LoadingSpinner>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default NotesHistory;
