import React from 'react';
import { Layout } from 'antd';
import CommonReportTable from 'utils/table/CommonReportTable';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { IPendingZoneFillsListData } from 'models/interfaces';
import { pendingZoneFillsHeaders } from './pendingZoneFillsUtils';

const { Content } = Layout;

const PendingZoneFillsTable: React.FC<{
  showLoader: boolean;
  dataFetchError: ICustomError | null;
  pendingZoneFillsData: IPendingZoneFillsListData | null;
  queryParams: any;
  setQueryParams: Function;
}> = ({
  showLoader,
  dataFetchError,
  pendingZoneFillsData,
  queryParams,
  setQueryParams,
}) => {
    return (dataFetchError ? (
      <CustomError {...dataFetchError} />
    ) : (
      <Content className="mainContainer">
        <div className="removeTblLRSpc">
          <div className="pendingZoneFieldTable">
            <CommonReportTable
              tableHeight={{ maxHeight: "calc(100vh - 196px)" }}
              onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                setQueryParams({
                  ...queryParams,
                  sort_column: sortIndex,
                  sort_order: sortOrder,
                });
              }}
              sortField={{
                dataIndex: queryParams?.sort_column,
                order: queryParams?.sort_order,
              }}
              data={
                pendingZoneFillsData?.pendingZoneFillsData ?? []
              }
              headers={pendingZoneFillsHeaders}
              loading={showLoader}
            />
          </div>
        </div>
      </Content>
    ));
  };

export default PendingZoneFillsTable;
