import { useState, useEffect } from "react";
import { Layout } from "antd";
import { actions } from "models/Slices/FilterSlice";
import { useAppDispatch } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import { usePageTitle } from "hooks";
import KanbanBoard from "views/KanbanBoard/KanbanBoard";
import { IFilterOptions } from "models/interfaces";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { getOffsetDate } from "utils/formatTimePeriod";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
const PurchasesPage = () => {
  usePageTitle("Purchase Queue");

  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);

  useEffect(() => {
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (
      hasPermissions([
        ERbacPermissions.FILTER_BUYER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_BUYER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getBuyerUserData());
    }
  }, [dispatch]);

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  return (
    <Layout className="mainInnerLayout">
      <div className="kanbanBoardTopBar">
        <div className="flx alignItemsFlexEnd">
          <h3>Purchase Queue</h3>
        </div>

        <FilterDrawer
          onApplyFilter={filterChanged}
          showBrokerUserDropdown={hasPermissions([
            ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
          ])}
          brokerUserDropdownType="single"
          showBuyerUserDropdown={hasPermissions([
            ERbacPermissions.FILTER_BUYER_USER_SELECTION_ALL,
            ERbacPermissions.FILTER_BUYER_USER_SELECTION_TEAM,
          ])}
          buyerUserDropdownType="single"
          showEventDatePicker={true}
          showHighPriorityInvoicesCheckbox={true}
          showIncludeMyBrokerCardsCheckbox={hasPermissions([
            ERbacPermissions.FILTER_SHOW_CATEGORY_INCLUDE_MY_BROKER_CARDS,
          ])}
          defaultAndValues={{
            preFilledValues: {
              event_date_from:
                getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
              event_date_to:
                getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
            },
            defaultValues: {
              event_date: {
                defaultFrom:
                  getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? "",
                defaultTo:
                  getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? "",
              },
            },
          }}
        />
      </div>

      <KanbanBoard queueType={1} filterObject={filterObject} />
    </Layout>
  );
};

export default PurchasesPage;
