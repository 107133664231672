import { Button, message, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
  IPurchaseQueueMasterData,
  IIssuesData,
  IMenuOption,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { formatThousandSeparator } from "utils/commonFunction";

const shipping_issues_swimlane_id = 7;  //shipping queue issues lane id

const MoveToIssuesShipping: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  swimlaneData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster,
}) => {
  const [issuesData, setIssuesData] = useState<IIssuesData>({
    invoice_id: eachInvoiceData.invoice_id,
    swimlane_id: shipping_issues_swimlane_id,
    issue_type: null,
    notes: null,
  });

  const [isValid, setIsValid] = useState(false);

  const issueTypeChangeHandler = (value: string) => {
    if (value)
      setIssuesData((prev) => ({
        ...prev,
        issue_type: parseInt(value),
      }));
  };

  const notesChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssuesData((prev) => ({
      ...prev,
      notes: e.currentTarget.value,
    }));
  };

  const cancelHandler = () => {
    setSelectedMenuId(null);
    setIssuesData((prev) => ({
      ...prev,
      issue_type: null,
      notes: null,
    }));
  };

  const updateIssuesData = async () => {
    const moveToIssuesKey = "moveToIssuesKey";
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Moving Invoice from ${swimlaneData.name} to Issues queue...`,
      duration: 0,
      key: moveToIssuesKey,
      className: "toastMsg loadingMsg",
    });
    if (isValid) {
      try {
        await new PurchaseAndShippingService().moveToIssuesShipping(issuesData);
        message.success({
          content: `Invoice# ${eachInvoiceData.invoice_id} moved from ${swimlaneData.name} to Issues queue.`,
          duration: 5,
          key: moveToIssuesKey,
          className: "toastMsg savedSuccess",
        });
        requestToReloadTheLanes([swimlaneData.id, shipping_issues_swimlane_id]);
      }
      catch (error: any) {
        new LoggerService().log({
          payload: error,
          function_name: "updateIssuesData",
        });
        message.error({
          content: "Failed to move invoice.",
          duration: 5,
          key: moveToIssuesKey,
          className: "toastMsg savedFailed",
        });
      }
    }
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  useEffect(() => {
    if (
      issuesData?.issue_type === null ||
      issuesData?.issue_type === undefined
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [issuesData]);

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <div className="moveOnSaleWrap">
          <div className="moveOnSaleRow">
            <Select
              className="positionTopLeft"
              defaultValue="Select"
              options={purchaseQueueMaster?.shippingIssuesTypeMasterData.map(
                (each) => ({ ...each, value: each.value.toString() })
              )}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLInputElement
              }
              value={
                issuesData?.issue_type
                  ? issuesData.issue_type.toString()
                  : "Select"
              }
              onChange={issueTypeChangeHandler}
            />
          </div>
          <div className="moveOnSaleRow">
            <TextArea
              placeholder="Note"
              className="moveToIssuesTxtArea"
              maxLength={1000}
              showCount={{
                formatter: ({ count, maxLength }) =>
                  maxLength
                    ? `${formatThousandSeparator(
                        (maxLength ?? 0) - count
                      )} Remaining`
                    : "",
              }}
              rows={4}
              value={issuesData?.notes ? issuesData.notes : ""}
              onChange={notesChangeHandler}
              allowClear
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </div>
          <div className="moveOnSaleRow btnGroupFooter">
            <Button className="discardBtn" onClick={cancelHandler}>
              <span>CANCEL</span>
            </Button>
            <Button
              className="btnUpdate"
              disabled={!isValid}
              onClick={updateIssuesData}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoveToIssuesShipping;
