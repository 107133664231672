import { Checkbox, InputNumber, Select, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppSelector } from "app/hooks";
import { IEventMetadata } from "models/interfaces";
import { stockTypeMaster } from "models/Slices/EventManagementSlice";
import { ChangeEvent } from "react";
import { checkValidUrl, getMpvEventListUrl, urlQueryParamsToObject } from "utils/commonFunction";

const mpvDomain = "mpv.tickets.com";

const EventOtherDetails: React.FC<{
  metadataUpdateData: IEventMetadata | null;
  setMetadataUpdateData: Function;
  setAllowSave: Function;
  isFromQuickEdit: boolean;
  metadata: IEventMetadata | null;
  setIsSoldOutActivated: Function;
  isWebScrapingEvent: boolean;
  setIsWebScrapingEvent: Function;
  disableCheckboxes: boolean;
  showWebScrapingCheckbox: boolean;
}> = ({
  metadataUpdateData,
  setMetadataUpdateData,
  isFromQuickEdit,
  metadata,
  setIsSoldOutActivated,
  isWebScrapingEvent,
  setIsWebScrapingEvent,
  disableCheckboxes,
  showWebScrapingCheckbox,
}) => {
  const stockTypeOptions = useAppSelector(stockTypeMaster);

  const seatMapUrlChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if(checkMPVEvent(e.target.value)) {
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        seat_map_url: e.target.value.trim().length ? e.target.value : null,
        scraping_status_id: 1,
        event_list_url: getMpvEventListUrl(e.target.value)
      }));
    } else {
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        seat_map_url: e.target.value.trim().length ? e.target.value : null,
      }));
    }
  };

  const checkValidity = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length && !checkValidUrl(e.target.value)) {
      e.target.classList.add("requiredField");
    } else {
      e.target.classList.remove("requiredField");
    }
  };

  const stockTypeChangeHandler = (value: string) => {
    setMetadataUpdateData((prev: IEventMetadata | null) => ({
      ...prev,
      stock_type_id: parseInt(value),
    }));
  };

  const overrideReminderHandler = (e: CheckboxChangeEvent) => {
    setMetadataUpdateData((prev: IEventMetadata | null) => ({
      ...prev,
      reminder_override: e.target.checked,
    }));
  };

  const reminderChangeHandler = (value: any) => {
    if (value) {
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        reminder_in_days: value,
      }));
    }
  };

  const soldOutAndCancelledCheckboxHandler = (
    value: CheckboxChangeEvent,
    checkboxType: string
  ) => {
    if (checkboxType === "soldOut") {
      setIsSoldOutActivated(value.target.checked);
      setMetadataUpdateData({
        ...metadataUpdateData,
        is_sold_out: value.target.checked,
      });
      return;
    }
    setMetadataUpdateData({
      ...metadataUpdateData,
      is_event_cancelled: value.target.checked,
    });
  };

  const webScrapingCheckboxHandler = (value: CheckboxChangeEvent) => {
    setIsWebScrapingEvent(value.target.checked);
    setMetadataUpdateData({
      ...metadataUpdateData,
      scraping_status_id: value.target.checked === true ? 1 : 2,
    });
  };

  const getTooltipTextForCheckboxes = (checkboxName: string) => {
    switch (checkboxName) {
      case "web_scraping":
      case "cancelled":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        }
        break;
      case "sold_out":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        } else if (
          metadataUpdateData &&
          metadataUpdateData.is_event_cancelled
        ) {
          return "Event is cancelled.";
        } else {
          return "";
        }
    }
  };

  const checkMPVEvent = (url: string | null) => {
    if(!url) {
      return false;
    }
    return url?.includes(mpvDomain);
  };
  
  return (
    <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12">
        <div className="inputGroup">
          <label>Seat Map URL</label>
          <Tooltip title={isWebScrapingEvent ? "Data is scraped" : ""}>
            <div className="flx alignItemsCenter">
              <input
                id="seatMapUrl"
                placeholder="http://www.example.com"
                value={metadataUpdateData?.seat_map_url}
                type="text"
                className="ant-input"
                onChange={seatMapUrlChangeHandler}
                onInput={checkValidity}
                disabled={isWebScrapingEvent}
              />

              {checkValidUrl(metadata?.seat_map_url ?? "") && (
                <a
                  rel="noopener noreferrer"
                  href={metadata?.seat_map_url}
                  className="ml-8"
                  target={"_blank"}
                  id="previewCta"
                >
                  Preview
                </a>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        className={`ant-col ant-col-xs-24 ant-col-sm-12 ${
          isFromQuickEdit
            ? "ant-col-md-5 ant-col-lg-5"
            : "ant-col-md-6 ant-col-lg-6"
        }`}
      >
        <div className="inputGroup">
          <label>
            Stock Type
            {!metadataUpdateData?.is_sold_out &&
              !metadataUpdateData?.is_event_cancelled && (
                <span className="text-danger"> *</span>
              )}
          </label>
          <Select
            id="stockType"
            placeholder="Select"
            options={stockTypeOptions.map((eachData) => {
              return { ...eachData, value: eachData.value.toString() };
            })}
            value={metadataUpdateData?.stock_type_id?.toString() ?? "Select"}
            onChange={stockTypeChangeHandler}
          />
        </div>
      </div>
      <div
        className={`ant-col ant-col-xs-24 ant-col-sm-12 ${
          isFromQuickEdit
            ? "ant-col-md-4 ant-col-lg-4"
            : "ant-col-md-6 ant-col-lg-6"
        }`}
      >
        <div className="inputGroup">
          <div className="flx alignItemsCenter setReminderLabel">
            <Tooltip
              title={
                isWebScrapingEvent
                  ? "Data is scraped"
                  : "Override reminder in days"
              }
            >
              <Checkbox
                disabled={
                  !Boolean(
                    metadataUpdateData?.days_to_event &&
                      metadataUpdateData?.days_to_event - 1 &&
                      !metadataUpdateData?.seat_map_tbd
                  ) || isWebScrapingEvent
                }
                onChange={overrideReminderHandler}
                checked={metadataUpdateData?.reminder_override}
              />
            </Tooltip>
            &nbsp; Set Reminder
          </div>
          <div className="flx alignItemsCenter ">
            <div className="removeFormMargin">
              <InputNumber
                id="reminderOverride"
                disabled={
                  !metadataUpdateData?.reminder_override || isWebScrapingEvent
                }
                precision={0}
                min={1}
                max={(metadataUpdateData?.days_to_event ?? 0) - 1}
                step={1}
                className="inputTxtRight"
                controls={false}
                onChange={reminderChangeHandler}
                value={
                  metadataUpdateData?.reminder_in_days
                    ? metadataUpdateData?.reminder_in_days
                    : undefined
                }
              />
            </div>
            <span>days</span>
          </div>
        </div>
      </div>
      
      {isFromQuickEdit && (
        <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-3 ant-col-lg-3 cancelledSoldOutCheckbox">
          <div className="inputGroup">
            {Object.keys(metadata ?? {}).length > 0 && (
              <div>
                {showWebScrapingCheckbox && (
                  <Tooltip
                    title={getTooltipTextForCheckboxes("web_scraping")}
                    placement="bottom"
                  >
                    <Checkbox
                      className="checkWithLbl ml0 mr0"
                      defaultChecked={metadata?.scraping_status_id === 1}
                      onChange={(value) => webScrapingCheckboxHandler(value)}
                    >
                      <label>Web Scraping</label>
                    </Checkbox>
                  </Tooltip>
                )}
                <Tooltip
                  title={getTooltipTextForCheckboxes("cancelled")}
                  placement="bottom"
                >
                  <Checkbox
                    className="checkWithLbl ml0 mr0"
                    defaultChecked={metadata?.is_event_cancelled}
                    onChange={(value) =>
                      soldOutAndCancelledCheckboxHandler(value, "cancelled")
                    }
                  >
                    <label>Cancelled</label>
                  </Checkbox>
                </Tooltip>
                <Tooltip
                  title={getTooltipTextForCheckboxes("sold_out")}
                  placement="bottom"
                >
                  <Checkbox
                    className="checkWithLbl ml0 mr0"
                    defaultChecked={metadata?.is_sold_out}
                    onChange={(value) =>
                      soldOutAndCancelledCheckboxHandler(value, "soldOut")
                    }
                    disabled={Boolean(
                      metadataUpdateData &&
                        metadataUpdateData.is_event_cancelled
                    )}
                  >
                    <label>Sold Out</label>
                  </Checkbox>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventOtherDetails;
