import { RoutesEnum } from "models/enums";
import { GrossProfitData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class BrokerTagsService {
    public getBrokerTagsData = async (brokerTagsRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.BROKER_TAG_ISSUES, {
            params: brokerTagsRequest
        });
    };
}

export { BrokerTagsService };