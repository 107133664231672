import { usePageTitle } from "hooks";
import React, { useEffect, useState } from "react";
import Events from "./Events";
import EventsSearchDashboard from "./EventsSearchDashboard";
import "./events.css";
import { useLocation } from "react-router";
import { IAddonFilter } from "models/interfaces";
import {
  urlQueryParamsToObject,
  decodeQueryStringData,
} from "utils/commonFunction";

const EventsPage: React.FC = () => {
  usePageTitle("Events");
  const location = useLocation();
  const [pageViewMode, setPageViewMode] = useState<
    "searchDashboard" | "eventListResult"
  >("searchDashboard");
  const [addonFilters, setAddonFilters] = useState<IAddonFilter[]>();
  const [isPageReady, setIsPageReady] = useState(false);

  const [searchFields, setSearchFields] = useState<{
    dashboard_search_text: string | null;
    event_date_from: string | null;
    event_date_to: string | null; 
  }>({
    dashboard_search_text: null,
    event_date_from: null,
    event_date_to: null,
  });

  const doSearch = (data: {
    dashboard_search_text: string | null;
    event_date_from: string | null;
    event_date_to: string | null;
  }) => {
    setSearchFields({
      dashboard_search_text: data.dashboard_search_text,
      event_date_from: data.event_date_from,
      event_date_to: data.event_date_to,
    });
    setPageViewMode("eventListResult");
  };

  useEffect(() => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes("filter")) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.filter) {
        let filterQueryString = decodeQueryStringData(queryObject.filter);
        if (filterQueryString) {
          if (filterQueryString.venue_name && filterQueryString.venue_id) {
            setAddonFilters([
              {
                filter_key: "venue_id",
                filter_value: filterQueryString.venue_id,
                filter_label: "Venue",
                filter_label_value: filterQueryString.venue_name,
              }
            ]);
          }
          if (filterQueryString.show_category_id) {
            setSearchFields((prev) => ({
              ...prev,
              show_category_id: filterQueryString.show_category_id,
            }));
          }

          if (filterQueryString.exclude_sold_out_events) {
            setSearchFields((prev) => ({
              ...prev,
              exclude_sold_out_events:
                filterQueryString.exclude_sold_out_events
            }));
          }
          if (filterQueryString.exclude_cancelled_events) {
            setSearchFields((prev) => ({
              ...prev,
              exclude_cancelled_events:
                filterQueryString.exclude_cancelled_events
            }));
          }

          let _searchFields = { ...searchFields, ...filterQueryString };
          if (_searchFields.venueName) {
            _searchFields.dashboard_search_text = filterQueryString.venueName;
          }

          setSearchFields(_searchFields);
          setPageViewMode("eventListResult");
        }
      }
    }
    setIsPageReady(true);
  }, []);

  return (
    <React.Fragment>
      {pageViewMode === "searchDashboard" && (
        <EventsSearchDashboard doSearch={doSearch} />
      )}

      {pageViewMode === "eventListResult" && (
        <Events
          setAddonFilters={setAddonFilters}
          dashboardSearchFields={searchFields}
          addonFilters={addonFilters}
          isPageReady={isPageReady}
        />
      )}
    </React.Fragment>
  );
};

export default EventsPage;
