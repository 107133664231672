export interface Pokedex {
    statusCode: number;
    message:    string;
    data:       LongInventoryData;
}


export interface PricingResolutionMasterData {
    id:     number;
    resolution:   string;
}

export interface InventoryPricingIssueRes {
    resolutionId?: number;
    resolutionNotes?: string;
    isResolved?:boolean;
    resolutionUpdatedBy?: string;
  
  }


export interface LongInventoryData {
    inventory: Inventory[];
    total:     number;
}

export interface Inventory extends InventoryPricingIssueRes {
    po_date:         Date;
    po_date_time:    Date;
    po_no:           number;
    alert_tag:       AlertTag[] | null;
    has_row_alert:   boolean;
    po_tags:         PoTags;
    eventId:         number;
    event_name:      string;
    event_date:      Date;
    days_to_event:   number;
    venue_name:      string;
    venue_location:  string;
    quantity:        number;
    section:         string;
    row:             string;
    seats:           string;
    inHandDate:      Date;
    currency:        Currency;
    cost_price:      number;
    list_price:      number;
    internal_notes:  string;
    lastPriceUpdate: Date;
    daysOld:         number;
    buyer:           Buyer;
    broker:          Broker;
    purchaseTerm:    PurchaseTerm;
    paymentMethod:   PaymentMethod;
    creditCardGroup: CreditCardGroup;
    creditCard:      string;
    inventory_id:    number;
}

export interface AlertTag {
    id:      number;
    name:    Name;
    tooltip: Tooltip;
}


export enum Name {
    CardDetailsMissing = "Card Details Missing",
    CostMissing = "Cost Missing",
    PricingIssue = "Pricing Issue",
    The1530Days = "15-30 days",
    The15Days = "<15 days",
}

export enum Tooltip {
    EventDateBetween15To30DaysFromToday = "Event Date between 15 to 30 days from today",
    EventDateLessThan15DaysFromToday = "Event Date less than 15 days from today",
    PaymentMethodIsCreditCardButDetailsAreNotAvailable = "Payment method is Credit Card but details are not available",
    TicketUnitCostIsHigherThanListPrice = "Ticket Unit Cost is higher than List Price",
    TicketUnitCostIsZero = "Ticket Unit Cost is zero",
}

export enum Broker {
    ConsignmentTurbo = "consignment turbo",
    Corporate = "corporate",
    Doug = "doug",
    DougLong = "doug-long",
    KevinVin = "kevin/vin",
    NoBroker = "no broker",
    Tommy = "tommy",
    Turbo = "turbo",
    Vin = "vin",
}

export enum Buyer {
    AriyanaTicketbashCOM = "Ariyana@ticketbash.com",
    DanielleTicketbashCOM = "danielle@ticketbash.com",
    DylanTicketbashCOM = "dylan@ticketbash.com",
    JenniferTicketbashCOM = "jennifer@ticketbash.com",
    JenniferfreybergGmailCOM = "jenniferfreyberg@gmail.com",
    JessTicketbashCOM = "jess@ticketbash.com",
    KevinTicketbashCOM = "kevin@ticketbash.com",
    RoseTicketbashCOM = "rose@ticketbash.com",
    TayTicketbashCOM = "tay@ticketbash.com",
    The1Ticket = "1Ticket",
    ThomasTicketbashCOM = "thomas@ticketbash.com",
    Ticketalpha2GmailCOM = "Ticketalpha2@gmail.com",
    TicketdougGmailCOM = "ticketdoug@gmail.com",
    TomTicketbashCOM = "tom@ticketbash.com",
}

export enum CreditCardGroup {
    NA = "N/A",
    The15098Amex = "15098-AMEX",
    The15099CapOne = "15099-CapOne",
    The15101BestBuy = "15101-BestBuy",
    The15102Chase = "15102-Chase",
    The16339Comdata = "16339-Comdata",
    The19428AmexNew = "19428-AMEX NEW",
    The19457SparkVisaNew = "19457-Spark Visa New",
    The19742SparkMastercard = "19742-Spark Mastercard",
    The19879Zelle = "19879-Zelle",
    The19893NewAmex = "19893-NEW AMEX",
    The20073CapOneQuickSilver = "20073-Cap One Quick Silver",
}

export enum Currency {
    Usd = "USD",
}

export enum PaymentMethod {
    Accruedcredit = "ACCRUEDCREDIT",
    Cash = "CASH",
    Check = "CHECK",
    Creditcard = "CREDITCARD",
    Evopay = "EVOPAY",
    Other = "OTHER",
    Paypal = "PAYPAL",
}

export enum PoTags {
    Doug = "doug",
    Empty = "",
    Reconciled = "reconciled",
    TBPaid = "tb_paid",
    Tommy = "tommy",
    Vin = "vin",
}

export enum PurchaseTerm {
    Net0 = "NET0",
    Net10 = "NET10",
    Net30 = "NET30",
    Net45 = "NET45",
    Net60 = "NET60",
}
