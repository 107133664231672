import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Layout, Switch, message } from "antd";
import CommonReportTable from "utils/table/CommonReportTable";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { userListingHeaders } from "./userListingUtils";
import { UserService } from "services/userServices/user.service";
import { UserDetailsData } from "models/interfaces";
import { LoggerService } from "sharedServices/api/logger.service";
import { on, off } from "sharedServices";
import NewUser from "./NewUser";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

const { Content } = Layout;
let currentCustomEventData: any | null = null;

const UserListingTable: React.FC = () => {
  const user = useAuth0();
  const actionRef = useRef<HTMLDivElement | null>(null);

  const [queryParams, setQueryParams] = useState<any>({
    show_active_users_only: true,
    sort_column: "user_name",
    sort_order: "asc",
  });
  const [userDetails, setUserDetails] = useState<UserDetailsData[] | null>(
    null
  );
  const [maxTicketsAllowed, setMaxTicketsAllowed] = useState(8);
  const [userDataFetchError, setUserDataFetchError] =
    useState<ICustomError | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const getListedUsers = async (query: any) => {
    setShowLoader(true);
    try {
      const response = await new UserService().getUserList({
        ...query,
      });

      let responseData = response.data.data;
      responseData.userDetailsData = handleHighlightedRow(
        responseData.userDetailsData
      );

      setUserDetails(responseData.userDetailsData);
      setMaxTicketsAllowed(responseData.max_tickets_allowed);
      setShowLoader(false);
      setUserDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getListedUsers",
      });
      setUserDataFetchError({ ...error, refreshAction: getListedUsers });
    }
  };
  const handleHighlightedRow = (userResponseData: any[]) => {
    if (userResponseData) {
      userResponseData = userResponseData.map((eachData: any) => {
        return {
          ...eachData,
          activeSelectedClass: +eachData?.is_active ? null : "inactive",
        };
      });
    }

    return userResponseData;
  };

  const onSuccessUserSaved = (success:boolean) =>{
    if(success){
      getListedUsers(queryParams);
      dispatch(actions.getSkyBoxUserData());
      dispatch(actions.getTicketbashBrokerData());
    }
  }

  const deleteUser = async (data: any) => {
    const key = 'deletingUser';
    try {
        message.loading({ content: 'Deleting user...', key, duration: 0, className: 'toastMsg loadingMsg' });
        const response = await new UserService().deleteUser(data.userId);
        message.success({ content: 'User has been successfully deleted.', key, duration: 5, className: 'toastMsg deletedSuccess' });
        if (response.data.data) {
            if(userDetails){
              getListedUsers(queryParams);
            }
        }
    } catch (error: any) {
        message.error({content: 'Failed to delete user.', key, duration: 5, className: 'toastMsg savedFailed'})
        new LoggerService().log({
            payload: error,
            function_name: "deleteUser",
        });
    }
  };

  const actionHandler = async () => {
    switch (currentCustomEventData?.action) {
        case "delete":
            if (currentCustomEventData?.data.userData.team_user && currentCustomEventData?.data.userData.team_user.length>1) {
              message.error({content: `There are members in the broker’s team. Please reassign them before changing the role.`, duration: 5, className: 'toastMsg savedFailed'})
            } else {
                const header = (
                    <span>
                    Are you sure you want to <strong>delete</strong> this user?
                    </span>
                );
                const userName = currentCustomEventData?.data.userData.user_name;
                const userId = currentCustomEventData?.data.userData.id;
                showConfirm(header, userName, { userId }, deleteUser, cancelHandler);
            }
            break;
        case "updatedUser":
            if(currentCustomEventData?.data.success){
              getListedUsers(queryParams);
              dispatch(actions.getSkyBoxUserData());
              dispatch(actions.getTicketbashBrokerData());
            }
            break;
    }
  };

  const cancelHandler = (_data: any) => {
    console.log("CANCELLED");
  };

  useEffect(() => {
    queryParams && getListedUsers(queryParams);
  }, [queryParams]);

  useEffect(() => {
    const actionHandler = (data: CustomEvent) => {
      currentCustomEventData = data.detail;
      actionRef?.current?.click();
    };
    on("userAction:click", actionHandler);
    return () => {
      off("userAction:click", actionHandler);
    };
  }, []);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.getBrokerFilterData(-999));
    dispatch(actions.getSkyBoxUserData());
    dispatch(actions.getRoleData());
    dispatch(actions.getSplitTypeData());
    dispatch(actions.getBuyerTagData());
    dispatch(actions.getListerTagData());
    dispatch(actions.getSkyBoxVendorData());
    dispatch(actions.getTicketbashBrokerData());
  }, [dispatch]);

  return userDataFetchError ? (
    <CustomError {...userDataFetchError} />
  ) : (
    <>
      <Content className="mainContainer allUsr">
        <div
          ref={actionRef}
          style={{ display: "none" }}
          onClick={() => actionHandler()}
        >
          Actions
        </div>
        <div className="innerHeader">
          <Row className="flx justifyContentBetween alignItemsCenter">
            <Col className="ant-col-sm-24 ant-col-md-6">
              <h3>All Users</h3>
            </Col>
            <Col className="ant-col-sm-24 ant-col-md-18 text-right">
              <div className="userTopRight forUser">
              <div className="switchToggle flx alignItemsCenter">
                <span>Show Inactive Users</span>
                <Switch
                  className="antSwitchSml onOff"
                  onChange={(e) =>
                    setQueryParams({
                      ...queryParams,
                      show_active_users_only: !e,
                    })
                  }
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  defaultChecked={false}
                />
              </div>
            
            <RBAC allowedPermissions={[ERbacPermissions.USER_CREATE]}>            
              <div><NewUser onSuccessUserSaved={onSuccessUserSaved} maxTicketsAllowed={maxTicketsAllowed}/></div>          
            </RBAC>
            </div>
            </Col>
          </Row>
        </div>
        <div className="removeTblLRSpc eventListTbl">
        <CommonReportTable
          tableClass="customTbl scroll-y fixedHeaderFooter"
          tableHeight={{ maxHeight: "calc(100vh - 210px)" }}
          onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
            setQueryParams({
              ...queryParams,
              sort_column: sortIndex,
              sort_order: sortOrder,
            });
          }}
          sortField={{
            dataIndex: queryParams?.sort_column,
            order: queryParams?.sort_order,
          }}
          data={userDetails}
          headers={userListingHeaders}
          loading={showLoader}
          otherData={{
            userId: user.user?.sub,
            maxTicketsAllowed: maxTicketsAllowed
          }}
          enableRowSelect={false}
        />
      </div>
      </Content>
    </>
  );
};

export default UserListingTable;
