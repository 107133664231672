import React from 'react';
import {
  daysToEventColorCheck,
  formatAccountingValues
} from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { AppSetting } from "utils/appSettings";
import { Tooltip, Typography } from "antd";
import { FormatCurrencyUnit } from "utils/formatCurrency";

const commonWidth = 100;

export const purchaseAssignmentDataHeaders = [
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    width: 80,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Date",
    dataIndex: "invoice_date",
    width: commonWidth,
    dataRender: (invoiceDate: string) =>
      invoiceDate && formatDateGivenFormatString(invoiceDate, "ddd, MMM DD, YYYY"),
  },
  {
    title: "PO Number",
    dataIndex: "purchase_id",
    width: 70,
    thClassName: "text-left eventDateTimeTh",
    dataRender: (basePoNumber: number[] | null) => {
      const poNumber = basePoNumber?.filter((v, i, a) => a.indexOf(v) === i);
      return (
        <React.Fragment>
          {poNumber && poNumber?.length > 1 && (
            <Tooltip
              title={poNumber.map(
                (each: number, index: number) => {
                  return (
                    <React.Fragment>
                      <Typography.Link
                        target="_blank"
                        rel="noreferrer"
                        href={AppSetting.PURCHASE_ID_URL + each}
                        underline={true}
                        style={{ color: "#1890ff" }}
                      >
                        {`${each}`}
                      </Typography.Link>
                      {`${index < poNumber.length - 1 ? ", " : ""}`}
                    </React.Fragment>
                  )
                })
              }
              className="nowrapTxt"
            >
              <span
                style={{ cursor: "default" }}
              >
                Multiple
              </span>
            </Tooltip>
          )}
          {poNumber && poNumber?.length === 1 &&
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + poNumber[0]}
              underline={true}
            >
              {`${poNumber[0]}`}
            </Typography.Link>
          }
        </React.Fragment>
      )
    }
  },
  {
    title: "PO Line Number",
    dataIndex: "purchase_line_id",
    width: 75,
    thClassName: "text-left eventDateTimeTh",
    dataRender: (basePoLineNumber: number[] | null) => {
      const poLineNumber = basePoLineNumber?.filter((v, i, a) => a.indexOf(v) === i);
      return (
        <React.Fragment>
          {poLineNumber && poLineNumber?.length > 1 && (
            <Tooltip
              title={poLineNumber.map(
                (each: number, index: number) => {
                  return (
                    <span
                      style={{ cursor: "default" }}
                    >
                      {`${each}${index < poLineNumber.length - 1 ? ", " : ""}`}
                    </span>
                  )
                })
              }
              className="nowrapTxt"
            >
              <span
                style={{ cursor: "default" }}
              >
                Multiple
              </span>
            </Tooltip>
          )}
          {poLineNumber && poLineNumber?.length === 1 &&
            <span>{poLineNumber[0]}</span>
          }
        </React.Fragment>
      )
    }
  },
  {
    title: "In-Hand Date",
    dataIndex: "in_hand_date",
    width: commonWidth,
    dataRender: (inHandDate: string) =>
      inHandDate && formatDateGivenFormatString(inHandDate, "ddd, MMM DD, YYYY"),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    width: 70,
    thClassName: "text-right dayToEventCol",
    tdClassName: "positionRel",
    dataRender: (data: number) => (
      <Tooltip
        placement="left"
        title={daysToEventColorCheck(data, "list").toolTipText}
      >
        <div
          className={
            "daysToEvent daysToEventData " +
            daysToEventColorCheck(data, "list").className
          }
        >
          <span style={{ cursor: "default" }}>{data}</span>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Section",
    dataIndex: "section",
    width: 70,
    dataRender: (section: string) =>
      <div className="text-capitalize">{section}</div>
  },
  {
    title: "Row",
    dataIndex: "row",
    width: 40,
    dataRender: (row: string) => <div className="text-upper">{row}</div>,
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    width: 40,
    thClassName: "text-right",
    dataRender: (quantity: number) => (
      <div className="text-right">
        {quantity}
      </div>
    ),
  },
  {
    title: "Unit Sale Price",
    dataIndex: "unit_sale_price",
    thClassName: "text-right eventDateTimeTh",
    width: 82,
    dataRender: (unitSalePrice: number, otherData: any) => (
      <div className={`text-right ${unitSalePrice < 0 ? "text-danger" : ""}`}>
        {unitSalePrice > 0 && otherData.data.singleData.currency_code
          ? FormatCurrencyUnit(
            formatAccountingValues(unitSalePrice),
            otherData.data.singleData.currency_code.toUpperCase()
          )
          : formatAccountingValues(unitSalePrice)}
      </div>
    ),
  },
  {
    title: "Stock Type",
    dataIndex: "stock_type",
    width: commonWidth,
  }
];
