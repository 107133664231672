import { Layout } from "antd";
import HighRiskListing from "./HighRiskListing";
import CancelledEvent from "./CancelledEvent";
import LowAvailability from "./LowAvailability";
import PricingIssue from "./PricingIssue";
import { HighRiskCategory } from "models/enums/highRiskCategory";

const ListingAlerts = () => {
  return (
    <Layout id="zoneInsights" className="mainInnerLayout zoneInsights dashboard">
      <h3>Listing Alerts - PO Lines</h3>
      <div className="zoneInsightsCardRow">
        <HighRiskListing widgetId={HighRiskCategory.less_than_7} />
        <CancelledEvent widgetId={HighRiskCategory.event_cancelled} />
        <PricingIssue widgetId={HighRiskCategory.pricing_issue} />
        <LowAvailability widgetId={HighRiskCategory.low_availability} />
      </div>
    </Layout>
  );
};

export default ListingAlerts;
