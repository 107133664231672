import { Button, message } from "antd";
import {
  IMenuOption,
  IOnSaleChangeData,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
} from "models/interfaces";
import React, { useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import ChangeOnSaleOptions from "./ChangeOnSaleOptions";

const swimlane_id = 3;

const ChangeOnSaleStatus: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster,
}) => {
  const [onSaleChangeData, setOnSaleChangeData] = useState<IOnSaleChangeData | null>({
    invoice_id: eachInvoiceData.invoice_id,
    event_date: eachInvoiceData.event_date,
    on_sale_type: eachInvoiceData.on_sale_type,
    on_sale_date: eachInvoiceData.on_sale_date,
  });
  const [isValid, setIsValid] = useState(false);

  const cancelHandler = () => {
    setSelectedMenuId(null);
    setOnSaleChangeData({
      invoice_id: eachInvoiceData.invoice_id,
      event_date: eachInvoiceData.event_date,
      on_sale_type: eachInvoiceData.on_sale_type,
      on_sale_date: eachInvoiceData.on_sale_date,
    });
  };

  const updateOnSaleData = async () => {
    const moveToOnSaleKey = "moveToOnSaleKey";
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Updating on sale details...`,
      duration: 0,
      key: moveToOnSaleKey,
      className: "toastMsg loadingMsg",
    });
    try {
      if (isValid && onSaleChangeData) {
        const res = await new PurchaseAndShippingService().changeOnSaleStatus(onSaleChangeData);
        if (res.data.statusCode === 200) {
          setOnSaleChangeData(null);
        }
        message.success({
          content: `On sale info updated for Invoice# ${eachInvoiceData.invoice_id}.`,
          duration: 5,
          key: moveToOnSaleKey,
          className: "toastMsg savedSuccess",
        });
        setIsValid(false);
      }
    } catch (error: any) {
      message.error({
        content: "Failed to move invoice.",
        duration: 5,
        key: moveToOnSaleKey,
        className: "toastMsg savedFailed",
      });
      new LoggerService().log({
        payload: error,
        function_name: "updateOnSaleData",
      });
    }
    requestToReloadTheLanes([swimlane_id]);
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <div className="moveOnSaleWrap">
          <ChangeOnSaleOptions
            purchaseQueueMaster={purchaseQueueMaster}
            onSaleChangeData={onSaleChangeData}
            setOnSaleChangeData={setOnSaleChangeData}
            eachInvoiceData={eachInvoiceData}
            setIsValid={setIsValid}
          />
          <div className="moveOnSaleRow btnGroupFooter">
            <Button className="discardBtn" onClick={cancelHandler}>
              <span>CANCEL</span>
            </Button>
            <Button
              className="btnUpdate"
              disabled={!isValid}
              onClick={updateOnSaleData}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangeOnSaleStatus;
