import { Tooltip, Typography } from "antd";
import CustomError from "controls/CustomError/CustomError";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { IHighRiskListings } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useEffect, useState } from "react";
import { getEncodedData, numberShorthand } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const ListingAlertsCard: React.FC<{
  errorObj: ICustomError | null;
  id: number;
  isLoading: boolean;
  data: IHighRiskListings | null;
  title?: string;
}> = ({ errorObj, id, isLoading, data, title }) => {
  const [max, setMax] = useState(0);

  const getMaxListingCount = () => {
    let listingsArray = data?.topFour
      ? data.topFour.map((eachUser) => eachUser.listings)
      : [0];
    data?.other && listingsArray?.push(data.other[0].listings);
    listingsArray &&
      setMax(
        listingsArray.reduce((a, b) => {
          return Math.max(a, b);
        })
      );
  };

  const getWidth = (input: number) => {
    if (input === max) return 100;
    if (input < max) return (input * 100) / max;
  };

  useEffect(() => {
    data && getMaxListingCount();
  }, [data]);

  const createQueryString = (users: string[], name: string) => {
    return getEncodedData({
      title: title,
      risk_type: id,
      name: name,
      users: JSON.stringify(users),
    });
  };

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className="zoneInsightsCard">
        <h3>{title}</h3>
        {errorObj ? (
          <CustomError {...errorObj} />
        ) : (
          <React.Fragment>
            {!Boolean(data && data.count) && !isLoading && (
              <div className="zoneInsightsCardInnerWrap cardCenter">
                <div className="zoneInsightsCardNoData">No Listings Found</div>
              </div>
            )}
            {Boolean(data && data.count) && (
              <div
                className={`zoneInsightsCardInnerWrap ${
                  data?.topFour === null && data.other === null
                    ? "cardCenter"
                    : ""
                }`}
              >
                <div className="zoneInsightsCardLeft">
                  <Typography.Link
                    target="_blank"
                    rel="noreferrer"
                    href={`${
                      LeftNavRoutesEnum["ZONES_LISTING"]
                    }?filter=${createQueryString(
                      ["all"],
                      "All"
                    )}&isDisabled=${getEncodedData({
                      disabled: true
                    })}`}
                    underline={false}
                    className="zoneInsightsCount danger"
                  >
                    {" "}
                    <span>{data && numberShorthand(data.count)}</span>
                  </Typography.Link>
                </div>
                {!(data?.topFour === null && data?.other === null) && (
                  <div
                    className="zoneInsightsCardRight"
                    id={`user_insights_${id}`}
                  >
                    {data &&
                      data.topFour?.map((eachUser, i) => {
                        if (eachUser.listings) {
                          return (
                            <div
                              className="progress-bar progress-warning"
                              id={`${eachUser.user_id}_${id}`}
                              key={`${eachUser.user_id}_${id}`}
                            >
                              <Typography.Link
                                target="_blank"
                                rel="noreferrer"
                                href={`${
                                  LeftNavRoutesEnum["ZONES_LISTING"]
                                }?filter=${createQueryString(
                                  [eachUser.user_id],
                                  eachUser.user_name
                                )}&isDisabled=${getEncodedData({
                                  disabled: true
                                })}`}
                                underline={false}
                                className="progress-text haveTooltip"
                              >
                                <span
                                  id={`topFour_${i}_${id}`}
                                  className="progress-text"
                                >
                                  <Tooltip
                                    overlayStyle={{ textTransform: "none" }}
                                    overlayInnerStyle={{
                                      textTransform: "none",
                                    }}
                                    placement={"right"}
                                    title={eachUser.user_name}
                                    arrowPointAtCenter={true}
                                  >
                                    <span style={{ cursor: "cursor" }}>
                                      {eachUser.user_name_initials.length > 10
                                        ? `${eachUser.user_name_initials
                                            .slice(0, 10)
                                            .trim()}...`
                                        : eachUser.user_name_initials}
                                    </span>
                                  </Tooltip>
                                </span>
                              </Typography.Link>
                              <div className="progress-outer">
                                <div
                                  className="progress-bg"
                                  style={{
                                    width: `${getWidth(eachUser.listings)}%`,
                                  }}
                                />
                              </div>
                              <span className="progressValue">
                                {numberShorthand(eachUser.listings)}
                              </span>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })}
                    {data &&
                      data.other?.map((eachUser, i) => {
                        if (eachUser.listings) {
                          return (
                            <div
                              className="progress-bar progress-warning"
                              key={`${eachUser.user_id[0]}_${id}`}
                            >
                              <Typography.Link
                                target="_blank"
                                rel="noreferrer"
                                href={`${
                                  LeftNavRoutesEnum["ZONES_LISTING"]
                                }?filter=${createQueryString(
                                  eachUser.user_id,
                                  "Others"
                                )}&isDisabled=${getEncodedData({
                                  disabled: true
                                })}`}
                                underline={false}
                                className="progress-text haveTooltip"
                              >
                                <span
                                  id={`other_${i}_${id}`}
                                  className="progress-text"
                                >
                                  {eachUser.user_name}
                                </span>
                              </Typography.Link>
                              <div className="progress-outer">
                                <div
                                  className="progress-bg"
                                  style={{
                                    width: `${getWidth(eachUser.listings)}%`,
                                  }}
                                />
                              </div>
                              <span className="progressValue">
                                {numberShorthand(eachUser.listings)}
                              </span>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })}
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default ListingAlertsCard;
