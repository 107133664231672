import { RoutesEnum } from "models/enums";
import { IReportDashboardCategoriesData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class ReportDashboardService {
  public getAllReports = async () => {
    return axiosClient.get<
      IApiBaseResponse<IReportDashboardCategoriesData>
    >(RoutesEnum.DASHBOARD_CATEGORY_LIST);
  };

  public addRemoveFavorite = async (id: number, operationType: number, favoriteCategoryId: number) => {
    return axiosClient.post<IApiBaseResponse<boolean>>(
      RoutesEnum.ADD_REMOVE_FAVORITES,
      {
        report_category_id: favoriteCategoryId,
        report_id: id,
        operation_type: operationType,
      }
    );
  };
}

export { ReportDashboardService };
