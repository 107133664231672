import { Layout } from "antd";
import WidgetListingsCountsAndSales from "./WidgetListingsCountsAndSales";
import WidgetListingsBrokers from "./WidgetListingsBrokers";
import { AppSetting } from "utils/appSettings";

const defaultFilterParam = {
  client_zone: AppSetting.TIMEZONE,
  page_index: AppSetting.DEFAULT_PAGE_INDEX,
  page_size: AppSetting.PAGE_SIZE,
};

const PendingFillsAlerts = () => {
  return (
    <Layout id="zoneInsights" className="mainInnerLayout zoneInsights dashboard">
      <h3>Pending Fills Alerts</h3>
      <div className="zoneInsightsCardRow">
        <div className="highRiskCardCol">
          <WidgetListingsCountsAndSales
            ifCountsWidget={true}
            functionType={"getPendingZoneFillsWidgetsCounts"}
            defaultFilterParams={defaultFilterParam}
          />
        </div>
        <div className="highRiskCardCol">
          <WidgetListingsCountsAndSales
            ifCountsWidget={false}
            functionType={"getPendingZoneFillsWidgetsSales"}
            defaultFilterParams={defaultFilterParam}
          />
        </div>
        <div className="highRiskCardCol">
          <WidgetListingsBrokers defaultFilterParams={defaultFilterParam} />
        </div>
      </div>
    </Layout>
  );
};

export default PendingFillsAlerts;
