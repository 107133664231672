import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { ServerError } from "layout/ServerError";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { ReportRoutesEnum } from "models/enums/reportRoutes";
import {
  LoginPage,
  ZoneListingsPage,
  PendingFillsPage,
  EditEventPage,
  StyleGuidePage,
  GrossProfitHtmlPage,
  UnderMaintenancePage,
  AllReportsPage,
  GrossProfit,
  GrossProfitCancelled,
  SchedulerPage,
  InsightsPage,
  GrossProfitSnapshotReport,
  LongInventory,
  LongInventoryScorecard,
  FinancePage,
  BrokerPage,
  BuyerPage,
  OperationsPage,
  ManagementPage,
  Venues,
  EventsPage,
  InboxPage,
  ArchivesPage,
  SentPage,
  Users,
  Roles,
  Privileges,
  CancelledShows,
  CommonRoute,
  CreateNewZoneListingPage,
  ReceivablesDetailsReportPage,
  ReceivablesDetailsReportPageOld,
  ActiveZoneListingReportPage,
  ActiveListingPage,
  ListerDashboardPage,
  BulkPlacementPage,
  CheckerDashboardPage,
  SeatMapCreatorDashboardPage,
  PurchasesPage,
  ShippingPage,
  EventMappingPage,
  BotPerformanceDashboardPage,
  PerformanceAnalysisPage
} from "views";
import DeliveredTicketsPage from "views/DeliveredTickets/DeliveredTicketsPage";
import OtherCostsAndCreditsPage from "views/Insights/Reports/AccountingAndFinance/OtherCostsAndCredits/OtherCostsAndCreditsPage";
import InvoicesNeedingAttention from "views/Insights/Reports/Exception/InvoicesNeedingAttention/InvoicesNeedingAttention";
import Commissions from "views/Settings/Commissions/Commissions";

const routes = [
  {
    auth: true,
    path: "/",
    exact: true,
    component: ManagementPage,
  },
  {
    auth: false,
    path: "/login",
    exact: true,
    component: LoginPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_MANAGEMENT,
    exact: true,
    component: ManagementPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_FINANCE,
    exact: true,
    component: FinancePage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_BROKER,
    exact: true,
    component: BrokerPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_LISTER,
    exact: true,
    component: ListerDashboardPage,
    allowedPermissions: [
      ERbacPermissions.LISTER_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_OPERATIONS,
    exact: true,
    component: OperationsPage,
    allowedPermissions: [
      ERbacPermissions.OPERATIONS_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_BOT_PERFORMANCE,
    exact: true,
    component: BotPerformanceDashboardPage,
    allowedPermissions: [
      ERbacPermissions.BOT_PERFORMANCE_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS,
    exact: true,
    component: PerformanceAnalysisPage,
    allowedPermissions: [
      ERbacPermissions.PERFORMANCE_ANALYSIS_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_CHECKER,
    exact: true,
    component: CheckerDashboardPage,
    allowedPermissions: [
      ERbacPermissions.CHECKER_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_SEATMAPCREATOR,
    exact: true,
    component: SeatMapCreatorDashboardPage,
    allowedPermissions: [
      ERbacPermissions.SEATMAPCREATOR_DASHBOARD_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DASHBOARD_BUYER,
    exact: true,
    component: BuyerPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.ZONES_LISTING,
    exact: true,
    component: ZoneListingsPage,
    allowedPermissions: [
      ERbacPermissions.VENUE_DETAILS_VIEW_ALL,
      ERbacPermissions.VENUE_DETAILS_VIEW_TEAM,
      ERbacPermissions.VENUE_DETAILS_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.ZONES_ACTIVE_LISTING,
    exact: true,
    component: ActiveListingPage,
    allowedPermissions: [
      ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY,
    exact: true,
    component: EditEventPage,
    allowedPermissions: [
      ERbacPermissions.EVENT_METADATA_EDIT,
      ERbacPermissions.EVENT_METADATA_EDIT_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.ZONES_PENDING_FILLS,
    exact: true,
    component: PendingFillsPage,
    allowedPermissions: [
      ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.ZONES_INSIGHTS, //will change later
    exact: true,
    component: InsightsPage, //will change later
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS,
    exact: true,
    component: CommonRoute,
    allowedPermissions: [
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE,
    exact: true,
    component: CancelledShows,
    allowedPermissions: [
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_SOLD,
    exact: true,
    component: CancelledShows,
    allowedPermissions: [
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN,
      ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_UNFILLED,
    exact: true,
    component: CancelledShows,
    allowedPermissions: [
      ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_OWN,
      ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED,
    exact: true,
    component: CancelledShows,
    allowedPermissions: [
      ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_ALL,
      ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_TEAM,
      ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_OWN,
    ],
  },
  // {
  //   auth: true,
  //   path: LeftNavRoutesEnum.HOUSEKEEPING_EVENT_MAPPING,
  //   exact: true,
  //   component: EventMappingPage,
  //   allowedPermissions: [
  //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_ALL,
  //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_TEAM,
  //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_OWN,
  //   ],
  // },
  {
    auth: true,
    path: LeftNavRoutesEnum.TRADING_VENUES,
    exact: true,
    component: Venues,
    allowedPermissions: [
      ERbacPermissions.VENUE_DETAILS_VIEW_ALL,
      ERbacPermissions.VENUE_DETAILS_VIEW_OWN,
      ERbacPermissions.VENUE_DETAILS_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.TRADING_EVENTS,
    exact: true,
    component: EventsPage,
    allowedPermissions: [
      ERbacPermissions.EVENT_DETAILS_VIEW_ALL,
      ERbacPermissions.EVENT_DETAILS_VIEW_OWN,
      ERbacPermissions.EVENT_DETAILS_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING,
    exact: true,
    component: CreateNewZoneListingPage,
    allowedPermissions: [
      ERbacPermissions.EVENT_CREATE_NEW_LISTING,
    ],
  },
  // {
  //   auth: true,
  //   path: LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_WITHOUT_METADATA,
  //   exact: true,
  //   component: CreateNewZoneListingWithoutMetadataPage,
  //   allowedPermissions: [
  //     ERbacPermissions.EVENT_CREATE_NEW_LISTING,
  //   ],
  // },
  {
    auth: true,
    path: LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_BULK_PLACEMENT,
    exact: true,
    component: BulkPlacementPage,
    allowedPermissions: [
      ERbacPermissions.EVENT_BULK_CREATE_NEW_LISTING,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.PURCHASE_QUEUE,
    exact: true,
    component: PurchasesPage,
    allowedPermissions: [
      ERbacPermissions.PURCHASE_QUEUE_VIEW_ALL,
      ERbacPermissions.PURCHASE_QUEUE_VIEW_TEAM,
      ERbacPermissions.PURCHASE_QUEUE_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.SHIPPING_QUEUE,
    exact: true,
    component: ShippingPage,
    allowedPermissions: [
      ERbacPermissions.SHIPPING_QUEUE_VIEW_ALL,
      ERbacPermissions.SHIPPING_QUEUE_VIEW_TEAM,
      ERbacPermissions.SHIPPING_QUEUE_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DELIVERED_TICKETS_REPORT,
    exact: true,
    component: DeliveredTicketsPage,
    allowedPermissions: [
      ERbacPermissions.REPORT_DELIVERED_TICKETS_VIEW_ALL
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.INSIGHTS_REPORTS,
    exact: true,
    component: AllReportsPage,
    allowedPermissions: [ERbacPermissions.REPORT_DASHBOARD_VIEW],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.INSIGHTS_SCHEDULES,
    exact: true,
    component: SchedulerPage,
    allowedPermissions: [
      ERbacPermissions.SCHEDULES_VIEW_ALL,
      ERbacPermissions.SCHEDULES_VIEW_OWN,
      ERbacPermissions.SCHEDULES_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.GROSS_PROFIT_REPORT,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_GROSS_PROFIT_VIEW_ALL,
      ERbacPermissions.REPORT_GROSS_PROFIT_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.INVOICES_NEEDING_ATTENTION,
    exact: true,
    component: InvoicesNeedingAttention,
    allowedPermissions: [
      ERbacPermissions.REPORT_INVOICES_NEEDING_ATTENTION_VIEW_ALL,
      ERbacPermissions.REPORT_INVOICES_NEEDING_ATTENTION_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.GROSS_PROFIT_CANCELLED_REPORT,
    exact: true,
    component: GrossProfitCancelled,
  },
  {
    auth: true,
    path: ReportRoutesEnum.PROFIT_DASHBOARD_REPORT,
    exact: true,
    component: GrossProfitSnapshotReport,
    allowedPermissions: [
      ERbacPermissions.REPORT_GROSS_PROFIT_SNAPSHOT_VIEW_ALL,
      ERbacPermissions.REPORT_GROSS_PROFIT_SNAPSHOT_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.LONG_INVENTORY_REPORT,
    exact: true,
    component: LongInventory,
    allowedPermissions: [
      ERbacPermissions.REPORT_LONG_INVENTORY_VIEW_ALL,
      ERbacPermissions.REPORT_LONG_INVENTORY_VIEW_OWN,
      ERbacPermissions.REPORT_LONG_INVENTORY_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.LONG_INVENTORY_NOT_LISTED_REPORT,
    exact: true,
    component: LongInventory,
    allowedPermissions: [
      ERbacPermissions.REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_ALL,
      ERbacPermissions.REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_OWN,
      ERbacPermissions.REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.LONG_INVENTORY_SCORECARD_REPORT,
    exact: true,
    component: LongInventoryScorecard,
    allowedPermissions: [
      ERbacPermissions.REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_ALL,
      ERbacPermissions.REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_OWN,
      ERbacPermissions.REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.BROKER_TAG_ISSUES_REPORT,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_ALL,
      ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.CONSIGNMENT_SALES_REPORT,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_CONSIGNMENT_SALES_VIEW_ALL,
      ERbacPermissions.REPORT_CONSIGNMENT_SALES_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.SHORTS_PENDING_DELIVERY_REPORT,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_ALL,
      ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.CONSIGNMENT_PENDING_BUY_IN_REPORT,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_CONSIGNMENT_PENDING_BUY_IN_VIEW_ALL,
      ERbacPermissions.REPORT_CONSIGNMENT_PENDING_BUY_IN_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.INVOICES_NEEDING_ATTENTION,
    exact: true,
    component: GrossProfit,
    allowedPermissions: [
      ERbacPermissions.REPORT_INVOICES_NEEDING_ATTENTION_VIEW_ALL,
      ERbacPermissions.REPORT_INVOICES_NEEDING_ATTENTION_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.RECEIVABLES_DETAILS,
    exact: true,
    component: ReceivablesDetailsReportPage,
    allowedPermissions: [
      ERbacPermissions.REPORT_RECEIVABLES_DETAILS_VIEW_ALL,
      ERbacPermissions.REPORT_RECEIVABLES_DETAILS_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.RECEIVABLES_RETAIL,
    exact: true,
    component: ReceivablesDetailsReportPage,
    allowedPermissions: [
      ERbacPermissions.REPORT_RECEIVABLES_RETAIL_VIEW_ALL,
      ERbacPermissions.REPORT_RECEIVABLES_RETAIL_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.RECEIVABLES_DETAILS_OLD,
    exact: true,
    component: ReceivablesDetailsReportPageOld,
    allowedPermissions: [
      ERbacPermissions.REPORT_RECEIVABLES_DETAILS_VIEW_ALL,
      ERbacPermissions.REPORT_RECEIVABLES_DETAILS_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.RECEIVABLES_RETAIL_OLD,
    exact: true,
    component: ReceivablesDetailsReportPageOld,
    allowedPermissions: [
      ERbacPermissions.REPORT_RECEIVABLES_RETAIL_VIEW_ALL,
      ERbacPermissions.REPORT_RECEIVABLES_RETAIL_VIEW_OWN,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.ACTIVE_ZONE_LISTING_REPORT,
    exact: true,
    component: ActiveZoneListingReportPage,
    allowedPermissions: [
      ERbacPermissions.REPORT_ACTIVE_ZONE_LISTING_VIEW_ALL,
      ERbacPermissions.REPORT_ACTIVE_ZONE_LISTING_VIEW_OWN,
      ERbacPermissions.REPORT_ACTIVE_ZONE_LISTING_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: ReportRoutesEnum.OTHER_COSTS_AND_CREDITS,
    exact: true,
    component: OtherCostsAndCreditsPage,
    allowedPermissions: [
      ERbacPermissions.REPORT_OTHER_COSTS_AND_CREDITS_VIEW_ALL,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.MESSAGES_INBOX,
    exact: true,
    component: InboxPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.MESSAGES_ARCHIVES,
    exact: true,
    component: ArchivesPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.MESSAGES_SENT,
    exact: true,
    component: SentPage,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.SETTINGS_USERS,
    exact: true,
    component: Users,
    allowedPermissions: [
      ERbacPermissions.USER_DETAILS_VIEW_ALL,
      ERbacPermissions.USER_DETAILS_VIEW_OWN,
      ERbacPermissions.USER_DETAILS_VIEW_TEAM,
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.SETTINGS_COMMISSIONS,
    exact: true,
    component: Commissions,
    allowedPermissions: [
      ERbacPermissions.SETTINGS_COMMISSIONS_VIEW
    ],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.SETTINGS_ROLES,
    exact: true,
    component: Roles,
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.SETTINGS_PRIVILEGES,
    exact: true,
    component: Privileges,
  },
  {
    path: "/server-error",
    exact: true,
    component: ServerError,
  },
  {
    auth: false,
    path: "/styleguide",
    exact: true,
    component: StyleGuidePage,
  },
  {
    auth: false,
    path: "/gross-profit-html",
    exact: true,
    component: GrossProfitHtmlPage,
  },
  {
    auth: false,
    path: "/under-maintenance-page",
    exact: true,
    component: UnderMaintenancePage,
  },
];

export { routes };
