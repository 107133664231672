import { Checkbox, DatePicker, Select, Switch, Tooltip } from "antd";
import { IDefaultDateFormValue, IListElement } from "models/interfaces";
import { dateType } from "models/types/Reports/dateType";
import React, { useState } from "react";
import { filterSortByRanking } from "utils/commonFunction";
import {
  getCustomDate,
  getDateObject,
  getPresetRange,
} from "utils/formatTimePeriod";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const FilterOption: React.FC<{
  keyValue: string;
  type: string;
  label: string;
  from?: dateType;
  to?: dateType;
  onchange: Function;
  placeholder?: string;
  defaultValue?: any;
  dropdownMode?: "multiple" | "tags";
  dropdownData?: IListElement[];
  defaultDateFormValue?: IDefaultDateFormValue;
  info?: string | null;
}> = (props) => {
  const { keyValue, defaultDateFormValue } = props;
  const [inputValue, setInputValue] = useState("");
  const handleOnSearch = (value: React.SetStateAction<string>) => {
    setInputValue(value);
  };

  function disabledDate(current: any) {
    return (
      current > getDateObject(props.defaultDateFormValue?.rangeTo) ||
      current < getDateObject(props.defaultDateFormValue?.rangeFrom)
    );
  }

  const ifAllDatePresent = () => {
    setTimeout(() => {
      if (
        Object.keys(
          getPresetRange(keyValue, {
            initialStartDate: getDateObject(defaultDateFormValue?.defaultFrom),
            initialEndDate: getDateObject(defaultDateFormValue?.defaultTo),
          })
        ).includes("All")
      ) {
        const elem = document.getElementsByClassName("ant-tag");
        elem[elem.length - 1]?.classList?.add("ant-tag-custom");
      }
    }, 10);
  };

  return (
    <div className="inputWrap">
      {props.type !== "checkbox" && props.type !== "switch" && (
        <label style={{display: 'flex'}}>
          {props.label}
          &nbsp;
          {props.info && (<Tooltip title={props.info}><i className="da icon-info-fill" style={{marginLeft: "5px", verticalAlign: "-25px", width: "12px", height: "12px"}}/></Tooltip>)}
        </label>
      )}
      {props.type === "datePicker" && (
        <div
          className="rangePickerWrap"
          onClick={ifAllDatePresent}
          id={props.keyValue}
        >
          <RangePicker
            value={[
              getDateObject(getCustomDate(props.from, dateFormat)),
              getDateObject(getCustomDate(props.to, dateFormat)),
            ]}
            disabledDate={disabledDate}
            ranges={getPresetRange(keyValue, {
              initialStartDate: getDateObject(
                defaultDateFormValue?.defaultFrom
              ),
              initialEndDate: getDateObject(defaultDateFormValue?.defaultTo),
              rangeTo: getDateObject(props.defaultDateFormValue?.rangeTo),
              rangeFrom: getDateObject(props.defaultDateFormValue?.rangeFrom),
            })}
            key={props.keyValue}
            onChange={(dateStrings: any) => {
              dateStrings &&
                props.onchange(
                  props.keyValue,
                  dateStrings[0]?.format("YYYY-MM-DD"),
                  dateStrings[1]?.format("YYYY-MM-DD")
                );
            }}
            allowClear={false}
          />
        </div>
      )}
      {props.type === "dropdown" && (
        <div id={props.label}>
          <Select
            showSearch
            options={props.dropdownData}
            placeholder={props.placeholder}
            mode={props.dropdownMode}
            value={props.defaultValue}
            onChange={(value) => props.onchange(keyValue, value)}
            onSelect={()=>setInputValue("")}
            onDropdownVisibleChange={(open)=>open && setInputValue("")}
            onSearch={handleOnSearch}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input?.toLowerCase())
                ? true
                : false
            }
            filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
          />
        </div>
      )}
      {props.type === "checkbox" && (
        <div className="checkboxGroup" id={props.label}>
          <Checkbox
            checked={props.defaultValue}
            onChange={(value) => props.onchange(keyValue, value.target.checked)}
            key={props.label}
          >
            {props.label}
          </Checkbox>
        </div>
      )}
      {props.type === "switch" && (
        <div className="switchToggle flx alignItemsCenter switchWrap">
          <label>{props.label}</label>
          <Switch
            onChange={(e) => props.onchange(keyValue, e)}
            checkedChildren="YES"
            unCheckedChildren="NO"
            defaultChecked={props.defaultValue}
            key={props.label}
          />
        </div>
      )}
    </div>
  );
};

export default FilterOption;
