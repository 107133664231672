import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  setQueryParams,
  eventListData,
  queryParams,
  isLoadingEvents,
  eventManagementActions,
} from "models/Slices/EventManagementSlice";
import React from "react";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import { eventsHeaders } from "./eventsUtils";

const EventsListView: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventQueryParams = useAppSelector(queryParams);
  const eventListDetails = useAppSelector(eventListData);
  const isLoadingEventsData = useAppSelector(isLoadingEvents);

  return (
    <React.Fragment>
      {
        !eventListDetails?.eventDetailsData
          ?
          <LoadingSpinner isLoading={isLoadingEventsData}>
            <div className='noResultFoundBlock'>No Result Found</div>
          </LoadingSpinner>
          :
          <div className="eventListTbl">
            <CommonReportTable
              tableClass="customTbl scroll-x-y fixedHeaderFooter"
              tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
              onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                dispatch(
                  setQueryParams({
                    sort_column: sortIndex,
                    sort_order: sortOrder,
                  })
                );
                dispatch(
                  eventManagementActions.getEventListData({
                    functionName: "getEventDetails",
                  })
                );
              }}
              sortField={{
                dataIndex: eventQueryParams.sort_column
                  ? eventQueryParams.sort_column
                  : "days_to_event",
                order: eventQueryParams.sort_order
                  ? eventQueryParams.sort_order
                  : "asc",
              }}
              data={
                eventListDetails?.eventDetailsData
                  ? eventListDetails?.eventDetailsData
                  : []
              }
              headers={eventsHeaders}
              loading={isLoadingEventsData}
              enableRowSelect={false}
            />
          </div>
      }
    </React.Fragment>
  );
};

export default EventsListView;
