import { useState } from "react";
import Logo from "../../assets/images/logo.svg";

import {
  Layout,
  Menu,
  Dropdown,
  Row,
  Col,
  Pagination,
  Table,
  Input,
  Button,
  Select,
  Checkbox,
  DatePicker,
  Modal
} from "antd";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const menu = (
  <Menu>
    <Menu.Item>Rajib Sahoo</Menu.Item>
    <Menu.Item>Logout</Menu.Item>
  </Menu>
);

const { Column } = Table;


const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";


export function GrossProfitHtmlPage() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mainWrapper">
      <Header className="header">
        <div className="headerLeft">
          <a href="#/" className="logo">
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>
        </div>
        <ul className="headerRight">
          <li>
            <div>
              <i className="da icon-bell" />
            </div>
          </li>
          <li>
            <div>
              <i className="da icon-question" />
            </div>
          </li>
          <li>
            <div>
              <i className="da icon-user" />
            </div>
          </li>
          <li className="username">
            <Dropdown overlay={menu} trigger={['click']} className="dropdown">
              <a href="#/" onClick={(e) => e.preventDefault()} className="dropdown-btn">
                Username <i className="da icon-arrow-down" />
              </a>
            </Dropdown>
          </li>
        </ul>
      </Header>
      <div className="bodyWrapper">
        <Sider width={150} className="sidebarSiderWrap">
          <div className="sidebarHeader">
            <button type="button" className="toggleSidebarBtn">
              <i className="da icon-arrow-back-circle" />
            </button>
          </div>
          <Menu defaultSelectedKeys={["1"]} mode="inline" className="leftMenu">
            <Menu.Item key="1" icon={<i className="da icon-home" />}>
              Dashboard
            </Menu.Item>
            <SubMenu
              key="sub1"
              icon={<i className="da icon-zone" />}
              title="Zones"
            >
              <Menu.Item key="2">Zone Listings</Menu.Item>
              <Menu.Item key="3">Pending Fills</Menu.Item>
              <Menu.Item key="4">Insights</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<i className="da icon-event" />}
              title="Venues &amp; Events"
            >
              <Menu.Item key="events1">Events1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub4"
              icon={<i className="da icon-message" />}
              title="Message"
            >
              <Menu.Item key="message">Message1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub5"
              icon={<i className="da icon-report" />}
              title="Reports"
            >
              <Menu.Item key="reports">Reports1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub6"
              icon={<i className="da icon-settings" />}
              title="Settings"
            >
              <Menu.Item key="settings1">Settings1</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>

        <Layout className="contentMainLayout">

          <h2 className="headimhStyleh2">GROSS PROFIT</h2>
          <Layout className="mainInnerLayout reduceTopSpace pb-0 newTblWrap">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="alignItemsCenter">
                  <Col span={6}>
                    <h3 id="inventoryReportTables">Gross Profit Report</h3>
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input type="search" placeholder="Search report..." />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight rightFilter">
                      <li>
                        <h4>548 Records Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                      <li>
                        <button type="button">
                          <i className="da icon-calendar-o"></i>
                        </button>
                      </li>
                      <li>
                        <button>
                          <i className="da icon-listview listviewIcon"></i>
                        </button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <div className="table-scroll fixedHeaderFooter" style={{ maxHeight: 'calc(100vh - 250px)' }}>
                  <table className="main-table">
                    <thead>
                      <tr>
                        <th className="colLeftSticky " style={{ left: '0px', minWidth: '100px' }}>P&amp;L Date<i className="da sortIcon icon-sort"></i></th>
                        <th className="colLeftSticky colLeftStickyLast " style={{ left: '100px', minWidth: '100px' }}>Invoice ID<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Invoice Date<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Fill Date<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Qty<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Currency<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Unit Cost<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Unit Ticket Sales<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Ticket Cost<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Ticket Sales<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>P &amp; L<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Profit (Loss)<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Fees<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Gross Profit<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Margin %<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>External Reference<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Customer<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Purchase ID<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Vendor<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Event<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Event Date<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Days to Event<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Venue<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Section<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Row<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Seats<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Internal Notes<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Fulfillment Status<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Fulfillment Date<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Inventory Tags<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Invoice Tags<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Fill Tags<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Consignment<i className="da sortIcon icon-sort"></i></th>
                        <th className=" " style={{ minWidth: '100px' }}>Data Source<i className="da sortIcon icon-sort-asc"></i></th>
                      </tr>
                      <tr className="subheader">
                        <th className="colLeftSticky" style={{ left: '0px', minWidth: '100px', top: '48px' }}>Total</th>
                        <th className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                        <th className="" style={{ minWidth: '100px', top: '48px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                      <tr className="">
                        <td className="colLeftSticky" style={{ left: '0px', minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="colLeftSticky colLeftStickyLast" style={{ left: '100px', minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">42734371</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">2</div></td>
                        <td className="" style={{ minWidth: '100px' }}>USD</td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">99.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">120.65</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">198.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">241.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.00</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right" style={{ fontWeight: 'bold' }}>43.30</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="text-right">0.18%</div></td>
                        <td className="" style={{ minWidth: '100px' }}>DVWZV3NQPK</td>
                        <td className="" style={{ minWidth: '100px' }}><span>Gametime (1Tick...</span></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">59395664</a></td>
                        <td className="" style={{ minWidth: '100px' }}><div>GIANTS</div></td>
                        <td className="" style={{ minWidth: '100px' }}><a target="_blank" rel="noreferrer" href="#">Atlanta Falcons at New York Giants (Eli Manning Jersey Retirement)</a></td>
                        <td className="" style={{ minWidth: '100px' }}><time >09-26-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}><div className="ant-tag danger text-right">-44</div></td>
                        <td className="" style={{ minWidth: '100px' }}><div>MetLife Stadium</div></td>
                        <td className="" style={{ minWidth: '100px' }}>307</td><td className="" style={{ minWidth: '100px' }}>10</td>
                        <td className="" style={{ minWidth: '100px' }}>3-4</td><td className="" style={{ minWidth: '100px' }}><span>goochbash3@gmai...</span></td>
                        <td className="" style={{ minWidth: '100px' }}>COMPLETE</td><td className="" style={{ minWidth: '100px' }}><time>09-09-2021</time></td>
                        <td className="" style={{ minWidth: '100px' }}>vin</td>
                        <td className="" style={{ minWidth: '100px' }}>revealmarkets-paid,tb_paid</td>
                        <td className="" style={{ minWidth: '100px' }}></td><td className="" style={{ minWidth: '100px' }}>No</td>
                        <td className="" style={{ minWidth: '100px' }}>Sold Inventory</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Content>
          </Layout>
          
          <h2 className="headimhStyleh2">NEW USER</h2>
          <Layout className="mainInnerLayout reduceTopSpace">
            <h3>Add New User</h3>
            <div className="ant-row ">

              <div className="ant-col ant-col-xl-24">
                <div className="createUserForm">
                  <h5>General Info</h5>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>First Name</label>
                        <input placeholder="First Name" type="text" className="ant-input" />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Last Name</label>
                        <input placeholder="Last Name" type="text" className="ant-input"/>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Email</label>
                        <input placeholder="Email" type="email" className="ant-input"/>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Tag</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Skybox User ID</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Roles</label>
                        <ul className="checkboxList">
                          <li><Checkbox>Admin</Checkbox></li>
                          <li><Checkbox>Management</Checkbox></li>
                          <li><Checkbox>Developer</Checkbox></li>
                          <li><Checkbox checked>Broker</Checkbox></li>
                          <li><Checkbox>Buyer</Checkbox></li>
                          <li><Checkbox checked>Lister</Checkbox></li>
                          <li><Checkbox>Shipper</Checkbox></li>
                          <li><Checkbox>Accounting</Checkbox></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-10">
                      <div className="inputGroup">
                        <label>Commission %</label>
                        <Input placeholder="Commission %" suffix="%" defaultValue="" />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-10">
                      <div className="inputGroup">
                        <label>Default Listing Quantity</label>

                        <input placeholder="Default Listing Quantity" type="text" className="ant-input"/>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-10">
                      <div className="inputGroup">
                        <label>Default Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-10">
                      <div className="inputGroup">
                        <label>Default Markup</label>
                        <Input addonBefore="$" placeholder="Default Markup" defaultValue="" />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-10">
                      <div className="inputGroup">
                        <label>Assign to Broker</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editUserInfoFooter">
                  <button type="button" className="linkBtn">CANCEL</button>
                  <button type="button" className="ant-btn btnSave">SAVE</button>
                </div>
              </div>
            </div>
          </Layout>
          <Layout className="mainInnerLayout" id="schedulerModal">
            <h3>Create New Scheduler</h3>
            <Button type="primary" className="ant-btn btnSave" onClick={() => setShowModal(true)}>
              Create New Scheduler Modal
            </Button>
            <Modal
              title="Create New Scheduler"
              visible={showModal}
              footer={false}
              centered
              // onOk={() => setShowModal(false)}
              onCancel={() => setShowModal(false)}
              className="customModal"
              width={820}
            >
              <div className="ant-row">
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Report Name</label>
                    <Input placeholder="input placeholder" />
                  </div>
                </div>
                <div className="ant-col pr20 ant-col-xl-19">
                  <div className="inputGroup ">
                    <label>Report Title</label>
                    <Input placeholder="input placeholder" />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-5">
                  <div className="inputGroup">
                    <label>Frequency</label>
                    <Select placeholder="Select" allowClear>
                      <Option value="option1">Option1</Option>
                      <Option value="option2">Option2</Option>
                      <Option value="option3">Option3</Option>
                    </Select>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Data</label>
                    <div>Report to be sent on <a href="#">3rd</a> of every month at <a href="#">9AM</a> IST and ends <span className="customTooltipWrap">
                      <a href="#">Never</a>
                      <div className="customTooltip">
                        <h6>Ends</h6>
                        <ul className="endsList">
                          <li className="active"><span className="listBox"></span><b>Never</b></li>
                          <li><span className="listBox"></span><b>On</b> 31, March 2019</li>
                          <li><span className="listBox"></span><b>After</b> 10 Occurrences</li>
                        </ul>
                      </div>
                    </span>.</div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Filter</label>
                    <div className="filters">
                      <span className="ant-tag info"><b>Date Range:</b> This Month</span>
                      <span className="ant-tag info"><b>Broker:</b> Doug Russo</span>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Recipients</label>
                    <div className="">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={['Option1', 'Option2']}
                      //onChange={handleChange}
                      >
                        <Option value="option1">Option1</Option>
                        <Option value="option2">Option2</Option>
                        <Option value="option3">Option3</Option>
                      </Select>
                    </div>
                  </div>

                  <div className="inputGroup">
                    <textarea className="txtArea" placeholder="Add a custom message here (optional)"></textarea>
                  </div>
                </div>

              </div>
              <div className="modalFooter">
                <button type="button" className="linkBtn" onClick={() => setShowModal(false)}>CANCEL</button>
                <button type="button" className="ant-btn btnSave">SAVE</button>
              </div>
            </Modal>
          </Layout>


        </Layout>
      </div>
    </div>
  );
}
