import React, { useCallback, useEffect, useState } from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { IConversionRateInfo } from 'models/interfaces';
import { PerformanceAnalysisDashboardService } from 'services';
import CustomError from 'controls/CustomError/CustomError';
import { getTooltipTitle } from '../../PerformanceAnalysisPage';
import PerformanceAnalysisInformationTooltip from '../../PerformanceAnalysisInformationTooltip';
import {
    widgetClassPerCol
} from "views/Dashboard/CommonComponents/CommonFile/commonFile";
import { Col, Row, Tag } from 'antd';

const ConversionRate: React.FC = () => {

    const INFORMATION = `For each lister the formula is 
    count of all sales / (count of all listings - last 30 days of listings)
    Count of all sales: all  invoices for which Category = Wholesale and Type = Zone and TB2.0 = Yes
    Count of all Listings: all zone listings created from the TB2.0 platform.`;

    const [isServiceLoading, setIsServiceLoading] = useState(false);
    const [listersInfo, setListersInfo] = useState<IConversionRateInfo[]>([]);
    const [dateRange, setDateRange] = useState<String>('');
    const [error, setError] = useState<any>(null);

    const getConversionRateInfo = useCallback(async () => {
        setIsServiceLoading(true);
        try {
            setListersInfo([]);
            setError(null);
            const response = await new PerformanceAnalysisDashboardService().getListerConversionRate();
            const { data } = response.data.data;
            if (data) {
                const { start_date, end_date } = data[0];
                setDateRange('( ' + getTooltipTitle(start_date, end_date) + ' )');
                setListersInfo(data);
            } else {
                setListersInfo([]);
            }
            setIsServiceLoading(false);
        } catch (error) {
            setIsServiceLoading(false);
            setError(error);
        }

    }, []);

    useEffect(() => {
        getConversionRateInfo()
        return () => { }
    }, [])


    return (
        <Row>
            <Col className={widgetClassPerCol[3]}>
                {
                    error ?
                        <CustomError
                            {...error}
                            refreshAction={getConversionRateInfo}
                        /> :
                        <div className="dashboardCard2">
                            <div className="dashboardCardHeader">
                                <span>Conversion Rate {dateRange} <PerformanceAnalysisInformationTooltip information={INFORMATION} /></span>
                            </div>
                            <div className="dashboardCardBody listerSeatAvailability">
                                <LoadingSpinner isLoading={isServiceLoading}>
                                    <ul>
                                        {
                                            listersInfo.map(({ first_name, last_name, conversion_rate }) =>
                                                <li>
                                                    <p>
                                                        <span className={ first_name === 'TOTAL' ? "text-capitalize bold-text" : "text-capitalize" }>{first_name} {last_name}</span>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            <span>&nbsp;&nbsp;</span>
                                                        </div>
                                                    </p>
                                                    <span>
                                                        <Tag className="success">
                                                            {conversion_rate}%
                                                        </Tag>
                                                    </span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </LoadingSpinner>
                            </div>
                        </div>
                }
            </Col>
        </Row>
    )
}

export default ConversionRate