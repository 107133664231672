import { Dropdown } from "antd";
import { IListElement } from "models/interfaces";
import { useState } from "react";
import KanbanLaneFilterForm from "./KanbanLaneFilterForm";

const KanbanLaneFilter: React.FC<{
    purchaseIssueTypeMaster?: IListElement[] | null;
    shippingIssueTypeMaster?: IListElement[] | null;
    onApply?: Function;
}> = ({
    purchaseIssueTypeMaster,
    shippingIssueTypeMaster,
    onApply
}) => {
    const [showForm, setShowForm] = useState(false);
    const [showRedDot, setShowRedDot] = useState(false);
    return (
        <Dropdown
            overlay={
                    <KanbanLaneFilterForm 
                        purchaseIssueTypeMaster={purchaseIssueTypeMaster}
                        shippingIssueTypeMaster={shippingIssueTypeMaster}
                        onApply={(data:any)=>{
                            if(onApply){
                                onApply(data);
                            }
                            if((data.purchase_issue_type && data.purchase_issue_type.length) || (data.shipping_issue_type && data.shipping_issue_type.length)){
                                setShowRedDot(true);
                            }
                            else{
                                setShowRedDot(false);
                            }
                        }}
                        doClose={()=>setShowForm(false)} 
                    />
                }
            placement="bottomRight"
            trigger={["click"]}
            visible={showForm}
            onVisibleChange={(data) => setShowForm(data)}
        >
            <span className="clickFilter" onClick={(e) => e.preventDefault()} style={{marginLeft: 10}}>
                {showRedDot && 
                    <div className="kanbanLaneFilterRedDot"></div>
                }
                <i className="da icon-filter"></i>
            </span>
        </Dropdown>
    )
};

export default KanbanLaneFilter;