import { Button, message, Modal, Tooltip, Typography } from "antd";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IActiveListing } from "models/interfaces";
import React, { useState } from "react";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import ActiveListingPoCard from "../ActiveListingPoCard";

const DeleteListing: React.FC<{
  eventId: number;
  eventName: string;
  eventDate: string;
  venueName: string;
  venueLocation: string;
  activeListingData?: IActiveListing;
  refreshListingsAfterDelete?: Function;
  setShowSplitView?: Function;
}> = ({ eventId, eventName, eventDate, venueName, venueLocation, activeListingData, refreshListingsAfterDelete, setShowSplitView }) => {
  const { hasPermissions, userDetails } = useRbac();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const deleteListingHandler = async () => {
    if (activeListingData && userDetails) {
      setIsUpdatingStatus(true);
      const deleteActiveListingKey = "deleteActiveListingKey";
      message.loading({
        content: "Cancelling listing and refreshing data...",
        duration: 0,
        key: deleteActiveListingKey,
        className: "toastMsg loadingMsg",
      });
      let statusResult = null;
      try {
        statusResult = await new ZonesService().cancelActiveListing(
          activeListingData.purchase_id,
          activeListingData.purchase_line_id,
          userDetails.uuid
        );
        message.success({
          content: "Listing cancelled successfully.",
          key: deleteActiveListingKey,
          duration: 3,
          className: "toastMsg savedSuccess",
        });
        if (refreshListingsAfterDelete) {
          refreshListingsAfterDelete(statusResult?.data.data.purchase_id);
        }
        setShowDeleteConfirm(false);
        setIsUpdatingStatus(false);
        setShowSplitView && setShowSplitView((prev: boolean)=>prev === true ? false : prev);
      } catch (error: any) {
        message.error({
          content: error?.error?.response?.data?.errors[0]?.message ?? "Failed to cancel listing",
          duration: 5,
          key: deleteActiveListingKey,
          className: "toastMsg savedFailed",
        });
        new LoggerService().log({
          payload: error,
          function_name: "deleteListingHandler",
        });
        setIsUpdatingStatus(false);
        setShowDeleteConfirm(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Delete Listing">
        <Typography.Link
          disabled={
            !hasPermissions([
              ERbacPermissions.ZONES_ACTIVE_LISTING_DELETE_ACTION,
            ])
          }
          onClick={() => setShowDeleteConfirm(true)}
        >
          <i className="da icon-trash-o" />
        </Typography.Link>
      </Tooltip>
      <Modal
        closable={false}
        width={350}
        title={false}
        footer={false}
        visible={showDeleteConfirm}
      >
        <div className="modalHeader modalHeaderCenter">
          Are you sure you want to <span className="text-danger">delete</span>{" "}
          this listing?
        </div>
        <div className="modalActiveListingPoCard">
          <ActiveListingPoCard
            eventId={eventId}
            eventName={eventName}
            eventDate={eventDate}
            venueName={venueName}
            venueLocation={venueLocation}
            activeListingData={activeListingData}
            showCardActionHeader={false}
            disableSplitToggle={true}
          />
        </div>
        <div className="modalCustomFooter">
          <Button
            className="linkBtn linkBtnColor customLinkBtn"
            onClick={() => {
              setShowDeleteConfirm(false);
            }}
            disabled={isUpdatingStatus}
          >
            CANCEL
          </Button>
          <Button
            className="btnOk"
            disabled={isUpdatingStatus}
            onClick={deleteListingHandler}
          >
            OK
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteListing;
