import { Skeleton, Typography } from "antd";
import {
  IActiveListingResponseData,
  IPurchaseID,
  IZoneListingsByEventResponseData,
} from "models/interfaces";
import React from "react";
import CardSkeleton from "../CardSkeleton";
import ActiveListingEventCard from "./ActiveListingEventCard";
import ActiveListingPoCard from "./ActiveListingPoCard";

const ActiveListingNonSplitView: React.FC<{
  isLoadingEventData: boolean;
  zoneListingDetailsByEvent: IZoneListingsByEventResponseData | undefined;
  currentPo: IPurchaseID | null;
  setCurrentPo: Function;
  isLoadingListingData: boolean;
  activeListingData: IActiveListingResponseData | undefined;
  setUpdatedBroadcastStatus: Function;
  refreshListingsAfterDelete: Function;
}> = ({
  isLoadingEventData,
  zoneListingDetailsByEvent,
  currentPo,
  setCurrentPo,
  isLoadingListingData,
  activeListingData,
  setUpdatedBroadcastStatus,
  refreshListingsAfterDelete
}) => {
  return (
    <div className="zonesGridView activeListingWrap">
      <div className="listRow">
        <div className="activeListingLeft">
          <div className="listRow">
            {isLoadingEventData && (
              <CardSkeleton isLoading={isLoadingEventData} />
            )}
            {!isLoadingEventData && zoneListingDetailsByEvent?.eventData && (
              <ActiveListingEventCard
                eventData={zoneListingDetailsByEvent.eventData}
              />
            )}
            {isLoadingEventData && (
              <Skeleton active loading={isLoadingEventData} />
            )}
            <div className="eventCardWrap noTopbar moreListing">
              {!isLoadingEventData &&
                zoneListingDetailsByEvent?.purchase_ids &&
                zoneListingDetailsByEvent?.purchase_ids.length > 1 && (
                  <div
                    onClick={() =>
                      setCurrentPo((prev: IPurchaseID | null) => ({
                        ...prev,
                        purchase_id: null,
                      }))
                    }
                  >
                    <Typography.Link
                      key="total_listings"
                      className={`readMore ${
                        currentPo?.purchase_id === null && "active"
                      }`}
                    >
                      <u>All</u>
                      <span>
                        {" "}
                        {zoneListingDetailsByEvent.total_listings}{" "}
                        {zoneListingDetailsByEvent.total_listings > 1 &&
                          "listings"}
                        {zoneListingDetailsByEvent.total_listings < 1 &&
                          "listing"}
                      </span>
                    </Typography.Link>{" "}
                  </div>
                )}
              {!isLoadingEventData &&
                zoneListingDetailsByEvent?.purchase_ids &&
                zoneListingDetailsByEvent?.purchase_ids.map((datum) => (
                  <div onClick={() => setCurrentPo(datum)}>
                    <Typography.Link
                      key={`${datum.listed_at}`}
                      className={`readMore ${
                        currentPo?.purchase_id === datum.purchase_id
                          ? "active"
                          : ""
                      }`}
                    >
                      <u>PO# {datum.purchase_id}</u>
                      <span>
                        {" "}
                        ({datum.po_line_count}{" "}
                        {datum.po_line_count && datum.po_line_count > 1
                          ? "listings"
                          : "listing"}
                        )
                      </span>
                    </Typography.Link>{" "}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="activeListingRight">
          <div className="listRow">
            {isLoadingListingData && (
              <React.Fragment>
                <CardSkeleton isLoading={true} />
                <CardSkeleton isLoading={true} />
                <CardSkeleton isLoading={true} />
                <CardSkeleton isLoading={true} />
              </React.Fragment>
            )}
            {activeListingData?.activeListings &&
              zoneListingDetailsByEvent?.eventData &&
              !isLoadingListingData &&
              activeListingData?.activeListings?.map((eachListing, index) => (
                <ActiveListingPoCard
                  key={`${index}_${eachListing.listed_at}`}
                  eventId={zoneListingDetailsByEvent.eventData.event_id}
                  eventName={zoneListingDetailsByEvent.eventData.event_name}
                  eventDate={zoneListingDetailsByEvent.eventData.event_date}
                  venueName={zoneListingDetailsByEvent.eventData.venue_name}
                  venueLocation={
                    zoneListingDetailsByEvent.eventData.venue_location
                  }
                  activeListingData={eachListing}
                  setUpdatedBroadcastStatus={setUpdatedBroadcastStatus}
                  refreshListingsAfterDelete={refreshListingsAfterDelete}
                  disableSplitToggle={false}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveListingNonSplitView;
