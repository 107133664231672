import { Typography } from "antd";
import { FixedColumnDirection } from "models/enums/table";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import {
  formatDateGivenFormatString,
  getCustomDate,
} from "utils/formatTimePeriod";
import {
  addToolTip,
  formatNumericValues,
  formatAccountingValues,
  formatPercentageValues,
} from "utils/commonFunction";
import React from "react";
import { AppSetting } from "utils/appSettings";
import { trigger } from "sharedServices";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

const commonWidth = 100;

export const invoiceNeedingAttentionHeaders = [
  {
    title: "",
    dataIndex: "is_resolved",
    width: 109,
    fixed: FixedColumnDirection.left,
    allowedPermissions: [ERbacPermissions.REPORT_INVOICES_RESOLVE_ISSUE],
    canSort: false,
    dataRender: (is_resolved: boolean, otherData: any) => {
      let tagClass: string = "";
      let tagName: string = "";
      if (is_resolved) {
        tagClass = "ant-tag lightGreenFill";
        tagName = "Resolved";
      } else {
        tagClass = "ant-tag danger-outline-fill";
        tagName = "Resolve Issue";
      }
      return (
        <Typography.Link
          className={tagClass}
          onClick={() => {
            trigger("pricingIssue:click", {
              action: "edit",
              data: otherData.data.singleData,
            });
          }}
        >
          {tagName}
        </Typography.Link>
      );
    },
  },
  {
    title: "P&L Date",
    dataIndex: "pl_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (plDate: string) =>
      plDate && getCustomDate(plDate, AppSetting.REPORT_DATE_FORMAT),
    dataHeaderRender: (plDateHeader: string) => (
      <div className="text-left">{plDateHeader}</div>
    ),
  },
  {
    title: "Invoice ID",
    dataIndex: "invoiceId",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Sale Type",
    dataIndex: "salesType",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Sale Category",
    dataIndex: "salesCategory",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceDate: string) =>
      invoiceDate && getCustomDate(invoiceDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Invoice Line Date",
    dataIndex: "invoiceLineDate",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceLineDate: string) =>
      invoiceLineDate &&
      getCustomDate(invoiceLineDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Purchase Date",
    dataIndex: "fillDate",
    canSort: true,
    width: commonWidth,
    dataRender: (fillDate: string) =>
      fillDate && getCustomDate(fillDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
    dataHeaderRender: (qtyHeader: number) => (
      <div className={`text-right ${qtyHeader < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qtyHeader)}
      </div>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currencyCode",
    canSort: true,
    width: commonWidth,
    dataRender: (currencyCode: any) => (
      <div className="text-upper">{currencyCode}</div>
    ),
  },
  {
    title: "Unit Cost",
    dataIndex: "unitCost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (unitCost: number) => (
      <div className={`text-right ${unitCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(unitCost)}
      </div>
    ),
  },
  {
    title: "Unit Sale",
    dataIndex: "unitTicketSales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (unitSale: number) => (
      <div className={`text-right ${unitSale < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(unitSale)}
      </div>
    ),
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticketCost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketCost: number) => (
      <div className={`text-right ${ticketCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketCost)}
      </div>
    ),
    dataHeaderRender: (ticketCostHeader: any) => (
      <div
        className={`text-right ${ticketCostHeader < 0 ? "text-danger" : ""}`}
      >
        {ticketCostHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketCostHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(ticketCostHeader)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticketSales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketSales: number) => (
      <div className={`text-right ${ticketSales < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketSales)}
      </div>
    ),
    dataHeaderRender: (ticketSalesHeader: any) => (
      <div
        className={`text-right ${ticketSalesHeader < 0 ? "text-danger" : ""}`}
      >
        {ticketSalesHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketSalesHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(ticketSalesHeader)}
      </div>
    ),
  },
  {
    title: "P & L (API)",
    dataIndex: "p&l",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (profitLossApi: number) => (
      <div className={`text-right ${profitLossApi < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(profitLossApi)}
      </div>
    ),
    dataHeaderRender: (profitLossApiHeader: any) => (
      <div
        className={`text-right ${profitLossApiHeader < 0 ? "text-danger" : ""}`}
      >
        {profitLossApiHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(profitLossApiHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(profitLossApiHeader)}
      </div>
    ),
  },
  {
    title: "Profit (Loss)",
    dataIndex: "p&l_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (profitLossCalculated: number, all: any) => {
      const allData = all.data.singleData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div
          className={`text-right ${
            profitLossCalculated < 0 ? "text-danger" : ""
          }`}
        >
          <span className={`${redTag}`}>
            {formatAccountingValues(profitLossCalculated)}
          </span>
        </div>
      );
    },
    dataHeaderRender: (profitLossCalculatedHeader: any, all: any) => {
      const allData = all.data._eachData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div
          className={`text-right ${
            profitLossCalculatedHeader < 0 ? "text-danger" : ""
          }`}
        >
          <span className={`${redTag}`}>
            {profitLossCalculatedHeader > 0
              ? FormatCurrencyUnit(
                  formatAccountingValues(profitLossCalculatedHeader),
                  CurrencyCode.Usd
                )
              : formatAccountingValues(profitLossCalculatedHeader)}
          </span>
        </div>
      );
    },
  },
  {
    title: "Fees",
    dataIndex: "fees",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (fees: number) => (
      <div className={`text-right ${fees < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(fees)}
      </div>
    ),
    dataHeaderRender: (feesHeader: any) => (
      <div className={`text-right ${feesHeader < 0 ? "text-danger" : ""}`}>
        {feesHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(feesHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(feesHeader)}
      </div>
    ),
  },
  {
    title: "Gross Profit",
    dataIndex: "margin_after_fees",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (grossProfit: number) => (
      <div className={`text-right ${grossProfit < 0 ? "text-danger" : ""}`}>
        <b>{formatAccountingValues(grossProfit)}</b>
      </div>
    ),
    dataHeaderRender: (grossProfitHeader: any) => (
      <div
        className={`text-right ${grossProfitHeader < 0 ? "text-danger" : ""}`}
      >
        {grossProfitHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(grossProfitHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(grossProfitHeader)}
      </div>
    ),
  },
  {
    title: "Margin %",
    dataIndex: "margin_per_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (margin: number) => (
      <div className={`text-right ${margin < 0 ? "text-danger" : ""}`}>
        {formatPercentageValues(margin * 100)}
      </div>
    ),
    dataHeaderRender: (marginHeader: any) => (
      <div className="text-right">{marginHeader}</div>
    ),
  },
  {
    title: "External Reference",
    dataIndex: "externalRef",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (customer: any) =>
      (customer && customer.length) >= 18 ? (
        addToolTip(customer, "right", "default", 18, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{customer}</div>
      ),
  },
  {
    title: "Customer Classification",
    dataIndex: "customer_classification",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (customerClassification: any) =>
      (customerClassification && customerClassification.length) >= 18 ? (
        addToolTip(customerClassification, "right", "default", 18, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{customerClassification}</div>
      ),
  },
  {
    title: "Purchase ID",
    dataIndex: "purchaseId",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number[]) =>
      purchaseId &&
      purchaseId.length &&
      purchaseId.map((id, index) => {
        return (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + id}
              underline={true}
            >
              {`${id}`}
            </Typography.Link>
            {`${index < purchaseId.length - 1 ? ", " : ""}`}
          </React.Fragment>
        );
      }),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (vendor: any) =>
      (vendor && vendor.length) >= 18 ? (
        addToolTip(vendor, "right", "default", 18, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div className="text-capitalize">{vendor}</div>
      ),
  },
  {
    title: "Event",
    dataIndex: "event",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.eventId}
      >
        {event?.length > 40
          ? addToolTip(event, "right", "pointer", 40, false, {
              textTransform: "capitalize",
            })
          : event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "eventDate",
    canSort: true,
    width: commonWidth,
    dataRender: (eventDate: string) =>
      eventDate &&
      formatDateGivenFormatString(eventDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    canSort: true,
    width: commonWidth,
    dataRender: (daysToEvent: any) => {
      let className = "";
      if (daysToEvent !== null) {
        if (daysToEvent > 30) className = "ant-tag success";
        else if (daysToEvent >= 15) className = "ant-tag warning";
        else if (daysToEvent <= 14) className = "ant-tag danger";
      }

      return (
        <div className={`${className}`} style={{ float: "right" }}>
          {daysToEvent}
        </div>
      );
    },
  },
  {
    title: "Venue",
    dataIndex: "venue",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (venue: any) =>
      (venue && venue.length) >= 18 ? (
        addToolTip(venue, "right", "default", 18, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{venue}</div>
      ),
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (section: any) =>
      (section && section.length) >= 18 ? (
        addToolTip(section, "right", "default", 18, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{section}</div>
      ),
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-upper",
    dataRender: (row: any) => <div>{row}</div>,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (seats: any) =>
      (seats && seats.length) >= 20 ? (
        addToolTip(seats, "right", "default", 20, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{seats}</div>
      ),
  },
  {
    title: "Internal Notes",
    dataIndex: "internalNotes",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillmentStatus",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentStatus: any) => (
      <div className="text-capitalize">{fulfillmentStatus}</div>
    ),
  },
  {
    title: "Fulfillment Date",
    dataIndex: "fulfillmentDate",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentDate: string) =>
      fulfillmentDate &&
      getCustomDate(fulfillmentDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventoryTags",
    canSort: true,
    width: commonWidth,
    dataRender: (inventoryTags: any) => <div>{inventoryTags}</div>,
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoiceTags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Consignment",
    dataIndex: "consignmentStatus",
    canSort: true,
    width: commonWidth,
    dataRender: (consignmentStatus: any) => (
      <div className="text-capitalize">{consignmentStatus}</div>
    ),
  },
  {
    title: "Invoice Payment Method",
    dataIndex: "PaymentType",
    canSort: true,
    width: commonWidth,
    dataRender: (PaymentType: any) => (
      <div className="text-capitalize">{PaymentType}</div>
    ),
  },
];
