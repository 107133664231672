import {
  EventsWithActiveZoneListingsQueryParams,
  IEventsWithActiveZoneListingsResponse,
} from "models/interfaces";
import React, { useCallback } from "react";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import AllZoneListingTable from "./AllZoneListingListViewComponents/AllZoneListingTable";

const AllZoneListingListView: React.FC<{
  isLoadingData: boolean;
  allZoneListings: IEventsWithActiveZoneListingsResponse | undefined;
  queryParams: EventsWithActiveZoneListingsQueryParams;
  setQueryParams: Function;
  setAllZoneListings: Function;
  searchText: string | null;
  urlParams: any;
  purchaseLineId: string | null;
}> = ({
  isLoadingData,
  allZoneListings,
  queryParams,
  setQueryParams,
  setAllZoneListings,
  searchText,
  urlParams,
  purchaseLineId,
}) => {
  
  const onSort = useCallback(
    (sortIndex: string, sortOrder: "asc" | "desc") => {
      setQueryParams((prev: EventsWithActiveZoneListingsQueryParams) => ({
        ...prev,
        sort_column: sortIndex,
        sort_order: sortOrder,
      }));
    },
    [setQueryParams]
  );

  return (
    <React.Fragment>
      {!allZoneListings?.events ? (
        <LoadingSpinner isLoading={isLoadingData}>
          <div className="noResultFoundBlock">No Result Found</div>
        </LoadingSpinner>
      ) : (
        <div className="eventListTbl">
          <AllZoneListingTable
            tableClass="customTbl scroll-x-y fixedHeaderFooter"
            tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
            onSort={onSort}
            sortField={{
              dataIndex: queryParams.sort_column
                ? queryParams.sort_column
                : "days_to_event",
              order: queryParams.sort_order ? queryParams.sort_order : "asc",
            }}
            data={allZoneListings.events ? allZoneListings.events : []}
            otherData={allZoneListings}
            loading={isLoadingData}
            enableRowSelect={false}
            setAllZoneListings={setAllZoneListings}
            queryParams={queryParams}
            searchText={searchText}
            urlParams={urlParams}
            purchaseLineId={purchaseLineId}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default AllZoneListingListView;
