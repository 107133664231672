import { Radio, Space, DatePicker, RadioChangeEvent } from "antd";
import {
  IOnSaleChangeData,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
} from "models/interfaces";
import moment, { Moment } from "moment";
import { useEffect } from "react";
import {
  getCustomDate,
  getDateObject,
  getDisabledDate,
} from "utils/formatTimePeriod";

const ChangeOnSaleOptions: React.FC<{
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  onSaleChangeData: IOnSaleChangeData | null;
  setOnSaleChangeData: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  setIsValid: Function;
}> = ({
  purchaseQueueMaster,
  onSaleChangeData,
  setOnSaleChangeData,
  eachInvoiceData,
  setIsValid
}) => {

  const radioChangeHandler = (e: RadioChangeEvent) => {
    if (e.target.value !== undefined) {
      setOnSaleChangeData((prev: IOnSaleChangeData) => ({
        ...prev,
        on_sale_type: e.target.value,
        on_sale_date:
          e.target.value === 2 ? null : eachInvoiceData.on_sale_date,
      }));
    }
  };

  function disabledDate(current: any) {
    return (
      current > moment.utc(eachInvoiceData.event_date).add(1,'day') ||
      current < moment.utc(getDisabledDate()).add(1, 'day')
    );
  }

  const onSaleDateChangeHandler = (date: Moment | null | undefined) => {
    if (date !== null) {
      setOnSaleChangeData((prev: IOnSaleChangeData) => ({
        ...prev,
        on_sale_type: 1,
        on_sale_date: getCustomDate(date),
      }));
    }
  };

  useEffect(() => {
    setOnSaleChangeData({
      invoice_id: eachInvoiceData.invoice_id,
      event_date: eachInvoiceData.event_date,
      on_sale_type: eachInvoiceData.on_sale_type,
      on_sale_date: eachInvoiceData.on_sale_date,
    });
  }, [eachInvoiceData]);

  useEffect(() => {
    if (
      onSaleChangeData?.on_sale_type === 1 &&
      onSaleChangeData.on_sale_date !== undefined &&
      onSaleChangeData.on_sale_date !== null &&
      eachInvoiceData.on_sale_date !== onSaleChangeData.on_sale_date
    ) {
      setIsValid(true);
    } else if (
      onSaleChangeData?.on_sale_type === 2 &&
      eachInvoiceData.on_sale_type !== onSaleChangeData?.on_sale_type
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [onSaleChangeData]);
  
  return (
    <Radio.Group
      className="moveOnSaleRadioGrp radioStyleDark"
      onChange={radioChangeHandler}
      value={onSaleChangeData?.on_sale_type ? onSaleChangeData?.on_sale_type : eachInvoiceData.on_sale_type}
    >
      <Space direction="vertical">
        <div className="groupCont">
          <Radio
            value={
              purchaseQueueMaster?.onSaleTypeMasterData?.find(
                (each) => each.label === "On Sale Date"
              )?.value
            }
          ></Radio>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            showToday={false}
            // style={{ width: "auto" }}
            disabled={onSaleChangeData?.on_sale_type === 1 ? false : true}
            onChange={onSaleDateChangeHandler}
            value={
              onSaleChangeData?.on_sale_date
                ? getDateObject(onSaleChangeData?.on_sale_date)
                : undefined
            }
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLInputElement
            }
          />
        </div>
        <Radio
          value={
            purchaseQueueMaster?.onSaleTypeMasterData?.find(
              (each) => each.label === "On Sale TBD"
            )?.value
          }
        >
          On Sale TBD
        </Radio>
      </Space>
    </Radio.Group>
  );
};

export default ChangeOnSaleOptions;
