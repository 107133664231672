import { RoutesEnum } from "models/enums";
import { IBrokerTags } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class BrokerCommissionService {
  public getBrokerTagsAndCommissions = async () => {
    return axiosClient.get<IApiBaseResponse>(RoutesEnum.BROKER_TAG_DETAILS);
  };

  public updateBrokerTagsCommission = async (updateCommissionRequest: IBrokerTags[]) => {
    return axiosClient.post<IApiBaseResponse>(RoutesEnum.BROKER_TAGS_COMMISSION_UPDATE, {broker_tags: updateCommissionRequest})
  }
}

export { BrokerCommissionService };
