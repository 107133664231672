import { Col, Layout, Row } from "antd";
import { useAppDispatch } from "app/hooks";
import { usePageTitle } from "hooks";
import { IFilterOptions } from "models/interfaces";
import { actions } from "models/Slices/FilterSlice";
import { useState, useEffect } from "react";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import ScheduleListTable from "./ScheduleListTable";
import ScheduleWrapper from "./ScheduleWrapper";

const SchedulerPage: React.FC = () => {
  usePageTitle("Report Schedules");
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };
  const reportId = -1;

  useEffect(() => {
    dispatch(actions.getSaleCategoryFilterData());
    dispatch(actions.getCustomerClassificationFilterData());
    dispatch(actions.getBrokerFilterData(-999));
    dispatch(actions.getBuyerFilterData());
    dispatch(actions.getVenueFilterData());
    dispatch(actions.getCurrencyFilterData());
    dispatch(actions.getCardFilterData());
    dispatch(actions.getAlertFilterData());
    dispatch(actions.getRecipientFilterData());
    dispatch(actions.getAgingPeriodList());
    dispatch(actions.getFulfillmentStatusList());
  }, [dispatch]);

  return (
    <div className={"fixedFilterArea"}>
      <div id="toast"></div>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={16} sm={16} md={12}>
            <h2>Report Schedules</h2>
          </Col>
          <Col xs={8} sm={8} md={12}>
            <div className="rightFilter">
              <FilterDrawer
                current_report_id={reportId}
                showReportDropdown={true}
                showRecipientDropdown={true}
                reportDropdownType={"single"}
                recipientDropdownType={"single"}
                showActiveItemsOnlyCheckbox={true}
                onApplyFilter={filterChanged}
              />
            </div>
          </Col>
        </Row>



      </div>
      <ScheduleWrapper>
        <Layout className="mainInnerLayout pb-0">
          <ScheduleListTable filterObject={filterObject} />
        </Layout>
      </ScheduleWrapper>
    </div>
  );
};

export default SchedulerPage;
