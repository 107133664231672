import { IGraphData } from "models/interfaces";
import { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import ProfitGraph from "./ProfitGraph";
import { ICustomError } from "models/interfaces/ICustomError";
import  CustomError  from "controls/CustomError/CustomError";
import { LoggerService } from 'sharedServices/api/logger.service';
import { AppSetting } from "utils/appSettings";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const GrossProfitGraph: React.FC<{
  queryParams: any;
}> = ({ queryParams }) => {
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [grossProfitGraphDataFetch,setGrossProfitGraphDataFetch] = useState<ICustomError | null>(null)
  const [grossProfitGraphData, setGrossProfitGraphData] =
    useState<IGraphData>();

  const getGrossProfitGraphData = async () => {
    try {
      if (!queryParams) return false;
      setIsServiceLoading(true);
      const grossProfitGraphResponseData =
        await new ProfitDashboardService().getGrossProfitGraphData({
          ...queryParams,
          ...{ client_zone: AppSetting.TIMEZONE },
        });
      setGrossProfitGraphData(grossProfitGraphResponseData.data.data);
      setIsServiceLoading(false);
      setGrossProfitGraphDataFetch(null)
    } catch (error:any) {
      setIsServiceLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getGrossProfitGraphData'
      })
      setGrossProfitGraphDataFetch({...error,refreshAction:getGrossProfitGraphData});
      console.log(error);
    }
  };

  useEffect(() => {
    getGrossProfitGraphData();
    
  }, [queryParams]);

  return (
    <div>
      <h3>
        Gross Margin
      </h3>
      <LoadingSpinner isLoading={isServiceLoading}>
      {
            grossProfitGraphDataFetch ? (
              <CustomError {...grossProfitGraphDataFetch } />
            ) : (
              <ProfitGraph
              data={grossProfitGraphData ?? null}
              currency={queryParams?.currency_Id ?? ""}
              cId={1}
              setIsLoading={setIsServiceLoading}
            />
            )}
      
      </LoadingSpinner>
    </div>
  );
};

export default GrossProfitGraph;
