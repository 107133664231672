import React, { useEffect, useState } from "react";
import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { usePageTitle } from "hooks";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { IFilterOptions } from "models/interfaces";
import NewShowsData from "./NewShows/NewShowsData";
import DashboardKpis from "../CommonComponents/KPIs/DashboardKpis";
import CommonCountsComponent from "../CommonComponents/CommonCountsComponent/CommonCountsComponent";
import { TBDWidgetCounts } from "../CommonComponents/TBDWidget/TBDWidget";
import { colsCount } from "../CommonComponents/CommonFile/commonFile";

const ListerDashboardPage: React.FC = () => {
  usePageTitle("Lister Dashboard");

  const { hasPermissions } = useRbac();
  const { hasRoles } = useRbac();
  const { auth0UserDetails } = useRbac();
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    dispatch(actions.getSeatIntelligenceData());

    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getListerUserData());
    }
  }, [dispatch]);

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  return (
    <div className="fixedFilterArea">
      <div className="mainHeading">
        <div className="dashboardTopBar">
          <div className="flx alignItemsFlexEnd">
            <h2>LISTER DASHBOARD</h2>
          </div>

          <FilterDrawer
            onApplyFilter={filterChanged}
            defaultAndValues={{
              preFilledValues: {
                lister_user_Id: hasRoles(['lister']) && auth0UserDetails?.sub
              },
            }}
            showCustomDatePicker={true}
            showBrokerUserDropdown={hasPermissions([
              ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
            ])}
            brokerUserDropdownType="single"
            showListerDropdown={hasPermissions([
              ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
              ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
            ])}
            listerDropdownType="single"
          />
        </div>
      </div>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Alerts</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="NEW SHOWS (NO LISTINGS)"
                paramObject={{ show_category_id: 2, exclude_sold_out_events: true, exclude_cancelled_events: true }}
                apiName="getListerNewShowsCount"
                otherFilterObjects={{ if_no_listing: true }}
                serviceType="lister"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="newShowsCount"
              />
              <TBDWidgetCounts
                label="HIGH RISK LISTINGS"
                cols={colsCount.alert}
                textColorClass="text-danger"
              />
              <TBDWidgetCounts
                label="UNREAD MESSAGES"
                cols={colsCount.alert}
                textColorClass="text-danger"
              />
            </Row>
          </div>
          <NewShowsData filterObject={filterObject} />
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Insights</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="VENUES"
                paramObject={{}}
                apiName="getListerVenuesCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_VENUES"
                dataKey="venuesCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="EVENTS"
                paramObject={{}}
                apiName="getListerEventsCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_EVENTS"
                dataKey="eventsCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="ZONE LISTINGS"
                paramObject={{}}
                apiName="getListerDashboardZoneListingsCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="ZONES_LISTING"
                dataKey="zoneListingsCount"
              />
            </Row>
          </div>
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap kpisGraphsWrap">
            <h3>KPIs</h3>
            <Row className="removeRowBtmSpc">
              <DashboardKpis
                filterObject={filterObject}
                graphType="stackedBar"
                serviceType="lister"
                apiName="getKPIsNewListingsGraphData"
                label="Active PO Lines"
                cols={colsCount.kpis}
                tooltipHeaders={["PO Lines", "PO Lines"]}
                cId={1}
                typeOfData="PO Line"
                seriesTitles={["Skybox", "Platform"]}
              />
              <DashboardKpis
                filterObject={filterObject}
                graphType="bar"
                serviceType="lister"
                apiName="getKPIsUnbroadcastsGraphData"
                label="Un-broadcasts"
                cols={colsCount.kpis}
                tooltipHeaders={["Un-broadcasts"]}
                cId={2}
                typeOfData="Un-broadcast"
              />
              <DashboardKpis
                filterObject={filterObject}
                graphType="stackedBar"
                serviceType="lister"
                apiName="getKPIsSeatsOverTimeGraphData"
                label="Sales"
                cols={colsCount.kpis}
                tooltipHeaders={["Skybox", "Platform"]}
                seriesTitles={["Skybox", "Platform"]}
                cId={3}
                typeOfData="Placement"
              />
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default ListerDashboardPage;
