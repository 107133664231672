import axiosClient from "sharedServices/api/axios.services";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { LongInventoryScorecardData } from "models/interfaces";
import { RoutesEnum } from "models/enums";
import { longInventoryScorecardType } from "models/types";

class LongInventoryScorecardService {
    public longInventoryData = async (longInventoryRequest: longInventoryScorecardType) => {
        
        return axiosClient.get<IApiBaseResponse<LongInventoryScorecardData>
        >(RoutesEnum.LONG_INVENTORY_SCORECARD, {
            params: longInventoryRequest
        })
    }
}

export { LongInventoryScorecardService }