import { RoutesEnum } from "models/enums";
import { 
    ICopyDownMetadataEventData, 
    IEventListResponseData, 
    IEventMetadata, 
    IEventMetadataForCreateZoneListingResponse, 
    IEventMetadataQueryParams, 
    IEventMetadataResponse, 
    IMetadataAuditData, 
    IMetadataTrendData, 
    IPendingZoneFillsListData,
    IPendingZoneFillsListQueryParams,
    IEventListQueryParams,
    IPurchaseAssignmentData,
    ISplitMetadata
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class EventService {
    public getEventDetailsData = async (venueDetailsRequest: IEventListQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IEventListResponseData>>(RoutesEnum.EVENT_DETAILS, {
            params: venueDetailsRequest
        })
    }

    public getEventMetadata = async (eventMetadataRequest: IEventMetadataQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IEventMetadataResponse>>(RoutesEnum.EVENT_METADATA, {
            params: eventMetadataRequest
        })
    }

    public getEventMetadataForCreateNewZoneListing = async (eventMetadataRequest: IEventMetadataQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IEventMetadataForCreateZoneListingResponse>>(RoutesEnum.EVENT_METADATA_FOR_NEW_ZONE_LISTING, {
            params: eventMetadataRequest
        })
    }

    public getMetadataAuditHistory = async (metadataAuditHistoryRequest: IEventMetadataQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IMetadataAuditData>>(RoutesEnum.EVENT_METADATA_AUDIT_HISTORY, {
            params: metadataAuditHistoryRequest
        })
    }

    public getMetadataTrendData = async (metadataTrendDataRequest: IEventMetadataQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IMetadataTrendData>>(RoutesEnum.EVENT_METADATA_TREND_DATA, {
            params: metadataTrendDataRequest
        })
    }

    public saveMetadata = async (metadataSaveRequest: IEventMetadata) => {
        return axiosClient.post<IApiBaseResponse>(RoutesEnum.EVENT_METADATA_SAVE, metadataSaveRequest);
    }

    public getEventsForCopyDownMetadata = async (event_id: number) => {
        const url = RoutesEnum.EVENT_LIST_FOR_COPY_DOWN_METADATA.replace(
            "{event_id}",
            event_id.toString()
        );
        return axiosClient.get<IApiBaseResponse<ICopyDownMetadataEventData>>(url);
    }

    public saveEventsForCopyDownMetadata = async (event_id: number, target_event_ids: number[]) => {
        const url = RoutesEnum.EVENT_SAVE_COPY_DOWN_METADATA.replace(
            "{event_id}",
            event_id.toString()
        );
        return axiosClient.post<IApiBaseResponse<{success_event_ids: number[] | null}>>(
            url,
            {
                target_event_ids
            }
        );
    }

    public getPendingZoneFillsListings = async (query: IPendingZoneFillsListQueryParams) => {
        return axiosClient.get<IApiBaseResponse<IPendingZoneFillsListData>>(
            RoutesEnum.ZONE_PENDING_ZONE_FILLS,
            {
                params: query,
            }
        );
    };

    public getPendingZoneFillsPurchaseAssignmentData = async (query: {
        purchase_line_ids: string | null;
        venue_id: number | null
    }) => {
        return axiosClient.get<IApiBaseResponse<IPurchaseAssignmentData>>(
            RoutesEnum.ZONE_PENDING_ZONE_FILLS_PURCHASE_ASSIGNMENT, {
                params: query
            }
        );
    }

    public postPurchaseAssignment = async (body: any) => {
        return axiosClient.post<IApiBaseResponse<any>>(
            RoutesEnum.ZONE_PENDING_ZONE_FILLS_SAVE_PURCHASE_ASSIGNMENT,
            body
        );
    };

    public refreshUpcomingEventsMVW = async () => {
        return axiosClient.get<IApiBaseResponse<{success: boolean}>>(RoutesEnum.EVENT_REFRESH_MVW);
    }

    public saveSplitMetadata = async (metadata: ISplitMetadata) => {
        return axiosClient.post<IApiBaseResponse>(RoutesEnum.SPLIT_METADATA, metadata);
    }
}
