import { Select, Button, message } from "antd";
import {
  IMenuOption,
  IMoveToShippingData,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import React, { useState } from "react";
import { PurchaseAndShippingService } from "services";

const MoveToShipping: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  swimlaneData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster,
}) => {
  const [shippingData, setShippingData] = useState<IMoveToShippingData>({
    invoice_id: eachInvoiceData.invoice_id,
    shipper_id: null
  });
  const [isValueChanged, setIsValueChanged] = useState(false);

  const cancelHandler = () => {
    setIsValueChanged(false);
    setSelectedMenuId(null);
    setShippingData({
      invoice_id: eachInvoiceData.invoice_id,
      shipper_id: null,
    });
  };

  const moveToShipping = async () => {
    const moveToShippingKey = "moveToShippingKey";
    const shipperName = purchaseQueueMaster?.shipperMasterData.find((each)=>each.value === shippingData.shipper_id)?.label;
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Moving Invoice to Shipping...`,
      duration: 0,
      key: moveToShippingKey,
      className: "toastMsg loadingMsg",
    });
    const res = await new PurchaseAndShippingService().moveToShipping(
      shippingData
    );
    if (res.data.data) {
      message.success({
        content: `Invoice# ${eachInvoiceData.invoice_id} assigned to ${shipperName} for shipping.`,
        duration: 5,
        key: moveToShippingKey,
        className: "toastMsg savedSuccess",
      });
      setIsValueChanged(false);
    } else {
      message.error({
        content: "Failed to assign to shipper.",
        duration: 5,
        key: moveToShippingKey,
        className: "toastMsg savedFailed",
      });
    }
    requestToReloadTheLanes([swimlaneData.id]);
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <Select
          options={purchaseQueueMaster?.shipperMasterData}
          getPopupContainer={(trigger) =>
            trigger.parentElement as HTMLInputElement
          }
          placeholder="Select Shipper"
          allowClear={false}
          value={shippingData.shipper_id}
          showSearch
          filterOption={true}
          optionFilterProp="label"
          onChange={(value) => {
            if (shippingData.shipper_id !== value) {
              setIsValueChanged(true);
            } else {
              setIsValueChanged(false);
            }
            setShippingData((prev) => ({ ...prev, shipper_id: value }));
          }}
        />
        <div className="moveOnSaleRow btnGroupFooter">
          <Button className="discardBtn" onClick={cancelHandler}>
            <span>CANCEL</span>
          </Button>
          <Button
            className="btnUpdate"
            disabled={!isValueChanged}
            onClick={moveToShipping}
          >
            Update
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoveToShipping;
