import React, { ChangeEvent } from "react";
import { checkValidUrl } from "utils/commonFunction";
import { IMarketplace } from "models/interfaces";
import { Col, Row } from "antd";

const SecondMarketPlace: React.FC<{
  marketPlaceInfo: IMarketplace[];
  doSetMarketPlaceInfo: Function;
}> = ({ marketPlaceInfo, doSetMarketPlaceInfo }) => {
  const urlChangeHandler = (e: any) => {
    const { id, value } = e.target;

    const newState = marketPlaceInfo?.map((obj) => {
      if (`${obj.id}` === id) {
        return {
          ...obj,
          secondary_marketplace_url: value.trim().length ? value : "",
        };
      }

      return obj;
    });
    doSetMarketPlaceInfo(newState);
  };

  const checkDomainName = (domain: string, url: string) => {
    return url.includes(domain);
  };

  const checkValidity = (e: ChangeEvent<HTMLInputElement>, domain: string) => {
    if (
      e.target.value.trim().length &&
      (!checkValidUrl(e.target.value) ||
        !checkDomainName(domain, e.target.value))
    ) {
      e.target.classList.add("requiredField");
    } else {
      e.target.classList.remove("requiredField");
    }
  };

  const secondaryMarketInfoListDiv = marketPlaceInfo?.map((market) => {
    return (
      <Col lg={12}>
        <div key={market.name} className="inputGroup">
          <div className="flx alignItemsCenter">
            <label>{market.name}</label>
            {checkValidUrl(market.secondary_marketplace_url) && (
              <a
                rel="noopener noreferrer"
                href={market.secondary_marketplace_url}
                className="ml-8"
                style={{ marginBottom: "8px" }}
                target={"_blank"}
                id="previewCta"
              >
                Preview
              </a>
            )}
          </div>
          <div className="flx alignItemsCenter">
            <input
              placeholder=""
              type="text"
              id={market.id.toString()}
              className="ant-input"
              value={market.secondary_marketplace_url}
              onChange={urlChangeHandler}
              onInput={(e: any) => checkValidity(e, market.domain_name)}
            />
          </div>
        </div>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <h3 style={{ marginTop: "15px" }}>Secondary Market Info</h3>
      <div className="editEvent">
        <Row>{secondaryMarketInfoListDiv}</Row>
      </div>
    </React.Fragment>
  );
};

export default SecondMarketPlace;
