import { RoutesEnum } from "models/enums";
import {
  ISchedulerDatum,
  IRecipientData,
  IScheduleListResponseData,
  ISchedulerData,
  IToggleResponseData,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import {
  deleteScheduleQueryType,
  scheduleByIdQueryType,
  scheduleListType,
  toggleScheduleQueryType,
} from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class SchedulerService {
  public createOrUpdateSchedule = async (schedulerData: ISchedulerDatum) => {
    return axiosClient.post<IApiBaseResponse<boolean>>(
      RoutesEnum.CREATE_SCHEDULE,
      {
        ...schedulerData,
      }
    );
  };

  public getAllRecipients = async () => {
    return axiosClient.get<IApiBaseResponse<IRecipientData>>(
      RoutesEnum.GET_SCHEDULE_RECIPIENTS
    );
  };

  public getScheduleList = async (scheduleListRequest: scheduleListType) => {
    return axiosClient.get<IApiBaseResponse<IScheduleListResponseData>>(
      RoutesEnum.GET_SCHEDULE_LIST,
      {
        params: scheduleListRequest,
      }
    );
  };

  public getScheduleById = async (
    scheduleByIdRequest: scheduleByIdQueryType
  ) => {
    return axiosClient.get<IApiBaseResponse<ISchedulerData>>(
      RoutesEnum.GET_SCHEDULE_BY_ID,
      {
        params: scheduleByIdRequest,
      }
    );
  };

  public toggleSchedule = async (
    toggleScheduleRequest: toggleScheduleQueryType
  ) => {
    return axiosClient.patch<IApiBaseResponse<IToggleResponseData>>(
      RoutesEnum.TOGGLE_SCHEDULE_STATUS,
      {
        ...toggleScheduleRequest,
      }
    );
  };

  public deleteSchedule = async (
    deleteScheduleRequest: deleteScheduleQueryType
  ) => {
    return axiosClient.delete<IApiBaseResponse<boolean>>(
      RoutesEnum.DELETE_SCHEDULE,
      {
        headers: {},
        data: {
          ...deleteScheduleRequest,
        },
      }
    );
  };

  public forceRunSchedule = async (
    scheduleId: string
  ) => {
    return axiosClient.post<IApiBaseResponse<boolean>>(
      `${RoutesEnum.FORCE_RUN_SCHEDULE}/${scheduleId}`
    )
  }
}

export { SchedulerService };
