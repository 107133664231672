import { Input, message, Modal, Select, Tooltip, Typography } from "antd";
import { useAppSelector } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import {
  DayOfMonthEnum,
  DayOfWeekNamesEnum,
  FrequencyEnum,
} from "models/enums/schedule";
import {
  IFilterOptions,
  IListElement,
  IReportQueryString,
} from "models/interfaces";
import {
  IFilterListData,
  IScheduleListResponse,
  ISchedulerDatum,
} from "models/interfaces/Scheduler/scheduler.model";
import { calendarPresets } from "models/Slices/GlobalSlice";
import React, { useEffect, useRef, useState } from "react";
import { SchedulerService } from "services/schedulerServices/scheduler.service";
import { AppSetting } from "utils/appSettings";
import { getDatePresetId } from "utils/commonFunction";
import {
  getDefaultTimeInCustomTimeZone,
  getHoursInCustomTimeZone,
} from "utils/formatTimePeriod";
import FilterDisplayArea from "./FilterDisplayArea";
import OccurrenceControl from "./OccurrenceControl";
import Recipients from "./Recipients";

const setTitle = (
  existingScheduleData: any,
  reportData: IReportQueryString | null
) => {
  if (existingScheduleData) {
    return existingScheduleData.title;
  }
  if (reportData) {
    return reportData.current_report;
  }
  return "";
}

const setReportId = (
  existingScheduleData: any,
  reportData: IReportQueryString | null
) => {
  if (existingScheduleData) {
    return existingScheduleData.scheduleDetails.reportId;
  }
  if (reportData) {
    return reportData.current_report_id
  }
  return -1;
}

const setRecipients = (
  existingScheduleData: any,
  userDetails: any
) => {
  if (existingScheduleData?.scheduleDetails.recipients) {
    return existingScheduleData?.scheduleDetails.recipients;
  }
  if (userDetails) {
    return [userDetails.uuid];
  }
  return undefined;
}

const { Option } = Select;
const timeZoneString = AppSetting.TIMEZONE;
const ReportScheduler: React.FC<{
  schedulerTitle: string;
  onUpdateSchedule?: Function;
  reportData: IReportQueryString | null;
  filterData: IFilterOptions | null;
  datePreset: Object;
  listData: IFilterListData | null;
  existingScheduleData?: IScheduleListResponse | null;
  allRecipients: IListElement[];
}> = ({
  schedulerTitle,
  onUpdateSchedule,
  reportData,
  filterData,
  datePreset,
  listData,
  existingScheduleData,
  allRecipients,
}) => {
  const { userDetails } = useRbac();
  const allPresets = useAppSelector(calendarPresets);
  const [showScheduler, setShowScheduler] = useState<boolean>(false);
  const [filterObjectWithDatePresets, setFilterObjectWithDatePresets] =
    useState<any>(null);
  const [scheduleData, setScheduleData] = useState<ISchedulerDatum>({
    status: existingScheduleData
      ? existingScheduleData?.status
      : true,
    id: existingScheduleData ? existingScheduleData.id : 0,
    reportId: setReportId(existingScheduleData, reportData),
    title: setTitle(existingScheduleData, reportData),
    frequencyId: existingScheduleData
      ? existingScheduleData.scheduleDetails.frequencyId
      : 1,
    timeOfDay: existingScheduleData
      ? existingScheduleData.scheduleDetails.timeOfDay
      : getHoursInCustomTimeZone(
          getDefaultTimeInCustomTimeZone(timeZoneString),
          timeZoneString
        ),
    occurrenceId: 1,
    filter: { ...filterData, ...filterObjectWithDatePresets },
    recipients: existingScheduleData?.scheduleDetails.recipients
      ? existingScheduleData?.scheduleDetails.recipients
      : [],
  });
  const [resetToDefaults, setResetToDefaults] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const [isRequiredEmpty, setIsRequiredEmpty] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState(
    setRecipients(existingScheduleData, userDetails)
  );
  const [reportTitle, setReportTitle] = useState(
    setTitle(existingScheduleData, reportData)
  );
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>(existingScheduleData?.scheduleDetails.dayOfWeek ? existingScheduleData.scheduleDetails.dayOfWeek : [1]);
  const [daysOfMonth, setDaysOfMonth] = useState<number[]>(existingScheduleData?.scheduleDetails.dayOfMonth ? existingScheduleData.scheduleDetails.dayOfMonth : [1]);
  const reportTitleRef = useRef<HTMLInputElement | null>(null);
  const [dropdownData, setDropdownData] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<number | number[]>(1);
  const children: any = [];

  useEffect(() => {
    existingScheduleData &&
      setScheduleData((prev) => ({
        ...prev,
        frequencyId: existingScheduleData?.frequencyid,
      }));
  }, [existingScheduleData]);

  useEffect(() => {
    children.splice(0, children.length);
    switch (scheduleData.frequencyId) {
      case FrequencyEnum.Monthly:
        for (let i = 1; i < 32; i++) {
          children.push({
            value: i,
            label: DayOfMonthEnum[i],
          });
        }
        !existingScheduleData && setSelectedTags(DayOfMonthEnum["1st"]);
        break;
      case FrequencyEnum.Weekly:
        for (let i = 1; i < 8; i++) {
          children.push({
            value: i,
            label: DayOfWeekNamesEnum[i],
          });
        }
        !existingScheduleData && setSelectedTags(DayOfWeekNamesEnum["Sunday"]);
        break;
      default:
        children.splice(0, children.length);
        setDaysOfMonth([]);
        setDaysOfWeek([]);
        break;
    }
  }, [scheduleData.frequencyId]);

  useEffect(() => {
    setDropdownData(children);
    setShowDateDropdown(false);
  }, [scheduleData.frequencyId]);

  const initScheduleData = () => {
    setScheduleData({
      status: existingScheduleData
        ? existingScheduleData?.status
        : true,
      id: existingScheduleData ? existingScheduleData.id : 0,
      reportId: reportData ? reportData.current_report_id : -1,
      title: setTitle(existingScheduleData, reportData),
      frequencyId: existingScheduleData
        ? existingScheduleData.scheduleDetails.frequencyId
        : 1,
      timeOfDay: existingScheduleData
        ? existingScheduleData.scheduleDetails.timeOfDay
        : getHoursInCustomTimeZone(
            getDefaultTimeInCustomTimeZone(timeZoneString),
            timeZoneString
          ),
      occurrenceId: 1,
      filter: { ...filterData, ...filterObjectWithDatePresets },
      recipients: existingScheduleData?.scheduleDetails.recipients
        ? existingScheduleData?.scheduleDetails.recipients
        : [],
    });
  };

  const schedulerCtaClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    // allRecipients
    e.preventDefault();
    const filterDateOptionCount =
      filterData &&
      Object.keys(filterData).filter((eachData) => eachData.includes("_from"))
        .length;
    const presetCount = Object.keys(datePreset).length;
    if (filterDateOptionCount != presetCount) {
      setShowScheduler(false);
      message.error({content: "Please select a date preset from filter.", duration: 5, className: 'toastMsg savedFailed'});
    } else {
      setShowScheduler(true);
    }
  };

  const editScheduleCtaClickHandler = () => {
    setShowScheduler(true);
  };

  const saveHandler = async () => {
    const createSchedulerKey = "createSchedulerKey";
    try {
      message.loading({content: "Saving schedule...", duration: 5, key: createSchedulerKey, className: 'toastMsg loadingMsg'});
      await new SchedulerService().createOrUpdateSchedule({
        ...scheduleData,
        title: reportTitle,
      });
      if (onUpdateSchedule) {
        onUpdateSchedule(true);
      }
      setShowScheduler(false);
      initScheduleData();
      message.success({content: "Schedule has been successfully saved.", duration: 5, key: createSchedulerKey, className: 'toastMsg savedSuccess'});
      setResetToDefaults(true);
    } catch (error) {
      console.log(error);
      if (onUpdateSchedule) {
        onUpdateSchedule(false);
      }
      setShowScheduler(false);
      initScheduleData();
      message.error({content: "Failed to save schedule.", duration: 5, key: createSchedulerKey, className: 'toastMsg savedFailed'});
      setResetToDefaults(true);
    }
  };

  useEffect(() => {
    if (existingScheduleData != null) {
      setScheduleData({
        ...existingScheduleData.scheduleDetails,
        status: existingScheduleData.status,
      });
      setReportTitle(existingScheduleData.title);
    }
  }, [existingScheduleData]);

  useEffect(() => {
    scheduleData.frequencyId === 1 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: null,
        dayOfWeek: null,
      });
    scheduleData.frequencyId === 2 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: null,
        dayOfWeek: existingScheduleData?.scheduleDetails.dayOfWeek ? existingScheduleData.scheduleDetails.dayOfWeek : [1],
      });
    scheduleData.frequencyId === 3 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: existingScheduleData?.scheduleDetails.dayOfMonth ? existingScheduleData.scheduleDetails.dayOfMonth : [1],
        dayOfWeek: null,
      });
  }, [scheduleData.frequencyId]);

  useEffect(() => {
    allPresets && 
    Object.keys(datePreset).forEach((key) => {
      switch (key) {
        case "PL Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            pl_date_from: getDatePresetId(allPresets, datePreset[key]),
            pl_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
        case "PO Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            po_date_from: getDatePresetId(allPresets, datePreset[key]),
            po_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
        case "Invoice Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            invoice_date_from: getDatePresetId(allPresets, datePreset[key]),
            invoice_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
        case "Fill Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            fill_date_from: getDatePresetId(allPresets, datePreset[key]),
            fill_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
        case "Event Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            event_date_from: getDatePresetId(allPresets, datePreset[key]),
            event_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
        case "Other Cost Date Range":
          setFilterObjectWithDatePresets((prev: any) => ({
            ...prev,
            other_cost_date_from: getDatePresetId(allPresets, datePreset[key]),
            other_cost_date_to: getDatePresetId(allPresets, datePreset[key]),
          }));
          break;
      }
    });
  }, [datePreset]);

  useEffect(() => {
    setScheduleData((prev) => ({
      ...prev,
      filter: { ...filterData, ...filterObjectWithDatePresets },
    }));
  }, [filterData, filterObjectWithDatePresets]);

  useEffect(() => {
    if (reportTitle.trim() !== "" && selectedRecipients?.length !== 0) {
      setIsRequiredEmpty(false);
    } else {
      setIsRequiredEmpty(true);
    }
  }, [reportTitle, selectedRecipients]);

  useEffect(() => {
    setReportTitle(
      setTitle(existingScheduleData, reportData)
    );
    setScheduleData((prev) => ({
      ...prev,
      frequencyId: existingScheduleData
        ? existingScheduleData.scheduleDetails.frequencyId
        : 1,
    }));
    setDaysOfMonth(
      existingScheduleData?.scheduleDetails.dayOfMonth
        ? existingScheduleData.scheduleDetails.dayOfMonth
        : [1]
    );
    setDaysOfWeek(
      existingScheduleData?.scheduleDetails.dayOfWeek
        ? existingScheduleData.scheduleDetails.dayOfWeek
        : [1]
    );
  }, [resetToDefaults === true]);

  useEffect(() => {
    if (isRequiredEmpty && reportTitle.trim() === "") {
      reportTitleRef.current?.classList.add("requiredField");
    } else {
      reportTitleRef.current?.classList.remove("requiredField");
    }
  }, [isRequiredEmpty, reportTitle]);

  return (
    <React.Fragment>
      {existingScheduleData === null && (
        <Typography.Link onClick={(e) => schedulerCtaClickHandler(e)}>
          <Tooltip title="Create a Schedule" placement="topRight"><i className="da icon-calendar-o schedulerIcon"/></Tooltip>
        </Typography.Link>
      )}
      {existingScheduleData !== null && (
        <Tooltip title="Edit this schedule">
          <div onClick={() => editScheduleCtaClickHandler()}>
            <i className={`da icon-pencil-o`} />
          </div>
        </Tooltip>
      )}

      <Modal
        title={schedulerTitle}
        visible={showScheduler}
        footer={false}
        closable={false}
        keyboard={true}
        className="customModal"
        width={820}
        centered
        destroyOnClose={true}
      >
        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="inputGroup">
              <label>Report Name</label>
              <Input
                type="text"
                defaultValue={reportData ? reportData.current_report : ""}
                disabled={true}
              />
            </div>
          </div>
          <div className="ant-col pr20 ant-col-xs-24 ant-col-xl-19">
            <div className="inputGroup ">
              <label>
                Report Title <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                value={reportTitle}
                onChange={(e) => {
                  setReportTitle(e.target.value);
                }}
                placeholder={"This is a required field."}
                ref={reportTitleRef}
              />
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-xl-5">
            <div className="inputGroup">
              <label>
                Frequency <span className="text-danger">*</span>
              </label>
              <Select
                onChange={(value) => {
                  setScheduleData((prev) => ({
                    ...prev,
                    frequencyId: parseInt(value),
                  }));
                }}
                value={FrequencyEnum[scheduleData.frequencyId]}
              >
                <Option value={FrequencyEnum.Daily}>{FrequencyEnum[1]}</Option>
                <Option value={FrequencyEnum.Weekly}>{FrequencyEnum[2]}</Option>
                <Option value={FrequencyEnum.Monthly}>
                  {FrequencyEnum[3]}
                </Option>
              </Select>
            </div>
          </div>
          <OccurrenceControl
            scheduleData={scheduleData}
            setScheduleData={setScheduleData}
            setShowDateDropdown={setShowDateDropdown}
            showDateDropdown={showDateDropdown}
            showTimeDropdown={showTimeDropdown}
            setShowTimeDropdown={setShowTimeDropdown}
            reset={resetToDefaults}
            setResetToDefaults={setResetToDefaults}
            existingScheduleData={
              existingScheduleData ? existingScheduleData : null
            }
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            daysOfMonth={daysOfMonth}
            setDaysOfMonth={setDaysOfMonth}
            dropdownData={dropdownData}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          <FilterDisplayArea
            filterData={filterData}
            datePreset={datePreset}
            listData={listData}
          />
          <Recipients
            setScheduleData={setScheduleData}
            resetToDefaults={resetToDefaults}
            setResetToDefaults={setResetToDefaults}
            selectedRecipients={selectedRecipients}
            setSelectedRecipients={setSelectedRecipients}
            existingScheduleData={
              existingScheduleData ? existingScheduleData : null
            }
            allRecipients={allRecipients}
          />
        </div>
        <div className="modalFooter">
          <button
            type="button"
            className="linkBtn"
            onClick={() => {
              setShowScheduler(false);
              setShowDateDropdown(false);
              setShowTimeDropdown(false);
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="ant-btn btnSave"
            onClick={saveHandler}
            disabled={isRequiredEmpty}
          >
            SAVE
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ReportScheduler;
