import { Button, Form, message, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useIdleTimerContext } from "react-idle-timer";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import {
  IEventMetadata,
  IFinalizeListingFormData,
  IListElement,
  IListingFormData,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { EventService, ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import {
  addToolTip,
  titleCase,
  filterSortByRanking,
  getEncodedData,
  formatThousandSeparator,
} from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { getCustomDate, getCustomTimeFromDate } from "utils/formatTimePeriod";

const FinalizeListingModal: React.FC<{
  listingTypeId: number;
  zones: IListingFormData[];
  setZones?: Function;
  isLoadingModalData: boolean;
  listingFormData?: IListingFormData;
  setListingFormData?: Function;
  vendors: IListElement[];
  defaultVendor: {};
  defaultInternalNotes: string;
  brokerTags: IListElement[];
  listerTags: IListElement[];
  showFinalizeListingModal: boolean;
  setShowFinalizeListingModal: Function;
  setIsCreatingNewListing?: Function;
  requestedEventId: number;
  eventMetadata: IEventMetadata | null;
  eventMetadataFormData?: IEventMetadata | null;
  statusAfterCreateNewListing?: Function;
}> = ({
  listingTypeId,
  zones,
  setZones,
  isLoadingModalData,
  listingFormData,
  setListingFormData,
  vendors,
  defaultVendor,
  defaultInternalNotes,
  brokerTags,
  listerTags,
  showFinalizeListingModal,
  setShowFinalizeListingModal,
  setIsCreatingNewListing,
  requestedEventId,
  eventMetadata,
  eventMetadataFormData,
  statusAfterCreateNewListing,
}) => {
  const idleTimer = useIdleTimerContext();
  const [form] = Form.useForm();
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();
  const location = useLocation();
  const handleOnSearch = (value: React.SetStateAction<string>) => {
    setInputValue(value);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...listingFormData,

      broker_tag_id: brokerTags.length === 1 ? brokerTags[0].value : null,
      lister_tag_id: listerTags.length === 1 ? listerTags[0].value : null,
      vendor_id: defaultVendor ? defaultVendor : null,
      internal_notes: defaultInternalNotes ? defaultInternalNotes : '',
    });
  }, [listingFormData]);

  const cancelHandler = () => {
    if (isValueChanged) {
      const header = (
        <span className="text-danger">{"All unsaved data will be lost."}</span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => {
          setIsValueChanged(false);
          setShowFinalizeListingModal(false);
        },
        () => console.log("cancelled"),
        "400px"
      );
    } else {
      setShowFinalizeListingModal(false);
      setIsValueChanged(false);
    }
  };

  const createListingHandler = async (currentZones: IListingFormData[]) => {
    let availableAndReadyToSubmitZones = currentZones.filter((eachZone) => {
      return eachZone.listing_quantity !== undefined && eachZone.is_selected;
    });
    if (availableAndReadyToSubmitZones.length) {
      let isMetadataCreated = false;
      if (setIsCreatingNewListing) {
        setIsCreatingNewListing(true);
      }

      if (eventMetadataFormData) {
        //Creating Metadata
        const creatingMetaDataLoadingKey = "createMetadata";
        message.loading({
          content: "Creating metadata...",
          duration: 0,
          key: creatingMetaDataLoadingKey,
          className: "toastMsg loadingMsg",
        });
        try {
          await new EventService().saveMetadata(eventMetadataFormData);
          isMetadataCreated = true;
          message.success({
            content: "Metadata has been synced successfully!",
            key: creatingMetaDataLoadingKey,
            duration: 5,
            className: "toastMsg savedSuccess",
          });
        } catch (error) {
          message.error({
            content: "Metadata has failed to create.",
            duration: 5,
            key: creatingMetaDataLoadingKey,
            className: "toastMsg savedFailed",
          });
          await new LoggerService().log({
            payload: error,
            message:
              "Failed to create metadata from create new listing without metadata page",
            function_name: "createListingHandler",
          });
        }

        // Fetching newly created metadata
        if (isMetadataCreated) {
          try {
            const metadataResponse =
              await new EventService().getEventMetadataForCreateNewZoneListing({
                event_id: requestedEventId,
              });
            if (metadataResponse.data.data.metadata.zone) {
              availableAndReadyToSubmitZones = [
                ...availableAndReadyToSubmitZones,
              ].map((eachData) => {
                const findZoneFromMetadata =
                  metadataResponse.data.data.metadata.zone?.find((eachZone) => {
                    return (
                      eachData.zone_section?.toLocaleUpperCase() ===
                        eachZone.zone_section?.toLocaleUpperCase() &&
                      eachData.rows?.toLocaleUpperCase() ===
                        eachZone.rows?.toLocaleUpperCase()
                    );
                  });
                let zoneId: number | undefined = 0;
                if (findZoneFromMetadata) {
                  zoneId = findZoneFromMetadata.id;
                }
                return {
                  ...eachData,
                  id: zoneId ? zoneId : eachData.id,
                };
              });
            }
          } catch (error) {
            await new LoggerService().log({
              payload: error,
              message:
                "Failed to fetch metadata from create new listing without metadata page",
              function_name: "createListingHandler",
            });
            console.log(error);
          }
        }
      }

      // Create new listing

      const createZoneListingLoadingKey = "createNewListing";
      message.loading({
        content: "Creating new zone listing...",
        duration: 0,
        key: createZoneListingLoadingKey,
        className: "toastMsg loadingMsg",
      });

      let created_purchase_id: number | null = null;
      let created_purchase_data: any = null;
      try {
        const currentTotalTime = idleTimer.getTotalElapsedTime();
        const currentTotalIdleTime = idleTimer.getTotalIdleTime();
        const totalTimeInSeconds = currentTotalTime / 1000;
        const idleTimeInSeconds = currentTotalIdleTime / 1000;

        const res = await new ZonesService().createNewZoneListing(
          requestedEventId.toString(),
          availableAndReadyToSubmitZones,
          totalTimeInSeconds,
          idleTimeInSeconds,
          listingTypeId
        );

        created_purchase_id = res.data.data.purchase_id;
        created_purchase_data = res.data.data.purchase_data;

        message.success({
          content: (
            <span className="withClose">
              Zone listing has been created successfully with PO#{" "}
              <a
                href={AppSetting.PURCHASE_ID_URL + created_purchase_id}
                target="_blank"
                rel="noreferrer"
              >
                {created_purchase_id}
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                className="toastMsgCloseArea"
                title="Close"
                onClick={() => {
                  message.destroy(createZoneListingLoadingKey);
                }}
              >
                <i className="da icon-cancel-white"></i>
              </span>
            </span>
          ),
          key: createZoneListingLoadingKey,
          duration: 0,
          className: "toastMsg savedSuccess",
        });
      } catch (error: any) {
        message.error({
          content: "Zone listing has failed to create.",
          duration: 5,
          key: createZoneListingLoadingKey,
          className: "toastMsg savedFailed",
        });
        if (
          error.error &&
          error.error.response &&
          error.error.response.status &&
          error.error.response.status === 400
        ) {
          if (Array.isArray(error.error.response.data.errors)) {
            error.error.response.data.errors.forEach((eachError: any) => {
              message.error({
                content: eachError.message,
                duration: 10,
                className: "toastMsg savedFailed",
              });
            });
          } else {
            message.error({
              content: error.error.response.data.errors,
              duration: 10,
              className: "toastMsg savedFailed",
            });
          }
        }
        console.log(error);
      }

      if (created_purchase_id) {
        const updatingKey = "syncingPurchaseToPlatform";
        message.loading({
          content:
            "Syncing zone listings from SkyBox. This may take a while. You may continue to work in other areas...",
          duration: 0,
          key: updatingKey,
          className: "toastMsg loadingMsg",
        });

        try {
          const forceUpdateResult =
            await new ZonesService().forceUpdatePurchase(
              created_purchase_id,
              created_purchase_data
            );

          let forceUpdateMessage = "";
          let messageDuration = 5;
          if (forceUpdateResult.data.data.is_mvw_refreshed) {
            forceUpdateMessage = "Zone listing has been synced successfully!";
          } else {
            forceUpdateMessage =
              "The Listing has been created successfully but it may take a while to appear on the platform. Data synchronization with SkyBox is in progress.";
            messageDuration = 15;
          }

          message.success({
            content: forceUpdateMessage,
            key: updatingKey,
            duration: messageDuration,
            className: "toastMsg savedSuccess",
          });

          if (statusAfterCreateNewListing) {
            statusAfterCreateNewListing({
              isMetadataSuccessfullyCreated: isMetadataCreated,
              isNewListingSuccessfullyCreated: true,
            });
          }
        } catch (error) {
          message.error({
            content:
              "Syncing has failed but zone listing data will be available within one hour.",
            duration: 10,
            key: updatingKey,
            className: "toastMsg savedFailed",
          });
          new LoggerService().log({
            payload: error,
            message:
              "Force synced has failed for purchase ID: " + created_purchase_id,
            function_name: "createListingHandler",
          });

          new ZonesService().updateConfigurationRefreshingMvwOff();
        }

        if (
          location.pathname ===
            LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING ||
          location.pathname ===
            LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_WITHOUT_METADATA
        ) {
          setTimeout(() => {
            history.push(
              "/zones/zone-listings/active-listings?event=" +
                getEncodedData({
                  event_id: requestedEventId,
                })
            );
          }, 1000);
        }
      } else {
        if (statusAfterCreateNewListing) {
          statusAfterCreateNewListing({
            isMetadataSuccessfullyCreated: isMetadataCreated,
            isNewListingSuccessfullyCreated: false,
          });
        }
      }
      if (setIsCreatingNewListing) {
        setIsCreatingNewListing(false);
      }
    }
  };

  const onFinish = (values: IFinalizeListingFormData) => {

    if (values) {
      if (setListingFormData) {
        setListingFormData((prev: IListingFormData) => {
          return {
            ...prev,
            ...values
          };
        });
      }

      let currentZones = [...zones];
      currentZones = currentZones.map((eachZone) =>
        eachZone.listing_quantity !== undefined && eachZone.is_selected
          ? {
              ...eachZone,
              ...values,
              broker_tag: brokerTags.find(
                (tag) => tag.value === values?.broker_tag_id
              )?.label,
              lister_tag: listerTags.find(
                (tag) => tag.value === values?.lister_tag_id
              )?.label,
            }
          : eachZone
      );
      if (setZones) {
        setZones(currentZones);
      }
      createListingHandler(currentZones);
    }
    setShowFinalizeListingModal(false);
  };

  return (
    <React.Fragment>
      {showFinalizeListingModal && (
        <Modal
          closable={false}
          width={730}
          title={false}
          footer={false}
          visible={showFinalizeListingModal}
          centered
          className="editModal"
        >
          <div className="modalHeader">
            <div className="modalHeadingMain">Finalize Listing</div>
            <div className="modalHeaderChild">
              <div>
                <div>
                  <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={AppSetting.EVENT_URL + eventMetadata?.event_id}
                  >
                    {(eventMetadata?.event_name ?? "").length > 40
                      ? addToolTip(
                          `${titleCase(eventMetadata?.event_name)}`,
                          "right",
                          "pointer",
                          37
                        )
                      : `${titleCase(eventMetadata?.event_name)}`}
                  </a>
                </div>
                <div className="modalHeaderInner">
                  {`${getCustomDate(
                    eventMetadata?.event_date,
                    "ddd, MMM D, YYYY"
                  )} at ${getCustomTimeFromDate(eventMetadata?.event_date)}`}
                </div>
              </div>
              <div className="modalHeaderChildRight">
                <div>
                  {(eventMetadata?.venue_name ?? "").length > 40
                    ? addToolTip(
                        titleCase(eventMetadata?.venue_name),
                        "right",
                        "default",
                        37
                      )
                    : titleCase(eventMetadata?.venue_name)}
                </div>
                <div className="modalHeaderInner">
                  {(eventMetadata?.venue_location ?? "").length > 40
                    ? addToolTip(
                        eventMetadata?.venue_location,
                        "right",
                        "default",
                        37
                      )
                    : eventMetadata?.venue_location}
                </div>
              </div>
            </div>
          </div>

          <LoadingSpinner isLoading={isLoadingModalData}>
            <Form
              className="modalCustomBody"
              name="listingForm"
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setIsValueChanged(true)}
              autoComplete="on"
            >
              <div className="editUserInfoForm">
                <div className="ant-row">
                  <div className="ant-col ant-col-xl-8">
                    <label>
                      Vendors <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"vendor_id"}
                        wrapperCol={{ xl: { span: 24 } }}
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          options={vendors}
                          value={listingFormData?.vendor_id}
                          showSearch
                          onSearch={handleOnSearch}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                              ? true
                              : false
                          }
                          filterSort={(optionA: any, optionB: any) =>
                            filterSortByRanking(optionA, optionB, inputValue)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="ant-col ant-col-xl-8">
                    <label>
                      Broker Tag <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"broker_tag_id"}
                        rules={[{ required: true, message: "" }]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          options={brokerTags}
                          value={listingFormData?.broker_tag_id}
                          showSearch
                          onSearch={handleOnSearch}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                              ? true
                              : false
                          }
                          filterSort={(optionA: any, optionB: any) =>
                            filterSortByRanking(optionA, optionB, inputValue)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="ant-col ant-col-xl-8">
                    <label>Lister Tag</label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"lister_tag_id"}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          options={listerTags}
                          value={listingFormData?.lister_tag_id}
                          showSearch
                          onSearch={handleOnSearch}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                              ? true
                              : false
                          }
                          filterSort={(optionA: any, optionB: any) =>
                            filterSortByRanking(optionA, optionB, inputValue)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="ant-row">
                  <div className="ant-col ant-col-xl-24">
                    <label>Public Notes </label>
                    <div className="inputGroup ">
                      <Form.Item
                        name={"public_notes"}
                        noStyle={true}
                        rules={[{ message: "", type: "string" }]}
                      >
                        <TextArea
                          autoSize={{ minRows: 5, maxRows: 5 }}
                          maxLength={1000}
                          showCount={{
                            formatter: ({ count, maxLength }) =>
                              maxLength
                                ? `${formatThousandSeparator(
                                    (maxLength ?? 0) - count
                                  )} Remaining`
                                : "",
                          }}
                          allowClear
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="ant-row">
                  <div className="ant-col ant-col-xl-24">
                    <label>Internal Notes </label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"internal_notes"}
                        noStyle={true}
                        rules={[{ message: "", type: "string" }]}
                      >
                        <TextArea
                          autoSize={{ minRows: 5, maxRows: 5 }}
                          maxLength={1000}
                          value={listingFormData?.internal_notes}
                          showCount={{
                            formatter: ({ count, maxLength }) =>
                              maxLength
                                ? `${formatThousandSeparator(
                                    (maxLength ?? 0) - count
                                  )} Remaining`
                                : "",
                          }}
                          allowClear
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Form.Item>
                  <Button className="linkBtn" onClick={cancelHandler}>
                    CANCEL
                  </Button>
                  <Button className="ant-btn btnOk" htmlType="submit">
                    SUBMIT
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </LoadingSpinner>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default FinalizeListingModal;
