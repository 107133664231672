import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from "react";
import { Modal, Radio, Select, Input, Button, message, Checkbox } from "antd";
import { on, off } from "sharedServices";
import { brokerUsers, seatIntelligenceData } from "models/Slices/FilterSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { VenueService } from "services";
import {
  setVenuesToBeAssigned,
  venueManagementActions,
} from "models/Slices/VenuesManagementSlice";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { checkValidUrl, titleCase } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import SelectListers from "./SelectListers";
import SelectCheckers from "./SelectCheckers";
import SelectSeatMapCreators from "./SelectSeatMapCreators";

const { Option } = Select;
const { TextArea } = Input;

let currentCustomEventData: any | null = null;

const EditVenue: React.FC = () => {
  const actionRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const { hasPermissions } = useRbac();

  const brokerList = useAppSelector(brokerUsers);
  const seatIntelligenceList = useAppSelector(seatIntelligenceData);

  const [showModal, setShowModal] = useState(false);
  const [venueData, setVenueData] = useState<any | null>(null);
  const [brokerId, setBrokerId] = useState<string | null>(null);
  const [listerIds, setListerIds] = useState<string[] | null>(null);
  const [checkerIds, setCheckerIds] = useState<string[] | null>(null);
  const [seatMapCreatorIds, setSeatMapCreatorIds] = useState<string[] | null>(
    null
  );
  const [venueUrl, setVenueUrl] = useState<string | null>(null);
  const [seatIntelligenceId, setSeatIntelligenceId] = useState(2);
  const [dataSource, setDataSource] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | null>(null);
  const [unavailableToScrapers, setUnavailableToScrapers] =
    useState<boolean>(false);
  const [feesAvailableScraping, setFeesAvailableScraping] =
    useState<boolean>(false);
  const [venueFees, setVenueFees] = useState<string | null>("");
  const [venueFeesInvalid, setVenueFeesInvalid] = useState<boolean>(false);
  const [assignedListers, setAssignedListers] = useState<
    { value: string; label: string; checked?: boolean }[] | null
  >(null);
  const [assignedCheckers, setAssignedCheckers] = useState<
    { value: string; label: string; checked?: boolean }[] | null
  >(null);
  const [assignedSeatMapCreators, setAssignedSeatMapCreators] = useState<
    { value: string; label: string; checked?: boolean }[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [allowSave, setAllowSave] = useState<boolean | null>(null);

  const venueUrlRef = useRef<HTMLInputElement | null>(null);

  const actionHandler = () => {
    if (currentCustomEventData.action === "edit") {
      setVenueData(currentCustomEventData.data.venueData);
      setShowModal(true);
    }
  };

  const handleSeatIntelligenceStatus = (e: any) => {
    e.preventDefault();
    setSeatIntelligenceId(e.target.value);
  };

  const checkValidity = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length && !checkValidUrl(e.target.value)) {
      e.target.classList.add("requiredField");
    } else {
      e.target.classList.remove("requiredField");
    }
  };

  const handleUrl = (e: any) => {
    e.preventDefault();
    setVenueUrl(e.target.value);
  };

  useEffect(() => {
    const eventHandler = (data: CustomEvent) => {
      currentCustomEventData = data.detail;
      actionRef?.current?.click();
    };

    on("venueAction:click", eventHandler);

    return () => {
      off("venueAction:click", eventHandler);
    };
  }, []);

  const resetFields = () => {
    setShowModal(false);
    setBrokerId(null);
    setListerIds(null);
    setCheckerIds(null);
    setSeatMapCreatorIds(null);
    setSeatIntelligenceId(2);
    setVenueUrl(null);
    setDataSource(null);
    setVenueFees(null);
    setNotes(null);
    setVenueFeesInvalid(false);
    venueUrlRef.current?.classList.remove("requiredField");
  };

  const saveVenue = async () => {
    setLoading(true);

    const listerStringIds =
      listerIds && listerIds.length > 0 ? listerIds.join(",") : null;
    const checkerStringIds =
      checkerIds && checkerIds.length > 0 ? checkerIds.join(",") : null;
    const seatMapCreatorStringIds =
      seatMapCreatorIds && seatMapCreatorIds.length > 0
        ? seatMapCreatorIds.join(",")
        : null;

    const body = {
      venueId: venueData.venueId,
      brokerId,
      listerIds: listerStringIds,
      checkerIds: checkerStringIds,
      seatMapCreatorIds: seatMapCreatorStringIds,
      venueUrl,
      seatIntelligenceId,
      dataSource,
      notes,
      unavailableToScrapers,
      venue_fees: venueFees ? venueFees : null,
    };
    try {
      const response = await new VenueService().editVenueDetails(body);

      if (response.data.data) {
        resetFields();
        message.success({
          content: "Venue successfully saved.",
          className: "toastMsg savedSuccess",
        });
        dispatch(setVenuesToBeAssigned({ id: -999, status: false }));
        dispatch(
          venueManagementActions.getVenueListData({
            functionName: "getVenueDetails",
          })
        );
      }
      setLoading(false);
    } catch (error: any) {
      message.error({
        content: "Something went wrong",
        className: "toastMsg savedFailed",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (seatIntelligenceId === 2) {
      setDataSource(null);
      setNotes(null);
    }
  }, [seatIntelligenceId]);

  useEffect(() => {
    if (venueData) {
      if (venueData.broker_id) {
        setBrokerId(venueData.broker_id[0]);
      }

      if (seatIntelligenceList.length > 0) {
        let filteredData = seatIntelligenceList.filter(
          (data) => data.label === venueData.scrapingStatus
        );
        if (filteredData.length > 0)
          setSeatIntelligenceId(Number(filteredData[0].value));
      }
      setVenueUrl(venueData.venueUrl);
      setUnavailableToScrapers(venueData?.fees_unavailable);
      setFeesAvailableScraping(venueData?.fees_available_scraping);
      // setFeesInput(venueData?.fees_unavailable)
      setDataSource(venueData.dataSource);
      setNotes(venueData.notes);
      setVenueFees(venueData?.venue_fees);
    }
  }, [venueData, brokerList, seatIntelligenceList, currentCustomEventData]);

  const getBrokerTeamByBroker = async (id: string) => {
    setLoading(true);
    try {
      setAssignedListers([]);
      setAssignedCheckers([]);
      setAssignedSeatMapCreators([]);
      const response = await new VenueService().getBrokerTeamByBrokerId(id);
      let listersArr: { value: string; label: string; checked?: boolean }[] =
        response.data.data.listers;
      let checkersArr: { value: string; label: string; checked?: boolean }[] =
        response.data.data.checkers;
      let seatMapCreatorsArr: {
        value: string;
        label: string;
        checked?: boolean;
      }[] = response.data.data.seat_map_creators;
      let _listerIds: any = [];
      let _checkerIds: any = [];
      let _seatMapCreatorIds: any = [];
      if (listersArr.length > 0) {
        listersArr = listersArr.map(
          (lister: { value: string; label: string; checked?: boolean }) => {
            const checked = venueData.lister_id.includes(lister.value);

            if (checked) {
              _listerIds.push(lister.value);
            }

            return {
              ...lister,
              checked,
            };
          }
        );
      }

      if (checkersArr.length > 0) {
        checkersArr = checkersArr.map(
          (checker: { value: string; label: string; checked?: boolean }) => {
            const checked = venueData.checker_user_id.includes(checker.value);

            if (checked) {
              _checkerIds.push(checker.value);
            }

            return {
              ...checker,
              checked,
            };
          }
        );
      }

      if (seatMapCreatorsArr.length > 0) {
        seatMapCreatorsArr = seatMapCreatorsArr.map(
          (seatMapCreator: {
            value: string;
            label: string;
            checked?: boolean;
          }) => {
            const checked = venueData.seat_map_creator_user_id.includes(
              seatMapCreator.value
            );

            if (checked) {
              _seatMapCreatorIds.push(seatMapCreator.value);
            }

            return {
              ...seatMapCreator,
              checked,
            };
          }
        );
      }

      setListerIds(_listerIds);
      setCheckerIds(_checkerIds);
      setSeatMapCreatorIds(_seatMapCreatorIds);
      setAssignedListers(listersArr);
      setAssignedCheckers(checkersArr);
      setAssignedSeatMapCreators(seatMapCreatorsArr);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleBrokerSelection = (id: string) => {
    setBrokerId(id);
    getBrokerTeamByBroker(id);
  };

  useEffect(() => {
    if (venueData) {
      getBrokerTeamByBroker(venueData.broker_id[0]);
    }
  }, [venueData]);

  useEffect(() => {
    if (venueUrl !== null && venueUrl !== "") {
      if (checkValidUrl(venueUrl)) {
        setAllowSave(true);
      } else {
        setAllowSave(false);
      }
    } else {
      setAllowSave(true);
    }
  }, [venueUrl]);

  useEffect( () => {
    if( unavailableToScrapers && !feesAvailableScraping ) {
      if( !venueFees || venueFees === '0' || /[a-z]/i.test(venueFees) === true || [',',' ','$'].some(char => `${venueFees}`?.includes(char)) ) {
        // setVenueFeesInvalid(true)
        setAllowSave(false)
      } else {
        // setVenueFeesInvalid(false)
        setAllowSave(true)
      }
    } else {
        setAllowSave(true)
    }
  }, [venueFees,unavailableToScrapers])

  useEffect( () => {
    if( unavailableToScrapers && !feesAvailableScraping ) {
      if (venueFeesInvalid === true) {
        setAllowSave(false);
      } else {
        setAllowSave(true);
      }
    }
  }, [venueFeesInvalid])

  const closeModal = useCallback(() => {
    resetFields();
    setShowModal(false);
    setVenueData(null);
  },[showModal]);

  const handleVenueFees = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setVenueFees(value);
  };

  const checkFeesInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length < 1) {
      setVenueFeesInvalid(true)
    } else if (e.target.value.trim() === '0') {
      setVenueFeesInvalid(true)
    } else if (/[a-z]/i.test(e.target.value.trim()) ===true ) {
      setVenueFeesInvalid(true)
    } else if ([',',' ','$'].some(char => `${venueFees}`?.includes(char))) {
      setVenueFeesInvalid(true)
    }
    else {
      setVenueFeesInvalid(false)
    }
  },[venueFeesInvalid, venueFees]);

  const feesAvailabilityCheckboxClickHandler = useCallback(() => {
    setUnavailableToScrapers((prev) => !prev);
    setVenueFees(null);
  },[unavailableToScrapers, venueFees]);

  return (
    <React.Fragment>
      <div ref={actionRef} style={{ display: "none" }} onClick={actionHandler}>
        Actions
      </div>
      <Modal
        closable={false}
        width={760}
        title={false}
        footer={false}
        visible={showModal}
        centered
        className="editModal"
      >
        <LoadingSpinner isLoading={loading}>
          <div className="modalCustomBody">
            <div className="editUserInfoForm">
              <div className="flx">
                <h3>Edit Venue :</h3>
                <h3 className="ml-5">{titleCase(venueData?.venueName)}</h3>
              </div>
              <div className="ant-row">
                <div
                  id="clmBrokers"
                  className="ant-col ant-col-xs-24 ant-col-sm-24"
                >
                  <div className="inputGroup">
                    <label>Broker</label>
                    <Select
                      placeholder="Select"
                      value={brokerId ? brokerId : ""}
                      onChange={(e: string) => handleBrokerSelection(e)}
                      disabled={
                        !hasPermissions([
                          ERbacPermissions.VENUE_DETAILS_EDIT_BROKER_SELECTION,
                        ])
                      }
                    >
                      {hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_BROKER_SELECTION,
                      ]) ? (
                        brokerList.map((data, ind) => (
                          <Option key={ind} value={data.value}>
                            {data.label}
                          </Option>
                        ))
                      ) : (
                        <Option key={1} value={venueData?.broker_id[0]}>
                          {venueData?.broker_name[0]}
                        </Option>
                      )}
                    </Select>
                  </div>
                </div>
                <SelectListers
                  assignedListers={assignedListers ?? []}
                  listerIds={listerIds}
                  setListerIds={setListerIds}
                />
                <SelectCheckers
                  assignedCheckers={assignedCheckers ?? []}
                  checkerIds={checkerIds}
                  setCheckerIds={setCheckerIds}
                />
                <SelectSeatMapCreators
                  assignedSeatMapCreators={assignedSeatMapCreators ?? []}
                  seatMapCreatorIds={seatMapCreatorIds}
                  setSeatMapCreatorIds={setSeatMapCreatorIds}
                />
                <div className="ant-col ant-col-xs-24 ant-col-sm-24">
                  <div className="inputGroup">
                    <label>Venue URL Link</label>
                    <input
                      value={venueUrl ? venueUrl : ""}
                      onChange={handleUrl}
                      placeholder="Venue Url"
                      type="text"
                      className="ant-input"
                      onInput={checkValidity}
                      ref={venueUrlRef}
                      disabled={
                        venueData?.seat_intelligence_id === 1 ? true : false
                      }
                    />
                  </div>
                </div>
                {hasPermissions([
                  ERbacPermissions.VENUE_DETAILS_EDIT_SEAT_INTELLIGENCE_SELECTION,
                ]) && (
                  <>
                    <div className="ant-col ant-col-xs-24 ant-col-sm-24">
                      <div className="inputGroup">
                        <label>Seating Intelligence</label>
                        <div className="radioList">
                          <Radio.Group
                            value={seatIntelligenceId}
                            onChange={(e) => handleSeatIntelligenceStatus(e)}
                            className="radioStyleDark radioGroupList"
                            disabled={true}
                          >
                            {seatIntelligenceList.map((data, ind) => (
                              <Radio key={ind} value={data.value}>
                                {data.label}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    {seatIntelligenceId === 1 && (
                      <>
                        <div className="ant-col ant-col-xs-24 ant-col-sm-24">
                          <div className="inputGroup">
                            <label>Data Source</label>
                            <input
                              value={dataSource ? dataSource : ""}
                              onChange={(e) => setDataSource(e.target.value)}
                              placeholder="Data Source"
                              type="text"
                              className="ant-input"
                            />
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-24">
                          <div className="inputGroup">
                            <label>Notes</label>
                            <TextArea
                              placeholder="Notes"
                              defaultValue={venueData.notes}
                              value={notes ? notes : ""}
                              style={{ minHeight: "150px" }}
                              onChange={(e) => setNotes(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="ant-col ant-col-xs-24 ant-col-sm-24 checkboxList">
                  <div className="inputGroup">
                    <Checkbox
                      disabled={feesAvailableScraping}
                      checked={unavailableToScrapers}
                      onClick={feesAvailabilityCheckboxClickHandler}
                    >
                      <span>Fees unavailable to Scrapers</span>
                    </Checkbox>
                  </div>
                </div>
                {unavailableToScrapers && (
                  <div className="ant-col ant-col-xs-24 ant-col-sm-24">
                    <div className="inputGroup">
                      <label>
                        Venue Fees<span className="req">*</span>
                      </label>
                      <Input
                        prefix={ <span style={{color:'#2741CC'}}>$</span> }
                        value={venueFees ? venueFees : ""}
                        name={"venue_fees"}
                        onChange={handleVenueFees}
                        placeholder="Venue Fees"
                        type="text"
                        onInput={checkFeesInput}
                        status={ venueFeesInvalid ? 'error' : ''}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modalCustomFooter">
            <Button className="linkBtn" onClick={closeModal}>
              CLOSE
            </Button>
            <Button
              className="ant-btn btnOk"
              onClick={saveVenue}
              disabled={!allowSave}
            >
              SAVE
            </Button>
          </div>
        </LoadingSpinner>
      </Modal>
    </React.Fragment>
  );
};

export default EditVenue;
