import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import Purchases from './Purchases';
import BuyersComparisonPurchases from './BuyersComparisonPurchases';
import InvoicesMovedToShipping from './InvoicesMovedToShipping';
import InvoicesDelivered from './InvoicesDelivered';
import AllComparisons from './AllComparisons';

const PurchaseShippingDepartment = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Purchase and Shipping Department Performance</h3>
                    <Row className="removeRowBtmSpc<Purchases/>">
                        <Purchases/>
                        <BuyersComparisonPurchases/>
                        <InvoicesMovedToShipping/>
                        <InvoicesDelivered/>
                        <AllComparisons/>
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default PurchaseShippingDepartment