import { Button, Input, Layout, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useRbac } from "features/rbac/rbac";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { useState, useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import FilterOption from "utils/commonFilter/FilterOption";
import { formatDateGivenFormatString, getOffsetDate } from "utils/formatTimePeriod";
import { resetEventSlice } from "models/Slices/EventManagementSlice";

const EventsSearchDashboard: React.FC<{doSearch: Function}> = ({doSearch}) => {

  const dispatch = useAppDispatch();
  const { auth0UserDetails } = useRbac();


  useEffect(() => {
    dispatch(resetEventSlice());
  }, [dispatch]);


  const [searchText, setSearchText] = useState("");
  const [showEventDateArea, setShowEventDateArea] = useState(false);
  const [eventDate, setEventDate] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
    to: getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
  });

  const [defaultEventDate, setDefaultEventDate] = useState<{
    rangeFrom: string | null;
    rangeTo: string | null;
    defaultFrom: string | null;
    defaultTo: string | null;
    selectedFrom: string | null;
    selectedTo: string | null;
  }>({
    rangeFrom: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
    rangeTo: getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
    defaultFrom:
      getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
    defaultTo: getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
    selectedFrom:
      getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
    selectedTo: getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
  });


  const setDateSelected = (key: string, startDate: string, endDate: string) => {
    let _defaultEventDate = { ...defaultEventDate };
    _defaultEventDate.selectedFrom = startDate;
    _defaultEventDate.selectedTo = endDate;
    setDefaultEventDate(_defaultEventDate);
  };

  const resetEventDate = () => {
    let _defaultEventDate = { ...defaultEventDate };
    _defaultEventDate.selectedFrom = _defaultEventDate.defaultFrom;
    _defaultEventDate.selectedTo = _defaultEventDate.defaultTo;
    setDefaultEventDate(_defaultEventDate);
  };

  const cancelEventDate = () => {
    setEventDate({
      from: null,
      to: null,
    });
    setShowEventDateArea(false);
  };

  const applyEventDate = () =>{
    setEventDate({
      from: defaultEventDate.selectedFrom,
      to: defaultEventDate.selectedTo,
    });
    setShowEventDateArea(false);
  }

  const goNextPageAndSearch = () =>{
    if(validation()){
      doSearch({
        dashboard_search_text: searchText,
        event_date_from: eventDate.from,
        event_date_to: eventDate.to,
      });
    }
  }

  const validation = () => {
    if(searchText.trim() !== '' || eventDate.from){
      return true;
    }
    return false;
  }

  return (
    <Layout className="mainInnerLayout fullHeight" title="Events">
      <Content className="mainContainer">
        <div className="eventsFormWrap fullHeight">
          <div className="eventsForm">
            <h3>Hi {auth0UserDetails?.name}!</h3>
            <div className="inputGroup">
              <label>What event are you looking for?</label>
              <Input className="searchXl" value={searchText} type={'text'} 
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onPressEnter={goNextPageAndSearch}
              placeholder="Search for event or venue name" allowClear />
            </div>
            <div className="flx justifyContentBetween alignItemsCenter searchEventTop">
              <div className="selectDateRangeWrap">
                <div 
                  onClick={() => {
                    setShowEventDateArea(!showEventDateArea);
                  }}
                  className="selectDateRange"
                >
                  <Tooltip title="Pick a Date Range" placement="bottom"><i className="da icon-calendar"/></Tooltip>
                  {eventDate.from &&  <span>Event date range: {formatDateGivenFormatString(eventDate?.from ?? '', 'ddd, MMM DD, YYYY')} - {formatDateGivenFormatString(eventDate?.to ?? '', 'ddd, MMM DD, YYYY')}</span>}
                  {!eventDate.from &&  <span>{showEventDateArea ? 'Hide': 'Select'} event date range</span>}
                  
                </div>
              </div>
              <Button className="ant-btn btnStyle2 eventSearchBtn" disabled={!validation()} onClick={goNextPageAndSearch}>
                SEARCH
              </Button>
            </div>

            {showEventDateArea && (
              <div className="eventDateRangeSec active">
                <h4>EVENT DATE RANGE</h4>
                <div className="eventDateRangeToggle">
                  <div className="eventDateRangeToggleInner">
                    <div className="eventDashboardFilterArea">

                      <FilterOption
                        keyValue={"event_date"}
                        type={"datePicker"}
                        label={""}
                        from={defaultEventDate.selectedFrom}
                        to={defaultEventDate.selectedTo}
                        defaultDateFormValue={defaultEventDate}
                        onchange={setDateSelected}
                      />
                    </div>
                  </div>
                </div>
                <div className="formFooter">
                  <button
                    type="button"
                    onClick={resetEventDate}
                    className="ant-btn linkBtn"
                  >
                    RESET
                  </button>
                  <button
                    type="button"
                    onClick={cancelEventDate}
                    className="ant-btn linkBtn"
                  >
                    CANCEL
                  </button>
                  <button 
                  type="button"
                  onClick={applyEventDate}
                  className="ant-btn btnStyle2 apply">
                    APPLY
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default EventsSearchDashboard;
