import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, InputNumber, message, Row, Spin, Tooltip, Typography } from "antd";
import CustomError from "controls/CustomError/CustomError";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import {
  IBulkListingFormData,
  IBulkListingQueryString,
  ICreateListingBulkPlacementZoneHeaders,
  IEventMetadata,
  IEventsCreateListingBulkPlacement,
  IEventsCreateListingBulkPlacementEvent,
  IMaster,
} from "models/interfaces";

import { ICustomError } from "models/interfaces/ICustomError";
import { useIdleTimerContext } from "react-idle-timer";
import { useHistory } from "react-router";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { asyncForEach, daysToEventColorCheck, formatAccountingValues, getEncodedData } from "utils/commonFunction";
import { formatDateGivenFormatString, getDateObject, getOffsetDate } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { calculateListPrice, getLastRowNameFromRowsRange } from "../createNewListing.utils";
import BulkPlacementErrorMessageModal from "./BulkPlacementErrorMessageModal";
import BulkSecondaryMarketplacePerEvent from "./BulkSecondaryMarketplace/BulkSecondaryMarketplacePerEvent";
import BulkSecondaryMarketplacePriceRangePerEvent from "./BulkSecondaryMarketplace/BulkSecondaryMarketplacePriceRangePerEvent";

let newRowInputTimer: any = null;

const BulkPlacementSetPricingForm: React.FC<{
  eventMetadata: IEventMetadata;
  zoneAvailabilityMaster: IMaster[];
  bulkPlacementFormData: IBulkListingFormData;
  onChangedDisabledAllInputsAndCtas: Function;
  // setSecondaryMarketplaceMaster: (
  //   secondaryMarketplaceMaster: IMaster[]
  // ) => void;
  // setCurrentSelectedMarketplaceNameAlias: (
  //   currentSelectedMarketplaceNameAlias: string
  // ) => void;
  updateDefaultMarkupValue: (markupType: number, markupValue: number) => void;
  filterQueryString: IBulkListingQueryString | undefined;
}> = ({
  eventMetadata,
  zoneAvailabilityMaster,
  bulkPlacementFormData,
  onChangedDisabledAllInputsAndCtas,
  // setSecondaryMarketplaceMaster,
  // setCurrentSelectedMarketplaceNameAlias,
  updateDefaultMarkupValue,
  filterQueryString
}) => {
  const idleTimer = useIdleTimerContext();
  const history = useHistory();
  const [form] = Form.useForm();

  const tableScrollableElement = useRef<HTMLDivElement | null>(null);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [eventsData, setEventsData] =
    useState<IEventsCreateListingBulkPlacement | null>(null);
  const [zoneHeaders, setZoneHeaders] = useState<
    ICreateListingBulkPlacementZoneHeaders[] | null
  >(null);

  const [checkUncheckAllRowColumn, setCheckUncheckAllRowColumn] = useState({
    checked: false,
    indeterminateChecked: false,
  });
  const [hideUnselectedRowsAndColumns, setHideUnselectedRowsAndColumns] =
    useState(false);
  const [
    doUncheckCellsWithExistingPlacements,
    setDoUncheckCellsWithExistingPlacements,
  ] = useState(false);
  const [doRoundOffValuesToZeroDecimals, setDoRoundOffValuesToZeroDecimals] =
    useState(false);

  const [currentSortField, setCurrentSortField] = useState<{
    sortField: string;
    sortType: "asc" | "desc";
  }>({
    sortField: "event_date",
    sortType: "asc",
  });

  /** When New listing is creating start **/
  const [currentSubmittingEventId, setCurrentSubmittingEventId] = useState<
    number | null
  >(null);
  const [isCreatingNewListing, setIsCreatingNewListing] = useState(false);
  const [doDisabledAllInputsAndCtas, setDoDisabledAllInputsAndCtas] =
    useState(false);
  const [isShowAllEnabled, setIsShowAllEnabled] = useState(false);

  useEffect(() => {
    onChangedDisabledAllInputsAndCtas(doDisabledAllInputsAndCtas);
  }, [doDisabledAllInputsAndCtas]);

  /** When New listing is creating end **/

  const getEvents = async (reset: boolean) => {
    if (eventMetadata.event_id) {
      setIsLoadingEvents(true);
      try {
        let response;
        if(filterQueryString && filterQueryString.zone_sections && !reset) {
          response =
            await new ZonesService().getEventsCreateListingBulkPlacement(
              filterQueryString
            );
        } else {
          response =
            await new ZonesService().getEventsCreateListingBulkPlacement(
              {event_id: eventMetadata.event_id}
            );
        }
        const initialFormValue: any = {};

        if (response.data.data.events && response.data.data.events.length) {
          const headers: ICreateListingBulkPlacementZoneHeaders[] = [];
          let startedFakeId = 1;
          response.data.data.events?.forEach((eachEvents) => {
            if (eachEvents.zones && eachEvents.zones.length) {
              eachEvents.zones?.forEach((eachZones) => {
                const findHeader = headers.find((eachHeaders) => {
                  return (
                    eachHeaders.zone_section.toLocaleUpperCase() ===
                      eachZones.zone_section.toLocaleUpperCase() &&
                    eachHeaders.rows.toLocaleUpperCase() ===
                      eachZones.rows.toLocaleUpperCase()
                  );
                });
                if (!findHeader) {
                  const newCreatedHeader = {
                    fake_id: startedFakeId,
                    zone_section: eachZones.zone_section.toLocaleUpperCase(),
                    rows: eachZones.rows.toLocaleUpperCase(),
                    listing_rows: getLastRowNameFromRowsRange(
                      eachZones.rows.toLocaleUpperCase()
                    ),
                    listing_rows_field_name: "listing_rows_" + startedFakeId,
                    zone_section_alias_dictionary:
                      eachZones.zone_section_alias_dictionary ?? [],
                    is_checked_all_zone_section_column: false,
                    is_indeterminate_zone_section_column: false,
                    is_disabled_zone_section_column: false,
                    available_event_count: 0,
                  };
                  headers.push(newCreatedHeader);
                  initialFormValue[newCreatedHeader.listing_rows_field_name] =
                    newCreatedHeader.listing_rows;
                  startedFakeId++;
                }
              });
            }
          });
          const sortedHeaders = [...headers].sort((a, b) => {
            if (a.zone_section === b.zone_section) {
              return a.rows > b.rows ? 1 : b.rows > a.rows ? -1 : 0;
            }
            return a.zone_section > b.zone_section ? 1 : -1;
          });

          const responseData = response.data.data;
          const requestedEvents = [...responseData.events];

          requestedEvents.forEach((eachEvent) => {
            const zones = eachEvent.zones;
            zones.forEach((eachZone) => {
              const findMatchedZone = sortedHeaders.find((eachHeader) => {
                return (
                  eachZone.zone_section.toLocaleUpperCase() ===
                    eachHeader.zone_section.toLocaleUpperCase() &&
                  eachZone.rows.toLocaleUpperCase() ===
                    eachHeader.rows.toLocaleUpperCase()
                );
              });

              eachZone.event_id = eachEvent.event_id;
              eachZone.is_checked_create_purchase_line = false;
              eachZone.availability_html_style_class_name = "";
              eachZone.no_data_input_html_style_class_name = "";
              if (findMatchedZone) {
                eachZone.fake_header_zone_id = findMatchedZone.fake_id;
                eachZone.show_purchase_line_card = true;
                if (![1, 2].includes(eachZone.zone_availability_id)) {
                  eachZone.show_card_inputs = false;
                  eachZone.no_data_input_html_style_class_name = "noData";
                } else {
                  eachZone.is_checked_create_purchase_line = true;
                  eachZone.show_card_inputs = true;
                  eachZone.listing_price_field_name =
                    "list_pricing_" + eachEvent.event_id + "_" + eachZone.id;
                  eachZone.min_markup_value =
                    eachZone.cost * (1 + bulkPlacementFormData.minMarkup / 100);
                  if (bulkPlacementFormData?.defaultMarkup) {
                    const listPriceWithDefaultMarkup = calculateListPrice(
                      {
                        defaultMarkupType:
                          bulkPlacementFormData.defaultMarkupType,
                        defaultMarkup: bulkPlacementFormData.defaultMarkup,
                      },
                      eachZone.cost
                    );
                    const listPrice =
                      listPriceWithDefaultMarkup &&
                      eachZone.min_markup_value > listPriceWithDefaultMarkup
                        ? eachZone.min_markup_value
                        : listPriceWithDefaultMarkup;
                    initialFormValue[eachZone.listing_price_field_name] =
                      listPrice;
                  }
                }

                if (eachZone.zone_availability_id === 0) {
                  eachZone.availability_html_style_class_name = "default";
                  eachZone.no_data_input_html_style_class_name = "";
                } else if (eachZone.zone_availability_id === 1) {
                  eachZone.availability_html_style_class_name = "success";
                } else if (eachZone.zone_availability_id === 2) {
                  eachZone.availability_html_style_class_name = "warning";
                } else if (eachZone.zone_availability_id === 3) {
                  eachZone.availability_html_style_class_name = "danger";
                } else if (eachZone.zone_availability_id === 4) {
                  eachZone.availability_html_style_class_name = "tbd";
                } else if (eachZone.zone_availability_id === 5) {
                  eachZone.availability_html_style_class_name = "null";
                }

                eachZone.has_zone_listings = false;
                const findZoneHeader = sortedHeaders?.find(
                  (each) => each.fake_id === findMatchedZone?.fake_id
                );
                const findEvent = requestedEvents.find(
                  (eachCheckEvent) =>
                    eachCheckEvent.event_id === eachEvent.event_id &&
                    eachCheckEvent.list_inventory &&
                    eachCheckEvent.list_inventory.length
                );

                if (findZoneHeader && findEvent) {
                  if (
                    findEvent.list_inventory?.find(
                      (each) =>
                        each.section.toLocaleUpperCase() ===
                          findZoneHeader.zone_section.toLocaleUpperCase() &&
                        each.row.toLocaleUpperCase() ===
                          findZoneHeader.listing_rows.toLocaleUpperCase()
                    )
                  ) {
                    eachZone.has_zone_listings = true;
                  }
                }
              }
            });

            eachEvent.is_disabled_purchase = false;
            if (
              !zones.filter((_eachZone) => _eachZone.show_card_inputs).length
            ) {
              eachEvent.is_disabled_purchase = true;
            } else if (
              zones.filter(
                (_eachZone) => _eachZone.is_checked_create_purchase_line
              ).length
            ) {
              eachEvent.is_checked_all_purchase_line = true;
              eachEvent.is_indeterminate_checked_purchase_line = false;
            }
          });

          sortedHeaders.forEach((eachHeader) => {
            const findAvailableEvent = requestedEvents.filter((eachEvent) => {
              return (
                eachEvent.zones.filter(
                  (eachZone) =>
                    eachZone.fake_header_zone_id === eachHeader.fake_id &&
                    eachZone.is_checked_create_purchase_line &&
                    eachZone.show_card_inputs
                ).length > 0
              );
            });

            eachHeader.is_disabled_zone_section_column = true;
            eachHeader.available_event_count = findAvailableEvent.length;
            if (findAvailableEvent.length) {
              eachHeader.is_disabled_zone_section_column = false;
              eachHeader.is_checked_all_zone_section_column = true;
              eachHeader.is_indeterminate_zone_section_column = false;
            }
          });
          responseData.events = requestedEvents;

          /** Find purchase line checked or not */
          const _checkUncheckAllRowColumn = { ...checkUncheckAllRowColumn };
          if (
            responseData.events.filter(
              (each) => each.is_checked_all_purchase_line
            ).length === responseData.events.length
          ) {
            _checkUncheckAllRowColumn.checked = true;
            _checkUncheckAllRowColumn.indeterminateChecked = false;
          } else if (
            responseData.events.filter(
              (each) => each.is_checked_all_purchase_line
            ).length ||
            responseData.events.filter(
              (each) => each.is_indeterminate_checked_purchase_line
            ).length
          ) {
            _checkUncheckAllRowColumn.checked = false;
            _checkUncheckAllRowColumn.indeterminateChecked = true;
          } else {
            _checkUncheckAllRowColumn.checked = false;
            _checkUncheckAllRowColumn.indeterminateChecked = false;
          }

          setCheckUncheckAllRowColumn(_checkUncheckAllRowColumn);

          form.setFieldsValue(initialFormValue);
          setEventsData(responseData);
          setZoneHeaders(sortedHeaders);
        }
        // if (
        //   response.data.data.availableSecondaryMarketplaceMaster &&
        //   response.data.data.availableSecondaryMarketplaceMaster.length
        // ) {
        //   const _secondaryMarketplaceMaster =
        //     response.data.data.availableSecondaryMarketplaceMaster.map(
        //       (each) => {
        //         return {
        //           label: each.name,
        //           value: each.name_alias,
        //         };
        //       }
        //     );
        //   setSecondaryMarketplaceMaster(_secondaryMarketplaceMaster);

        //   if (
        //     _secondaryMarketplaceMaster.find(
        //       (each) => each.value === "vivid_seats"
        //     )
        //   ) {
        //     setCurrentSelectedMarketplaceNameAlias("vivid_seats");
        //   }
        // }
      } catch (error: any) {
        new LoggerService().log({
          payload: error,
          function_name: "getEvents",
        });

        setDataFetchError({ ...error, refreshAction: getEvents });
      }
      setIsLoadingEvents(false);
    }
  };

  const applyChangeMarkup = () => {
    const _eventsData = { ...eventsData };
    if (_eventsData.events && bulkPlacementFormData?.defaultMarkup) {
      const _events = _eventsData.events;
      _events.forEach((eachEvent) => {
        eachEvent.zones.forEach((eachZone) => {
          if (
            eachZone.show_card_inputs &&
            eachZone.listing_price_field_name &&
            eachZone.is_checked_create_purchase_line
          ) {
            const listPriceWithDefaultMarkup =
              calculateListPrice(
                {
                  defaultMarkupType: bulkPlacementFormData.defaultMarkupType,
                  defaultMarkup: bulkPlacementFormData.defaultMarkup,
                },
                eachZone.cost
              ) ?? 0;
            const listPrice =
              listPriceWithDefaultMarkup &&
              eachZone.min_markup_value > listPriceWithDefaultMarkup
                ? eachZone.min_markup_value
                : listPriceWithDefaultMarkup;
            form.setFieldsValue({
              [eachZone.listing_price_field_name]: listPrice,
            });
          }
        });
      });
    }
  };

  const getSortedEvents = () => {
    let filteredEvents: IEventsCreateListingBulkPlacementEvent[] = [];
    if (eventsData?.events) {
      filteredEvents = [...eventsData?.events].sort((a, b) => {
        const c = new Date(a.event_date).getTime();
        const d = new Date(b.event_date).getTime();
        return currentSortField.sortType === "asc" ? c - d : d - c;
      });
    }

    if (hideUnselectedRowsAndColumns) {
      filteredEvents = filteredEvents
        .map((eachMapEvent) => {
          return {
            ...eachMapEvent,
            zones: eachMapEvent.zones.filter(
              (eachFilterZone) => eachFilterZone.is_checked_create_purchase_line
            ),
          };
        })
        .filter(
          (eachEvent) =>
            eachEvent.is_checked_all_purchase_line ||
            eachEvent.is_indeterminate_checked_purchase_line
        );
    }

    return filteredEvents;
  };

  const getSortedZoneHeaders = () => {
    let _zoneHeaders: ICreateListingBulkPlacementZoneHeaders[] = [];
    if (zoneHeaders && zoneHeaders.length > 0) {
      _zoneHeaders = [...zoneHeaders];
      if (hideUnselectedRowsAndColumns) {
        _zoneHeaders = _zoneHeaders.filter(
          (each) =>
            each.is_checked_all_zone_section_column ||
            each.is_indeterminate_zone_section_column
        );
      }
    }
    return _zoneHeaders;
  };

  useEffect(() => {
    if(filterQueryString && filterQueryString.zone_sections && filterQueryString.zone_sections.length > 0){
      console.log(filterQueryString)
      setIsShowAllEnabled(true);
    } else {
      setIsShowAllEnabled(false);
    }
    getEvents(false);
  }, []);

  useLayoutEffect(() => {
    function updateScrollPosition() {
      if (
        tableScrollableElement.current &&
        tableScrollableElement.current?.scrollLeft > 0
      ) {
        tableScrollableElement.current?.classList.add("scrolledShadow");
      }
      if (
        tableScrollableElement.current &&
        tableScrollableElement.current?.scrollLeft === 0
      ) {
        setTimeout(() => {
          tableScrollableElement.current?.classList.remove("scrolledShadow");
        }, 100);
      }
    }
    tableScrollableElement.current?.addEventListener(
      "scroll",
      updateScrollPosition
    );
  });

  const cancelHandler = () => {
    const header = (
      <span className="text-danger">{"All unsaved data will be lost."}</span>
    );
    const body = "Continue?";
    showConfirm(
      header,
      body,
      null,
      () => {
        history.goBack();
      },
      () => console.log("cancelled"),
      "400px"
    );
  };

  const onFinish = async () => {
    setDoDisabledAllInputsAndCtas(true);
    setIsCreatingNewListing(true);
    const readyForSubmitEvents = [...getSortedEvents()].filter(
      (eachEvents) =>
        eachEvents.is_indeterminate_checked_purchase_line ||
        eachEvents.is_checked_all_purchase_line
    );
    if (readyForSubmitEvents.length) {
      await doBulkPlacementCreateListing(
        readyForSubmitEvents.map((data) => data.event_id)
      );
    }
  };

  const doBulkPlacementCreateListing = async (eventIds: number[]) => {
    const createZoneListingLoadingKey = "createNewListing";
    message.loading({
      content: `Creating new zone listing event of "${eventMetadata.event_name}"...`,
      duration: 0,
      key: createZoneListingLoadingKey,
      className: "toastMsg loadingMsg",
    });
    let successCount = 0;
    const currentTotalTime = idleTimer.getTotalElapsedTime();
    const currentTotalIdleTime = idleTimer.getTotalIdleTime();
    const totalTimeInSeconds = currentTotalTime / 1000;
    const idleTimeInSeconds = currentTotalIdleTime / 1000;

    await asyncForEach(eventIds, async (eventId: number) => {
      setCurrentSubmittingEventId(eventId);
      const result = await createNewListingForEvent(
        eventId,
        totalTimeInSeconds,
        idleTimeInSeconds,
        doRoundOffValuesToZeroDecimals
      );
      if (result) {
        successCount = successCount + 1;
      }
    });
    setCurrentSubmittingEventId(null);
    setIsCreatingNewListing(false);
    message.destroy(createZoneListingLoadingKey);
    if (!successCount) {
      message.error({
        content: "Zone listing has failed to create for all event dates.",
        duration: 8,
        className: "toastMsg savedFailed",
      });
    } else {
      doRefreshMvW();
    }
  };

  const doRefreshMvW = async () => {
    const updatingKey = "syncingPurchaseToPlatform";
    try {
      message.loading({
        content:
          "Syncing zone listings from SkyBox. This may take a while. You may continue to work in other areas...",
        duration: 0,
        key: updatingKey,
        className: "toastMsg loadingMsg",
      });

      const refreshMVWforPurchaseResult =
        await new ZonesService().refreshMVWforPurchase();
      let forceUpdateMessage = "";

      if (refreshMVWforPurchaseResult.data.data.is_mvw_refreshed) {
        forceUpdateMessage = "Zone listing has been synced successfully.";
      } else {
        forceUpdateMessage =
          "The Listing has been created successfully but it may take a while to appear on the platform. Data synchronization with SkyBox is in progress.";
      }

      message.success({
        content: (
          <span className="withClose">
            {forceUpdateMessage}{" "}
            <Typography.Link
              href={
                LeftNavRoutesEnum.ZONES_LISTING +
                "?search=" +
                getEncodedData(eventMetadata.event_name)
              }
              style={{ textDecoration: "underline" }}
            >
              View zone listing
            </Typography.Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="toastMsgCloseArea"
              title="Close"
              onClick={() => {
                message.destroy(updatingKey);
              }}
            >
              <i className="da icon-cancel-white"></i>
            </span>
          </span>
        ),
        key: updatingKey,
        duration: 0,
        className: "toastMsg savedSuccess",
      });
    } catch (error) {
      message.error({
        content:
          "Syncing has failed but zone listing data will be available within one hour.",
        duration: 10,
        key: updatingKey,
        className: "toastMsg savedFailed",
      });
      new LoggerService().log({
        payload: error,
        function_name: "doRefreshMvW",
      });

      new ZonesService().updateConfigurationRefreshingMvwOff();
    }
  };

  const createNewListingForEvent = async (
    eventId: number,
    totalTimeInSeconds: number,
    idleTimeInSeconds: number,
    _doRoundOffValuesToZeroDecimals: boolean
  ) => {
    let isSuccess = false;
    const _eventsData = { ...eventsData };
    const _events = _eventsData.events ? [..._eventsData.events] : [];
    _eventsData.events = _events;
    const findCurrentEvent = _events.find(
      (currentEachEvent) => currentEachEvent.event_id === eventId
    );
    if (findCurrentEvent) {
      const zonesToSubmit = [...findCurrentEvent.zones].filter(
        (data) => data.is_checked_create_purchase_line && data.show_card_inputs
      );
      if (zonesToSubmit.length) {
        const zonesToSubmitFormData: any = zonesToSubmit.map((eachZone) => {
          let _listing_price = eachZone.listing_price_field_name
            ? form.getFieldValue(eachZone.listing_price_field_name)
            : 0;
          if (_doRoundOffValuesToZeroDecimals) {
            _listing_price = Math.round(_listing_price);
          }
          return {
            id: eachZone.id,
            zone_availability_id: eachZone.zone_availability_id,
            cost: eachZone.cost,
            zone_section: eachZone.zone_section,
            rows: eachZone.rows,
            broadcast: bulkPlacementFormData.broadcast,
            listing_quantity: bulkPlacementFormData.listing_quantity,
            listing_rows: zoneHeaders?.find(
              (eachHeadData) =>
                eachHeadData.fake_id === eachZone.fake_header_zone_id
            )?.listing_rows,
            listing_second_row: null,
            listing_price: _listing_price,
            stock_type: bulkPlacementFormData.stock_type?.toLocaleUpperCase(), //'Electronic'.toLocaleUpperCase(),
            seat_type: bulkPlacementFormData.seat_type?.toLocaleUpperCase(), //'Consecutive'.toLocaleUpperCase(),
            split_type: bulkPlacementFormData.split_type?.toLocaleUpperCase(), //'ANY',
            low_seat: bulkPlacementFormData.low_seat,
            in_hand_date: getDateObject(
              getOffsetDate(
                OffsetMode.SUBTRACT,
                bulkPlacementFormData.in_hand_days,
                OffsetType.DAYS,
                "YYYY-MM-DD",
                getDateObject(findCurrentEvent.event_date)
              )
            ),
            electronic_transfer: bulkPlacementFormData.electronic_transfer,
            vendor_id: bulkPlacementFormData.vendor_id,
            broker_tag: bulkPlacementFormData.broker_tag,
            broker_tag_id: bulkPlacementFormData.broker_tag_id,
            lister_tag: bulkPlacementFormData.lister_tag,
            lister_tag_id: bulkPlacementFormData.lister_tag_id,
            public_notes: bulkPlacementFormData.public_notes,
            internal_notes: bulkPlacementFormData.internal_notes,
            disclosures: bulkPlacementFormData?.disclosures ?? [],
            attributes: bulkPlacementFormData?.attributes ?? [],
          };
        });
        try {
          const res = await new ZonesService().createNewZoneListing(
            eventId.toString(),
            zonesToSubmitFormData,
            totalTimeInSeconds,
            idleTimeInSeconds,
            3, // Listing type id (Bulk listing with seat intelligence)
            false,
            true
          );
          findCurrentEvent.is_success = true;
          findCurrentEvent.purchased_id = res.data.data.purchase_id;
          isSuccess = true;
        } catch (error: any) {
          console.log(error);
          const errors: string[] = [];
          if (
            error.error &&
            error.error.response &&
            error.error.response.status &&
            error.error.response.status === 400
          ) {
            if (Array.isArray(error.error.response.data.errors)) {
              error.error.response.data.errors.forEach((eachError: any) => {
                errors.push(eachError.message);
              });
            } else {
              errors.push(error.error.response.data.errors);
            }
          } else {
            errors.push("Failed to create new listing");
            new LoggerService().log({
              payload: error,
              function_name: "doBulkPlacementCreateListing",
            });
          }
          findCurrentEvent.is_success = false;
          findCurrentEvent.errors = errors;
        }
      }
    }
    setEventsData({
      ..._eventsData,
      events: _events,
      availableSecondaryMarketplaceMaster:
        _eventsData.availableSecondaryMarketplaceMaster
          ? _eventsData.availableSecondaryMarketplaceMaster
          : [],
    });
    return isSuccess;
  };

  const renderSecondaryCost = (secondaryCost: string) => {
    if (secondaryCost && secondaryCost !== "") {
      const secondaryCostArray = secondaryCost
        .split(",")
        .map(function (el: string) {
          return el.trim() === "" ? "" : "$" + el.trim();
        });

      if (secondaryCostArray.length) {
        return (
          <Tooltip title={secondaryCostArray.join(", ")}>
            <span className="numberCircle mr-5">
              {secondaryCostArray.length}
            </span>
          </Tooltip>
        );
      }
    }
    return "";
  };

  const showAllHandler = () => {
    setIsShowAllEnabled(false);
    getEvents(true);
  };

  return (
    <LoadingSpinner isLoading={isLoadingEvents}>
      {dataFetchError ? (
        <CustomError {...dataFetchError} />
      ) : (
        <React.Fragment>
          <div className="mb20">
            <Row>
              <Col lg={7} xl={6}>
                <div className="bodyCheckbox checkboxListV">
                  <Checkbox
                    checked={hideUnselectedRowsAndColumns}
                    onClick={() =>
                      setHideUnselectedRowsAndColumns(
                        !hideUnselectedRowsAndColumns
                      )
                    }
                  >
                    Hide all Unselected Rows and Columns
                  </Checkbox>
                  <Checkbox
                    checked={doUncheckCellsWithExistingPlacements}
                    onClick={() => {
                      if (eventsData && zoneHeaders) {
                        const _doUncheckCellsWithExistingPlacements =
                          doUncheckCellsWithExistingPlacements ? false : true;

                        /** For Update Event */
                        const _eventsData = { ...eventsData };
                        const _events = _eventsData.events;
                        _events?.forEach((eachEventForCheck) => {
                          eachEventForCheck.zones.forEach(
                            (eachZoneForCheck) => {
                              if (
                                eachZoneForCheck.show_card_inputs &&
                                eachZoneForCheck.has_zone_listings
                              ) {
                                if (_doUncheckCellsWithExistingPlacements) {
                                  eachZoneForCheck.is_checked_create_purchase_line =
                                    false;
                                  eachZoneForCheck.is_disabled_create_purchase_line =
                                    true;
                                } else {
                                  eachZoneForCheck.is_checked_create_purchase_line =
                                    true;
                                  eachZoneForCheck.is_disabled_create_purchase_line =
                                    false;
                                }
                              }
                            }
                          );

                          const totalValidCardCount =
                            eachEventForCheck?.zones.filter(
                              (_eachZone) => _eachZone.show_card_inputs
                            ).length;
                          const totalCheckedCardCount =
                            eachEventForCheck?.zones.filter(
                              (_eachZone) =>
                                _eachZone.is_checked_create_purchase_line
                            ).length;

                          if (!totalCheckedCardCount) {
                            eachEventForCheck.is_checked_all_purchase_line =
                              false;
                            eachEventForCheck.is_indeterminate_checked_purchase_line =
                              false;
                          } else if (
                            totalValidCardCount === totalCheckedCardCount
                          ) {
                            eachEventForCheck.is_checked_all_purchase_line =
                              true;
                            eachEventForCheck.is_indeterminate_checked_purchase_line =
                              false;
                          } else if (
                            totalValidCardCount !== totalCheckedCardCount
                          ) {
                            eachEventForCheck.is_checked_all_purchase_line =
                              false;
                            eachEventForCheck.is_indeterminate_checked_purchase_line =
                              true;
                          }
                        });

                        setEventsData(_eventsData);

                        /** For Update Header */

                        const _headers = [...zoneHeaders];
                        _headers.forEach((eachHeader) => {
                          const findAvailableEvents = _events.filter(
                            (eachEvent) => {
                              return (
                                eachEvent.zones.filter(
                                  (eachZone) =>
                                    eachZone.fake_header_zone_id ===
                                      eachHeader.fake_id &&
                                    eachZone.show_card_inputs
                                ).length > 0
                              );
                            }
                          );

                          const findAvailableCheckedEvents =
                            findAvailableEvents.filter((eachEvent) => {
                              return (
                                eachEvent.zones.filter(
                                  (eachZone) =>
                                    eachZone.fake_header_zone_id ===
                                      eachHeader.fake_id &&
                                    eachZone.is_checked_create_purchase_line &&
                                    eachZone.show_card_inputs
                                ).length > 0
                              );
                            });

                          if (!findAvailableCheckedEvents.length) {
                            eachHeader.is_checked_all_zone_section_column =
                              false;
                            eachHeader.is_indeterminate_zone_section_column =
                              false;
                          } else if (
                            findAvailableEvents.length ===
                            findAvailableCheckedEvents.length
                          ) {
                            eachHeader.is_checked_all_zone_section_column =
                              true;
                            eachHeader.is_indeterminate_zone_section_column =
                              false;
                          } else {
                            eachHeader.is_checked_all_zone_section_column =
                              false;
                            eachHeader.is_indeterminate_zone_section_column =
                              true;
                          }
                        });

                        setZoneHeaders(_headers);
                        setDoUncheckCellsWithExistingPlacements(
                          _doUncheckCellsWithExistingPlacements
                        );

                        /** Find purchase line checked or not */
                        const _checkUncheckAllRowColumn = {
                          ...checkUncheckAllRowColumn,
                        };
                        if (
                          _eventsData.events.filter(
                            (each) => each.is_checked_all_purchase_line
                          ).length === _eventsData.events.length
                        ) {
                          _checkUncheckAllRowColumn.checked = true;
                          _checkUncheckAllRowColumn.indeterminateChecked =
                            false;
                        } else if (
                          _eventsData.events.filter(
                            (each) => each.is_checked_all_purchase_line
                          ).length ||
                          _eventsData.events.filter(
                            (each) =>
                              each.is_indeterminate_checked_purchase_line
                          ).length
                        ) {
                          _checkUncheckAllRowColumn.checked = false;
                          _checkUncheckAllRowColumn.indeterminateChecked = true;
                        } else {
                          _checkUncheckAllRowColumn.checked = false;
                          _checkUncheckAllRowColumn.indeterminateChecked =
                            false;
                        }

                        setCheckUncheckAllRowColumn(_checkUncheckAllRowColumn);
                      }
                    }}
                  >
                    Uncheck all Cells With Existing Placements
                  </Checkbox>
                </div>
              </Col>
              <Col lg={9} xl={10}>
                <div className="bodyCheckbox bulkPlacementSetPriceMarkup">
                  <div className="flx alignItemsCenter mb5">
                    <span style={{ paddingRight: "6px" }}>Markup :</span>
                    <div className="inputGroup removeErrorDiv">
                      <div className="groupAddon">
                        <span
                          className="addon"
                          onClick={() => {
                            const _defaultMarkupType =
                              bulkPlacementFormData.defaultMarkupType === 1
                                ? 2
                                : 1;
                            let _defaultMarkup =
                              bulkPlacementFormData.defaultMarkup;
                            if (
                              _defaultMarkupType === 1 &&
                              bulkPlacementFormData.defaultMarkup > 100
                            ) {
                              _defaultMarkup = 100;
                            }
                            updateDefaultMarkupValue(
                              _defaultMarkupType,
                              _defaultMarkup
                            );
                          }}
                        >
                          {bulkPlacementFormData.defaultMarkupType === 1
                            ? "%"
                            : "$"}
                        </span>
                        <InputNumber
                          value={bulkPlacementFormData.defaultMarkup}
                          precision={2}
                          min={1}
                          controls={false}
                          onChange={(value) => {
                            if (value) {
                              updateDefaultMarkupValue(
                                bulkPlacementFormData.defaultMarkupType,
                                value
                              );
                            }
                          }}
                          max={
                            bulkPlacementFormData?.defaultMarkupType === 1
                              ? 100
                              : 9999
                          }
                        />
                      </div>
                    </div>
                    <Button
                      className="linkBtn color-blue ml-10"
                      style={{fontSize: '0.875rem'}}
                      onClick={applyChangeMarkup}
                      type="link"
                    >
                      APPLY
                    </Button>
                  </div>
                  <Checkbox
                    checked={doRoundOffValuesToZeroDecimals}
                    disabled={doDisabledAllInputsAndCtas}
                    onClick={() => {
                      setDoRoundOffValuesToZeroDecimals(
                        !doRoundOffValuesToZeroDecimals
                      );
                    }}
                  >
                    Round Off Values to Zero Decimals
                  </Checkbox>
                </div>
              </Col>
              <Col lg={8} xl={8} className="text-right right">
                <Button
                  className="linkBtn cancelBtn"
                  style={{fontSize: '0.875rem'}}
                  onClick={showAllHandler}
                  disabled={!isShowAllEnabled}
                >
                  ALL ZONES
                </Button>
                <Button
                  className="linkBtn cancelBtn"
                  style={{fontSize: '0.875rem'}}
                  onClick={cancelHandler}
                  disabled={doDisabledAllInputsAndCtas}
                >
                  START OVER
                </Button>
                <Button
                  className="btnStyle2 createBtn"
                  disabled={
                    eventsData?.events.filter(
                      (eachEvent) =>
                        eachEvent.is_indeterminate_checked_purchase_line ||
                        eachEvent.is_checked_all_purchase_line
                    ).length === 0 || doDisabledAllInputsAndCtas
                  }
                  onClick={() => {
                    setTimeout(() => form.submit(), 150);
                  }}
                >
                  Create Listing
                </Button>
              </Col>
            </Row>
          </div>

          {zoneHeaders && eventsData?.events && (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={() => {
                message.error({
                  content:
                    "Set pricing form has error, please fix and try again.",
                  duration: 5,
                  className: "toastMsg savedFailed",
                });
              }}
              autoComplete="off"
              id="bulkPlacementPricingForm"
            >
              <div className="removeTblLRSpc">
                <div className="customTbl setPricingTbl bulkPlacementTbl">
                  <div
                    className="table-scroll fixedHeaderFooter"
                    ref={tableScrollableElement}
                    style={{ maxHeight: "calc(100vh - 218px)" }}
                  >
                    <table className="main-table">
                      <thead>
                        <tr>
                          <th
                            className="colLeftSticky"
                            style={{ left: 0, minWidth: 40, width: 40 }}
                          >
                            <Tooltip
                              title={"Select or Unselect all Rows and Columns"}
                            >
                              <Checkbox
                                className="mr-10"
                                disabled={doDisabledAllInputsAndCtas}
                                indeterminate={
                                  checkUncheckAllRowColumn.indeterminateChecked
                                }
                                checked={checkUncheckAllRowColumn.checked}
                                onClick={() => {
                                  if (eventsData && zoneHeaders) {
                                    let checkAll = false;
                                    if (
                                      !checkUncheckAllRowColumn.indeterminateChecked &&
                                      !checkUncheckAllRowColumn.checked
                                    ) {
                                      checkAll = true;
                                    }

                                    /** For Update Event */
                                    const _eventsData = { ...eventsData };
                                    const _events = _eventsData.events;
                                    _events?.forEach((eachEventForCheck) => {
                                      eachEventForCheck.zones.forEach(
                                        (eachZoneForCheck) => {
                                          if (
                                            eachZoneForCheck.show_card_inputs &&
                                            !eachZoneForCheck.is_disabled_create_purchase_line
                                          ) {
                                            if (checkAll) {
                                              eachZoneForCheck.is_checked_create_purchase_line =
                                                true;
                                            } else {
                                              eachZoneForCheck.is_checked_create_purchase_line =
                                                false;
                                            }
                                          }
                                        }
                                      );

                                      const totalValidCardCount =
                                        eachEventForCheck?.zones.filter(
                                          (_eachZone) =>
                                            _eachZone.show_card_inputs
                                        ).length;
                                      const totalCheckedCardCount =
                                        eachEventForCheck?.zones.filter(
                                          (_eachZone) =>
                                            _eachZone.is_checked_create_purchase_line
                                        ).length;

                                      if (!totalCheckedCardCount) {
                                        eachEventForCheck.is_checked_all_purchase_line =
                                          false;
                                        eachEventForCheck.is_indeterminate_checked_purchase_line =
                                          false;
                                      } else if (
                                        totalValidCardCount ===
                                        totalCheckedCardCount
                                      ) {
                                        eachEventForCheck.is_checked_all_purchase_line =
                                          true;
                                        eachEventForCheck.is_indeterminate_checked_purchase_line =
                                          false;
                                      } else if (
                                        totalValidCardCount !==
                                        totalCheckedCardCount
                                      ) {
                                        eachEventForCheck.is_checked_all_purchase_line =
                                          false;
                                        eachEventForCheck.is_indeterminate_checked_purchase_line =
                                          true;
                                      }
                                    });

                                    setEventsData(_eventsData);

                                    /** For Update Header */

                                    const _headers = [...zoneHeaders];
                                    _headers.forEach((eachHeader) => {
                                      const findAvailableEvents =
                                        _events.filter((eachEvent) => {
                                          return (
                                            eachEvent.zones.filter(
                                              (eachZone) =>
                                                eachZone.fake_header_zone_id ===
                                                  eachHeader.fake_id &&
                                                eachZone.show_card_inputs
                                            ).length > 0
                                          );
                                        });

                                      const findAvailableCheckedEvents =
                                        findAvailableEvents.filter(
                                          (eachEvent) => {
                                            return (
                                              eachEvent.zones.filter(
                                                (eachZone) =>
                                                  eachZone.fake_header_zone_id ===
                                                    eachHeader.fake_id &&
                                                  eachZone.is_checked_create_purchase_line &&
                                                  eachZone.show_card_inputs
                                              ).length > 0
                                            );
                                          }
                                        );

                                      if (!findAvailableCheckedEvents.length) {
                                        eachHeader.is_checked_all_zone_section_column =
                                          false;
                                        eachHeader.is_indeterminate_zone_section_column =
                                          false;
                                      } else if (
                                        findAvailableEvents.length ===
                                        findAvailableCheckedEvents.length
                                      ) {
                                        eachHeader.is_checked_all_zone_section_column =
                                          true;
                                        eachHeader.is_indeterminate_zone_section_column =
                                          false;
                                      } else {
                                        eachHeader.is_checked_all_zone_section_column =
                                          false;
                                        eachHeader.is_indeterminate_zone_section_column =
                                          true;
                                      }
                                    });

                                    setZoneHeaders(_headers);

                                    /** Find purchase line checked or not */
                                    const _checkUncheckAllRowColumn = {
                                      ...checkUncheckAllRowColumn,
                                    };
                                    if (
                                      _eventsData.events.filter(
                                        (each) =>
                                          each.is_checked_all_purchase_line
                                      ).length === _eventsData.events.length
                                    ) {
                                      _checkUncheckAllRowColumn.checked = true;
                                      _checkUncheckAllRowColumn.indeterminateChecked =
                                        false;
                                    } else if (
                                      _eventsData.events.filter(
                                        (each) =>
                                          each.is_checked_all_purchase_line
                                      ).length ||
                                      _eventsData.events.filter(
                                        (each) =>
                                          each.is_indeterminate_checked_purchase_line
                                      ).length
                                    ) {
                                      _checkUncheckAllRowColumn.checked = false;
                                      _checkUncheckAllRowColumn.indeterminateChecked =
                                        true;
                                    } else {
                                      _checkUncheckAllRowColumn.checked = false;
                                      _checkUncheckAllRowColumn.indeterminateChecked =
                                        false;
                                    }

                                    setCheckUncheckAllRowColumn(
                                      _checkUncheckAllRowColumn
                                    );
                                  }
                                }}
                              />
                            </Tooltip>
                          </th>
                          <th
                            scope="col"
                            className="colLeftSticky text-left"
                            style={{ left: 40, minWidth: 128, width: 128 }}
                          >
                            <div className="tblSort">
                              DATE & TIME
                              <i
                                className={
                                  currentSortField.sortType === "asc"
                                    ? "da sortIcon icon-sort-desc"
                                    : "da sortIcon icon-sort-asc"
                                }
                                onClick={() => {
                                  if (!isCreatingNewListing) {
                                    if (currentSortField.sortType === "asc") {
                                      setCurrentSortField({
                                        sortField: "event_date",
                                        sortType: "desc",
                                      });
                                    } else {
                                      setCurrentSortField({
                                        sortField: "event_date",
                                        sortType: "asc",
                                      });
                                    }
                                  }
                                }}
                              ></i>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="colLeftSticky text-right colLeftStickyLast"
                            style={{ left: 168, minWidth: 90, width: 90 }}
                          >
                            <div className="tblSort">DAYS TO EVENT</div>
                          </th>
                          {getSortedZoneHeaders().map((eachHead) => {
                            return (
                              <th
                                key={
                                  "header-" +
                                  eachHead.zone_section +
                                  eachHead.rows
                                }
                                scope="col"
                                className="text-left"
                                style={{ minWidth: 190, width: 190 }}
                              >
                                <div className="flx alignItemsCenter">
                                  <Checkbox
                                    className="mr-10"
                                    disabled={
                                      eachHead.is_disabled_zone_section_column ||
                                      doDisabledAllInputsAndCtas
                                    }
                                    indeterminate={
                                      eachHead.is_indeterminate_zone_section_column
                                    }
                                    checked={
                                      eachHead.is_checked_all_zone_section_column
                                    }
                                    onClick={() => {
                                      /** For event update */
                                      const _eventsData = { ...eventsData };
                                      const _events = _eventsData.events;

                                      const findAvailableEventIds = _events
                                        .filter((eachEvent) => {
                                          return (
                                            eachEvent.zones.filter(
                                              (eachZone) =>
                                                eachZone.fake_header_zone_id ===
                                                  eachHead.fake_id &&
                                                eachZone.show_card_inputs
                                            ).length > 0
                                          );
                                        })
                                        .map((each) => each.event_id);

                                      if (findAvailableEventIds.length) {
                                        findAvailableEventIds.forEach(
                                          (eachEventId) => {
                                            const findEvent = _events.find(
                                              (eachEvent) =>
                                                eachEvent.event_id ===
                                                eachEventId
                                            );
                                            if (findEvent) {
                                              findEvent.zones.forEach(
                                                (eachZone) => {
                                                  if (
                                                    eachZone.fake_header_zone_id ===
                                                      eachHead.fake_id &&
                                                    eachZone.show_card_inputs &&
                                                    !eachZone.is_disabled_create_purchase_line
                                                  ) {
                                                    if (
                                                      eachHead.is_indeterminate_zone_section_column
                                                    ) {
                                                      eachZone.is_checked_create_purchase_line =
                                                        false;
                                                    } else if (
                                                      eachHead.is_checked_all_zone_section_column
                                                    ) {
                                                      eachZone.is_checked_create_purchase_line =
                                                        false;
                                                    } else {
                                                      eachZone.is_checked_create_purchase_line =
                                                        true;
                                                    }
                                                  }
                                                }
                                              );

                                              const totalValidCardCount =
                                                findEvent?.zones.filter(
                                                  (_eachZone) =>
                                                    _eachZone.show_card_inputs
                                                ).length;
                                              const totalCheckedCardCount =
                                                findEvent?.zones.filter(
                                                  (_eachZone) =>
                                                    _eachZone.is_checked_create_purchase_line
                                                ).length;

                                              if (!totalCheckedCardCount) {
                                                findEvent.is_checked_all_purchase_line =
                                                  false;
                                                findEvent.is_indeterminate_checked_purchase_line =
                                                  false;
                                              } else if (
                                                totalValidCardCount ===
                                                totalCheckedCardCount
                                              ) {
                                                findEvent.is_checked_all_purchase_line =
                                                  true;
                                                findEvent.is_indeterminate_checked_purchase_line =
                                                  false;
                                              } else if (
                                                totalValidCardCount !==
                                                totalCheckedCardCount
                                              ) {
                                                findEvent.is_checked_all_purchase_line =
                                                  false;
                                                findEvent.is_indeterminate_checked_purchase_line =
                                                  true;
                                              }
                                            }
                                          }
                                        );
                                      }
                                      setEventsData(_eventsData);
                                      /** For Header Update */

                                      const _headers = [...zoneHeaders];
                                      const findHeader = _headers.find(
                                        (eachHeaderItem) =>
                                          eachHeaderItem.fake_id ===
                                          eachHead.fake_id
                                      );
                                      if (findHeader) {
                                        const findAvailableEvents =
                                          _events.filter((eachEvent) => {
                                            return (
                                              eachEvent.zones.filter(
                                                (eachZone) =>
                                                  eachZone.fake_header_zone_id ===
                                                    findHeader.fake_id &&
                                                  eachZone.show_card_inputs
                                              ).length > 0
                                            );
                                          });
                                        const findAvailableCheckedEvents =
                                          findAvailableEvents.filter(
                                            (eachEvent) => {
                                              return (
                                                eachEvent.zones.filter(
                                                  (eachZone) =>
                                                    eachZone.fake_header_zone_id ===
                                                      findHeader.fake_id &&
                                                    eachZone.is_checked_create_purchase_line &&
                                                    eachZone.show_card_inputs
                                                ).length > 0
                                              );
                                            }
                                          );
                                        if (
                                          !findAvailableCheckedEvents.length
                                        ) {
                                          findHeader.is_checked_all_zone_section_column =
                                            false;
                                          findHeader.is_indeterminate_zone_section_column =
                                            false;
                                        } else if (
                                          findAvailableEvents.length ===
                                          findAvailableCheckedEvents.length
                                        ) {
                                          findHeader.is_checked_all_zone_section_column =
                                            true;
                                          findHeader.is_indeterminate_zone_section_column =
                                            false;
                                        } else {
                                          findHeader.is_checked_all_zone_section_column =
                                            false;
                                          findHeader.is_indeterminate_zone_section_column =
                                            true;
                                        }
                                      }

                                      setZoneHeaders(_headers);

                                      /** Find purchase line checked or not */
                                      const _checkUncheckAllRowColumn = {
                                        ...checkUncheckAllRowColumn,
                                      };
                                      if (
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_checked_all_purchase_line
                                        ).length === _eventsData.events.length
                                      ) {
                                        _checkUncheckAllRowColumn.checked =
                                          true;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          false;
                                      } else if (
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_checked_all_purchase_line
                                        ).length ||
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_indeterminate_checked_purchase_line
                                        ).length
                                      ) {
                                        _checkUncheckAllRowColumn.checked =
                                          false;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          true;
                                      } else {
                                        _checkUncheckAllRowColumn.checked =
                                          false;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          false;
                                      }

                                      setCheckUncheckAllRowColumn(
                                        _checkUncheckAllRowColumn
                                      );
                                    }}
                                  ></Checkbox>
                                  <div>
                                    {eachHead.zone_section}
                                    <br />
                                    <span>{eachHead.rows}</span>
                                  </div>
                                </div>
                              </th>
                            );
                          })}
                          <th></th>
                        </tr>
                        <tr className="setPricingTblTopRow">
                          <th
                            className="colLeftSticky"
                            style={{ left: 0, top: 60 }}
                          ></th>
                          <th
                            className="colLeftSticky"
                            style={{ left: 40, top: 60 }}
                          >
                            Row
                          </th>
                          <th
                            className="colLeftSticky colLeftStickyLast"
                            style={{ left: 168, top: 60 }}
                          ></th>
                          {getSortedZoneHeaders().map((eachHead) => {
                            return (
                              <th
                                key={eachHead.listing_rows_field_name}
                                style={{ top: 60 }}
                                className="removeErrorDiv"
                              >
                                <Form.Item
                                  name={eachHead.listing_rows_field_name}
                                  rules={[
                                    () => ({
                                      validator(_, value) {
                                        let isAnyCardTryToCreatePurchaseLine =
                                          false;
                                        eventsData.events.every((eachData) => {
                                          const findIfCurrentHeaderAnyTryToCreatePurchaseLine =
                                            eachData.zones.find((_zoneData) => {
                                              return (
                                                _zoneData.fake_header_zone_id ===
                                                  eachHead.fake_id &&
                                                _zoneData.is_checked_create_purchase_line
                                              );
                                            });

                                          if (
                                            findIfCurrentHeaderAnyTryToCreatePurchaseLine
                                          ) {
                                            isAnyCardTryToCreatePurchaseLine =
                                              true;
                                            return false;
                                          }
                                          return true;
                                        });

                                        if (isAnyCardTryToCreatePurchaseLine) {
                                          if (!value || value.trim() === "") {
                                            return Promise.reject(
                                              "Listing rows is required"
                                            );
                                          } else {
                                            const duplicateZoneRowsFilter =
                                              zoneHeaders.filter(
                                                (HData) =>
                                                  HData.zone_section.toLocaleUpperCase() ===
                                                    eachHead.zone_section.toLocaleUpperCase() &&
                                                  HData.listing_rows.toLocaleUpperCase() ===
                                                    value.toLocaleUpperCase() &&
                                                  HData.fake_id !==
                                                    eachHead.fake_id
                                              );

                                            const duplicateZoneRowsFakeIds =
                                              duplicateZoneRowsFilter.map(
                                                (eachRowData) =>
                                                  eachRowData.fake_id
                                              );
                                            let hasInAnother = false;
                                            eventsData.events.every(
                                              (_eachData) => {
                                                const checking =
                                                  _eachData.zones.find(
                                                    (_zoneData) => {
                                                      return (
                                                        _zoneData.fake_header_zone_id &&
                                                        duplicateZoneRowsFakeIds.includes(
                                                          _zoneData.fake_header_zone_id
                                                        ) &&
                                                        _zoneData.is_checked_create_purchase_line
                                                      );
                                                    }
                                                  );

                                                if (checking) {
                                                  hasInAnother = true;
                                                  return false;
                                                }
                                                return true;
                                              }
                                            );

                                            if (
                                              duplicateZoneRowsFilter.length >
                                                0 &&
                                              hasInAnother
                                            ) {
                                              return Promise.reject(
                                                "Zone and row combination must be unique!"
                                              );
                                            }
                                          }
                                        }

                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Input
                                    onChange={(event) => {
                                      const _zoneHeaders = [...zoneHeaders];
                                      const _eventsData = { ...eventsData };
                                      const _events = _eventsData.events;

                                      const findHeader = _zoneHeaders.find(
                                        (_eachHeader) =>
                                          _eachHeader.fake_id ===
                                          eachHead.fake_id
                                      );
                                      if (findHeader) {
                                        findHeader.listing_rows =
                                          event.target.value;

                                        const findAvailableEventIds = _events
                                          .filter((eachEvent) => {
                                            return (
                                              eachEvent.zones.filter(
                                                (eachZone) =>
                                                  eachZone.fake_header_zone_id ===
                                                    eachHead.fake_id &&
                                                  eachZone.show_card_inputs
                                              ).length > 0
                                            );
                                          })
                                          .map((each) => each.event_id);

                                        if (findAvailableEventIds.length) {
                                          findAvailableEventIds.forEach(
                                            (eachEventId) => {
                                              const findEvent = _events.find(
                                                (eachEvent) =>
                                                  eachEvent.event_id ===
                                                  eachEventId
                                              );
                                              if (findEvent) {
                                                findEvent.zones.forEach(
                                                  (eachZone) => {
                                                    if (
                                                      eachZone.fake_header_zone_id ===
                                                        eachHead.fake_id &&
                                                      eachZone.show_card_inputs
                                                    ) {
                                                      eachZone.has_zone_listings =
                                                        false;
                                                      eachZone.is_disabled_create_purchase_line =
                                                        false;

                                                      if (
                                                        findEvent.list_inventory?.find(
                                                          (each) =>
                                                            each.section.toLocaleUpperCase() ===
                                                              findHeader.zone_section.toLocaleUpperCase() &&
                                                            each.row.toLocaleUpperCase() ===
                                                              findHeader.listing_rows.toLocaleUpperCase()
                                                        )
                                                      ) {
                                                        eachZone.has_zone_listings =
                                                          true;
                                                        if (
                                                          doUncheckCellsWithExistingPlacements
                                                        ) {
                                                          eachZone.is_checked_create_purchase_line =
                                                            false;
                                                          eachZone.is_disabled_create_purchase_line =
                                                            true;
                                                        }
                                                      }
                                                    }
                                                  }
                                                );
                                              }
                                            }
                                          );
                                        }

                                        const findAvailableEvents =
                                          _events.filter((eachEvent) => {
                                            return (
                                              eachEvent.zones.filter(
                                                (eachZone) =>
                                                  eachZone.fake_header_zone_id ===
                                                    findHeader.fake_id &&
                                                  eachZone.show_card_inputs
                                              ).length > 0
                                            );
                                          });

                                        const findAvailableCheckedEvents =
                                          findAvailableEvents.filter(
                                            (eachEvent) => {
                                              return (
                                                eachEvent.zones.filter(
                                                  (eachZone) =>
                                                    eachZone.fake_header_zone_id ===
                                                      findHeader.fake_id &&
                                                    eachZone.is_checked_create_purchase_line &&
                                                    eachZone.show_card_inputs
                                                ).length > 0
                                              );
                                            }
                                          );

                                        if (
                                          !findAvailableCheckedEvents.length
                                        ) {
                                          findHeader.is_checked_all_zone_section_column =
                                            false;
                                          findHeader.is_indeterminate_zone_section_column =
                                            false;
                                        } else if (
                                          findAvailableEvents.length ===
                                          findAvailableCheckedEvents.length
                                        ) {
                                          findHeader.is_checked_all_zone_section_column =
                                            true;
                                          findHeader.is_indeterminate_zone_section_column =
                                            false;
                                        } else {
                                          findHeader.is_checked_all_zone_section_column =
                                            false;
                                          findHeader.is_indeterminate_zone_section_column =
                                            true;
                                        }
                                      }

                                      //setZoneHeaders(_zoneHeaders);
                                      if (newRowInputTimer) {
                                        clearTimeout(newRowInputTimer);
                                      }

                                      newRowInputTimer = setTimeout(() => {
                                        setZoneHeaders(_zoneHeaders);
                                        setEventsData(_eventsData);
                                      }, 800);
                                    }}
                                    disabled={doDisabledAllInputsAndCtas}
                                  />
                                </Form.Item>
                              </th>
                            );
                          })}
                          <th style={{ top: 60 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {getSortedEvents().map((eachEvent) => {
                          return (
                            <tr
                              key={"event-" + eachEvent.event_id}
                              style={{ minWidth: 180 }}
                            >
                              <td
                                className="colLeftSticky"
                                style={{ left: 0, minWidth: 40 }}
                              >
                                <Checkbox
                                  className="selectAllEventPurchaseLine"
                                  disabled={
                                    eachEvent.is_disabled_purchase ||
                                    doDisabledAllInputsAndCtas
                                  }
                                  indeterminate={
                                    eachEvent.is_indeterminate_checked_purchase_line
                                  }
                                  checked={
                                    eachEvent.is_checked_all_purchase_line
                                  }
                                  onClick={() => {
                                    /** Update events */
                                    const _eventsData = { ...eventsData };
                                    const _events = _eventsData.events;
                                    const findCurrentEvent = _events.find(
                                      (currentEachEvent) =>
                                        currentEachEvent.event_id ===
                                        eachEvent.event_id
                                    );
                                    if (findCurrentEvent) {
                                      findCurrentEvent?.zones
                                        .filter(
                                          (_eachZone) =>
                                            _eachZone.show_card_inputs
                                        )
                                        .forEach((eachFilteredZone) => {
                                          if (
                                            !eachFilteredZone.is_disabled_create_purchase_line
                                          ) {
                                            if (
                                              findCurrentEvent.is_indeterminate_checked_purchase_line
                                            ) {
                                              eachFilteredZone.is_checked_create_purchase_line =
                                                false;
                                            } else if (
                                              findCurrentEvent.is_checked_all_purchase_line
                                            ) {
                                              eachFilteredZone.is_checked_create_purchase_line =
                                                false;
                                            } else {
                                              eachFilteredZone.is_checked_create_purchase_line =
                                                true;
                                            }
                                          }
                                        });

                                      const totalValidCardCount =
                                        findCurrentEvent?.zones.filter(
                                          (_eachZone) =>
                                            _eachZone.show_card_inputs
                                        ).length;
                                      const totalCheckedCardCount =
                                        findCurrentEvent?.zones.filter(
                                          (_eachZone) =>
                                            _eachZone.is_checked_create_purchase_line
                                        ).length;

                                      if (!totalCheckedCardCount) {
                                        findCurrentEvent.is_checked_all_purchase_line =
                                          false;
                                        findCurrentEvent.is_indeterminate_checked_purchase_line =
                                          false;
                                      } else if (
                                        totalValidCardCount ===
                                        totalCheckedCardCount
                                      ) {
                                        findCurrentEvent.is_checked_all_purchase_line =
                                          true;
                                        findCurrentEvent.is_indeterminate_checked_purchase_line =
                                          false;
                                      } else if (
                                        totalValidCardCount !==
                                        totalCheckedCardCount
                                      ) {
                                        findCurrentEvent.is_checked_all_purchase_line =
                                          false;
                                        findCurrentEvent.is_indeterminate_checked_purchase_line =
                                          true;
                                      }
                                    }
                                    setEventsData(_eventsData);

                                    /** Update Headers */
                                    if (findCurrentEvent) {
                                      const _headers = [...zoneHeaders];

                                      _headers.forEach((eachItemHeader) => {
                                        if (
                                          eachItemHeader.available_event_count >
                                          0
                                        ) {
                                          const findAvailableEvents =
                                            _events.filter((eachEvent) => {
                                              return (
                                                eachEvent.zones.filter(
                                                  (eachZone) =>
                                                    eachZone.fake_header_zone_id ===
                                                      eachItemHeader.fake_id &&
                                                    eachZone.show_card_inputs
                                                ).length > 0
                                              );
                                            });

                                          const findAvailableCheckedEvents =
                                            findAvailableEvents.filter(
                                              (eachEvent) => {
                                                return (
                                                  eachEvent.zones.filter(
                                                    (eachZone) =>
                                                      eachZone.fake_header_zone_id ===
                                                        eachItemHeader.fake_id &&
                                                      eachZone.is_checked_create_purchase_line &&
                                                      eachZone.show_card_inputs
                                                  ).length > 0
                                                );
                                              }
                                            );

                                          if (
                                            !findAvailableCheckedEvents.length
                                          ) {
                                            eachItemHeader.is_checked_all_zone_section_column =
                                              false;
                                            eachItemHeader.is_indeterminate_zone_section_column =
                                              false;
                                          } else if (
                                            findAvailableEvents.length ===
                                            findAvailableCheckedEvents.length
                                          ) {
                                            eachItemHeader.is_checked_all_zone_section_column =
                                              true;
                                            eachItemHeader.is_indeterminate_zone_section_column =
                                              false;
                                          } else {
                                            eachItemHeader.is_checked_all_zone_section_column =
                                              false;
                                            eachItemHeader.is_indeterminate_zone_section_column =
                                              true;
                                          }
                                        }
                                      });

                                      setZoneHeaders(_headers);

                                      /** Find purchase line checked or not */
                                      const _checkUncheckAllRowColumn = {
                                        ...checkUncheckAllRowColumn,
                                      };
                                      if (
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_checked_all_purchase_line
                                        ).length === _eventsData.events.length
                                      ) {
                                        _checkUncheckAllRowColumn.checked =
                                          true;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          false;
                                      } else if (
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_checked_all_purchase_line
                                        ).length ||
                                        _eventsData.events.filter(
                                          (each) =>
                                            each.is_indeterminate_checked_purchase_line
                                        ).length
                                      ) {
                                        _checkUncheckAllRowColumn.checked =
                                          false;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          true;
                                      } else {
                                        _checkUncheckAllRowColumn.checked =
                                          false;
                                        _checkUncheckAllRowColumn.indeterminateChecked =
                                          false;
                                      }

                                      setCheckUncheckAllRowColumn(
                                        _checkUncheckAllRowColumn
                                      );
                                    }
                                  }}
                                ></Checkbox>
                              </td>
                              <td
                                className="colLeftSticky"
                                style={{ left: 40, minWidth: 128 }}
                              >
                                {formatDateGivenFormatString(
                                  eachEvent.event_date,
                                  "ddd, MMM DD, YYYY h:mm A"
                                )}
                                {/* {eventMetadata.venue_id && (
                                  <BulkSecondaryMarketplacePerEvent
                                    event={eachEvent}
                                    venue_id={eventMetadata.venue_id}
                                  />
                                )} */}
                                {eachEvent.purchased_id && (
                                  <React.Fragment>
                                    <span className="errorSuccessIcon">
                                      <i className="da icon-tick-circle"></i>
                                      <a
                                        href={
                                          AppSetting.PURCHASE_ID_URL +
                                          eachEvent.purchased_id
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        PO# {eachEvent.purchased_id}
                                      </a>
                                    </span>
                                  </React.Fragment>
                                )}
                                {eachEvent.is_success === false && (
                                  <BulkPlacementErrorMessageModal
                                    errors={
                                      eachEvent.errors ? eachEvent.errors : []
                                    }
                                  />
                                )}
                                {currentSubmittingEventId ===
                                  eachEvent.event_id &&
                                  eachEvent.is_success === undefined && (
                                    <React.Fragment>
                                      <span className="smallSpin">
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              style={{ fontSize: 12 }}
                                              spin
                                            />
                                          }
                                        />
                                      </span>
                                    </React.Fragment>
                                  )}
                              </td>
                              <td
                                className="colLeftSticky tblTextStyle4 text-right colLeftStickyLast"
                                style={{
                                  left: 168,
                                  minWidth: 90,
                                  cursor: "default",
                                }}
                              >
                                <div
                                  style={{
                                    color: daysToEventColorCheck(
                                      eachEvent.days_to_event,
                                      "list"
                                    ).textColor,
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      daysToEventColorCheck(
                                        eachEvent.days_to_event,
                                        "list"
                                      ).toolTipText
                                    }
                                    placement="left"
                                  >
                                    {eachEvent.days_to_event}
                                  </Tooltip>
                                </div>
                              </td>
                              {getSortedZoneHeaders().map((eachHead) => {
                                let findMatchedZone: any;
                                let findMatchedZoneCost: number;
                                let findMatchedZoneMinMarkupValue: number;
                                let isCheckedCreatePurchaseLine = false;
                                let availabilityText = "";
                                if (eachEvent.zones && eachEvent.zones.length) {
                                  findMatchedZone = eachEvent.zones.find(
                                    (eachZone) => {
                                      return (
                                        eachZone.fake_header_zone_id ===
                                        eachHead.fake_id
                                      );
                                    }
                                  );
                                  if (findMatchedZone) {
                                    findMatchedZoneCost = findMatchedZone.cost;
                                    findMatchedZoneMinMarkupValue =
                                      findMatchedZone.min_markup_value;
                                    if (
                                      findMatchedZone?.is_web_scraping === 1 &&
                                      findMatchedZone?.available_seats
                                    ) {
                                      availabilityText = `${findMatchedZone?.available_seats} / ${findMatchedZone?.total_seats}`;
                                    } else {
                                      availabilityText =
                                        zoneAvailabilityMaster.find(
                                          (eachAvailable) =>
                                            eachAvailable.value ===
                                            findMatchedZone?.zone_availability_id
                                        )?.label ?? "";
                                    }
                                  }
                                  if (
                                    findMatchedZone &&
                                    findMatchedZone.is_checked_create_purchase_line
                                  ) {
                                    isCheckedCreatePurchaseLine = true;
                                  }
                                }
                                return (
                                  <td
                                    key={
                                      eachEvent.event_id +
                                      eachHead.zone_section +
                                      eachHead.rows
                                    }
                                  >
                                    {findMatchedZone &&
                                      findMatchedZone.show_purchase_line_card && (
                                        <div className={"pricingCard"}>
                                          <div
                                            className={
                                              "pricingCardBody flx flexColumn " +
                                              findMatchedZone.availability_html_style_class_name +
                                              " " +
                                              findMatchedZone.no_data_input_html_style_class_name
                                            }
                                          >
                                            {findMatchedZone.show_card_inputs && (
                                              <React.Fragment>
                                                <div className="flx flexColumn pricingCardBodyInner">
                                                  <div className="flx alignItemsCenter  justifyContentBetween w-100">
                                                    <div className="flx alignItemsCenter">
                                                      <Checkbox
                                                        checked={
                                                          findMatchedZone.is_checked_create_purchase_line
                                                        }
                                                        disabled={
                                                          findMatchedZone.is_disabled_create_purchase_line ||
                                                          doDisabledAllInputsAndCtas
                                                        }
                                                        onClick={() => {
                                                          /** Update Events */
                                                          const _eventsData = {
                                                            ...eventsData,
                                                          };
                                                          const _events =
                                                            _eventsData.events;
                                                          const findCurrentEvent =
                                                            _events.find(
                                                              (
                                                                currentEachEvent
                                                              ) =>
                                                                currentEachEvent.event_id ===
                                                                eachEvent.event_id
                                                            );
                                                          if (
                                                            findCurrentEvent
                                                          ) {
                                                            const findCurrentZone =
                                                              findCurrentEvent.zones.find(
                                                                (
                                                                  currentEacZone
                                                                ) =>
                                                                  currentEacZone.fake_header_zone_id ===
                                                                  eachHead.fake_id
                                                              );
                                                            if (
                                                              findCurrentZone
                                                            ) {
                                                              findCurrentZone.is_checked_create_purchase_line =
                                                                !findCurrentZone.is_checked_create_purchase_line;
                                                            }
                                                            const totalValidCardCount =
                                                              findCurrentEvent?.zones.filter(
                                                                (_eachZone) =>
                                                                  _eachZone.show_card_inputs
                                                              ).length;
                                                            const totalCheckedCardCount =
                                                              findCurrentEvent?.zones.filter(
                                                                (_eachZone) =>
                                                                  _eachZone.is_checked_create_purchase_line
                                                              ).length;

                                                            if (
                                                              !totalCheckedCardCount
                                                            ) {
                                                              findCurrentEvent.is_checked_all_purchase_line =
                                                                false;
                                                              findCurrentEvent.is_indeterminate_checked_purchase_line =
                                                                false;
                                                            } else if (
                                                              totalValidCardCount ===
                                                              totalCheckedCardCount
                                                            ) {
                                                              findCurrentEvent.is_checked_all_purchase_line =
                                                                true;
                                                              findCurrentEvent.is_indeterminate_checked_purchase_line =
                                                                false;
                                                            } else if (
                                                              totalValidCardCount !==
                                                              totalCheckedCardCount
                                                            ) {
                                                              findCurrentEvent.is_checked_all_purchase_line =
                                                                false;
                                                              findCurrentEvent.is_indeterminate_checked_purchase_line =
                                                                true;
                                                            }
                                                          }
                                                          setEventsData(
                                                            _eventsData
                                                          );

                                                          /** Update Headers */
                                                          if (
                                                            findCurrentEvent
                                                          ) {
                                                            const _headers = [
                                                              ...zoneHeaders,
                                                            ];
                                                            const findHeader =
                                                              _headers.find(
                                                                (each) =>
                                                                  each.fake_id ===
                                                                  eachHead.fake_id
                                                              );
                                                            if (findHeader) {
                                                              const findAvailableEvents =
                                                                _events.filter(
                                                                  (
                                                                    eachEvent
                                                                  ) => {
                                                                    return (
                                                                      eachEvent.zones.filter(
                                                                        (
                                                                          eachZone
                                                                        ) =>
                                                                          eachZone.fake_header_zone_id ===
                                                                            findHeader.fake_id &&
                                                                          eachZone.show_card_inputs
                                                                      ).length >
                                                                      0
                                                                    );
                                                                  }
                                                                );

                                                              const findAvailableCheckedEvents =
                                                                findAvailableEvents.filter(
                                                                  (
                                                                    eachEvent
                                                                  ) => {
                                                                    return (
                                                                      eachEvent.zones.filter(
                                                                        (
                                                                          eachZone
                                                                        ) =>
                                                                          eachZone.fake_header_zone_id ===
                                                                            findHeader.fake_id &&
                                                                          eachZone.is_checked_create_purchase_line &&
                                                                          eachZone.show_card_inputs
                                                                      ).length >
                                                                      0
                                                                    );
                                                                  }
                                                                );

                                                              if (
                                                                !findAvailableCheckedEvents.length
                                                              ) {
                                                                findHeader.is_checked_all_zone_section_column =
                                                                  false;
                                                                findHeader.is_indeterminate_zone_section_column =
                                                                  false;
                                                              } else if (
                                                                findAvailableEvents.length ===
                                                                findAvailableCheckedEvents.length
                                                              ) {
                                                                findHeader.is_checked_all_zone_section_column =
                                                                  true;
                                                                findHeader.is_indeterminate_zone_section_column =
                                                                  false;
                                                              } else {
                                                                findHeader.is_checked_all_zone_section_column =
                                                                  false;
                                                                findHeader.is_indeterminate_zone_section_column =
                                                                  true;
                                                              }
                                                            }

                                                            setZoneHeaders(
                                                              _headers
                                                            );

                                                            /** Find purchase line checked or not */
                                                            const _checkUncheckAllRowColumn =
                                                              {
                                                                ...checkUncheckAllRowColumn,
                                                              };
                                                            if (
                                                              _eventsData.events.filter(
                                                                (each) =>
                                                                  each.is_checked_all_purchase_line
                                                              ).length ===
                                                              _eventsData.events
                                                                .length
                                                            ) {
                                                              _checkUncheckAllRowColumn.checked =
                                                                true;
                                                              _checkUncheckAllRowColumn.indeterminateChecked =
                                                                false;
                                                            } else if (
                                                              _eventsData.events.filter(
                                                                (each) =>
                                                                  each.is_checked_all_purchase_line
                                                              ).length ||
                                                              _eventsData.events.filter(
                                                                (each) =>
                                                                  each.is_indeterminate_checked_purchase_line
                                                              ).length
                                                            ) {
                                                              _checkUncheckAllRowColumn.checked =
                                                                false;
                                                              _checkUncheckAllRowColumn.indeterminateChecked =
                                                                true;
                                                            } else {
                                                              _checkUncheckAllRowColumn.checked =
                                                                false;
                                                              _checkUncheckAllRowColumn.indeterminateChecked =
                                                                false;
                                                            }

                                                            setCheckUncheckAllRowColumn(
                                                              _checkUncheckAllRowColumn
                                                            );
                                                          }
                                                        }}
                                                      ></Checkbox>

                                                      {findMatchedZone.has_zone_listings && (
                                                        <Tooltip title="There Are Existing Listing For The Show">
                                                          <i className="da icon-exclamation-warning ml-10"></i>
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                    <div className="InputNumWidth">
                                                      <div className="bulkPlacementFormGroup removeErrorDiv">
                                                        {findMatchedZone.listing_price_field_name && (
                                                          <Form.Item
                                                            name={
                                                              findMatchedZone.listing_price_field_name
                                                            }
                                                            rules={[
                                                              () => ({
                                                                validator(
                                                                  _,
                                                                  value
                                                                ) {
                                                                  let _value = 0;
                                                                  if (
                                                                    value &&
                                                                    value !== ""
                                                                  ) {
                                                                    _value =
                                                                      value;
                                                                    if (
                                                                      doRoundOffValuesToZeroDecimals
                                                                    ) {
                                                                      _value =
                                                                        Math.round(
                                                                          _value
                                                                        );
                                                                    }
                                                                  }
                                                                  if (
                                                                    isCheckedCreatePurchaseLine &&
                                                                    (!value ||
                                                                      value ===
                                                                        "" ||
                                                                      findMatchedZoneCost ===
                                                                        undefined ||
                                                                      _value <=
                                                                        findMatchedZoneCost ||
                                                                      _value <
                                                                        findMatchedZoneMinMarkupValue)
                                                                  ) {
                                                                    return Promise.reject(
                                                                      "Listing Price cannot be less than unit ticket cost"
                                                                    );
                                                                  }
                                                                  return Promise.resolve();
                                                                },
                                                              }),
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              precision={
                                                                doRoundOffValuesToZeroDecimals
                                                                  ? 0
                                                                  : 2
                                                              }
                                                              min={0}
                                                              controls={{
                                                                upIcon: (
                                                                  <span className="da icon-plus-square"></span>
                                                                ),
                                                                downIcon: (
                                                                  <span className="da icon-minus-square"></span>
                                                                ),
                                                              }}
                                                              prefix="$"
                                                              bordered={false}
                                                              className="inputBorderBottom py-0"
                                                              disabled={
                                                                !findMatchedZone.is_checked_create_purchase_line ||
                                                                doDisabledAllInputsAndCtas
                                                              }
                                                            />
                                                          </Form.Item>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <BulkSecondaryMarketplacePriceRangePerEvent
                                                  event={eachEvent}
                                                  zoneSectionAliasDictionary={
                                                    eachHead.zone_section_alias_dictionary
                                                  }
                                                /> */}
                                              </React.Fragment>
                                            )}
                                          </div>
                                          <div className="pricingCardFooter">
                                            <span>{availabilityText}</span>
                                            <div className="flx alignItemsCenter">
                                              {renderSecondaryCost(
                                                findMatchedZone.secondary_cost
                                              )}
                                              <span>
                                                {findMatchedZone.cost
                                                  ? "$" +
                                                    formatAccountingValues(
                                                      findMatchedZone.cost
                                                    )
                                                  : "Cost: N/A"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </td>
                                );
                              })}
                              <td></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </React.Fragment>
      )}
    </LoadingSpinner>
  );
};

export default BulkPlacementSetPricingForm;
