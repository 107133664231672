import { Tooltip } from "antd";
import { useAppDispatch } from "app/hooks";
import { toggleFilterDrawer } from "models/Slices/GlobalSlice";
import { Link } from "react-router-dom";
import resetButtonImage from "../../assets/images/reset-icon.svg";

const booleanRepresentation = {
  true: "Yes",
  false: "No",
};
const AppliedFilters: React.FC<{
  appliedFilters: any;
  resetUrl?: string | null;
}> = ({ appliedFilters, resetUrl }) => {
  const dispatch = useAppDispatch();

  const openFilterDrawer = () => {
    dispatch(toggleFilterDrawer(true));
  };

  const getFilterData = () => {
    return appliedFilters.filter(
      (data: any) =>
        (data.field_id &&
        !(
          data.field_id.includes("date_to") ||
          data.field_id.includes("date_from")
        ))
    );
  };

  const getOnlyViewableFilterData = () => {
    const filteredData = getFilterData().filter((eachData: any) => eachData.field_value);
    const field_ids = filteredData.map((o:any) => o.field_id);
    return filteredData.filter((eachData: any, index: number) => !field_ids.includes(eachData.field_id, index + 1));
  };

  const getTitle = (title: any) => {
    return title ? title.label : "No Data";
  };

  const getViewByFilterValue = (filter: any) => {
    if (filter.field_value.length > 1) {
      return (
        <Tooltip
          overlayInnerStyle={{ textTransform: "capitalize" }}
          title={filter.field_value.map((e: any) => `${e.label}`).join(", ")}
        >
          {`Multiple ${filter.field_name}`}
        </Tooltip>
      );
    } else if (filter.field_value.length === 1) {
      return filter.field_value[0].label.length > 30 ? (
        <Tooltip
          overlayInnerStyle={{ textTransform: "capitalize" }}
          title={getTitle(filter.field_value[0])}
        >
          {filter.field_value[0].label.slice(0, 30).trim()}...
        </Tooltip>
      ) : (
        filter.field_value[0].label
      );
    }
    return null;
  };

  const getViewByFilterType = (filter: { field_value: any; }) => {
    if (typeof filter.field_value == "object") {
      return getViewByFilterValue(filter);
    }
    return typeof filter.field_value == "boolean" ? (
      booleanRepresentation[`${filter.field_value}`]
    ) : (
      <>{filter.field_value}</>
    );
  };
  const sliceSize = window.innerWidth > 1500 ? 4 : 3;
  return (
    <div className="filters2 flx alignItemsCenter">
      {appliedFilters &&
        getOnlyViewableFilterData()
          .slice(0, sliceSize)
          .map((filter: any) => (
            <span
              className="ant-tag info"
              key={filter.field_id}
              style={{ cursor: "default" }}
            >
              <b>{filter.field_name}: </b>
              <br />
              {getViewByFilterType(filter)}
            </span>
          ))}
      {appliedFilters && getOnlyViewableFilterData().length > sliceSize && (
        <span
          className="moreLink"
          style={{ cursor: "pointer" }}
          onClick={openFilterDrawer}
        >
          More
        </span>
      )}
      {resetUrl && (
        <Tooltip title="Reset">
          <Link
            to={resetUrl}
            className="resetCard"
            style={{
              position: "relative",
              cursor: "pointer",
              background: `url(${resetButtonImage}) no-repeat center center / 15px`,
              backgroundColor: "transparent",
              right: "0px",
              top: "0px",
            }}
          >
            <i />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

export default AppliedFilters;
