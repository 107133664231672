import { Col, Layout, Pagination, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import CustomError from "controls/CustomError/CustomError";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import {
  EventsWithActiveZoneListingsQueryParams,
  IEventsWithActiveZoneListingsResponse,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { formatThousandSeparator } from "utils/commonFunction";
import { getDateDiff } from "utils/formatTimePeriod";
import AllZoneListingGridView from "./AllZoneListingGridView";
import AllZoneListingListView from "./AllZoneListingListView";

interface IAllZoneListingsComponent {
  urlParams: any | null;
  setSearchText: Function;
  searchText: string | null;
  queryParams: EventsWithActiveZoneListingsQueryParams;
  filtersAppliedWithValues: any | null;
  setQueryParams: Function;
  getHeightOfAllZoneListings: Function;
  isWidgetFilterParams?: boolean;
  viewMode: string;
  allZoneListings: IEventsWithActiveZoneListingsResponse | undefined;
  setAllZoneListings: Function;
  purchaseLineId: string | null;
  setIsUnbroadcastFilteredEnabled: Function;
};

const AllZoneListingsComponent: React.FC<IAllZoneListingsComponent> = ({
  urlParams,
  setSearchText,
  searchText,
  queryParams,
  filtersAppliedWithValues,
  setQueryParams,
  getHeightOfAllZoneListings,
  isWidgetFilterParams,
  viewMode,
  allZoneListings,
  setAllZoneListings,
  purchaseLineId,
  setIsUnbroadcastFilteredEnabled,
}) => {
  const [allZoneListingsFetchError, setAllZoneListingsFetchError] =
    useState<ICustomError | null>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const getAllZoneListings = async (
    filters: EventsWithActiveZoneListingsQueryParams
  ) => {
    setIsUnbroadcastFilteredEnabled(false);
    try {
      const diff = getDateDiff( filters.event_date_to, filters.event_date_from )??-1;
      setIsLoadingData(true);
      const response = await new ZonesService().getEventsWithActiveZoneListings(
        {
          ...filters,
        }
      );
      setAllZoneListings(response.data.data);
      const broadcasted_listings = response.data.data.broadcasted_po_lines;
      setIsLoadingData(false);
      if (  diff >= 0 && diff <= 10 && broadcasted_listings != null &&  broadcasted_listings?.length > 0 ) {
        setIsUnbroadcastFilteredEnabled(true);
      }
      setAllZoneListingsFetchError(null);
    } catch (error: any) {
      setIsLoadingData(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getAllZoneListings",
      });
      setAllZoneListingsFetchError({
        ...error,
        refreshAction: getAllZoneListings,
      });
    }
  };

  useEffect(() => {
    if (queryParams.event_date_from) {
      getAllZoneListings(queryParams);
    }
    setSearchText(queryParams.search_text ?? null);
  }, [queryParams]);

  const setPageNumber = useCallback((page: number) => {
    setQueryParams((prev: EventsWithActiveZoneListingsQueryParams) => ({
      ...prev,
      page_index: page,
    }));
  },[queryParams]);

  const getAppliedFilterNames = (id: any) => {
    switch (id) {
      case "name":
        return "Broker";
      case "title":
        return "Risk Type";
    }
  };

  const getFiltersList = (filters: Object) => {
    let filtersList = [];
    for (const property in filters) {
      filtersList.push({
        field_id: property,
        field_name: getAppliedFilterNames(property),
        field_value: filters[property],
      });
    }
    filtersList = filtersAppliedWithValues
      ? [...filtersList, ...filtersAppliedWithValues]
      : [...filtersList];
    return filtersList.filter(
      (data: any) =>
        !(
          data.field_id.includes("risk_type") ||
          data.field_id.includes("users") ||
          data.field_id.includes("search_text") ||
          data.field_id.includes("page_size") ||
          data.field_id.includes("page_index") ||
          data.field_id.includes("sort_order") ||
          data.field_id.includes("sort_column") ||
          data.field_id.includes("date_to") ||
          data.field_id.includes("date_from") ||
          data.field_id.includes("event_id") ||
          data.field_id.includes("purchase_id") ||
          data.field_id.includes("purchase_line_id")
        )
    );
  };

  return (
    <React.Fragment>
      {allZoneListingsFetchError ? (
        <CustomError {...allZoneListingsFetchError}></CustomError>
      ) : (
        <Layout className="mainInnerLayout">
          <Content className="mainContainer zones">
            <div className="innerHeader">
              <Row className="flx justifyContentBetween alignItemsCenter">
                <Col xl={12} xxl={16}>
                  <AppliedFilters
                    appliedFilters={getFiltersList(urlParams)}
                    resetUrl={
                      isWidgetFilterParams
                        ? LeftNavRoutesEnum.ZONES_LISTING
                        : null
                    }
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  {Boolean(
                    !isLoadingData &&
                      allZoneListings?.count &&
                      allZoneListings?.count > 0
                  ) && (
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>
                          {allZoneListings?.count &&
                            allZoneListings?.count === 1 && (
                              <React.Fragment>
                                {`${formatThousandSeparator(
                                  allZoneListings?.count
                                )} Record Found`}
                              </React.Fragment>
                            )}
                          {allZoneListings?.count &&
                            allZoneListings?.count > 1 && (
                              <React.Fragment>
                                {`${formatThousandSeparator(
                                  allZoneListings?.count
                                )} Records Found`}
                              </React.Fragment>
                            )}{" "}
                        </h4>
                      </li>
                      <li>
                        {!isMobile && (
                          <Pagination
                            className="pagination"
                            hideOnSinglePage={true}
                            defaultCurrent={1}
                            current={queryParams?.page_index}
                            total={allZoneListings?.count}
                            pageSize={AppSetting.PAGE_SIZE}
                            onChange={setPageNumber}
                            showSizeChanger={false}
                            responsive={true}
                          />
                        )}
                        {isMobile && (
                          <Pagination
                            simple
                            size="small"
                            hideOnSinglePage={true}
                            defaultCurrent={1}
                            current={queryParams?.page_index}
                            total={allZoneListings?.count}
                            pageSize={AppSetting.PAGE_SIZE}
                            onChange={setPageNumber}
                          />
                        )}
                      </li>
                    </ul>
                  )}
                  {!(
                    !isLoadingData &&
                    allZoneListings?.count &&
                    allZoneListings?.count > 0
                  ) && <div style={{ height: 21 }}></div>}
                </Col>
              </Row>
            </div>
            {viewMode === "gridView" && (
              <AllZoneListingGridView
                getHeightOfAllZoneListings={getHeightOfAllZoneListings}
                isLoadingData={isLoadingData}
                listings={allZoneListings}
              />
            )}
            {viewMode === "listView" && (
              <AllZoneListingListView
                isLoadingData={isLoadingData}
                allZoneListings={allZoneListings}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setAllZoneListings={setAllZoneListings}
                searchText={searchText}
                urlParams={urlParams}
                purchaseLineId={purchaseLineId}
              />
            )}
          </Content>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default AllZoneListingsComponent;
