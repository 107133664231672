import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IBulkAssignBodyData, IVenueListData, IVenueListQueryParams } from "models/interfaces";
import { VenueService } from "services";
import { LoggerService } from "sharedServices";

let initialState: {
  venueListData: IVenueListData;
  queryParams: IVenueListQueryParams;
  venuesToBeAssigned: number[];
  isLoadingVenues: boolean;
  isAssigningVenues: boolean;
  hasError: boolean;
  dataFetchError: any;
} = {
  venueListData: {
    venueDetailsData: [],
    total: 0,
    tooltip: []
  },
  queryParams: {
    search_text: "",
    sort_column: "venueName",
    sort_order: "asc",
    page_size: 100,
    page_index: 1,
    broker_user_Id: null,
    lister_user_Id: null,
    state_Id: null,
    new_venue_Id: null,
    seat_intelligence_Id: '1,2',
    show_only_favorites: false, 
  },
  venuesToBeAssigned: [],
  isLoadingVenues: false,
  isAssigningVenues: false,
  hasError: false,
  dataFetchError: null,
};

const getVenueListData = createAsyncThunk(
  "venues/getVenueListData",
  async (args: any, thunkApi) => {    
    const initState: any = thunkApi.getState(); //get initial state
    //update queryParams
    args.query !== null && 
    thunkApi.dispatch(
      setQueryParams({
        ...initState.venuesManagement.queryParams,
        ...args.query,
      })
    ); 
    try {
      const state: any = thunkApi.getState(); //get updated state tree
      let params = state.venuesManagement;
      const response = await new VenueService().getVenueDetailsData({
        ...params.queryParams,
      });
      return response.data.data;
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getVenueListData",
      });

      return thunkApi.rejectWithValue({code: error.code, message: error.message, refreshAction: args.functionName});
    }
  }
);

const bulkAssignVenues = createAsyncThunk(
  "venues/bulkAssignVenues",
  async (args: IBulkAssignBodyData, thunkApi) => {
    try {
      const response = await new VenueService().bulkAssignVenues(args);
      if(response.data.data) {
        thunkApi.dispatch(getVenueListData(queryParams));
      }
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "bulkAssignVenues",
      });
      return {...error, message: "Bulk assign operation failed."}
    }
  }
)

export const venuesManagementSlice = createSlice({
  name: "venuesManagement",
  initialState,
  reducers: {
    reset: () => initialState,
    setQueryParams: (state, action: PayloadAction<IVenueListQueryParams>) => {
      state.queryParams = action.payload;
    },
    setVenuesToBeAssigned: (
      state,
      action: PayloadAction<{id: number, status: boolean}>
    ) => {
      const {id, status} = action.payload;
      if(id === -999) {
        state.venuesToBeAssigned = [];
      }
      else if(status === true && !state.venuesToBeAssigned.includes(id)) {
        state.venuesToBeAssigned.push(id);
      }
      else if(status === false && state.venuesToBeAssigned.includes(id)) {
        state.venuesToBeAssigned = state.venuesToBeAssigned.filter((venueId)=>venueId !== id);
      }
    },
    setIsLoadingVenues: (state, action: PayloadAction<boolean>) => {
      state.isLoadingVenues = action.payload ?? false;
    },
  },
  extraReducers: (builder) => {
    //Get venue list data
    builder.addCase(getVenueListData.pending, (state) => {
      state.isLoadingVenues = true;
    });
    builder.addCase(getVenueListData.fulfilled, (state, action) => {
      state.hasError = false;
      state.isLoadingVenues = false;
      state.venueListData = action.payload;
    });
    builder.addCase(getVenueListData.rejected, (state, action) => {
      state.hasError = true;
      state.dataFetchError = action.payload;
      state.isLoadingVenues = false;
    });

    //Bulk assign venues
    builder.addCase(bulkAssignVenues.pending, (state) => {
      state.isAssigningVenues = true;
    });
    builder.addCase(bulkAssignVenues.fulfilled, (state) => {
      state.hasError = false;
      state.isAssigningVenues = false;
      state.venuesToBeAssigned = [];
    });
    builder.addCase(bulkAssignVenues.rejected, (state, action) => {
      state.hasError = true;
      state.dataFetchError = action.payload;
      state.isAssigningVenues = false;
    });
  },
});

const venueListData = (state: RootState) =>
  state.venuesManagement.venueListData;
const queryParams = (state: RootState) => state.venuesManagement.queryParams;
const venuesToBeAssigned = (state: RootState) =>
  state.venuesManagement.venuesToBeAssigned;
const isLoadingVenues = (state: RootState) =>
  state.venuesManagement.isLoadingVenues;
const hasError = (state: RootState) => state.venuesManagement.hasError;
const dataFetchError = (state: RootState) =>
  state.venuesManagement.dataFetchError;

export {
  venueListData,
  queryParams,
  venuesToBeAssigned,
  isLoadingVenues,
  hasError,
  dataFetchError,
};

export const venueManagementActions = { getVenueListData, bulkAssignVenues };
export const {
  reset,
  setQueryParams,
  setVenuesToBeAssigned,
  setIsLoadingVenues,
} = venuesManagementSlice.actions;
export default venuesManagementSlice.reducer;
