import React, { useEffect, useState } from "react";
import { Col, Typography } from "antd";
import CustomError from "controls/CustomError/CustomError";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { IFilterOptions, IDashboardAlertsQueryParams, IdType } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { BrowserView, MobileView } from "react-device-detect";
import { LoggerService } from "sharedServices";
import { formatNumericValues, getEncodedData, numberShorthand } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import {
  getUsableService,
  widgetClassPerCol,
  defaultParams
} from "../CommonFile/commonFile";

const CommonCountsComponent: React.FC<{
  filterObject: IFilterOptions | null;
  label: string;
  paramObject: {
    show_category_id?: IdType;
    exclude_sold_out_events?: boolean;
    exclude_cancelled_events?: boolean;
  };
  apiName: string;
  otherFilterObjects: {
    if_no_listing?: boolean;
    new_shows_only?: boolean;
    if_with_seat_structure?: boolean;
    shows_seatmap_tbd?: boolean;
  };
  serviceType: string;
  cols: number;
  redirectPath: string;
  dataKey: string;
  labelColor?: string;
}> = ({
  filterObject,
  label,
  paramObject,
  apiName,
  otherFilterObjects,
  serviceType,
  cols,
  redirectPath,
  dataKey,
  labelColor = "text-success"
}) => {
    const [queryParams, setQueryParams] = useState<IDashboardAlertsQueryParams | null>(null);
    const [newDataCount, setNewDataCount] = useState<number>(0);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);

    const [defaultQueryParams] = useState<IDashboardAlertsQueryParams>({
      ...defaultParams,
      ...otherFilterObjects
    });

    const getNewDataCount = async (query: IDashboardAlertsQueryParams) => {
      try {
        setShowLoader(true);

        const response =
          await getUsableService(serviceType)[apiName]({
            ...query,
          });
        setNewDataCount(response.data.data[dataKey]);

        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);

        await new LoggerService().log({
          payload: error,
          function_name: "getNewDataCount",
        });

        setDataFetchError({ ...error, refreshAction: getNewDataCount });
      }
    };

    useEffect(() => {
      queryParams && getNewDataCount(queryParams);
    }, [queryParams]);

    useEffect(() => {
      if (filterObject !== null && defaultQueryParams) {
        setQueryParams({
          ...defaultQueryParams,
          ...filterObject,
        });
      }
    }, [defaultQueryParams, filterObject]);

    return (
      <Col className={widgetClassPerCol[cols]}>
        <div className="dashboardCard2">
          <div className="dashboardCard">
            <LoadingSpinner isLoading={showLoader}>
              {dataFetchError ? (
                <CustomError {...dataFetchError} />
              ) : (
                <React.Fragment>
                  <h2 className={labelColor}>
                    { newDataCount > 0 ? numberShorthand(newDataCount) : formatNumericValues(newDataCount)}
                  </h2>
                  <BrowserView>
                    {newDataCount === 0 &&
                      <div className="withoutHref">{label}</div>
                    }
                    {newDataCount > 0 &&
                      <Typography.Link
                        target="_blank"
                        rel="noreferrer"
                        href={`${LeftNavRoutesEnum[redirectPath]
                          }?filter=${getEncodedData({
                            ...filterObject,
                            ...paramObject,
                          })}`}
                        underline={true}
                      >
                        {label}
                      </Typography.Link>
                    }
                  </BrowserView>
                  <MobileView>
                    <div className="withoutHref">{label}</div>
                  </MobileView>
                </React.Fragment>
              )}
            </LoadingSpinner>
          </div>
        </div>
      </Col>
    );
  };
  
export default CommonCountsComponent;
