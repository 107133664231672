
import { AnyCnameRecord } from "dns";
import { RoutesEnum } from "models/enums";
import { IBotPerformanceChart } from "models/interfaces";


import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";
export class BotPerformanceDashboardService {
    public getBotPerformanceData = async () => {
      return axiosClient.get<IApiBaseResponse<any>>(
        RoutesEnum.DASHBOARD_BOT_PERFORMANCE_DATA,
        {
          params: null,
        }
      );
    };
}