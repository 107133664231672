import { Input } from "antd";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IActiveListing, IEventsWithActiveZoneListingsResponse } from "models/interfaces";

const InputComponent: React.FC<{ currentEditedListings: IActiveListing[], setAllZoneListings: Function; singleData: IActiveListing, keyword: string }> = ( { currentEditedListings ,setAllZoneListings, singleData, keyword } ) => {

    const { hasPermissions } = useRbac();

    const sectionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
            if (prev) {
              const _prev = { ...prev };
              let currentPo = _prev.events
                .find(
                  (event) => event.event_id === singleData.event_id
                )
                ?.po_details?.editedActiveListings.find(
                  (eachPo) =>
                    eachPo.inventory_id === singleData.inventory_id
                );
                
              if (currentPo) {
                currentPo[keyword]= e.target?.value;
              }
              return _prev;
            }
            return prev;
        });
      };
    
    const getValue = ( listings: IActiveListing[] ) => {
        const temp = listings?.find(
            (eachPo) =>
              eachPo.inventory_id === singleData.inventory_id)
        if(temp) {
            return temp[keyword]
        }
        return ''

    }

    return (
        <Input
          value={getValue(currentEditedListings)}
          onChange={sectionChangeHandler}
          id={
            "section_" +
            singleData.inventory_id.toString() +
            "_eventID_" +
            singleData.event_id.toString()
          }
          disabled={singleData.is_split || !hasPermissions([ERbacPermissions.ZONES_SAVE_EDITED_LISTINGS])}
        />
      );
}

export default InputComponent