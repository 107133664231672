import React, { useEffect, useState } from "react";
import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { usePageTitle } from "hooks";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import DashboardKpis from "../CommonComponents/KPIs/DashboardKpis";
import SeatAvailability from "./SeatAvailability/SeatAvailability";
import { IFilterOptions } from "models/interfaces";
import CommonCountsComponent from "../CommonComponents/CommonCountsComponent/CommonCountsComponent";
import { TBDWidgetCounts } from "../CommonComponents/TBDWidget/TBDWidget";
import { colsCount } from "../CommonComponents/CommonFile/commonFile";

const CheckerDashboardPage: React.FC = () => {
  usePageTitle("Checker Dashboard");

  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    dispatch(actions.getSeatIntelligenceData());

    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (
      hasPermissions([
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getCheckerUserData());
    }
  }, [dispatch]);

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  return (
    <div className="fixedFilterArea">
      <div className="mainHeading">
        <div className="dashboardTopBar">
          <div className="flx alignItemsFlexEnd">
            <h2>CHECKER DASHBOARD</h2>
          </div>

          <FilterDrawer
            onApplyFilter={filterChanged}
            showCustomDatePicker={true}
            showBrokerUserDropdown={hasPermissions([
              ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
            ])}
            brokerUserDropdownType="single"
            showCheckerDropdown={hasPermissions([
              ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
              ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
            ])}
            checkerDropdownType="single"
          />
        </div>
      </div>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Alerts</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="NEW SHOWS (WITH SEAT STRUCTURE)"
                paramObject={{ show_category_id: 3, exclude_sold_out_events: true, exclude_cancelled_events: true }}
                apiName="getCheckerNewShowsCount"
                otherFilterObjects={{ new_shows_only: true, if_with_seat_structure: true }}
                serviceType="checker"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="newShowsCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="SHOWS WITH ACTIVE LISTINGS"
                paramObject={{ show_category_id: 5 }}
                apiName="getCheckerNewShowsCount"
                otherFilterObjects={{ new_shows_only: false, if_with_seat_structure: false }}
                serviceType="checker"
                cols={colsCount.alert}
                redirectPath="TRADING_EVENTS"
                dataKey="newShowsCount"
              />
              <TBDWidgetCounts
                label="UNREAD MESSAGES"
                cols={colsCount.alert}
                textColorClass="text-danger"
              />
            </Row>
          </div>
          <SeatAvailability filterObject={filterObject} />
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap">
            <h3>Insights</h3>
            <Row>
              <CommonCountsComponent
                filterObject={filterObject}
                label="VENUES"
                paramObject={{}}
                apiName="getListerVenuesCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_VENUES"
                dataKey="venuesCount"
              />
              <CommonCountsComponent
                filterObject={filterObject}
                label="EVENTS"
                paramObject={{}}
                apiName="getListerEventsCount"
                otherFilterObjects={{}}
                serviceType="lister"
                cols={colsCount.insights}
                redirectPath="TRADING_EVENTS"
                dataKey="eventsCount"
              />
              <TBDWidgetCounts
                label="Widget TBD"
                cols={colsCount.insights}
                textColorClass="text-danger"
              />
            </Row>
          </div>
        </Content>
      </Layout>

      <Layout className="mainInnerLayout dashboard">
        <Content className="mainContainer">
          <div className="dashboardCardWrap kpisGraphsWrap">
            <h3>KPIs</h3>
            <Row className="removeRowBtmSpc">
              <DashboardKpis
                filterObject={filterObject}
                graphType="stackedBar"
                serviceType="checker"
                apiName="getKPIsSeatAvailabilityUpdateGraphData"
                label="Seat Availability Updates"
                cols={colsCount.kpis}
                tooltipHeaders={["Timely Updates", "Late Updates"]}
                cId={1}
                typeOfData="Update"
              />
              <DashboardKpis
                filterObject={filterObject}
                graphType="bar"
                serviceType="lister"
                apiName="getKPIsUnbroadcastsGraphData"
                label="Un-broadcasts"
                cols={colsCount.kpis}
                tooltipHeaders={["Unbroadcasts"]}
                cId={2}
                typeOfData="Unbroadcast"
              />
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default CheckerDashboardPage;
