import { RBAC } from "features/rbac/rbac";
import {
  getCustomDate
} from "utils/formatTimePeriod";
import ToggleFavorite from "./Actions/ToggleFavorite";
import { trigger } from "sharedServices";
import { Tooltip, Typography } from "antd";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import {
  getEncodedData,
  addToolTip,
  checkValidUrl,
  formatAccountingValues
} from "utils/commonFunction";

export const venuesHeaders = [{
    title: "",
    dataIndex: "is_favorite",
    width: 1,
    thClassName: "text-left ",
    dataRender: (data: any, all: any) => (
      <ToggleFavorite isFavorite={data} venueData={all} />
    )
  },
  {
    title: "Venue Name",
    dataIndex: "venueName",
    width: 200,
    canSort: true,
    thClassName: "text-left",
    dataRender: (data: number, all: any) => {
      const venueUrl = all.data.singleData.venueUrl;

      if (venueUrl)
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={checkValidUrl(venueUrl) ? venueUrl : `https://${venueUrl}`}
            className="text-capitalize eventNameVenue venueNameData"
          >
            {data}
          </a>
        );

      return <div className="venueNameData text-capitalize">{data}</div>;
    }
  },
  {
    title: "Venue Location",
    dataIndex: "location",
    canSort: true,
    thClassName: "text-left vLocationCol",
    dataRender: (data: any) => <div className="text-capitalize">{data}</div>
  },
  {
    title: "Date Added",
    dataIndex: "dateAdded",
    canSort: true,
    thClassName: "text-left dateAddedCol2",
    dataRender: (data: any) => getCustomDate(data, "MMM DD, YYYY")
  },
  {
    title: "Broker",
    dataIndex: "broker_name",
    thClassName: "text-left brokerNameCol",
    dataRender: (data: string[]) => {
      if (data) {
        if (data.length > 1) {
          return addToolTip(data);
        } else {
          return <div className="text-capitalize">{data}</div>;
        }
      }
    }
  },
  {
    title: "Lister",
    dataIndex: "lister_name",
    thClassName: "text-left listerNameCol",
    dataRender: (data: string[]) =>
      addToolTip(data, "right", "default", 15, true)
  },
  {
    title: "Seat Map Creator",
    dataIndex: "seat_map_creator_name",
    thClassName: "text-left listerNameCol",
    dataRender: (data: string[]) =>
      addToolTip(data, "right", "default", 15, true)
  },
  {
    title: "Checker",
    dataIndex: "checker_name",
    thClassName: "text-left listerNameCol",
    dataRender: (data: string[]) =>
      addToolTip(data, "right", "default", 15, true)
  },
  {
    title: "Venue Fees",
    dataIndex: "venue_fees",
    canSort: true,
    thClassName: "text-right dateAddedCol2",
    dataRender: (data: number, otherData: any) => {
      return(
        <div className="text-right">
          {otherData?.data?.singleData?.fees_available_scraping ? 'Scraped' : data > 0 ? formatAccountingValues(data) : "-"}
        </div>
      )
    }
  },
  {
    title: "Event Count",
    dataIndex: "eventCount",
    canSort: true,
    dataRender: (data: number, all: any) => (
      <div className="text-right">
        {data > 0 ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/trading/events?filter=${getEncodedData({
              venue_id: all.data.singleData.venueId,
              venue_name: all.data.singleData.venueName,
              seat_intelligence_Id: all.data.singleData.seat_intelligence_id?.length > 1 ? null : all.data.singleData.seat_intelligence_id[0]
            })}`}
            className="eventCountDataUrl"
          >
            {data}
          </a>
        ) : (
          <span className="eventCountData">-</span>
        )}
      </div>
    ),
    thClassName: "text-right eventCountCol"
  },
  {
    title: "Unique Events",
    dataIndex: "uniqueEventCount",
    canSort: true,
    thClassName: "text-right dateAddedCol2",
    dataRender: (data: number) => (
      <div className="text-right uniqueEventCountData">
        {data > 0 ? data?.toLocaleString("en-US") : "-"}
      </div>
    )
  },
  {
    title: "Events (No Structure)",
    dataIndex: "events_without_seat_structure",
    canSort: true,
    dataRender: (data: number, all: any) => (
      <div className="text-right">
        {data > 0 ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/trading/events?filter=${getEncodedData({
              venue_id: all.data.singleData.venueId,
              venue_name: all.data.singleData.venueName,
              show_category_id: 7
            })}`}
            className="eventCountDataUrl"
          >
            {data}
          </a>
        ) : (
          <span className="eventCountData">-</span>
        )}
      </div>
    ),
    thClassName: "text-right eventCountCol"
  },
  {
    title: "No Availability Info",
    dataIndex: "events_without_availability_info",
    width: 135,
    canSort: true,
    dataRender: (data: number, all: any) => (
      <div className="text-right">
        {data > 0 ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/trading/events?filter=${getEncodedData({
              venue_id: all.data.singleData.venueId,
              venue_name: all.data.singleData.venueName,
              show_category_id: 8
            })}`}
            className="eventCountDataUrl"
          >
            {data}
          </a>
        ) : (
          <span className="eventCountData">-</span>
        )}
      </div>
    ),
    thClassName: "text-right eventCountCol"
  },
  {
    title: "Events (No Listings)",
    dataIndex: "events_without_listings",
    canSort: true,
    width: 110,
    dataRender: (data: number, all: any) => (
      <div className="text-right">
        {data > 0 ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/trading/events?filter=${getEncodedData({
              venue_id: all.data.singleData.venueId,
              venue_name: all.data.singleData.venueName,
              show_category_id: 6
            })}`}
            className="eventCountDataUrl"
          >
            {data}
          </a>
        ) : (
          <span className="eventCountData">-</span>
        )}
      </div>
    ),
    thClassName: "text-right eventCountCol"
  },
  {
    title: "Scraping Type",
    dataIndex: "venue_scraping_type",
    width: 120,
    thClassName: "text-left",
    dataRender: (venue_scraping_type: string[]) => venue_scraping_type.join(', ')
  },
  {
    title: "Scraping Status",
    tooltipIndex: "scraping_status",
    dataIndex: "successfully_scraped_events",
    canSort: true,
    thClassName: "text-left scrapingCol",
    width: 130,
    dataRender: (_data: number, all: any) => {
      if (all.data.singleData.seat_intelligence_id === 1) {
        return (
          <div style={{ textAlign: "right", fontSize: "var(--font-size-20)" }}>
            {all.data.singleData.successfully_scraped_events} / {all.data.singleData.total_scraped_events}
          </div>
        );
      }
    }
  },
  {
    title: "",
    dataIndex: "venueName",
    width: 70,
    thClassName: "text-left newListingCOl",
    dataRender: (_data: any, all: any) => (
      <div className="tblActions">
        <RBAC allowedPermissions={[ERbacPermissions.VENUE_DETAILS_EDIT]}>
          <Tooltip title="Edit Venue Metadata" placement="left">
            <Typography.Link
              onClick={() => {
                trigger("venueAction:click", {
                  action: "edit",
                  data: {
                    venueData: all.data.singleData
                  }
                });
              }}
            >
              <i className="da icon-pencil-o" />
            </Typography.Link>
          </Tooltip>
        </RBAC>
        <Tooltip title="Notifications" placement="topLeft">
          <Typography.Link disabled={true} className="cardNotificationIcon">
            <i className="da icon-comment" />
          </Typography.Link>
        </Tooltip>
      </div>
    )
  }
];
