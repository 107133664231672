import { Select } from "antd";
import { useRbac } from "features/rbac/rbac";
import { IListElement } from "models/interfaces";
import {
  IScheduleListResponse,
  ISchedulerData,
} from "models/interfaces/Scheduler/scheduler.model";
import { useEffect, useState } from "react";
import { filterSortByRanking } from "utils/commonFunction";

const { Option } = Select;

const Recipients: React.FC<{
  setScheduleData: Function;
  resetToDefaults: boolean;
  setResetToDefaults: Function;
  selectedRecipients: string[] | undefined;
  setSelectedRecipients: Function;
  existingScheduleData?: IScheduleListResponse | null;
  allRecipients: IListElement[];
}> = ({
  setScheduleData,
  resetToDefaults,
  setResetToDefaults,
  selectedRecipients,
  setSelectedRecipients,
  existingScheduleData,
  allRecipients,
}) => {
  const { userDetails } = useRbac();

  const [optionalMessage, setOptionalMessage] = useState<string | null>(
    existingScheduleData?.scheduleDetails.message
      ? existingScheduleData?.scheduleDetails.message
      : null
  );

  const [inputValue, setInputValue] = useState("");
  const handleOnSearch = (value: React.SetStateAction<string>) => {
    setInputValue(value);
  };

  const handleSelectedRecipientChange = (value: string[]) => {
    setSelectedRecipients(value);
  };

  useEffect(() => {
    setSelectedRecipients(
      existingScheduleData?.scheduleDetails.recipients
        ? existingScheduleData?.scheduleDetails.recipients
        : [userDetails?.uuid]
    );
    setOptionalMessage(
      existingScheduleData?.scheduleDetails.message
        ? existingScheduleData?.scheduleDetails.message
        : null
    );
    setResetToDefaults(false);
  }, [resetToDefaults === true]);

  useEffect(() => {
    setScheduleData((prev: ISchedulerData) => ({
      ...prev,
      recipients: selectedRecipients,
      message: optionalMessage,
    }));
  }, [selectedRecipients, optionalMessage]);

  return (
    <div className="ant-col ant-col-xs-24 ant-col-xl-24">
      <div className="inputGroup">
        <label>
          Recipients <span className="text-danger">*</span>
        </label>
        <div className="">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Recipients"
            onChange={handleSelectedRecipientChange}
            value={selectedRecipients ? selectedRecipients : undefined}
            onSelect={()=>setInputValue("")}
            onDropdownVisibleChange={(open)=>open && setInputValue("")}
            onSearch={handleOnSearch}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input?.toLowerCase())
                ? true
                : false
            }
            filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
            options={allRecipients}
          />
        </div>
      </div>

      <div className="inputGroup">
        <textarea
          className="txtArea"
          placeholder="Add a custom message here (optional)"
          value={optionalMessage === null ? "" : optionalMessage}
          onChange={(e) => {
            e.target.value.trim().length !== 0
              ? setOptionalMessage(e.target.value)
              : setOptionalMessage(null);
          }}
          style={{ minHeight: "150px" }}
        ></textarea>
      </div>
    </div>
  );
};

export default Recipients;
