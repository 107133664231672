import { Layout } from "antd";
import CommonReportTable from "utils/table/CommonReportTable";
import { topEventsHeader } from "./grossProfitSnapshotUtils";
import { IProfitDashboardBaseType, ITopEvents } from "models/interfaces";
import { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { AppSetting } from "utils/appSettings";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { getHeaderData } from "utils/commonFunction";

const TopEventsTable: React.FC<{
  queryParams: IProfitDashboardBaseType | null;
}> = ({ queryParams }) => {
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [topEventsData, setTopEventsData] = useState<ITopEvents>();
  const [topEventsFetchError, setTopEventsFetchError] = useState<ICustomError | null>(null)

  async function getData() {
    try {
      if (!queryParams) return false;
      setIsServiceLoading(true);

      const topEventsResponseData =
        await new ProfitDashboardService().getTopEvents({
          ...queryParams,
          ...{ client_zone: AppSetting.TIMEZONE },
        });
      setTopEventsData(topEventsResponseData.data.data);
      setIsServiceLoading(false);
      setTopEventsFetchError(null)
    } catch (error: any) {
      setIsServiceLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getData'
      })
      setTopEventsFetchError({...error, refreshAction: getData})
    }
  }

  useEffect(() => {
    getData();
  }, [queryParams?.currency_Id]);

  return (
    topEventsFetchError
    ?
    <CustomError {...topEventsFetchError} />
    :
    <Layout className="mainInnerLayout tblStyle2">
      <h3>Top 10 Performing Events by Gross Margin</h3>
      <LoadingSpinner isLoading={isServiceLoading}>
        <div className="ant-row">
          <div className="ant-col ant-col-xl-10 topEventsTbl">           
            <CommonReportTable
              headers={topEventsHeader}
              data={topEventsData?.topEvents ?? null}
              headerData={getHeaderData(topEventsData, "event")}
              tableClass={"customTbl"}
            />           
          </div>
        </div>
      </LoadingSpinner>
    </Layout>
  );
};

export default TopEventsTable;
