import { FrequencyEnum } from "models/enums/schedule";
import {
  IScheduleListResponse,
  ISchedulerDatum,
} from "models/interfaces/Scheduler/scheduler.model";
import React, { useEffect, useState } from "react";
import DaySelector from "./DaySelector";
import OccurrenceControlTooltip from "./OccurrenceControlTooltip";
import TimeSelector from "./TimeSelector";

const OccurrenceControl: React.FC<{
  existingScheduleData: IScheduleListResponse | null;
  scheduleData: ISchedulerDatum;
  setScheduleData: Function;
  setShowDateDropdown: Function;
  setShowTimeDropdown: Function;
  showDateDropdown: boolean;
  showTimeDropdown: boolean;
  setResetToDefaults: Function;
  reset: boolean;
  daysOfWeek: number[];
  setDaysOfWeek: Function;
  daysOfMonth: number[];
  setDaysOfMonth: Function;
  dropdownData: any;
  selectedTags: number | number[];
  setSelectedTags: Function;
}> = ({
  existingScheduleData,
  scheduleData,
  setScheduleData,
  showDateDropdown,
  setShowDateDropdown,
  showTimeDropdown,
  setShowTimeDropdown,
  reset,
  setResetToDefaults,
  daysOfWeek,
  setDaysOfWeek,
  daysOfMonth,
  setDaysOfMonth,
  dropdownData,
  selectedTags,
  setSelectedTags,
}) => {
  const [dayOfString, setDayOfString] = useState(" at ");

  useEffect(() => {
    switch (scheduleData?.frequencyId) {
      case FrequencyEnum.Daily:
        setDayOfString(" at ");
        break;
      case FrequencyEnum.Weekly:
        setDayOfString(" of every week at ");
        break;
      case FrequencyEnum.Monthly:
        setDayOfString(" of every month at ");
        break;
    }
  }, [scheduleData?.frequencyId]);

  return (
    <React.Fragment>
      <div className="ant-col ant-col-xs-24 ant-col-xl-24">
        <div className="inputGroup">
          <label>Data</label>
          <div>
            <span>Report to be sent on </span>
            <DaySelector
              existingScheduleData={
                existingScheduleData ? existingScheduleData : null
              }
              setScheduleData={setScheduleData}
              scheduleData={scheduleData}
              showDateDropdown={showDateDropdown}
              setShowDateDropdown={setShowDateDropdown}
              reset={reset}
              setResetToDefaults={setResetToDefaults}
              daysOfWeek={daysOfWeek}
              setDaysOfWeek={setDaysOfWeek}
              daysOfMonth={daysOfMonth}
              setDaysOfMonth={setDaysOfMonth}
              dropdownData={dropdownData}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
            <span> {dayOfString} </span>
            <TimeSelector
              existingScheduleData={
                existingScheduleData ? existingScheduleData : null
              }
              setScheduleData={setScheduleData}
              scheduleData={scheduleData}
              showTimeDropdown={showTimeDropdown}
              setShowTimeDropdown={setShowTimeDropdown}
              reset={reset}
              setResetToDefaults={setResetToDefaults}
            />
            <span> and ends </span>
            <OccurrenceControlTooltip
              scheduleData={scheduleData}
              setScheduleData={setScheduleData}
              reset={reset}
              setResetToDefaults={setResetToDefaults}
              existingScheduleData={existingScheduleData}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OccurrenceControl;
