import Logo from "../../assets/images/logo-white.svg";
import UnderMaintenanceImg from "../../assets/images/under-maintenance-img.svg";


export function UnderMaintenancePage() {
  return (
    <div className="underMaintenancePageWrap">
      <div className="underMaintenanceContent">
      <div className="underMaintenanceLogo"><img src={Logo} alt="Ticket Bash" title="Ticket Bash" /></div>
      <div className="txtStyle3">Website is under maintenance.</div>
      <p>Unfortunately, the site is down for a bit of maintenance right now.  Sorry for the inconvenience, but we’ll soon be up!</p>
      <div className="underMaintenanceImg"><img src={UnderMaintenanceImg} alt="Ticket Bash" title="Ticket Bash" /></div>
      </div>
    </div>
  );
}
