import { Badge, Tooltip, Typography } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IActiveListing } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip, twoDecimalPlacesNumber } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import DeleteListing from "./Actions/DeleteListing";
import SplitListing from "./Actions/SplitListing";
import ActiveListingBroadcastStatusChange from "./Actions/ActiveListingBroadcastStatusChange";
import SplitInventoryView from "../SplitInventoryView/SplitInventoryView";

const getSeatAvailabilityMessage = (
  isWebScraping: number,
  totalSeats: number | null,
  availableSeats: number | null,
  availabilityDisplayAlias: string
) => {
  return isWebScraping === 1 && availableSeats && totalSeats
    ? `${availableSeats} / ${totalSeats}`
    : availabilityDisplayAlias;
};

const getDataForSeatAvailabilityInfo = (
  activeListingData: IActiveListing | undefined
) => {
  switch (activeListingData?.availability) {
    case 0: // none
      return {
        ribbonClassName: "ribbon error right",
        iconClassName: "",
        message: getSeatAvailabilityMessage(
          activeListingData.is_web_scraping,
          activeListingData.total_seats,
          activeListingData.available_seats,
          activeListingData.availability_display_alias
        ),
      };
    case 1: // high
      return {
        ribbonClassName: "ribbon success",
        iconClassName: "da icon-chart-up-green",
        message: getSeatAvailabilityMessage(
          activeListingData.is_web_scraping,
          activeListingData.total_seats,
          activeListingData.available_seats,
          activeListingData.availability_display_alias
        ),
      };
    case 2: // mid
      return {
        ribbonClassName: "ribbon warning-light",
        iconClassName: "da icon-chart-up-yellow",
        message: getSeatAvailabilityMessage(
          activeListingData.is_web_scraping,
          activeListingData.total_seats,
          activeListingData.available_seats,
          activeListingData.availability_display_alias
        ),
      };
    case 3: // low
      return {
        ribbonClassName: "ribbon danger",
        iconClassName: "da icon-chart-up-red",
        message: getSeatAvailabilityMessage(
          activeListingData.is_web_scraping,
          activeListingData.total_seats,
          activeListingData.available_seats,
          activeListingData.availability_display_alias
        ),
      };
    case 4: // tbd
      return {
        ribbonClassName: "ribbon tbd",
        iconClassName: "",
        message: activeListingData.availability_display_alias,
      };
    case 5:
      return {
        ribbonClassName: "ribbon",
        iconClassName: "",
        message: activeListingData.availability_display_alias,
      };
    default:
      return {
        ribbonClassName: "ribbon secondary",
        iconClassName: "",
        message: "Availability Unknown",
      };
  }
};

const ActiveListingPoCard: React.FC<{
  eventId: number;
  eventName: string;
  eventDate: string;
  venueName: string;
  venueLocation: string;
  activeListingData?: IActiveListing;
  showCardActionHeader?: boolean;
  setUpdatedBroadcastStatus?: Function;
  refreshListingsAfterDelete?: Function;
  disableSplitToggle?: boolean;
  header?: JSX.Element;
  classes?: string;
}> = ({
  eventId,
  eventName,
  eventDate,
  venueName,
  venueLocation,
  activeListingData,
  showCardActionHeader,
  setUpdatedBroadcastStatus,
  refreshListingsAfterDelete,
  disableSplitToggle,
  header,
  classes,
}) => {
  const [seatAvailabilityInfo, setSeatAvailabilityInfo] = useState({
    ribbonClassName: "",
    iconClassName: "",
    message: "",
  });

  const [showSplitView, setShowSplitView] = useState(false);

  useEffect(() => {
    setSeatAvailabilityInfo(getDataForSeatAvailabilityInfo(activeListingData));
  }, [activeListingData]);

  const splitViewClickHandler = () => {
    setShowSplitView && setShowSplitView(true);
  };

  const getCardHeaderLeftContent = () => {
    if (!header) {
      return (
        <a
          rel="noreferrer"
          href={AppSetting.PURCHASE_ID_URL + activeListingData?.purchase_id}
          className="poNumber"
          target={"_blank"}
        >
          PO# {activeListingData?.purchase_id}
        </a>
      );
    }
    return header;
  };

  const getSplitListingCta = () => {
    if (
      activeListingData &&
      activeListingData.quantity > 1 &&
      !activeListingData.is_split
    ) {
      return (
        <SplitListing
          actionElement={
            <Tooltip title="Split">
              <Typography.Link>
                <i className="da icon-split"></i>
              </Typography.Link>
            </Tooltip>
          }
          data={{
            eventId,
            eventName,
            eventDate,
            venueName,
            venueLocation,
            purchaseId: activeListingData.purchase_id,
            purchaseLineId: activeListingData.purchase_line_id,
            inventoryId: activeListingData.inventory_id,
            quantity: activeListingData.quantity,
            row: activeListingData.row,
            section: activeListingData.section,
            listPrice: activeListingData.list_price,
          }}
          refreshListings={refreshListingsAfterDelete}
        />
      );
    }
  };

  const getBroadcastCta = () => {
    if (activeListingData && !activeListingData.is_split) {
      return (
        <ActiveListingBroadcastStatusChange
          activeListingData={activeListingData}
          setUpdatedBroadcastStatus={setUpdatedBroadcastStatus}
          eventId={eventId}
          eventName={eventName}
          eventDate={eventDate}
          venueName={venueName}
          venueLocation={venueLocation}
        />
      );
    }
  };

  const getEventCardHeaderJsx = () => {
    if (showCardActionHeader !== false) {
      return (
        <div className="eventCardHeader">
          {getCardHeaderLeftContent()}
          <div className="actions">
            <RBAC
              allowedPermissions={[
                ERbacPermissions.ZONES_ACTIVE_LISTING_SPLIT_ACTION,
              ]}
            >
              {getSplitListingCta()}
            </RBAC>

            <Badge offset={[0, 3]} count={0} size={"small"} color="#9F2533">
              <Tooltip title="Notifications">
                <Typography.Link disabled className="cardNotificationIcon">
                  <i className="da icon-comment" />
                </Typography.Link>
              </Tooltip>
            </Badge>
            {getBroadcastCta()}
            <Tooltip title="Edit Listing">
              <Typography.Link disabled>
                <i className="da icon-pencil-o" />
              </Typography.Link>
            </Tooltip>
            <DeleteListing
              activeListingData={activeListingData}
              refreshListingsAfterDelete={refreshListingsAfterDelete}
              eventId={eventId}
              eventName={eventName}
              eventDate={eventDate}
              venueName={venueName}
              venueLocation={venueLocation}
              setShowSplitView={setShowSplitView}
            />
          </div>
        </div>
      );
    }
  };

  const getZoneSeatingWrapLeftContent = () => {
    return (
      <div>
        <h2>
          {activeListingData?.is_list_price_multiple
            ? "Multiple"
            : `$${twoDecimalPlacesNumber(activeListingData?.list_price)}`}
        </h2>
        <p>
          Max Cost: ${twoDecimalPlacesNumber(activeListingData?.cost_price_with_venue_fees)}
          {
            Boolean(activeListingData?.venue_fees && (activeListingData?.cost_price_with_venue_fees >= 1)) &&
            <Tooltip title="Venue Fees Included"><i className="da icon-venue-fees" style={{ marginLeft: '5px'}}/></Tooltip>
          }
        </p>
        {activeListingData?.cost_price !== undefined &&
        activeListingData?.list_price !== undefined &&
        activeListingData?.cost_price > activeListingData?.list_price ? (
          <p className="text-danger">
            <strong>List Price less than Cost</strong>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  };

  const getQuantityJsx = () => {
    if (activeListingData && activeListingData.inventory_ids?.length > 1) {
      return (
        <Tooltip title="View Splits">
          <div className="rankingInner yellow poCardQty">
            {!disableSplitToggle ? (
              <span
                className="rankNumber splitQty"
                onClick={splitViewClickHandler}
                style={{ cursor: "pointer" }}
              >
                {activeListingData?.quantity} <i className="da icon-split2-yellow" />
              </span>
            ) : (
              <span className="rankNumber splitQty" style={{ cursor: "text" }}>
                {activeListingData?.quantity} <i className="da icon-split2-yellow" />
              </span>
            )}
            <div>Qty</div>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div className="rankingInner yellow poCardQty">
          <span className="rankNumber">{activeListingData?.quantity}</span>
          <div>Qty</div>
        </div>
      );
    }
  };

  const getSectionJsx = () => {
    if (activeListingData?.section && activeListingData?.section.length >= 10) {
      return addToolTip(
        activeListingData?.section,
        "right",
        "default",
        activeListingData?.is_split ? 20 : 10
      );
    }
    return activeListingData?.section;
  };

  const getRowJsx = () => {
    if (activeListingData?.row && activeListingData?.row.length > 3) {
      return addToolTip(activeListingData?.row, "right", "default", 3);
    }
    return activeListingData?.row;
  };

  const getSeatsJsx = () => {
    if (activeListingData?.seats && activeListingData?.seats.length >= 10) {
      return addToolTip(activeListingData?.seats, "right", "default", 10);
    }
    return activeListingData?.seats;
  };

  return (
    <React.Fragment>
      <div className={"eventCardWrap activeListing noTopbar " + classes}>
        <div className="eventCardContent">
          {getEventCardHeaderJsx()}
          <div className={seatAvailabilityInfo.ribbonClassName}>
            <i className={seatAvailabilityInfo.iconClassName} />
            <span>{seatAvailabilityInfo.message}</span>
          </div>
          <div className="zoneSeatingWrap">
            {getZoneSeatingWrapLeftContent()}
            <div className="zoneSeating">
              <span>
                Zone
                <br />
                Seating
              </span>
              {activeListingData?.section && (
                <i className="da icon-checked-circle" />
              )}
            </div>
          </div>

          <div className="eventCardBody">
            <div className="ranking">
              {getQuantityJsx()}
              <div className="rankingInner yellow">
                <span className="rankNumber">{getSectionJsx()}</span>
                <div>Section</div>
              </div>
              <div className="rankingInner yellow poCardRow">
                <span className="rankNumber">{getRowJsx()}</span>
                <div>Row</div>
              </div>
              {!activeListingData?.is_split && (
                <div className="rankingInner yellow">
                  <span className="rankNumber">{getSeatsJsx()}</span>
                  <div>Seats</div>
                </div>
              )}
            </div>
          </div>
          <div className="eventCardFooter">
            <div>
              <Tooltip title="Broker">
                <i className="da icon-user-o"></i>
              </Tooltip>
              {activeListingData?.broker_tag &&
              activeListingData?.broker_tag.length > 15
                ? addToolTip(activeListingData?.broker_tag)
                : activeListingData?.broker_tag}
            </div>
            <div>
              <Tooltip title="Lister">
                <i className="da icon-user-role"></i>
              </Tooltip>
              {activeListingData?.lister_tag &&
              activeListingData?.lister_tag.length > 15
                ? addToolTip(activeListingData?.lister_tag)
                : activeListingData?.lister_tag}
            </div>
          </div>
          <div className="eventCardFooter2">
            <div>
              {"Created: "}
              <span>
                {activeListingData?.listed_at &&
                  formatDateGivenFormatString(
                    activeListingData?.listed_at.toString(),
                    "MMM DD, YYYY h:mm A"
                  )}
                <br />
                {activeListingData?.listed_by}
              </span>
            </div>
            <div>
              <span>
                {activeListingData?.tb_placement_check ? "TB2.0" : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showSplitView && (
        <SplitInventoryView
          inventory_id={activeListingData?.inventory_id}
          showSplitView={showSplitView}
          setShowSplitView={setShowSplitView}
        />
      )}
    </React.Fragment>
  );
};

export default ActiveListingPoCard;
