import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { dateType } from "models/types";
import moment from "moment-timezone";
import { AppSetting } from "./appSettings";

const DATE_OPTIONS: {} = { year: "numeric", month: "short", day: "numeric" };
const currentQuarter = moment().quarter();
const TIMEZONE = AppSetting.TIMEZONE;
const yearFormat = "YYYY";
let preDefinedStartDate: string[], preDefinedEndDate: string[];
moment && moment.tz.setDefault(TIMEZONE);

export const formatDateGivenFormatString = (
  date: any,
  format?: string,
  timeZone?: string
): string => {
  if(timeZone){
    return moment
    .tz(date, timeZone)
    .set({ minute: 0, second: 0 })
    .format(format ? format : "MMMM Do YYYY, h:mm A")
  }
  return moment(date).format(format ? format : "MMMM Do YYYY, h:mm A");
};

export const formatTimePeriod = (
  fromDate: dateType,
  toDate: dateType
): string => {
  let fromYear, fromMonth, fromDay;
  let toYear, toMonth, toDay;
  const monthFormat = "MMM";
  const dayFormat = "DD";
  fromDate = moment(fromDate).format(AppSetting.DB_DATE_FORMAT);
  toDate = moment(toDate).format(AppSetting.DB_DATE_FORMAT);

  fromYear = moment(fromDate).format(yearFormat);
  toYear = moment(toDate).format(yearFormat);

  fromMonth = moment(fromDate).format(monthFormat);
  toMonth = moment(toDate).format(monthFormat);

  fromDay = moment(fromDate).format(dayFormat);
  toDay = moment(toDate).format(dayFormat);

  if (fromYear !== toYear) {
    return `${new Date(fromDate).toLocaleDateString(
      "en-US",
      DATE_OPTIONS
    )}-${new Date(toDate).toLocaleDateString("en-US", DATE_OPTIONS)}`;
  } else {
    if (fromMonth !== toMonth) {
      return `${fromMonth} ${fromDay}-${toMonth} ${toDay}, ${fromYear} `;
    } else {
      return `${fromMonth} ${fromDay}-${toDay}, ${fromYear}`;
    }
  }
};

export const dateRangeIsYesterday = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(
      moment().subtract(1, OffsetType.DAYS),
      OffsetType.DAYS
    ) &&
    moment(to).isSame(moment().subtract(1, OffsetType.DAYS), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsTomorrow = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(moment().add(1, OffsetType.DAYS), OffsetType.DAYS) &&
    moment(to).isSame(moment().add(1, OffsetType.DAYS), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsToday = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(moment(), OffsetType.DAYS) &&
    moment(to).isSame(moment(), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLast7Days = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(
      moment().subtract(6, OffsetType.DAYS),
      OffsetType.DAYS
    ) &&
    moment(to).isSame(moment(), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNext7Days = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(moment(), OffsetType.DAYS) &&
    moment(to).isSame(moment().add(6, OffsetType.DAYS), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLastWeek = (from: dateType, to: dateType) => {
  if (
    moment()
      .subtract(1, OffsetType.WEEKS)
      .startOf(OffsetType.WEEKS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .subtract(1, OffsetType.WEEKS)
      .endOf(OffsetType.WEEKS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsThisWeek = (from: dateType, to: dateType) => {
  if (
    moment().startOf(OffsetType.WEEKS).isSame(moment(from), OffsetType.DAYS) &&
    moment().endOf(OffsetType.WEEKS).isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNextWeek = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.WEEKS)
      .startOf(OffsetType.WEEKS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(1, OffsetType.WEEKS)
      .endOf(OffsetType.WEEKS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLast15Days = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(
      moment().subtract(14, OffsetType.DAYS),
      OffsetType.DAYS
    ) &&
    moment(to).isSame(moment(), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNext15Days = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(moment(), OffsetType.DAYS) &&
    moment(to).isSame(moment().add(14, OffsetType.DAYS), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLastMonth = (from: dateType, to: dateType) => {
  if (
    moment()
      .subtract(1, OffsetType.MONTHS)
      .startOf(OffsetType.MONTHS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .subtract(1, OffsetType.MONTHS)
      .endOf(OffsetType.MONTHS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsThisMonth = (from: dateType, to: dateType) => {
  if (
    moment().startOf(OffsetType.MONTHS).isSame(moment(from), OffsetType.DAYS) &&
    moment().endOf(OffsetType.MONTHS).isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNextMonth = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.MONTHS)
      .startOf(OffsetType.MONTHS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(1, OffsetType.MONTHS)
      .endOf(OffsetType.MONTHS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLastQuarter = (from: dateType, to: dateType) => {
  if (
    moment()
      .subtract(1, OffsetType.QUARTERS)
      .startOf(OffsetType.QUARTERS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .subtract(1, OffsetType.QUARTERS)
      .endOf(OffsetType.QUARTERS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsThisQuarter = (from: dateType, to: dateType) => {
  if (
    moment()
      .startOf(OffsetType.QUARTERS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment().endOf(OffsetType.QUARTERS).isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNextQuarter = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.QUARTERS)
      .startOf(OffsetType.QUARTERS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(1, OffsetType.QUARTERS)
      .endOf(OffsetType.QUARTERS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsLastYear = (from: dateType, to: dateType) => {
  if (
    moment()
      .subtract(1, OffsetType.YEARS)
      .startOf(OffsetType.YEARS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .subtract(1, OffsetType.YEARS)
      .endOf(OffsetType.YEARS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsThisYear = (from: dateType, to: dateType) => {
  if (
    moment().startOf(OffsetType.YEARS).isSame(moment(from), OffsetType.DAYS) &&
    moment().endOf(OffsetType.YEARS).isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNextYear = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.YEARS)
      .startOf(OffsetType.YEARS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(1, OffsetType.YEARS)
      .endOf(OffsetType.YEARS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

//NEW
export const dateRangeIsNext365Days = (from: dateType, to: dateType) => {
  if (
    moment(from).isSame(moment(), OffsetType.DAYS) &&
    moment(to).isSame(moment().add(364, OffsetType.DAYS), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNext3Months = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.MONTHS)
      .startOf(OffsetType.MONTHS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(3, OffsetType.MONTHS)
      .endOf(OffsetType.MONTHS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNext6Months = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.MONTHS)
      .startOf(OffsetType.MONTHS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(6, OffsetType.MONTHS)
      .endOf(OffsetType.MONTHS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsNext9Months = (from: dateType, to: dateType) => {
  if (
    moment()
      .add(1, OffsetType.MONTHS)
      .startOf(OffsetType.MONTHS)
      .isSame(moment(from), OffsetType.DAYS) &&
    moment()
      .add(9, OffsetType.MONTHS)
      .endOf(OffsetType.MONTHS)
      .isSame(moment(to), OffsetType.DAYS)
  ) {
    return true;
  }
  return false;
};

export const dateRangeIsAll = (from: string, to: string) => {
  if (preDefinedStartDate?.includes(from) &&
    preDefinedEndDate?.includes(to)
  ) {
    return true;
  }
  return false;
}

export const dateRangeIsCustom = (from: string, to: string) => {
  if (
    !dateRangeIsYesterday(from, to) &&
    !dateRangeIsToday(from, to) &&
    !dateRangeIsLast7Days(from, to) &&
    !dateRangeIsNext7Days(from, to) &&
    !dateRangeIsLastWeek(from, to) &&
    !dateRangeIsThisWeek(from, to) &&
    !dateRangeIsNextWeek(from, to) &&
    !dateRangeIsLast15Days(from, to) &&
    !dateRangeIsNext15Days(from, to) &&
    !dateRangeIsLastMonth(from, to) &&
    !dateRangeIsThisMonth(from, to) &&
    !dateRangeIsNextMonth(from, to) &&
    !dateRangeIsLastQuarter(from, to) &&
    !dateRangeIsThisQuarter(from, to) &&
    !dateRangeIsNextQuarter(from, to) &&
    !dateRangeIsLastYear(from, to) &&
    !dateRangeIsThisYear(from, to) &&
    !dateRangeIsNextYear(from, to) &&
    !dateRangeIsNext365Days(from, to) &&
    !dateRangeIsNext3Months(from, to) &&
    !dateRangeIsNext6Months(from, to) &&
    !dateRangeIsNext9Months(from, to) &&
    !dateRangeIsAll(from, to)
  ) {
    return true;
  }
  return false;
};

export const presets = [
  {
    text: "Last Year",
    start: moment(moment().year() - 1, yearFormat).startOf(OffsetType.YEARS),
    end: moment(moment().year() - 1, yearFormat).endOf(OffsetType.YEARS),
    fields: ["po_date", "pl_date", "invoice_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Last Quarter",
    start: moment()
      .subtract(1, OffsetType.QUARTERS)
      .startOf(OffsetType.QUARTERS),
    end: moment().subtract(1, OffsetType.QUARTERS).endOf(OffsetType.QUARTERS),
    fields: ["po_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Last Month",
    start: moment().subtract(1, OffsetType.MONTHS).startOf(OffsetType.MONTHS),
    end: moment().subtract(1, OffsetType.MONTHS).endOf(OffsetType.MONTHS),
    fields: ["po_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Last 15 Days",
    start: moment().subtract(14, OffsetType.DAYS),
    end: moment(),
    fields: ["pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Last 7 Days",
    start: moment().subtract(6, OffsetType.DAYS),
    end: moment(),
    fields: ["pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Last Week",
    start: moment().subtract(1, OffsetType.WEEKS).startOf(OffsetType.WEEKS),
    end: moment().subtract(1, OffsetType.WEEKS).endOf(OffsetType.WEEKS),
    fields: ["po_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Yesterday",
    start: moment().subtract(1, OffsetType.DAYS),
    end: moment().subtract(1, OffsetType.DAYS),
    fields: ["pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Today",
    start: moment(),
    end: moment(),
    fields: ["pl_date", "invoice_date", "fill_date", "custom_date", "event_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "This Week",
    start: moment().startOf(OffsetType.WEEKS),
    end: moment().endOf(OffsetType.WEEKS),
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "This Month",
    start: moment().startOf(OffsetType.MONTHS),
    end: moment().endOf(OffsetType.MONTHS),
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "This Quarter",
    start: moment().quarter(currentQuarter).startOf(OffsetType.QUARTERS),
    end: moment().quarter(currentQuarter).endOf(OffsetType.QUARTERS),
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "This Year",
    start: moment().startOf(OffsetType.YEARS),
    end: moment().endOf(OffsetType.YEARS),
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "Next 7 Days",
    start: moment(),
    end: moment().add(6, OffsetType.DAYS),
    fields: ["event_date"],
  },
  {
    text: "Next 15 Days",
    start: moment(),
    end: moment().add(14, OffsetType.DAYS),
    fields: ["event_date"],
  },
  {
    text: "Next Week",
    start: moment().add(1, OffsetType.WEEKS).startOf(OffsetType.WEEKS),
    end: moment().add(1, OffsetType.WEEKS).endOf(OffsetType.WEEKS),
    fields: ["event_date"],
  },
  {
    text: "Next Month",
    start: moment().add(1, OffsetType.MONTHS).startOf(OffsetType.MONTHS),
    end: moment().add(1, OffsetType.MONTHS).endOf(OffsetType.MONTHS),
    fields: ["event_date"],
  },
  {
    text: "Next 3 Months",
    start: moment().add(1, OffsetType.MONTHS).startOf(OffsetType.MONTHS),
    end: moment().add(3, OffsetType.MONTHS).endOf(OffsetType.MONTHS),
    fields: ["event_date"],
  },
  {
    text: "Next Quarter",
    start: moment().add(1, OffsetType.QUARTERS).startOf(OffsetType.QUARTERS),
    end: moment().add(1, OffsetType.QUARTERS).endOf(OffsetType.QUARTERS),
    fields: ["event_date"],
  },
  {
    text: "Next 6 Months",
    start: moment().add(1, OffsetType.MONTHS).startOf(OffsetType.MONTHS),
    end: moment().add(6, OffsetType.MONTHS).endOf(OffsetType.MONTHS),
    fields: ["event_date"],
  },
  {
    text: "Next 9 Months",
    start: moment().add(1, OffsetType.MONTHS).startOf(OffsetType.MONTHS),
    end: moment().add(9, OffsetType.MONTHS).endOf(OffsetType.MONTHS),
    fields: ["event_date"],
  },
  {
    text: "Next Year",
    start: moment(moment().year() + 1, yearFormat).startOf(OffsetType.YEARS),
    end: moment(moment().year() + 1, yearFormat).endOf(OffsetType.YEARS),
    fields: ["event_date"],
  },
  {
    text: "Next 365 Days",
    start: moment(),
    end: moment().add(364, OffsetType.DAYS),
    fields: ["event_date"],
  },
  {
    text: "Default",
    start: moment(moment().year() - 1, yearFormat).startOf(OffsetType.YEARS),
    end: moment(moment().year() - 1, yearFormat).endOf(OffsetType.YEARS),
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  },
  {
    text: "All",
    start: null,
    end: null,
    fields: ["po_date", "event_date", "pl_date", "invoice_date", "fill_date", "custom_date", "delivery_date", "other_cost_date"],
  }
];

export const getPresetRange = (fieldName: string, defaultDateRange: any) => {
  let range = {};
  preDefinedEndDate = preDefinedEndDate?.length > 0 ? [...preDefinedEndDate, moment(defaultDateRange.rangeTo).format("YYYY-MM-DD")] : [moment(defaultDateRange.rangeTo).format("YYYY-MM-DD")];
  preDefinedStartDate = preDefinedStartDate?.length > 0 ? [...preDefinedStartDate, moment(defaultDateRange.rangeFrom).format("YYYY-MM-DD")] : [moment(defaultDateRange.rangeFrom).format("YYYY-MM-DD")];
  for (let preset of presets) {
    if (preset.fields?.includes(fieldName)) {
      if (defaultDateRange && preset.text === "Default") {
        range[preset.text] = [
          defaultDateRange.initialStartDate,
          defaultDateRange.initialEndDate,
        ];
      } else if (defaultDateRange && preset.text === "All") {
        range[preset.text] = [
          defaultDateRange.rangeFrom,
          defaultDateRange.rangeTo,
        ]
      } else {
        range[preset.text] = [preset?.start, preset?.end];
      }
    }
  }
  
  return range;
};

//Time Functions

export const getCustomTime = (
  timeInput: string,
  format: string = "hh:mm A"
) => {
  timeInput = "01-03-2021 " + timeInput + "-0";

  return moment(timeInput).utc().format(format);
};

export const getCustomTimeFromDate = (
  dateInput: dateType,
  format: string = "hh:mm A"
) => {
  if (dateInput) {
    return moment(dateInput).format(format);
  }
};

export const getCurrentTime = (format: string = "hh:mm A") => {
  return moment().format(format);
};

export const getHoursInCustomTimeZone = (
  timeInput: dateType,
  timeZone: string
) => {
  return moment
    .tz(timeInput, timeZone)
    .set({ minute: 0, second: 0 })
    .format("HH:mm:ssZ");
};

export const getDefaultTimeInCustomTimeZone = (timeZone: string) => {
  const temp = getCurrentTimeInCustomTimeZone(timeZone);
  const disabledTimeString1 = "00:00:00-05:00";
  const disabledTimeString2 = "12:00:00-05:00";
  if (
    getHoursInCustomTimeZone(temp, timeZone) === disabledTimeString1 ||
    getHoursInCustomTimeZone(temp, timeZone) === disabledTimeString2
  ) {
    return moment().add(1, "hours").tz(timeZone).set({ minute: 0, second: 0 });
  }
  return moment().tz(timeZone).set({ minute: 0, second: 0 });
};

export const getTimeInCustomTimeZone = (time: dateType, timeZone: string) => {
  const disabledTimeString1 = "00:00:00-05:00";
  const disabledTimeString2 = "12:00:00-05:00";
  if (
    getHoursInCustomTimeZone(time, timeZone) === disabledTimeString1 ||
    getHoursInCustomTimeZone(time, timeZone) === disabledTimeString2
  ) {
    return moment(time)
      .add(1, "hours")
      .tz(timeZone)
      .set({ minute: 0, second: 0 });
  }
  return moment(time).tz(timeZone).set({ minute: 0, second: 0 });
};

export const getCurrentTimeInCustomTimeZone = (timeZone: string) => {
  return moment().tz(timeZone).set({ minute: 0, second: 0 });
};

// DateTime Functions

export const getDateTimeInCustomTimeZone = (
  date: dateType,
  timeZone: string
) => {
  return moment(date).tz(timeZone);
};

// Scheduler Functions
export const getNextRunAtTimeFormat = (date: string) => {
  return `${moment(date).format("ddd, MMM D, YYYY")} at ${moment(date).format(
    "h:mm A"
  )}`;
};

export const getTimeSchedulerDisplayFormat = (time: dateType) => {
  return moment(time).tz(TIMEZONE).format("hA");
};

export const getDisabledDate = () => {
  return moment().endOf(OffsetType.DAYS).subtract(1, OffsetType.DAYS);
};

export const getDisabledTime = (dateSelected: dateType) => {
  const disabledHours: number[] = [];
  const hourNow = moment(dateSelected).hour();
  for (let i = 0; i < 24; i++) {
    if (i < hourNow || i === 0) {
      disabledHours.push(i);
    }
  }
  //

  return disabledHours;
};

// Date Functions

export const getCurrentDate = (format: string = "YYYY-MM-DD") => {
  return moment().format(format);
};

export const getOffsetDate = (
  offsetMode: string,
  offsetValue: number,
  offsetType: any,
  format: string = "YYYY-MM-DD",
  dateValue: string | Date | moment.Moment = moment()
) => {
  if (offsetMode === OffsetMode.SUBTRACT) {
    return moment(dateValue).subtract(offsetValue, offsetType).format(format);
  } else if (offsetMode === OffsetMode.ADD) {
    return moment(dateValue).add(offsetValue, offsetType).format(format);
  }
  else {
    return null;
  }
};

export const getCustomDate = (
  dateString?: dateType,
  format: string = "YYYY-MM-DD"
) => {
  if (dateString) {
    return moment(dateString, "YYYY-MM-DD").format(format);
  }
  return moment().format(format);
};

export const getCustomDateTime = (
  dateString?: dateType,
  format: string = "YYYY-MM-DD"
) => {
  if (dateString) {
    return moment(dateString).format(format);
  }
  return moment().format(format);
};

export const getDateObject = (dateValue: dateType) => {
  if(dateValue !== null || dateValue !== undefined){
    return moment(dateValue);
  } else {
    return moment();
  }
};

export const getDateDiff = (
  start: dateType,
  end: dateType,
  diffType: "d" | "M" | "y" = "d"
) => {
  if (start && end) {
    return moment(start).diff(moment(end), diffType);
  }
};

export const getCurrentYear =() => {
  const start = moment().startOf(OffsetType.YEARS).format("YYYY-MM-DD");
  const end = moment().endOf(OffsetType.YEARS).format("YYYY-MM-DD");
  return [
    start,
    end
  ]
};
