import { Col, Layout, Pagination, Row } from "antd";
import CustomError from "controls/CustomError/CustomError";
import {
  IFilterOptions,
  IOtherCostAndCreditsReportData,
  IOtherCostAndCreditsReportDatum,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React from "react";
import { useState, useEffect } from "react";
import { OtherCostAndCreditsService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { formatThousandSeparator } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import { OtherCostsAndCreditsTableHeaders } from "./OtherCostsAndCreditsUtils";

interface IOtherCostsAndCreditsTable {
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
  outerLoader: boolean;
  setOuterLoader: Function;
}

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const OtherCostsAndCreditsTable: React.FC<IOtherCostsAndCreditsTable> = ({
  filterObject,
  appliedFilterValues,
  searchQuery,
  outerLoader,
  setOuterLoader,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "other_cost_created_date",
    sort_order: "desc",
    search_text: "",
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [otherCostsAndCreditsData, setOtherCostsAndCreditsData] =
    useState<IOtherCostAndCreditsReportData | null>(null);
  const [reportData, setReportData] = useState<
    IOtherCostAndCreditsReportDatum[] | null
  >();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  const handleHighlightedRow = (responseReportData: any) => {
    if (responseReportData) {
      responseReportData = responseReportData.map((eachReportData: any) => {
        return {
          ...eachReportData,
          activeSelectedClass: +eachReportData?.has_row_alert
            ? "redSelected"
            : null,
        };
      });
    }
    return responseReportData;
  };

  const getOtherCostsAndCreditsReportData = async (query: any) => {
    setOuterLoader(false);
    setShowLoader(true);
    try {
      const res =
        await new OtherCostAndCreditsService().getOtherCostsAndCredits({
          ...query,
        });
      let responseData = res.data.data;
      responseData.otherCostAndCreditsReports = handleHighlightedRow(
        res.data.data.otherCostAndCreditsReports
      );
      setOtherCostsAndCreditsData(responseData);
      setShowLoader(false);
      setReportData(responseData.otherCostAndCreditsReports);
      setDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getOtherCostsAndCreditsReportData",
      });
      setDataFetchError({
        ...error,
        refreshAction: getOtherCostsAndCreditsReportData,
      });
    }
  };

  useEffect(() => {
    if (queryParams != null) {
      getOtherCostsAndCreditsReportData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        search_text: searchQuery,
      });
    }
  }, [searchQuery]);

  const getViewTable = () => {
    if (!showLoader &&
        otherCostsAndCreditsData &&
        otherCostsAndCreditsData?.total) {
        return (
          <ul className="innerHeaderRight">
            <li>
              <h4>
                {otherCostsAndCreditsData?.total === 1
                  ? otherCostsAndCreditsData?.total + " Record Found"
                  : formatThousandSeparator(otherCostsAndCreditsData?.total) + " Records Found"}
              </h4>
            </li>
            <li>
              <Pagination
                className="pagination"
                hideOnSinglePage={true}
                //defaultCurrent={1}
                current={queryParams?.page_index}
                total={otherCostsAndCreditsData?.total}
                pageSize={queryParams?.page_size}
                onChange={(pageNumber) =>
                  setQueryParams({
                    ...queryParams,
                    page_index: pageNumber,
                  })
                }
                showSizeChanger={false}
              />
            </li>
          </ul>
        );
    }
    return (
      <div style={{ height: 21 }}></div>
    );
  }
  return (
    <LoadingSpinner isLoading={outerLoader}>
      <React.Fragment>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          queryParams && (
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="flx justifyContentBetween alignItemsCenter">
                  <Col span={14}>
                    <AppliedFilters appliedFilters={appliedFilterValues} />
                  </Col>
                  <Col span={10}>
                    { getViewTable() }
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <CommonReportTable
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    setQueryParams({
                      ...queryParams,
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                    });
                  }}
                  sortField={{
                    dataIndex: queryParams?.sort_column,
                    order: queryParams?.sort_order,
                  }}
                  headers={OtherCostsAndCreditsTableHeaders}
                  data={reportData ?? null}
                  headerData={otherCostsAndCreditsData?.aggregate.map((data) => {
                          return { ...data, invoice_id: "Total" };
                        })
                  }
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  loading={showLoader}
                />
              </div>
            </Content>
          )
        )}
      </React.Fragment>
    </LoadingSpinner>
  );
};

export default OtherCostsAndCreditsTable;
