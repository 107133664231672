import { IBrokerSummary } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import CommonReportTable from "utils/table/CommonReportTable";
import { brokerSummaryHeaders } from "./grossProfitSnapshotUtils";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { AppSetting } from "utils/appSettings";

const BrokerSummaryTable: React.FC<{
  queryParams: any;
}> = ({ queryParams }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [brokerSummaryData, setBrokerSummaryData] = useState<IBrokerSummary>();
  const [brokerSummaryError, setBrokerSummaryError] = useState<ICustomError | null>(null)

  const getBrokerSummaryData = async () => {
    try {
      if (!queryParams) return false;
      setIsLoading(true);
      const brokerSummaryResponseData =
        await new ProfitDashboardService().getBrokerSummary({
          ...queryParams,
          ...{ client_zone: AppSetting.TIMEZONE },
        });
      setBrokerSummaryData(brokerSummaryResponseData.data.data);
      setIsLoading(false);
      setBrokerSummaryError(null)
    } catch (error: any) {
      setIsLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getBrokerSummaryData'
      })
      setBrokerSummaryError({...error, refreshAction: getBrokerSummaryData})
    }
  };

  const formatData = (data: any) => {
    const normalData = data?.filter((item: any) => item.brokerId > 0);
    const flaggedData = data?.filter((item: any) => item.brokerId <= 0);
    return normalData && flaggedData ? normalData?.concat(flaggedData) : null;
  };

  useEffect(() => {
    getBrokerSummaryData();
  }, [queryParams]);

  

  return (
    brokerSummaryError
    ?
    <CustomError {...brokerSummaryError} />
    :
    <div className="innerComponent tblStyle2">
      <CommonReportTable
        headers={brokerSummaryHeaders}
        data={formatData(brokerSummaryData?.profitDashboardResponse)}
        tableClass={"customTbl"}
        footerData={brokerSummaryData?.aggregate}
        loading={isLoading}
      />
    </div>
  );
};

export default BrokerSummaryTable;
