import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Pagination } from "antd";
import CommonReportTable from "utils/table/CommonReportTable";
import { InventoryHeaders } from "./inventoryUtils";
import InventoryTabs from "../InventoryTabs";
import { LoggerService } from "sharedServices";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { AppSetting } from "utils/appSettings";
import { HouseKeepingService } from "services";
import { formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;
const CLIENT_ZONE = AppSetting.CLIENT_ZONE;
const ActiveInventoryTable: React.FC<{
  path: string;
  selectedInventories: any;
  setSelectedInventories: Function;
  searchQuery: string | null;
}> = ({ path, selectedInventories, setSelectedInventories, searchQuery }) => {
  const [queryParams, setQueryParams] = useState<any>(null);
  const [activeInventoryData, setActiveInventoryData] = useState<any | null>(
    null
  );
  const [showLoader, setShowLoader] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const [defaultQueryParams] = useState<any>({
    clientZone: CLIENT_ZONE,
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: PAGE_SIZE,
    sortColumn: "poNo",
    sortOrder: "desc",
  });

  const getActiveInventoryData = async (query: any) => {
    try {
      setShowLoader(true);
      const response = await new HouseKeepingService().getActiveInventoryList({
        ...query,
      });

      setActiveInventoryData(response.data.data);
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getActiveInventoryData",
      });

      setDataFetchError({ ...error, refreshAction: getActiveInventoryData });
    }
  };

  useEffect(() => {
    queryParams && getActiveInventoryData(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        pageIndex: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        pageIndex: DEFAULT_PAGE_INDEX,
        searchText: searchQuery,
      });
    }
  }, [searchQuery]);

  const getViewtable = () => {
    if (!showLoader && 
      activeInventoryData && 
      activeInventoryData?.total) {
        return (
          <ul className="innerHeaderRight">
            <li>
              <h4>
                {activeInventoryData?.total === 1
                  ? formatThousandSeparator(activeInventoryData?.total) +
                    " Record Found"
                  : formatThousandSeparator(activeInventoryData?.total) +
                    " Records Found"}
              </h4>
            </li>
            <li>
              <Pagination
                className="pagination"
                hideOnSinglePage={true}
                defaultCurrent={1}
                current={queryParams?.pageIndex}
                total={activeInventoryData?.total}
                pageSize={PAGE_SIZE}
                onChange={(pageNumber) =>
                  setQueryParams({
                    ...queryParams,
                    pageIndex: pageNumber,
                  })
                }
                showSizeChanger={false}
              />
            </li>
          </ul>
        );
    }
    return (
      <div style={{ height: 21 }}></div>
    ); 
  }

  return dataFetchError ? (
    <CustomError {...dataFetchError} />
  ) : (
    <Content className="mainContainer relative">
      <div className="cancelledShowsPagination">
        <Row className="flx justifyContentBetween alignItemsCenter">
          <Col span={24}>
            { getViewtable() }
          </Col>
        </Row>
      </div>
      <div className="tabContainer removeTblLRSpc">
        <InventoryTabs path={path} />
        <div>
          <CommonReportTable
            // tableClass='customTbl scroll-x-y fixedHeaderFooter'
            tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
            onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
              setQueryParams({
                ...queryParams,
                sortColumn: sortIndex,
                sortOrder: sortOrder,
              });
            }}
            sortField={{
              dataIndex: queryParams?.sortColumn,
              order: queryParams?.sortOrder,
            }}
            data={activeInventoryData?.activeInventoryData}
            headers={InventoryHeaders}
            loading={showLoader}
            otherData={{
              selectedInventories,
              setSelectedInventories,
            }}
          />
        </div>
      </div>
    </Content>
  );
};

export default ActiveInventoryTable;
