import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IListElement } from "models/interfaces";
import React from "react";

const SelectListers: React.FC<{
  assignedListers: IListElement[];
  listerIds: string[] | null;
  setListerIds: Function;
}> = ({ assignedListers, listerIds, setListerIds }) => {
  const { hasPermissions } = useRbac();
  
  const listerSelectionHandler = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (listerIds) {
      if (isChecked) {
        if (!listerIds.includes(value)) {
          setListerIds([...listerIds, value]);
        }
      } else {
        const ind = listerIds.findIndex((id) => id === value);
        listerIds.splice(ind, 1);

        setListerIds(listerIds);
      }
    } else {
      setListerIds([value]);
    }
  };
  return (
    <React.Fragment>
      {assignedListers && listerIds && assignedListers?.length > 0 && (
        <div id="clmListers" className="ant-col ant-col-xs-24 ant-col-sm-24">
          <div className="inputGroup">
            <label>Listers</label>
            <ul className={`checkboxList ant-row${!hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_LISTER_SELECTION,
                      ]) ? " elementDisabled" : ""}`}>
              {assignedListers?.map((data: any, ind: any) => (
                <li key={'lister_'+ind} className="ant-col ant-col-xs-24 ant-col-sm-8 ant-col-lg-8 ant-col-xl-8">
                  <Checkbox
                    defaultChecked={data.checked}
                    disabled={
                      !hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_LISTER_SELECTION,
                      ])
                    }
                    value={data.value}
                    onChange={listerSelectionHandler}
                  >
                    {data.label}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectListers;