import React, { useState } from "react";
import { Row, Tooltip, Typography } from "antd";
import { IFilterOptions } from "models/interfaces";
import RefreshIcon from "../../../../assets/images/reload-icon-white.svg";
import CreateSeatStructureCommon from "./CreateSeatStructureCommon";

const CreateSeatStructureData: React.FC<{
  filterObject: IFilterOptions | null;
  cols: number;
}> = ({ filterObject, cols }) => {
  const [isRefreshed, setIsRefreshed] = useState(false);
  
  return (
    <div className="dashboardCardWrap dashboardMidWrap">
      <div className="headingWithLoader">
        <h3>Create Seat Structure</h3>
        <Tooltip title="Reload" placement="right">
          <Typography.Link onClick={() => setIsRefreshed(true)}>
            <img
              src={RefreshIcon}
              alt="Refresh"
              style={{ verticalAlign: "middle", marginLeft: "10px" }}
            />
          </Typography.Link>
        </Tooltip>
      </div>
      <Row>
        <CreateSeatStructureCommon
          filterObject={filterObject}
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          newShowsOnly={true}
          label="New Shows (last 30 days)"
          apiName="getSeatMapCreatorSeatStructureData"
          cols={cols}
          labelColorClass="text-danger"
          paramObject={{ show_category_id: 1, exclude_sold_out_events: true, exclude_cancelled_events: true }}
        />
        <CreateSeatStructureCommon
          filterObject={filterObject}
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          newShowsOnly={false}
          label="All Other Shows"
          apiName="getSeatMapCreatorSeatStructureData"
          cols={cols}
          labelColorClass="text-danger"
          paramObject={{ show_category_id: 4, exclude_sold_out_events: true, exclude_cancelled_events: true }}
        />
        <CreateSeatStructureCommon
          filterObject={filterObject}
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          label="Seat Map TBD"
          apiName="getSeatMapCreatorSeatStructureData"
          showsSeatMapTbd={true}
          cols={cols}
          tagStyleType="nameOfDays"
          labelColorClass="text-danger"
          paramObject={{ show_category_id: 9, exclude_sold_out_events: true, exclude_cancelled_events: true }}
        />
      </Row>
    </div>
  )
}

export default CreateSeatStructureData;
