import { useEffect, useState } from "react";
import Routes from "routes/Routes";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "utils/sharedComponents/PageLoader";
import { hotjar } from 'react-hotjar';
import "App.css";
import "assets/css/common-style.css";
import "assets/css/custom-style.css";
import "assets/css/custom-dev-style.css";
import "assets/css/responsive.css";
import { auth0Security } from "utils/auth/auth0.security";
import AppLayout from "layout/AppLayout";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/GlobalSlice";

const App = () => {
  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const [isHotjarInitialized, setIsHotjarInitialized] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(()=>{
    dispatch(actions.getCalendarPresetsAction());
  });

  useEffect(() => {
    if(process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV){
      hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID), parseInt(process.env.REACT_APP_HOTJAR_SV));
      setIsHotjarInitialized(true);
      console.log('Hotjar initialized');
    }
    else{
      console.log('Hotjar not initialized');
    }
  }, []);

  useEffect(() => {
    
    if(isHotjarInitialized && !isLoading && user?.sub){
      hotjar.identify(user.sub, { email: user.email ? user.email : '', name: user.name ? user.name : '' });
      console.log('Hotjar identified');
    }
  }, [isHotjarInitialized, isLoading, user]);

  if (isLoading) {
    return <PageLoader />;
  }

  auth0Security.setAccessTokenSilently(getAccessTokenSilently);

  return (
    <AppLayout>
      <Routes />
    </AppLayout>
  );
};

export default App;
