import React from "react";
import { Checkbox } from "antd";

const SelectInventory: React.FC<{ data: any }> = ({ data }) => {
  const { selectedInventories, setSelectedInventories } = data.otherData;
  const inventoryData = data.data.singleData;

  const handleSelection = () => {
    const checked = selectedInventories.some(
      (item: any) => item.poLineNo === inventoryData.poLineNo
    );
    if (checked) {
      setSelectedInventories(
        selectedInventories.filter(
          (inv: any) => inv.poLineNo !== inventoryData.poLineNo
        )
      );
    } else {
      setSelectedInventories([...selectedInventories, inventoryData]);
    }
  };

  return (
    <>
      <Checkbox
        checked={selectedInventories.some(
          (item: any) => item.poLineNo === inventoryData.poLineNo
        )}
        onChange={handleSelection}
      />
    </>
  );
};

export default SelectInventory;
