import { FixedColumnDirection } from "models/enums/table";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import { formatDateGivenFormatString, getCustomDate } from "utils/formatTimePeriod";
import {addToolTip, formatNumericValues, formatAccountingValues} from 'utils/commonFunction'
import { Typography } from "antd";
import React from "react";
import { AppSetting } from "utils/appSettings";

const commonWidth = 100;

export const ReceivablesDetailsTableHeaders = [
  {
    title: "P&L Date",
    dataIndex: "pl_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (plDate: string) =>
      plDate && getCustomDate(plDate, AppSetting.REPORT_DATE_FORMAT),
    dataHeaderRender: (plDateHeader: string) => (
      <div className="text-left">{plDateHeader}</div>
    ),
  },
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Sale Type",
    dataIndex: "sale_type",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Sale Category",
    dataIndex: "sale_category",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Invoice Date",
    dataIndex: "invoice_date",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceDate: string) =>
      invoiceDate && getCustomDate(invoiceDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Payment Status",
    dataIndex: "payment_status",
    canSort: true,
    dataRender: (paymentStatus: string) => <div className="text-capitalize">{paymentStatus}</div>
  },
  {
    title: "Aging",
    dataIndex: "aging",
    canSort: true,
    tdClassName: "text-right",
    thClassName: "text-right",
    dataRender: (data: number) => formatNumericValues(data)
  },
  {
    title: "Aging Period",
    dataIndex: "aging",
    // canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (aging: any, otherData: any) => {
      const className = getAgingPeriodTagClasses(aging);
      
      return (
          <div className="flx justifyContentCenter">
            <div className={`cursorDefault ${className}`} style={{ width: 75 }}>
              {otherData.data.singleData.aging_period_title}
            </div>
        </div>
      ); 
    },
  },
  {
    title: "Outstanding Balance",
    dataIndex: "outstanding_balance",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (outstandingBalance: number) => (
      <div className={`text-right ${outstandingBalance < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(outstandingBalance)}
      </div>
    ),
    dataHeaderRender: (outstandingBalanceHeader: any) => (
      <div
        className={`text-right ${outstandingBalanceHeader < 0 ? "text-danger" : ""}`}
      >
        {outstandingBalanceHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(outstandingBalanceHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(outstandingBalanceHeader)}
      </div>
    ),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: 70,
    thClassName: "text-right",
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
    dataHeaderRender: (qtyHeader: number) => (
      <div className={`text-right ${qtyHeader < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qtyHeader)}
      </div>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currency_code",
    canSort: true,
    width: commonWidth,
    dataRender: (currencyCode: any) => (
      <div className="text-upper">{currencyCode}</div>
    ),
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticket_cost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketCost: number) => (
      <div className={`text-right ${ticketCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketCost)}
      </div>
    ),
    dataHeaderRender: (ticketCostHeader: any) => (
      <div
        className={`text-right ${ticketCostHeader < 0 ? "text-danger" : ""}`}
      >
        {ticketCostHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketCostHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(ticketCostHeader)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticket_sales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketSales: number) => (
      <div className={`text-right ${ticketSales < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketSales)}
      </div>
    ),
    dataHeaderRender: (ticketSalesHeader: any) => (
      <div
        className={`text-right ${ticketSalesHeader < 0 ? "text-danger" : ""}`}
      >
        {ticketSalesHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketSalesHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(ticketSalesHeader)}
      </div>
    ),
  },
  {
    title: "Profit (Loss)",
    dataIndex: "p&l_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (profitLossCalculated: number) => (
      <div className={`text-right ${profitLossCalculated < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(profitLossCalculated)}
      </div>
    ),
    dataHeaderRender: (profitLossCalculatedHeader: any) => (
      <div
        className={`text-right ${profitLossCalculatedHeader < 0 ? "text-danger" : ""}`}
      >
        {profitLossCalculatedHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(profitLossCalculatedHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(profitLossCalculatedHeader)}
      </div>
    ),
  },
  {
    title: "External Reference",
    dataIndex: "external_ref",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (customer: any) =>
      (customer && customer.length) >= 18 ? (
        addToolTip(customer,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div >{customer}</div>
      ),
  },
  {
    title: "Purchase ID",
    dataIndex: "purchase_id",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number[]) =>
      purchaseId &&
      purchaseId.length &&
      purchaseId.map((id, index) => {
        return (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + id}
              underline={true}
            >
              {`${id}`}
            </Typography.Link>
            {`${index < purchaseId.length - 1 ? ", " : ""}`}
          </React.Fragment>
        );
      }),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (vendor: any) =>
      (vendor && vendor.length) >= 18 ? (
        addToolTip(vendor,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{vendor}</div>
      ),
  },
  {
    title: "Event",
    dataIndex: "event_name",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.event_id}
      >
        {event?.length > 40 ? addToolTip(event,"right", "pointer", 40, false, {textTransform: "capitalize"}): event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "event_date",
    canSort: true,
    width: commonWidth,
    dataRender: (eventDate: string) =>
      eventDate &&
      formatDateGivenFormatString(eventDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    canSort: true,
    width: commonWidth,
    dataRender: (daysToEvent: any) => {
      let className = "";
      if (daysToEvent !== null) {
        if (daysToEvent > 30) className = "ant-tag success";
        else if (daysToEvent >= 15) className = "ant-tag warning";
        else if (daysToEvent <= 14) className = "ant-tag danger";
      }

      return (
        <div className={`${className}`} style={{ float: "right" }}>
          {daysToEvent}
        </div>
      );
    },
  },
  {
    title: "Venue",
    dataIndex: "venue_name",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (venue: any) =>
      (venue && venue.length) >= 18 ? (
        addToolTip(venue,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{venue}</div>
      ),
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillment_status",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (fulfillmentStatus: any) => (
      <div>{fulfillmentStatus}</div>
    ),
  },
  {
    title: "Fulfillment Date",
    dataIndex: "fulfillment_date",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentDate: string) =>
      fulfillmentDate &&
      getCustomDate(fulfillmentDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventory_tags",
    canSort: true,
    width: commonWidth,
    dataRender: (inventoryTags: any) => (
      <div>{inventoryTags}</div>
    ),
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoice_tags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Event Tags",
    dataIndex: "event_tags",
    canSort: true,
    width: commonWidth,
  }
];

const getAgingPeriodTagClasses = (aging: number) => {
  if (aging > 90)
    return "ant-tag danger agingPeriod";
  if (aging > 60 && aging <= 90)
    return"ant-tag semi-danger";
  if (aging > 30 && aging <= 60)
    return "ant-tag warning";
  return "ant-tag success";
}
