import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import { LongInventoryService, LongInventoryNotListedService } from "services";
import {
  IFilterOptions,
  LongInventoryData,
} from "models/interfaces";
import CommonReportTable from "utils/table/CommonReportTable";
import { longInventoryHeaders } from "./longInventoryUtils";
import { useLocation } from "react-router-dom";
import { LoggerService } from "sharedServices/api/logger.service";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";

import LIPriceIssueResolution from "./LIPriceIssueResolution";
import { formatReportCount, formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const usePathname = () => {
  const location = useLocation();

  return location.pathname;
};

const LongInventoryReportTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
}> = ({ filterObject, appliedFilterValues, searchQuery }) => {
  const path = usePathname();

  const [defaultQueryParams] = useState<any>({
    client_zone: "America/New_York",
    currency_Id: null,
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "po_date",
    sort_order: "desc",
    search_text: "",
  });
  const [longInventoryData, setLongInventoryData] =
    useState<LongInventoryData | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [queryParams, setQueryParams] = useState<any>(null);
  const [inventoryDataFetchError, setInventoryDataFetchError] =
    useState<ICustomError | null>(null);

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
        search_text: searchQuery,
      });
    }
  }, [defaultQueryParams, filterObject, searchQuery]);

  const getInventoryData = async (query: any) => {
    setShowLoader(true);
    let response;
    query = query || queryParams;
    try {
      if (path.endsWith("inventory-long")) {
        response = await new LongInventoryService().longInventoryData({
          ...query,
        });
      } else {
        response = await new LongInventoryNotListedService().longInventoryData({
          ...query,
        });
      }

      const responseData = response.data.data;

      if (responseData.inventory) {
        responseData.inventory = responseData.inventory.map((eachData) => {
          return {
            ...eachData,
            activeSelectedClass:
              eachData.days_to_event < 15 &&
              eachData.list_price == 0 &&
              "redSelected",
          };
        });
      }

      setShowLoader(false);
      setLongInventoryData(responseData);
      setInventoryDataFetchError(null);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getInventoryData",
      });
      setShowLoader(false);
      setInventoryDataFetchError({ ...error, refreshAction: getInventoryData });
    }
  };

  useEffect(() => {
    queryParams && getInventoryData(queryParams);
  }, [queryParams]);

  const setPageSize = () => {
    if (queryParams) {
      return queryParams.page_size;
    }
    return 1;
  }

  return (
    <React.Fragment>
      {inventoryDataFetchError ? (
        <CustomError {...inventoryDataFetchError} />
      ) : (
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row className="flx justifyContentBetween alignItemsCenter">
              <Col span={14}>
                <AppliedFilters appliedFilters={appliedFilterValues} />
              </Col>
              <Col span={10}>
                {!showLoader && longInventoryData && longInventoryData.total > 0 && (
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>
                        {formatThousandSeparator(longInventoryData.total)}
                        {formatReportCount(longInventoryData.total)}
                      </h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        hideOnSinglePage={true}
                        current={queryParams?.page_index}
                        total={longInventoryData.total}
                        pageSize={setPageSize()}
                        onChange={(pageNumber) =>
                          setQueryParams({
                            ...queryParams,
                            page_index: pageNumber,
                          })
                        }
                        showSizeChanger={false}
                      />
                    </li>
                  </ul>
                )}
                {!(!showLoader && longInventoryData && longInventoryData.total > 0) &&
                  <div style={{ height: 21 }}></div>
                }
              </Col>
            </Row>
          </div>
          <div className="removeTblLRSpc">
            <CommonReportTable
              onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                setQueryParams({
                  ...queryParams,
                  sort_column: sortIndex,
                  sort_order: sortOrder,
                });
              }}
              sortField={{
                dataIndex: queryParams?.sort_column,
                order: queryParams?.sort_order,
              }}
              headers={longInventoryHeaders}
              data={longInventoryData?.inventory ?? null}
              tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
              loading={showLoader}
            />
          </div>
        </Content>
      )}
      <LIPriceIssueResolution getInventoryData={getInventoryData} />
    </React.Fragment>
  );
};

export default LongInventoryReportTable;
