import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import { GrossProfitService } from "services";
import CommonReportTable from "utils/table/CommonReportTable";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { LoggerService } from "sharedServices/api/logger.service";
import { GrossProfitData, IFilterOptions } from "models/interfaces";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";
import { usePageTitle } from "hooks";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { formatThousandSeparator } from "utils/commonFunction";
import { invoiceNeedingAttentionHeaders } from "./InvoicesNeedingAttentionUtils";
import SoldInventoryIssueResolution from "./SoldInventoryIssueResolution";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const InvoicesNeedingAttentionTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
  outerLoader: boolean;
  setOuterLoader: Function;
}> = ({
  filterObject,
  appliedFilterValues,
  searchQuery,
  outerLoader,
  setOuterLoader,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "pl_date",
    sort_order: "desc",
    keywords: "",
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [invoicesNeedingAttention, setInvoicesNeedingAttention] =
    useState<GrossProfitData | null>(null);
  const [reportData, setReportData] = useState<any | null>();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  usePageTitle("Invoices Needing Attention");

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  const getInvoicesNeedingAttentionData = async (query: any) => {
    setOuterLoader(false);
    setShowLoader(true);
    try {
      let response;
      let responseData;
      response = await new GrossProfitService().getInvoicesNeedingAttention(
        {
          ...query,
        }
      );
      responseData = response.data.data;
      responseData.invoicesNeedingAttentionReport = handleHighlightedRow(
        response.data.data.invoicesNeedingAttentionReport
      );
      setReportData(responseData.invoicesNeedingAttentionReport);

      setShowLoader(false);

      setInvoicesNeedingAttention(responseData);
      setDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getInvoicesNeedingAttentionData",
      });
      setDataFetchError({
        ...error,
        refreshAction: getInvoicesNeedingAttentionData,
      });
    }
  };

  useEffect(() => {
    if (queryParams != null) {
      getInvoicesNeedingAttentionData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      });
    }
  }, [searchQuery]);

  const handleHighlightedRow = (responseReportData: any) => {
    if (responseReportData) {
      responseReportData = responseReportData.map((eachReportData: any) => {
        return {
          ...eachReportData,
          activeSelectedClass: eachReportData?.has_row_alert
            ? "redSelected"
            : null,
        };
      });
    }

    return responseReportData;
  };

  const getViewTable = () => {
    if (
      !showLoader &&
      invoicesNeedingAttention &&
      invoicesNeedingAttention?.total
    ) {
      return (
        <ul className="innerHeaderRight">
          <li>
            <h4>
              {invoicesNeedingAttention?.total === 1
                ? invoicesNeedingAttention?.total + " Record Found"
                : formatThousandSeparator(invoicesNeedingAttention?.total) +
                  " Records Found"}
            </h4>
          </li>
          <li>
            <Pagination
              className="pagination"
              hideOnSinglePage={true}
              current={queryParams?.page_index}
              total={invoicesNeedingAttention?.total}
              pageSize={queryParams?.page_size}
              onChange={(pageNumber) =>
                setQueryParams({
                  ...queryParams,
                  page_index: pageNumber,
                })
              }
              showSizeChanger={false}
            />
          </li>
        </ul>
      );
    }
    return <div style={{ height: 21 }}></div>;
  };

  return (
    <LoadingSpinner isLoading={outerLoader}>
      <React.Fragment>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          queryParams && (
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="flx justifyContentBetween alignItemsCenter">
                  <Col xs={24} sm={24} md={14} className="gpAppliedFilter">
                    <AppliedFilters appliedFilters={appliedFilterValues} />
                  </Col>
                  <Col xs={24} sm={24} md={10}>
                    {getViewTable()}
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <CommonReportTable
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    setQueryParams({
                      ...queryParams,
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                    });
                  }}
                  sortField={{
                    dataIndex: queryParams?.sort_column,
                    order: queryParams?.sort_order,
                  }}
                  headers={invoiceNeedingAttentionHeaders}
                  data={(() => {
                    return invoicesNeedingAttention ? reportData : null;
                  })()}
                  headerData={invoicesNeedingAttention?.aggregate.map(
                    (data) => {
                      return { ...data, pl_date: "Total" };
                    }
                  )}
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  loading={showLoader}
                />
                <SoldInventoryIssueResolution queryParams={queryParams} getInvoicesNeedingAttentionData={getInvoicesNeedingAttentionData}/>
              </div>
            </Content>
          )
        )}
      </React.Fragment>
    </LoadingSpinner>
  );
};

export default InvoicesNeedingAttentionTable;
