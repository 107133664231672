import React, { useEffect, useState } from "react";
import { Button, message, Modal, Table, Tooltip } from "antd";
import { ICopyDownMetadataEvent } from "models/interfaces";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { EventService } from "services";
import { getCustomDate, getCustomTimeFromDate } from "utils/formatTimePeriod";
import { addToolTip, getEncodedData } from "utils/commonFunction";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";

const CopyDownMetadataModal: React.FC<{
  requestedEventId: number;
  setShowCopyDownMetadata: Function;
}> = ({ requestedEventId, setShowCopyDownMetadata }) => {
  const [isLoadingModalData, setIsLoadingModalData] = useState(false);
  const [events, setEvents] = useState<ICopyDownMetadataEvent[]>([]);
  const [selectedEventsIds, setSelectedEventsIds] = useState<number[]>([]);
  const [successEventsIds, setSuccessEventsIds] = useState<number[] | null>(
    null
  );
  const [isSubmittingCopyDown, setIsSubmittingCopyDown] = useState(false);

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedEventsIds(selectedRowKeys);
  };
  const rowSelection: any = {
    selectedRowKeys: selectedEventsIds,
    onChange: onSelectChange,
    getCheckboxProps: () => ({
      disabled: successEventsIds ? true : false,
    }),
  };

  const getEventForCopyDownMetaData = async () => {
    setIsLoadingModalData(true);
    try {
      const response = await new EventService().getEventsForCopyDownMetadata(
        requestedEventId
      );
      if (response.data.data.events) {
        const eventsList = response.data.data.events.map((event) => {
          return {
            ...event,
            key: event.event_id,
          };
        });
        const _selectedEventsIds = eventsList.map((event) => event.key);
        setSelectedEventsIds(_selectedEventsIds);
        setEvents(eventsList);
      }
    } catch (error) {
      message.error({
        content: "Failed to get events for copy down metadata.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      await new LoggerService().log({
        payload: error,
        function_name: "getEventForCopyDownMetaData",
      });
      setShowCopyDownMetadata(false);
    }
    setIsLoadingModalData(false);
  };

  const doCopyDownMetaData = async () => {
    if (selectedEventsIds && selectedEventsIds.length) {
      setIsSubmittingCopyDown(true);
      try {
        const response = await new EventService().saveEventsForCopyDownMetadata(
          requestedEventId,
          selectedEventsIds
        );
        setSuccessEventsIds(
          response.data.data.success_event_ids
            ? response.data.data.success_event_ids
            : []
        );
      } catch (error) {
        message.error({
          content: "Failed to copy down metadata.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
        await new LoggerService().log({
          payload: error,
          function_name: "doCopyDownMetaData",
        });
      }
      setIsSubmittingCopyDown(false);
    }
  };

  useEffect(() => {
    getEventForCopyDownMetaData();
  }, []);

  const columns = [
    {
      title: "EVENT NAME",
      dataIndex: "event_name",
      render: (eventName: string, record: ICopyDownMetadataEvent) => (
        <div className="eventUrl">
          <a
            target="_blank"
            rel="noreferrer"
            href={
              `${LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY}?event=${getEncodedData({event_id: record.event_id})}`
            }
            className="text-capitalize"
          >
            {eventName?.length > 25
              ? addToolTip(eventName,"right", "pointer", 25)
              : eventName}
          </a>

          {successEventsIds && selectedEventsIds.length && (
            <React.Fragment>
              {selectedEventsIds.includes(record.event_id) &&
                successEventsIds.includes(record.event_id) && (
                  <Tooltip title="Success">
                    <i className="da icon-tick-circle"></i>
                  </Tooltip>
                )}
              {selectedEventsIds.includes(record.event_id) &&
                !successEventsIds.includes(record.event_id) && (
                  <Tooltip title="Failed">
                    <i className="da icon-cross-circle"></i>
                  </Tooltip>
                )}
            </React.Fragment>
          )}
        </div>
      ),
    },
    {
      title: "DATE AND TIME",
      dataIndex: "event_date",
      render: (eventDate: string) =>
        eventDate &&
        `${getCustomDate(
          eventDate,
          "ddd, MMM D, YYYY"
        )} at ${getCustomTimeFromDate(eventDate)}`,
    },
  ];

  return (
    <Modal
      closable={false}
      width={530}
      title={false}
      footer={false}
      visible={true}
      className="editModal"
    >
      <div className="modalHeader">Copy to Other Events</div>
      <LoadingSpinner isLoading={isLoadingModalData}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={events}
          pagination={false}
          scroll={{ y: 240 }}
          className="customTbl"
        />

        <div className="modalCustomFooter">
          <Button
            className="linkBtn"
            onClick={() => {
              setShowCopyDownMetadata(false);
            }}
          >
            {successEventsIds ? "CLOSE" : "CANCEL"}
          </Button>
          {!successEventsIds && (
            <Button
              className="ant-btn btnOk"
              onClick={doCopyDownMetaData}
              disabled={
                selectedEventsIds.length === 0 || isSubmittingCopyDown
                  ? true
                  : false
              }
              loading={isSubmittingCopyDown}
            >
              COPY DOWN
            </Button>
          )}
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

export default CopyDownMetadataModal;
