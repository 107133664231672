import React, { useRef, useState } from "react";
import {
  IMenuOption,
  IPurchaseQueueAuditHistory,
  ISwimlaneMaster,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { Modal } from "antd";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CustomError from "controls/CustomError/CustomError";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { AppSetting } from "utils/appSettings";

const ViewAuditHistory: React.FC<{
  menuData: IMenuOption;
  invoice_id: number;
  setShowMenu: Function;
  swimlaneData: ISwimlaneMaster;
}> = ({ menuData, invoice_id, setShowMenu, swimlaneData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const [purchaseAuditHistoryList, setPurchaseAuditHistoryList] = useState<
    IPurchaseQueueAuditHistory[] | null
  >(null);

  const [shippingAuditHistoryList, setShippingAuditHistoryList] = useState<
    IPurchaseQueueAuditHistory[] | null
  >(null);

  const [showPurchaseAuditHistory, setShowPurchaseAuditHistory] = useState(
    swimlaneData.queue_type === 2 ? false : true
  );

  let style = {};

  const getAuditHistory = async () => {
    setDataFetchError(null);
    try {
      setShowLoader(true);
      const response = await new PurchaseAndShippingService().getAuditHistory(
        invoice_id
      );
      const responseData = response.data.data ?? [];
      setPurchaseAuditHistoryList([
        ...responseData.filter((eachData) => eachData.queue_type === 1),
      ]);
      setShippingAuditHistoryList([
        ...responseData.filter((eachData) => eachData.queue_type === 2),
      ]);

      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      setDataFetchError({ ...error, refreshAction: getAuditHistory });
      new LoggerService().log({
        payload: error,
        function_name: "getAuditHistory",
      });
    }
  };

  const changeUpdatedBy = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    let updated_by = eachHistoryData.updated_by_name;
    if (eachHistoryData?.is_updated_by_deleted) {
      /**style = {
        color: "#ff4d4f",
      };*/
      updated_by = "Unknown";
    }
    return updatedString.replaceAll("{{updated_by}}", updated_by);
  };

  const changeLaneName = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{lane_name}}",
      eachHistoryData.swimlane_name
    );
  };

  const changeOnSaleDate = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{on_sale_date}}",
      eachHistoryData.on_sale_date_formatted ?? ""
    );
  };

  const changeFromBuyerName = (
    lastEachHistoryData: IPurchaseQueueAuditHistory | null,
    updatedString: string
  ) => {
    let buyer_name = lastEachHistoryData?.buyer_name ?? "";
    if (lastEachHistoryData?.is_buyer_deleted) {
      /**setStyle({
        color: "#ff4d4f",
      });*/
      buyer_name = "Unknown";
    }
    return updatedString.replaceAll("{{from_buyer_name}}", buyer_name);
  };

  const changeToBuyerName = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    let buyer_name = eachHistoryData.buyer_name ?? "";
    if (eachHistoryData?.is_buyer_deleted) {
      /**setStyle({
        color: "#ff4d4f",
      });*/
      buyer_name = "Unknown";
    }
    return updatedString.replaceAll("{{to_buyer_name}}", buyer_name);
  };

  const changePriorityName = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{priority_name}}",
      eachHistoryData.priority_name
    );
  };

  const changeIssueTypeName = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{issue_type_name}}",
      eachHistoryData.issue_type_name ?? ""
    );
  };

  const changeUpdatedOn = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{updated_on}}",
      formatDateGivenFormatString(
        eachHistoryData.updated_on,
        "MMM DD, YYYY h:mm A"
      )
    );
  };

  const replaceIssueReminderPlaceholder = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{issues_reminder_date}}",
      formatDateGivenFormatString(
        eachHistoryData.issues_reminder_date,
        "MMM DD, YYYY"
      )
    );
  };

  const replaceIssueResolutionPlaceholder = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{issue_resolution_reason}}",
      eachHistoryData.issue_resolution_reason
    );
  };

  const replaceOnSaleReminderDatePlaceholder = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    if (eachHistoryData.on_sale_reminder_date) {
      return updatedString.replaceAll(
        "{{on_sale_reminder_date}}",
        formatDateGivenFormatString(
          eachHistoryData.on_sale_reminder_date,
          "MMM DD, YYYY"
        )
      );
    } else {
      return updatedString.replaceAll("{{on_sale_reminder_date}}", "N/A");
    }
  };

  const replaceShippingIssueTypePlaceholder = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    return updatedString.replaceAll(
      "{{shipping_issue_type_name}}",
      eachHistoryData.shipping_issue_type_name ?? ""
    );
  };

  const changeFromShipperName = (
    lastEachHistoryData: IPurchaseQueueAuditHistory | null,
    updatedString: string
  ) => {
    let shipper_name = lastEachHistoryData?.shipper_name ?? "";
    if (lastEachHistoryData?.is_shipper_deleted) {
      /**setStyle({
        color: "#ff4d4f",
      });*/
      shipper_name = "Unknown";
    }
    return updatedString.replaceAll("{{from_shipper_name}}", shipper_name);
  };

  const changeToShipperName = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    updatedString: string
  ) => {
    let shipper_name = eachHistoryData.shipper_name ?? "";
    if (eachHistoryData?.is_shipper_deleted) {
      /**setStyle({
        color: "#ff4d4f",
      });*/
      shipper_name = "Unknown";
    }
    return updatedString.replaceAll("{{to_shipper_name}}", shipper_name);
  };

  const changeTemplateString = (
    string: string,
    eachHistoryData: IPurchaseQueueAuditHistory,
    lastEachHistoryData: IPurchaseQueueAuditHistory | null
  ) => {
    let updatedString = string;
    let className = "";
    updatedString = changeUpdatedBy(eachHistoryData, updatedString);
    updatedString = changeLaneName(eachHistoryData, updatedString);
    updatedString = changeOnSaleDate(eachHistoryData, updatedString);
    updatedString = changeFromBuyerName(lastEachHistoryData, updatedString);
    updatedString = changeToBuyerName(eachHistoryData, updatedString);
    updatedString = changePriorityName(eachHistoryData, updatedString);
    updatedString = changeIssueTypeName(eachHistoryData, updatedString);
    updatedString = replaceIssueReminderPlaceholder(
      eachHistoryData,
      updatedString
    );
    updatedString = replaceIssueResolutionPlaceholder(
      eachHistoryData,
      updatedString
    );
    updatedString = replaceOnSaleReminderDatePlaceholder(
      eachHistoryData,
      updatedString
    );
    updatedString = replaceShippingIssueTypePlaceholder(
      eachHistoryData,
      updatedString
    );
    updatedString = changeUpdatedOn(eachHistoryData, updatedString);
    updatedString = changeFromShipperName(lastEachHistoryData, updatedString);
    updatedString = changeToShipperName(eachHistoryData, updatedString);

    let _style = {};
    if (string !== updatedString) {
      _style = {
        ...style,
        fontWeight: "bold",
      };
    }

    return {
      string: updatedString,
      className: className,
      style: _style,
    };
  };

  const historyFormattedFromTemplate = (
    eachHistoryData: IPurchaseQueueAuditHistory,
    lastEachHistoryData: IPurchaseQueueAuditHistory | null
  ) => {
    let messageTemplate = eachHistoryData.message_template;
    let showNotes = eachHistoryData.show_audit_notes;

    if (eachHistoryData.action_mode.toUpperCase() === "C") {
      showNotes = true;
      if (eachHistoryData.swimlane_id === 1) {
        messageTemplate =
          "{{updated_by}} assigned the invoice to {{to_buyer_name}} and moved to {{lane_name}} as {{priority_name}} priority on {{updated_on}}";
      } else if (eachHistoryData.swimlane_id === 3) {
        messageTemplate =
          "{{updated_by}} assigned the invoice to {{to_buyer_name}} and moved to {{lane_name}}({{on_sale_date}}) as {{priority_name}} priority on {{updated_on}}";
      } else if (eachHistoryData.swimlane_id === 4) {
        messageTemplate =
          "{{updated_by}} assigned the invoice to {{to_buyer_name}} and moved to {{lane_name}}({{issue_type_name}}) as {{priority_name}} priority on {{updated_on}}";
      }
    }

    if (!messageTemplate) {
      messageTemplate =
        "{{updated_by}} moved the invoice to {{lane_name}} on {{updated_on}}";
    }

    const parts: any = messageTemplate.split(" ");
    for (let i = 0; i < parts.length; i++) {
      const templateStringData = changeTemplateString(
        parts[i],
        eachHistoryData,
        lastEachHistoryData
      );
      parts[i] = (
        <span
          className={templateStringData.className}
          style={templateStringData.style}
          key={i}
        >
          {templateStringData.string}{" "}
        </span>
      );
    }

    return {
      message: parts,
      notes: showNotes ? eachHistoryData.notes : "",
    };
  };

  const getAuditHistoryJsx = () => {
    if (dataFetchError) {
      return <CustomError {...dataFetchError} />;
    } else if (
      !shippingAuditHistoryList?.length &&
      !purchaseAuditHistoryList?.length
    ) {
      return (
        <div className="text-center">
          <h3>No History Found</h3>
        </div>
      );
    } else if (
      !shippingAuditHistoryList?.length &&
      purchaseAuditHistoryList?.length
    ) {
      return (
        <React.Fragment>
          {swimlaneData.queue_type === 2 && (
            <div className="purchaseTitleWrap">
              <h3
                onClick={() =>
                  setShowPurchaseAuditHistory(!showPurchaseAuditHistory)
                }
                className={
                  showPurchaseAuditHistory
                    ? "purchaseTitle active"
                    : "purchaseTitle"
                }
              >
                Purchase <i className="da icon-arrow-down-dark"></i>
              </h3>
            </div>
          )}
          <div
            className={
              showPurchaseAuditHistory
                ? "purchaseAuditHistoryArea active"
                : "purchaseAuditHistoryArea"
            }
          >
            <ul className="auditHistoryList">
              {purchaseAuditHistoryList?.map((eachAudit, index) => {
                const historyData = historyFormattedFromTemplate(
                  eachAudit,
                  purchaseAuditHistoryList[index + 1] ?? null
                );
                return (
                  <li key={eachAudit.audit_id}>
                    <div>
                      {historyData.message}
                      {historyData.notes && (
                        <div className="noteBlk">
                          <div>
                            <strong>Note:</strong>
                          </div>
                          <div>{historyData.notes}</div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </React.Fragment>
      );
    } else if (
      !purchaseAuditHistoryList?.length &&
      shippingAuditHistoryList?.length
    ) {
      return (
        <React.Fragment>
          <h3>Shipping</h3>
          <div
            className="shippingAuditHistoryArea"
            style={{
              maxHeight: `${shippingRef.current?.offsetHeight}px`,
            }}
          >
            <ul className="auditHistoryList" ref={shippingRef}>
              {shippingAuditHistoryList?.map((eachAudit, index) => {
                const historyData = historyFormattedFromTemplate(
                  eachAudit,
                  shippingAuditHistoryList[index + 1] ?? null
                );
                return (
                  <li key={eachAudit.audit_id}>
                    <div>
                      {historyData.message}
                      {historyData.notes && (
                        <div className="noteBlk">
                          <div>
                            <strong>Note:</strong>
                          </div>
                          <div>{historyData.notes}</div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h3>Shipping</h3>
          <div
            className="shippingAuditHistoryArea"
            style={{
              maxHeight: `${shippingRef.current?.offsetHeight}px`,
            }}
          >
            <ul className="auditHistoryList" ref={shippingRef}>
              {shippingAuditHistoryList?.map((eachAudit, index) => {
                const historyData = historyFormattedFromTemplate(
                  eachAudit,
                  shippingAuditHistoryList[index + 1] ?? null
                );
                return (
                  <li key={eachAudit.audit_id}>
                    <div>
                      {historyData.message}
                      {historyData.notes && (
                        <div className="noteBlk">
                          <div>
                            <strong>Note:</strong>
                          </div>
                          <div>{historyData.notes}</div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {swimlaneData.queue_type === 2 && (
            <div className="purchaseTitleWrap">
              <h3
                onClick={() =>
                  setShowPurchaseAuditHistory(!showPurchaseAuditHistory)
                }
                className={
                  showPurchaseAuditHistory
                    ? "purchaseTitle active"
                    : "purchaseTitle"
                }
              >
                Purchase <i className="da icon-arrow-down-dark"></i>
              </h3>
            </div>
          )}
          <div
            className={
              showPurchaseAuditHistory
                ? "purchaseAuditHistoryArea active"
                : "purchaseAuditHistoryArea"
            }
          >
            <ul className="auditHistoryList">
              {purchaseAuditHistoryList?.map((eachAudit, index) => {
                const historyData = historyFormattedFromTemplate(
                  eachAudit,
                  purchaseAuditHistoryList[index + 1] ?? null
                );
                return (
                  <li key={eachAudit.audit_id}>
                    <div>
                      {historyData.message}
                      {historyData.notes && (
                        <div className="noteBlk">
                          <div>
                            <strong>Note:</strong>
                          </div>
                          <div>{historyData.notes}</div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </React.Fragment>
      );
    }
  };

  const shippingRef = useRef<HTMLUListElement | null>(null);

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          getAuditHistory();
          setShowModal(true);
          setShowMenu(false);
        }}
      >
        {menuData.name}
      </div>
      <Modal
        closable={true}
        width={900}
        title={false}
        footer={false}
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <div className="modalHeader">
          Audit History
          <div className="invoiceId mb0">
            <label>Invoice Id:</label>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={AppSetting.INVOICE_ID_URL + invoice_id}
            >
              {invoice_id}
            </a>
          </div>
        </div>
        <div className="modalCustomBody">
          <LoadingSpinner isLoading={showLoader}>
            {!showLoader && getAuditHistoryJsx()}
          </LoadingSpinner>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ViewAuditHistory;
