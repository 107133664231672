import { Modal } from "antd";

const { confirm } = Modal;

export function showConfirm(
    header: string|JSX.Element,
    body: string|JSX.Element,
    data: any,
    confirmHandler: Function,
    cancelHandler: Function,
    width: string = "500px"
) {
  confirm({
    title: (
      <div className="confirmHeader">
          {header}
      </div>
    ),
    content: typeof body === 'string' ? <div className="confirmBody">{body}</div> : body,
    onOk() {
      confirmHandler({ ...data });
    },
    onCancel() {
      cancelHandler({ ...data });
    },
    keyboard: true,
    width: width,
  });
}
