import { HighRiskCategory } from "models/enums/highRiskCategory";
import { IHighRiskListings } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import ListingAlertsCard from "./ListingAlertsCard";

const HighRiskListing: React.FC<{
  widgetId: number;
}> = ({ widgetId }) => {
  const [isLoadingHighRiskListings, setIsLoadingHighRiskListings] =
    useState(false);
  const [highRiskListingsFetchError, setHighRiskListingsFetchError] =
    useState<ICustomError | null>(null);
  const [highRiskListingsData, setHighRiskListingsData] =
    useState<IHighRiskListings | null>(null);

  const getHighRiskListings = async () => {
    setIsLoadingHighRiskListings(true);
    try {
      const response =
        await new OperationsDashboardService().getHighRiskListings({
          risk_type: HighRiskCategory.less_than_7,
        });
      setHighRiskListingsData(response.data.data);
      setIsLoadingHighRiskListings(false);
      setHighRiskListingsFetchError(null);
    } catch (error: any) {
      setIsLoadingHighRiskListings(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getHighRiskListings",
      });
      setHighRiskListingsFetchError({
        ...error,
        refreshAction: getHighRiskListings,
      });
    }
  };

  useEffect(() => {
    getHighRiskListings();
  }, []);

  return (
    <div className="highRiskCardCol">
      <ListingAlertsCard
        errorObj={highRiskListingsFetchError}
        title={"HIGH RISK (<7d)"}
        isLoading={isLoadingHighRiskListings}
        data={highRiskListingsData}
        id={widgetId}
      />
    </div>
  );
};

export default HighRiskListing;
