import { FixedColumnDirection } from "models/enums/table";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import { getCustomDate } from "utils/formatTimePeriod";
import {twoDecimalPlacesNumber, addToolTip} from 'utils/commonFunction'
import React from "react";
import { Typography } from "antd";

const commonWidth = 100;
const grossProfitDateFormat = "MM-DD-YYYY";

export const grossProfitCancelledHeaders = [
  {
    title: "P&L Date",
    dataIndex: "pl_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
    dataHeaderRender: (data: string) => <div className="text-left">{data}</div>,
  },
  {
    title: "Invoice ID",
    dataIndex: "invoiceId",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://skybox.vividseats.com/invoices/" + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    width: commonWidth,
    canSort: true,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
  },
  {
    title: "Invoice Line Date",
    dataIndex: "invoiceLineDate",
    width: commonWidth,
    canSort: true,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
  },
  {
    title: "Sale Type",
    dataIndex: "salesType",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Sale Category",
    dataIndex: "salesCategory",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Purchase Date",
    dataIndex: "fillDate",
    canSort: true,
    width: commonWidth,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number) => (
      <div className="text-right">{data?.toLocaleString("en-US")}</div>
    ),
    dataHeaderRender: (data: any) => (
      <div className="text-right">{data?.toLocaleString("en-US")}</div>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currencyCode",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className='text-upper'>{data}</div>
  },
  {
    title: "Unit Cost",
    dataIndex: "unitCost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number) => (
      <div className="text-right">{twoDecimalPlacesNumber(data)}</div>
    ),
  },
  {
    title: "Unit Sale",
    dataIndex: "unitTicketSales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number) => (
      <div className={`${data < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(data)}
      </div>
    ),
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticketCost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (d: number) => (
      <div className={`${d < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(d)}
      </div>
    ),
    dataHeaderRender: (data: any) => (
      <div className="text-right">
        {data && FormatCurrencyUnit(twoDecimalPlacesNumber(data), CurrencyCode.Usd)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticketSales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (n: number) => (
      <div className={`${n < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(n)}
      </div>
    ),
    dataHeaderRender: (d: any) => (
      <div className="text-right">
        {d && FormatCurrencyUnit(twoDecimalPlacesNumber(d), CurrencyCode.Usd)}
      </div>
    ),
  },
  {
    title: "P & L (API)",
    dataIndex: "p&l",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (t: number) => (
      <div className={`${t < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(t)}
      </div>
    ),
    dataHeaderRender: (n: any) => (
      <div className="text-right">
        {n && FormatCurrencyUnit(twoDecimalPlacesNumber(n), CurrencyCode.Usd)}
      </div>
    ),
  },
  {
    title: "Profit (Loss)",
    dataIndex: "p&l_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number, all: any) => {
      const allData = all.data.singleData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div className="text-right">
          <span className={`${redTag}`}>{twoDecimalPlacesNumber(data)}</span>
        </div>
      );
    },
    dataHeaderRender: (data: any, all: any) => {
      const allData = all.data._eachData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div className="text-right">
          <span className={`${redTag}`}>
            {data && FormatCurrencyUnit(twoDecimalPlacesNumber(data), CurrencyCode.Usd)}
          </span>
        </div>
      );
    },
  },
  {
    title: "Fees",
    dataIndex: "fees",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (p: number) => (
      <div className={`${p < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(p)}
      </div>
    ),
    dataHeaderRender: (t: any, _otherData: any) => (
      <div className="text-right">
        {t && FormatCurrencyUnit(twoDecimalPlacesNumber(t), CurrencyCode.Usd)}
      </div>
    ),
  },
  {
    title: "Gross Profit",
    dataIndex: "margin_after_fees",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number) => (
      <div
        className={`${data < 0 ? "text-danger text-right" : "text-right"}`}
        style={{ fontWeight: "bold" }}
      >
        {twoDecimalPlacesNumber(data)}
      </div>
    ),
    dataHeaderRender: (p: any, _otherData: any) => (
      <div className="text-right">
        {p && FormatCurrencyUnit(twoDecimalPlacesNumber(p), CurrencyCode.Usd)}
      </div>
    ),
  },
  {
    title: "Margin %",
    dataIndex: "margin_per_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (data: number) => (
      <div className={`${data < 0 ? "text-danger text-right" : "text-right"}`}>
        {twoDecimalPlacesNumber(data * 100)}%
      </div>
    ),
    dataHeaderRender: (data: any) => (
      <div className="text-right">{twoDecimalPlacesNumber(data)}</div>
    ),
  },
  {
    title: "External Reference",
    dataIndex: "externalRef",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) =>
      (data && data.length) >= 18 ? addToolTip(data) : <div className='text-capitalize'>{data}</div>,
  },
  {
    title: "Customer Classification",
    dataIndex: "customer_classification",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) =>
      (data && data.length) >= 18 ? addToolTip(data) : <div className='text-capitalize'>{data}</div>,
  },
  {
    title: "Purchase ID",
    dataIndex: "purchaseId",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number[]) =>
      purchaseId &&
      purchaseId.length &&
      purchaseId.map((id, index) => {
        return (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={"https://skybox.vividseats.com/purchases/" + id}
              underline={true}
            >
              {`${id}`}
            </Typography.Link>
            {`${index < purchaseId.length - 1 ? ", " : ""}`}
          </React.Fragment>
        );
      }),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) =>
      (data && data.length) >= 18 ? addToolTip(data) : <div className='text-capitalize'>{data}</div>,
  },
  {
    title: "Event",
    dataIndex: "event",
    canSort: true,
    width: commonWidth,
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={
          "https://skybox.vividseats.com/inventory?pageNumber=1&ef.eventId=" +
          otherData.data.singleData.eventId
        }
        className='text-capitalize'
      >
        {event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "eventDate",
    canSort: true,
    width: commonWidth,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => {
      let className = "";
      if (data !== null) {
        if (data > 30) className = "ant-tag warning";
        else if (data >= 15) className = "ant-tag success";
        else if (data <= 14) className = "ant-tag danger";
      }

      return (
        <div className={`${className}`} style={{ float: "right" }}>
          {data}
        </div>
      );
    },
  },
  {
    title: "Venue",
    dataIndex: "venue",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) =>
      (data && data.length) >= 18 ? addToolTip(data) : <div>{data}</div>,
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Internal Notes",
    dataIndex: "internalNotes",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) =>
      (data && data.length) >= 18 ? addToolTip(data) : <div>{data}</div>,
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillmentStatus",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className='text-capitalize'>{data}</div>
  },
  {
    title: "Fulfillment Date",
    dataIndex: "fulfillmentDate",
    canSort: true,
    width: commonWidth,
    dataRender: (date: string) =>
      date && getCustomDate(date, grossProfitDateFormat),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventoryTags",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className='text-capitalize'>{data}</div>
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoiceTags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Fill Tags",
    dataIndex: "fillTags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Consignment",
    dataIndex: "consignmentStatus",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className='text-capitalize'>{data}</div>
  },
  {
    title: "Payment Type",
    dataIndex: "PaymentType",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className='text-capitalize'>{data}</div>
  },
];
