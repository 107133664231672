import React from "react";
import { Row, Col, Layout, Pagination } from "antd";
import CustomError from "controls/CustomError/CustomError";
import CommonReportTable from "utils/table/CommonReportTable";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { venuesHeaders } from "./venuesUtils";
import { useAppSelector } from "app/hooks";
import {
  venueListData,
  dataFetchError,
  hasError,
  isLoadingVenues,
  queryParams,
} from "models/Slices/VenuesManagementSlice";
import EditVenue from "./Actions/EditVenue";
import { formatReportCount, formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;

const VenuesTable: React.FC<{
  appliedFilterValues: any | null;
  getVenueDetails: Function;
}> = ({ appliedFilterValues, getVenueDetails }) => {
  const showLoader = useAppSelector(isLoadingVenues);
  const venueDetails = useAppSelector(venueListData);
  const venueQueryParams = useAppSelector(queryParams);
  const isError = useAppSelector(hasError);
  const errorObj = useAppSelector(dataFetchError);

  const pageSize = venueQueryParams ? venueQueryParams.page_size : 100;

  return (
    <React.Fragment>
      <EditVenue />
      {isError ? (
        <CustomError {...errorObj} />
      ) : (
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row className="flx justifyContentBetween alignItemsCenter">
              <Col xl={12} xxl={16}>
                <AppliedFilters appliedFilters={appliedFilterValues} />
              </Col>
              <Col xl={12} xxl={8}>
                {!showLoader && venueDetails?.total > 0 && 
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>
                        {formatThousandSeparator(venueDetails.total)}
                        {formatReportCount(venueDetails.total)}
                      </h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        hideOnSinglePage={true}
                        defaultCurrent={1}
                        current={venueQueryParams?.page_index}
                        total={venueDetails?.total}
                        pageSize={pageSize}
                        onChange={(pageNumber) =>
                          getVenueDetails({
                            page_index: pageNumber,
                          })
                        }
                        showSizeChanger={false}
                      />
                    </li>
                  </ul>
                }
                {showLoader || venueDetails?.total <= 0 &&
                  <div style={{ height: 21 }}></div>
                }
              </Col>
            </Row>
          </div>
          <div className="removeTblLRSpc eventListTbl">
            <CommonReportTable
              tableClass="customTbl scroll-y fixedHeaderFooter"
              tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
              onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                getVenueDetails({
                  sort_column: sortIndex,
                  sort_order: sortOrder,
                });
              }}
              sortField={{
                dataIndex: venueQueryParams?.sort_column,
                order: venueQueryParams?.sort_order,
              }}
              data={venueDetails?.venueDetailsData}
              otherData={venueDetails?.venueDetailsData}
              headers={venuesHeaders}
              loading={showLoader}
              tooltipData={venueDetails?.tooltip[0] ?? null}
            />
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default VenuesTable;
