import { CommonTableHeader } from "../Reports/commonTable.model";

export interface ISectionQueryParams {
  time_unit: string;
  time_value: number;
}

export interface ISectionUserMetaData {
  start_date: string;
  end_date: string;
  period_number: number;
  zone_section_count?: number;
  ga_events_count?: number;
  split_inventory_count?: number;
  venue_assignment_count?: number;
  events_count?: number;
  sections_count?: number;
}

export interface ISectionInfo {
  first_name: string;
  last_name: number;
  data: ISectionUserMetaData[];
}

export interface IConversionRateInfo {
  first_name: string;
  last_name: number;
  conversion_rate: number;
  start_date: string;
  end_date: string;
}

export interface ISectionResponse {
  data: ISectionInfo[];
}

export interface IConversionRateResponse {
  data: IConversionRateInfo[];
}

export interface ICreatedSetionsTableInfo {
  headers:    CommonTableHeader[];
  data:       any;
  footerData: any;
  tableClass: tableClasses;
}

export enum tableClasses {
  CUSTOM_TABLE = "customTbl",
  MAIN_TABLE = "main-table",
  SCROLLABLE_TABLE = "table-scroll fixedHeaderFooter",
  FIXED_HEADER_FOOTER_TABLE = "customTbl fixedHeaderFooter",
  TWO_DIMENSION_SCROLLABLE_FIXED_HEADER_FOOTER_TABLE = "customTbl scroll-x-y fixedHeaderFooter",
  ONE_DIMENSION_SCROLLABLE_FIXED_HEADER_FOOTER_TABLE = "customTbl scroll-y fixedHeaderFooter"
}