import { Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";

const InlineEdit: React.FC<{
  defaultValue: any;
  formattedRenderValue?: JSX.Element | string
  inputType?: "text" | "number";
  inputNumberProps?: {
    max?:number;
    min?:number;
    precision?:number;
  };
  onFinish?: Function;
  onEditing?: Function;
  uniqueTabIndex?: string | null;
  currentTabIndex?: string | null;
  inputValidationRules?: any;
}> = ({ defaultValue, formattedRenderValue, inputType, inputNumberProps, onFinish, onEditing, uniqueTabIndex, currentTabIndex, inputValidationRules}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = React.useRef<any>(null);

  const onSubmit = (values: any) => {
    if(onFinish){
      onFinish(values);
    }
    setIsEditing(false);
  };

  useEffect(()=>{
    if(isEditing){
      form.setFieldsValue({
        filedValue: defaultValue
      });
      inputRef.current!.focus({
        cursor: 'end',
      });
    }
    else{
      form.setFieldsValue({
        isTabEnter: 'no'
      });
    }
    if(onEditing){
      onEditing(isEditing)
    }
  }, [isEditing])

  useEffect(()=>{
    if(uniqueTabIndex && currentTabIndex){
        if(uniqueTabIndex === currentTabIndex){
          setIsEditing(true);
        }
    }
  }, [uniqueTabIndex, currentTabIndex])

  const inputOnBlur = () =>{
    setTimeout(()=>{
      form.submit()
    },5)
  }

  const onKeyDown = (e:any) => {

    if (e.key.toLowerCase() === "tab") {
      e.preventDefault();
      form.setFieldsValue({
        isTabEnter: 'yes'
      });
      
      form.submit();
    }
    else if(e.key.toLowerCase() === "enter"){
      setTimeout(()=>{
        form.submit();
      },1)
    }
    else if(e.key.toLowerCase() === "escape"){
      setTimeout(()=>{
        form.setFieldsValue({
          filedValue: defaultValue
        });
        form.submit();
      },1)
    }
  };

  return (
      <React.Fragment>
          {!isEditing &&
            <div className="tableCellInlineEditArea" onClick={()=>{
                setIsEditing(true);
            }}>{formattedRenderValue ? formattedRenderValue : defaultValue}</div>
          }
          {isEditing &&
            <Form form={form} onFinish={onSubmit} name="inlineEdit" className="inlineEditForm">
                <Form.Item name="filedValue" rules={inputValidationRules ? [...inputValidationRules]: []} initialValue={defaultValue}>
                    {inputType === 'number' ? <InputNumber  onBlur={inputOnBlur} ref={inputRef} controls={false} className="inputTxtRight" {...inputNumberProps} onKeyDown={onKeyDown}/> : <Input  onBlur={inputOnBlur} ref={inputRef}  onKeyDown={onKeyDown}/>}
                </Form.Item>
                <Form.Item name="isTabEnter" initialValue={'no'} style={{display: 'none'}} >
                  <Input />
                </Form.Item>
            </Form>
        }
        
      </React.Fragment>
    
  );
};
export default InlineEdit;
