import { Tooltip } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { IEventDataForActiveZoneListing } from "models/interfaces";
import React from "react";
import { Link } from "react-router-dom";
import { AppSetting } from "utils/appSettings";
import { addToolTip, daysToEventColorCheck, getEncodedData, getSeatAvailabilityDetails, getUserName } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import EventCardActions from "utils/sharedComponents/EventCardActions";

const ActiveListingEventCard: React.FC<{
  eventData?: IEventDataForActiveZoneListing;
}> = ({ eventData }) => {
  const getLastUpdatedBy = (updatedBy: string) => {
    const lastUpdatedData = JSON.parse(updatedBy),
      userName = getUserName(lastUpdatedData);

    if (lastUpdatedData.compare_date > 1) {
      return (
        <>
          Last updated {lastUpdatedData.compare_date} days ago by {userName}
        </>
      );
    } else if (lastUpdatedData.compare_date === 1) {
      return (
        <>
          Last updated {lastUpdatedData.compare_date} day ago by {userName}
        </>
      );
    } else if (lastUpdatedData.compare_date === 0) {
      return <>Last updated today by {userName}</>;
    }
    return <></>;
  }

  const getSeatAvailabilityTopBar = (seat_availability_score: string) => {
    const availabilityDetails = getSeatAvailabilityDetails(
      seat_availability_score
    );
  
    return (
      <Tooltip placement="top" title={availabilityDetails.title}>
        <div
          className={`seatAvailableLogicTopBar ${availabilityDetails.color}`}
          style={{ padding: "0" }}
        ></div>
      </Tooltip>
    );
  };

  return (
    <div className="eventCardWrap active">
      <div className="eventCardContent">
      {eventData && getSeatAvailabilityTopBar(eventData.seat_availability_score)}
        <div className="lastUpdated">
          <span> {eventData && getLastUpdatedBy(eventData.updated_by)} </span>
        </div>
        <div className="eventCardBody">
          <div className="eventCardBodyInnerGrid">
            <div>
              <p>
                <a
                  rel="noopener noreferrer"
                  href={AppSetting.EVENT_URL + eventData?.event_id}
                  target="_blank"
                  className="zoneEventName"
                >
                  <strong>{eventData && eventData.event_name?.length > 18 ? addToolTip(eventData?.event_name,"right", "cursor", 18) : eventData?.event_name}</strong>
                </a>
                <br />
                {eventData
                  ? `${formatDateGivenFormatString(
                      eventData.event_date.toString(),
                      "ddd, MMM DD, YYYY"
                    )} at ${formatDateGivenFormatString(
                      eventData.event_date.toString(),
                      "h:mm A"
                    )}`
                  : ""}
              </p>
            </div>
            <EventCardActions eachEventData={eventData} />
          </div>
          <p>
            {eventData?.venue_name} <br/> 
            {eventData?.venue_location}
          </p>
          <div className="ranking">
            <div
              className={`rankingInner ${
                eventData?.days_to_event !== undefined &&
                daysToEventColorCheck(eventData.days_to_event, "grid").className
              }`}
            >
              {eventData?.days_to_event !== undefined && (
                <Tooltip
                  placement="top"
                  title={
                    daysToEventColorCheck(eventData.days_to_event, "grid")
                      .toolTipText
                  }
                >
                  <span className="rankNumber">{eventData.days_to_event}</span>
                </Tooltip>
              )}
              <div>
                Days <br />
                to event
              </div>
            </div>
            <div className="rankingInner default">
              <span className="rankNumber">{eventData?.active_listings}</span>
              Active
              <br />
              Listings
            </div>
            <RBAC allowedPermissions={[ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW]}>
            <div className="rankingInner positive">
              {eventData?.pending_fills !== undefined &&
                eventData?.pending_fills > 0 && (
                  <React.Fragment>
                    <span className="rankNumber">
                      {eventData?.pending_fills}
                    </span>
                    <Link
                      to={`${
                        LeftNavRoutesEnum.ZONES_PENDING_FILLS
                      }?search=${getEncodedData({
                        event_id: eventData?.event_id,
                        invoice_date: eventData?.invoice_date,
                      })}`}
                      target={"_blank"}
                    >
                      Pending
                      <br />
                      Fills
                    </Link>
                  </React.Fragment>
                )}
            </div>
            </RBAC>
            <div className="rankingInner default disabled">
              {eventData?.pricing_issue !== undefined &&
                eventData?.pricing_issue > 0 && (
                  <React.Fragment>
                    <span className="rankNumber">
                      {eventData?.pricing_issue}
                    </span>
                    <div className="noHyperlink">
                      Pricing
                      <br />
                      Issue
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
        <div className="eventCardFooter">
          <div>
          <Tooltip title="Broker"><i className="da icon-user-o"></i></Tooltip>
            <span>
              {eventData?.broker_name.length
                ? addToolTip(eventData?.broker_name.toString())
                : "Unknown"}
            </span>
          </div>
          <div>
          <Tooltip title="Lister"><i className="da icon-user-role"></i></Tooltip>
            <span>
              {eventData?.lister_name.length
                ? addToolTip(eventData?.lister_name.toString())
                : "Unknown"}
            </span>
          </div>
        </div>
        <div className="eventCardFooter2">
          {eventData?.scraping_status_id === 1 &&
            <div>
              Successfully scraped on:
              <br />
              {eventData?.scraped_succeed_on && 
                <span className="fontMedium">
                  {formatDateGivenFormatString(
                    eventData?.scraped_succeed_on,
                    "MMM DD, YYYY hh:mm A"
                  )}
                </span>
              }
              {!eventData?.scraped_succeed_on &&
                <span className="fontMedium">Never</span>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ActiveListingEventCard;
