import { Tooltip } from "antd";
import { IFilterListData } from "models/interfaces/Scheduler/scheduler.model";
import { useEffect, useState } from "react";

const getBrokerTag = (listData: IFilterListData | null) => {
  let brokerTag;
  if (listData != null && listData.brokerNames.length > 1) {
    brokerTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={listData.brokerNames.map((el) => `"${el}"`).join(", ")}
      >
        Multiple Brokers
      </Tooltip>
    );
  } else {
    brokerTag = ` ${listData && listData.brokerNames[0]}`;
  }
  return brokerTag;
};

const getInventoryTypeTag = (listData: IFilterListData | null) => {
  let inventoryTypeTag;
  if (listData != null && listData.brokerNames.length > 1) {
    inventoryTypeTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={listData.inventoryTypes.map((el) => `"${el}"`).join(", ")}
      >
        Multiple Brokers
      </Tooltip>
    );
  } else {
    inventoryTypeTag = ` ${listData && listData.inventoryTypes[0]}`;
  }
  return inventoryTypeTag;
};

const getBuyerTag = (listData: IFilterListData | null) => {
  let buyerTag;
  if (listData != null && listData.buyerNames.length > 1) {
    buyerTag = (
      <Tooltip
        title={listData.buyerNames.map((el) => `"${el}"`).join(", ")}
      >
        Multiple Buyers
      </Tooltip>
    );
  } else {
    buyerTag = ` ${listData && listData.buyerNames[0]}`;
  }
  return buyerTag;
};

const getEventTag = (listData: IFilterListData | null) => {
  let eventTag;
  if (listData != null && listData.eventNames.length > 1) {
    eventTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.eventNames.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Events
      </Tooltip>
    );
  } else {
    eventTag = ` ${listData && listData.eventNames[0]}`;
  }
  return eventTag;
};

const getVenueTag = (listData: IFilterListData | null) => {
  let venueTag;
  if (listData != null && listData.venueNames.length > 1) {
    venueTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.venueNames.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Venues
      </Tooltip>
    );
  } else {
    venueTag = ` ${listData && listData.venueNames[0]}`;
  }
  return venueTag;
};

const getCurrencyTag = (listData: IFilterListData | null) => {
  let currencyTag;
  if (listData != null && listData.currencyNames.length > 1) {
    currencyTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.currencyNames.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Currencies
      </Tooltip>
    );
  } else {
    currencyTag = ` ${listData && listData.currencyNames[0]}`;
  }
  return currencyTag;
};

const getCreditCardTag = (listData: IFilterListData | null) => {
  let creditCardTag;
  if (listData != null && listData.creditCardNames.length > 1) {
    creditCardTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.creditCardNames.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Credit Cards
      </Tooltip>
    );
  } else {
    creditCardTag = ` ${listData && listData.creditCardNames[0]}`;
  }
  return creditCardTag;
};

const getAlertTag = (listData: IFilterListData | null) => {
  let alertTag;
  if (listData != null && listData.alertNames.length > 1) {
    alertTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.alertNames.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Alerts
      </Tooltip>
    );
  } else {
    alertTag = ` ${listData && listData.alertNames[0]}`;
  }
  return alertTag;
};

const getSaleCategoryTag = (listData: IFilterListData | null) => {
  let saleCategoryTag;
  if (listData != null && listData.sale_category.length > 1) {
    saleCategoryTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.sale_category.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Sale Categories
      </Tooltip>
    );
  } else {
    saleCategoryTag = ` ${listData && listData.sale_category[0]}`;
  }
  return saleCategoryTag;
};

const getCustomerClassificationTag = (listData: IFilterListData | null) => {
  let customerClassificationTag;
  if (listData != null && listData.customer_classification.length > 1) {
    customerClassificationTag = (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.customer_classification.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Customer Classifications
      </Tooltip>
    );
  } else {
    customerClassificationTag = ` ${listData && listData.customer_classification[0]}`;
  }
  return customerClassificationTag;
};

const getNoTicketCostTag = (listData: IFilterListData | null) => {
  if (listData != null && listData.noTicketCostSelected) {
    return " Yes";
  } else {
    return " No";
  }
};

const getTbPlacementTag = (listData: IFilterListData | null) => {
  if (listData != null && listData.tbPlacementSelected) {
    return " Yes";
  } else {
    return " No";
  }
};

const getExcludeZeroValuedInventoryTag = (listData: IFilterListData | null) => {
  if (listData != null && listData.excludeZeroValuedInventorySelected) {
    return " Yes";
  } else {
    return " No";
  }
};

const getInvoiceMappingIdTag = (data: string) => {
  if(data === '1') return " Yes";
  if(data === '2') return " No";
}

const getSaleTypeTag = (data: number) => {
  if(data === 1) return " Long";
  if(data === 2) return " Zone";
}

const getAgingPeriod = (listData: IFilterListData | null) => {
  if (listData != null && listData.agingPeriods.length > 1) {
    return (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.agingPeriods.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Aging Periods
      </Tooltip>
    );
  } 
  return ` ${listData && listData.agingPeriods[0]}`;
};

const getFulfillmentStatus = (listData: IFilterListData | null) => {
  if (listData != null && listData.fulfillmentStatuses.length > 1) {
    return (
      <Tooltip
        overlayInnerStyle={{ textTransform: "capitalize" }}
        title={
          listData && listData.fulfillmentStatuses.map((el) => `"${el}"`).join(", ")
        }
      >
        Multiple Fulfillment Statuses
      </Tooltip>
    );
  } 
  return ` ${listData && listData.fulfillmentStatuses[0]}`;
};

const FilterDisplayArea: React.FC<{
  filterData: any;
  datePreset: any;
  listData: IFilterListData | null;
}> = ({ filterData, datePreset, listData }) => {
  
  const [noFilterSelected, setNoFilterSelected] = useState(false);

  const isAnyFilterSelected = () => {
    if (
      datePreset &&
      Object.keys(datePreset).length < 1 &&
      listData &&
      listData.brokerNames?.length < 1 &&
      listData.buyerNames.length < 1 &&
      listData.eventNames.length < 1 &&
      listData.venueNames.length < 1 &&
      listData.currencyNames.length < 1 &&
      listData.creditCardNames.length < 1 &&
      listData.alertNames.length < 1 &&
      listData.sale_category.length < 1 &&
      listData.customer_classification.length < 1 &&
      !Object.keys(filterData).includes("no_ticketCost") &&
      !Object.keys(filterData).includes("show_tb_placements") &&
      !Object.keys(filterData).includes("exclude_zero_valued_inventory") &&
      filterData.show_invoice_mapping_id === null &&
      listData.agingPeriods?.length < 1 &&
      listData.fulfillmentStatuses?.length < 1 
    ) {
      setNoFilterSelected(true);
    } else {
      setNoFilterSelected(false);
    }
  };

  useEffect(() => {
    isAnyFilterSelected();
  }, [filterData, datePreset]);

  const getDatePresetFilterJsx = () => {
    if (datePreset) {
      return Object.keys(datePreset).map((key, i) => (
        <span
          key={`${i}_date_preset`}
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>{key}:</b> {datePreset[key]}
        </span>
      ));
    }
  };

  const getBrokerTagFilterJsx = () => {
    if (listData && listData.brokerNames.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Broker:</b>
          {getBrokerTag(listData)}
        </span>
      );
    }
  };

  const getInventoryTypeTagFilterJsx = () => {
    if (listData && listData.inventoryTypes.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Inventory Type:</b>
          {getInventoryTypeTag(listData)}
        </span>
      );
    }
  };

   const getBuyerTagFilterJsx = () => {
    if (listData && listData.buyerNames.length > 0) {
      return (
        <span className="ant-tag info" style={{ cursor: "default" }}>
          <b>Buyer:</b>
          {getBuyerTag(listData)}
        </span>
      );
    }
  };

  const getEventTagFilterJsx = () => {
    if (listData && listData.eventNames.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Events:</b>
          {getEventTag(listData)}
        </span>
      );
    }
  };

  const getVenueTagFilterJsx = () => {
    if (listData && listData.venueNames.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Venues:</b>
          {getVenueTag(listData)}
        </span>
      );
    }
  };

  const getCurrencyTagFilterJsx = () => {
    if (listData && listData.currencyNames.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Currencies:</b>
          {getCurrencyTag(listData)}
        </span>
      );
    }
  };

  const getCreditCardTagFilterJsx = () => {
    if (listData && listData.creditCardNames.length) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Credit Cards:</b>
          {getCreditCardTag(listData)}
        </span>
      );
    }
  };

  const getAlertTagFilterJSx = () => {
    if (listData && listData.alertNames.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Alerts:</b>
          {getAlertTag(listData)}
        </span>
      );
    }
  };

  const getSaleCategoryTagFilterJsx = () => {
    if (listData && listData.sale_category.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Sale Category:</b>
          {getSaleCategoryTag(listData)}
        </span>
      );
    }
  };

  const getCustomerClassificationTagFilterJsx = () => {
    if (listData && listData.customer_classification.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Customer Classification:</b>
          {getCustomerClassificationTag(listData)}
        </span>
      );
    }
  };

  const getAgingPeriodFilterJsx = () => {
    if (listData && listData.agingPeriods.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Aging Periods:</b>
          {getAgingPeriod(listData)}
        </span>
      );
    }
  };

  const getFulfillmentStatusFilterJsx = () => {
    if (listData && listData.fulfillmentStatuses.length > 0) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Fulfillment Status:</b>
          {getFulfillmentStatus(listData)}
        </span>
      );
    }
  };

  const getNoTicketCostTagFilterJsx = () => {
    if (listData && listData.noTicketCost) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>No Ticket Cost:</b>
          {getNoTicketCostTag(listData)}
        </span>
      );
    }
  };

  const getTbPlacementTagFilterJsx = () => {
    if (listData && listData.tbPlacement) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>TB2.0:</b>
          {getTbPlacementTag(listData)}
        </span>
      );
    }
  };

  const getExcludeZeroValuedInventoryTagFilterJsx = () => {
    if (listData && listData.excludeZeroValuedInventory) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Exclude Zero Valued Inventory:</b>
          {getExcludeZeroValuedInventoryTag(listData)}
        </span>
      );
    }
  };

  const getInvoiceMappingIdTagFilterJsx = () => {
    if (filterData.show_invoice_mapping_id) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Invoice Mapping Id:</b>
          {getInvoiceMappingIdTag(filterData.show_invoice_mapping_id)}
        </span>
      );
    }
  };

  const getSaleTypeTagFilterJsx = () => {
    if (filterData.sale_type) {
      return (
        <span
          className="ant-tag info"
          style={{ textTransform: "capitalize", cursor: "default" }}
        >
          <b>Sale Type:</b>
          {getSaleTypeTag(filterData.sale_type)}
        </span>
      );
    }
  };

  return (
    <div className="ant-col ant-col-xl-24">
      <div className="inputGroup">
        <label>Filters</label>
        {!noFilterSelected ? (
          <div className="filters">
            {getDatePresetFilterJsx()}
            {getBrokerTagFilterJsx()}
            {getInventoryTypeTagFilterJsx()}
            {getBuyerTagFilterJsx()}
            {getEventTagFilterJsx()}
            {getVenueTagFilterJsx()}
            {getCurrencyTagFilterJsx()}
            {getCreditCardTagFilterJsx()}
            {getAlertTagFilterJSx()}
            {getSaleCategoryTagFilterJsx()}
            {getCustomerClassificationTagFilterJsx()}
            {getAgingPeriodFilterJsx()}
            {getFulfillmentStatusFilterJsx()}
            {getNoTicketCostTagFilterJsx()}
            {listData?.tbPlacementSelected ? getTbPlacementTagFilterJsx() : ""}
            {listData?.excludeZeroValuedInventorySelected ? getExcludeZeroValuedInventoryTagFilterJsx() : ""}
            {getInvoiceMappingIdTagFilterJsx()}
            {getSaleTypeTagFilterJsx()}
          </div>
        ) : (
          <div className="filters">No Filter Data</div>
        )}
      </div>
    </div>
  );
};

export default FilterDisplayArea;
