import React, { useState, useEffect, memo } from "react";
import { Layout, Row, Col, Switch, Tooltip } from "antd";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { actions, scrapingStatusData } from "models/Slices/FilterSlice";
import { useRbac } from "features/rbac/rbac";
import {
  setQueryParams,
  eventManagementActions,
} from "models/Slices/EventManagementSlice";
import EventList from "./EventList";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { isMobile } from "react-device-detect";
import { IAddonFilter, IdType } from "models/interfaces";

const Events: React.FC<{
  dashboardSearchFields: {
    dashboard_search_text: string | null;
    event_date_from: string | null;
    event_date_to: string | null;
    broker_user_Id?: string | null;
    lister_user_Id?: string | null;
    seat_map_creator_user_id?: string | null;
    checker_user_id?: string | null;
    seat_availability_update?: number | null;
    show_category_id?: IdType;
    seat_intelligence_Id?: number | null;
  };
  setAddonFilters: Function;
  addonFilters: IAddonFilter[] | undefined;
  isPageReady: boolean;
}> = ({
  dashboardSearchFields,
  setAddonFilters,
  addonFilters,
  isPageReady,
}) => {
  console.log(dashboardSearchFields);
  
  const [viewMode, setViewMode] = useState<"gridView" | "listView">("listView");

  const dispatch = useAppDispatch();
  const { hasPermissions } = useRbac();

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    dispatch(actions.getSeatIntelligenceData());

    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL]))
      dispatch(actions.getBrokerUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getListerUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getSeatMapCreatorUserData());

    if (
      hasPermissions([
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
      ])
    )
      dispatch(actions.getCheckerUserData());

    if (hasPermissions([ERbacPermissions.FILTER_SHOW_CATEGORY_VIEW])) {
      dispatch(actions.getShowCategories());
    }
  }, []);

  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [searchText, setSearchText] = useState<string | null>(
    dashboardSearchFields.dashboard_search_text
      ? dashboardSearchFields.dashboard_search_text
      : null
  );

  const filterChanged = (filters: any) => {
    dispatch(
      setQueryParams({
        search_text: searchText,
        event_date_from: dashboardSearchFields.event_date_from,
        event_date_to: dashboardSearchFields.event_date_to,
        page_index: 1,
        ...filters,
      })
    );
    getEventDetails();
  };

  const handleTextSearch = (e: any) => {
    e.preventDefault();
    dispatch(setQueryParams({ page_index: 1, search_text: searchText }));
    getEventDetails();
  };

  const closeSearch = () => {
    setSearchText(null);
    dispatch(setQueryParams({ page_index: 1, search_text: null }));
    getEventDetails();
  };

  const getEventDetails = async () => {
    dispatch(
      eventManagementActions.getEventListData({
        functionName: "getEventDetails",
      })
    );
  };

  useEffect(() => {
    if (isMobile) {
      setViewMode("gridView");
    }
  }, [isMobile]);

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={20} sm={16} md={9} xl={7} xxl={7} className="titleOrder">
            <h2>Upcoming Events</h2>
          </Col>
          <Col xs={7} sm={5} md={3} xl={3} xxl={2} className="switchOrder">
            <Tooltip
              title={
                viewMode === "listView"
                  ? "Toggle Card View"
                  : "Toggle List View"
              }
            >
              {
                <Switch
                  className="gridListView"
                  defaultChecked
                  onChange={(value) => {
                    setTimeout(() => {
                      if (value) {
                        setViewMode("listView");
                      } else {
                        setViewMode("gridView");
                      }
                    }, 190);
                  }}
                  checkedChildren={<i className="da icon-th-list"></i>}
                  unCheckedChildren={<i className="da icon-th-large"></i>}
                />
              }
            </Tooltip>
          </Col>
          <Col xs={17} sm={24} md={10} xl={7} xxl={6} className="searchOrder">
            <div className="search">
              <form onSubmit={handleTextSearch}>
                <input
                  type="search"
                  placeholder="Search event, venue, broker, city, state..."
                  value={searchText ? searchText : ""}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText && searchText.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={4} sm={3} md={2} xl={7} xxl={9} className="filterOrder">
            <div className="rightFilter">
              {isPageReady && (
                <FilterDrawer
                  onApplyFilter={filterChanged}
                  defaultAndValues={{
                    preFilledValues: {
                      ...dashboardSearchFields
                    },
                  }}
                  showBrokerUserDropdown={hasPermissions([
                    ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                  ])}
                  brokerUserDropdownType="single"
                  showListerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
                  ])}
                  listerDropdownType="single"
                  showSeatMapCreatorDropdown={hasPermissions([
                    ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM,
                  ])}
                  seatMapCreatorDropdownType="single"
                  showCheckerDropdown={hasPermissions([
                    ERbacPermissions.FILTER_CHECKER_USER_SELECTION_ALL,
                    ERbacPermissions.FILTER_CHECKER_USER_SELECTION_TEAM,
                  ])}
                  checkerDropdownType="single"
                  showStateDropdown={true}
                  stateDropdownType="single"
                  showSeatIntelligenceDropdown={true}
                  seatIntelligenceDropdownType="single"
                  showSeatAvailabilityUpdateDropdown={true}
                  showSeatAvailabilityUpdateDropdownType={"single"}
                  showShowCategoryDropdown={hasPermissions([
                    ERbacPermissions.FILTER_SHOW_CATEGORY_VIEW,
                  ])}
                  showCategoryDropdownType={"single"}
                  showFavoriteVenuesOnlyCheckbox={true}
                  showSoldOutEventsCheckbox={true}
                  showCancelledEventsCheckbox={true}
                  showFailedScrapedEventsCheckbox={true}
                  showEventsPendingVerificationCheckbox={hasPermissions([ERbacPermissions.EVENT_SEATMAP_VERIFICATION])}
                  addonFilters={addonFilters}
                  setAddonFilters={setAddonFilters}
                  onApplyFilterWithValues={(e: any) =>
                    setFilterAppliedWithValues(e)
                  }
                />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Layout className="mainInnerLayout pb-0 reduceTopSpace upcomingEventWrap">
        <EventList
          appliedFilterValues={filtersAppliedWithValues}
          viewMode={viewMode}
          getEventDetails={getEventDetails}
        />
      </Layout>
    </React.Fragment>
  );
};

export default memo(Events);
