import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Pagination } from "antd";
import {
  IPendingZoneFillsListData,
  IPendingZoneFillsListDatum,
} from "models/interfaces";
import PendingZoneFillsTable from "./PendingZoneFillsTable";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { reset } from "models/Slices/FilterSlice";
import { AppSetting } from "utils/appSettings";
import { ICustomError } from "models/interfaces/ICustomError";
import { EventService } from "services";
import { LoggerService } from "sharedServices";
import { isMobile } from "react-device-detect";
import { formatThousandSeparator } from "utils/commonFunction";
import PurchaseAssignmentModal from "./Actions/PurchaseAssignmentModal";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";

const PendingZoneFillsListings: React.FC<{
  searchQuery: any;
  queryParams: any;
  setQueryParams: Function;
  filtersAppliedWithValues: any;
  widgetFilterParams: any;
  isFilterDrawerDisabled: boolean;
  queryEvent: number | null;
}> = ({
  searchQuery,
  queryParams,
  setQueryParams,
  filtersAppliedWithValues,
  widgetFilterParams,
  isFilterDrawerDisabled,
  queryEvent,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(true);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [pendingZoneFillsData, setPendingZoneFillsData] =
    useState<IPendingZoneFillsListData | null>(null);
  const [isQueryApplied, setIsQueryApplied] = useState<boolean>(false);
  const [isNewAssignmentSaved, setIsNewAssignmentSaved] =
    useState<boolean>(false);

  const getPendingZoneFillsData = async () => {
    try {
      setShowLoader(true);
      let effectiveQueryParam;
      if (queryEvent && !isQueryApplied) {
        effectiveQueryParam = {
          ...queryParams,
          ...widgetFilterParams,
          ...{ event_id: queryEvent },
        };
        setSearch(`${queryEvent}`);
        setIsQueryApplied(true);
      } else {
        if (!searchQuery && search) {
          effectiveQueryParam = {
            ...queryParams,
            ...{ event_id: queryEvent },
          };
        } else {
          effectiveQueryParam = { ...queryParams, ...widgetFilterParams };
        }
      }
      const response = await new EventService().getPendingZoneFillsListings({
        ...effectiveQueryParam,
      });
      setPendingZoneFillsData(handleHighlightedRow(response.data.data));
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getActiveInventoryData",
      });

      setDataFetchError({ ...error, refreshAction: getPendingZoneFillsData });
    }
  };

  const handleHighlightedRow = (
    responseData: IPendingZoneFillsListData | null
  ) => {
    if (responseData && responseData.pendingZoneFillsData) {
      return {
        ...responseData,
        pendingZoneFillsData: responseData.pendingZoneFillsData.map(
          (elem: IPendingZoneFillsListDatum) => {
            return {
              ...elem,
              activeSelectedClass: elem?.if_completed_assignment
                ? "greenSelected"
                : null,
            };
          }
        ),
      };
    }
    return responseData;
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(reset());
    };
  }, []);

  
  useEffect(() => {
      queryParams && getPendingZoneFillsData();
    }, [queryParams]);
    
    useEffect(() => {
      if (searchQuery !== null) {
        setQueryParams({
          ...queryParams,
          page_index: AppSetting.DEFAULT_PAGE_INDEX,
          keywords: searchQuery,
        });
      }
    }, [searchQuery]);

    useEffect(() => {
        if (isNewAssignmentSaved) {
      getPendingZoneFillsData();
      setIsNewAssignmentSaved(false);
    }
  }, [isNewAssignmentSaved]);

  const getFormattedTotalCount = (count: number) => {
    const formattedCount = formatThousandSeparator(count);
    return count === 1
      ? `${formattedCount} Record Found`
      : `${formattedCount} Records Found`;
  };

  const handlePageChange = (pageIndex: number) => {
    setQueryParams({
      ...queryParams,
      page_index: pageIndex,
    });
  };

  return (
    <React.Fragment>
      <Layout className="mainInnerLayout pb-0 reduceTopSpace pending-fills-wrap">
        <PurchaseAssignmentModal
          setIsNewAssignmentSaved={setIsNewAssignmentSaved}
        />
        <div className="innerHeader">
          <Row className="flx justifyContentBetween alignItemsCenter">
            <Col xs={18} sm={8} md={8} xl={9}>
              <AppliedFilters
                appliedFilters={filtersAppliedWithValues}
                resetUrl={
                  isFilterDrawerDisabled
                    ? LeftNavRoutesEnum.ZONES_PENDING_FILLS
                    : null
                }
              />
            </Col>
            <Col xs={24} md={24} xl={12}>
              <ul className="innerHeaderRight">
                {!showLoader &&
                pendingZoneFillsData &&
                pendingZoneFillsData?.count ? (
                  <React.Fragment>
                    <li>
                      <h4>
                        {getFormattedTotalCount(pendingZoneFillsData?.count)}
                      </h4>
                    </li>
                    <li>
                      {!isMobile && (
                        <Pagination
                          className="pagination"
                          hideOnSinglePage={true}
                          defaultCurrent={1}
                          current={queryParams?.page_index}
                          total={pendingZoneFillsData?.count}
                          pageSize={AppSetting.PAGE_SIZE}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                        />
                      )}
                      {isMobile && (
                        <Pagination
                          simple
                          size="small"
                          hideOnSinglePage={true}
                          defaultCurrent={1}
                          current={queryParams?.page_index}
                          total={pendingZoneFillsData?.count}
                          pageSize={AppSetting.PAGE_SIZE}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                        />
                      )}
                    </li>
                  </React.Fragment>
                ) : (
                  <div style={{ height: 21 }}></div>
                )}
              </ul>
            </Col>
          </Row>
        </div>
        <PendingZoneFillsTable
          showLoader={showLoader}
          dataFetchError={dataFetchError}
          pendingZoneFillsData={pendingZoneFillsData}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </Layout>
    </React.Fragment>
  );
};

export default PendingZoneFillsListings;
