import { HighRiskCategory } from "models/enums/highRiskCategory";
import { IHighRiskListings } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import ListingAlertsCard from "./ListingAlertsCard";

const CancelledEvent: React.FC<{
  widgetId: number;
}> = ({ widgetId }) => {
  const [isLoadingCancelledEvents, setIsLoadingCancelledEvents] =
    useState(false);
  const [cancelledEventsFetchError, setCancelledEventsFetchError] =
    useState<ICustomError | null>(null);
  const [cancelledEventsData, setCancelledEventsData] =
    useState<IHighRiskListings | null>(null);

  const getCancelledEvents = async () => {
    setIsLoadingCancelledEvents(true);
    try {
      const response =
        await new OperationsDashboardService().getHighRiskListings({
          risk_type: HighRiskCategory.event_cancelled,
        });
      setCancelledEventsData(response.data.data);
      setIsLoadingCancelledEvents(false);
      setCancelledEventsFetchError(null);
    } catch (error: any) {
      setIsLoadingCancelledEvents(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getCancelledEvents",
      });
      setCancelledEventsFetchError({
        ...error,
        refreshAction: getCancelledEvents,
      });
    }
  };

  useEffect(() => {
    getCancelledEvents();
  }, []);

  return (
    <div className="highRiskCardCol">
      <ListingAlertsCard
        errorObj={cancelledEventsFetchError}
        title={"CANCELLED EVENTS"}
        isLoading={isLoadingCancelledEvents}
        data={cancelledEventsData}
        id={widgetId}
      />
    </div>
  );
};

export default CancelledEvent;
