import { RoutesEnum } from "models/enums";
import { HighRiskCategory } from "models/enums/highRiskCategory";
import {
  ActiveListingQueryParams,
  EventsWithActiveZoneListingsQueryParams,
  IActiveListingResponseData,
  ICreateListingModalData,
  IEventsCreateListingBulkPlacement,
  IEventsWithActiveZoneListingsResponse,
  IHighRiskListings,
  IListingFormData,
  IZoneListingsByEventResponseData,
  ZoneDetailsForZoneListingQueryParams,
  ZoneListingDetailsByEventQueryParams,
  ISeatMapTbdDropdownData,
  ISplitData,
  IEditInventoryListingDatum,
  IBulkUpdateBroadcastStatusDto,
  IBulkListingQueryString
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";
import { getDeviceInfo } from "utils/commonFunction";

export class ZonesService {
  
  public getZoneDetailsForZoneListing = async (
    zoneDetailsForZoneListingQueryParams: ZoneDetailsForZoneListingQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<ICreateListingModalData>>(
      RoutesEnum.ZONE_DETAILS_BY_ID,
      {
        params: zoneDetailsForZoneListingQueryParams,
      }
    );
  };

  public createNewZoneListing = async (
    event_id: string,
    zoneData: IListingFormData[],
    total_time: number,
    idle_time: number,
    listing_type_id: number,
    sendEmail?: boolean,
    syncToSkyboxPlatformDb?: boolean
  ) => {
    const createListingURL = RoutesEnum.ZONE_CREATE_NEW_LISTING.replace(
      "{event_id}",
      event_id
    );

    const deviceInfo = getDeviceInfo();

    return axiosClient.post<
      IApiBaseResponse<{
        purchase_id: number;
        purchase_data: any;
        purchase_line_ids: number[];
      }>
    >(createListingURL, {
      zones: zoneData,
      device_type: deviceInfo.deviceType,
      browser_name: deviceInfo.browserName,
      browser_version: deviceInfo.browserFullVersion,
      platform_name: deviceInfo.osName,
      platform_version: deviceInfo.osVersion,
      listing_type_id: listing_type_id,
      total_time: total_time ? Math.round(total_time) : 0,
      idle_time: idle_time ? Math.round(idle_time) : 0,
      send_email: sendEmail,
      sync_to_skybox_platform_db: syncToSkyboxPlatformDb,
    });
  };

  public refreshMVWforPurchase = async () => {
    return axiosClient.get<
      IApiBaseResponse<{ success: true; is_mvw_refreshed: boolean }>
    >(RoutesEnum.ZONE_REFRESH_MVW_FOR_PURCHASE);
  };

  public getZoneListingDetailsByEvent = async (
    zoneListingDetailsByEventRequest: ZoneListingDetailsByEventQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<IZoneListingsByEventResponseData>>(
      RoutesEnum.ZONE_LISTING_DETAILS_BY_EVENT,
      {
        params: zoneListingDetailsByEventRequest,
      }
    );
  };
  public forceUpdatePurchase = async (
    purchase_id: number,
    created_purchase_data: any
  ) => {
    const forceUpdateURL = RoutesEnum.ZONE_FORCE_UPDATE_PURCHASE.replace(
      "{purchase_id}",
      purchase_id.toString()
    );

    return axiosClient.put<
      IApiBaseResponse<{ purchase_id: number; is_mvw_refreshed: boolean }>
    >(forceUpdateURL, {
      purchase_data: created_purchase_data,
    });
  };

  public updateConfigurationRefreshingMvwOff = () => {
    return axiosClient.post<IApiBaseResponse<{ success: boolean }>>(
      RoutesEnum.ZONE_FORCE_UPDATE_REFRESHED_MVW_CONFIG
    );
  };

  public getActiveListings = async (
    activeListingRequest: ActiveListingQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<IActiveListingResponseData>>(
      RoutesEnum.ZONE_ACTIVE_LISTINGS_BY_EVENT,
      {
        params: activeListingRequest,
      }
    );
  };

  public getEventsWithActiveZoneListings = async (
    eventsWithActiveZoneListingsRequest: EventsWithActiveZoneListingsQueryParams
  ) => {
    return axiosClient.get<
      IApiBaseResponse<IEventsWithActiveZoneListingsResponse>
    >(RoutesEnum.ZONE_ALL_ZONE_LISTINGS, {
      params: eventsWithActiveZoneListingsRequest,
    });
  };

  public getHighRiskListings = async (highRiskListingQueryDto: {
    risk_type: HighRiskCategory;
  }) => {
    return axiosClient.get<IApiBaseResponse<IHighRiskListings>>(
      RoutesEnum.HIGH_RISK_LISTINGS,
      {
        params: highRiskListingQueryDto,
      }
    );
  };

  public updateInventoryBroadcastStatus = async (
    inventory_id: number,
    broadcast_status: boolean,
    purchase_line_id: number
  ) => {
    const url = RoutesEnum.ZONE_ACTIVE_LISTINGS_UPDATE_BROADCAST_STATUS.replace(
      "{inventory_id}",
      inventory_id.toString()
    );
    return axiosClient.put<
      IApiBaseResponse<{ inventory_id: number; broadcast_status: boolean }>
    >(url, {
      broadcast_status,
      purchase_line_id,
    });
  };

  public cancelActiveListing = async (
    purchase_id: number,
    line_id: number,
    userId: string
  ) => {
    const url = RoutesEnum.ZONE_ACTIVE_LISTING_CANCEL.replace(
      "{purchase_id}",
      purchase_id.toString()
    ).replace("{line_id}", line_id.toString());
    return axiosClient.put<
      IApiBaseResponse<{ purchase_id: number; line_id: number }>
    >(url, {
      userId,
    });
  };

  public refreshZoneInventoryMVW = async () => {
    return axiosClient.put<IApiBaseResponse<{ success: boolean }>>(
      RoutesEnum.ZONE_INVENTORY_FORCE_SYNCED
    );
  };

  public splitListInventory = async (
    inventory_id: number,
    number_of_tickets: number
  ) => {
    let splitInventoryUrl =
      RoutesEnum.ZONE_ACTIVE_LISTINGS_SPLIT_INVENTORY.replace(
        "{inventory_id}",
        inventory_id.toString()
      );

    splitInventoryUrl = splitInventoryUrl.replace(
      "{numberOfTickets}",
      number_of_tickets.toString()
    );

    return axiosClient.put<IApiBaseResponse<{ new_inventory_id: number }>>(
      splitInventoryUrl
    );
  };

  public refreshPurchaseLine = async (purchase_line_id: number) => {
    const refreshPurchaseLineUrl =
      RoutesEnum.ZONE_PURCHASE_LINE_FORCE_SYNCED.replace(
        "{purchase_line_id}",
        purchase_line_id.toString()
      );
    return axiosClient.put<IApiBaseResponse<{ purchase_line_id: number }>>(
      refreshPurchaseLineUrl
    );
  };

  public getEventsCreateListingBulkPlacement = async (queryParams: IBulkListingQueryString) => {
    return axiosClient.get<IApiBaseResponse<IEventsCreateListingBulkPlacement>>(
      RoutesEnum.ZONE_GET_EVENTS_CREATE_LISTING_BULK_PLACEMENT,
      {
        params: {
          ...queryParams,
        },
      }
    );
  };

  public getSeatMapTbdDropdownData = async (
    seatMapTbdDropdownParam: ZoneListingDetailsByEventQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<ISeatMapTbdDropdownData>>(
      RoutesEnum.SEAT_MAP_TBD_DROPDOWN_DATA,
      { params: seatMapTbdDropdownParam }
    );
  };

  public getSplitInventoryData = async (splitInventoryDataParams: {
    inventory_id: number;
  }) => {
    return axiosClient.get<IApiBaseResponse<ISplitData>>(
      RoutesEnum.ZONE_GET_SPLIT_INVENTORY_DATA,
      { params: splitInventoryDataParams }
    );
  };

  public updateListingSectionRowPrice = async (
    updateListingSectionRowPriceDataParams: IEditInventoryListingDatum[]
  ) => {
    return axiosClient.post(
      RoutesEnum.ZONE_LISTING_UPDATE_SECTION_ROW_PRICE,
      updateListingSectionRowPriceDataParams
    );
  };

  public forceUpdatePlatformSkyboxInventories = async (
    inventory_ids: number[]
  ) => {
    return axiosClient.post<IApiBaseResponse>(RoutesEnum.ZONE_FORCE_UPDATE_INVENTORIES, {
      inventory_ids,
    });
  };

  public bulkUpdateBroadcastStatus = async (
    bulkUpdateBroadcastStatusDto: IBulkUpdateBroadcastStatusDto
  ) => {
    return axiosClient.put(
      RoutesEnum.ZONE_BULK_UPDATE_BROADCAST_STATUS,
      bulkUpdateBroadcastStatusDto
    );
  };

  public remapListingsWithZones = async (event_id: number) => {
    return axiosClient.get<IApiBaseResponse>(
      RoutesEnum.ZONE_REMAP_LISTING_WITH_ZONE,
      {
        params: {
          event_id: event_id,
        },
      }
    );
  };
}
