import { useAppSelector } from "app/hooks";
import { IFilterOptions, IReportQueryString } from "models/interfaces";
import {
  IFilterListData,
  IScheduleListResponse,
} from "models/interfaces/Scheduler/scheduler.model";
import {
  brokers,
  buyers,
  venues,
  currencies,
  cards,
  alerts,
  events,
  recipients,
  saleCategories,
  customerClassifications,
  agingPeriodTypes,
  fulfillmentStatusTypes,
  inventoryCancellationTypes
} from "models/Slices/FilterSlice";
import { calendarPresets } from "models/Slices/GlobalSlice";
import { useEffect, useState } from "react";
import { getDatePresetLabel } from "utils/commonFunction";
import {
  dateRangeIsCustom,
  dateRangeIsLast15Days,
  dateRangeIsLast7Days,
  dateRangeIsLastMonth,
  dateRangeIsLastQuarter,
  dateRangeIsLastWeek,
  dateRangeIsLastYear,
  dateRangeIsNext15Days,
  dateRangeIsNext365Days,
  dateRangeIsNext3Months,
  dateRangeIsNext6Months,
  dateRangeIsNext7Days,
  dateRangeIsNext9Months,
  dateRangeIsNextMonth,
  dateRangeIsNextQuarter,
  dateRangeIsNextWeek,
  dateRangeIsNextYear,
  dateRangeIsThisMonth,
  dateRangeIsThisQuarter,
  dateRangeIsThisWeek,
  dateRangeIsThisYear,
  dateRangeIsToday,
  dateRangeIsTomorrow,
  dateRangeIsYesterday,
  dateRangeIsAll
} from "utils/formatTimePeriod";
import ReportScheduler from "./ReportScheduler";

const CreateScheduler: React.FC<{
  schedulerTitle: string;
  onUpdateSchedule?: Function;
  reportData?: IReportQueryString;
  filterData?: IFilterOptions | null;
  scheduleData?: IScheduleListResponse | null;
}> = ({
  schedulerTitle,
  onUpdateSchedule,
  reportData,
  filterData,
  scheduleData
}) => {
  const [datePreset, setDatePreset] = useState<Object>({});
  const brokerList = useAppSelector(brokers);
  const inventoryTypeList = useAppSelector(inventoryCancellationTypes);
  const buyerList = useAppSelector(buyers);
  const eventList = useAppSelector(events);
  const venueList = useAppSelector(venues);
  const currencyList = useAppSelector(currencies);
  const cardList = useAppSelector(cards);
  const alertList = useAppSelector(alerts);
  const saleCategoryList = useAppSelector(saleCategories);
  const customerClassificationList = useAppSelector(customerClassifications);
  const allRecipients = useAppSelector(recipients);
  const allPresets = useAppSelector(calendarPresets);
  const agingPeriodList = useAppSelector(agingPeriodTypes);
  const fulfillmentStatusList = useAppSelector(fulfillmentStatusTypes);

  const [brokerNames, setBrokerNames] = useState<string[]>([]);
  const [inventoryTypes, setInventoryTypes] = useState<string[]>([]);
  const [buyerNames, setBuyerNames] = useState<string[]>([]);
  const [eventNames, setEventNames] = useState<string[]>([]);
  const [venueNames, setVenueNames] = useState<string[]>([]);
  const [currencyNames, setCurrencyNames] = useState<string[]>([]);
  const [creditCardNames, setCreditCardNames] = useState<string[]>([]);
  const [alertNames, setAlertNames] = useState<string[]>([]);
  const [saleCategoryNames, setSaleCategoryNames] = useState<string[]>([]);
  const [customerClassificationNames, setCustomerClassificationNames] =
    useState<string[]>([]);
  const [agingPeriods, setAgingPeriods] = useState<string[]>([]);
  const [fulfillmentStatuses, setFulfillmentStatuses] = useState<string[]>([]);
  const [noTicketCost, setNoTicketCost] = useState(false);
  const [noTicketCostSelected, setNoTicketCostSelected] = useState(false);
  const [tbPlacement, setTbPlacement] = useState(false);
  const [tbPlacementSelected, setTbPlacementSelected] = useState(false);
  const [excludeZeroValuedInventory, setExcludeZeroValuedInventory] = useState(false);
  const [excludeZeroValuedInventorySelected, setExcludeZeroValuedInventorySelected] = useState(false);

  const [listData, setListData] = useState<IFilterListData | null>(null);

  const getListData = (values: any, id: string) => {
    const valueArray =
      values?.length > 0 ? values.split(",") : [values?.toString()];

    let nameArray;
    if (values === null) {
      setBrokerNames([]);
      setInventoryTypes([]);
      setBuyerNames([]);
      setEventNames([]);
      setVenueNames([]);
      setCurrencyNames([]);
      setCreditCardNames([]);
      setAlertNames([]);
      setSaleCategoryNames([]);
      setCustomerClassificationNames([]);
      setAgingPeriods([]);
      setFulfillmentStatuses([]);
    } else {
      switch (id) {
        case "broker":
          nameArray = brokerList
            .filter((broker) => valueArray.includes(broker.value.toString()))
            .map((each) => each.label);
          setBrokerNames(nameArray);
          break;
        case "inventory_types":
          nameArray = inventoryTypeList
            .filter((type) => valueArray.includes(type.value.toString()))
            .map((each) => each.label);
          setInventoryTypes(nameArray);
          break;
        case "buyer":
          setBuyerNames(
            buyerList
              .filter((buyer) => valueArray.includes(buyer.value.toString()))
              .map((each) => each.label)
          );
          break;
        case "event":
          nameArray = eventList
            .filter((event) => valueArray.includes(event.value.toString()))
            .map((each) => each.label);
          setEventNames(nameArray);
          break;
        case "venue":
          nameArray = venueList
            .filter((venue) => valueArray.includes(venue.value.toString()))
            .map((each) => each.label);
          setVenueNames(nameArray);
          break;
        case "currency":
          nameArray = currencyList
            .filter((currency) => valueArray.includes(currency.value.toString()))
            .map((each) => each.label);
          setCurrencyNames(nameArray);
          break;
        case "creditCard":
          nameArray = cardList
            .filter((card) => valueArray.includes(card.value.toString()))
            .map((each) => each.label);
          setCreditCardNames(nameArray);
          break;
        case "alert":
          nameArray = alertList
            .filter((alert) => valueArray.includes(alert.value.toString()))
            .map((each) => each.label);
          setAlertNames(nameArray);
          break;
        case "saleCategory":
          setSaleCategoryNames(
            saleCategoryList
              .filter((category) =>
                valueArray.includes(category.value.toString())
              )
              .map((each) => each.label)
          );
          break;
        case "customerClassification":
          setCustomerClassificationNames(
            customerClassificationList
              .filter((classification) =>
                valueArray.includes(classification.value.toString())
              )
              .map((each) => each.label)
          );
          break;
        case "agingPeriod":
          nameArray = agingPeriodList
            .filter((agingPeriod) => valueArray.includes(agingPeriod.value.toString()))
            .map((each) => each.label);
          setAgingPeriods(nameArray);
          break;
        case "fulfillmentStatus":
          nameArray = fulfillmentStatusList
            .filter((fulfillmentStatus) => valueArray.includes(fulfillmentStatus.value.toString()))
            .map((each) => each.label);
          setFulfillmentStatuses(nameArray);
          break;
      }
    }
  };

  const createDisplayData = () => {
    filterData &&
      Object.entries(filterData).forEach((option) => {
        console.log(option[0])
        switch (option[0]) {
          case "broker_Id":
            getListData(option[1], "broker");
            break;
          case "inventory_cancellation_type_Id":
            getListData(option[1], "inventory_types");
            break;
          case "buyer_Id":
            getListData(option[1], "buyer");
            break;
          case "event_Id":
            getListData(option[1], "event");
            break;
          case "venue_Id":
            getListData(option[1], "venue");
            break;
          case "currency_Id":
            getListData(option[1], "currency");
            break;
          case "credit_card_Id":
            getListData(option[1], "creditCard");
            break;
          case "alert_Id":
            getListData(option[1], "alert");
            break;
          case "sale_category":
            getListData(option[1], "saleCategory");
            break;
          case "customer_classification":
            getListData(option[1], "customerClassification");
            break;
          case "no_ticketCost":
            setNoTicketCost(true);
            setNoTicketCostSelected(
              filterData?.no_ticketCost ? filterData.no_ticketCost : false
            );
            break;
          case "aging_period":
            getListData(option[1], "agingPeriod");
            break;
          case "fulfillment_status":
            getListData(option[1], "fulfillmentStatus");
            break;
          case "show_tb_placements":
            setTbPlacement(true);
            setTbPlacementSelected(
              filterData?.show_tb_placements ? filterData.show_tb_placements : false
            );
            break;
          case "exclude_zero_valued_inventory":
            setExcludeZeroValuedInventory(true);
            setExcludeZeroValuedInventorySelected(
              filterData?.exclude_zero_valued_inventory ? filterData.exclude_zero_valued_inventory : false
            );
            break;
          default:
            checkForPresetDates(option[0]);
            break;
        }
      });
  };

  const setAndChooseDatePreset = (from: any, to: any, key: string) => {
    if (
      from !== null &&
      from !== undefined &&
      allPresets &&
      typeof from === "number"
    ) {
      setDatePreset((prev) => ({
        ...prev,
        [key]: getDatePresetLabel(allPresets, from),
      }));
    } else if(allPresets) {
      chooseDayPreset(key, from, to);
    }
  }

  const checkForPresetDates = (key: string) => {
    
    let from: any = "";
    let to: any = "";
    switch (key) {
      case "pl_date_from":
        from = filterData?.pl_date_from;
        to = filterData?.pl_date_to;
        setAndChooseDatePreset(from, to, "PL Date Range");
        break;
      case "po_date_from":
        from = filterData?.po_date_from;
        to = filterData?.po_date_to;
        setAndChooseDatePreset(from, to, "PO Date Range");
        break;
      case "invoice_date_from":
        from = filterData?.invoice_date_from;
        to = filterData?.invoice_date_to;
        setAndChooseDatePreset(from, to, "Invoice Date Range");
        break;
      case "fill_date_from":
        from = filterData?.fill_date_from;
        to = filterData?.fill_date_to;
        setAndChooseDatePreset(from, to, "Fill Date Range");
        break;
      case "event_date_from":
        from = filterData?.event_date_from;
        to = filterData?.event_date_to;
        setAndChooseDatePreset(from, to, "Event Date Range");
        break;
      case "delivery_date_from":
        from = filterData?.delivery_date_from;
        to = filterData?.delivery_date_to;
        setAndChooseDatePreset(from, to, "Delivery Date Range");
        break;
      case "other_cost_date_from":
        from = filterData?.other_cost_date_from;
        to = filterData?.other_cost_date_to;
        setAndChooseDatePreset(from, to, "Other Cost Date Range");
        break;
    }
  };

  const chooseDayPreset = (key: string, from: string, to: string) => {
    if(allPresets) {
      if(dateRangeIsYesterday(from, to)){
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 1) }));
      } 
      else if (dateRangeIsLast7Days(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 2) }));
      } 
      else if (dateRangeIsLast15Days(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 3) }));
      } 
      else if (dateRangeIsLastWeek(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 4) }));
      } 
      else if (dateRangeIsLastMonth(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 5) })); 
      } 
      else if (dateRangeIsLastQuarter(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 6) }));
      } 
      else if (dateRangeIsLastYear(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 7) }));
      } 
      else if (dateRangeIsToday(from, to)){
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 8) }));
      } 
      else if (dateRangeIsThisWeek(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 9) }));
      } 
      else if (dateRangeIsThisMonth(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 10) }));
      } 
      else if (dateRangeIsThisQuarter(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 11) }));
      } 
      else if (dateRangeIsThisYear(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 12) }));
      } 
      else if (dateRangeIsTomorrow(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 13) }));
      } 
      else if (dateRangeIsNext7Days(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 14) }));
      } 
      else if (dateRangeIsNext15Days(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 15) }));
      } 
      else if (dateRangeIsNextWeek(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 16) }));
      } 
      else if (dateRangeIsNextMonth(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 17) }));
      } 
      else if (dateRangeIsNextQuarter(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 18) }));
      } 
      else if (dateRangeIsNextYear(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 19) }));
      } 
      else if (dateRangeIsNext365Days(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 20) }));
      } 
      else if (dateRangeIsNext3Months(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 21) }));
      } 
      else if (dateRangeIsNext6Months(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 22) }));
      } 
      else if (dateRangeIsNext9Months(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 23) }));
      } 
      else if (dateRangeIsAll(from, to)) {
        setDatePreset((prev) => ({ ...prev, [key]: getDatePresetLabel(allPresets, 24) }));
      }
    }
    
    if (dateRangeIsCustom(from, to)) {
      const updatedState = { ...datePreset };
      delete updatedState[key];
      setDatePreset(updatedState);
    }
  };

  useEffect(() => {
    createDisplayData();
  }, [filterData]);

  useEffect(() => {
    setListData((prev) => ({
      ...prev,
      brokerNames: brokerNames,
      inventoryTypes: inventoryTypes,
      buyerNames: buyerNames,
      eventNames: eventNames,
      venueNames: venueNames,
      currencyNames: currencyNames,
      creditCardNames: creditCardNames,
      alertNames: alertNames,
      sale_category: saleCategoryNames,
      customer_classification: customerClassificationNames,
      noTicketCost: noTicketCost,
      noTicketCostSelected: noTicketCostSelected,
      tbPlacement: tbPlacement,
      tbPlacementSelected: tbPlacementSelected,
      excludeZeroValuedInventory: excludeZeroValuedInventory,
      excludeZeroValuedInventorySelected: excludeZeroValuedInventorySelected,
      agingPeriods: agingPeriods,
      fulfillmentStatuses: fulfillmentStatuses
    }));
  }, [
    brokerNames,
    inventoryTypes,
    buyerNames,
    eventNames,
    venueNames,
    currencyNames,
    creditCardNames,
    alertNames,
    saleCategoryNames,
    customerClassificationNames,
    noTicketCost,
    noTicketCostSelected,
    tbPlacement,
    tbPlacementSelected,
    excludeZeroValuedInventory,
    excludeZeroValuedInventorySelected,
    agingPeriods,
    fulfillmentStatuses
  ]);

  return (
    <ReportScheduler
      schedulerTitle={schedulerTitle}
      onUpdateSchedule={onUpdateSchedule}
      reportData={
        scheduleData
          ? {
              category_id: 0,
              category_name: "",
              current_report: scheduleData.name,
              current_report_id: scheduleData.scheduleDetails.reportId,
            }
          : reportData
          ? reportData
          : null
      }
      filterData={filterData ? filterData : null}
      datePreset={datePreset}
      listData={listData}
      existingScheduleData={scheduleData ? scheduleData : null}
      allRecipients={allRecipients}
    />
  );
};

export default CreateScheduler;
