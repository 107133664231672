import {
  Tooltip,
  Typography,
  Input,
  Tag,
  Button,
  InputNumber,
  Checkbox,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import {
  IActiveListing,
  IEventsWithActiveZoneListingsResponse,
  IEventWithActiveZoneListings,
  IAllZoneListingPOTableHeader,
  ICompetitorPriceData
} from "models/interfaces";
import React, { useState } from "react";
import { AppSetting } from "utils/appSettings";
import {
  addToolTip,
  getTagClassForZones,
  twoDecimalPlacesNumber,
} from "utils/commonFunction";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { getCustomDateTime } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CompetitiveModalMain from "views/Zones/Actions/CreateNewListing/CompetitiveModal/CompetitiveModalMain";
import ActiveListingBroadcastStatusChange from "../../ActiveListingComponents/Actions/ActiveListingBroadcastStatusChange";
import SplitListing from "../../ActiveListingComponents/Actions/SplitListing";
import InputComponent from "./InputComponent";

let sectionInputTimer: any = null;
let rowInputTimer: any = null;
let listPriceInputTimer: any = null;

interface IAllZoneListingPoTableDataRow {
  poTableHeaders: IAllZoneListingPOTableHeader[];
  data: IActiveListing;
  otherData?: IEventsWithActiveZoneListingsResponse;
  setShowSplitView: Function;
  setCurrentClickedInventory: Function;
  setAllZoneListings: Function;
  rowSaveClickHandler: Function;
  checkIsAllSelected: Function;
  // competitorPricesListData: ICompetitorPriceData[];
  currentEventData: IEventWithActiveZoneListings;
}

const AllZoneListingPoTableDataRow: React.FC<IAllZoneListingPoTableDataRow> = ({
  poTableHeaders,
  data,
  otherData,
  setShowSplitView,
  setCurrentClickedInventory,
  setAllZoneListings,
  rowSaveClickHandler,
  checkIsAllSelected,
  // competitorPricesListData,
  currentEventData,
}) => {
  const { hasPermissions } = useRbac();
  const [listPriceInputError, setListPriceInputError] = useState(false);

  const getEventAvailabilityJsx = (singleData: IActiveListing) => {
    let zoneAvailability;
    let currentEventData = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (
      currentEventData &&
      currentEventData.scraping_status_id === 1 &&
      currentEventData.is_sold_out
    ) {
      zoneAvailability = getTagClassForZones(
        0,
        otherData?.zone_availability_master ?? []
      );
    } else {
      zoneAvailability = getTagClassForZones(
        singleData.availability,
        otherData?.zone_availability_master ?? []
      );
    }
    return (
      <Tooltip placement="right" title={singleData.availability_display_alias}>
        <div
          className={`seatAviaileLogicBar ${zoneAvailability?.className} haveTooltip`}
          style={{ padding: "0" }}
        >
          &nbsp;
        </div>
      </Tooltip>
    );
  };

  const getPONumberJsx = (purchase_id: number) => {
    return (
      <Typography.Link
        href={`${AppSetting.PURCHASE_ID_URL}${purchase_id}`}
        target="_blank"
        className="linkStyle1"
      >
        {purchase_id}
      </Typography.Link>
    );
  };

  const splitViewClickHandler = (inventory_id: number) => {
    setShowSplitView(true);
    setCurrentClickedInventory(inventory_id);
  };

  const getPOQuantityJsx = (singleData: IActiveListing) => {
    if (singleData.is_split) {
      return (
        <Tooltip title="View Splits">
          <span
            className="splitQty textNoWrap"
            onClick={() => splitViewClickHandler(singleData.inventory_id)}
            style={{ cursor: "pointer" }}
          >
            {singleData.quantity}{" "}
            <span className="border rounded splitBtn textNoWrap">
              <i className="da icon-split2-yellow vMiddle ml0" /> SPLIT
            </span>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <div>
          <span>{singleData.quantity}</span>
        </div>
      );
    }
  };

  const getSectionJsx = (singleData: IActiveListing) => {
    const currentEvent = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (currentEvent) {
      const currentEditedListings =
        currentEvent.po_details?.editedActiveListings;
      if (currentEditedListings) {
        return (
          <InputComponent
            currentEditedListings={currentEditedListings}
            setAllZoneListings={setAllZoneListings}
            singleData={singleData}
            keyword="section"
          />
        );
      }
    }
  };

  const getRowJsx = (singleData: IActiveListing) => {
    const currentEvent = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (currentEvent) {
      const currentEditedListings =
        currentEvent.po_details?.editedActiveListings;
      if (currentEditedListings) {
        return (
          <InputComponent
            currentEditedListings={currentEditedListings}
            setAllZoneListings={setAllZoneListings}
            singleData={singleData}
            keyword="row"
          />
        );
      }
    }
  };

  const getCostPriceJsx = (singleData: IActiveListing) => {
    return (
      <div className="text-right">
        {FormatCurrencyUnit(
          twoDecimalPlacesNumber(singleData.cost_price_with_venue_fees),
          singleData.currency
        )}
      </div>
    );
  };

  const getAltCostJsx = (singleData: IActiveListing) => {
    if (singleData.secondary_cost !== "") {
      const alt_cost = singleData.secondary_cost
        ?.split(",")
        .filter((each) => parseInt(each.replace(/[^0-9.]/g, "")) !== 0)
        .map(
          (each) =>
            "$" +
            Math.round(
              parseInt(each.replace(/[^0-9.]/g, "")) +
                (singleData?.venue_fees ?? 0)
            )
        )
        .join(", ");
      return <div>{alt_cost}</div>;
    }
    return <></>;
  };

  const getListPriceJsx = (singleData: IActiveListing) => {
    const currentEvent = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (currentEvent) {
      const currentEditedListings =
        currentEvent.po_details?.editedActiveListings;
      if (currentEditedListings) {
        return (
          <div className={`flx alignItemsCenter`}>
            <InputNumber
              precision={2}
              size="small"
              controls={false}
              className={`${
                listPriceInputError ? "requiredField" : ""
              } metadataZonePOTable`}
              value={
                currentEditedListings.find(
                  (element) => element.inventory_id === singleData.inventory_id
                )?.list_price
              }
              onChange={(value) =>
                listPriceChangeHandler(
                  value,
                  singleData.event_id,
                  singleData.inventory_id
                )
              }
              // onBlur={validateListPrice}
              id={
                "listPrice_" +
                singleData.inventory_id.toString() +
                "_eventID_" +
                singleData.event_id.toString()
              }
              style={{ width: 84 }}
              disabled={
                !hasPermissions([ERbacPermissions.ZONES_SAVE_EDITED_LISTINGS])
              }
            />
            {singleData.list_price < singleData.cost_price ||
            singleData.list_price === 0 ? (
              <Tooltip title={"List Price is 0 or less than Max Cost"}>
                <i className="da icon-warning ml-5" />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      }
    }
  };

  const validateListPrice = (value: number | null) => {
    const _value = value?.toString() ?? "";
    if (
      /[a-zA-Z]/.test(_value) ||
      _value === null ||
      _value === "" ||
      parseFloat(_value.replace(/,/g, "")) <= 0 ||
      parseFloat(_value.replace(/,/g, "")) > 9999
    ) {
      setListPriceInputError(true);
    } else {
      setListPriceInputError(false);
    }
  };

  const listPriceChangeHandler = (
    value: number | null,
    event_id: number,
    inventory_id: number
  ) => {
    if (listPriceInputTimer) {
      clearTimeout(listPriceInputTimer);
    }
    listPriceInputTimer = setTimeout(() => {
      setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
        if (prev) {
          const _prev = { ...prev };
          let currentPo = _prev.events
            .find((event) => event.event_id === event_id)
            ?.po_details?.editedActiveListings.find(
              (eachPo) => eachPo.inventory_id === inventory_id
            );
          if (currentPo && value !== null) {
            currentPo.list_price = value;
          }
          return _prev;
        }
        return prev;
      });
    }, 500);
    validateListPrice(value);
  };

  const setAvailability = (
    singleData: IActiveListing,
    currentEventData: IEventWithActiveZoneListings | undefined,
    zoneAvailability: any
  ) => {
    if (
      singleData &&
      singleData.available_seats !== null &&
      singleData.available_seats !== undefined &&
      singleData.total_seats !== null &&
      singleData.total_seats !== undefined &&
      (currentEventData?.scraping_status_id === 1 ||
        currentEventData?.is_from_desktop_app)
    ) {
      return `${
        currentEventData && currentEventData.is_sold_out
          ? 0
          : singleData.available_seats
      } / ${singleData.total_seats}`;
    }
    return zoneAvailability?.zoneAvailability?.label;
  };

  const getZoneAvailabilityJsx = (singleData: IActiveListing) => {
    let zoneAvailability;
    let currentEventData = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (
      currentEventData &&
      currentEventData.scraping_status_id === 1 &&
      currentEventData.is_sold_out
    ) {
      zoneAvailability = getTagClassForZones(
        0,
        otherData?.zone_availability_master ?? []
      );
    } else {
      zoneAvailability = getTagClassForZones(
        singleData.availability,
        otherData?.zone_availability_master ?? []
      );
    }

    const tagClassName =
      zoneAvailability && zoneAvailability.className
        ? zoneAvailability.className
        : "";
    return (
      <div className="text-center">
        <Tag
          className={tagClassName}
          style={{
            cursor: "initial",
          }}
        >
          {setAvailability(singleData, currentEventData, zoneAvailability)}
        </Tag>
      </div>
    );
  };

  const getZoneSeatingJsx = (is_zone_seating: boolean) => {
    if (is_zone_seating) {
      return (
        <div className="text-center">
          <i className="da icon-zone-seating" />
        </div>
      );
    } else {
      return "";
    }
  };

  const getInternalNotesJsx = (internal_notes: string) => {
    if (internal_notes?.length >= 18) {
      return addToolTip(internal_notes, "right", "default", 18, false);
    } else {
      return <div>{internal_notes}</div>;
    }
  };

  const getListedByJsx = (listed_by: string | null) => {
    if (!listed_by || listed_by?.toLowerCase() === "unknown") {
      return <div className="text-center">-</div>;
    } else if (listed_by.includes("@") && listed_by.split("@")[0].length > 12) {
      return (
        <Tooltip arrowPointAtCenter={true} title={listed_by}>
          {listed_by.split("@")[0].slice(0, 12)}...
        </Tooltip>
      );
    } else if (
      listed_by.includes("@") &&
      listed_by.split("@")[0].length <= 12
    ) {
      return (
        <Tooltip arrowPointAtCenter={true} title={listed_by}>
          {listed_by.split("@")[0].slice(0, 12)}
        </Tooltip>
      );
    }
    return listed_by;
  };

  const getPOTagsJsx = (po_tags: string[] | null) => {
    if (!po_tags) {
      return <div className="text-center">-</div>;
    }
    return <div>{po_tags?.join(", ")}</div>;
  };

  const getInventoryTagJsx = (inventory_tag: string | null) => {
    if (!inventory_tag) {
      return <div className="text-center">-</div>;
    }
    return <div>{inventory_tag}</div>;
  };

  const getTBPlacementJsx = (tb_placement_check: boolean) => {
    if (tb_placement_check) {
      return <div>Yes</div>;
    }
    return "";
  };

  const getSaveActionJsx = () => {
    return (
      <RBAC allowedPermissions={[ERbacPermissions.ZONES_SAVE_EDITED_LISTINGS]}>
        <Tooltip title="Save changes">
          <Button
            type="text"
            className="text-center"
            icon={<i className="da icon-save-floppy" />}
            size="small"
            onClick={() => rowSaveClickHandler(data)}
          />
        </Tooltip>
      </RBAC>
    );
  };

  const isDataChanged = () => {
    const oldData = otherData?.events
      .find((eachEvent) => eachEvent.event_id === data.event_id)
      ?.po_details?.activeListings.find(
        (eachListing) => eachListing.inventory_id === data.inventory_id
      );
    const newData = otherData?.events
      .find((eachEvent) => eachEvent.event_id === data.event_id)
      ?.po_details?.editedActiveListings.find(
        (eachListing) => eachListing.inventory_id === data.inventory_id
      );
    if (
      oldData &&
      newData &&
      (oldData.section !== newData.section ||
        oldData.row !== newData.row ||
        oldData.list_price !== newData.list_price)
    ) {
      return true;
    }
    return false;
  };

  const getBroadcastCta = (singleData: IActiveListing) => {
    let currentEventData = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );
    if (
      singleData &&
      currentEventData &&
      currentEventData?.event_id &&
      currentEventData?.event_date
    ) {
      return (
        <ActiveListingBroadcastStatusChange
          activeListingData={singleData}
          setUpdatedBroadcastStatus={setUpdatedBroadcastStatus}
          eventId={currentEventData?.event_id}
          eventName={currentEventData?.event_name}
          eventDate={(currentEventData?.event_date).toString()}
          venueName={currentEventData?.venue_name}
          venueLocation={currentEventData?.venue_location}
          placement={"left"}
          // onCloseActiveListingModal={onCloseActiveListingModal}
        />
      );
    }
  };

  function setUpdatedBroadcastStatus(
    inventory_id: number,
    broadcast_status: boolean
  ) {
    const activeListingData = otherData?.events.find(
      (eachEvent) => eachEvent.event_id === data.event_id
    )?.po_details;

    if (activeListingData) {
      setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
        if (prev) {
          const _prev = { ...prev };
          let currentPo = _prev.events
            .find((event) => event.event_id === data.event_id)
            ?.po_details?.editedActiveListings.find(
              (eachPo) => eachPo.inventory_id === inventory_id
            );
          let currentPoActive = _prev.events
            .find((event) => event.event_id === data.event_id)
            ?.po_details?.activeListings.find(
              (eachPo) => eachPo.inventory_id === inventory_id
            );
          if (currentPo && currentPoActive) {
            currentPo.broadcast = broadcast_status;
            currentPo.broadcasted_inventory_count = broadcast_status ? 1 : 0;
            currentPo.unbroadcasted_inventory_count = broadcast_status ? 0 : 1;
            if (currentPo.is_checked) {
              currentPo.is_checked = broadcast_status !== false;
            }
            currentPoActive.broadcast = broadcast_status;
            currentPoActive.broadcasted_inventory_count = broadcast_status
              ? 1
              : 0;
            currentPoActive.unbroadcasted_inventory_count = broadcast_status
              ? 0
              : 1;
            if (currentPoActive.is_checked) {
              currentPoActive.is_checked = broadcast_status !== false;
            }
          }
          return _prev;
        }
        return prev;
      });
      //}
    }
  }

  const getToolTipMessage = () => {
    let toolTipMessage = "Change Broadcast Status";
    if (
      !hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION])
    ) {
      toolTipMessage = "Partially Broadcasted";
    }
    return toolTipMessage;
  };

  const getBroadcastStatusJsx = (singleData: IActiveListing) => {
    if (
      singleData.is_split &&
      singleData.broadcasted_inventory_count > 0 &&
      singleData.unbroadcasted_inventory_count > 0
    ) {
      return (
        <Tooltip title={getToolTipMessage()} placement="left">
          <Button
            type="text"
            className="text-center"
            icon={<i className="da icon-broadcast-unbroadcast" />}
            size="small"
            style={{ cursor: "pointer" }}
            onClick={() =>
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
                ? splitViewClickHandler(singleData.inventory_id)
                : null
            }
          />
        </Tooltip>
      );
    } else if (singleData.broadcasted_inventory_count > 0) {
      if (singleData.is_split) {
        return (
          <Tooltip title={getToolTipMessage()} placement="left">
            <Button
              type="text"
              className="text-center"
              icon={<i className="da icon-network" />}
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => splitViewClickHandler(singleData.inventory_id)}
            />
          </Tooltip>
        );
      }
      return getBroadcastCta(singleData);
    } else if (singleData.unbroadcasted_inventory_count > 0) {
      if (singleData.is_split) {
        return (
          <Tooltip title={getToolTipMessage()} placement="left">
            <Button
              type="text"
              className="text-center"
              icon={<i className="da icon-network-red" />}
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => splitViewClickHandler(singleData.inventory_id)}
            />
          </Tooltip>
        );
      }
      return getBroadcastCta(singleData);
    }
  };

  const handleCheckboxCheckHandler = (
    e: CheckboxChangeEvent,
    inventories: number[]
  ) => {
    setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
      if (prev) {
        const _prev = { ...prev };
        let currentEventPoDetails = _prev.events.find(
          (event) => event.event_id === data.event_id
        )?.po_details?.editedActiveListings;
        if (currentEventPoDetails) {
          currentEventPoDetails.forEach((eachPo) => {
            if (inventories.includes(eachPo.inventory_id)) {
              eachPo.is_checked = e.target.checked;
            }
            return eachPo;
          });
        }
        checkIsAllSelected(_prev);
        return _prev;
      }
      checkIsAllSelected();
      return prev;
    });
  };

  const getSelectCheckboxJsx = (singleData: IActiveListing) => {
    return (
      <Checkbox
        disabled={
          !(
            singleData.broadcasted_inventory_count > 0 &&
            hasPermissions([
              ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
            ])
          )
        }
        onChange={(e) =>
          handleCheckboxCheckHandler(e, singleData.inventory_ids)
        }
        checked={singleData.is_checked}
      />
    );
  };

  // const getCompetitivePrice = (
  //   currentEventData: IEventWithActiveZoneListings,
  //   zone_section_alias_dictionary: string[] | null,
  //   row: string,
  //   site_type: string,
  //   index: number
  // ) => {
  //   const _competitorPrice = competitorPricesListData.find(
  //     (eachCp) =>
  //       eachCp.event_id === currentEventData?.event_id &&
  //       eachCp.provider.toLocaleLowerCase() === site_type.toLocaleLowerCase()
  //   );
  //   let listingsCount = 0;
  //   let listingsCountString = "-";
  //   let highestCost = 0;
  //   let lowestCost = 0;
  //   let costRangeString = "";

  //   if (_competitorPrice && _competitorPrice.metadata.length > 0) {
  //     const _competitorPriceFiltered = _competitorPrice.metadata.filter(
  //       (eachMeatdata) =>
  //         zone_section_alias_dictionary?.includes(
  //           eachMeatdata.zone_section?.toString()?.toLocaleUpperCase()
  //         )
  //     );
  //     listingsCount = _competitorPriceFiltered.length;
  //     if (_competitorPriceFiltered.length > 0) {
  //       highestCost =
  //         _competitorPriceFiltered.reduce((previous, current) => {
  //           return current.cost > previous.cost ? current : previous;
  //         }).cost ?? 0;

  //       lowestCost =
  //         _competitorPriceFiltered.reduce((previous, current) => {
  //           return current.cost < previous.cost ? current : previous;
  //         }).cost ?? 0;
  //     }
  //   }

  //   if (listingsCount > 0) {
  //     listingsCountString =
  //       listingsCount > 1
  //         ? listingsCount + " listings"
  //         : listingsCount + " listing";
  //     costRangeString =
  //       highestCost === lowestCost
  //         ? "$" + highestCost
  //         : "$" + lowestCost + "-" + highestCost;
  //   }

  //   const x = currentEventData?.po_details?.secondaryMarketplace?.filter(
  //     (eachMarket) =>
  //       eachMarket.secondary_marketplace_url &&
  //       eachMarket.secondary_marketplace_url !== ""
  //   );
  //   return (
  //     <div className="competitiveInner flx alignItemsCenter">
  //       {_competitorPrice && (
  //         <React.Fragment>
  //           <div className="flx flexColumn flx-1">
  //             <div
  //               className={`mb5 text-md ${
  //                 listingsCountString === "-" && "text-center"
  //               }`}
  //             >
  //               {listingsCountString}
  //             </div>
  //             {listingsCount > 0 && (
  //               <div className="competitiveValue">{costRangeString}</div>
  //             )}

  //             {/* } */}
  //           </div>
  //           {x && index === x.length - 1 && (
  //             <CompetitiveModalMain
  //               eventId={currentEventData?.event_id}
  //               secondaryMarketplaceList={
  //                 currentEventData?.po_details?.secondaryMarketplace
  //                   ? currentEventData?.po_details?.secondaryMarketplace
  //                   : []
  //               }
  //               zoneSectionAliasDictionary={zone_section_alias_dictionary ?? []}
  //             />
  //           )}
  //         </React.Fragment>
  //       )}
  //     </div>
  //   );
  // };

  const refreshNewSplitListing = (purchase_id: number | undefined, purchase_line_id: number | undefined) => {
    if (purchase_id && data && purchase_line_id) {
      setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
        if (prev) {
          let _prev = { ...prev };
          _prev.events?.forEach(eachEvent => {
            const newListings = eachEvent?.po_details?.editedActiveListings
            eachEvent?.po_details?.activeListings?.map((each) => {
              const newData = newListings?.find((listing) => listing.inventory_id === each.inventory_id);
              if(newData && each && newData.purchase_line_id === purchase_line_id ) {
                newData.is_split = true;
                each.is_split = true;
              }
            })
          })
          return _prev;
        }
        return prev;
      });
    }
  };

  const getSplitListingCta = (singleData: IActiveListing) => {
    let currentEventData = otherData?.events.find(
      (element) => element.event_id === singleData.event_id
    );

    if (
      currentEventData &&
      singleData &&
      singleData.quantity > 1 &&
      !singleData.is_split
    ) {
      return (
        <SplitListing
          actionElement={
            <Tooltip title="Split">
              <Typography.Link>
                <i className="da icon-split"></i>
              </Typography.Link>
            </Tooltip>
          }
          data={{
            eventId: currentEventData.event_id,
            eventName: currentEventData?.event_name,
            eventDate: currentEventData?.event_date.toString(),
            venueName: currentEventData.venue_name,
            venueLocation: currentEventData.venue_location,
            purchaseId: singleData.purchase_id,
            purchaseLineId: singleData.purchase_line_id,
            inventoryId: singleData.inventory_id,
            quantity: singleData.quantity,
            row: singleData.row,
            section: singleData.section,
            listPrice: singleData.list_price,
          }}
          refreshListings={refreshNewSplitListing}
        />
      );
    }
  };

  const getRowElements = (
    eachHeader: IAllZoneListingPOTableHeader,
    singleData: IActiveListing
  ) => {
    // if (singleData[eachHeader.dataIndex] !== undefined) {
    switch (eachHeader.dataIndex) {
      case "seat_availability_score":
        return getEventAvailabilityJsx(singleData);
      case "purchase_id":
        return getPONumberJsx(singleData.purchase_id);
      case "quantity":
        return getPOQuantityJsx(singleData);
      case "section":
        return getSectionJsx(singleData);
      case "row":
        return getRowJsx(singleData);
      case "cost_price":
        return getCostPriceJsx(singleData);
      case "secondary_cost":
        return getAltCostJsx(singleData);
      case "list_price":
        return getListPriceJsx(singleData);
      case "availability":
        return getZoneAvailabilityJsx(singleData);
      case "is_zone_seating":
        return getZoneSeatingJsx(singleData.is_zone_seating);
      case "internal_notes":
        return getInternalNotesJsx(singleData.internal_notes);
      case "listed_at":
        return getCustomDateTime(singleData.listed_at, "MMM DD, hh:mm A");
      case "listed_by":
        return getListedByJsx(singleData.listed_by);
      case "inventory_tag":
        return getInventoryTagJsx(singleData.inventory_tag);
      case "po_tags":
        return getPOTagsJsx(singleData.po_tags);
      case "tb_placement_check":
        return getTBPlacementJsx(singleData.tb_placement_check);
      case "inventory_id":
        return isDataChanged() &&
          !listPriceInputError &&
          !singleData.is_updating
          ? getSaveActionJsx()
          : "";
      case "purchase_line_id":
        return singleData.is_updating ? (
          <LoadingSpinner isLoading={singleData.is_updating ? true : false} />
        ) : (
          ""
        );
      case "broadcast":
        return getBroadcastStatusJsx(singleData);
      case "split":
        return (
          <RBAC
            allowedPermissions={[
              ERbacPermissions.ZONES_ACTIVE_LISTING_SPLIT_ACTION,
            ]}
          >
            {getSplitListingCta(singleData)}
          </RBAC>
        );
      case "inventory_ids":
        return getSelectCheckboxJsx(singleData);
      // case "stub_hub":
      // case "vivid_seats":
      // case "ticket_network":
      //   return getCompetitivePrice(
      //     currentEventData,
      //     singleData.zone_section_alias_dictionary,
      //     singleData.row,
      //     eachHeader.dataIndex,
      //     eachHeader.miscData
      //   );
      default:
        return singleData[eachHeader.dataIndex];
    }
    // }
    // return "";
  };

  return (
    <tr style={{ backgroundColor: "#F4ECDF" }}>
      {poTableHeaders.map((eachHeader) => {
        return (
          <td
            key={`porow_${eachHeader.title + eachHeader.dataIndex}}`}
            className={eachHeader.tdClassName ?? ""}
            style={{
              ...eachHeader.tdStyle,
            }}
          >
            {(!eachHeader.allowedPermissions ||
              hasPermissions(eachHeader.allowedPermissions)) && (
              <React.Fragment>
                {getRowElements(eachHeader, data)}
              </React.Fragment>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default AllZoneListingPoTableDataRow;