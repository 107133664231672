import { message } from "antd";
import {
  IMenuOption,
  ISwimlaneInvoiceList,
  ISwimlaneMaster
} from "models/interfaces";
import React from "react";
import { PurchaseAndShippingService } from "services";

const MarkAsPurchased: React.FC<{
  menuData: IMenuOption
  swimlaneData: ISwimlaneMaster;
  eachInvoiceData: ISwimlaneInvoiceList;
  setShowMenu: Function;
  setSelectedMenuId: Function;
  requestToReloadTheLanes: Function;
}> = ({
  menuData,
  swimlaneData,
  eachInvoiceData,
  setShowMenu,
  setSelectedMenuId,
  requestToReloadTheLanes
}) => {
    const moveToPurchased = async () => {
      const moveToPurchasedKey = "moveToPurchasedKey";
      setSelectedMenuId(null);
      setShowMenu(false);
      message.loading({
        content: `Moving Invoice from ${swimlaneData.name} to Purchased Queue.`,
        duration: 0,
        key: moveToPurchasedKey,
        className: "toastMsg loadingMsg",
      });

      const res = await new PurchaseAndShippingService()
        .moveToPurchased({
          invoice_id: eachInvoiceData.invoice_id
        });

      if (res.data.data) {
        requestToReloadTheLanes([swimlaneData.id, 2]);
        message.success({
          content: `Invoice #${eachInvoiceData.invoice_id} moved from ${swimlaneData.name} to Purchased Queue.`,
          duration: 3,
          key: moveToPurchasedKey,
          className: "toastMsg savedSuccess",
        });
      } else {
        message.error({
          content: `Failed to move Invoice #${eachInvoiceData.invoice_id}.`,
          duration: 5,
          key: moveToPurchasedKey,
          className: "toastMsg savedFailed",
        });
      }

      setTimeout(() => {
        requestToReloadTheLanes(null);
      }, 500);
    }

    return (
      <React.Fragment>
        <div
          className="accHead"
          onClick={moveToPurchased}>
          {menuData.name}
        </div>
      </React.Fragment>
    );
  };

export default MarkAsPurchased;
