import { IAuditHistory } from "models/interfaces";
import { getCustomDate, getCustomTimeFromDate } from "utils/formatTimePeriod";

export const auditHistoryTableHeaders = [
  {
    title: "ZONE / SECTION",
    dataIndex: "id",
    className: "text-left",
    render: (_data: number, rowData: IAuditHistory) => {
      return `${getCustomDate(
        rowData.updated_on,
        "ddd, MMM D, YYYY"
      )} at ${getCustomTimeFromDate(rowData.updated_on)} by ${
        rowData.updated_by
      }`;
    },
  },
];
