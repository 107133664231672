import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import {
  IMenuOption,
  IPurchaseQueueMasterData,
  IPurchaseQueueMenuOption,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import React, { useCallback, useState } from "react";
import OutsideClickHandler from "utils/OutsideClickHandler";
import {
  ChangeBuyer,
  ChangeOnSaleStatus,
  ChangePriority,
  ChangeShipperShipping,
  CloseIssue,
  MarkAsPurchased,
  MoveToDeliveredShipping,
  MoveToIssues,
  MoveToIssuesShipping,
  MoveToNeedToBuy,
  MoveToNotDeliveredShipping,
  MoveToOnSale,
  MoveToSellOutShipping,
  MoveToShipping,
  UpdateIssue,
  UpdateIssueShipping,
  ViewAuditHistory,
  AddNote,
  AddNoteShipping,
  MoveToNeedToBuyLowPriority
} from "./SubMenuComponents";
import CloseIssueShipping from "./SubMenuComponents/CloseIssueShipping";

const KebabMenu: React.FC<{
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  kebabMenuOptions: IPurchaseQueueMenuOption[] | null;
  setShowNotesHistory: Function;
}> = ({
  eachInvoiceData,
  swimlaneData,
  requestToReloadTheLanes,
  purchaseQueueMaster,
  kebabMenuOptions,
  setShowNotesHistory
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const handleMenuSelection = useCallback((menuId: number | null) => {
    setSelectedMenuId(menuId);
  },[selectedMenuId]);

  const menuItemColorClass = (eachMenu: IMenuOption) => {
    let className = "";
    if (eachMenu.menu_ui_class_name) {
      className = className + " " + eachMenu.menu_ui_class_name;
    }

    if (eachMenu.menu_id === selectedMenuId) {
      className = className + " active";
    }

    return className;
  };

  const menuClickHandler = (eachMenu: IMenuOption) => {
    if (selectedMenuId === eachMenu.menu_id) {
      handleMenuSelection(null);
    } else {
      handleMenuSelection(eachMenu.menu_id);
    }
  };

  const getMenus = () => {
    const menus = kebabMenuOptions?.find(
      (each) => each.swimlane_id === swimlaneData.id
    )?.menu_options;
    if (menus) {
      return menus.map((eachMenu) => {
        switch (eachMenu.menu_id) {
          case 2:
            return (
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.SHIPPING_QUEUE_KEBAB_MENU_CLOSE_ISSUE,
                ]}
              >
                <li className={menuItemColorClass(eachMenu)}>
                  <CloseIssue
                    menuData={eachMenu}
                    eachInvoiceData={eachInvoiceData}
                    handleMenuSelection={handleMenuSelection}
                    selectedMenuId={selectedMenuId}
                    setSelectedMenuId={setSelectedMenuId}
                    setShowMenu={setShowMenu}
                    requestToReloadTheLanes={requestToReloadTheLanes}
                    purchaseQueueMaster={purchaseQueueMaster}
                  />
                </li>
              </RBAC>
            );
            case 29:
              return (
                <RBAC
                  allowedPermissions={[
                    ERbacPermissions.SHIPPING_QUEUE_KEBAB_MENU_CLOSE_ISSUE,
                  ]}
                >
                  <li className={menuItemColorClass(eachMenu)}>
                    <CloseIssueShipping
                      menuData={eachMenu}
                      eachInvoiceData={eachInvoiceData}
                      handleMenuSelection={handleMenuSelection}
                      selectedMenuId={selectedMenuId}
                      setSelectedMenuId={setSelectedMenuId}
                      setShowMenu={setShowMenu}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                      purchaseQueueMaster={purchaseQueueMaster}
                    />
                  </li>
                </RBAC>
              );
          case 3:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <UpdateIssue
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 4:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MarkAsPurchased
                  menuData={eachMenu}
                  swimlaneData={swimlaneData}
                  eachInvoiceData={eachInvoiceData}
                  setShowMenu={setShowMenu}
                  setSelectedMenuId={setSelectedMenuId}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                />
              </li>
            );
          case 5:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToShipping
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  swimlaneData={swimlaneData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 6:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToNeedToBuy
                  menuData={eachMenu}
                  invoice_id={eachInvoiceData.invoice_id}
                  swimlaneData={swimlaneData}
                  setShowMenu={setShowMenu}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                />
              </li>
            );
          case 26:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToNeedToBuyLowPriority
                  menuData={eachMenu}
                  invoice_id={eachInvoiceData.invoice_id}
                  swimlaneData={swimlaneData}
                  setShowMenu={setShowMenu}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                />
              </li>
            );
          case 27:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <AddNote
                  menuData={eachMenu}
                  invoice_id={eachInvoiceData.invoice_id}
                  swimlaneData={swimlaneData}
                  setShowMenu={setShowMenu}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowNotesHistory={setShowNotesHistory}
                />
              </li>
            );
            case 28:
              return (
                <li className={menuItemColorClass(eachMenu)}>
                  <AddNoteShipping
                    menuData={eachMenu}
                    invoice_id={eachInvoiceData.invoice_id}
                    swimlaneData={swimlaneData}
                    setShowMenu={setShowMenu}
                    handleMenuSelection={handleMenuSelection}
                    selectedMenuId={selectedMenuId}
                    setSelectedMenuId={setSelectedMenuId}
                    setShowNotesHistory={setShowNotesHistory}
                  />
                </li>
              );
          case 7:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToDeliveredShipping
                  menuData={eachMenu}
                  swimlaneData={swimlaneData}
                  eachInvoiceData={eachInvoiceData}
                  setShowMenu={setShowMenu}
                  setSelectedMenuId={setSelectedMenuId}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                />
              </li>
            );
          case 17:
            return (
              <React.Fragment>
                {eachInvoiceData.fulfillment_status_id === 2 && (
                  <li className={menuItemColorClass(eachMenu)}>
                    <MoveToNotDeliveredShipping
                      menuData={eachMenu}
                      swimlaneData={swimlaneData}
                      eachInvoiceData={eachInvoiceData}
                      setShowMenu={setShowMenu}
                      setSelectedMenuId={setSelectedMenuId}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                    />
                  </li>
                )}
              </React.Fragment>
            );
          case 8:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToOnSale
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  swimlaneData={swimlaneData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 9:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <ChangeOnSaleStatus
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 10:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <MoveToIssues
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  swimlaneData={swimlaneData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 12:
            return (
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.PURCHASE_QUEUE_MENU_CHANGE_BUYER,
                ]}
              >
                <li className={menuItemColorClass(eachMenu)}>
                  <div
                    className="accHead"
                    onClick={() => menuClickHandler(eachMenu)}
                  >
                    {eachMenu.name}
                  </div>
                  {selectedMenuId === eachMenu.menu_id && (
                    <ChangeBuyer
                      eachInvoiceData={eachInvoiceData}
                      handleMenuSelection={handleMenuSelection}
                      swimlaneData={swimlaneData}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                      setShowMenu={setShowMenu}
                    />
                  )}
                </li>
              </RBAC>
            );
          case 13:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <ChangePriority
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  swimlaneData={swimlaneData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 16:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <UpdateIssueShipping
                  menuData={eachMenu}
                  eachInvoiceData={eachInvoiceData}
                  handleMenuSelection={handleMenuSelection}
                  selectedMenuId={selectedMenuId}
                  setSelectedMenuId={setSelectedMenuId}
                  setShowMenu={setShowMenu}
                  requestToReloadTheLanes={requestToReloadTheLanes}
                  purchaseQueueMaster={purchaseQueueMaster}
                />
              </li>
            );
          case 19:
            return (
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.SHIPPING_QUEUE_MENU_CHANGE_SHIPPER,
                ]}
              >
                <li className={menuItemColorClass(eachMenu)}>
                  <div
                    className="accHead"
                    onClick={() => menuClickHandler(eachMenu)}
                  >
                    {eachMenu.name}
                  </div>
                  {selectedMenuId === eachMenu.menu_id && (
                    <ChangeShipperShipping
                      eachInvoiceData={eachInvoiceData}
                      handleMenuSelection={handleMenuSelection}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                      purchaseQueueMaster={purchaseQueueMaster}
                      swimlaneData={swimlaneData}
                      setShowMenu={setShowMenu}
                    />
                  )}
                </li>
              </RBAC>
            );
          case 20:
            return (
              <li className={menuItemColorClass(eachMenu)}>
                <ViewAuditHistory
                  menuData={eachMenu}
                  invoice_id={eachInvoiceData.invoice_id}
                  setShowMenu={setShowMenu}
                  swimlaneData={swimlaneData}
                />
              </li>
            );
          case 23:
            return (
              <React.Fragment>
                {eachInvoiceData.fulfillment_status_id === 2 && (
                  <li className={menuItemColorClass(eachMenu)}>
                    <MoveToSellOutShipping
                      menuData={eachMenu}
                      invoice_id={eachInvoiceData.invoice_id}
                      swimlaneData={swimlaneData}
                      setShowMenu={setShowMenu}
                      handleMenuSelection={handleMenuSelection}
                      selectedMenuId={selectedMenuId}
                      setSelectedMenuId={setSelectedMenuId}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                    />
                  </li>
                )}
              </React.Fragment>
            );
          case 25:
            return (
              <React.Fragment>
                {eachInvoiceData.fulfillment_status_id === 2 && (
                  <li className={menuItemColorClass(eachMenu)}>
                    <MoveToIssuesShipping
                      menuData={eachMenu}
                      eachInvoiceData={eachInvoiceData}
                      swimlaneData={swimlaneData}
                      handleMenuSelection={handleMenuSelection}
                      selectedMenuId={selectedMenuId}
                      setSelectedMenuId={setSelectedMenuId}
                      setShowMenu={setShowMenu}
                      requestToReloadTheLanes={requestToReloadTheLanes}
                      purchaseQueueMaster={purchaseQueueMaster}
                    />
                  </li>
                )}
              </React.Fragment>
            );
          default:
            return <React.Fragment></React.Fragment>;
        }
      });
    }
  };

  return (
    <div className="kebabDropdownWrap">
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowMenu(false);
          setSelectedMenuId(null);
        }}
      >
        <span
          className="da icon-kebab-v"
          onClick={() => setShowMenu((prev) => !prev)}
        />
        <ul className={`kebabDropdown ${showMenu ? "kebabMenuActive" : ""}`}>
          {getMenus()}
        </ul>
      </OutsideClickHandler>
    </div>
  );
};

export default KebabMenu;
