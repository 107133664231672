import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import ReportLayout from "../ReportLayout";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import GrossProfitTable from "./GrossProfitCancelledTable";
import { useLocation } from "react-router";
import CreateScheduler from "utils/commonScheduler/createScheduler";
import { IFilterOptions, IReportQueryString } from "models/interfaces";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";
import { usePageTitle } from "hooks";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";

const GrossProfitCancelled: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(actions.getRecipientFilterData());
    }, [dispatch]);
    usePageTitle("Gross Profit Cancelled");
    const location = useLocation();
    const queryParams = urlQueryParamsToObject(location.search);
    const reportQueryString: IReportQueryString = decodeQueryStringData(
        queryParams.report
    );

    let filterQueryString;

    if (location.search.includes("filter")) {
        filterQueryString = decodeQueryStringData(
            queryParams.filter
        );
    }
    const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
    const [filtersAppliedWithValues, setFilterAppliedWithValues] = useState<any>(null)
    const [search, setSearch] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string | null>(null)

    const filterChanged = (filters: any) => {
        setFilterObject({
            ...filters,
        });
    };

    const handleSearch = (e: any) => {
        e.preventDefault();
        setSearchQuery(search)
    };

    const closeSearch = () => {
        setSearch('')
        setSearchQuery('')
    };

    return (
        <React.Fragment>
            <div className="mainHeading">
                <Row className="flx-1">
                    <Col span={9}>
                        <h2>{reportQueryString.current_report}</h2>
                    </Col>
                    <Col span={6}>
                        <div className="search">
                            <form onSubmit={handleSearch}>
                                <input
                                    type="search"
                                    placeholder="Search invoice id, po number, or event name..."
                                    value={search ? search : ''}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                {search && search.length > 0 && (
                                    <span onClick={closeSearch}>&times;</span>
                                )}
                            </form>
                        </div>
                    </Col>
                    <Col span={9}>
                        <div className="rightFilter">
                            <CreateScheduler
                                schedulerTitle={"Create Schedule"}
                                reportData={reportQueryString}
                                filterData={filterObject}
                            />
                            <FilterDrawer
                                current_report_id={reportQueryString.current_report_id}
                                showPLDatePicker={true}
                                showBrokerDropdown={true}
                                showNoTicketCheckbox={true}
                                onApplyFilter={filterChanged}
                                onApplyFilterWithValues={(e: any) => setFilterAppliedWithValues(e)}
                                brokerDropdownType="single"
                                defaultAndValues={{
                                    preFilledValues: {
                                        pl_date_from: filterQueryString?.pl_date_from,
                                        pl_date_to: filterQueryString?.pl_date_to,
                                    },
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <ReportLayout>
                <Layout className="mainInnerLayout pb-0 reduceTopSpace">
                    <GrossProfitTable
                        filterObject={filterObject}
                        appliedFilterValues={filtersAppliedWithValues}
                        searchQuery={searchQuery}
                    />
                </Layout>
            </ReportLayout>
        </React.Fragment>
    );
};

export default GrossProfitCancelled;
