export const chartProperties = {
  json: {
    chart: {
      type: "area"
    }
  },
  normal: {
    fill: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      opacity: 1
    },
    negativeFill: {
      color: "#B0C0D7"
    },
    stroke: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      lineJoin: "round",
      lineCap: "round",
      thickness: 1
    },
    negativeStroke: { color: "#B0C0D7" }
  },
  hovered: {
    fill: {
      keys: ["#c68105", "#e9a939"],
      angle: 90
    },
    negativeFill: {
      color: "#B0C0D7"
    },
    stroke: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      lineJoin: "round",
      thickness: 2
    },
    negativeStroke: { color: "#B0C0D7", thickness: 2 }
  },
  selected: {
    fill: { color: "#E39408", opacity: 0.8 },
    negativeFill: { color: "#B0C0D7", opacity: 1 },
    stroke: {
      color: "#E39408",
      thickness: 4,
      lineJoin: "round"
    },
    negativeStroke: { color: "#B0C0D7", thickness: 4 }
  },
};

export const chartProperties2 = {
  json: {
    chart: {
      type: "area"
    },
  },
  normal: {
    fill: {
      keys: ["#9F2533"],
      angle: 90,
      opacity: 1
    },
    negativeFill: {
      color: "#B0C0D7"
    },
    stroke: {
      keys: ["#9F2533"],
      angle: 90,
      lineJoin: "round",
      lineCap: "round",
      thickness: 1
    },
    negativeStroke: { color: "#B0C0D7" }
  },
  hovered: {
    fill: {
      keys: ["#9F2533"],
      angle: 90
    },
    negativeFill: {
      color: "#B0C0D7"
    },
    stroke: {
      keys: ["#9F2533"],
      angle: 90,
      lineJoin: "round",
      thickness: 2
    },
    negativeStroke: { color: "#B0C0D7", thickness: 2 }
  },
  selected: {
    fill: { color: "#9F2533", opacity: 0.8 },
    negativeFill: { color: "#B0C0D7", opacity: 1 },
    stroke: {
      color: "#9F2533",
      thickness: 4,
      lineJoin: "round"
    },
    negativeStroke: { color: "#B0C0D7", thickness: 4 }
  },
};