import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IListElement } from "models/interfaces";
import React from "react";

const SelectCheckers: React.FC<{
  assignedCheckers: IListElement[];
  checkerIds: string[] | null;
  setCheckerIds: Function;
}> = ({ assignedCheckers, checkerIds, setCheckerIds }) => {
  const { hasPermissions } = useRbac();
  
  const checkerSelectionHandler = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (checkerIds) {
      if (isChecked) {
        if (!checkerIds.includes(value)) {
          setCheckerIds([...checkerIds, value]);
        }
      } else {
        const ind = checkerIds.findIndex((id) => id === value);
        checkerIds.splice(ind, 1);

        setCheckerIds(checkerIds);
      }
    } else {
      setCheckerIds([value]);
    }
  };

  return (
    <React.Fragment>
      {assignedCheckers && checkerIds && assignedCheckers?.length > 0 && (
        <div id="clmCheckers" className="ant-col ant-col-xs-24 ant-col-sm-24">
          <div className="inputGroup">
            <label>Checkers</label>
            <ul className={`checkboxList ant-row${!hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_CHECKER_SELECTION,
                      ]) ? " elementDisabled" : ""}`}>
              {assignedCheckers?.map((data: any, ind: any) => (
                <li key={'checker_'+ind} className="ant-col ant-col-xs-24 ant-col-sm-8 ant-col-lg-8 ant-col-xl-8">
                  <Checkbox
                    defaultChecked={data.checked}
                    disabled={
                      !hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_CHECKER_SELECTION,
                      ])
                    }
                    value={data.value}
                    onChange={checkerSelectionHandler}
                  >
                    {data.label}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectCheckers;
