import React, {useEffect, useState} from 'react';
import { Modal, Tooltip } from 'antd';
import NewUserForm from './NewUserForm';
import { UserDetailsData } from 'models/interfaces';


const NewUser: React.FC<{ onSuccessUserSaved: Function, userDataOnEdit?: UserDetailsData, maxTicketsAllowed: number}> = ({onSuccessUserSaved, userDataOnEdit, maxTicketsAllowed}) => {
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userData, setUserData] = useState<any>(null);

    useEffect(() => {
        if(isModalVisible){
            let umUserId = userDataOnEdit?.id ? userDataOnEdit?.id : null;
            let skyboxUserId = userDataOnEdit?.skybox_user_id ? userDataOnEdit?.skybox_user_id : null;
            let firstName = userDataOnEdit?.first_name ? userDataOnEdit?.first_name : null;
            let lastName = userDataOnEdit?.last_name ? userDataOnEdit?.last_name : null;
            let email = userDataOnEdit?.email ? userDataOnEdit?.email : null;
            let roleIds:string[] = [];
            if(userDataOnEdit?.roles){
                userDataOnEdit?.roles.forEach((eachRole: any)=>{
                    roleIds.push(eachRole.rold_id);
                })
            }
            let brokerTagId: number[] | null = null;
            let buyerTagId: string | null = null;
            let listerTagId: string | null = null;
            let defaultListingQuantity = 7;
            let internalNotes = "-tmplus";
            let minMarkup: number | null = null;
            let vendor: number | null = null;
            let defaultInHandDays = 3;
            let defaultSplitType: number | null = null;
            let defaultMarkupType = 1; //1= percentage 
            let defaultMarkup: number | null = null;
            let brokerUserId = userDataOnEdit?.associated_with_broker_user_id ? userDataOnEdit?.associated_with_broker_user_id : null;
            if( userDataOnEdit && userDataOnEdit.other_details && userDataOnEdit.other_details.length){
                let userOtherDetails = userDataOnEdit.other_details[0];
                brokerTagId = userOtherDetails.brokerTagId?.length ? userOtherDetails.brokerTagId : null;
                buyerTagId = userOtherDetails.buyerTagId;
                listerTagId = userOtherDetails.listerTagId;
                if(userOtherDetails.defaultListingQuantity && !isNaN(userOtherDetails.defaultListingQuantity)){
                    defaultListingQuantity = userOtherDetails.defaultListingQuantity
                }
                if(userOtherDetails.internalNotes && userOtherDetails.internalNotes!==null){
                    internalNotes = userOtherDetails.internalNotes
                }
                if(userOtherDetails.vendor){
                    vendor = userOtherDetails.vendor
                }
                if(userOtherDetails.minMarkup && !isNaN(userOtherDetails.minMarkup)){
                    minMarkup = userOtherDetails.minMarkup
                }
                if(userOtherDetails.defaultInHandDays && !isNaN(userOtherDetails.defaultInHandDays)){
                    defaultInHandDays = userOtherDetails.defaultInHandDays
                }
                defaultSplitType = userOtherDetails.defaultSplitType;
                if(userOtherDetails.defaultMarkupType && !isNaN(userOtherDetails.defaultMarkupType)){
                    defaultMarkupType = userOtherDetails.defaultMarkupType
                }
                defaultMarkup = userOtherDetails.defaultMarkup;
            }
            setUserData((prev: any)=>({
                ...prev,
                umUserId: umUserId,
                skyboxUserId: skyboxUserId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                roleIds: roleIds,
                brokerTagId: brokerTagId,
                buyerTagId: buyerTagId,
                listerTagId: listerTagId,
                defaultListingQuantity: defaultListingQuantity,
                internalNotes: internalNotes,
                minMarkup: minMarkup,
                vendor: vendor,
                defaultInHandDays: defaultInHandDays,
                defaultSplitType: defaultSplitType,
                defaultMarkupType: defaultMarkupType, //percentage
                defaultMarkup: defaultMarkup,
                brokerUserId: brokerUserId,
                max_tickets_allowed: maxTicketsAllowed
            }));
        }
    }, [isModalVisible, userDataOnEdit]);

    const closeModal = () => {
        setIsModalVisible(false);
        setUserData(null);
    }

    const onSuccessSavedUser = (success:boolean) =>{
        onSuccessUserSaved(success);
        setIsModalVisible(false);
        setUserData(null);
    }

    return (
        <React.Fragment>
            {!userDataOnEdit &&
                <div className='addUser' onClick={() => setIsModalVisible(true)}>                    
                    <div className='addUserBtn'>
                        <i className='da icon-add-user-o'/><span>New User</span>
                    </div>                    
                </div>
            }
            {userDataOnEdit &&
                <Tooltip title="Edit" placement='top'>
                    <div className='text-left' style={{cursor:'pointer'}} onClick={() => {
                        setIsModalVisible(true);
                    }}>
                        <i className='da icon-pencil-o' />
                    </div>
                </Tooltip>
            }
            {isModalVisible &&
                <Modal closable={false} width={870} title={false} footer={false} visible={isModalVisible} centered className="editModal">
                    {/* {console.log(userData)} */}
                    {userData && isModalVisible &&
                        <NewUserForm onSuccess={onSuccessSavedUser} isModalVisible={isModalVisible} userData={userData} setUserData={setUserData} closeModal={closeModal} oldUserData={userDataOnEdit} />
                    }
                </Modal>
            }
        </React.Fragment>
    )
}

export default NewUser
