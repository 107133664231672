import { RoutesEnum } from "models/enums";
import {
  IDashboardAlertsQueryParams,
  IListerSeatAvailabilityData,
  IListerSeatAvailabilityQueryParams,
  IListerKPIsNewListingGraphData,
  IListerKPIsGraphQueryParams
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class CheckerDashboardService {
  public getCheckerNewShowsCount = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{newShowsCount: number}>>(
      RoutesEnum.DASHBOARD_CHECKER_ALERTS_NEW_SHOWS_COUNT,
      {
        params: query,
      }
    );
  };

  public getCheckerSeatAvailabilityList = async (query: IListerSeatAvailabilityQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerSeatAvailabilityData>>(
      RoutesEnum.DASHBOARD_CHECKER_SEAT_AVAILABILITY,
      {
        params: query,
      }
    );
  };

  public getKPIsSeatAvailabilityUpdateGraphData = async (query: IListerKPIsGraphQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerKPIsNewListingGraphData>>(
      RoutesEnum.DASHBOARD_CHECKER_KPIS_SEAT_AVAILABILITY_UPDATE_GRAPH,
      {
        params: query,
      }
    );
  };
}
