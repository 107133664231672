import { RoutesEnum } from "models/enums";
import { HighRiskCategory } from "models/enums/highRiskCategory";
import {
  IHighRiskListings,
  IPendingZoneFillsListData,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class OperationsDashboardService {
  public getHighRiskListings = async (highRiskListingQueryDto: {
    risk_type: HighRiskCategory;
  }) => {
    return axiosClient.get<IApiBaseResponse<IHighRiskListings>>(
      RoutesEnum.DASHBOARD_OPERATIONS_ALERTS_HIGH_RISK_LISTINGS,
      {
        params: highRiskListingQueryDto,
      }
    );
  };

  public getPendingZoneFillsWidgetsCounts = async () => {
    return axiosClient.get<IApiBaseResponse<IPendingZoneFillsListData>>(
      RoutesEnum.DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_COUNTS,
      {
        params: { if_widget_counts: true },
      }
    );
  };

  public getPendingZoneFillsWidgetsSales = async () => {
    return axiosClient.get<IApiBaseResponse<IPendingZoneFillsListData>>(
      RoutesEnum.DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_SALES,
      {
        params: { if_widget_counts: false },
      }
    );
  };

  public getPendingZoneFillsWidgetsBrokers = async () => {
    return axiosClient.get<IApiBaseResponse<IPendingZoneFillsListData>>(
      RoutesEnum.DASHBOARD_OPERATIONS_PENDING_FILLS_ALERTS_BROKERS
    );
  };
}
