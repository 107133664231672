import { useEffect, useState } from "react";

/** Hook for using anychart module */
export const useAnychart = () => {
    const [isAnychartLoaded, setIsAnychartLoaded] = useState<{anychart: any, isAnychartReady: boolean}>({anychart: null, isAnychartReady: false});
    useEffect(()=>{
        if (window.anychart) {
          window.anychart.licenseKey(
            process.env.REACT_APP_ANYCHART_LICENSE_KEY?.toString()
          );
          // disable credits by customizing default theme
          const customTheme = { chart: { credits: { enabled: false } } };
          window.anychart.theme(customTheme);
          setIsAnychartLoaded({
            anychart: window.anychart,
            isAnychartReady: true,
          });
        } else {
          setIsAnychartLoaded({
            anychart: null,
            isAnychartReady: false,
          });
        }
    },[]);

    return isAnychartLoaded;
}