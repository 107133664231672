import { RoutesEnum } from "models/enums";
import { IListedUser } from "models/interfaces";
import { IApiBaseResponse, IUserDetails } from "models/interfaces/Iglobal";
import { userType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class UserService {
    public getUserDetails = async() => {
        return axiosClient.get<IApiBaseResponse<IUserDetails>>(RoutesEnum.USER_DETAILS)
    };

    public createOrUpdateUser = async(data:any) => {
        return axiosClient.put<IApiBaseResponse<any>>(
            RoutesEnum.USER_CREATE_OR_UPDATE,
            {
                ...data
            }
        )
    };

    public getUserList = async (userListRequest: userType) => {
        return axiosClient.get<IApiBaseResponse<IListedUser>>(
            RoutesEnum.USER_LIST,
            {
                params: userListRequest
            }
        )
    };

    public getAuth0UserDetails = async (userId: string) => {
        return axiosClient.get<IApiBaseResponse<any>>(
            RoutesEnum.USER_AUTH0_DETAIL,
            {
                params: {
                    id: userId
                }
            }
        )
    };

    public resendInvite = async (userId: string) => {
        return axiosClient.get<IApiBaseResponse<any>>(
            RoutesEnum.USER_RESEND_INVITE,
            {
                params: {
                    id: userId
                }
            }
        )
    };

    public deleteUser = async (userId: string) => {
        
        return axiosClient.delete<IApiBaseResponse<any>>(
            RoutesEnum.USER_DELETE,
            {
                params: {
                    id: userId
                },
            }
        )
    }
}

export {UserService};