import { RoutesEnum } from "models/enums";
import {
  IDashboardAlertsQueryParams,
  IListerDashboardEventsResponse
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class SeatMapCreatorDashboardService {
  public getSeatMapCreatorShowsCount = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{showsCount: number}>>(
      RoutesEnum.DASHBOARD_SEAT_MAP_CREATOR_ALERTS_SHOWS_COUNT,
      {
        params: query,
      }
    );
  };

  public getSeatMapCreatorSeatStructureData = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerDashboardEventsResponse>>(
      RoutesEnum.DASHBOARD_SEAT_MAP_CREATOR_SEAT_STRUCTURE_DATA,
      {
        params: query,
      }
    );
  };

  public getKPIsSeatingStructureCreatedGraphData = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerDashboardEventsResponse>>(
      RoutesEnum.DASHBOARD_SEATMAP_SEATING_STRUCTURE_CREATED_GRAPH,
      {
        params: query,
      }
    );
  };
}
