import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Select,
  Switch,
  Tag,
} from "antd";
import {
  IEventMetadata,
  IMaster,
  IPendingZoneFillsListDatum,
  IZoneAvailabilityDatum,
  IZoneAvailabilityFormData
} from "models/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { EventService } from "services";
import { LoggerService, off, on } from "sharedServices";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import {
  addToolTip,
  formatAccountingValues,
  formatThousandSeparator,
  getTagClassForZones,
  titleCase
} from "utils/commonFunction";
import { AppSetting } from "utils/appSettings";
import { ICustomError } from "models/interfaces/ICustomError";
import CommonReportTable from "utils/table/CommonReportTable";
import CustomError from "controls/CustomError/CustomError";
import { purchaseAssignmentDataHeaders } from './purchaseAssignmentModalUtils';
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { FormatCurrencyUnit } from "utils/formatCurrency";

let currentPurchaseAssignmentData: any | null = null;

const PurchaseAssignmentModal: React.FC<{
  setIsNewAssignmentSaved: Function
}> = ({ setIsNewAssignmentSaved }) => {
  const [form] = Form.useForm();
  const actionRef = useRef<HTMLDivElement | null>(null);
  const [showPurchaseAssignmentModal, setShowPurchaseAssignmentModal] = useState(false);
  const [isLoadingModalData, setIsLoadingModalData] = useState(false);

  const [baseData, setBaseData] = useState<IPendingZoneFillsListDatum | null>(null);
  const [buyerMaster, setBuyerMaster] = useState<any>([]);
  const [priorityMaster, setPriorityMaster] = useState<IMaster[]>([]);
  const [onSaleMaster, setOnSaleMaster] = useState<IMaster[]>([]);
  const [onSaleTypeMaster, setOnSaleTypeMaster] = useState<IMaster[]>([]);
  const [issuesTypeMaster, setIssuesTypeMaster] = useState<IMaster[]>([]);
  const [zoneAvailabilityMaster, setZoneAvailabilityMaster] = useState<IMaster[]>([]);
  const [zoneAvailabilityData, setZoneAvailabilityData] = useState<IZoneAvailabilityDatum[] | null>(null);
  const [eventMetadata, setEventMetadata] = useState<any>(null);
  const [poLineIds, setPoLineIds] = useState<number[]>([]);

  const [updateAssignmentData, setUpdateAssignmentData] = useState<IZoneAvailabilityFormData | null>(null);
  const [ifShowOnSaleTypes, setIfShowOnSaleTypes] = useState<boolean>(false);
  const [ifIssueTypesDisabled, setIfIssueTypesDisabled] = useState<boolean>(false);
  const [ifShowOnSaleDate, setIfShowOnSaleDate] = useState<boolean>(false);
  const [ifAvailabilityUpdated, setIfAvailabilityUpdated] = useState<boolean>(false);
  const [zoneId, setZoneId] = useState<any>(null);

  const [isValueChanged, setIsValueChanged] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);

  const purchaseAssignmentStatusHeaders = [
    {
      title: "Availability Updated on ",
      dataIndex: "updated_on",
      width: 70,
      dataRender: (updatedOn: string) =>
        `${updatedOn ? formatDateGivenFormatString(updatedOn, "ddd, MMM DD, YYYY") : "Info not available"}`,
    },
    {
      title: "Availability Status",
      dataIndex: "zone_availability_id",
      width: 60,
      className: "text-center",
      dataRender: (_data: string, otherData: any) => {
        const zoneAvailability = getTagClassForZones(
          otherData.data.singleData.zone_availability_id,
          zoneAvailabilityMaster ? zoneAvailabilityMaster : []
        );

        let tagClassName =
          zoneAvailability && zoneAvailability.className
            ? `${zoneAvailability.className} purchaseAssignmentAvailabilityStatus`
            : "purchaseAssignmentAvailabilityStatus";

        return (
          <div className="text-center">
            {otherData.is_web_scraping === 1 &&
            Boolean(otherData.available_seats === null ||  otherData.total_seats === null) &&
              <Tag
                className={tagClassName}
                onClick={() => changeAvailabilityStatus(otherData)}
                style={{ cursor: "pointer" }}
              >
                {zoneAvailability?.zoneAvailability?.label}
              </Tag>
            }
            {otherData.is_web_scraping === 1 &&
              <Tag
                className={tagClassName}
                style={{ cursor: "default" }}
              >
                {`${otherData.available_seats} / ${otherData.total_seats}`}
              </Tag>
            }
            {otherData.is_web_scraping !== 1 &&
              <Tag
                className={tagClassName}
                onClick={() => changeAvailabilityStatus(otherData)}
                style={{ cursor: "pointer" }}
              >
                {zoneAvailability?.zoneAvailability?.label}
              </Tag>
            }
          </div>
        );
      },
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      thClassName: "text-right eventDateTimeTh",
      width: 50,
      dataRender: (unitCost: number, otherData: any) => (
        <div className={`text-right ${unitCost < 0 ? "text-danger" : ""}`}>
          {unitCost > 0 && otherData.data.singleData.currency_code
            ? FormatCurrencyUnit(
              formatAccountingValues(unitCost),
              otherData.data.singleData.currency_code.toUpperCase()
            )
            : FormatCurrencyUnit(
              formatAccountingValues(unitCost),
              "USD"
            )}
        </div>
      ),
    },
  ];

  const actionHandler = async () => {
    if (currentPurchaseAssignmentData.action === "updateAssignment") {
      try {
        setBaseData(currentPurchaseAssignmentData.data.data);
        setShowPurchaseAssignmentModal(true);
        setIsLoadingModalData(true);
        const res = await new EventService().getPendingZoneFillsPurchaseAssignmentData({
          purchase_line_ids: getUniquePurchaseLineIds(),
          venue_id: currentPurchaseAssignmentData.data.data.venue_id
        });
        const purchaseAssignmentResponseData = res.data.data;
        setBuyerMaster(purchaseAssignmentResponseData.buyer_master);
        setPriorityMaster(purchaseAssignmentResponseData.priority_master);
        setOnSaleMaster(purchaseAssignmentResponseData.on_sale_master);
        setOnSaleTypeMaster(purchaseAssignmentResponseData.on_sale_type_master);
        setIssuesTypeMaster(purchaseAssignmentResponseData.issues_type_master);
        setZoneAvailabilityMaster(purchaseAssignmentResponseData.zone_availability_master);
        
        const filterOfNotNullZoneId = purchaseAssignmentResponseData.zone_availability_data?.filter(
          (elem: any) => elem.zone_id !== null);
        if (filterOfNotNullZoneId
          && filterOfNotNullZoneId.length > 0
        ) {
          setZoneAvailabilityData([filterOfNotNullZoneId[0]]);
        } else {
          purchaseAssignmentResponseData.zone_availability_data &&
            setZoneAvailabilityData([purchaseAssignmentResponseData.zone_availability_data[0]]);
        }

        setUpdateAssignmentData({
          ...initialAssignmentData(purchaseAssignmentResponseData.zone_availability_data)
        });

        if (purchaseAssignmentResponseData.zone_availability_data &&
          purchaseAssignmentResponseData.zone_availability_data.length > 0) {
          const resMetadata = await new EventService().getEventMetadata({
            event_id: currentPurchaseAssignmentData.data.data.event_id
          });

          setEventMetadata(resMetadata.data.data.metadata);
        }

        setIsLoadingModalData(false);
      } catch (error: any) {
        setIsLoadingModalData(false);
        await new LoggerService().log({
          payload: error,
          function_name: "actionHandler",
        });

        setDataFetchError({ ...error, refreshAction: actionHandler });
      }
    }
  };

  useEffect(() => {
    const handler = (data: CustomEvent) => {
      currentPurchaseAssignmentData = data.detail;
      actionRef?.current?.click();
    };

    on("purchaseAssignmentAction:click", handler);
    return () => {
      off("purchaseAssignmentAction:click", handler);
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...updateAssignmentData,
    });
  }, [updateAssignmentData]);

  const cancelHandler = () => {
    if (isValueChanged || ifAvailabilityUpdated) {
      const header = (
        <span className="text-danger">
          All unsaved data will be lost.
        </span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => {
          afterCancelledOrSaved();
        },
        () => console.log("Cancelled cancelling process"),
        "400px"
      );
    } else {
      afterCancelledOrSaved();
    }
  };

  const onFinish = (values: any) => {
    const header = (
      <span className="text-danger">
        Once saved, the process cannot be undone. Please click confirm to save your action.
      </span>
    );
    const body = "Continue?";
    showConfirm(
      header,
      body,
      null,
      async () => {
        setIsLoadingModalData(true);

        const purchaseAssignmentDataLoadingKey = 'createPurchaseAssignments';
        message.loading({
          content: "Creating assignment...",
          duration: 0,
          key: purchaseAssignmentDataLoadingKey,
          className: "toastMsg loadingMsg",
        });

        const availabilityDetails = {
          invoice_id: baseData?.invoice_id,
          event_id: baseData?.event_id,
          buyer_id: buyerMaster.filter((item: any) => item.value === values.buyer_name)[0].user_id,
          priority: values.priority ? 1 : 2,
          on_sale_type: !updateAssignmentData?.on_sale ? null : values.on_sale_type,
          on_sale_date: !values.on_sale_date ? null : values.on_sale_date,
          issue_type: values.issue_type,
          notes: values.notes,
          swimlane_id: getSwimlaneName(values.on_sale, values.issue_type),
          event_date: formatDateGivenFormatString(baseData?.event_date_and_time, "YYYY-MM-DD")
        }

        try {
          let newZoneId = null;
          // if (poLineIds.length > 0) {
          //   const newMetadata:IEventMetadata = createMetadataBody();
          //   if (newMetadata.zone && newMetadata.zone[0].zone_availability_id !== null) {
          //     await new EventService().saveMetadata(newMetadata);
          //     if (zoneId === null) {
          //       const resMetadata = await new EventService().getEventMetadata({
          //         event_id: currentPurchaseAssignmentData.data.data.event_id
          //       });
          //       newZoneId = resMetadata.data.data.metadata?.zone &&
          //         resMetadata.data.data.metadata?.zone[0].id;
          //     }
          //   } else {
          //     newZoneId = null;
          //   }
          // }

          await new EventService().postPurchaseAssignment({
            ...availabilityDetails,
            zone_id: newZoneId
          });

          message.success({
            content: "Purchase Assignment created successfully!",
            key: purchaseAssignmentDataLoadingKey,
            duration: 5,
            className: "toastMsg savedSuccess",
          });

          setIsLoadingModalData(false);
          afterCancelledOrSaved();
          setIsNewAssignmentSaved(true);
        } catch (error: any) {
          message.error({
            content: "Purchase Assignment has failed to create.",
            duration: 5,
            key: purchaseAssignmentDataLoadingKey,
            className: "toastMsg savedFailed",
          });

          setIsLoadingModalData(false);
        }
      },
      () => console.log("Cancelled saving process"),
      "600px"
    );
  };

  const afterCancelledOrSaved = () => {
    setUpdateAssignmentData(null);
    setZoneAvailabilityData(null);
    setIsValueChanged(false);
    setShowPurchaseAssignmentModal(false);
    setIfIssueTypesDisabled(false);
    setIfShowOnSaleTypes(false);
    setIfShowOnSaleDate(false);
    setIfAvailabilityUpdated(false);
    form.setFieldsValue({
      ...initialAssignmentData(zoneAvailabilityData)
    });
  }

  const getUniquePurchaseLineIds = () => {
    if (currentPurchaseAssignmentData.data.data.purchase_line_id) {
      const uniqueLineIds = currentPurchaseAssignmentData?.data.data.purchase_line_id
        .filter((item: number, index: number, a: any) => a.indexOf(item) === index);

      setPoLineIds(uniqueLineIds);

      return uniqueLineIds.toString();
    }
    setPoLineIds([]);
    return null;
  }

  const changeAvailabilityStatus = (otherData: any) => {
    setIfAvailabilityUpdated(true);
    const newStatus = getNewAvailabilityStatus(otherData.data.singleData.zone_availability_id);
    let newStatusData: any = null;
    if (newStatus !== null) {
      const indexOfStatus = zoneAvailabilityMaster?.
        findIndex(obj =>
          obj.value === newStatus);
      newStatusData = zoneAvailabilityMaster[indexOfStatus];
    }

    const updatedZoneAvailabilityData: any = zoneAvailabilityData?.map(elem =>
      elem.purchase_line_id === otherData.data.singleData.purchase_line_id ?
        {
          ...elem,
          zone_availability_id: newStatusData ? newStatusData.value : null,
          zone_availability_status: newStatusData ? newStatusData.label : null
        } :
        elem
    );

    setZoneAvailabilityData(updatedZoneAvailabilityData);
  }

  const getNewAvailabilityStatus = (oldStatus: number | null) => {
    if (oldStatus === null) {
      return 0;
    }
    if (oldStatus >= 0 && oldStatus < 4) {
      return oldStatus + 1;
    }
    return null;
  }

  const onSaleChanged = (on_sale: boolean) => {
    if (updateAssignmentData) {
      if (on_sale) {
        setUpdateAssignmentData({
          ...updateAssignmentData,
          on_sale_type: 2,
          issue_type: null,
          on_sale,
          on_sale_date: null
        });
      } else {
        setUpdateAssignmentData({
          ...updateAssignmentData,
          on_sale_type: 1, on_sale
        });
        setIfShowOnSaleDate(on_sale);
      }
      setIfIssueTypesDisabled(on_sale);
      setIfShowOnSaleTypes(on_sale);
    }
  }

  const onChanged = (value: any, type: string) => {
    if (updateAssignmentData) {
      setUpdateAssignmentData({
        ...updateAssignmentData,
        [type]: value
      });
    }
  }

  const onChangedPriority = (value: boolean) => {
    if (updateAssignmentData) {
      setUpdateAssignmentData({
        ...updateAssignmentData,
        priority: value
      })
    }
  }

  const onSaleTypeChanged = (on_sale_type: number) => {
    setIfShowOnSaleDate(on_sale_type === 1);
    if (on_sale_type === 2) {
      updateAssignmentData &&
        setUpdateAssignmentData({
          ...updateAssignmentData,
          on_sale_type,
          on_sale_date: null
        });
    } else {
      updateAssignmentData &&
        setUpdateAssignmentData({
          ...updateAssignmentData,
          on_sale_type
        });
    }
  }

  const onChangedNotes = (notes: string) => {
    updateAssignmentData &&
      setUpdateAssignmentData({
        ...updateAssignmentData,
        notes
      });
  }

  const setInitialPriority = (bd: any, zd: any) => {
    return (bd.days_to_event < 15 ||
      bd.quantity >= 6 ||
      zd && (
        zd[0].zone_availability_id === 2 ||
        zd[0].zone_availability_id === 3
      )
    );
  }

  const getSwimlaneName = (onSale: boolean, issue_type: string | null) => {
    if (onSale) {
      return 3;
    }
    if (!onSale && issue_type) {
      return 4;
    }
    return 1;
  }

  const initialAssignmentData = (zoneData: any) => {
    return {
      buyer_name: null,
      priority: setInitialPriority(
        currentPurchaseAssignmentData.data.data,
        zoneData
      ),
      issue_type: null,
      notes: null,
      on_sale: false,
      on_sale_type: null,
      on_sale_date: null,
    }
  }

  // const createMetadataBody = () => {
  //   const newEventMetadata:IEventMetadata = {
  //     ...eventMetadata,
  //     stock_type_id: eventMetadata.stock_type_id ?? 1,
  //     total_idle_time_duration: 0,
  //     total_time_duration: 0
  //   }
  //   const notesMsg = {
  //     create: "Created from Purchase Assignment",
  //     update: "Updated from Purchase Assignment"
  //   }
  //   if (zoneAvailabilityData && !zoneAvailabilityData[0].zone_id) {
  //     if (eventMetadata.zone.length > 0) {
  //       // check row-section from event metadata
  //       const filterByZoneSection = eventMetadata.zone
  //         .filter((elem: any) => elem.rows == baseData?.row && elem.zone_section === baseData?.section);
  //       // if matched, save locally the zone_id and update availability for the zone_id
  //       if (filterByZoneSection.length > 0) {
  //         return {
  //           ...newEventMetadata,
  //           notes: notesMsg.update,
  //           zone: createMetadataZoneOld(filterByZoneSection[0].id)
  //         }
  //       }
  //       return {
  //         ...newEventMetadata,
  //         notes: notesMsg.create,
  //         zone: newEventMetadata.zone ? newEventMetadata.zone.push(createMetadataZoneNew()) : [createMetadataZoneNew()]
  //       }
  //     }
  //     return {
  //       ...newEventMetadata,
  //       notes: notesMsg.create,
  //       zone: newEventMetadata.zone ? newEventMetadata.zone.push(createMetadataZoneNew()) : [createMetadataZoneNew()]
  //     }
  //   }

  //   return {
  //     ...newEventMetadata,
  //     notes: notesMsg.update,
  //     zone: createMetadataZoneOld((zoneAvailabilityData && zoneAvailabilityData[0].zone_id) ?? 0)
  //   }
  // }

  // const createMetadataZoneNew = () => {
  //   return {
  //     cost: 0,
  //     id: 0,
  //     ranking: 1,
  //     rows: `${baseData?.row}`,
  //     zone_availability: `${zoneAvailabilityData && zoneAvailabilityData[0].zone_availability_status}`,
  //     zone_availability_id: (zoneAvailabilityData && zoneAvailabilityData[0].zone_availability_id) ?? 5,
  //     zone_section: `${baseData?.section}`
  //   };
  // }

  // const createMetadataZoneOld = (zone_id: number) => {
  //   setZoneId(zone_id);
  //   const index = eventMetadata.zone.findIndex((elem: any) => elem.id = zone_id);
  //   return eventMetadata.zone.splice(index, 1, {
  //     ...eventMetadata.zone[index],
  //     zone_availability: `${zoneAvailabilityData && zoneAvailabilityData[0].zone_availability_status}`,
  //     zone_availability_id: zoneAvailabilityData && zoneAvailabilityData[0].zone_availability_id
  //   });
  // }

  return (
    <React.Fragment>
      <div ref={actionRef} style={{ display: "none" }} onClick={actionHandler}>
        Actions
      </div>
      {showPurchaseAssignmentModal && (
        <Modal
          closable={false}
          width={1000}
          title={false}
          footer={false}
          visible={showPurchaseAssignmentModal}
          centered
          className="editModal"
        >
          <div className="modalHeader">
            <div className="modalHeadingMain">Purchase Assignment Form</div>
            <div className="modalHeaderChild eventDt">
              <div className="eventDtLft">
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href={
                    AppSetting.EVENT_URL +
                    baseData?.event_id
                  }
                >
                  {baseData && baseData.event_name.length > 40 ?
                    addToolTip(
                      `${titleCase(baseData?.event_name)}`, "right", "pointer", 37) :
                    `${titleCase(baseData?.event_name)}`}
                </a>
                {Boolean(baseData?.event_date_and_time) &&
                  <span>
                    {` (${formatDateGivenFormatString(
                      baseData?.event_date_and_time,
                      "ddd, MMM DD, YYYY hh:mm A"
                    )})`}
                  </span>
                }
              </div>
              <div>
                {Boolean(baseData && baseData.venue_location) && (
                  <React.Fragment>
                    {(baseData?.venue_name + ", " + baseData?.venue_location).length > 60 ?
                      addToolTip(
                        `${titleCase(baseData?.venue_name)}, ${baseData?.venue_location}`, "right", "default", 57
                      ) :
                      `${titleCase(baseData?.venue_name)}, ${baseData?.venue_location}`
                    }
                  </React.Fragment>
                )}

                {!Boolean(
                  baseData &&
                  baseData.venue_location) &&
                  Boolean(
                    baseData &&
                    baseData.venue_name.trim().toLowerCase() !== 'to be determined') && (
                    <React.Fragment>
                      {baseData && baseData.venue_name.length > 60 ?
                        addToolTip(titleCase(baseData?.venue_name), "right", "pointer", 57) :
                        titleCase(baseData?.venue_name)
                      }
                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>

          <LoadingSpinner isLoading={isLoadingModalData}>
            {dataFetchError && (
              <CustomError {...dataFetchError} />
            )}
            {!dataFetchError && (
              <React.Fragment>
                <div className="purchaseAssignmentTable" style={{ marginBottom: "20px", overflow: "unset" }}>
                  <CommonReportTable
                    data={baseData ? [baseData] : null}
                    headers={purchaseAssignmentDataHeaders}
                    loading={false}
                  />
                </div>
                <Form
                  className="modalCustomBody"
                  name="updateAssignmentData"
                  form={form}
                  onFinish={onFinish}
                  onValuesChange={() => setIsValueChanged(true)}
                  autoComplete="on"
                >
                  <div className="editUserInfoForm">
                    <div className="ant-row">
                      <Col xs={24} sm={24} md={24} xl={14}>

                        {Boolean(zoneAvailabilityData) && (
                          <div className="ant-row">
                            <Col xs={24} sm={24} md={24} xl={24}>
                              <div className="purchaseAssignmentTable purchaseAssignmentStatusTable" style={{ marginBottom: "20px" }}>
                                <CommonReportTable
                                  data={zoneAvailabilityData}
                                  headers={purchaseAssignmentStatusHeaders}
                                  loading={false}
                                />
                              </div>
                            </Col>
                          </div>
                        )}

                        <div className="ant-row">
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <label>
                              Assigned to <span className="req">*</span>
                            </label>
                            <div className="inputGroup removeErrorDiv">
                              <Form.Item
                                name="buyer_name"
                                rules={[
                                  { required: true, message: "", type: "number" },
                                ]}
                                wrapperCol={{ xl: { span: 24 } }}
                              >
                                <Select
                                  placeholder="Select"
                                  allowClear={false}
                                  options={buyerMaster}
                                  value={updateAssignmentData?.buyer_name}
                                  showSearch
                                  filterOption={true}
                                  optionFilterProp="label"
                                  onChange={(value) => onChanged(value, "buyer_name")}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col xs={24} sm={24} md={12} xl={12}>
                            <label>
                              Priority <span className="req">*</span>
                            </label>
                            <div className="inputGroup">
                              <div className="swithWithLabel">
                                <Form.Item                                
                                  name="priority"
                                  wrapperCol={{ xl: { span: 24 } }}
                                  valuePropName={updateAssignmentData?.priority ? "checked" : "unchecked"}
                                  shouldUpdate={(prev, cur) => 
                                    prev.priority !== cur.priority
                                  }
                                >
                                  <div className="normalHighToggle flx alignItemsCenter">
                                  <span>Normal</span>
                                  <Switch
                                  className="yesNo"
                                    checked={updateAssignmentData?.priority}
                                    onClick={() => onChangedPriority(!updateAssignmentData?.priority)}
                                  />
                                  <span>High</span>
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                          </Col>

                          <Col xs={24} sm={24} md={12} xl={12}>
                            {onSaleMaster && onSaleMaster.length > 0 &&
                              <React.Fragment>
                                <label>On Sale</label>
                                <div className="inputGroup" style={{ marginTop: "15px" }}>
                                  <Form.Item
                                    name="on_sale"
                                    wrapperCol={{ xl: { span: 24 } }}
                                    valuePropName={updateAssignmentData?.on_sale ? "checked" : "unchecked"}
                                    shouldUpdate={(prev, cur) =>
                                      prev.on_sale !== cur.on_sale
                                    }
                                  >
                                    <Switch
                                      className="yesNo"
                                      onChange={(value) => onSaleChanged(value)}
                                    />
                                  </Form.Item>
                                </div>
                              </React.Fragment>
                            }

                            {ifShowOnSaleTypes && (
                              <div className="inputGroup removeErrorDiv">
                                <Form.Item
                                  name="on_sale_type"
                                  rules={[
                                    { required: false, message: "", type: "number" },
                                  ]}
                                  wrapperCol={{ xl: { span: 24 } }}
                                >
                                  <Select
                                    placeholder="Select"
                                    allowClear={false}
                                    options={onSaleTypeMaster}
                                    value={updateAssignmentData?.on_sale_type}
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label"
                                    onChange={(value) => onSaleTypeChanged(value)}
                                  />
                                </Form.Item>
                              </div>
                            )}

                            {ifShowOnSaleTypes && ifShowOnSaleDate && (
                              <div className="inputGroup removeErrorDiv">
                                <Form.Item
                                  name={"on_sale_date"}
                                  rules={[{ required: true, message: "" }]}
                                  wrapperCol={{ xl: { span: 24 } }}
                                >
                                  <DatePicker
                                    allowClear={false}
                                    className={'ant-picker-white'}
                                    disabledDate={(current) =>
                                      current < moment().subtract(1, 'days') ||
                                      current > moment(baseData?.event_date_and_time)
                                    }
                                    showToday={false}
                                    onChange={(value) => onChanged(value, "on_sale_date")}
                                  />
                                </Form.Item>
                              </div>
                            )}
                          </Col>

                          <Col xs={24} sm={24} md={12} xl={12}>
                            <label>Issues</label>
                            <div className="inputGroup removeErrorDiv">
                              <Form.Item
                                name="issue_type"
                                rules={[
                                  { required: false, message: "", type: "number" },
                                ]}
                                wrapperCol={{ xl: { span: 24 } }}
                              >
                                <Select
                                  placeholder="Select"
                                  allowClear={false}
                                  options={issuesTypeMaster}
                                  value={updateAssignmentData?.issue_type}
                                  showSearch
                                  filterOption={true}
                                  optionFilterProp="label"
                                  onChange={(value) => onChanged(value, "issue_type")}
                                  disabled={ifIssueTypesDisabled}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </div>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={10}>
                        <div className="textAreaWrap pa-textArea">
                          <label>Notes</label>
                          <div className="inputGroup ">
                            <Form.Item
                              name="notes"
                              noStyle={true}
                              rules={[{ message: "", type: "string" }]}
                            >
                              <TextArea
                                //autoSize={{ minRows: 6, maxRows: 6 }}
                                maxLength={1000}
                                showCount={{
                                  formatter: ({ count, maxLength }) =>
                                    maxLength ?
                                      `${formatThousandSeparator((maxLength ?? 0) - count)} Remaining` :
                                      "",
                                }}
                                value={updateAssignmentData?.notes ?? ""}
                                allowClear
                                onChange={(data) => onChangedNotes(data.target.value)}
                                className="noteTxtArea"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      {baseData?.venue_url &&
                        <Col xs={24} sm={24} md={24} xl={24}>
                          <div className="primaryWebAddress">
                            <a className="linkColor2"
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={baseData?.venue_url ?? ''}
                            >
                              Primary website address
                            </a>
                          </div>
                        </Col>
                      }
                      
                    </div>
                  </div>
                  <div className="modalFooterStyle2 purchaseAvailabilityModal">
                    <Form.Item>
                      <Button className="linkBtn" onClick={cancelHandler}>
                        CANCEL
                      </Button>
                      <Button className="ant-btn btnOk" htmlType="submit">
                        SAVE
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </React.Fragment>
            )}
          </LoadingSpinner>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default PurchaseAssignmentModal;
