import { Button, message, Select } from "antd";
import {
  IMenuOption,
  IPurchaseQueueMasterData,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import React, { useState } from "react";
import { PurchaseAndShippingService } from "services";

const ChangePriority: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  swimlaneData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster,
}) => {
  const [priorityData, setPriorityData] =
    useState({
      invoice_id: eachInvoiceData.invoice_id,
      priority: eachInvoiceData.is_high_priority ? '1' : '2',
    });
  const [isValueChanged, setIsValueChanged] = useState(false);
  const priority_id = eachInvoiceData.is_high_priority ? '1' : '2';
  
  const cancelHandler = () => {
    setIsValueChanged(false);
    setSelectedMenuId(null);
    setPriorityData({
      invoice_id: eachInvoiceData.invoice_id,
      priority: eachInvoiceData.is_high_priority ? '1' : '2',
    });
  };

  const updatePriority = async () => {
    const changePriorityKey = "changePriorityKey";
    const oldPriority = eachInvoiceData.is_high_priority ? "High" : "Normal";
    const newPriority = priorityData.priority === '1' ? "High" : "Normal";
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Updating Invoice priority from ${
        oldPriority
      } to ${
        newPriority
      }...`,
      duration: 0,
      key: changePriorityKey,
      className: "toastMsg loadingMsg",
    });
    const _priorityData = {...priorityData, priority: parseInt(priorityData.priority)};
    const res = await new PurchaseAndShippingService().changePriority(
      _priorityData
    );
    if (res.data.data) {
      message.success({
        content: `Invoice# ${eachInvoiceData.invoice_id} priority updated from ${oldPriority} to ${newPriority}.`,
        duration: 5,
        key: changePriorityKey,
        className: "toastMsg savedSuccess",
      });
      setIsValueChanged(false);
    } else {
      message.error({
        content: "Failed to update invoice priority.",
        duration: 5,
        key: changePriorityKey,
        className: "toastMsg savedFailed",
      });
    }
    requestToReloadTheLanes([swimlaneData.id]);
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <Select
          options={purchaseQueueMaster?.priorityMasterData.map((each)=>({...each, value: each.value.toString()}))}
          getPopupContainer={(trigger) =>
            trigger.parentElement as HTMLInputElement
          }
          allowClear={false}
          value={priorityData.priority}
          showSearch
          filterOption={true}
          optionFilterProp="label"
          onChange={(value) => {
            if (priority_id !== value) {
              setIsValueChanged(true);
            } else {
              setIsValueChanged(false);
            }
            setPriorityData((prev) => ({ ...prev, priority: value }));
          }}
        />
        <div className="moveOnSaleRow btnGroupFooter">
          <Button className="discardBtn" onClick={cancelHandler}>
            <span>CANCEL</span>
          </Button>
          <Button
            className="btnUpdate"
            disabled={!isValueChanged}
            onClick={updatePriority}
          >
            Update
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePriority;
