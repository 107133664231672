import React from "react";
import { Row, Col, Layout, Pagination } from "antd";
import { AppSetting } from "utils/appSettings";
import CustomError from "controls/CustomError/CustomError";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  setQueryParams,
  eventListData,
  queryParams,
  hasError,
  dataFetchError,
  isLoadingEvents,
} from "models/Slices/EventManagementSlice";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import EventsListView from "./EventsListView";
import EventsGridView from "./EventsGridView";
import { isMobile } from "react-device-detect";
import { formatReportCount, formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const EventList: React.FC<{
  appliedFilterValues: any | null;
  getEventDetails: Function;
  viewMode: "gridView" | "listView";
}> = ({ appliedFilterValues, getEventDetails, viewMode }) => {
  const dispatch = useAppDispatch();
  const showLoader = useAppSelector(isLoadingEvents);
  const eventListDetails = useAppSelector(eventListData);
  const eventQueryParams = useAppSelector(queryParams);
  const isError = useAppSelector(hasError);
  const errorObj = useAppSelector(dataFetchError);

  const handlePageChange = (page: number) => {
    dispatch(
      setQueryParams({
        page_index: page,
      })
    );
    getEventDetails();
  }

  return (
    <React.Fragment>
      {isError ? (
        <CustomError {...errorObj} />
      ) : (
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row className="flx justifyContentBetween alignItemsCenter">
              <Col xs={24} sm={24} md={24} xl={14} xxl={16} className="appliedFilters">
                <AppliedFilters appliedFilters={appliedFilterValues} />
              </Col>
              <Col xs={24} sm={24} md={24} xl={10} xxl={8}>
                {!showLoader && eventListDetails && eventListDetails.total > 0 && (
                  <ul className="innerHeaderRight pl0">
                    <li>
                      <h4>
                        {formatThousandSeparator(eventListDetails.total)}
                        {formatReportCount(eventListDetails.total)}
                      </h4>
                    </li>
                    <li>
                      {!isMobile && (
                        <Pagination
                          className="pagination"
                          hideOnSinglePage={true}
                          defaultCurrent={1}
                          current={eventQueryParams.page_index}
                          total={eventListDetails.total}
                          pageSize={PAGE_SIZE}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                        />
                      )}
                      {isMobile && (
                        <Pagination
                          simple
                          size="small"
                          hideOnSinglePage={true}
                          defaultCurrent={1}
                          current={eventQueryParams.page_index}
                          total={eventListDetails.total}
                          pageSize={PAGE_SIZE}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                        />
                      )}
                    </li>
                  </ul>
                )}
                {!(!showLoader && eventListDetails && eventListDetails.total > 0) && (
                  <div style={{ height: 21 }}></div>
                )}
              </Col>
            </Row>
          </div>
          <div className="removeTblLRSpc">
            {viewMode === "listView" && <EventsListView />}
            {viewMode === "gridView" && <EventsGridView />}
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default EventList;
