import React, { useEffect, useState } from 'react';
import { Button, Col, Tooltip } from "antd";
import {
  IFilterOptions,
  IDashboardAlertsQueryParams,
  IDashboardCommonDatum
} from "models/interfaces";
import { LoggerService } from "sharedServices";
import { ListerDashboardService } from "services";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { Link } from 'react-router-dom';
import { getEncodedData } from 'utils/commonFunction';
import { isMobile } from 'react-device-detect';
import { RBAC } from 'features/rbac/rbac';
import { ERbacPermissions } from 'features/rbac/rbacPermissionsList';
import {
  formatDate,
  formatEventName,
  defaultParams,
  viewAllCTA
} from "views/Dashboard/CommonComponents/CommonFile/commonFile";

const NewShowsCommon: React.FC<{
  filterObject: IFilterOptions | null;
  isRefreshed: boolean;
  setIsRefreshed: Function;
  newShowsOnly: boolean;
  ifNoListing: boolean;
}> = ({
  filterObject,
  isRefreshed,
  setIsRefreshed,
  newShowsOnly,
  ifNoListing
}) => {
    const [queryParams, setQueryParams] = useState<IDashboardAlertsQueryParams | null>(null);
    const [newShowsData, setNewShowsData] = useState<IDashboardCommonDatum[] | null>(null);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);
    const [ifLengthExceeds, setIfLengthExceeds] = useState<boolean>(false);

    const [defaultQueryParams] = useState<IDashboardAlertsQueryParams>({
      ...defaultParams,
      new_shows_only: newShowsOnly,
      if_no_listing: ifNoListing
    });

    const getNewShowsData = async (query: IDashboardAlertsQueryParams) => {
      try {
        setShowLoader(true);

        const response = await new ListerDashboardService().getNewShowsNoListingDataList({
          ...query,
        });

        const data = response.data.data;
        setIfLengthExceeds(data.if_length_exceeds);
        setNewShowsData(data.eventsData && data.eventsData.length > 0 ? [...data.eventsData] : null);

        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);
        await new LoggerService().log({
          payload: error,
          function_name: "getNewShowsData",
        });

        setDataFetchError({ ...error, refreshAction: getNewShowsData });
      }
    };

    useEffect(() => {
      queryParams && getNewShowsData(queryParams);
    }, [queryParams]);

    useEffect(() => {
      if (isRefreshed && queryParams) {
        setIsRefreshed(false);
        getNewShowsData(queryParams);
      }
    }, [isRefreshed])

    useEffect(() => {
      if (filterObject !== null && defaultQueryParams) {
        setQueryParams({
          ...defaultQueryParams,
          ...filterObject
        });
      }
    }, [defaultQueryParams, filterObject]);

    return (
      <Col className="ant-col-xs-24 ant-col-lg-24">
        <div className="dashboardCard2">
          <div className="dashboardCardHeader text-danger">
            <span>Create New Listings</span>
            {ifLengthExceeds && (
              viewAllCTA(
                "TRADING_EVENTS",
                { ...filterObject, show_category_id: 2, exclude_sold_out_events: true, exclude_cancelled_events: true },
                { color: "#4171B9", fontSize: 14, marginLeft: 10 },
                "View All"
              ))}
          </div>
          <div className="dashboardCardBody listerSeatAvailability dashboardMidWrap">
            <LoadingSpinner isLoading={showLoader}>
              {!showLoader && (
                <React.Fragment>
                  {dataFetchError && (
                    <CustomError {...dataFetchError} />
                  )}
                  {!dataFetchError && (
                    <React.Fragment>
                      {Boolean(newShowsData) && newShowsData && (
                        <React.Fragment>
                          <ul>
                            {newShowsData.map((elem: IDashboardCommonDatum) =>
                              <li key={elem.event_id}>
                                <p className="dashboardCTAWrapper">
                                  {!isMobile ? (
                                    <span className="text-capitalize dashboardCTALabel">
                                      {elem.event_name}, {formatDate(elem.event_date)}
                                    </span>
                                  ) : (
                                    <React.Fragment>
                                      <span className="text-capitalize dashboardCTALabel">
                                        {formatEventName(elem.event_name, 15)}, {formatDate(elem.event_date)}
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <RBAC allowedPermissions={[ERbacPermissions.EVENT_CREATE_NEW_LISTING]}>
                                    {elem.is_broker_available && elem.event_id > 0 && (
                                      <Tooltip
                                        title="Create New Listing"
                                        placement="bottomLeft"
                                      >
                                        <Link
                                          to={`${elem.has_seat_structure
                                            ? LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING
                                            : LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING_WITHOUT_METADATA
                                            }?event=${getEncodedData({
                                              event_id: elem.event_id,
                                            })}`}
                                          target={"_blank"}
                                        >
                                          <Button className="createListing createListingActive">
                                            <i className="da icon-add-layer " />
                                          </Button>
                                        </Link>
                                      </Tooltip>
                                    )}
                                    {!(elem.is_broker_available && elem.event_id > 0) && (
                                      <Tooltip
                                        title={elem.event_id > 0 ? "Venue must be associated with a broker" : "Not on POS"}
                                        placement="bottomLeft"
                                      >
                                        <Button className="createListing" disabled>
                                          <i className="da icon-add-layer" />
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </RBAC>
                                </p>
                              </li>
                            )}
                            {ifLengthExceeds && (
                              <div>
                                {viewAllCTA(
                                  "TRADING_EVENTS",
                                  { ...filterObject, show_category_id: 2, exclude_sold_out_events: true, exclude_cancelled_events: true },
                                  { color: "#4171B9", fontSize: 14 },
                                  "View All"
                                )}
                              </div>
                            )}
                          </ul>
                        </React.Fragment>
                      )}
                      {!Boolean(newShowsData) && (
                        <div className="text-primary">No Data Available</div>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </LoadingSpinner>
          </div>
        </div>
      </Col>
    )
  }

export default NewShowsCommon;
