import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Menu, Breadcrumb, Dropdown } from "antd";
import Logo from "assets/images/logo.svg";
import React from "react";
import { isHamMenuVisible, toggleHamMenu } from "models/Slices/GlobalSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
const { Header } = Layout;

export const AppHeader: React.FC<{ headerTitle?: string, breadcrumbTitle?: string, breadcrumbSubtitle?: string }> = (props) => {
  const { logout, user } = useAuth0();
  const hamMenuActive = useAppSelector(isHamMenuVisible);
  const dispatch = useAppDispatch();

  const userDropdown = (
    <Menu className="userAccountDd">
      {/* <Menu.Item>{user?.name}</Menu.Item> */}
      <Menu.Item
        key={"logout"}
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  let appHeaderTitle =
    props.headerTitle ? (
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item href="">
          <span>{props.breadcrumbTitle}</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{props.breadcrumbSubtitle}</Breadcrumb.Item>
      </Breadcrumb>
    ) : (
      <h3>{props.headerTitle}</h3>
    );

  return (
    <React.Fragment>
    <Header className="header">
      <div className="headerLeft">
        <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
          <img src={Logo} alt="" title="Ticket Bash" />
        </a>

        {appHeaderTitle}
      </div>
      <ul className="headerRight">
        {/* <li>
          <div>
            <i className="da icon-bell" />
          </div>
        </li>
        <li>
          <div>
            <i className="da icon-question" />
          </div>
        </li>
        <li>
          <div>
            <i className="da icon-user" />
          </div>
        </li> */}
        <li className="username">
          <Dropdown overlay={userDropdown} placement={'bottomRight'}>
            <a href="#/" className="dropdown-btn" onClick={(e) => e.preventDefault()}>
              {user?.nickname} <i className="da icon-arrow-down" />
            </a>
          </Dropdown>
        </li>
      </ul>
    </Header>
    <div className="dashboardLogoArea">
    <span  className={`hamburgerMenu ${hamMenuActive ? 'active' : ''}`}
    onClick={() => { dispatch(toggleHamMenu(hamMenuActive));
}}><i className="da icon-hamburger" /></span>
    <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
      <img src={Logo} alt="" title="Ticket Bash" />
    </a>              
  </div>
  </React.Fragment>
  );
};

export default AppHeader;
