import React from "react";
import PendingFillsAlerts from "./PendingFillsAlerts/PendingFillsAlerts";
import PurchaseQueue from "./PurchaseQueue/PurchaseQueue";
import PurchaseShippingDepartment from "./PurchaseShippingDepartment/PurchaseShippingDepartment";
import ShippingQueue from "./ShippingQueue/ShippingQueue";

const PurchaseShipping: React.FC = () => {

  return (
    <React.Fragment>
      <PendingFillsAlerts />
      <PurchaseQueue />
      <ShippingQueue />
      <PurchaseShippingDepartment />
    </React.Fragment>
  );
};

export default PurchaseShipping;
