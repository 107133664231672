import React, { useEffect } from "react";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { useHistory } from "react-router-dom";

const CommonRoute: React.FC = () => {
  const history = useHistory();

  const { hasPermissions } = useRbac();

  const handleRoute = () => {
    const inventoryRoutes = [
      LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE,
      LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_SOLD,
      LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_UNFILLED,
      LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED,
    ];

    if (
      hasPermissions([
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
      ])
    ) {
      history.push(inventoryRoutes[0]);
    } else if (
      hasPermissions([
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM,
      ])
    ) {
      history.push(inventoryRoutes[1]);
    } else if (
      hasPermissions([
        ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_OWN,
        ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
      ])
    ) {
      history.push(inventoryRoutes[2]);
    } else if (
      hasPermissions([
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_ALL,
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_TEAM,
        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_OWN,
      ])
    ) {
      //Add Permissions for Cancelled Inventory
      history.push(inventoryRoutes[3]);
    }
  };

  useEffect(() => {
    handleRoute();
  }, []);

  return <></>;
};

export default CommonRoute;
