import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import { EventService } from "services";
import {
  CommonTableHeader,
  IEventMetadata,
  IListElement,
  IListingFormData,
  IMarketplace,
} from "models/interfaces";
import { formatDateGivenFormatString, getCurrentDate, getCustomDate, getCustomTimeFromDate, getDateDiff } from "utils/formatTimePeriod";
import {
  getShowTitleRender,
  titleCase,
  twoDecimalPlacesNumber,
} from "utils/commonFunction";
// import {
//   competitorPricesActions,
//   competitorPricesList,
// } from "models/Slices/CompetitorPricesSlice";
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CurrencyCode } from "models/enums";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import CommonReportTable from "utils/table/CommonReportTable";
import CreateListingModal from "./CreateListingModal";
import { LoggerService, trigger } from "sharedServices";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { Unauthorized } from "routes/Unauthorized";
import FinalizeListingModal from "./FinalizeListingModal";
import { AppSetting } from "utils/appSettings";
import InlineEdit from "utils/table/InlineEdit";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppDispatch, useAppSelector } from "app/hooks";
import CompetitiveModalMain from "./CompetitiveModal/CompetitiveModalMain";

const CreateNewZoneListingTable: React.FC<{
  requestedEventId: number;
  zones: IListingFormData[];
  setZones: Function;
  eventMetadata: IEventMetadata | null;
  setEventMetadata: Function;
  isLoadingData: boolean;
  setIsLoadingData: Function;
}> = ({
  requestedEventId,
  zones,
  setZones,
  eventMetadata,
  setEventMetadata,
  isLoadingData,
  setIsLoadingData,
}) => {
  const dispatch = useAppDispatch();
  // const competitorPricesListData = useAppSelector(competitorPricesList);

  const [isCreatingNewListing, setIsCreatingNewListing] = useState(false);
  const [isLoadingModalData, setIsLoadingModalData] = useState(false);
  const [stockTypesMaster, setStockTypesMaster] = useState<IListElement[]>([]);
  const [zoneListingFetchError, setZoneListingFetchError] =
    useState<ICustomError | null>(null);
  const [showFinalizeListingModal, setShowFinalizeListingModal] =
    useState(false);
  const [maxTicketsAllowed, setMaxTicketsAllowed] = useState<number>(8);
  const [listingFormData, setListingFormData] = useState<IListingFormData>();
  const [brokerTags, setBrokerTags] = useState<IListElement[]>([]);
  const [listerTags, setListerTags] = useState<IListElement[]>([]);
  const [vendors, setVendors] = useState<IListElement[]>([]);
  const [defaultVendor, setDefaultVendor] = useState({});
  const [defaultInternalNotes, setDefaultInternalNotes] = useState('');
  const [applyToAll, setApplyToAll] = useState(false);
  const [currentZoneId, setCurrentZoneId] = useState<number | null>(null);
  const [showApplyToAll, setShowApplyToAll] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const changeZoneSelection = (zoneId: number) => {
    const currentZones = [...zones];
    const findZone = currentZones.find((eachZone) => {
      return eachZone.id === zoneId;
    });

    if (findZone && findZone?.is_selected !== undefined) {
      findZone.is_selected = !findZone.is_selected;
    }

    const numOfChecked = currentZones.reduce((n: number, zone: any) => {
      return n + zone.is_selected;
    }, 0);
    setShowApplyToAll(numOfChecked > 1 ? true : false);
    checkIfAllSelected();
    setZones(currentZones);
  };

  const getSortedZones = () => {
    let selectedSortedData = zones
      .filter((eachZone) => eachZone.is_selected)
      .sort(function (a: any, b: any) {
        return a.ranking - b.ranking;
      });

    let notSelectedSortedData = zones
      .filter((eachZone) => !eachZone.is_selected)
      .sort(function (a: any, b: any) {
        return a.ranking - b.ranking;
      });
    return [...selectedSortedData, ...notSelectedSortedData];
  };

  const setTableIndices = (
    sortedZones: IListingFormData[],
    findZone: IListingFormData,
    requiredFields: string
  ) => {
    const zoneIndex = sortedZones.indexOf(findZone);
    if (sortedZones[zoneIndex + 1]) {
      const nextZone = sortedZones[zoneIndex + 1];
      if (nextZone[requiredFields]) {
        setCurrentTabIndex(requiredFields + "_" + nextZone.id);
      } else if (nextZone.id) {
        setNextTabIndex(nextZone.id, "listing_rows");
      }
    }
  };

  const [currentTabIndex, setCurrentTabIndex] = useState<string | null>(null);
  const setNextTabIndex = (zoneId: number, fieldName: string) => {
    const requiredFields = [
      "listing_quantity",
      "listing_price",
      "listing_rows",
    ];
    let nextIndexTabIndex: string | null = null;
    if (zoneId) {
      const requiredFieldIndex = requiredFields.indexOf(fieldName);
      const _getSortedZones = [...getSortedZones()];
      let findZone = _getSortedZones.find((zone) => zone.id === zoneId);
      if (findZone) {
        if (requiredFieldIndex >= 0) {
          if (
            requiredFields[requiredFieldIndex + 1] &&
            findZone[requiredFields[requiredFieldIndex + 1]] !== undefined
          ) {
            nextIndexTabIndex =
              requiredFields[requiredFieldIndex + 1] + "_" + zoneId;
            setCurrentTabIndex(nextIndexTabIndex);
          } else {
            setTableIndices(_getSortedZones, findZone, requiredFields[0]);
          }
        }
      }
    }
  };

  const handleIsOnEditing = (isEditing: boolean, zoneId: number) => {
    const _zones = [...zones];
    let findZone = _zones.find((zone) => zone.id === zoneId);
    if (findZone) {
      if (!findZone.on_editing_field_count) {
        findZone.on_editing_field_count = 0;
      }
      if (isEditing) {
        findZone.on_editing_field_count = findZone.on_editing_field_count + 1;
      } else if (findZone.on_editing_field_count > 0) {
        findZone.on_editing_field_count = findZone.on_editing_field_count - 1;
      }
      setZones(_zones);
    }
  };

  const isAnyFieldEditing = () => {
    if (
      zones.find(
        (zone) => zone.on_editing_field_count && zone.on_editing_field_count > 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getListingQuantityValidator = (
    value: any,
    findZone: IListingFormData | undefined
  ) => {
    let isSeatTypePiggyback = false;
    if (findZone) {
      if (
        findZone.seat_type &&
        findZone.seat_type.toUpperCase() === "PIGGYBACK"
      ) {
        isSeatTypePiggyback = true;
      }
    }

    if (isSeatTypePiggyback) {
      if (value % 2 === 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  const zoneHeaders:CommonTableHeader[] = [
    {
      title: "",
      thClassName: "thCheck",
      dataIndex: "id",
      tooltipText: "Select All",
      dataRender: (_data: number, otherData: any) => (
        <React.Fragment>
          {[1, 2].includes(otherData.data.singleData.zone_availability_id) && (
            <Checkbox
              checked={otherData.data.singleData.is_selected}
              onChange={() => {
                changeZoneSelection(otherData.data.singleData.id);
              }}
              disabled={isAnyFieldEditing()}
            ></Checkbox>
          )}
        </React.Fragment>
      ),
      titleRender: getShowTitleRender(zones)
        ? () => (
            <React.Fragment>
              <Checkbox
                checked={isSelectedAll}
                onChange={(e) => {
                  selectAllZone(e);
                }}
              ></Checkbox>
            </React.Fragment>
          )
        : undefined,
    },
    {
      title: "ZONE / SECTION",
      dataIndex: "zone_section",
      dataRender: (data: string) => (
        <strong>{data ? data.toUpperCase() : ""}</strong>
      ),
    },
    {
      title: "ROW",
      dataIndex: "rows",
    },
    {
      title: <div>COST{Boolean(eventMetadata?.venue_fees) &&<Tooltip title="Venue Fees Included"><i className="da icon-venue-fees" style={{ marginLeft: '5px'}}/></Tooltip>}</div>,
      dataIndex: "cost",
      thClassName: "text-right",
      tdClassName: "costCol",
      dataRender: (cost: number, data: any ) => {
        if(cost !== null && cost !== undefined && cost !== 0 ) {
          return <div className="text-right">
          {FormatCurrencyUnit(twoDecimalPlacesNumber(cost+parseFloat(data?.otherData?.venue_fees??0)), CurrencyCode.Usd)}
        </div>
        }
        return <div className="text-right">
          {FormatCurrencyUnit(twoDecimalPlacesNumber(cost), CurrencyCode.Usd)}
        </div>
      },
    },
    {
      title: "AVAILABILITY",
      dataIndex: "zone_availability_id",
      thClassName: "availabilityTh",
      tdClassName: "availabilityCol text-center",
      dataRender: (zone_availability_id: number, otherData: any) => {
        let iconClassName = "";
        let outerClassName = "availabilityGraphArea noAvailable none";
        if (zone_availability_id === 1) {
          outerClassName = "availabilityGraphArea successAvailability";
          iconClassName = "icon-chart-up-green";
        } else if (zone_availability_id === 2) {
          outerClassName = "availabilityGraphArea warningAvailability";
          iconClassName = "icon-chart-up-yellow";
        } else if (zone_availability_id === 3) {
          outerClassName = "availabilityGraphArea dangerAvailability";
          iconClassName = "icon-chart-up-red";
        } else if (zone_availability_id === 4) {
          outerClassName = "availabilityGraphArea tbd";
        } else if (zone_availability_id === 5) {
          outerClassName = "availabilityGraphArea null";
        }
        return (
          <div className={outerClassName}>
            <span>
              {otherData.data.singleData.is_web_scraping === 1 && otherData.data.singleData.available_seats && otherData.data.singleData.total_seats
                ? `${otherData.data.singleData.available_seats} / ${otherData.data.singleData.total_seats}`
                : otherData.data.singleData.zone_availability}
            </span>
            {zone_availability_id > 0 && (
              <i className={"da " + iconClassName}></i>
            )}
          </div>
        );
      },
    },
    {
      title: "QUANTITY",
      dataIndex: "listing_quantity",
      tdClassName: "text-right newListingQtyTd",
      thClassName: "text-right newListingQtyCol",
      width: 70,
      dataRender: (listing_quantity: number, otherData: any) => (
        <div>
          <InlineEdit
            defaultValue={listing_quantity}
            inputType="number"
            onFinish={(value: any) => {
              const _zones = [...zones];
              let findZone = _zones.find(
                (zone) => zone.id === otherData.data.singleData.id
              );
              if (findZone) {
                findZone.listing_quantity = value.filedValue
                  ? value.filedValue
                  : findZone.listing_quantity;
              }
              setCurrentTabIndex(null);
              setZones(_zones);
              if (value.isTabEnter === "yes") {
                setNextTabIndex(
                  otherData.data.singleData.id,
                  "listing_quantity"
                );
              }
            }}
            inputNumberProps={{
              min: 1,
              max: maxTicketsAllowed,
              precision: 0,
            }}
            uniqueTabIndex={"listing_quantity_" + otherData.data.singleData.id}
            currentTabIndex={currentTabIndex}
            onEditing={(isEditing: boolean) => {
              handleIsOnEditing(isEditing, otherData.data.singleData.id);
            }}
            inputValidationRules={[
              { required: true, message: "", type: "number" },
              () => ({
                validator(_: any, value: any) {
                  const findZone = zones.find(
                    (zone) => zone.id === otherData.data.singleData.id
                  );

                  if (getListingQuantityValidator(value, findZone)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Value must be even!");
                },
              }),
            ]}
          />
        </div>
      ),
    },
    {
      title: "PRICE",
      dataIndex: "listing_price",
      thClassName: "text-right newListingPriceCol",
      tdClassName: "text-right newListingPriceTd",
      tooltipText: "List Price + Markup Value",
      width: 80,
      dataRender: (listing_price: number, otherData: any) => (
        <div>
          <InlineEdit
            defaultValue={listing_price}
            inputType="number"
            onFinish={(value: any) => {
              const _zones = [...zones];
              let findZone = _zones.find(
                (zone) => zone.id === otherData.data.singleData.id
              );
              if (findZone) {
                findZone.listing_price = value.filedValue
                  ? value.filedValue
                  : findZone.listing_price;
              }
              setCurrentTabIndex(null);
              setZones(_zones);
              if (value.isTabEnter === "yes") {
                setNextTabIndex(otherData.data.singleData.id, "listing_price");
              }
            }}
            inputNumberProps={{
              min: 0,
              precision: 2,
            }}
            formattedRenderValue={FormatCurrencyUnit(
              twoDecimalPlacesNumber(listing_price ?? ""),
              CurrencyCode.Usd
            )}
            uniqueTabIndex={"listing_price_" + otherData.data.singleData.id}
            currentTabIndex={currentTabIndex}
            onEditing={(isEditing: boolean) => {
              handleIsOnEditing(isEditing, otherData.data.singleData.id);
            }}
            inputValidationRules={[
              { required: true, message: "", type: "number" },
              () => ({
                validator(_: any, value: any) {
                  
                  let min_markup_value = zones.find(
                    (zone) => zone.id === otherData.data.singleData.id
                  )?.min_markup_value;
                  
                  if (min_markup_value !== undefined && value < min_markup_value) {
                    return Promise.reject(
                      "Listing Price cannot be less than min list price"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
        </div>
      ),
    },
    // {
    //   title: "VIVID",
    //   dataIndex: "zone_availability_id",
    //   thClassName: "availabilityTh",
    //   tdClassName: "availabilityCol text-center",
    //   dataRender: () => {
    //     return (
    //       <div className="competitiveInner flx flexColumn">
    //         <div className="mb5 text-md">12 listings</div>
    //         <div className="competitiveValue">$100-150</div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "TICKET MASTER",
    //   dataIndex: "zone_availability_id",
    //   thClassName: "availabilityTh",
    //   tdClassName: "availabilityCol text-center",
    //   dataRender: () => {
    //     return (
    //       <div className="competitiveInner flx alignItemsCenter">
    //         <div className="flx flexColumn flx-1">
    //           <div className="mb5 text-md">12 listings</div>
    //           <div className="competitiveValue">$100-150</div>
    //         </div>
    //         <span
    //           onClick={showCompetitiveModal}
    //           className="da icon-search-view icon-md pointer"
    //         ></span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "ROW",
      dataIndex: "listing_rows",
      tdClassName: "text-left newListingRowTd",
      thClassName: "newListingRowCol",
      width: 100,
      dataRender: (listing_rows: string, otherData: any) => (
        <div>
          <InlineEdit
            defaultValue={listing_rows}
            inputType="text"
            onFinish={(value: any) => {
              const _zones = [...zones];
              let findZone = _zones.find(
                (zone) => zone.id === otherData.data.singleData.id
              );
              if (findZone) {
                findZone.listing_rows = value.filedValue
                  ? value.filedValue
                  : findZone.listing_rows;
              }
              setCurrentTabIndex(null);
              setZones(_zones);
              if (value.isTabEnter === "yes") {
                setNextTabIndex(otherData.data.singleData.id, "listing_rows");
              }
            }}
            uniqueTabIndex={"listing_rows_" + otherData.data.singleData.id}
            currentTabIndex={currentTabIndex}
            onEditing={(isEditing: boolean) => {
              handleIsOnEditing(isEditing, otherData.data.singleData.id);
            }}
            inputValidationRules={[
              { required: true, message: "", type: "string" },
              () => ({
                validator(_: any, value: any) {
                  if (!applyToAll) {
                    let zoneAndRow = `${
                      zones.find(
                        (zone) => zone.id === otherData.data.singleData.id
                      )?.zone_section
                    }${value}`.toLowerCase();
                    let zoneAndRows = zones
                      ?.filter(
                        (zone) => zone.id !== otherData.data.singleData.id
                      )
                      ?.map((zone) =>
                        `${zone.zone_section}${zone.listing_rows}`.toLowerCase()
                      );
                    if (zoneAndRows.includes(zoneAndRow)) {
                      return Promise.reject(
                        "Zone and row combination must be unique!"
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
        </div>
      ),
    },
    {
      title: "STOCK TYPE",
      dataIndex: "id",
      dataRender: (id: number) => (
        <div>
          {
            stockTypesMaster.find(
              (stockType) =>
                stockType.value ===
                zones.find((zone) => zone.id === id)?.stock_type_id
            )?.label
          }
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 80,
      dataRender: (id: number, otherData: any) => (
        <RBAC allowedPermissions={[ERbacPermissions.EVENT_CREATE_NEW_LISTING]}>
          <div className="tblActions">
            {otherData.data.singleData.is_selected && (
              <Tooltip title={"Open Listing Form"}>
                <Typography.Link
                  onClick={() => {
                    trigger("zoneListingAction:click", {
                      action: "create",
                      data: {
                        zoneId: id,
                        zoneData: otherData.data.singleData,
                        eventMetadata: eventMetadata,
                      },
                    });
                  }}
                  disabled={isAnyFieldEditing()}
                >
                  <i className="da icon-add-layer-blue"></i>
                </Typography.Link>
              </Tooltip>
            )}
          </div>
        </RBAC>
      ),
    },
  ];

  // const secondaryMarketplaceHeaderRender = (headerData:IMarketplace) =>{
  //   const _competitorPrice = competitorPricesListData.find(each => each.event_id === requestedEventId && each.provider.toLocaleLowerCase() === headerData.name_alias.toLocaleLowerCase());  
  //   return (
  //     <div>
  //       <div>
  //         <a
  //           rel="noopener noreferrer"
  //           href={headerData.secondary_marketplace_url}
  //           style={{ color: 'inherit' }}
  //           target={"_blank"}
  //           >
  //               {headerData.name}
  //           </a>
  //         {(_competitorPrice && _competitorPrice.metadata && _competitorPrice.metadata.length > 0) &&
  //           <span> ({_competitorPrice.metadata.length})</span>
  //         }
  //       </div>
  //       {_competitorPrice &&
  //         <div>
  //           {_competitorPrice.hasError &&
  //             <Tooltip title={_competitorPrice.messege}>
  //               <CloseCircleOutlined style={{color: '#9F2533', fontSize: '11px', lineHeight: 'unset', marginRight: 5}}/>
  //             </Tooltip>
  //           }
  //           {_competitorPrice.updated_on &&
  //             <span>{formatDateGivenFormatString(_competitorPrice.updated_on, AppSetting.EVENT_DATE_FORMAT)} &nbsp;</span>
  //           }
            
  //           {(!_competitorPrice.isLoading && (!_competitorPrice?.updated_on || Boolean(getDateDiff( getCustomDate(new Date(_competitorPrice?.updated_on as string )), getCurrentDate() ))) ) &&

  //             <Tooltip title="Force Refresh">
  //               <span className="da icon-refresh icon-sm pointer" style={{cursor: 'pointer'}} onClick={()=>{
  //                 // fetchCompetitorPrice(eventMetadata?.event_id as number, eventMetadata?.venue_id as number, headerData.name_alias.toLocaleLowerCase(), headerData.secondary_marketplace_url, true)
  //               }}></span>
  //             </Tooltip>
  //           }
  //           {_competitorPrice.isLoading &&
  //             <Spin style={{fontSize: '1px'}} indicator={<LoadingOutlined spin style={{color: '#223A5E', fontSize: '13px', lineHeight: 'unset'}} />} />
  //           }
           
  //         </div>
  //       }
  //     </div>
  //   )
  // }

  // if(eventMetadata?.secondary_marketplace && eventMetadata?.secondary_marketplace.length){
  //   const secondaryMarketplaceFiltered = eventMetadata?.secondary_marketplace?.filter((eachMarket)=>eachMarket.secondary_marketplace_url && eachMarket.secondary_marketplace_url !== '');
  //   if(secondaryMarketplaceFiltered.length){
  //     const marketPlaceZoneHeaders:CommonTableHeader[] = [];
  //     secondaryMarketplaceFiltered.forEach((each, index)=>{
  //       marketPlaceZoneHeaders.push(
  //         {
  //           title: secondaryMarketplaceHeaderRender(each),
  //           dataIndex: "zone_section_alias_dictionary",
  //           thClassName: "availabilityTh",
  //           tdClassName: "competitive",
  //           width: 161,
  //           dataRender: (zone_section_alias_dictionary:string[]) => {

  //             const _competitorPrice = competitorPricesListData.find(eachCp => eachCp.event_id === requestedEventId && eachCp.provider.toLocaleLowerCase() === each.name_alias.toLocaleLowerCase());
  //             let listingsCount = 0;
  //             let listingsCountString = '-';
  //             let highestCost = 0;
  //             let lowestCost = 0;
  //             let costRangeString = '';

  //             if(_competitorPrice && _competitorPrice.metadata.length > 0){
  //               const _competitorPriceFiltered = _competitorPrice.metadata.filter(eachMeatdata => zone_section_alias_dictionary.includes(eachMeatdata.zone_section.toString().toLocaleUpperCase()));
  //               listingsCount = _competitorPriceFiltered.length;

  //               if(_competitorPriceFiltered.length > 0){
  //                 highestCost = _competitorPriceFiltered.reduce((previous, current) => {
  //                   return current.cost > previous.cost ? current : previous;
  //                 }).cost ?? 0;
  
  //                 lowestCost = _competitorPriceFiltered.reduce((previous, current) => {
  //                   return current.cost < previous.cost ? current : previous;
  //                 }).cost ?? 0;
  //               }
  //             }

  //             if(listingsCount > 0){
  //               listingsCountString = listingsCount > 1 ? listingsCount+ ' listings' : listingsCount+ ' listing';
  //               costRangeString = highestCost === lowestCost ? '$'+highestCost : '$'+lowestCost+'-'+highestCost;
  //             }

  //             return (
  //               <div className="competitiveInner flx alignItemsCenter">
  //                 {_competitorPrice &&
  //                   <React.Fragment>
  //                     <div className="flx flexColumn flx-1">
  //                       {/* {_competitorPrice && !_competitorPrice.isLoading && */}
  //                       <React.Fragment>
  //                         <div className={`mb5 text-md ${listingsCountString === '-' && 'text-center'}`}>{listingsCountString}</div>
  //                         {listingsCount > 0 &&
  //                           <div className="competitiveValue">{costRangeString}</div>
  //                         }
  //                       </React.Fragment>
  //                       {/* } */}
  //                     </div>
  //                     {index === secondaryMarketplaceFiltered.length-1 &&
  //                       <CompetitiveModalMain eventId={requestedEventId} secondaryMarketplaceList={eventMetadata?.secondary_marketplace ? eventMetadata?.secondary_marketplace : []} zoneSectionAliasDictionary={zone_section_alias_dictionary} />
  //                     }
  //                   </React.Fragment>
  //                 }
  //               </div>
  //             );
  //           },
  //         },
  //       )
  //     });

  //     zoneHeaders.splice( 7, 0, ...marketPlaceZoneHeaders )
  //   }
  // }

  // const fetchCompetitorPrice = (event_id: number, venue_id: number, site_type: string, seatmap_url: string, force_refresh: boolean) =>{
  //   dispatch(
  //     competitorPricesActions.getCompetitorPrices({
  //       event_id: event_id,
  //       venue_id: venue_id,
  //       site_type: site_type,
  //       seatmap_url: seatmap_url,
  //       force_refresh: force_refresh,
  //       functionName: "getEventCompetitivePrice",
  //     })
  //   );
  // }

  useEffect(() => {
    if (requestedEventId) {
      getEventMetadataForNewZoneListing(requestedEventId);
    }
  }, [requestedEventId]);

  const getEventMetadataForNewZoneListing = async (event_id: number) => {
    setIsLoadingData(true);
    try {
      const response =
        await new EventService().getEventMetadataForCreateNewZoneListing({
          event_id: event_id,
        });

      const _metadata = response.data.data.metadata;
      setEventMetadata(_metadata);

      // if(_metadata.secondary_marketplace && _metadata.secondary_marketplace.length){
      //   _metadata.secondary_marketplace?.filter((eachMarket)=>eachMarket.secondary_marketplace_url && eachMarket.secondary_marketplace_url !== '').forEach(each => {
      //     fetchCompetitorPrice(_metadata.event_id as number, _metadata.venue_id as number, each.name_alias.toLocaleLowerCase(), each.secondary_marketplace_url as string, false);
      //   })
      // }


      if (_metadata.zone) {
        const _zones = _metadata.zone?.map((eachZone) => {
          return {
            ...eachZone,
            is_selected: false,
          };
        });
        setZones(_zones);
      }
      setIsLoadingData(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getEventMetadataForNewZoneListing",
      });
      setZoneListingFetchError({
        ...error,
        refreshAction: getEventMetadataForNewZoneListing,
      });
      setIsLoadingData(false);
    }
  };

  const selectAllZone = (e: CheckboxChangeEvent) => {
    const currentZones = [...zones];
    currentZones.forEach((eachZone: any) => {
      if (
        [1, 2].includes(eachZone.zone_availability_id) &&
        eachZone.is_selected !== e.target.checked
      ) {
        eachZone.is_selected = e.target.checked;
      }
    });
    setZones(currentZones);
    const numOfChecked = currentZones.reduce((n: number, zone: any) => {
      return n + zone.is_selected;
    }, 0);
    setShowApplyToAll(numOfChecked > 1 ? true : false);
    setIsSelectedAll(e.target.checked);
  };

  const checkIfAllSelected = () => {
    let allSelected = true;
    for (const zone of zones) {
      if (
        !zone.is_selected &&
        (zone.zone_availability_id === 1 || zone.zone_availability_id === 2)
      ) {
        allSelected = false;
        break;
      }
    }
    setIsSelectedAll(allSelected);
  };

  return (
    <React.Fragment>
      {zoneListingFetchError ? (
        <React.Fragment>
          {zoneListingFetchError.code.toString() === "401" && <Unauthorized />}
          {zoneListingFetchError.code.toString() !== "401" && (
            <CustomError {...zoneListingFetchError} />
          )}
        </React.Fragment>
      ) : (
        <Layout className="mainInnerLayout">
          {/* <CompetitiveModal
            isModalVisible18={isModalVisible18}
            cancelCompetitiveModal={cancelCompetitiveModal}
            eventMetadata={eventMetadata}
          /> */}
          {eventMetadata && !isLoadingData && (
            <div className="innerHeader">
              <Row className="alignItemsCenter">
                <Col xs={24} sm={24} md={12} xl={6}>
                  <h3>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href={AppSetting.EVENT_URL + eventMetadata.event_id}
                    >
                      {titleCase(eventMetadata.event_name)}
                    </a>
                  </h3>
                </Col>
                <Col xs={24} sm={24} md={12} xl={11}>
                  <span className="dateTimeTxt">
                    {`${getCustomDate(
                      eventMetadata?.event_date,
                      "ddd, MMM D, YYYY"
                    )} at ${getCustomTimeFromDate(
                      eventMetadata?.event_date
                    )}`}{" "}
                  </span>
                  <span className="dateTimeTxt">
                    {titleCase(eventMetadata.venue_name)},{" "}
                    {eventMetadata.venue_location}
                  </span>
                  {
                    eventMetadata?.venue_fees &&
                    <span>
                      <Tooltip title="Fees have been manually added from Edit Venue screen"><i className="da icon-venue-fees" /></Tooltip>
                      <span>Venue Fees: </span>
                      <span>${eventMetadata.venue_fees}</span>
                    </span>
                  }
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  xl={7}
                  className="text-right unsavedDataMessage"
                >
                  {zones.findIndex(
                    (zone) => zone.listing_quantity !== undefined
                  ) > -1 &&
                    !isCreatingNewListing && <h4>You have unsaved data</h4>}
                  <Button
                    onClick={() => setShowFinalizeListingModal(true)}
                    className="btnPrimary btnCreateListing"
                    disabled={
                      zones.findIndex(
                        (zone) =>
                          zone.listing_quantity !== undefined &&
                          zone.is_selected
                      ) <= -1 ||
                      isCreatingNewListing ||
                      isAnyFieldEditing()
                    }
                  >
                    Create Listing
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          <CreateListingModal
            zones={zones}
            setZones={setZones}
            stockTypesMaster={stockTypesMaster}
            setStockTypesMaster={setStockTypesMaster}
            isLoadingModalData={isLoadingModalData}
            setIsLoadingModalData={setIsLoadingModalData}
            listingFormData={listingFormData}
            setListingFormData={setListingFormData}
            setBrokerTags={setBrokerTags}
            setListerTags={setListerTags}
            setVendors={setVendors}
            setDefaultVendor={setDefaultVendor}
            setDefaultInternalNotes={setDefaultInternalNotes}
            applyToAll={applyToAll}
            setApplyToAll={setApplyToAll}
            currentZoneId={currentZoneId}
            setCurrentZoneId={setCurrentZoneId}
            onGetMaxTicketsAllowed={(maxTicketsAllowedValue: string) => {
              setMaxTicketsAllowed(parseInt(maxTicketsAllowedValue));
            }}
            showApplyToAll={showApplyToAll}
            eventMetaData={eventMetadata}
          />
          {showFinalizeListingModal && (
            <FinalizeListingModal
              zones={zones}
              setZones={setZones}
              isLoadingModalData={isLoadingModalData}
              listingFormData={listingFormData}
              setListingFormData={setListingFormData}
              vendors={vendors}
              defaultVendor={defaultVendor}
              defaultInternalNotes={defaultInternalNotes}
              brokerTags={brokerTags}
              listerTags={listerTags}
              showFinalizeListingModal={showFinalizeListingModal}
              setShowFinalizeListingModal={setShowFinalizeListingModal}
              setIsCreatingNewListing={setIsCreatingNewListing}
              requestedEventId={requestedEventId}
              eventMetadata={eventMetadata}
              listingTypeId={
                1
              } /** Listing type 1 = Listing with seat intelligence */
            />
          )}
          <React.Fragment>
            <div className="customTbl createListingTbl">
              <CommonReportTable
                tableClass="customTbl scroll-y fixedHeaderFooter"
                tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                data={getSortedZones()}
                headers={zoneHeaders}
                loading={isLoadingData}
                enableRowSelect={false}
                otherData={eventMetadata}
              />
              {/* {JSON.stringify(competitorPricesListData)} */}
            </div>
          </React.Fragment>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default CreateNewZoneListingTable;
