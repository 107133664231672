import { Tooltip } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { trigger } from "sharedServices/customEvents/events";
import { getRandomValue } from "utils/commonFunction";
import CreateScheduler from "utils/commonScheduler/createScheduler";
import { getNextRunAtTimeFormat } from "utils/formatTimePeriod";
import ForceRunSchedule from "./Actions/ForceRunSchedule";

const commonWidth = 1;

const schedulerListHeaders = [
  {
    title: "",
    dataIndex: "id",
    key: `force_render_${getRandomValue("number")}`,
    width: commonWidth,
    thClassName: "thForceRunSchedule",
    dataRender: (data: any, otherData: any) => {
      if (otherData.data.singleData.recipients) {
        return (
          <div className="tblActions text-center " style={{ width: "40" }}>
            {otherData.data.singleData.status && (
              <ForceRunSchedule scheduleId={data} />
            )}
          </div>)
      }
    },
  },
  {
    title: "REPORT NAME",
    dataIndex: "name",
    key: `report_name_${getRandomValue("number")}`,
    canSort: true,
    dataRender: (data: any) => (
      <div className="">
        <b>{data}</b>
      </div>
    ),
  },
  {
    title: "REPORT TITLE",
    dataIndex: "title",
    key: `report_title_${getRandomValue("number")}`,
    canSort: true,
  },
  {
    title: "FREQUENCY",
    dataIndex: "frequency",
    key: `frequency_${getRandomValue("number")}`,
    canSort: true,
    dataRender: (data: any) => (
      <div className="" style={{ textTransform: "capitalize" }}>
        {data}
      </div>
    ),
  },
  {
    title: "RECIPIENTS",
    dataIndex: "recipients",
    key: `recipients_${getRandomValue("number")}`,
    dataRender: (data: any) => {
      if (data) {
        if (data.length < 2) {
          return (
            <div className="">{data[0].first_name + " " + data[0].last_name}</div>
          );
        } else {
          const count = data.length;
          return (
            <Tooltip
              title={data
                .map((el: any) => `"${el.first_name} ${el.last_name}"`)
                .join(", ")}
            >
              <span style={{ cursor: "default" }}>
                {`${data[0].first_name + " " + data[0].last_name} + ${count - 1
                  } more`}
              </span>
            </Tooltip>
          );
        }
      }
    },
  },
  {
    title: "NEXT RUN",
    thClassName: "nexRunCol",
    dataIndex: "nextRunAt",
    key: `next_run_${getRandomValue("number")}`,
    canSort: true,
    dataRender: (data: string) => (
      <div className="">
        {data != null ? getNextRunAtTimeFormat(data) : null}
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "status",
    key: `toggle__${getRandomValue("number")}`,
    width: commonWidth,
    thClassName: "thToggleSchedule",
    dataRender: (data: boolean, otherData: any) => (
      <RBAC allowedPermissions={[ERbacPermissions.SCHEDULE_TOGGLE_STATUS]}>
        <div className="tblActions text-center">
          <Tooltip title={data ? "De-activate schedule" : "Activate schedule"}>
            <div
              onClick={() => {
                trigger("scheduleAction:click", {
                  action: "toggle",
                  data: otherData.data.singleData,
                });
              }}
            >
              <i
                className={`da ${data ? "icon-power-off-success" : "icon-power-off-danger"
                  }`}
              />
            </div>
          </Tooltip>
        </div>
      </RBAC>
    ),
  },
  {
    title: "",
    dataIndex: "id",
    key: `edit_delete_${getRandomValue("number")}`,
    //width: commonWidth,
    thClassName: "thModifySchedule",
    dataRender: (_data: any, otherData: any) => (
      <div className="tblActions text-center">
        <RBAC allowedPermissions={[ERbacPermissions.SCHEDULE_EDIT]}>
          <div>
            <CreateScheduler
              schedulerTitle={"Edit Schedule"}
              scheduleData={otherData.data.singleData}
              filterData={otherData.data.singleData?.scheduleDetails.filter}
              onUpdateSchedule={(d: boolean) => {
                trigger("scheduleAction:click", { action: "edit", data: d });
              }}
            />
          </div>
        </RBAC>
        <RBAC allowedPermissions={[ERbacPermissions.SCHEDULE_DELETE]}>
          <Tooltip title="Delete this schedule" placement="topLeft">
            <div
              onClick={() => {
                trigger("scheduleAction:click", {
                  action: "delete",
                  data: otherData.data.singleData,
                });
              }}
            >
              <i className={`da icon-trash-o`} />
            </div>
          </Tooltip>
        </RBAC>
      </div>
    ),
  },
];

export { schedulerListHeaders };
