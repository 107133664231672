import React from "react";
import { Tag, Typography } from "antd";
import {
  IDashboardCommonDatum,
  IdType,
  IListerSeatAvailabilityQueryParams,
} from "models/interfaces";
import { getEncodedData } from "utils/commonFunction";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { BrowserView, MobileView } from "react-device-detect";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import {
  getActiveListingField,
  formatDate,
  getRightTagStyles,
  formatEventName,
  viewAllCTA,
} from "views/Dashboard/CommonComponents/CommonFile/commonFile";
import EventQuickUpdate from "views/Zones/Actions/EditEventComponents/EventQuickUpdate/EventQuickUpdate";

const SeatAvailabilityCommon: React.FC<{
  eventData: IDashboardCommonDatum[] | undefined | null;
  showLoader: boolean;
  queryParams: IListerSeatAvailabilityQueryParams | null;
  label: string;
  paramObject: {
    seat_availability_update?: number;
    show_category_id?: IdType;
    exclude_sold_out_events?: boolean;
    exclude_cancelled_events?: boolean;
  };
  textLimits: {
    browser: number;
    mobile: number;
  };
  tagStyleType?: string;
  labelColorClass: string;
  showNoOfListings?: boolean;
  ifLengthExceeds?: boolean;
  ifShowingHyperlinkedLabel?: boolean;
}> = ({
  eventData,
  showLoader,
  queryParams,
  label,
  paramObject,
  textLimits,
  tagStyleType = null,
  labelColorClass,
  showNoOfListings = true,
  ifLengthExceeds = false,
  ifShowingHyperlinkedLabel = true,
}) => {
  return (
    <div className="dashboardCard2">
      {ifShowingHyperlinkedLabel && (
        <React.Fragment>
          <BrowserView>
            {eventData && eventData.length > 0 && (
              <Typography.Link
                target="_blank"
                rel="noreferrer"
                href={`${
                  LeftNavRoutesEnum.TRADING_EVENTS
                }/?filter=${getEncodedData({
                  ...queryParams,
                  ...paramObject,
                })}`}
                underline={true}
                className={`dashboardCardHeader ${labelColorClass}`}
              >
                {label}
              </Typography.Link>
            )}
            {(!eventData || eventData.length === 0) && (
              <div className={`dashboardCardHeader ${labelColorClass}`}>
                {label}
              </div>
            )}
          </BrowserView>
          <MobileView>
            <div className={`dashboardCardHeader ${labelColorClass}`}>
              {label}
            </div>
          </MobileView>
        </React.Fragment>
      )}
      {!ifShowingHyperlinkedLabel && (
        <div className={`dashboardCardHeader ${labelColorClass}`}>
          <span>{label}</span>
          {ifLengthExceeds &&
            viewAllCTA(
              "TRADING_EVENTS",
              { ...queryParams, ...paramObject },
              { color: "#4171B9", fontSize: 14, marginLeft: 10 },
              "View All"
            )}
        </div>
      )}
      <div className="dashboardCardBody listerSeatAvailability">
        <LoadingSpinner isLoading={showLoader}>
          {!showLoader && (
            <React.Fragment>
              {eventData && eventData.length > 0 && (
                <ul>
                  {eventData?.map((elem: IDashboardCommonDatum) => (
                    <li key={elem.event_id}>
                      <p>
                        <BrowserView>
                          <Typography.Link
                            target="_blank"
                            rel="noreferrer"
                            href={`${
                              LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY
                            }/?event=${getEncodedData({
                              event_id: elem.event_id,
                            })}`}
                            underline={true}
                          >
                            <span className="text-capitalize">
                              {formatEventName(
                                elem.event_name,
                                textLimits.browser
                              )}
                            </span>
                            <span>, {formatDate(elem.event_date)}</span>
                          </Typography.Link>
                          &nbsp;&nbsp;
                          {elem.seat_intelligence_id === 3 &&
                            elem.is_verification_pending && (
                              <Tag className="warning tagSmall">Structure Verification Pending</Tag>
                            )}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {showNoOfListings && (
                              <div className="checkerListingCountStyle">
                                <Typography.Link
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${
                                    LeftNavRoutesEnum.ZONE_LISTINGS_ACTIVE_LISTINGS
                                  }/?event=${getEncodedData({
                                    event_id: elem.event_id,
                                  })}`}
                                  underline={true}
                                >
                                  {getActiveListingField(
                                    elem.active_listings ?? 0
                                  )}
                                </Typography.Link>
                              </div>
                            )}
                            <span>&nbsp;&nbsp;</span>
                            {Boolean(
                              elem.platform_broadcasted_active_listings
                            ) &&
                              elem.platform_broadcasted_active_listings > 0 && (
                                <React.Fragment>
                                  <EventQuickUpdate
                                    eventId={elem.event_id}
                                    platformBroadcastedActiveListingsCount={
                                      elem.platform_broadcasted_active_listings
                                    }
                                  />
                                </React.Fragment>
                              )}
                          </div>
                        </BrowserView>
                        <MobileView>
                          <span className="text-capitalize">
                            {formatEventName(
                              elem.event_name,
                              textLimits.mobile
                            )}
                          </span>
                          <span>, {formatDate(elem.event_date)}</span>
                          &nbsp;&nbsp;
                          {elem.seat_intelligence_id === 3 &&
                            elem.is_verification_pending && (
                              <Tag className="warning tagSmall">Structure Verification Pending</Tag>
                            )}
                          {showNoOfListings && (
                            <div>
                              {getActiveListingField(elem.active_listings ?? 0)}
                            </div>
                          )}
                        </MobileView>
                      </p>
                      {getRightTagStyles(
                        tagStyleType,
                        elem.days || elem.days_to_event
                      )}
                    </li>
                  ))}
                  {ifLengthExceeds && (
                    <div>
                      {viewAllCTA(
                        "TRADING_EVENTS",
                        { ...queryParams, ...paramObject },
                        { color: "#4171B9", fontSize: 14 },
                        "View All"
                      )}
                    </div>
                  )}
                </ul>
              )}
              {!(eventData && eventData.length > 0) && (
                <div className="text-primary">No Data Available</div>
              )}
            </React.Fragment>
          )}
        </LoadingSpinner>
      </div>
    </div>
  );
};

export default SeatAvailabilityCommon;
