import { message } from "antd";
import { useAppDispatch } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import {
  EventsWithActiveZoneListingsQueryParams,
  IActiveListing,
  IAllZoneListingTableHeader,
  IEditInventoryListingDatum,
  IEventsWithActiveZoneListingsResponse,
  IEventWithActiveZoneListings,
} from "models/interfaces";
import { competitorPricesActions } from "models/Slices/CompetitorPricesSlice";
import React, {
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import AllZoneListingTableDataRow from "./AllZoneListingTableDataRow";

interface IAllZoneListingTable {
  data: IEventWithActiveZoneListings[];
  onSort?: Function;
  sortField?: { dataIndex: string; order: string };
  loading?: boolean;
  otherData: IEventsWithActiveZoneListingsResponse;
  tableClass?:
    | "customTbl"
    | "table-scroll fixedHeaderFooter"
    | "customTbl fixedHeaderFooter"
    | "customTbl scroll-x-y fixedHeaderFooter"
    | "customTbl scroll-y fixedHeaderFooter"
    | "main-table";
  tableHeight?: { maxHeight: any };
  enableRowSelect?: boolean;
  setAllZoneListings: Function;
  queryParams: EventsWithActiveZoneListingsQueryParams;
  urlParams: any;
  searchText: string | null;
  purchaseLineId: string | null;
}

const AllZoneListingTable: React.FC<IAllZoneListingTable> = ({
  data,
  onSort,
  sortField,
  loading,
  otherData,
  tableClass,
  tableHeight,
  enableRowSelect,
  setAllZoneListings,
  queryParams,
  urlParams,
  searchText,
  purchaseLineId,
}) => {
  const { hasPermissions } = useRbac();
  const tableScrollableElement = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: any;
  }>(null);

  const listViewHeaders: IAllZoneListingTableHeader[] = [
    {
      title: "",
      dataIndex: "seat_availability_score",
      thClassName: "warningBg text-left seatAviaileLogicCol",
    },
    {
      title: "",
      dataIndex: "event_id",
      thStyle: { width: 32 },
    },
    {
      title: "LAST UPDATED",
      dataIndex: "updated_on",
      canSort: true,
      thClassName: "text-left lastUpdateCol",
      thStyle: { width: 90 },
    },
    {
      title: "EVENT NAME & VENUE",
      dataIndex: "event_name",
      canSort: true,
      thClassName: "text-left eventNameCol",
      thStyle: { width: 300 },
    },
    {
      title: "DAYS TO EVENT",
      dataIndex: "days_to_event",
      canSort: true,
      thClassName: "text-left dayToEventCol",
      thStyle: { width: 50 },
    },
    {
      title: "DATE & TIME",
      dataIndex: "event_date",
      canSort: true,
      thClassName: "text-left dateTimeCol",
    },
    {
      title: "BROKER",
      dataIndex: "broker_name",
      canSort: false,
      thClassName: "text-left brokerCol",
      thStyle: { width: 80 },
    },
    {
      title: "LISTER",
      dataIndex: "lister_name",
      canSort: false,
      thClassName: "text-left listerCol",
      thStyle: { width: 80 },
    },
    {
      title: "PO LINES",
      dataIndex: "active_listings",
      canSort: true,
      thClassName: "text-right",
      thStyle: { width: 100 },
    },
    {
      title: "PENDING FILLS",
      dataIndex: "pending_fills",
      canSort: true,
      thClassName: "text-right",
      thStyle: { width: 120 },
    },
    {
      title: "PRICING ISSUE",
      dataIndex: "pricing_issue",
      canSort: true,
      thClassName: "text-right",
      thStyle: { width: 120 },
    },
    {
      title: "",
      dataIndex: "venue_id",
    },
  ];


  useLayoutEffect(() => {
    function updateScrollPosition() {
      if (
        tableScrollableElement.current &&
        tableScrollableElement.current?.scrollLeft > 0
      ) {
        tableScrollableElement.current?.classList.add("scrolledShadow");
      }
      if (
        tableScrollableElement.current &&
        tableScrollableElement.current?.scrollLeft === 0
      ) {
        setTimeout(() => {
          tableScrollableElement.current?.classList.remove("scrolledShadow");
        }, 100);
      }
    }
    tableScrollableElement.current?.addEventListener(
      "scroll",
      updateScrollPosition
    );
  });

  const renderSortIcon = (header: IAllZoneListingTableHeader) => {
    if (!header.canSort) return "";
    if (
      header.dataIndex === sortField?.dataIndex &&
      sortField?.order === "desc"
    ) {
      return <i className="da sortIcon icon-sort-asc" />;
    } else if (
      header.dataIndex === sortField?.dataIndex &&
      sortField?.order === "asc"
    ) {
      return <i className="da sortIcon icon-sort-desc" />;
    }
    return <i className="da sortIcon icon-sort" />;
  };

  const doSort = (sortIndex: string, header: IAllZoneListingTableHeader) => {
    if (onSort && header.canSort) {
      let sortOrder = "desc";
      if (sortField?.dataIndex === sortIndex) {
        sortOrder = sortField.order === "desc" ? "asc" : "desc";
      }
      onSort(sortIndex, sortOrder);
    }
  };

  // const fetchCompetitorPrice = useCallback((event_id: number, venue_id: number, site_type: string, seatmap_url: string, force_refresh: boolean) =>{
  //   dispatch(
  //     competitorPricesActions.getCompetitorPrices({
  //       event_id: event_id,
  //       venue_id: venue_id,
  //       site_type: site_type,
  //       seatmap_url: seatmap_url,
  //       force_refresh: force_refresh,
  //       functionName: "getEventCompetitivePrice",
  //     })
  //   );
  // },[dispatch]);

  const rowClicked = useCallback(
    (singleData: any) => {
      if (
        (enableRowSelect === undefined || enableRowSelect === true) &&
        data
      ) {
        setSelectedRow(singleData === selectedRow ? null : singleData);
      }
    },
    [enableRowSelect, data, selectedRow]
  );

  

  const rowSaveClickHandler = async (data: IActiveListing) => {
    let messageKey = 'updateListing';
    message.loading({
      content: `Updating listing...`,
      duration: 0,
      key: messageKey,
      className: "toastMsg loadingMsg",
    });
    setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
      if (prev) {
        const _prev = { ...prev };
        let currentPo = _prev.events
          .find(
            (event) => event.event_id === data.event_id
          )
          ?.po_details?.editedActiveListings.find(
            (eachPo) =>
              eachPo.inventory_id === data.inventory_id
          );
        if (currentPo) {
          currentPo.is_updating = true;
        }
        return _prev;
      }
      return prev;
    });
    
    const oldData = otherData?.events
      .find((eachEvent) => eachEvent.event_id === data.event_id)
      ?.po_details?.activeListings.find(
        (eachListing) => eachListing.inventory_id === data.inventory_id
      );
    const newData = otherData?.events
      .find((eachEvent) => eachEvent.event_id === data.event_id)
      ?.po_details?.editedActiveListings.find(
        (eachListing) => eachListing.inventory_id === data.inventory_id
      );
    if(oldData && newData) {
      const updatedInventory: IEditInventoryListingDatum = {
        inventory_id: data.inventory_id,
        inventory_ids: data.inventory_ids,
        is_split: data.is_split,
        oldSection: oldData.section,
        newSection: newData.section,
        oldRow: oldData.row,
        newRow: newData.row,
        old_list_price: oldData.list_price,
        new_list_price: newData.list_price,
        update_price: newData.list_price !== oldData.list_price,
        update_section_row: newData.section !== oldData.section || newData.row !== oldData.row
      }
      try {
        const updateResponse = await new ZonesService().updateListingSectionRowPrice([updatedInventory]);
        setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
          if (prev) {
            const _prev = { ...prev };
            let currentPo = _prev.events
              .find(
                (event) => event.event_id === data.event_id
              )
              ?.po_details?.editedActiveListings.find(
                (eachPo) =>
                  eachPo.inventory_id === data.inventory_id
              );
            if (currentPo) {
              currentPo.is_updating = false;
            }
            return _prev;
          }
          return prev;
        });
        let forceUpdateMessage = "Syncing data from skybox to platform...";
        let messageDuration = 5;
        if (updateResponse.status === 200) {
          message.success({
            content: `Listing Updated in Skybox.`,
            duration: 5,
            key: messageKey,
            className: "toastMsg savedSuccess",
          });
          messageKey = 'forceUpdate';
          setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
            if (prev) {
              const _prev = { ...prev };
              let currentPo = _prev.events
                .find(
                  (event) => event.event_id === data.event_id
                )
                ?.po_details?.activeListings.find(
                  (eachPo) =>
                    eachPo.inventory_id === data.inventory_id
                );
              if (currentPo) {
                currentPo.is_updating = false;
                currentPo.section = updatedInventory.newSection;
                currentPo.row = updatedInventory.newRow;
                currentPo.list_price = updatedInventory.new_list_price;
              }
              return _prev;
            }
            return prev;
          });
          
          message.loading({
            content: forceUpdateMessage,
            duration: 0,
            key: messageKey,
            className: "toastMsg loadingMsg",
          });
          const forceUpdateResponse = await new ZonesService().forceUpdatePlatformSkyboxInventories(updatedInventory.inventory_ids);
          if (forceUpdateResponse.data.data.is_mvw_refreshed) {
            forceUpdateMessage = "Zone listing has been synced successfully!";
          } else {
            forceUpdateMessage =
              "The Listing has been updated successfully but it may take a while to appear on the platform. Data synchronization with SkyBox is in progress.";
            messageDuration = 10;
          }
        }
        message.success({
          content: forceUpdateMessage,
          duration: messageDuration,
          key: messageKey,
          className: "toastMsg savedSuccess",
        });
        await new ZonesService().remapListingsWithZones(data.event_id); //remap listing with zone bts
      } catch (error: any) {
        setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
          if (prev) {
            const _prev = { ...prev };
            let currentPo = _prev.events
              .find(
                (event) => event.event_id === data.event_id
              )
              ?.po_details?.editedActiveListings.find(
                (eachPo) =>
                  eachPo.inventory_id === data.inventory_id
              );
            if (currentPo) {
              currentPo.is_updating = false;
            }
            return _prev;
          }
          return prev;
        });
        message.error({
          key: messageKey,
          content: "Failed to refresh data.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
  
        new LoggerService().log({
          payload: error,
          function_name: "moveToNotDelivered",
        });
      }
    };
  }

  return (
    <LoadingSpinner isLoading={loading ? loading : false}>
      <div className="removeTblLRSpc">
        <div
          className={tableClass ? tableClass : "table-scroll fixedHeaderFooter"}
          style={tableHeight}
          ref={tableScrollableElement}
        >
          <table className="main-table">
            <thead>
              <tr>
                {listViewHeaders.map((eachHeader) => {
                  return (
                    <React.Fragment
                      key={`${eachHeader.dataIndex}_${eachHeader.title}`}
                    >
                      {(!eachHeader.allowedPermissions ||
                        hasPermissions(eachHeader.allowedPermissions)) && (
                        <th
                          onClick={() =>
                            doSort(eachHeader.dataIndex, eachHeader)
                          }
                          className={`${
                            eachHeader.thClassName ? eachHeader.thClassName : ""
                          }`}
                          style={{
                            ...eachHeader.thStyle,
                          }}
                        >
                          {eachHeader.title}
                          {renderSortIcon(eachHeader)}
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data && (
                <React.Fragment>
                  {data.map((eachData) => (
                    <AllZoneListingTableDataRow
                      headers={listViewHeaders}
                      key={"index-" + eachData.event_id}
                      singleData={eachData}
                      onRowClicked={rowClicked}
                      otherData={data}
                      allZoneListings={otherData}
                      setAllZoneListings={setAllZoneListings}
                      rowSaveClickHandler={rowSaveClickHandler}
                      queryParams={queryParams}
                      urlParams={urlParams}
                      searchText={searchText}
                      // fetchCompetitorPrice={fetchCompetitorPrice}
                      purchaseLineId={purchaseLineId}
                    />
                  ))}
                </React.Fragment>
              )}
              {(!data || data.length === 0) && (
                <tr className="tableNoResultFound">
                  <td colSpan={45}>
                    <div className="noResultFound">No Result Found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </LoadingSpinner>
  );
};

export default AllZoneListingTable;
