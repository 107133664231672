import { RoutesEnum } from "models/enums";
import { GrossProfitData, IRreceivablesReportData, ISoldInventoryIssueRes } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class GrossProfitService {
    public getGrossProfitData = async (grossProfitRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.GROSS_PROFIT, {
            params: grossProfitRequest
        });
    };

    public getInvoicesNeedingAttention = async (grossProfitRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.INVOICES_NEEDING_ATTENTION, {
            params: grossProfitRequest
        });
    };

    public getReceivablesDetails = async (grossProfitRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<IRreceivablesReportData>
        >(RoutesEnum.RECEIVABLES_DETAILS, {
            params: grossProfitRequest
        });
    };

    public getReceivablesRetail = async (receivableRetailRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<IRreceivablesReportData>
        >(RoutesEnum.RECEIVABLES_RETAIL, {
            params: receivableRetailRequest
        });
    };

    public getReceivablesDetailsOld = async (grossProfitRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<IRreceivablesReportData>
        >(RoutesEnum.RECEIVABLES_DETAILS_OLD, {
            params: grossProfitRequest
        });
    };

    public getReceivablesRetailOld = async (receivableRetailRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<IRreceivablesReportData>
        >(RoutesEnum.RECEIVABLES_RETAIL_OLD, {
            params: receivableRetailRequest
        });
    };

    public savePricingResolution = async (soldInventoryIssueRes: ISoldInventoryIssueRes) => {
        return axiosClient.patch<IApiBaseResponse<boolean>>(
          RoutesEnum.SOLD_INVENTORY_ISSUE_RESOLUTION,
          {
            ...soldInventoryIssueRes,
          }
        );
      };
    

}

export { GrossProfitService };