import React, { useState } from "react";
import { Row, Tooltip, Typography } from "antd";
import { IFilterOptions } from "models/interfaces";
import RefreshIcon from "../../../../assets/images/reload-icon-white.svg";
import NewShowsCommon from "./NewShowsCommon";

const NewShowsData: React.FC<{
  filterObject: IFilterOptions | null;
}> = ({ filterObject }) => {
  const [isRefreshed, setIsRefreshed] = useState(false);

  return (
    <div className="dashboardCardWrap">
      <div className="headingWithLoader">
        <h3>New Shows (No Listings)</h3>
        <Tooltip title="Reload" placement="right">
          <Typography.Link onClick={() => setIsRefreshed(true)}>
            <img
              src={RefreshIcon}
              alt="Refresh"
              style={{ verticalAlign: "middle", marginLeft: "10px" }}
            />
          </Typography.Link>
        </Tooltip>
      </div>
      <Row>
        <NewShowsCommon
          filterObject={filterObject}
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          newShowsOnly={true}
          ifNoListing={true}
        />
      </Row>
    </div>
  )
}

export default NewShowsData;