import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withRbac } from "features/rbac/rbac";
import { FC, FunctionComponent } from "react";
import { Route, RouteProps } from "react-router";
import PageLoader from "utils/sharedComponents/PageLoader";

export const AuthenticateRoute: FC<{component: FunctionComponent, path: string, exact: boolean, allowedPermissions?: string[]}> = ({ component, path, exact, allowedPermissions, ...rest }) => {
  if(allowedPermissions){
    return (
      <Route
        component={withAuthenticationRequired(withRbac(component, {allowedPermissions, isAccessFromPage: true}), {
          onRedirecting: () => <PageLoader />,
        })}
        path={path}
        exact={exact}
        {...rest}
      />
    )
  }
  else{
    return (
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <PageLoader />,
        })}
        path={path}
        exact={exact}
        {...rest}
      />
    )
  }
 
};

export const AnonymousRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (Component ? <Component {...props} /> : null)}
    />
  );
};
