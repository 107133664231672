import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import PendingSellOut from './PendingSellOut';
import Issues from './Issues';

const ShippingQueue = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Shipping Queue</h3>
                    <Row className="removeRowBtmSpc">
                        <PendingSellOut/>
                        <Issues/>
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default ShippingQueue