import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import ReportLayout from "../../ReportLayout";
import AllBrokers from "./AllBrokers";
import { BrokerDetailsService } from "services";
import CustomBroker from "./CustomBroker";
import { IReportQueryString } from "models/interfaces";
import { LoggerService } from "sharedServices/api/logger.service";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";
import { useLocation } from "react-router";
import { usePageTitle } from "hooks";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

const Scorecard: React.FC = () => {
  usePageTitle("Long Inventory");
  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report
  );

  const [brokers, setBrokers] = useState<any | null>();
  const [brokerDataFetchError, setBrokerDataFetchError] =
    useState<ICustomError | null>(null);

  const getBrokerDetails = async (current_report_id: number) => {
    try {
      const response = await new BrokerDetailsService().getAllBrokers({current_report_id});
      let brokersArr = response.data.data.brokers;
      let noBrokerData = brokersArr.filter((broker) => broker.value === 0)[0];

      brokersArr = brokersArr.filter((broker) => broker.value !== 0);

      if(noBrokerData)
        brokersArr.unshift(noBrokerData);

      setBrokers(brokersArr);
      setBrokerDataFetchError(null);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getBrokerDetails",
      });
      setBrokerDataFetchError({ ...error, refreshAction: getBrokerDetails });
    }
  };

  useEffect(() => {
    getBrokerDetails(reportQueryString.current_report_id);
  }, []);

  return (
    <div className="fixedFilterArea">
      <div className="mainHeading">
        <div>
          <h2>Long Inventory Snapshot</h2>
        </div>
      </div>
      <ReportLayout>
        <RBAC allowedPermissions={[ERbacPermissions.REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_ALL_BROKER]}>
          <Layout className="mainInnerLayout pb-0 reduceTopSpace">
            <AllBrokers
              reportData={reportQueryString}
            />
          </Layout>
        </RBAC>
        {brokerDataFetchError ? (
          <CustomError {...brokerDataFetchError} />
        ) : (
          brokers?.map((data: any, ind: number) => (
            <CustomBroker
              key={ind}
              brokerId={data.value}
              reportData={reportQueryString}
            />
          ))
        )}
      </ReportLayout>
    </div>
  );
};

export default Scorecard;
