import { message, Tooltip } from "antd";
import React, { useState } from "react";
import { SchedulerService } from "services";
import { LoggerService } from "sharedServices";

const ForceRunSchedule: React.FC<{ scheduleId: string }> = ({ scheduleId }) => {
  const [isForceRunning, setIsForceRunning] = useState(true);

  const forceRunHandler = async () => {
    const key = "forceRunning";
    try {
      setIsForceRunning(false);
      message.loading({
        content: "Running schedule...",
        duration: 0,
        key: key,
        className: "toastMsg loadingMsg"
      });
      const response = await new SchedulerService().forceRunSchedule(
        scheduleId
      );
      if (response.data.data) {
        message.success({
          content: "Schedule has run successfully!",
          key,
          duration: 5,
          className: "toastMsg savedSuccess"
        });
        setIsForceRunning(true);
      }
    } catch (error) {
      await new LoggerService().log({
        payload: error,
        function_name: "triggerForceRun",
      });
      message.error({ content: "Schedule has failed to run.", duration: 5, key: key, className: "toastMsg savedFailed" });
      setIsForceRunning(true);
    }
  };

  return (
    <React.Fragment>
      {isForceRunning && (
        <Tooltip title="Run immediately">
          <div onClick={forceRunHandler}>
            <i className={`da icon-play`} />
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default ForceRunSchedule;
