import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import globalReducer from "models/Slices/GlobalSlice";
import filterReducer from "models/Slices/FilterSlice";
import venuesManagementReducer from "models/Slices/VenuesManagementSlice";
import eventManagementReducer from "models/Slices/EventManagementSlice";
// import competitorPricesReducer from "models/Slices/CompetitorPricesSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    filters: filterReducer,
    venuesManagement: venuesManagementReducer,
    eventsManagement: eventManagementReducer,
    // competitorPrices: competitorPricesReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
