import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import { GrossProfitService } from "services";
import CommonReportTable from "utils/table/CommonReportTable";
import { ReceivablesDetailsTableHeaders } from "./receivablesDetailsUtils";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { LoggerService } from "sharedServices/api/logger.service";
import {
  IFilterOptions,
  IRreceivablesReportData,
  IReceivablesReportDatum,
} from "models/interfaces";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { useLocation } from "react-router";
import { formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const ReceivablesDetailsTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
  outerLoader: boolean;
  setOuterLoader: Function;
}> = ({ 
  filterObject, 
  appliedFilterValues, 
  searchQuery, 
  outerLoader,
  setOuterLoader 
}) => {
  const pathName = useLocation().pathname;

  const [showLoader, setShowLoader] = useState(false);
  const [defaultQueryParams] = useState<any>({
    // client_zone: AppSetting.TIMEZONE,
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "pl_date",
    sort_order: "desc",
    keywords: "",
    is_retail: pathName.endsWith("retail") ? true : false
  });
  const [queryParams, setQueryParams] = useState<any>(null);
  const [receivablesReportData, setReceivablesReportData] =
    useState<IRreceivablesReportData | null>(null);
  const [reportData, setReportData] = useState<
    IReceivablesReportDatum[] | null
  >();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [defaultQueryParams, filterObject]);

  const getRreceivablesReportData = async (query: any) => {
    setOuterLoader(false);
    setShowLoader(true);
    try {
      let response;
      let responseData;

      if (pathName.endsWith("retail")) {
        response = await new GrossProfitService().getReceivablesRetail({
          ...query,
        });
      } else {
        response = await new GrossProfitService().getReceivablesDetails({
          ...query,
        });
      }

      responseData = response.data.data;
      responseData.receivablesReport = handleHighlightedRow(
        response.data.data.receivablesReport
      );
      setReportData(responseData.receivablesReport);

      setShowLoader(false);

      setReceivablesReportData(responseData);
      setDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getRreceivablesReportData",
      });
      setDataFetchError({ ...error, refreshAction: receivablesReportData });
    }
  };

  useEffect(() => {
    if (queryParams != null) {
      getRreceivablesReportData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      });
    }
  }, [searchQuery]);

  const handleHighlightedRow = (responseReportData: any) => {
    if (responseReportData) {
      responseReportData = responseReportData.map((eachReportData: any) => {
        return {
          ...eachReportData,
          activeSelectedClass: +eachReportData?.has_row_alert
            ? "redSelected"
            : null,
        };
      });
    }

    return responseReportData;
  };

  const getViewTable = () => {
    if (!showLoader &&
      receivablesReportData &&
      receivablesReportData?.total) {
        return (
          <ul className="innerHeaderRight">
            <li>
              <h4>
                {receivablesReportData?.total === 1
                  ? receivablesReportData?.total + " Record Found"
                  : formatThousandSeparator(receivablesReportData?.total) + " Records Found"}
              </h4>
            </li>
            <li>
              <Pagination
                className="pagination"
                hideOnSinglePage={true}
                //defaultCurrent={1}
                current={queryParams?.page_index}
                total={receivablesReportData?.total}
                pageSize={queryParams?.page_size}
                onChange={(pageNumber) =>
                  setQueryParams({
                    ...queryParams,
                    page_index: pageNumber,
                  })
                }
                showSizeChanger={false}
              />
            </li>
          </ul>
        );
    }
    return (
      <div style={{ height: 21 }}></div>
    );
  }

  return (
    <LoadingSpinner isLoading={outerLoader}>
      <React.Fragment>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          queryParams && (
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row className="flx justifyContentBetween alignItemsCenter">
                  <Col span={14}>
                    <AppliedFilters appliedFilters={appliedFilterValues} />
                  </Col>
                  <Col span={10}>
                    { getViewTable() }
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <CommonReportTable
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    setQueryParams({
                      ...queryParams,
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                    });
                  }}
                  sortField={{
                    dataIndex: queryParams?.sort_column,
                    order: queryParams?.sort_order,
                  }}
                  headers={ReceivablesDetailsTableHeaders}
                  data={reportData ?? null}
                  headerData={receivablesReportData?.aggregate.map((data) => {
                          return { ...data, pl_date: "Total" };
                        })
                  }
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  loading={showLoader}
                />
              </div>
            </Content>
          )
        )}
      </React.Fragment>
    </LoadingSpinner>
  );
};

export default ReceivablesDetailsTable;
