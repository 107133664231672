import { Button, Modal, Input, message } from "antd";
import { ISoldInventoryDatum } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getCurrentDate,
  getCurrentTime,
  getCustomDate,
  getCustomTime,
} from "utils/formatTimePeriod";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { HouseKeepingService } from "services";
import { useRbac } from "features/rbac/rbac";
import { AppSetting } from "utils/appSettings";
import { twoDecimalPlacesNumber } from "utils/commonFunction";

const { TextArea } = Input;

const CancelInvoiceModal: React.FC<{
  isModalVisible: boolean;
  setIsModalVisible: Function;
  setIsCancelPending: Function;
  modalData: ISoldInventoryDatum;
}> = ({ isModalVisible, setIsModalVisible, setIsCancelPending, modalData }) => {
  const { auth0UserDetails } = useRbac();
  
  const [defaultInvoiceCreditNote, setDefaultInvoiceCreditNote] = useState(
    `TB 2.0: Canceled show credit note on ${getCurrentDate(
      "MMM DD, YYYY"
    )} at ${getCurrentTime()} EST against PO Number: ${
      modalData?.purchaseId?.join(', ')
    } and PO Line ID: ${modalData?.poLineId?.join(', ')}, Qty = ${modalData?.quantity}, Unit Price = ${modalData?.unitSalePrice}`
  );

  const [defaultPoCreditNote, setDefaultPoCreditNote] = useState(
    `TB 2.0: Canceled show credit note on ${getCurrentDate(
      "MMM DD, YYYY"
    )} at ${getCurrentTime()} EST against Invoice Number: ${
      modalData?.invoiceId
    } and Invoice Line ID: ${modalData?.invLineId}, Qty = ${modalData?.quantity}, Unit Cost = ${modalData?.unitCost}`
  );

  useEffect(()=>{
    setDefaultPoCreditNote(`TB 2.0: Canceled show credit note on ${getCurrentDate(
      "MMM DD, YYYY"
    )} at ${getCurrentTime()} EST against Invoice Number: ${
      modalData?.invoiceId
    } and Invoice Line ID: ${modalData?.invLineId}, Qty = ${modalData?.quantity}, Unit Cost = ${modalData?.unitCost}`);
    setDefaultInvoiceCreditNote(`TB 2.0: Canceled show credit note on ${getCurrentDate(
      "MMM DD, YYYY"
    )} at ${getCurrentTime()} EST against PO Number: ${
      modalData?.purchaseId?.join(', ')
    } and PO Line ID: ${modalData?.poLineId?.join(', ')}, Qty = ${modalData?.quantity}, Unit Price = ${modalData?.unitSalePrice}`);
  }, [modalData]);

  const [invoiceCreditNote, setInvoiceCreditNote] = useState<string|null>(null);
  const [poCreditNote, setPoCreditNote] = useState<string|null>(null);

  const cancelHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsModalVisible(false);
    message.warning({content: "Operation cancelled by user", duration: 5, className: "toastMsg savedFailed"});
  };

  const cancelInvoiceCancelHandler = (_data: ISoldInventoryDatum) => {
    message.warning({content: "Operation cancelled by user", duration: 5, className: "toastMsg savedFailed"});
  }

  const cancelInvoice = async (data: ISoldInventoryDatum) => {
    const body = {
      invoiceId: data?.invoiceId,
      invLineId: data?.invLineId,
      purchaseId: data?.purchaseId,
      poLineId: data?.poLineId,
      quantity: data?.quantity,
      targetUrl: "",
      poCreditNote: poCreditNote && poCreditNote.trim().length > 0? poCreditNote : defaultPoCreditNote,
      invoiceCreditNote: invoiceCreditNote && invoiceCreditNote.trim().length > 0 ? invoiceCreditNote : defaultInvoiceCreditNote,
      invoiceAmount: data?.ticketSales * -1,
      invoiceUnitPrice: data?.unitSalePrice,
      purchaseAmount: data?.ticketCost * -1,
      purchaseUnitCost: data?.unitCost,
      lineType: data?.lineType,
      lineItemType: data?.lineItemType,
      mailTo: auth0UserDetails?.email || '',
      isFromUnfilled: false
    };
    try {
      console.log(body);
      const res = await new HouseKeepingService().cancelInvoice(body);
      if(res?.status === 200) {
        setIsCancelPending(true);
        message.success({content: "Invoice Cancellation in progress.", duration: 5, className: "toastMsg savedSuccess"});
      }
      else {
        message.warning({content: "Failed to cancel invoice", duration: 5, className: "toastMsg savedFailed"});
      }
    } catch (error) {
      message.warning({content: "Failed to cancel invoice", duration: 5, className: "toastMsg savedFailed"});
    }  
  }

  const confirmHandler = () => {
    setIsCancelPending(false);
    const header = <span>Are you sure you want to <strong>cancel</strong> this invoice?</span>;
    const body = (
      <div>
        <div className="delMessageBody">
          <p>Invoice Line ID: {modalData?.invLineId.toString()}</p>
          <p>Purchase Line ID: {modalData?.poLineId.join(', ')}</p>
        </div>
        <div className="smlErrorTxt">This action cannot be undone.</div>
      </div>
    );
    showConfirm(header, body, modalData, cancelInvoice, cancelInvoiceCancelHandler);
    setInvoiceCreditNote(null);
    setPoCreditNote(null);
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      {modalData && (
        <Modal
          className="cancelInvoicePurchaseOrderModal"
          closable={false}
          width={1008}
          title={false}
          footer={false}
          visible={isModalVisible}
          destroyOnClose={true}
        >
          <div className="modalCustomBody">
            <h3>Cancel Invoice &amp; Purchase Order</h3>
            <div className="inputGroup">
              <label>
                Invoice No:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    AppSetting.INVOICE_ID_URL +
                    modalData?.invoiceId
                  }
                >
                  {modalData?.invoiceId}
                </a>
              </label>
              <div
                className="customTbl scroll-y scroll-x fixedHeaderFooter"
                style={{ maxHeight: "calc(100vh - 220px)" }}
              >
                <table className="main-table fixedHeaderFooter">
                  <thead>
                    <tr>
                      <th className="text-left">EVENT</th>
                      <th className="text-right lowSeatCol">LOW SEAT</th>
                      <th className="text-right highSeatCol">HIGH SEAT</th>
                      <th className="text-right unitConstCol">UNIT COST</th>
                      <th className="text-right totalCostCol">TOTAL COST</th>
                      <th className="text-right qtyCol">QTY</th>
                      <th className="text-right unitSaleCol">UNIT SALE</th>
                      <th className="text-right totalSaleCol">TOTAL SALE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <a
                            className="eventNameVenue text-capitalize"
                            target="_blank"
                            rel="noreferrer"
                            href={
                              AppSetting.EVENT_URL +
                              modalData?.eventId
                            }
                          >
                            <strong>{modalData?.event}</strong>
                          </a>
                          <br />
                          {getCustomDate(
                            modalData?.eventDate,
                            "ddd, MMM DD, YYYY"
                          )}
                          <br />
                          {getCustomTime(
                            `${modalData?.eventTime}`,
                            "hh:mm A"
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="text-right">
                          {modalData?.seats?.split(",")[0]}
                        </div>
                      </td>
                      <td>
                        <div className="text-right">
                          {
                            modalData?.seats?.split(",")[
                              modalData?.seats?.split(",").length - 1
                            ]
                          }
                        </div>
                      </td>
                      <td>
                        <div className="text-right">
                          {FormatCurrencyUnit(
                            twoDecimalPlacesNumber(modalData?.unitCost),
                            modalData?.currencyCode
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="text-right">
                          {FormatCurrencyUnit(
                            twoDecimalPlacesNumber(modalData?.ticketCost),
                            modalData?.currencyCode
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="text-right">{modalData?.quantity}</div>
                      </td>
                      <td>
                        <div className="text-right">
                          {FormatCurrencyUnit(
                            twoDecimalPlacesNumber(modalData?.unitSalePrice),
                            modalData?.currencyCode
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="text-right">
                          {FormatCurrencyUnit(
                            twoDecimalPlacesNumber(modalData?.ticketSales),
                            modalData?.currencyCode
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="inputGroup">
              <label>Invoice Credit Note</label>
              <div
                className="customTbl scroll-y scroll-x fixedHeaderFooter"
                style={{ maxHeight: "calc(100vh - 220px)" }}
              >
                <table className="main-table fixedHeaderFooter">
                  <thead>
                    <tr>
                      <th className="text-left">INVOICE ID</th>
                      <th className="text-left">INVOICE LINE ID</th>
                      <th className="text-left descCol">DESCRIPTION</th>
                      <th className="text-right qtyCol">QTY</th>
                      <th className="text-right">UNIT COST</th>
                      <th className="text-right">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <a
                            className="eventNameVenue"
                            target="_blank"
                            rel="noreferrer"
                            href={
                              AppSetting.INVOICE_ID_URL +
                              modalData?.invoiceId
                            }
                          >
                            <strong>{modalData?.invoiceId}</strong>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div>
                          <strong>{modalData?.invLineId}</strong>
                        </div>
                      </td>
                      <td>
                        <div className="">
                          <TextArea
                            className="desc-textarea"
                            defaultValue={defaultInvoiceCreditNote}
                            onChange={(e)=>setInvoiceCreditNote(e.target.value)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="text-right">{modalData.quantity}</div>
                      </td>
                      <td>
                        <div className="text-right">{FormatCurrencyUnit(twoDecimalPlacesNumber(0),modalData?.currencyCode)}</div>
                      </td>
                      <td>
                        <div className="text-right">{FormatCurrencyUnit(twoDecimalPlacesNumber(0),modalData?.currencyCode)}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="inputGroup">
              <label>PO Credit Note</label>
              <div
                className="customTbl scroll-y scroll-x fixedHeaderFooter"
                style={{ maxHeight: "calc(100vh - 220px)" }}
              >
                <table className="main-table fixedHeaderFooter">
                  <thead>
                    <tr>
                      <th className="text-left">PO#</th>
                      <th className="text-left">PURCHASE LINE ID</th>
                      <th className="text-left descCol">DESCRIPTION</th>
                      <th className="text-right qtyCol">QTY</th>
                      <th className="text-right">UNIT COST</th>
                      <th className="text-right">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <a
                            className="eventNameVenue"
                            target="_blank"
                            rel="noreferrer"
                            href={
                              AppSetting.PURCHASE_ID_URL +
                              modalData?.purchaseId
                            }
                          >
                            <strong>{modalData?.purchaseId}</strong>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div>
                          <strong>{modalData?.poLineId}</strong>
                        </div>
                      </td>
                      <td>
                        <div className="">
                          <TextArea
                            className="desc-textarea"
                            defaultValue={defaultPoCreditNote}
                            onChange={(e)=>setPoCreditNote(e.target.value)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="text-right">{modalData?.quantity}</div>
                      </td>
                      <td>
                        <div className="text-right">{FormatCurrencyUnit(twoDecimalPlacesNumber(0),modalData?.currencyCode)}</div>
                      </td>
                      <td>
                        <div className="text-right">{FormatCurrencyUnit(twoDecimalPlacesNumber(0),modalData?.currencyCode)}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modalCustomFooter">
            <Link
              to="#/"
              className="discardBtn"
              onClick={(e) => cancelHandler(e)}
            >
              CANCEL
            </Link>
            <Button className="btnOk" onClick={confirmHandler}>CONFIRM CANCELLATION</Button>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CancelInvoiceModal;
