import LoginLogo from '../../assets/images/logo-white.svg';


const LoginPage: React.FC = () => {

  return (
    <div className="loginBg">
        <div className="loginContainer">
          <div className="logo-login"><img src={LoginLogo} alt="Ticket Bash" title="Ticket Bash" /></div>
          <h3>Welcome Back!</h3>
          <div className="loginFormWrap">
            <div className="inputWrap">
              <input type="email" placeholder="Email" />
            </div>
            <div className="inputWrap">
              <span className="pwdShowHide pwdHide" />
              <input type="password" placeholder="Password" />
            </div>
            <div className="inputWrap btnWrap">
              <button type="button" className="btnStyle1">Sign in to Ticketbash</button>
            </div>
            <p><a href="#/" className="forgotPassLink">Forgot Password?</a></p>
          </div>
        </div>
      </div>
  );
}

export default LoginPage;