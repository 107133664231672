import React, { useState } from "react";
import { Alert, Modal, Typography } from "antd";


const BulkPlacementErrorMessageModal: React.FC<{
    errors: string[];
  }> = ({ errors }) => {
    const [showErrors, setShowErrors] = useState(false);
    return (
      <React.Fragment>
        <br />
        <span className="errorSuccessIcon">
          <i className="da icon-cross-circle"></i> 
          <Typography.Link onClick={()=>setShowErrors(!showErrors)} className="viewerror">
            <Typography.Text type="danger">View Error(s)</Typography.Text>
          </Typography.Link>
        </span>
        <Modal
          title={<div className="modalTitle"><i className="da icon-exclamation-circle"></i> Error(s)</div>}
          visible={showErrors}
          footer={false}
          closable={true}
          keyboard={true}
          className="customModal forError"
          closeIcon={<i className="da icon-close"></i>}
          width={500}
          centered
          onCancel={()=>setShowErrors(false)}
        >
          <div>
            {errors.map(eachError=>{
              return <Alert key={eachError} message={eachError} type="error" showIcon={false} />
            })}
            <div className="modalFooter" style={{marginTop: 0}}></div>
          </div>
        
        </Modal>
      </React.Fragment>
    )
};

export default BulkPlacementErrorMessageModal;