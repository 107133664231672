import { RoutesEnum } from "models/enums";
import { IActiveZoneListingData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { activeZoneListingType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class ActiveZoneListingService {

    public getActiveZoneListings = async (activeZoneListingReq: activeZoneListingType) => {
        return axiosClient.get<
            IApiBaseResponse<IActiveZoneListingData>
        >(RoutesEnum.ACTIVE_ZONE_LISTING, {
            params: activeZoneListingReq
        });
    }; 

}

export { ActiveZoneListingService };