import { Typography } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { FixedColumnDirection } from "models/enums/table";
import React from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip, formatAccountingValues, formatNumericValues } from "utils/commonFunction";
import { formatDateGivenFormatString, getCustomDate } from "utils/formatTimePeriod";
import CancelInvoice from "./Actions/CancelInvoice";

const commonWidth = 100;

export const unfilledZonesHeaders = [
  {
    title: "",
    dataIndex: "invoice_line_id",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    dataRender: (_data: number, allData: any) => (
      <RBAC
        allowedPermissions={[
          ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_CANCEL_INVOICE,
        ]}
      >
        {!allData.data.singleData.is_invoice_cancel_in_progress &&
          !allData.data.singleData.is_purchase_cancel_in_progress && (
            <CancelInvoice
              purchase_line_id={allData.data.singleData.purchase_line_id}
              invoice_line_id={allData.data.singleData.invoice_line_id}
              initModalData={allData.data.singleData}
            />
          )
        }
        {allData.data.singleData.is_invoice_cancel_in_progress ||
          allData.data.singleData.is_purchase_cancel_in_progress && (
            <span>Cancel Pending</span>
          )
        }
      </RBAC>
    ),
  },
  {
    title: "P&L Date",
    dataIndex: "pl_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (plDate: string) =>
      plDate && getCustomDate(plDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Line ID",
    dataIndex: "invoice_line_id",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
  },
  {
    title: "Invoice Line Date",
    dataIndex: "invoice_line_date",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceLineDate: string) =>
      invoiceLineDate && getCustomDate(invoiceLineDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Purchase Date",
    dataIndex: "purchase_date",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseDate: string) =>
      purchaseDate && getCustomDate(purchaseDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currency_code",
    canSort: true,
    width: commonWidth,
    dataRender: (currency: any) => <div className='text-upper'>{currency}</div>
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticket_cost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketCost: number) => (
      <div className={`text-right ${ticketCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketCost)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticket_sales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketSales: number) => (
      <div className={`text-right ${ticketSales < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketSales)}
      </div>
    ),
  },
  {
    title: "P & L (API)",
    dataIndex: "p&l",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (plApi: number) => (
      <div className={`text-right ${plApi < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(plApi)}
      </div>
    ),
  },
  {
    title: "Profit (Loss)",
    dataIndex: "p&l_calculated",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (profitLossCalculated: number, all: any) => {
      const allData = all.data.singleData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div
          className={`text-right ${
            profitLossCalculated < 0 ? "text-danger" : ""
          }`}
        >
          <span className={`${redTag}`}>
            {formatAccountingValues(profitLossCalculated)}
          </span>
        </div>
      );
    },
  },
  {
    title: "Gross Profit",
    dataIndex: "margin_after_fees",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (grossProfit: number) => (
      <div className={`text-right ${grossProfit < 0 ? "text-danger" : ""}`}>
        <b>{formatAccountingValues(grossProfit)}</b>
      </div>
    ),
  },
  {
    title: "External Reference",
    dataIndex: "external_ref",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (customer: any) =>
      (customer && customer.length) >= 18 ? addToolTip(customer,"right", "default", 18, false, {textTransform: "capitalize"}) : <div >{customer}</div>,
  },
  {
    title: "Purchase ID",
    dataIndex: "purchase_id",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number[]) =>
      purchaseId &&
      purchaseId.length &&
      purchaseId.map((id, index) => {
        return (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + id}
              underline={true}
            >
              {`${id}`}
            </Typography.Link>
            {`${index < purchaseId.length - 1 ? ", " : ""}`}
          </React.Fragment>
        );
      }),
  },
  {
    title: "PO Line ID",
    dataIndex: "purchase_line_id",
    canSort: true,
    width: commonWidth,
    dataRender: (poLineId: number[]) => poLineId && poLineId.length && poLineId.join(', ')
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (vendor: any) =>
      (vendor && vendor.length) >= 18 ? addToolTip(vendor,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{vendor}</div>,
  },
  {
    title: "Event",
    dataIndex: "event",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={
          AppSetting.EVENT_URL +
          otherData.data.singleData.eventId
        }
        className='text-capitalize'
      >
        {event?.length >= 40 ? addToolTip(event,"right", "pointer", 40, false, {textTransform: "capitalize"}): event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "event_date_time",
    canSort: true,
    width: commonWidth,
    dataRender: (eventDate: string) =>
      eventDate && formatDateGivenFormatString(eventDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Venue",
    dataIndex: "venue",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (venue: any) =>
      (venue && venue.length) >= 18 ? addToolTip(venue,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{venue}</div>,
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (section: any) =>
      (section && section.length) >= 18 ? addToolTip(section,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{section}</div>,
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-upper',
    dataRender: (row: any) => <div>{row}</div>,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    width: commonWidth,
    dataRender: (seats: any) =>
      (seats && seats.length) > 15 ? addToolTip(seats) : <div>{seats}</div>,
  },
  {
    title: "Internal Notes",
    dataIndex: "internal_notes",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillment_status",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentStatus: any) => <div className='text-capitalize'>{fulfillmentStatus}</div>
  },
  {
    title: "Fulfillment Date",
    dataIndex: "fulfillment_date",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentDate: string) =>
      fulfillmentDate && getCustomDate(fulfillmentDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventory_tags",
    canSort: true,
    width: commonWidth,
    dataRender: (inventoryTags: any) => <div>{inventoryTags}</div>
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoice_tags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Invoice Payment Method",
    dataIndex: "Payment_type",
    canSort: true,
    width: commonWidth,
    dataRender: (invoicePaymentMethod: any) => <div className='text-capitalize'>{invoicePaymentMethod}</div>
  },
];