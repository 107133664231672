import React from "react";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { Badge, Button, Tag, Tooltip, Typography } from "antd";
import { IEachEvent } from "models/interfaces";
import {
  addToolTip,
  checkValidUrl,
  daysToEventColorCheck,
  getEncodedData,
  getSeatAvailabilityDetails,
  getUserName
} from "utils/commonFunction";
import { Link } from "react-router-dom";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { RBAC, useRbac } from "features/rbac/rbac";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { AppSetting } from "utils/appSettings";
import EventQuickUpdate from "views/Zones/Actions/EditEventComponents/EventQuickUpdate/EventQuickUpdate";



const commonWidth = 150;

export const showBrokerOrSeatMapCreatorCheckerLister = (
  brokersOrLister: string[]
) => {
  if (brokersOrLister && brokersOrLister.length) {
    return addToolTip(brokersOrLister, "top", "default", 15, true);
  }
  return <span>Unassigned</span>;
};

const renderCheckMark = (data: number | null) => {
  if (data) {
    if (data === 1) {
      return (
        <div>
          <i className="da icon-warning" />
        </div>
      );
    } else {
      return (
        <div>
          <i className="da icon-tick-circle" />
        </div>
      );
    }
  }
};

const RenderActiveListing = (
  data: number,
  all: { data: { singleData: IEachEvent } }
) => {
  const { hasPermissions } = useRbac();
  return (
    <div className="text-right activeListingData">
      {data ? (
        hasPermissions([
          ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW
        ]) ? (
          <Link
            to={`${
              LeftNavRoutesEnum.ZONE_LISTINGS_ACTIVE_LISTINGS
            }?event=${getEncodedData({
              event_id: all.data.singleData.event_id
            })}`}
            target={"_blank"}
          >
            {data}
          </Link>
        ) : (
          <span>{data}</span>
        )
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

const getDaysToEventHeaderJsx = () => {
  return (
    <React.Fragment>
      <span>DAYS TO EVENT</span>&nbsp;
      <Tooltip
        title={
          <p>
            <span><strong style={{textDecoration: "underline"}}>Reminder Frequency:</strong></span><br/>
            <div style={{marginLeft: "5px"}}>
              <span><strong>1 to 14 Days (Events with Active Broadcasted Listing) : </strong>Every 2 Days</span><br/>
              <span><strong>15 to 30 Days : </strong>Every 2 Days</span><br/>
              <span><strong>31 to 45 Days : </strong>Every 3 Days</span><br/>
              <span><strong>{">45 Days : "}</strong>Every 7 Days</span>
            </div>
          </p>
        }
      >
        <i className="da icon-info-fill" style={{marginLeft: "5px", verticalAlign: "-25px", width: "12px", height: "12px"}}></i>
      </Tooltip>
    </React.Fragment>
  );
};

export const eventsHeaders = [
  {
    title: "",
    dataIndex: "event_id",
    thClassName: "warningBg text-left seatAviaileLogicCol",
    dataRender: (_data: any, all: any) => {
      const availabilityDetails = getSeatAvailabilityDetails(
        all?.data.singleData.seat_availability_score
      );
      return (
        <Tooltip placement="right" title={availabilityDetails.title}>
          <div
            className={`seatAviaileLogicBar ${availabilityDetails.color} haveTooltip`}
            style={{ padding: "0" }}
          >
            &nbsp;
          </div>
        </Tooltip>
      );
    }
  },
  {
    title: "",
    dataIndex: "platform_broadcasted_active_listings",
    canSort: false,
    dataRender: (data: number, all: { data: { singleData: IEachEvent } }) =>{
      return (
        <div>
          {data > 0 &&
            <EventQuickUpdate eventId={all.data.singleData.event_id} platformBroadcastedActiveListingsCount={data} />
          }
        </div>
      )
    },
    allowedPermissions: [
      ERbacPermissions.EVENT_QUICK_UPDATE
    ]
  },
  {
    title: "LAST UPDATED",
    dataIndex: "updated_on",
    canSort: true,
    canSelectRow: true,
    width: 95,
    thClassName: "text-left lastUpdateCol",
    dataRender: (_data: any, all: any) => {
      const lastUpdatedData = JSON.parse(all?.data.singleData.updated_by),
        userName = getUserName(lastUpdatedData);

      if (lastUpdatedData.compare_date > 1) {
        return (
          <div className="lastUpdatedtext">{lastUpdatedData.compare_date} days ago by {userName}</div>
        );
      } else if (lastUpdatedData.compare_date === 1) {
        return (
          <div className="lastUpdatedtext">{`${lastUpdatedData.compare_date} day ago by `}{userName}</div>
        );
      } else if (lastUpdatedData.compare_date === 0) {
        return <div className="lastUpdatedtext">Today by {userName}</div>;
      }
      return <></>;
    }
  },
  {
    title: "EVENT NAME & VENUE",
    dataIndex: "event_name",
    canSort: true,
    canSelectRow: true,
    width: 300,
    thClassName: "text-left eventNameCol",
    dataRender: (data: string, all: { data: { singleData: IEachEvent } }) => {
      return (
        <div className="text-capitalize">
          <a
            target={"_blank"}
            className="eventNameVenue"
            rel="noopener noreferrer"
            href={AppSetting.EVENT_URL + all.data.singleData.event_id}
          >
            <strong>{data}</strong>
          </a>
          &nbsp;
          {all.data.singleData.is_new_event && (
            <Tag className="success tagSmall">New</Tag>
          )}
          {all.data.singleData.is_sold_out && (
            <Tag className="danger tagSmall">Sold Out</Tag>
          )}
          {all.data.singleData.is_event_cancelled && (
            <Tag className="danger tagSmall">Cancelled</Tag>
          )}
          {all.data.singleData.event_id < 0 && (
            <Tag className="danger tagSmall">Not on POS</Tag>
          )}
          {all.data.singleData.seat_intelligence_id === 3 && all.data.singleData.is_verification_pending && (
            <Tag className="warning tagSmall">Structure Verification Pending</Tag>
          )}
          <br />
          {all?.data?.singleData?.venue_name}:{" "}
          {all?.data?.singleData?.venue_location}
        </div>
      );
    }
  },
  {
    title: "NOTES",
    dataIndex: "event_notes",
    canSort: false,
    canSelectRow: true,
    width: commonWidth,
    dataRender: (eventNotes: any) =>
      (eventNotes && eventNotes.length) >= 18 ? (
        addToolTip(
          eventNotes,
          "rightTop",
          "default",
          18,
          false,
          { textAlign: "left" },
          { minWidth: `${eventNotes.length / 2}px` }
        )
      ) : (
        <div>{eventNotes}</div>
      )
  },
  {
    title: "DATE ADDED",
    dataIndex: "date_added",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left dateAddedCol",
    dataRender: (data: string | null) =>
      data && formatDateGivenFormatString(data, "MMM DD, YYYY")
  },
  {
    title: getDaysToEventHeaderJsx(),
    dataIndex: "days_to_event",
    canSort: true,
    canSelectRow: true,
    width: 120,
    thClassName: "text-right dayToEventCol",
    dataRender: (data: number) => (
      <Tooltip
        placement="left"
        title={daysToEventColorCheck(data, "list").toolTipText}
      >
        <div
          className={
            "daysToEvent daysToEventData " +
            daysToEventColorCheck(data, "list").className
          }
        >
          <span style={{ cursor: "default" }}>{data}</span>
        </div>
      </Tooltip>
    )
  },
  {
    title: "DATE & TIME",
    dataIndex: "event_date",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left dateTimeCol",
    dataRender: (data: string) =>
      formatDateGivenFormatString(data, "ddd, MMM DD, YYYY h:mm A")
  },
  {
    title: "Broker",
    dataIndex: "broker_name",
    canSort: false,
    canSelectRow: true,
    thClassName: "text-left brokerCol",
    dataRender: (data: string[]) =>
      showBrokerOrSeatMapCreatorCheckerLister(data)
  },
  {
    title: "Lister",
    dataIndex: "lister_name",
    canSort: false,
    canSelectRow: true,
    thClassName: "text-left listerCol",
    dataRender: (data: string[]) =>
      showBrokerOrSeatMapCreatorCheckerLister(data)
  },
  {
    title: "Checker",
    dataIndex: "checker_name",
    canSort: false,
    canSelectRow: true,
    thClassName: "text-left listerCol",
    dataRender: (data: string[]) =>
      showBrokerOrSeatMapCreatorCheckerLister(data)
  },
  {
    title: "Seat Map Creator",
    dataIndex: "seat_map_creator_name",
    canSort: false,
    canSelectRow: true,
    thClassName: "text-left brokerCol",
    dataRender: (data: string[]) =>
      showBrokerOrSeatMapCreatorCheckerLister(data)
  },
  {
    title: "Structure Creation",
    dataIndex: "has_seat_structure",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left",
    tdClassName: "text-center",
    dataRender: renderCheckMark
  },
  {
    title: "Availability/ Cost Info",
    dataIndex: "has_cost_and_availability",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left",
    tdClassName: "text-center",
    dataRender: renderCheckMark
  },
  {
    title: "ACTIVE LISTINGS",
    dataIndex: "active_listings",
    thClassName: "text-right activeListingCol",
    canSort: true,
    canSelectRow: true,
    dataRender: (data: number, all: { data: { singleData: IEachEvent } }) => (
      RenderActiveListing(data, all)
    )
  },
  {
    title: "SUCCESSFULLY SCRAPED ON",
    dataIndex: "scraped_succeed_on",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left dateTimeCol",
    dataRender: (data: string | null, all: { data: { singleData: IEachEvent }}) =>
    {
      return (
        <React.Fragment>
          {
            all.data.singleData.is_from_desktop_app ? 
            <div>{all.data.singleData.scraped_succeed_on_desktop ? formatDateGivenFormatString(all.data.singleData.scraped_succeed_on_desktop, "ddd, MMM DD, YYYY h:mm A") : 'Never'}</div>
            :
            (all.data.singleData.seat_intelligence_id === 1 && 
            <div>
              {data ? formatDateGivenFormatString(data, "ddd, MMM DD, YYYY h:mm A") : 'Never'}
            </div>)
          }
        </React.Fragment>
      )
    }
  },
  {
    title: "LAST ATTEMPTED",
    dataIndex: "last_attempted_on",
    canSort: true,
    canSelectRow: true,
    thClassName: "text-left dateTimeCol",
    dataRender: (data: string | null, all: { data: { singleData: IEachEvent }}) => {
      return (
        <React.Fragment>
        {
        all.data.singleData.is_from_desktop_app ? 
        <div>{
          all.data.singleData.is_desktop_final_success ? 
          <div>
            {all.data.singleData.scraped_succeed_on_desktop ? formatDateGivenFormatString(all.data.singleData.scraped_succeed_on_desktop, "ddd, MMM DD, YYYY h:mm A") : 'Never'}
            {all.data.singleData.scraped_succeed_on_desktop &&
              <span>
              &nbsp;&nbsp;
                  <i className="da icon-desktop-app-success"></i>
              </span>}
          </div>:<div>
            {all.data.singleData.scraped_failed_on_desktop ? formatDateGivenFormatString(all.data.singleData.scraped_failed_on_desktop, "ddd, MMM DD, YYYY h:mm A") : 'Never'}
            {all.data.singleData.scraped_failed_on_desktop &&
              <span>
              &nbsp;&nbsp;
                  <i className="da icon-desktop-app-failed"></i>
              </span>}
          </div>
        }</div>
        :
        (all.data.singleData.seat_intelligence_id === 1 && 
          <div>
              {data ? formatDateGivenFormatString(data, "ddd, MMM DD, YYYY h:mm A") : 'Never'}
              {data &&
                <span>
                &nbsp;&nbsp;
                  {all.data.singleData.last_scraped_status === true &&
                    <i className="da icon-fictionalCharacter-green"></i>
                  }
                  {all.data.singleData.last_scraped_status === false &&
                    <i className="da icon-fictionalCharacter-red"></i>
                  }
                </span>
              }
            </div>)
          }
        </React.Fragment>
      )
    }
      
  },
  {
    title: "",
    dataIndex: "event_id",
    thClassName: "text-right actionCol",
    width: commonWidth,
    dataRender: (data: number, all: { data: { singleData: IEachEvent } }) => (
      <div className="text-right">
        <div className="tblActions">
          <Tooltip title="View Seating Info">
            <Typography.Link
              disabled={all.data.singleData.seat_map_url && checkValidUrl(all.data.singleData.seat_map_url) ? false : true}
              onClick={() =>
                window.open(all.data.singleData.seat_map_url, "_blank")
              }
            >
              <i className="da icon-th" />
            </Typography.Link>
          </Tooltip>
          <RBAC allowedPermissions={[ERbacPermissions.EVENT_METADATA_EDIT]}>
            <Tooltip title="Edit Event Metadata">
              <Link
                to={`${
                  LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY
                }?event=${getEncodedData({
                  event_id: data
                })}`}
                target={"_blank"}
              >
                <i className="da icon-pencil-o" />
              </Link>
            </Tooltip>
          </RBAC>
          <Tooltip title="Notifications">
            <Badge count={0} size={"small"} color="#9F2533">
              <Typography.Link disabled className="cardNotificationIcon">
                <i className="da icon-comment"></i>
              </Typography.Link>
            </Badge>
          </Tooltip>
        </div>
      </div>
    )
  },
  {
    title: "NEW LISTING",
    dataIndex: "event_id",
    thClassName: "text-left newListingCOl",
    allowedPermissions: [ERbacPermissions.EVENT_CREATE_NEW_LISTING],
    dataRender: (data: number, all: { data: { singleData: IEachEvent } }) => {
      const ifBrokerAvailable =
        all.data.singleData.broker_name.length <= 0 ? false : true;

      return (
        <RBAC allowedPermissions={[ERbacPermissions.EVENT_CREATE_NEW_LISTING]}>
          {ifBrokerAvailable &&
            all.data.singleData.has_seat_structure !== 0 &&
            all.data.singleData.has_cost_and_availability && 
            all.data.singleData.event_id > 0 && (
              <Tooltip title={"Create New Listing"} placement={"left"}>
                <Link
                  to={`${
                    LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING
                  }?event=${getEncodedData({
                    event_id: data
                  })}`}
                  target={"_blank"}
                >
                  <Button className="createListing">
                    <i className="da icon-add-layer " />
                  </Button>
                </Link>
              </Tooltip>
            )}
          {(!ifBrokerAvailable ||
            all.data.singleData.has_seat_structure === 0 ||
            !all.data.singleData.has_cost_and_availability ||
            all.data.singleData.event_id < 0) && (
            <Tooltip
              title={
                all.data.singleData.event_id < 0
                  ? "Not on POS"
                  : "Venue must be associated with a broker and have seat structure and cost info"
              }
              placement={"left"}
            >
              <Button className="createListing" disabled>
                <i className="da icon-add-layer" />
              </Button>
            </Tooltip>
          )}
        </RBAC>
      );
    }
  }
];
