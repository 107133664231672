import React from "react";
import { Tooltip } from "antd";
import { IEventWithActiveZoneListings } from "models/interfaces";
import {
  addToolTip,
  daysToEventColorCheck,
  getEncodedData,
  getSeatAvailabilityDetails,
  getUserName
} from "utils/commonFunction";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { AppSetting } from "utils/appSettings";
import { Link } from "react-router-dom";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import EventCardActions from "utils/sharedComponents/EventCardActions";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

export const showBrokerOrLister = (brokersOrLister: string[]) => {
  if (brokersOrLister && brokersOrLister.length) {
    return addToolTip(brokersOrLister,"right", "default", 15, true);
  }
  return <span>Unassigned</span>;
};

const ListingEventCard: React.FC<{
  eachEventData: IEventWithActiveZoneListings;
}> = ({ eachEventData }) => {
  const { hasPermissions } = useRbac();
  
  const getLastUpdatedBy = (updatedBy: string) => {
    const lastUpdatedData = JSON.parse(updatedBy)

    if( lastUpdatedData.compare_date === null ) {
      return <></>;
    }

    let userName;
    if( ( eachEventData.is_from_desktop_app && eachEventData.is_desktop_final_success ) || ( eachEventData.is_web_final_success === true ) || ( eachEventData.scraping_status_id === 2 ) ) {
      userName =  getUserName(lastUpdatedData)
    }

    if(userName){
      if (lastUpdatedData.compare_date > 1) {
        return (
          <>
            Last updated {lastUpdatedData.compare_date} days ago by {userName}
          </>
        );
      } else if (lastUpdatedData.compare_date === 1) {
        return (
          <>
            Last updated {lastUpdatedData.compare_date} day ago by {userName}
          </>
        );
      } else if (lastUpdatedData.compare_date === 0) {
        return <>Last updated today by {userName}</>;
      }
    }

    return <></>;
  };

  const setBrokerOrListerName = (type: string) => {
    if (
      eachEventData &&
      eachEventData[type]?.length &&
      eachEventData[type].toString() !== " "
    ) {
      return eachEventData[type]?.toString().length > 10 ?
        addToolTip(eachEventData[type].toString()) :
        eachEventData[type].toString();
    }
    else {
      return "Unknown"
    }
  }
  
  const getSeatAvailabilityTopBar = (seat_availability_score: string) => {
    const availabilityDetails = getSeatAvailabilityDetails(
      seat_availability_score
    );
  
    return (
      <Tooltip placement="top" title={availabilityDetails.title}>
        <div
          className={`seatAvailableLogicTopBar ${availabilityDetails.color}`}
          style={{ padding: "0" }}
        ></div>
      </Tooltip>
    );
  };

  return (
    <div className="eventCardWrap">
      <div className="eventCardContent">
      {eachEventData && getSeatAvailabilityTopBar(eachEventData.seat_availability_score)}
        <div className="lastUpdated">
          <span> {getLastUpdatedBy(eachEventData.updated_by)} </span>
        </div>
        <div className="eventCardBody">
          <div className="eventCardBodyInnerGrid">
            <div>
              <a
                className="zoneEventName text-capitalize"
                href={AppSetting.EVENT_URL + eachEventData?.event_id}
                target="_blank"
                rel="noreferrer"
              >
                {eachEventData?.event_name.length > 25
                  ? addToolTip(
                      eachEventData?.event_name,
                      "right",
                      "pointer",
                      25,
                      false,
                      { textTransform: "capitalize" }
                    )
                  : eachEventData?.event_name}
              </a>
              <div>
                {eachEventData
                  ? `${formatDateGivenFormatString(
                      eachEventData.event_date.toString(),
                      "ddd, MMM DD, YYYY"
                    )} at ${formatDateGivenFormatString(
                      eachEventData.event_date.toString(),
                      "h:mm A"
                    )}`
                  : ""}
              </div>
            </div>
            <EventCardActions eachEventData={eachEventData} />
          </div>
          <p>
            {eachEventData?.venue_name.length > 25
              ? addToolTip(
                  eachEventData?.venue_name,
                  "right",
                  "default",
                  25,
                  false,
                  { textTransform: "capitalize" }
                )
              : eachEventData?.venue_name}{" "}
            <br />
            {eachEventData?.venue_location}
          </p>
          <div className="ranking">
            <div
              className={`rankingInner ${
                eachEventData?.days_to_event !== undefined &&
                daysToEventColorCheck(eachEventData.days_to_event, "grid")
                  .className
              }`}
            >
              {eachEventData?.days_to_event !== undefined && (
                <Tooltip
                  placement="top"
                  title={
                    daysToEventColorCheck(eachEventData.days_to_event, "grid")
                      .toolTipText
                  }
                >
                  <span className="rankNumber">
                    {eachEventData.days_to_event}
                  </span>
                </Tooltip>
              )}
              <div>
                Days <br />
                to event
              </div>
            </div>
            <div className="rankingInner default">
              <span className="rankNumber">
                {eachEventData.active_listings}
              </span>

              {hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW]) ?
                <Link
                to={`${
                  LeftNavRoutesEnum.ZONE_LISTINGS_ACTIVE_LISTINGS
                }?event=${getEncodedData({
                  event_id: eachEventData.event_id,
                })}`}
                target={"_blank"}
              >
                Active <br /> 
                Listings
              </Link>
              : 
              <div>
                Active <br />Listings
              </div>
              }
              
            </div>
            <RBAC allowedPermissions={[ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW]}>
            <div className="rankingInner positive">
              {eachEventData?.pending_fills !== undefined &&
                eachEventData?.pending_fills > 0 && (
                  <React.Fragment>
                    <span className="rankNumber">
                      {eachEventData?.pending_fills}
                    </span>
                    <Link
                      to={`${
                        LeftNavRoutesEnum.ZONES_PENDING_FILLS
                      }?search=${getEncodedData({
                        event_id: eachEventData.event_id,
                        invoice_date: eachEventData.invoice_date,
                      })}`}
                      target={"_blank"}
                    >
                      Pending
                      <br />
                      Fills
                    </Link>
                  </React.Fragment>
                )}
            </div>
            </RBAC>
            <div className="rankingInner default negative">
              {eachEventData?.pricing_issue !== undefined &&
                eachEventData?.pricing_issue > 0 && (
                  <React.Fragment>
                    <span className="rankNumber">
                      {eachEventData?.pricing_issue}
                    </span>
                    <div className="noHyperlink">
                      Pricing
                      <br />
                      Issue
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="eventCardFooter">
        <div>
          <Tooltip title="Broker">
            <i className="da icon-user-o"></i>
          </Tooltip>
          <span>
            {setBrokerOrListerName("broker_name")}
          </span>
        </div>
        <div>
          <Tooltip title="Lister">
            <i className="da icon-user-role"></i>
          </Tooltip>
          <span>
            {setBrokerOrListerName("lister_name")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListingEventCard;
