import React, { useState, useEffect } from "react";
import { Col, Layout, Row } from "antd";
import ReportLayout from "../../ReportLayout";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { IReportQueryString, IFilterOptions } from "models/interfaces";
import LongInventoryReportTable from "./LongInventoryReportTable";
import { useLocation } from "react-router-dom";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";
import CreateScheduler from "utils/commonScheduler/createScheduler";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { usePageTitle } from "hooks";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";

const LongInventory: React.FC = () => {

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.getRecipientFilterData());
  }, [dispatch]);
  usePageTitle("Long Inventory");
  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);
  const path = location.pathname
  const { hasPermissions } = useRbac();
  let reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report
  );

  if(path.endsWith('inventory-long')) {
    reportQueryString = {
      ...reportQueryString,
      current_report: 'Long Inventory Report',
      current_report_id: 1
    }
  }

  let filterQueryString;

  if (location.search.includes('filter'))
    filterQueryString = decodeQueryStringData(
      queryParams.filter
    );

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] = useState<any>(null)
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);


  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };
  const closeSearch = () => {
    setSearch("");
    setSearchQuery("");
  };

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xl={9} xxl={9}>
            <h2>{reportQueryString.current_report}</h2>
          </Col>
          <Col xl={9} xxl={7}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search by PO No, Event Name, Invoice Mapping ID, Internal Notes"
                  value={search ? search : ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xl={6} xxl={8}>
            <div className="rightFilter">
              <RBAC allowedPermissions={
                path.endsWith('inventory-long')
                  ?
                  [ERbacPermissions.REPORT_LONG_INVENTORY_SCHEDULE]
                  :
                  [ERbacPermissions.REPORT_LONG_INVENTORY_NOT_LISTED_SCHEDULE]
              }>
                <CreateScheduler
                  schedulerTitle={"Create Schedule"}
                  reportData={reportQueryString}
                  filterData={filterObject}
                />
              </RBAC>
              <FilterDrawer
                current_report_id={reportQueryString.current_report_id}
                showPODatePicker={false}
                showBrokerDropdown={hasPermissions(
                    [ERbacPermissions.FILTER_BROKER_TAGS_VIEW_ALL, ERbacPermissions.FILTER_BROKER_TAGS_VIEW_TEAM]
                )}
                onApplyFilter={filterChanged}
                onApplyFilterWithValues={(e: any) => setFilterAppliedWithValues(e)}
                showEventDatePicker={true}
                showBuyerDropdown={true}
                showEventDropdown={false}
                showVenueDropdown={false}
                showCardDropdown={true}
                showAlertDropdown={true}
                showInvoiceMappingId={true}
                defaultAndValues={{
                  preFilledValues: {
                    broker_Id: filterQueryString?.broker_Id,
                    alert_Id: filterQueryString?.alert_Id,
                    buyer_Id: filterQueryString?.buyer_Id,
                    event_date_from: filterQueryString?.event_date_from,
                  },
                }}
              />
            </div>
          </Col>
        </Row>

      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <LongInventoryReportTable filterObject={filterObject} appliedFilterValues={filtersAppliedWithValues} searchQuery={searchQuery} />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default LongInventory;
