import { RoutesEnum } from "models/enums";
import {
  IDeliveredTickets,
  IDeliveredTicketsQueryParams,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class DeliveredTicketsService {
  public getDeliveredTicketsReport = async (
    deliveredTicketsRequest: IDeliveredTicketsQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<IDeliveredTickets>>(
      RoutesEnum.DELIVERED_TICKETS,
      {
        params: deliveredTicketsRequest,
      }
    );
  };
}
