import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
// import barChart from "../../../../assets/images/bar-chart.svg";
import Events from './Widgets/ListedEventCount';
import Sections from "./Widgets/ListedSectionCount";
import Pricing from "./Widgets/MarginOfPricing";
import ConversionRate from "./Widgets/ConversionRate";
// import Sales from "./Widgets/AllSalesCount";
// import Listings from "./Widgets/AllListingsCount";
import ListedGACount from './Widgets/ListedGACount';



const ListersPerformanceAnalysis = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Listers</h3>
                    <section className="ant-layout mainInnerLayout">
                        <Events />
                    </section>
                    <section className="ant-layout mainInnerLayout">
                        <ListedGACount />
                    </section>
                    <section className="ant-layout mainInnerLayout"> 
                        <Sections />
                    </section>
                    <section className="ant-layout mainInnerLayout"> 
                        <Pricing />
                    </section>
                    <section className="ant-layout mainInnerLayout"> 
                        <ConversionRate />
                    </section>
                    {/* <Events barChart={barChart} />
                        <Sections barChart={barChart} />
                        <ListedGACount barChart={barChart} />
                        <Pricing barChart={barChart} />
                        <Rates barChart={barChart} />
                        <Sales barChart={barChart} />
                        <Listings barChart={barChart} /> */}
                </div>
            </Content>
        </Layout>
    )
}

export default ListersPerformanceAnalysis
