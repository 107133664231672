import { RoutesEnum } from "models/enums";
import { IBrokerData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class BrokerDetailsService {
  public getAllBrokers = async (queryParams: { current_report_id: number; }) => {
    return axiosClient.get<
      IApiBaseResponse<IBrokerData>
    >(RoutesEnum.BROKER_TAGS_BY_REPORT_ID, {params: {...queryParams}});
  };
}

export { BrokerDetailsService };
