import CustomError from "controls/CustomError/CustomError";
import { IFilterOptions, IPurchaseQueueMasterData, IPurchaseQueueMenuOption, ISwimlaneMaster } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useEffect, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import Swimlane from "./SwimlaneComponents/Swimlane";

const KanbanBoard:React.FC<{
  queueType: number;
  filterObject: IFilterOptions | null;
  reloadLane?: boolean;
  resetTimer?: Function;
}> = ({queueType, filterObject, reloadLane, resetTimer}) => {
  const cardHeight = queueType === 1 ? 240 : 215;
  const [showLoader, setShowLoader] = useState(true);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [swimLaneMaster, setSwimLaneMaster] = useState<
    ISwimlaneMaster[] | null
  >(null);
  const [requestedReloadLaneIds, setRequestedReloadLaneIds] = useState<
    number[] | null
  >(null);
  const [purchaseQueueMaster, setPurchaseQueueMaster] =
    useState<IPurchaseQueueMasterData | null>(null);
  
  const [kebabMenuOptions, setKebabMenuOptions] = useState<IPurchaseQueueMenuOption[] | null>(null);
  const [maxCardCount, setMaxCardCount] = useState(-1);
  const [heightOfDivider, setHeightOfDivider] = useState(514);

  const getHeightOfDivider = () => {
    if(maxCardCount !== 0) {
      return (maxCardCount*cardHeight)+48;
    } else {
      return 514;
    }
  }

  useEffect(()=>{
    setHeightOfDivider(getHeightOfDivider());
  },[maxCardCount]);

  const getMenuOptions = async() => {
    const res = await new PurchaseAndShippingService().getKebabMenuOptions();
    if(res.data.data) {
      setKebabMenuOptions(res.data.data.purchaseQueueMenuOptions);
    }
  };
  
  const getSwimLaneMaster = async () => {
    setDataFetchError(null);
    try {
      setShowLoader(true);
      const response = await new PurchaseAndShippingService().getSwimLaneMaster(queueType);

      setSwimLaneMaster(response.data.data.swimlaneMasterList);
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      setDataFetchError({ ...error, refreshAction: getSwimLaneMaster });
      new LoggerService().log({
        payload: error,
        function_name: "getSwimLaneMaster",
      });
    }
    if(resetTimer){
      setTimeout(()=>{
        resetTimer();
      }, 2000);
      
    };
  };

  const getPurchaseQueueMaster = async () => {
    setShowLoader(true);
    try {
      const res = await new PurchaseAndShippingService().getPurchaseQueueMasterData();
      setPurchaseQueueMaster(res.data.data);
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      setDataFetchError({ ...error, refreshAction: getPurchaseQueueMaster });
      new LoggerService().log({
        payload: error,
        function_name: "getPurchaseQueueMaster",
      });
    }
  };

  useEffect(() => {
    getMenuOptions();
    getSwimLaneMaster();
    getPurchaseQueueMaster();
  }, []);

  useEffect(() => {
    if(reloadLane){
      setSwimLaneMaster(null);
      getSwimLaneMaster();
    }
  }, [reloadLane])

  return (
    <LoadingSpinner isLoading={showLoader}>
      <div className="purchaseContainer">
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          <div className="rowWrap">
            {swimLaneMaster?.map((eachSwimLane) => {
              return (
                <Swimlane
                  key={eachSwimLane.id}
                  swimlaneData={eachSwimLane}
                  queueType={queueType}
                  requestedReloadLaneIds={requestedReloadLaneIds}
                  requestToReloadTheLaneIds={(laneIds: number[] | null) => {
                    setRequestedReloadLaneIds(laneIds);
                  }}
                  purchaseQueueMaster={purchaseQueueMaster}
                  kebabMenuOptions={kebabMenuOptions}
                  filterObject={filterObject}
                  setMaxCardCount={setMaxCardCount}
                  heightOfDivider={heightOfDivider}
                />
              );
            })}
          </div>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default KanbanBoard;
