import React, { useState, useEffect } from "react";
import { Col } from "antd";
import {
  IFilterOptions,
  IListerKPIsNewListingGraphData,
  IListerKPIsGraphQueryParams
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { LoggerService } from "sharedServices";
import {
  defaultParams,
  getUsableService,
  widgetClassPerCol
} from "../CommonFile/commonFile";
import moment from "moment";
import CustomError from "controls/CustomError/CustomError";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import KpisBaseBarGraph from "./KpisBaseBarGraph";
import KpisBaseStackedBarGraph from "./KpisBaseStackedBarGraph";

const DashboardKpis: React.FC<{
  filterObject: IFilterOptions | null;
  graphType: string;
  serviceType: string;
  apiName: string;
  label: string;
  cols: number;
  tooltipHeaders: string[];
  cId: number;
  typeOfData: string;
  seriesTitles?: string[];
}> = ({
  filterObject,
  graphType,
  serviceType,
  apiName,
  label,
  cols,
  tooltipHeaders,
  cId,
  typeOfData,
  seriesTitles
}) => {

    const [queryParams, setQueryParams] = useState<
      IListerKPIsGraphQueryParams | null
    >(null);

    const [newGraphData, setNewGraphData] = useState<
      IListerKPIsNewListingGraphData | null
    >(null);

    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);
    const [dateRange, setDateRange] = useState<number>(30)

    const [defaultQueryParams] = useState<IListerKPIsGraphQueryParams>({
      custom_date_from: null,
      custom_date_to: null,
      ...defaultParams
    });

    const getKPIsGraphData = async () => {
      try {
        setShowLoader(true);

        const response = await getUsableService(serviceType)[apiName]({
          ...queryParams,
        });
        setNewGraphData(response.data.data);

        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);

        await new LoggerService().log({
          payload: error,
          function_name: "getKPIsGraphData",
        });

        setDataFetchError({ ...error, refreshAction: getKPIsGraphData });
      }
    };

    useEffect(() => {
      if (queryParams) {
        setDateRange(moment(queryParams.custom_date_to).diff(moment(queryParams.custom_date_from), 'days') + 1);
        getKPIsGraphData();
      }
    }, [queryParams]);

    useEffect(() => {
      if (filterObject !== null && defaultQueryParams) {
        setQueryParams({
          ...defaultQueryParams,
          ...filterObject
        });
      }
    }, [defaultQueryParams, filterObject]);

    const getPercentage = (diff: number, total: number) => {
      if (total <= 0) {
        return '100.00';
      } else {
        return (getAbsolute(diff) / total * 100).toFixed(2);
      }
    }

    const getArrowClass = (diff: number) => {
      return diff >= 0 ? '' : 'negTrend negDown';
    }

    const getAbsolute = (diff: number) => {
      return Math.abs(diff);
    }

    const countFormat = (totalCount: number) => {
      return totalCount > 1 ?
        `${totalCount.toLocaleString('en-US')} ${typeOfData}s` :
        `${totalCount} ${typeOfData}`;
    }

    const getGraphListingCountsView = (totalCount: number) => {
      return !showLoader ? (
        <div className="titleLerge">
          {countFormat(totalCount)}
        </div>
      ) : (
        <div style={{ height: 35 }}></div>
      )
    }

    const getCountsRatioView = (listingData: IListerKPIsNewListingGraphData | null) => {
      return listingData?.is_count_diff_available &&
        ((listingData?.total_count ?? 0) > 0 || (listingData?.count_diff ?? 0) !== 0) ? (
        <div className="resultOther">
          <span
            className={
              `${getArrowClass(listingData?.count_diff ?? 0)}`
            }>
            {getAbsolute(listingData?.count_diff ?? 0).toLocaleString('en-US')}
          </span>
          <span
            className={
              `${getArrowClass(listingData?.count_diff ?? 0)}`
            }>
            {getPercentage(listingData?.count_diff ?? 0, listingData?.total_count ?? 0)}%
          </span>
        </div>
      ) : (
        <></>
      );
    }

    return (
      <Col className={widgetClassPerCol[cols]}>
        <div className="dashboardChartCard">
          {dataFetchError ? (
            <CustomError {...dataFetchError} />
          ) : (
            <div className="dashboardCard mainChartWidget" style={{ minHeight: 460 }}>
              <LoadingSpinner isLoading={showLoader}>
                <div className="chartTopbar">
                  <h5>{label}</h5>
                  <div className="graphDetail">
                    {getGraphListingCountsView(newGraphData?.total_count ?? 0)}
                    {getCountsRatioView(newGraphData)}
                  </div>
                </div>

                {graphType === "bar" && (
                  <KpisBaseBarGraph
                    data={newGraphData?.graph_data}
                    cId={cId}
                    tooltipHeader={tooltipHeaders[0]}
                    dateRange={dateRange}
                  />
                )}

                {graphType === "stackedBar" && (
                  <KpisBaseStackedBarGraph
                    data={newGraphData?.graph_data}
                    cId={cId}
                    tooltipHeaders={tooltipHeaders}
                    dateRange={dateRange}
                    seriesTitles={seriesTitles}
                  />
                )}
              </LoadingSpinner>
            </div>
          )}
        </div>
      </Col>
    )
  }

export default DashboardKpis;
