import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { GrossProfitsReport, ISoldInventoryIssueRes } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { GrossProfitService } from "services";
import { LoggerService, off, on } from "sharedServices";
import { addToolTip, twoDecimalPlacesNumber } from "utils/commonFunction";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { getCustomDate } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const SoldInventoryIssueResolution: React.FC<{
  queryParams: any;
  getInvoicesNeedingAttentionData: Function;
}> = ({ queryParams, getInvoicesNeedingAttentionData }) => {
  const [data, setData] = useState<GrossProfitsReport | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [issueResolution, setIssueResolution] =
    useState<ISoldInventoryIssueRes | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const actionHandler = (eData: CustomEvent) => {
      setData(eData.detail?.data);
      setModalVisible(true);
      setIssueResolution((prev) => ({
        ...prev,
        invoice_id: eData.detail?.data?.invoiceId,
        resolution_notes: eData.detail?.data?.resolution_notes,
        is_resolved: eData.detail?.data?.is_resolved,
      }));
    };
    on("pricingIssue:click", actionHandler);
    return () => {
      off("pricingIssue:click", actionHandler);
      setIssueResolution((prev) => ({
        ...prev,
        resolution_notes: "",
        is_resolved: undefined,
      }));
    };
  }, []);

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssueResolution((prev) => ({
      ...prev,
      resolution_notes: e.target.value,
    }));
  };

  const saveIssueResChanges = async () => {
    setShowLoader(true);
    try {
      if (issueResolution) {
        const res = await new GrossProfitService().savePricingResolution({
          ...issueResolution,
          is_resolved: !issueResolution.is_resolved,
        });
        if (res.data.data) {
          setModalVisible(false);
          getInvoicesNeedingAttentionData(queryParams);
        }
      }
      setShowLoader(false);
    } catch (error) {
      await new LoggerService().log({
        payload: error,
        function_name: "saveIssueResChanges",
      });
      setShowLoader(false);
    }
  };

  return (
    <Modal
      title={
        data && data?.is_resolved
          ? "Undo Cost and Pricing Issue Resolution "
          : "Resolve Cost and Pricing Issue "
      }
      visible={modalVisible}
      footer={false}
      closable={false}
      keyboard={true}
      className="customModal pricingIssueModal"
      width={720}
      centered
    >
      <LoadingSpinner isLoading={showLoader}>
        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-xl-12">
            <div className="inputGroup eventNameRow">
              <label className="mb0">Event Name:</label>
              <span className="text-capitalize">
                {data?.event &&
                  addToolTip(data?.event, "right", "default", 18, false, {
                    textTransform: "capitalize",
                  })}
              </span>
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-xl-12">
            <div className="inputGroup eventNameRow">
              <label className="mb0">Event Showtime:</label>
              <span>
                {data?.eventDate &&
                  getCustomDate(data.eventDate, "ddd, MMM DD, YYYY")}{" "}
                {data?.eventDate && "at"}{" "}
                {data?.eventDate && getCustomDate(data.eventDate, "hh:mm A")}
              </span>
            </div>
          </div>
        </div>
        <div className="ant-row">
        <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
            <div className="inputGroup text-capitalize eventNameRow">
              <label className="mb0">Quantity:</label>
              <span>{data?.quantity}</span>
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
            <div className="inputGroup text-capitalize eventNameRow">
              <label className="mb0">Unit Cost:</label>
              <span className={`${data?.unitCost && data?.unitCost< 0 && "text-danger"}`}>
                {data?.currencyCode &&
                  FormatCurrencyUnit(
                    twoDecimalPlacesNumber(data?.unitCost),
                    data?.currencyCode
                  )}
              </span>
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
            <div className="inputGroup text-capitalize eventNameRow">
              <label className="mb0">Unit Sale Price:</label>
              <span className={`${data?.unitTicketSales && data?.unitTicketSales< 0 && "text-danger"}`}>
                {data?.currencyCode &&
                  FormatCurrencyUnit(
                    twoDecimalPlacesNumber(data?.unitTicketSales),
                    data?.currencyCode
                  )}
              </span>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="inputGroup text-capitalize">
              <label className="mb0">Notes:</label>
              {data?.is_resolved ? (
                <span>{data?.resolution_notes}</span>
              ) : (
                <div>
                  <TextArea
                    value={issueResolution?.resolution_notes || ""}
                    autoSize={{ minRows: 3 }}
                    onChange={handleNotesChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modalFooter">
          <button
            type="button"
            className="linkBtn"
            onClick={() => {
              setModalVisible(false);
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="ant-btn btnSave"
            onClick={saveIssueResChanges}
          >
            {data && data?.is_resolved ? "Undo Resolution" : "Not an Issue"}
          </button>
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

export default SoldInventoryIssueResolution;
