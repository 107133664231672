import { Select, Tooltip } from "antd";
import { DayOfMonthEnum, DayOfWeekNamesEnum } from "models/enums/schedule";
import {
  IScheduleListResponse,
  ISchedulerDatum,
} from "models/interfaces/Scheduler/scheduler.model";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const { Option } = Select;

const DaySelector: React.FC<{
  existingScheduleData: IScheduleListResponse | null;
  setScheduleData: Function;
  scheduleData: ISchedulerDatum;
  setShowDateDropdown: Function;
  showDateDropdown: boolean;
  reset: boolean;
  setResetToDefaults: Function;
  daysOfWeek: number[];
  setDaysOfWeek: Function;
  daysOfMonth: number[];
  setDaysOfMonth: Function;
  dropdownData: any;
  selectedTags: number | number[];
  setSelectedTags: Function;
}> = ({
  existingScheduleData,
  setScheduleData,
  scheduleData,
  setShowDateDropdown,
  showDateDropdown,
  reset,
  setResetToDefaults,
  daysOfWeek,
  setDaysOfWeek,
  daysOfMonth,
  setDaysOfMonth,
  dropdownData,
  setSelectedTags,
}) => {
  const [displayString, setDisplayString] = useState<string>("daily ");
  const [tooltipText, setTooltipText] = useState<string>("");

  const resetSelectedTags = () => {
    switch (scheduleData.frequencyId) {
      case 1:
        setSelectedTags(1);
        break;
      case 2:
        setSelectedTags(
          existingScheduleData?.scheduleDetails.dayOfWeek
            ? existingScheduleData.scheduleDetails.dayOfWeek
            : daysOfWeek
        );
        break;
      case 3:
        setSelectedTags(
          existingScheduleData?.scheduleDetails.dayOfMonth
            ? existingScheduleData.scheduleDetails.dayOfMonth
            : daysOfMonth
        );
    }
  };

  const multipleDayText = "multiple days";

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          showDateDropdown
        ) {
          setShowDateDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, showDateDropdown]);
  }

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    scheduleData.frequencyId === 1 && setDaysOfWeek([1]);
    scheduleData.frequencyId === 1 && setDaysOfMonth([1]);
    scheduleData.frequencyId === 2 &&
      setDaysOfWeek(
        existingScheduleData
          ? existingScheduleData.scheduleDetails.dayOfWeek
          : [1]
      );
    scheduleData.frequencyId === 2 && setDaysOfMonth([]);
    scheduleData.frequencyId === 3 &&
      setDaysOfMonth(
        existingScheduleData
          ? existingScheduleData.scheduleDetails.dayOfMonth
          : [1]
      );
    scheduleData.frequencyId === 3 && setDaysOfWeek([]);
    existingScheduleData ? resetSelectedTags() : setSelectedTags(1);
    setResetToDefaults(false);
  }, [reset === true]);

  useEffect(() => {
    scheduleData.frequencyId === 1 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: null,
        dayOfWeek: null,
      });
    scheduleData.frequencyId === 2 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: null,
        dayOfWeek: existingScheduleData
          ? existingScheduleData.scheduleDetails.dayOfWeek
          : [1],
      });
    scheduleData.frequencyId === 3 &&
      setScheduleData({
        ...scheduleData,
        dayOfMonth: existingScheduleData
          ? existingScheduleData.scheduleDetails.dayOfMonth
          : [1],
        dayOfWeek: null,
      });
    setDaysOfMonth(existingScheduleData?.scheduleDetails.dayOfMonth ? existingScheduleData.scheduleDetails.dayOfMonth : []);
    setDaysOfWeek(existingScheduleData?.scheduleDetails.dayOfWeek ? existingScheduleData.scheduleDetails.dayOfWeek : []);
    setSelectedTags(1);
  }, [dropdownData, scheduleData.frequencyId]);

  useEffect(() => {
    
    if (
      scheduleData.frequencyId === 1 ||
      (daysOfMonth && daysOfMonth.length === 31) ||
      (daysOfWeek && daysOfWeek.length === 7)
    ) {
      setDisplayString("daily");
    } else if (scheduleData.frequencyId === 2) {
      daysOfWeek &&
        daysOfWeek.length === 1 &&
        setDisplayString(`${DayOfWeekNamesEnum[daysOfWeek[0]]}`);
      daysOfWeek && daysOfWeek.length > 1 && setDisplayString(multipleDayText);
      daysOfWeek && daysOfWeek.length === 0 && setDisplayString("Sunday");
    } else {
      daysOfMonth &&
        daysOfMonth.length === 1 &&
        setDisplayString(`${DayOfMonthEnum[daysOfMonth[0]]}`);
      daysOfMonth &&
        daysOfMonth.length === 1 &&
        daysOfMonth[0] === 31 &&
        setDisplayString(`${DayOfMonthEnum[daysOfMonth[0]].toLowerCase()}`);
      daysOfMonth &&
        daysOfMonth.length > 1 &&
        setDisplayString(multipleDayText);
      daysOfMonth && daysOfMonth.length === 0 && setDisplayString("1st");
    }
  }, [daysOfMonth, daysOfWeek, scheduleData.frequencyId, reset === true]);

  const onChangeHandler = (rawValue: any) => {
    
    let value: string = rawValue.toString();
    if (value) {
      const days = value
        .split(",")
        .map((eachDay) => parseInt(eachDay))
        .sort((firstEl, secondEl) => {
          return firstEl - secondEl;
        });
      if(scheduleData.frequencyId === 2) {
        setScheduleData((prev: ISchedulerDatum)=>({
          ...prev,
          dayOfMonth: null,
          dayOfWeek: days
        }))
        setDaysOfWeek(days);
        setSelectedTags(days);
        setTooltipText(days.map((el) => DayOfWeekNamesEnum[el]).join(", "));
      }
      else if(scheduleData.frequencyId === 3){
        setScheduleData((prev: ISchedulerDatum)=>({
          ...prev,
          dayOfMonth: days,
          dayOfWeek: null
        }))
        setDaysOfMonth(days);
        setSelectedTags(days);
        setTooltipText(days.map((el) => DayOfMonthEnum[el]).join(", "));
      }
      else {
        setDaysOfMonth(existingScheduleData?.scheduleDetails.dayOfMonth ? existingScheduleData.scheduleDetails.dayOfMonth : []);
        setDaysOfWeek(existingScheduleData?.scheduleDetails.dayOfWeek ? existingScheduleData.scheduleDetails.dayOfWeek : []);
      }
    }
  };

  useEffect(() => {
    setScheduleData({
      ...scheduleData,
      dayOfMonth: daysOfMonth && daysOfMonth.length > 0 ? daysOfMonth : null,
      dayOfWeek: daysOfWeek && daysOfWeek.length > 0 ? daysOfWeek : null,
    });
  }, [daysOfMonth, daysOfWeek]);

  let tooltipElement;
  if (displayString === multipleDayText)
    tooltipElement = (
      <Tooltip title={tooltipText}>
        <span>{displayString}</span>
      </Tooltip>
    );
  else tooltipElement = displayString;

  const getFrequency = (frequencyId: number) => {
    if (frequencyId === 2) {
      return daysOfWeek;
    } else if (frequencyId === 3) {
      return daysOfMonth;
    } else {
      return [1];
    }
  }

  return (
    <React.Fragment>
      <span className="customTooltipWrap daySelector">
        {scheduleData.frequencyId !== 1 ? (
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              setShowDateDropdown((prev: boolean) => !prev);
            }}
          >
            {tooltipElement}
          </Link>
        ) : (
          tooltipElement
        )}
        <div
          className="customTooltip"
          style={showDateDropdown ? { display: "block" } : { display: "none" }}
          id={existingScheduleData?.id.toString() + "daySelector"}
          ref={wrapperRef}
        >
          {dropdownData && dropdownData.length > 0 && (
            <Select
              allowClear
              open={showDateDropdown}
              mode={"multiple"}
              style={{ width: "150px" }}
              value={ getFrequency(scheduleData.frequencyId) }
              onChange={(value) => onChangeHandler(value)}
              maxTagCount={"responsive"}
              placeholder={displayString}
              getPopupContainer={() =>
                document.getElementById(
                  existingScheduleData?.id.toString() + "daySelector"
                ) as HTMLInputElement
              }
              onClear={() => {
                scheduleData.frequencyId === 2 && setDaysOfWeek([1]);
                scheduleData.frequencyId === 3 && setDaysOfMonth([1]);
                setSelectedTags(1);
              }}
              options={dropdownData}
            />
          )}
        </div>
      </span>
    </React.Fragment>
  );
};

export default DaySelector;
