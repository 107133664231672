import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import {
  IEventMetadata,
  IMaster,
  ISplitDatum,
  IZone,
  MetaDataZoneActiveListing,
} from "models/interfaces";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import {
  formatThousandSeparator,
  getEncodedData,
  getRandomValue,
  getTagClassForZones,
  twoDecimalPlacesNumber,
} from "utils/commonFunction";
import { arrayMoveImmutable } from "array-move";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import CopyDownMetadataModal from "./CopyDownMetadataModal";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import SplitListing from "views/Zones/ZoneListings/ActiveListingComponents/Actions/SplitListing";
import { useAppDispatch } from "app/hooks";
import { eventManagementActions } from "models/Slices/EventManagementSlice";
import BroadcastFromZoneAvailability from "./BroadcastFromZoneAvailability";
import SplitInventoryView from "views/Zones/ZoneListings/SplitInventoryView/SplitInventoryView";

interface IZoneAvailabilityTable {
  metadata: IEventMetadata | null;
  zoneAvailabilityMasterData: IMaster[] | null;
  metadataUpdateData: IEventMetadata | null;
  setMetadataUpdateData: Function;
  setDisabledSaveButton: Function;
  getIfShowingScroll: Function;
  isFromQuickEdit?: boolean;
  isSoldOutActivated: boolean | null;
  setIsSoldOutActivated: Function;
  isWebScrapingEvent: boolean | null;
  setDisableCheckboxes: Function;
}

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
  <Tooltip title="Click & Drag to reposition" placement="left">
    <MenuOutlined
      className="dragable"
      style={{ cursor: "grab", color: "#999" }}
    />
  </Tooltip>
));

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: IZone;
  index: number;
  onKeyDown: any;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  onKeyDown,
  children,
  className,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        placeholder={"$"}
        precision={2}
        min={0}
        onKeyDown={onKeyDown}
        className="metadataZoneTable"
        controls={false}
      />
    ) : (
      <Input
        type={"text"}
        autoComplete="false"
        id={"input_" + dataIndex}
        placeholder={title}
        maxLength={30}
        onKeyDown={onKeyDown}
      />
    );

  let rules: any = [
    {
      required: true,
      message: ``,
      pattern: /^(?!\s+$)[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s-]+$/,
    },
  ];

  if (dataIndex === "secondary_cost") {
    rules = [
      {
        required: false,
        message: ``,
        // pattern: /^\$?\d+(?:.\d+)?\s?(?:,\s?\$?\d+(?:.\d+)?\s?)*$/g
        // pattern: /^(?!.*(\b(?:\d+|3[0-1])\b).*\b\1\b)(?:\d+|3\d+)(?:,\s?(?:\d+|\d+\d|3\d+))*$/
        pattern:
          /^(?!.*(\b(?:[1-9][0-9]*|3[1-9][0-9]*)\b).*\b\1\b)(?:[1-9][0-9]*|3\d+)(?:,\s?(?:[1-9][0-9]*|[1-9][0-9]*\d|3[1-9][0-9]*))*$/,
      },
    ];
  }

  if (inputType === "number") {
    rules = [
      {
        required: true,
        message: ``,
        pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
      },
    ];
  }

  return (
    <td {...restProps} className={"editZoneArea " + className}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ZoneAvailabilityTable: React.FC<IZoneAvailabilityTable> = ({
  metadata,
  zoneAvailabilityMasterData,
  metadataUpdateData,
  setMetadataUpdateData,
  setDisabledSaveButton,
  getIfShowingScroll,
  isFromQuickEdit,
  isSoldOutActivated,
  setIsSoldOutActivated,
  isWebScrapingEvent,
  setDisableCheckboxes,
}) => {
  console.log("Zone table");

  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [originData, setOriginData] = useState<IZone[]>([]);
  const [firstTimeReceivedOriginData, setFirstTimeReceivedOriginData] =
    useState(false);
  const [showCopyDownMetadata, setShowCopyDownMetadata] = useState(false);
  const [lastUpdatedData, setLastUpdatedData] = useState<IZone[]>([]);

  const isEditing = (record: IZone) => record.key === editingKey;

  const fetchMetadata = () => {
    if (metadata && metadata.event_id) {
      dispatch(
        eventManagementActions.getEventMetadata({
          event_id: metadata.event_id,
          functionName: fetchMetadata.toString(),
        })
      );
    }
  };

  const updateOriginData = (b: boolean) => {
    if (b) {
      setLastUpdatedData(originData);
      setOriginData(
        originData.map((item) => {
          return {
            ...item,
            zone_availability_id: 0,
            zone_availability: zoneAvailabilityMasterData?.filter(
              (elem) => elem.value === 0
            )[0].label,
          };
        })
      );
      setMetadataUpdateData({
        ...metadataUpdateData,
        zone: metadataUpdateData?.zone?.map((item) => {
          return {
            ...item,
            zone_availability_id: 0,
            zone_availability: zoneAvailabilityMasterData?.filter(
              (elem) => elem.value === 0
            )[0].label,
          };
        }),
      });
    } else {
      if (lastUpdatedData.length > 0) {
        setOriginData(lastUpdatedData);
        setMetadataUpdateData({
          ...metadataUpdateData,
          zone: lastUpdatedData,
        });
      }
    }
  };

  useEffect(() => {
    if (!isWebScrapingEvent && isSoldOutActivated !== null) {
      updateOriginData(isSoldOutActivated);
      setIsSoldOutActivated(null);
    }
  }, [isSoldOutActivated]);

  useEffect(() => {
    if (editingKey !== "") {
      setDisabledSaveButton(true);
    } else {
      setDisabledSaveButton(false);
    }
  }, [editingKey, setDisabledSaveButton]);

  const edit = useCallback(
    (record: any) => {
      form.setFieldsValue({
        zone_section: "",
        rows: "",
        cost: "",
        zone_availability_id: -1,
        ...record,
      });
      setEditingKey(record.key);
      setIfShowingScroll("edit initiated");
    },
    [form]
  );

  const cancelEdit = () => {
    setEditingKey("");
    setIfShowingScroll("edit cancelled");
  };

  useEffect(() => {
    if (editingKey?.length > 0) {
      setDisableCheckboxes(true);
    } else {
      setDisableCheckboxes(false);
    }
  }, [editingKey]);

  const getSplitListingJsx = (
    record: IZone,
    listingRecord: MetaDataZoneActiveListing
  ) => {
    if (
      metadata &&
      metadata.event_id &&
      metadata.event_name &&
      metadata.event_date &&
      metadata.venue_name &&
      metadata.venue_location &&
      record.active_listing
    ) {
      return (
        <SplitListing
          actionElement={
            <Tooltip title="Split">
              <Typography.Link>
                <i className="da icon-split"></i>
              </Typography.Link>
            </Tooltip>
          }
          data={{
            eventId: metadata.event_id,
            eventName: metadata.event_name,
            eventDate: metadata?.event_date?.toString(),
            venueName: metadata.venue_name,
            venueLocation: metadata.venue_location,
            purchaseId: listingRecord.purchase_id,
            purchaseLineId: listingRecord.purchase_line_id,
            inventoryId: listingRecord.inventory_id,
            quantity: listingRecord.quantity,
            row: listingRecord.row,
            section: listingRecord.section,
            listPrice: listingRecord.list_price,
          }}
          refreshListings={fetchMetadata}
        />
      );
    }
  };

  const renderContent = (value: any, row: any, index: number) => {
    const obj: any = {
      children: value,
      props: {},
    };
    obj.props.colSpan = 0;
    return obj;
  };

  const [showSplitView, setShowSplitView] = useState<{
    inventoryId: number | null;
    show: boolean;
  }>({
    inventoryId: null,
    show: false,
  });

  const setAvailability = (
    otherData: any,
    zoneAvailability: any
  ) => {
    if (
      otherData &&
      otherData.available_seats !== null &&
      otherData.available_seats !== undefined &&
      otherData.total_seats !== null &&
      otherData.total_seats !== undefined &&
      (isWebScrapingEvent || metadataUpdateData?.scraping_status_id === 3)
    ) {
      return `${
        metadataUpdateData && metadataUpdateData.is_sold_out
          ? 0
          : otherData.available_seats
      } / ${otherData.total_seats}`;
    }
    return zoneAvailability?.zoneAvailability?.label;
  };

  const zoneAvailabilityTableHeaders = [
    {
      title: "",
      dataIndex: "ranking",
      width: 24,
      className: "dragRowCol",
      render: () => {
        if (!isWebScrapingEvent && !isFromQuickEdit) {
          return <DragHandle />;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "ZONE / SECTION",
      dataIndex: "zone_section",
      className: "text-left zoneSec wordBreak",
      editable:  (metadataUpdateData?.scraping_status_id === 2), //!metadata?.is_from_desktop_app,
      width: 140,
      render: (data: string) => (
        <strong>{data ? data.toUpperCase() : ""}</strong>
      ),
    },
    {
      title: "ROWS",
      dataIndex: "rows",
      className: "text-left wordBreak fifteenDaysCol",
      editable: true,
      width: 120,
      render: (data: string) => (
        <React.Fragment>{data ? data.toUpperCase() : ""}</React.Fragment>
      ),
    },
    {
      title: <React.Fragment><span>SECONDARY COST</span><Tooltip title="Accepts comma seperated integer values"><i className='da icon-info-fill infoTooltip'></i></Tooltip>{ ( metadata && metadata?.venue_fees) && <Tooltip title="Venue Fees will be included during listing"><i className="da icon-venue-fees" style={{ marginLeft: '3px', height: '13px'}}/></Tooltip> }</React.Fragment> as any,
      dataIndex: "secondary_cost",
      className: "text-right wordBreak",
      width: 110,
      editable: true,
      render: (secondary_cost: string) => (
        <div className="text-right">
          { secondary_cost && secondary_cost
            .split(",")
            .map(function (el) {
              return el.trim() === "" ? "" : "$" + el.trim();
            })
            .join(", ")}
        </div>
      ),
    },
    {
      title: <div>COST{ ( metadata && metadata?.venue_fees) && <Tooltip title="Venue Fees will be included during listing"><i className="da icon-venue-fees" style={{ marginLeft: '3px', height: '13px'}}/></Tooltip> }</div>,
      dataIndex: "cost",
      className: "text-right fifteenDaysCol",
      width: 110,
      editable: true,
      render: (cost: number) => (
        <div className="text-right wordBreak">
          {FormatCurrencyUnit(twoDecimalPlacesNumber(cost), CurrencyCode.Usd)}
        </div>
      ),
    },
    {
      title: "AVAILABILITY",
      dataIndex: "zone_availability",
      width: 110,
      className: "text-center",
      render: (_data: string, otherData: IZone) => {
        let zoneAvailability;
        if (
          metadataUpdateData &&
          isWebScrapingEvent &&
          metadataUpdateData.is_sold_out
        ) {
          zoneAvailability = getTagClassForZones(
            0,
            zoneAvailabilityMasterData ? zoneAvailabilityMasterData : []
          );
        } else {
          zoneAvailability = getTagClassForZones(
            otherData.zone_availability_id,
            zoneAvailabilityMasterData ? zoneAvailabilityMasterData : []
          );
        }

        const tagClassName =
          zoneAvailability && zoneAvailability.className
            ? zoneAvailability.className
            : "";

        return (
          <div className="text-center">
            <Tag
              className={tagClassName}
              onClick={() =>
                !isWebScrapingEvent &&
                metadataUpdateData?.scraping_status_id === 2 &&
                changeAvailability(otherData)
              }
              style={{
                cursor:
                  !isWebScrapingEvent && metadataUpdateData?.scraping_status_id === 2
                    ? "pointer"
                    : "initial",
              }}
            >
              {setAvailability(
                otherData,
                zoneAvailability
              )}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      ),
      dataIndex: "id",
      className: "text-right zoneActions",
      width: 80,
      render: (data: any, record: IZone) => {
        const editable = isEditing(record);
        return (
          !isWebScrapingEvent &&
          (editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Keep">
                  <i className="da icon-save"></i>
                </Tooltip>
              </Typography.Link>
              <Typography.Link
                onClick={() =>
                  record.id === -1 ? deleteZone(record) : cancelEdit()
                }
              >
                <Tooltip title="Cancel">
                  <i className="da icon-cancel"></i>
                </Tooltip>
              </Typography.Link>
            </span>
          ) : (
            <span>
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Edit">
                  <i className="da icon-pencil-o"></i>
                </Tooltip>
              </Typography.Link>

              {!isFromQuickEdit && (
                <Typography.Link
                  disabled={
                    editingKey !== "" ||
                    metadata?.zone?.find((each) => each.id === data)
                      ?.has_active_listings
                  }
                  onClick={() => deleteZone(record)}
                >
                  <Tooltip
                    title={
                      metadata?.zone?.find((each) => each.id === data)
                        ?.has_active_listings
                        ? "Zone has active listings"
                        : "Delete"
                    }
                  >
                    <i className="da icon-trash-o"></i>
                  </Tooltip>
                </Typography.Link>
              )}
            </span>
          ))
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "id",
      key: "listingQuantity",
      width: 35,
      className: "borderLeft text-right noPadding invQty",
      render: (_data: number, record: IZone) => {
        const activeListing: MetaDataZoneActiveListing[] =
          record.active_listing ?? [];

        const columns = [
          {
            title: "quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: 35,
            className: " ",
            render: (data: Text, listingRecord: MetaDataZoneActiveListing) => {
              return (
                <div className="text-right">
                  {listingRecord.is_split ? (
                    <Tooltip title="View Splits">
                      <span
                        onClick={() => {
                          setShowSplitView({
                            inventoryId: listingRecord.inventory_id,
                            show: true,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="da icon-split2-blue invQtySplitIcon" />{" "}
                        {data}
                      </span>
                    </Tooltip>
                  ) : (
                    data
                  )}
                </div>
              );
            },
          },
          {
            title: "row",
            dataIndex: "row",
            key: "row",
            width: 48,
            render: (data: string) => {
              return <div>{data ? data.toUpperCase() : ""}</div>;
            },
          },
          {
            title: "list_price",
            dataIndex: "list_price",
            key: "list_price",
            width: 56,
            render: (
              data: number,
              listingRecord: MetaDataZoneActiveListing
            ) => {
              return (
                <div className="text-right">
                  {data
                    ? FormatCurrencyUnit(
                        twoDecimalPlacesNumber(data),
                        listingRecord.currency
                      )
                    : ""}
                </div>
              );
            },
          },
          {
            title: "purchase_id",
            dataIndex: "purchase_id",
            key: "purchase_id",
            width: 65,
            render: (
              data: number,
              listingRecord: MetaDataZoneActiveListing
            ) => {
              let navigationUrl = `${
                LeftNavRoutesEnum.ZONES_LISTING
              }?search=${getEncodedData({
                event_id: metadata?.event_id,
                po_id: listingRecord?.purchase_id,
                po_line_id: listingRecord?.purchase_line_id,
                is_from_metadata: true
              })}`;
              return (
                <div>
                  <a href={navigationUrl} target="_blank" rel="noreferrer">
                    {data}
                  </a>
                </div>
              );
            },
          },
          {
            title: "",
            dataIndex: "purchase_id",
            key: "purchase_id_1",
            width: 40,
            render: (
              data: number,
              listingRecord: MetaDataZoneActiveListing
            ) => {
              return (
                <div className="rowActions" style={{ width: 36 }}>
                  {!listingRecord.is_split && (
                    <React.Fragment>
                      <RBAC
                        allowedPermissions={[
                          ERbacPermissions.ZONES_ACTIVE_LISTING_SPLIT_ACTION,
                        ]}
                      >
                        {getSplitListingJsx(record, listingRecord)}
                      </RBAC>
                      &nbsp;&nbsp;
                      {data ? (
                        <BroadcastFromZoneAvailability
                          activeListingData={listingRecord}
                          refreshListings={fetchMetadata}
                        />
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  )}
                </div>
              );
            },
          },
        ];

        return {
          children: (
            <Table
              className="eventMetaDataZoneListTbl"
              locale={{ emptyText: <div></div> }}
              pagination={false}
              showHeader={false}
              columns={columns}
              dataSource={activeListing}
            />
          ),
          props: {
            colSpan: 5,
          },
        };
      },
    },
    {
      title: "ROW",
      dataIndex: "id",
      key: "listingRow",
      width: 48,
      className: "invRow",
      render: renderContent,
    },
    {
      title: "PRICE",
      dataIndex: "id",
      key: "listingPrice",
      width: 56,
      className: "text-right invPrice",
      render: renderContent,
    },
    {
      title: "PO#",
      dataIndex: "id",
      key: "listingPoId",
      width: 65,
      className: "invPO",
      render: renderContent,
    },
    {
      title: "",
      dataIndex: "id",
      key: "listingActions",
      className: "invAction",
      width: 40,
      render: renderContent,
    },
  ].filter((eachColumn) => {
    if (
      eachColumn.key &&
      [
        "listingQuantity",
        "listingRow",
        "listingPrice",
        "listingPoId",
        "listingActions",
      ].includes(eachColumn.key) &&
      !hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW])
    ) {
      return false;
    }
    return true;
  });

  const changeAvailability = (currentZone: IZone) => {
    if (zoneAvailabilityMasterData && zoneAvailabilityMasterData.length) {
      let thisOriginData = originData.map((eachOrigin) => {
        return { ...eachOrigin };
      });

      let currentZoneData = thisOriginData.find(
        (eachData) => eachData.key === currentZone.key
      );
      const foundZoneAvailability = zoneAvailabilityMasterData?.find(
        (eachZoneAvaData) =>
          eachZoneAvaData.value === currentZone.zone_availability_id
      );
      let foundZoneAvailabilityIndex = 0;
      if (foundZoneAvailability) {
        foundZoneAvailabilityIndex =
          zoneAvailabilityMasterData?.findIndex((element) => {
            if (element.value === currentZone.zone_availability_id) {
              return true;
            }
            return false;
          }) ?? 0;
      }

      let nextZoneAvailabilityIndex = 0;
      if (foundZoneAvailabilityIndex < zoneAvailabilityMasterData.length - 1) {
        nextZoneAvailabilityIndex = foundZoneAvailabilityIndex + 1;
      }
      if (currentZoneData) {
        currentZoneData.zone_availability_id =
          zoneAvailabilityMasterData[nextZoneAvailabilityIndex].value;
        currentZoneData.zone_availability =
          zoneAvailabilityMasterData[nextZoneAvailabilityIndex].label;
      }

      setOriginData(thisOriginData);
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        zone: thisOriginData.length ? thisOriginData : [],
      }));
    }
  };

  const mergedColumns = zoneAvailabilityTableHeaders.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IZone) => ({
        record,
        inputType: col.dataIndex === "cost" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: typeof col.title === 'string' ? col.title : col.title.props?.children[0]?.props?.children,
        onKeyDown: onEnterOrEscKeyPress,
        editing: isEditing(record),
      }),
    };
  });

  const onEnterOrEscKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      save(editingKey);
    } else if (event.keyCode === 27) {
      const findCurrentZoneByKey = originData.find(
        (eachData) => eachData.key === editingKey
      );
      if (findCurrentZoneByKey) {
        if (findCurrentZoneByKey.id === -1) {
          deleteConfirmZone(findCurrentZoneByKey);
        } else {
          setEditingKey("");
        }
      }
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      let newData = arrayMoveImmutable(
        [].concat(originData as any),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const newDataWithNewRanking = newData.map((eachData: any, index) => {
        return {
          ...eachData,
          ranking: index + 1,
        };
      });
      setOriginData(newDataWithNewRanking);
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        zone: newDataWithNewRanking.length ? newDataWithNewRanking : [],
      }));
    }
  };

  const save = async (key: any) => {
    try {
      const row = (await form.validateFields()) as IZone;
      row.zone_section = row.zone_section?.trim();
      row.rows = row.rows?.trim();

      const newData = [...originData];
      const index = newData.findIndex((item) => key === item.key);
      let secondary_cost = row && row.secondary_cost ? row?.secondary_cost?.split(',') : []
      let cost = row && row.cost ? row.cost : 0.00
      
      let secondaryCostValid = secondary_cost.every((value) => parseInt(value.trim()) <= cost )

      if (!secondaryCostValid) {
        message.error({
          content: `Secondary Cost Values should be less than or equal to Cost Value.`,
          duration: 5,
          className: "toastMsg savedFailed",
        });
        return
      }

      if (index > -1 && secondaryCostValid) {
        const item = newData[index];
        row.zone_section = metadataUpdateData?.scraping_status_id === 3 ? item.zone_section : row.zone_section;
        const findSameValue = newData.find((eachItem) => {
          const eachItemZoneSection = eachItem.zone_section
            ? eachItem.zone_section.toLocaleLowerCase().trim()
            : "";
          const currentItemZoneSection = row.zone_section
            ? row.zone_section.toLocaleLowerCase()
            : "";
          const eachItemRows = eachItem.rows
            ? eachItem.rows.toLocaleLowerCase().trim()
            : "";
          const currentItemRows = row.rows ? row.rows.toLocaleLowerCase() : "";

          if (
            eachItem.key !== key &&
            eachItemZoneSection === currentItemZoneSection &&
            eachItemRows === currentItemRows
          ) {
            return true;
          }
          return false;
        });

        if (!findSameValue) {
          newData.splice(index, 1, {
            ...item,
            ...row,
            id: !item.id || item.id < 0 ? 0 : item.id,
          });
          setOriginData(newData);
          setMetadataUpdateData((prev: IEventMetadata | null) => ({
            ...prev,
            zone: newData.length ? newData : [],
          }));
          setEditingKey("");
        } else {
          message.error({
            content: `ZONE / SECTION, ROWS combination already exists.`,
            duration: 5,
            className: "toastMsg savedFailed",
          });
        }
        
      } else {
        newData.push(row);
        setOriginData(newData);
        setMetadataUpdateData((prev: IEventMetadata | null) => ({
          ...prev,
          zone: newData.length ? newData : [],
        }));
        setEditingKey("");
      }
      setIfShowingScroll("save initiated");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const createNewZone = useCallback(() => {
    const currentKey =
      new Date().getTime().toString() + getRandomValue("number");
    const prevOriginData = [...originData];
    let maxRanking = 1;
    if (prevOriginData.length) {
      maxRanking =
        prevOriginData.reduce(
          (acc: any, shot: any) =>
            (acc = acc > shot.ranking ? acc : shot.ranking),
          0
        ) + 1;
    }

    const newZone = {
      id: -1,
      ranking: maxRanking,
      key: currentKey,
      zone_section: "",
      rows: "",
      secondary_cost: "",
      cost: 0,
      zone_availability_id: 5,
    };

    prevOriginData.push(newZone);

    setOriginData(prevOriginData);
    edit(newZone);
  }, [edit, originData]);

  const deleteZone = (zone: IZone) => {
    const deleteConfirmHeader = (
      <span>
        Are you sure you want to <strong>delete</strong>?
      </span>
    );

    const zoneAvailability = getTagClassForZones(
      zone.zone_availability_id,
      zoneAvailabilityMasterData ? zoneAvailabilityMasterData : []
    );

    const deleteConfirmBody = (
      <div className="confirmBody">
        <div className="deleteConfirmBodyTableWrap">
          <table className="deleteConfirmBodyTable">
            <tr>
              <th>ZONE / SECTION:</th>
              <td>
                {zone.zone_section ? zone.zone_section.toUpperCase() : ""}
              </td>
            </tr>
            <tr>
              <th>ROWS:</th>
              <td>{zone.rows}</td>
            </tr>
            <tr>
              <th>SECONDARY COST:</th>
              <td>{zone.secondary_cost}</td>
            </tr>
            <tr>
              <th>COST:</th>
              <td>
                {FormatCurrencyUnit(
                  formatThousandSeparator(zone.cost ?? 0).toString(),
                  CurrencyCode.Usd
                )}
              </td>
            </tr>
            <tr>
              <th>AVAILABILITY:</th>
              <td>{zoneAvailability?.zoneAvailability?.label}</td>
            </tr>
          </table>
        </div>
      </div>
    );
    if (zone.id !== undefined && zone.id >= 0) {
      showConfirm(
        deleteConfirmHeader,
        deleteConfirmBody,
        zone,
        deleteConfirmZone,
        () => {
          return;
        }
      );
    } else {
      deleteConfirmZone(zone);
    }
  };

  const deleteConfirmZone = (zone: IZone) => {
    const prevOriginData = [...originData];
    const findZone = prevOriginData.find(
      (eachZone) => eachZone.key === zone.key
    );

    if (findZone) {
      prevOriginData.splice(prevOriginData.indexOf(findZone), 1);
      setOriginData(prevOriginData);
      setMetadataUpdateData((prev: IEventMetadata | null) => ({
        ...prev,
        zone: prevOriginData.length ? prevOriginData : [],
      }));
      setEditingKey("");
    }
    setIfShowingScroll("after delete");
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const dataSource = originData;
    const index = dataSource?.findIndex(
      (x) => x.ranking === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  useEffect(() => {
    if (metadata?.zone && !firstTimeReceivedOriginData) {
      const newData = metadata?.zone.map((eachData) => {
        return { ...eachData };
      });
      if (newData.length) {
        setOriginData([...newData]);
        setMetadataUpdateData((prev: IEventMetadata | null) => ({
          ...prev,
          zone: newData.length ? newData : [],
        }));
      } else {
        if (
          metadataUpdateData &&
          !metadataUpdateData.seat_map_tbd &&
          !isWebScrapingEvent &&
          metadata?.scraping_status_id === 2
        ) {
          createNewZone();
        }
      }

      setFirstTimeReceivedOriginData(true);
    }
  }, [
    metadata?.zone,
    firstTimeReceivedOriginData,
    setOriginData,
    setFirstTimeReceivedOriginData,
    setMetadataUpdateData,
    createNewZone,
  ]);

  useEffect(() => {
    if (metadataUpdateData?.seat_map_tbd) {
      deleteConfirmZone(originData[0]);
    }
  }, [metadataUpdateData]);

  const setIfShowingScroll = (type: string) => {
    setTimeout(() => {
      getIfShowingScroll();
    }, 200);
  };

  const zoneDataFilter = () => {
    const originalZoneData = [...originData];
    //return originalZoneData;
    if (isFromQuickEdit) {
      return originalZoneData.filter((eachZone) => {
        if (
          !eachZone.active_listing ||
          eachZone.active_listing.length === 0 ||
          !eachZone.active_listing.find(
            (eachZoneListing) =>
              eachZoneListing.platform_broadcasted_active_listings
          )
        ) {
          return false;
        } else {
          eachZone.active_listing = eachZone.active_listing.filter(
            (eachListing) =>
              eachListing.platform_broadcasted_active_listings > 0
          );
          return true;
        }
      });
    } else {
      return originalZoneData;
    }
  };

  return (
    <React.Fragment>
      <div className="customTbl metadataZoneTbl">
        <Form form={form} component={false}>
          <Table
            //dataSource={originData.slice(0,2)}
            dataSource={zoneDataFilter()}
            columns={mergedColumns}
            pagination={false}
            className="main-table dragableTbl"
            scroll={{ x: 850 }}
            rowKey="ranking"
            rowClassName="editable-row"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </div>
      {!isFromQuickEdit && editingKey === "" && !isWebScrapingEvent && metadataUpdateData?.scraping_status_id === 2 && (
        <div className="addRow addRowWithCopyEvent">
          <Typography.Link
            onClick={() => {
              createNewZone();
              setTimeout(() => {
                document?.getElementById("input_zone_section")?.focus();
              }, 300);
            }}
            style={{ textDecoration: "underline" }}
            className="newRowLink"
            disabled={metadataUpdateData?.seat_map_tbd}
          >
            NEW ROW
          </Typography.Link>
          <div className="text-right copyRow">
            {metadata &&
              metadata.event_id &&
              metadata.zone &&
              metadata.zone.length > 0 && (
                <RBAC
                  allowedPermissions={[
                    ERbacPermissions.EVENT_COPYDOWN_METADATA,
                  ]}
                >
                  <Typography.Link
                    className="txtlink"
                    onClick={() => {
                      setShowCopyDownMetadata(true);
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    COPY DOWN EVENT SEATING STRUCTURE
                  </Typography.Link>
                </RBAC>
              )}
          </div>

          {showCopyDownMetadata && metadata?.event_id && metadata.zone && (
            <CopyDownMetadataModal
              requestedEventId={metadata.event_id}
              setShowCopyDownMetadata={setShowCopyDownMetadata}
            />
          )}
        </div>
      )}

      {showSplitView.show && showSplitView.inventoryId && (
        <SplitInventoryView
          inventory_id={showSplitView.inventoryId}
          showSplitView={showSplitView.show}
          setShowSplitView={() => {
            setShowSplitView({
              inventoryId: null,
              show: false,
            });
          }}
          isFromQuickEdit={isFromQuickEdit}
          onCloseGetSplitInventoryData={(splitInventoryData: ISplitDatum[]) => {
            if (!splitInventoryData.length && isFromQuickEdit) {
              fetchMetadata();
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

const areEqual = (
  prevProps: IZoneAvailabilityTable,
  nextProps: IZoneAvailabilityTable
) => {
  if (
    prevProps.metadata === nextProps.metadata &&
    prevProps.isWebScrapingEvent === nextProps.isWebScrapingEvent &&
    prevProps.metadataUpdateData?.seat_map_tbd ===
      nextProps.metadataUpdateData?.seat_map_tbd &&
    prevProps.metadataUpdateData?.is_sold_out ===
      nextProps.metadataUpdateData?.is_sold_out &&
    prevProps.metadataUpdateData?.scraping_status_id ===
      nextProps.metadataUpdateData?.scraping_status_id
  ) {
    return true; // donot re-render
  }
  return false; // will re-render
};

export default React.memo(ZoneAvailabilityTable, areEqual);
