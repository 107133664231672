import { IMarkupData } from "models/interfaces";

export const calculateListPrice = (
    markupData: IMarkupData | null,
    ticketCost: number
  ) => {
    if (
      markupData &&
      markupData.defaultMarkupType &&
      markupData.defaultMarkup
    ) {
      if (markupData.defaultMarkupType === 1 && markupData.defaultMarkup) {
        return ticketCost
          ? ticketCost + (markupData.defaultMarkup * ticketCost) / 100
          : 0;
      } else if (
        markupData.defaultMarkupType === 2 &&
        markupData.defaultMarkup
      ) {
        return ticketCost + markupData.defaultMarkup;
      }
    } else {
      return ticketCost;
    }
};

export const getLastRowNameFromRowsRange = (rowsRange:string) => {
  if(rowsRange && rowsRange.trim() !== '' && rowsRange.trim().length){
    let splitRowsData = rowsRange.trim().split(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    if(splitRowsData.length){
      return splitRowsData[splitRowsData.length-1];
    }
  }
  return '';
}