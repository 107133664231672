import { DatePicker, Button, Radio, Space, message } from "antd";
import type { RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import {
  getCustomDate,
  getDateObject,
  getDisabledDate,
} from "utils/formatTimePeriod";
import { IOnSaleData } from "models/interfaces/Purchases/submenus.model";
import moment, { Moment } from "moment";
import { PurchaseAndShippingService } from "services/purchaseAndShippingServices/purchaseAndShipping.service";
import { IMenuOption, IPurchaseQueueMasterData, ISwimlaneInvoiceList, ISwimlaneMaster } from "models/interfaces";
import { LoggerService } from "sharedServices";

const swimlane_id = 3;

const MoveToOnSale: React.FC<{
  menuData: IMenuOption;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  requestToReloadTheLanes: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
}> = ({
  menuData,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  eachInvoiceData,
  swimlaneData,
  setShowMenu,
  requestToReloadTheLanes,
  purchaseQueueMaster
}) => {
  const [onSaleData, setOnSaleData] = useState<IOnSaleData>({
    invoice_id: eachInvoiceData.invoice_id,
    event_date: eachInvoiceData.event_date,
    swimlane_id: swimlane_id,
    on_sale_type: null,
    on_sale_date: null,
  });
  const [isValid, setIsValid] = useState(false);

  const radioChangeHandler = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    if (e.target.value !== undefined) {
      setOnSaleData((prev) => ({
        ...prev,
        on_sale_type: e.target.value,
        on_sale_date: e.target.value === 2 ? null : prev?.on_sale_date,
      }));
    }
  };

  function disabledDate(current: any) {
    return (
      current > moment.utc(eachInvoiceData.event_date).add(1,'day') ||
      current < moment.utc(getDisabledDate()).add(1, 'day')
    );
  }

  const cancelHandler = () => {
    setSelectedMenuId(null);
    setOnSaleData((prev) => ({
      ...prev,
      on_sale_type: null,
      on_sale_date: null,
    }));
  };

  const updateOnSaleData = async () => {
    const moveToOnSaleKey = "moveToOnSaleKey";
    setSelectedMenuId(null);
    setShowMenu(false);
    message.loading({
      content: `Moving Invoice from ${swimlaneData.name} to On-Sale queue...`,
      duration: 0,
      key: moveToOnSaleKey,
      className: "toastMsg loadingMsg",
    });
    try {
      if (isValid) {
        await new PurchaseAndShippingService().moveToOnSale(onSaleData);
          message.success({
            content: `Invoice# ${eachInvoiceData.invoice_id} moved from ${swimlaneData.name} to On-Sale queue.`,
            duration: 5,
            key: moveToOnSaleKey,
            className: "toastMsg savedSuccess",
          });
      }
    } catch (error: any) {
      message.error({
        content: "Failed to move invoice.",
        duration: 5,
        key: moveToOnSaleKey,
        className: "toastMsg savedFailed",
      });
      new LoggerService().log({
        payload: error,
        function_name: "updateOnSaleData",
      });
    }
    requestToReloadTheLanes([swimlaneData.id, swimlane_id]);
    setTimeout(() => {
      requestToReloadTheLanes(null);
    }, 3000);
  };

  const onSaleDateChangeHandler = (date: Moment | null | undefined) => {
    if (date !== null) {
      setOnSaleData((prev) => ({
        ...prev,
        on_sale_type: 1,
        on_sale_date: getCustomDate(date),
      }));
    }
  };

  useEffect(() => {
    if (
      onSaleData?.on_sale_type === 1 &&
      onSaleData.on_sale_date !== undefined &&
      onSaleData.on_sale_date !== null
    ) {
      setIsValid(true);
    } else if (onSaleData?.on_sale_type === 2) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [onSaleData]);

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
          } else {
            handleMenuSelection(menuData.menu_id);
          }
        }}
      >
        {menuData.name}
      </div>
      <div className="accBody">
        <div className="moveOnSaleWrap">
          <Radio.Group
            className="moveOnSaleRadioGrp radioStyleDark"
            onChange={radioChangeHandler}
            value={onSaleData?.on_sale_type}
          >
            <Space direction="vertical">
              <div className="groupCont">
                <Radio value={purchaseQueueMaster?.onSaleTypeMasterData?.find((each)=>each.label === "On Sale Date")?.value}></Radio>
                <DatePicker
                  className="positionTopLeft"
                  placement="topLeft"
                  allowClear={false}
                  disabledDate={disabledDate}
                  showToday={false}
                  // style={{ width: "auto" }}
                  disabled={onSaleData?.on_sale_type === 1 ? false : true}
                  onChange={onSaleDateChangeHandler}
                  value={
                    onSaleData?.on_sale_date
                      ? getDateObject(onSaleData?.on_sale_date)
                      : undefined
                  }
                  getPopupContainer={(trigger) =>
                    trigger.parentElement as HTMLInputElement
                  }
                />
              </div>
              <Radio value={purchaseQueueMaster?.onSaleTypeMasterData?.find((each)=>each.label === "On Sale TBD")?.value}>On Sale TBD</Radio>
            </Space>
          </Radio.Group>
          <div className="moveOnSaleRow btnGroupFooter">
            <Button className="discardBtn" onClick={cancelHandler}>
              <span>CANCEL</span>
            </Button>
            <Button
              className="btnUpdate"
              disabled={!isValid}
              onClick={updateOnSaleData}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoveToOnSale;
