import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import EventQuickUpdateModal from "./EventQuickUpdateModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { eventMetadata, isLoadingMetadata, updateUpcomingEventList } from "models/Slices/EventManagementSlice";

const EventQuickUpdate: React.FC<{
    eventId: number;
    platformBroadcastedActiveListingsCount: number;
}> = ({ eventId, platformBroadcastedActiveListingsCount }) => {

    const dispatch = useAppDispatch();
    const metadata = useAppSelector(eventMetadata);
    const loadingMetadata = useAppSelector(isLoadingMetadata);

    const [showModal, setShowModal] = useState(false);

    const handleClick = (event:React.MouseEvent<HTMLDivElement>) => {
        setShowModal(true);
        event.stopPropagation();
    };

    const zoneDataFilter = () => {
        let _metadata = {...metadata};
        let originalZoneData = _metadata?.zone ? [..._metadata?.zone] : [];
        return originalZoneData.filter((eachZone)=>{
            if(!eachZone.active_listing || eachZone.active_listing.length === 0 || !eachZone.active_listing.find(eachZoneListing=>eachZoneListing.platform_broadcasted_active_listings)){
              return false;
            }
            else if(eachZone.active_listing.filter(eachListing => eachListing.platform_broadcasted_active_listings > 0)){
                return true;
            }
            else{
                return false
            }
        });
    }

    useEffect(()=>{
        if( !loadingMetadata && metadata && metadata.event_id && metadata.event_id === eventId && metadata.zone && metadata.zone.length){
            if(zoneDataFilter().length === 0){
                dispatch(updateUpcomingEventList(
                    {
                        event_id: eventId,
                        platform_broadcasted_active_listings: 0
                    }
                ));
            }
        }
        
    }, [eventId, metadata, loadingMetadata])

    return (
        <React.Fragment>
            {platformBroadcastedActiveListingsCount > 0 &&
                <RBAC allowedPermissions={[ERbacPermissions.EVENT_QUICK_UPDATE]}>
                    <div style={{cursor: 'pointer'}} onClick={handleClick}>
                        <Tooltip title="Quick Update">
                            <i style={{ width: 23, height: 22 }} className="da icon-event-quick-update-o"></i>
                        </Tooltip>
                    </div>
                </RBAC>
            }

            {showModal &&
                <EventQuickUpdateModal eventId={eventId} hideModal={setShowModal} />
            }
            
        </React.Fragment>
    )
}

export default EventQuickUpdate;