import { getCustomDate, formatDateGivenFormatString } from 'utils/formatTimePeriod';
import { addToolTip, formatNumericValues, formatAccountingValues } from 'utils/commonFunction'
import { FixedColumnDirection } from 'models/enums/table';
import SelectInventory from './Actions/SelectInventory';
import { AppSetting } from 'utils/appSettings';

const commonWidth = 100;

export const InventoryHeaders = [
  {
    title: "",
    dataIndex: "poNo",
    thClassName: "text-left",
    width: 40,
    fixed: FixedColumnDirection.left,
    dataRender: (_data: any, all: any) => {
      const { totalCost, invoiceMappingId } = all.data.singleData;

      if (totalCost != 0 || invoiceMappingId == null) {
        return <SelectInventory data={all} />;
      }
    },
  },
  {
    title: "PO Date",
    dataIndex: "poDate",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    canSort: true,
    dataRender: (poDate: string) => poDate && getCustomDate(poDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "PO Number",
    dataIndex: "poNo",
    width: 120,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    canSort: true,
    dataRender: (purchaseId: any) =>
      isNaN(purchaseId) ? (
        <div>{purchaseId}</div>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={AppSetting.PURCHASE_ID_URL + purchaseId}
        >
          {purchaseId}
        </a>
      ),
  },
  {
    title: "PO Line Id",
    dataIndex: "poLineNo",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    canSort: true,
  },
  {
    title: "Broker",
    dataIndex: "broker",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (broker: any) => <div className="text-capitalize">{broker}</div>,
  },
  {
    title: "Po Tag",
    dataIndex: "poTags",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (poTags: any) => (
      <div className="flx">
        {poTags && poTags.map((d: any) => d.role_name).join(", ")}
      </div>
    ),
  },
  {
    title: "Event Name",
    dataIndex: "eventName",
    width: commonWidth + 50,
    thClassName: "text-left",
    canSort: true,
    dataRender: (event: string, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.eventId}
        className='text-capitalize'
      >
        {event?.length >= 40 ? addToolTip(event,"right", "pointer", 40, false, {textTransform: "capitalize"}): event}
      </a>
    ),
  },
  {
    title: "Event Date & Time",
    dataIndex: "eventDate",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (eventDate: string) =>
      eventDate && formatDateGivenFormatString(eventDate, AppSetting.EVENT_DATE_FORMAT),
  },
  {
    title: "Venue Name",
    dataIndex: "venueName",
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    canSort: true,
    dataRender: (venue: any) =>
    (venue && venue.length) >= 18 ? addToolTip(venue,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{venue}</div>,
  },
  {
    title: "Section",
    dataIndex: "section",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (section: any) =>
      (section && section.length) >= 18 ? addToolTip(section,"right", "default", 18) : <div className='text-capitalize'>{section}</div>,
  },
  {
    title: "Row",
    dataIndex: "row",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (row: any) => <div className='text-upper'>{row}</div>,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    dataRender: (seats: any) =>
      (seats && seats.length) > 15 ? addToolTip(seats) : <div>{seats}</div>,
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
  },
  {
    title: "Cur",
    dataIndex: "currency",
    thClassName: "text-left",
    canSort: true,
    dataRender: (data: any) => <div className="text-upper">{data}</div>,
  },
  {
    title: "Unit Cost",
    dataIndex: "unitCost",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (unitCost: number) => (
      <div className={`text-right ${unitCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(unitCost)}
      </div>
    ),
  },
  {
    title: "Total Cost",
    dataIndex: "totalCost",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (totalCost: number) => (
      <div className={`text-right ${totalCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(totalCost)}
      </div>
    ),
  },
  {
    title: "Invoice Mapping Id",
    dataIndex: "invoiceMappingId",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
  },
  {
    title: "Internal Notes",
    dataIndex: "internalNotes",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
  },
  {
    title: "Credit Card Group",
    dataIndex: "creditCardGroup",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (creditCardGroup: any) => <div className="text-capitalize">{creditCardGroup}</div>,
  },
  {
    title: "Credit Card",
    dataIndex: "creditCard",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (creditCard: any) => <div className="text-capitalize">{creditCard}</div>,
  },
];
