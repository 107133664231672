import {
  CheckerDashboardService,
  ListerDashboardService,
  SeatMapCreatorDashboardService
} from "services";
import moment from "moment";
import { Tag, Typography } from "antd";
import { OffsetType } from "models/enums/dateTime";
import { addToolTip, getEncodedData } from "utils/commonFunction";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { IListerSeatAvailabilityQueryParams } from "models/interfaces";

export const widgetClassPerCol = [
  "",
  "ant-col-xs-24 ant-col-sm-24 ant-col-lg-24",
  "ant-col-xs-24 ant-col-sm-12 ant-col-lg-12",
  "ant-col-xs-24 ant-col-sm-12 ant-col-lg-8",
  "ant-col-xs-24 ant-col-sm-12 ant-col-lg-6",
  "ant-col-xs-24 ant-col-sm-10 ant-col-lg-10"
];

export const getActiveListingField = (activeListing: number | null) => {
  if (activeListing) {
    return (
      <span className="text-danger">
        {` (${activeListing} listings)`}
      </span>
    );
  }
  return "";
}

export const getUsableService = (serviceType: string) => {
  if (serviceType === "checker") {
    return new CheckerDashboardService();
  }
  if (serviceType === "lister") {
    return new ListerDashboardService();
  }
  return new SeatMapCreatorDashboardService();
}

export const colsCount = {
  alert: 3,
  insights: 3,
  kpis: 2
}

export const formatDate = (date: Date | string) => {
  return moment(date).format("MMM DD, YYYY, h:mma");
}

export const getRightTagStyles = (
  type: string | null,
  days: number | null = null
) => {
  /**if(type && days === null) {
    return (
      <span>
        <Tag className="danger overdueTag">
          N/A
        </Tag>
      </span>
    );
  }*/
  if (type === "noOfDays") {
    return (
      <span>
        <Tag className="danger overdueTag">
          {days}d
        </Tag>
      </span>
    );
  }
  if (type === "nameOfDays") {
    return (
      <span>
        <Tag className="success upcomingTag">
          {moment().add(days, OffsetType.DAYS).format("ddd")}
        </Tag>
      </span>
    );
  }
  return "";
}

export const formatEventName = (eventName: string | null, limit: number) => {
  if (eventName && eventName.length >= limit) {
    return addToolTip(
      eventName,
      "topLeft",
      "pointer",
      (limit - 3),
      false,
      { textTransform: "capitalize" }
    );
  }
  return eventName;
}

export const viewAllCTA = (
  redirectPath: string,
  params: any,
  style: any,
  label: string
) => {
  return (
    <Typography.Link
      target="_blank"
      rel="noreferrer"
      href={`${LeftNavRoutesEnum[redirectPath]
        }?filter=${getEncodedData(params)}`}
      underline={true}
      style={style}
    >
      {label}
    </Typography.Link>
  );
}

export const defaultParams: IListerSeatAvailabilityQueryParams = {
  broker_user_Id: null,
  lister_user_Id: null,
  checker_user_id: null,
  seat_map_creator_user_id: null
}
