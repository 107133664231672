import { Tooltip } from "antd";
import { INotesHistory } from "models/interfaces";
import { useState } from "react";
import { titleCase } from "utils/commonFunction";
import { getCustomDate, getCustomTimeFromDate } from "utils/formatTimePeriod";
import inboxAvatar2 from "../../../../assets/images/no-user.svg";

const EachNote: React.FC<{
  eachNote: INotesHistory;
  setCopiedNote: Function;
}> = ({ eachNote, setCopiedNote }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li key={eachNote.id}>
      <span className="userAvatar">
          <img
            src={
              eachNote.profile_picture_url
                ? eachNote.profile_picture_url
                : inboxAvatar2
            }
            alt=""
            title=""
          />
      </span>
      <div className="flx-1">
        <div className="flxSpcBetween">
          <span className="eventUserName">
            {eachNote?.updated_by}
            <span
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => setCopiedNote(eachNote.notes)}
            >
              <Tooltip title="Copy to Notes">
                <i
                  className="da icon-copy"
                  style={{ width: "12px", top: "-1px" }}
                />
              </Tooltip>
            </span>
          </span>
          <span className="eventDateTime">
            {`${getCustomDate(
              eachNote?.updated_on,
              "ddd, MMM D, YYYY"
            )} at ${getCustomTimeFromDate(eachNote?.updated_on)}`}
          </span>
        </div>
        <div className="sortDesc">
          {eachNote.notes?.length > 225 ? (
            <span>
              {!isExpanded && eachNote.notes.substring(0, 210)}
              {isExpanded && eachNote.notes}
              <span
                className="noteHistoryTextMore"
                onClick={() => setIsExpanded((prev) => !prev)}
              >
                {!isExpanded && "...more"}
                {isExpanded && "less"}
              </span>
            </span>
          ) : (
            <span>{eachNote.notes}</span>
          )}
        </div>
      </div>
    </li>
  );
};

export default EachNote;
