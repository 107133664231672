import { Button, message, Select } from "antd";
import { IMaster, ISwimlaneInvoiceList, ISwimlaneMaster } from "models/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { PurchaseAndShippingService, VenueService } from "services";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const ChangeBuyer: React.FC<{
  swimlaneData: ISwimlaneMaster;
  handleMenuSelection: Function;
  eachInvoiceData: ISwimlaneInvoiceList;
  requestToReloadTheLanes: Function;
  setShowMenu: Function;
}> = ({ swimlaneData, handleMenuSelection, eachInvoiceData, requestToReloadTheLanes, setShowMenu }) => {
  const [buyerList, setBuyerList] = useState<IMaster[] | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [newBuyerId, setNewBuyerId] = useState<string | null>(eachInvoiceData.buyer_id);

  const getBuyerList = useCallback(async () => {
    setShowLoader(true);
    const buyerResponse = await new VenueService().getBuyerListByVenueId(
      eachInvoiceData.venue_id
    );
    setBuyerList(buyerResponse.data.data);
    if(!buyerResponse.data.data?.find(eachBuyer => eachBuyer.value === eachInvoiceData.buyer_id)){
      setNewBuyerId(null);
    }
    setShowLoader(false);
  }, [eachInvoiceData.venue_id]);

  const doUpdateChangeBuyer = async () =>{
    if(!newBuyerId || eachInvoiceData.buyer_id === newBuyerId){
      message.error({
        content: "Can't re-assign to the same buyer.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      return false;
    }
    setShowLoader(true);
    const updatingBuyerKey = "moveToIssuesKey";
    message.loading({
      content: `Updating buyer...`,
      duration: 0,
      key: updatingBuyerKey,
      className: "toastMsg loadingMsg",
    });

    try {
      await new PurchaseAndShippingService().changeBuyer({invoice_id: eachInvoiceData.invoice_id, buyer_id: newBuyerId});
      message.success({
        content: `Invoice #${eachInvoiceData.invoice_id} re-assigned from ${buyerList?.find(each => each.value === eachInvoiceData.buyer_id)?.label} to ${buyerList?.find(each => each.value === newBuyerId)?.label}`,
        duration: 5,
        key: updatingBuyerKey,
        className: "toastMsg savedSuccess",
      });
      handleMenuSelection(null);
      setShowMenu(null);
      requestToReloadTheLanes([swimlaneData.id]);
      setTimeout(() => {
        requestToReloadTheLanes(null);
      }, 100);
    }  catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "doUpdateChangeBuyer",
      });
      message.error({
        key: updatingBuyerKey,
        content: "Failed to change buyer.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
    setShowLoader(false);
  }

  useEffect(() => {
    getBuyerList();
  }, [getBuyerList]);

  return (
    <LoadingSpinner isLoading={showLoader}>
      <div className="accBody">
        <div className="moveOnSaleWrap" style={{ minHeight: 30 }}>
          {buyerList && (
            <React.Fragment>
              <Select
                options={buyerList}
                getPopupContainer={(trigger) =>
                  trigger.parentElement as HTMLInputElement
                }
                value={newBuyerId ? newBuyerId : 'Select'}
                onChange={(value)=>{
                  setNewBuyerId(value);
                }}
              />

              <div className="moveOnSaleRow btnGroupFooter">
                <Button className="discardBtn" onClick={()=>{
                  handleMenuSelection(null);
                }}>
                  <span>CANCEL</span>
                </Button>
                <Button className="btnUpdate" disabled={!newBuyerId || eachInvoiceData.buyer_id === newBuyerId} onClick={doUpdateChangeBuyer}>Update</Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </LoadingSpinner>
  );
};

export default ChangeBuyer;
