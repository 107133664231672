import { Checkbox, Spin, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppSelector } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import {
  CommonTableHeader,
  IActiveListing,
  IAllZoneListingPOTableHeader,
  IEventsWithActiveZoneListingsResponse,
  IEventWithActiveZoneListings,
  IMarketplace,
  ISplitDatum,
} from "models/interfaces";
// import { competitorPricesList } from "models/Slices/CompetitorPricesSlice";
import React, { useState, useEffect } from "react";
import { AppSetting } from "utils/appSettings";
import { getSeatAvailabilityDetails } from "utils/commonFunction";
import { formatDateGivenFormatString, getCurrentDate, getCustomDate, getDateDiff } from "utils/formatTimePeriod";
import SplitInventoryView from "../../SplitInventoryView/SplitInventoryView";
import AllZoneListingPoTableDataRow from "./AllZoneListingPoTableDataRow";
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface IAllZoneListingPoTable {
  data: null | IActiveListing[];
  allZoneListings: IEventsWithActiveZoneListingsResponse;
  currentEventData: IEventWithActiveZoneListings
  otherData?: IEventsWithActiveZoneListingsResponse;
  setAllZoneListings: Function;
  rowSaveClickHandler: Function;
  haveVenueFees: boolean;
  // fetchCompetitorPrice: Function;
}

const AllZoneListingPoTable: React.FC<IAllZoneListingPoTable> = ({
  data,
  allZoneListings,
  currentEventData,
  otherData,
  setAllZoneListings,
  rowSaveClickHandler,
  haveVenueFees,
  // fetchCompetitorPrice
}) => {
  const { hasPermissions } = useRbac();
  const [showSplitView, setShowSplitView] = useState(false);
  const [currentClickedInventory, setCurrentClickedInventory] =
    useState<number>();
  const [availabilityColor, setAvailabilityColor] = useState("");
  //const [isAllSelected, setIsAllSelected] = useState(false);
  // const competitorPricesListData = useAppSelector(competitorPricesList);

  useEffect(() => {
    data &&
      setAvailabilityColor(
        getSeatAvailabilityDetails(data[0].seat_availability_score).color
      );
  }, [data]);

  const checkIsAllSelected = (allData: IEventsWithActiveZoneListingsResponse) => {
    let check = true;
    if (data) {
      const _data = allData?.events.find(eachEvent => eachEvent.event_id === data[0].event_id)?.po_details?.editedActiveListings;
      if(_data) {
        let broadcasted_po_count = _data.reduce(
          (n: number, eachPo: IActiveListing) => {
            return n + (eachPo.broadcasted_inventory_count > 0 ? 1 : 0);
          },
          0
        );
        let checked_pos = _data.reduce((n: number, eachPo: IActiveListing) => {
          return n + (eachPo.is_checked === true ? 1 : 0);
        }, 0);
        if (
          broadcasted_po_count === 0 ||
          checked_pos === 0 ||
          broadcasted_po_count !== checked_pos
        ) {
          check = false;
        }
      }
    }
    //setIsAllSelected(check);
  };

  const partialChecked = () => {
    
      let currentEventPoDetails = allZoneListings?.events?.find(
          (event) => {
            if(data && data?.length > 0) {
              return event?.event_id === data[0].event_id
            }
          })?.po_details?.editedActiveListings;

        if (currentEventPoDetails) {

          const totalCheckable = currentEventPoDetails?.reduce((c,eachPo) => {
            return c + (eachPo.broadcasted_inventory_count > 0 ? 1 : 0);
          },0)

          const checked = currentEventPoDetails?.reduce((c,eachPo) => {
            return c + (eachPo.is_checked ? 1 : 0);
          },0)

          if(checked < totalCheckable && checked > 0) {
            return true
          }
        }
        return false
  }


  const checkAllChecked = () => {
    
    let currentEventPoDetails = allZoneListings?.events?.find(
        (event) => {
          if(data && data?.length > 0) {
            return event?.event_id === data[0].event_id
          }
        })?.po_details?.editedActiveListings;

      if (currentEventPoDetails) {

        const totalCheckable = currentEventPoDetails?.reduce((c,eachPo) => {
          return c + (eachPo.broadcasted_inventory_count > 0 ? 1 : 0);
        },0)

        const checked = currentEventPoDetails?.reduce((c,eachPo) => {
          return c + (eachPo.is_checked ? 1 : 0);
        },0)

        if(checked === totalCheckable && checked > 0) {
          return true
        }
      }
      return false
}

  const getCheckAllHeaderJsx = () => {
    const disabled =
      data?.find((eachPoLine) => eachPoLine.broadcasted_inventory_count > 0) !==
        undefined &&
      hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION])
        ? false
        : true;
    return (
      <Checkbox
        disabled={disabled}
        indeterminate={partialChecked()}
        onChange={handleCheckboxCheckAllHandler}
        //checked={isAllSelected && data?.find(eachPoLine => eachPoLine.broadcasted_inventory_count > 0 ) !== undefined}
        checked={checkAllChecked()}
      />
    );
  };

  const handleCheckboxCheckAllHandler = (e: CheckboxChangeEvent) => {
    setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
      if (prev && data) {
        const _prev = { ...prev };
        let currentEventPoDetails = _prev.events
          .find(
            (event) => {
              return event.event_id === data[0].event_id
            })?.po_details?.editedActiveListings;
        if (currentEventPoDetails) {
          currentEventPoDetails.forEach(eachPo => {
            if(eachPo.broadcasted_inventory_count > 0) {
              eachPo.is_checked = e.target.checked;
            }
            return eachPo;
          })
        }
        return _prev;
      }
      return prev;
    });
    //setIsAllSelected(e.target.checked);
  };

  const getVenueFeesIncludedCost = (title: string) => {
    return <div>{title}<Tooltip title="Venue Fees Included"><i className="da icon-venue-fees" style={{ marginLeft: '5px'}}/></Tooltip></div>
  }

  const poTableHeaders: IAllZoneListingPOTableHeader[] = [
    {
      title: "",
      dataIndex: "seat_availability_score",
      thClassName: availabilityColor,
    },
    {
      title: "",
      dataIndex: "purchase_line_id",
      thStyle: { width: 10 },
    },
    {
      title: getCheckAllHeaderJsx(),
      dataIndex: "inventory_ids",
      thStyle: { width: 40 },
    },
    {
      title: "Po Number",
      dataIndex: "purchase_id",
      thStyle: { width: 80 },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      thClassName: "text-right",
      tdClassName: "text-right",
      thStyle: { width: 60 },
    },
    {
      title: "Section",
      dataIndex: "section",
      thStyle: { width: 150 },
    },
    {
      title: "Row",
      dataIndex: "row",
      thStyle: { width: 100 },
    },
    {
      title: "Seats",
      dataIndex: "seats",
      thStyle: { width: 50 },
    },
    {
      title: haveVenueFees ? getVenueFeesIncludedCost("Max Cost") : "Max Cost",
      dataIndex: "cost_price",
      thClassName: "text-right",
      thStyle: { width: 80 },
    },
    {
      title: haveVenueFees ? getVenueFeesIncludedCost("Alt. Cost") : "Alt. Cost",
      dataIndex: "secondary_cost",
    },
    {
      title: "List Price",
      dataIndex: "list_price",
      thStyle: { width: 120 },
    },
    {
      title: "Availability",
      dataIndex: "availability",
      thClassName: "text-center",
      thStyle: { width: 80 },
    },
    {
      title: "Zone Seating",
      dataIndex: "is_zone_seating",
      thClassName: "text-center",
      thStyle: { width: 80 },
    },
    {
      title: "Internal Notes",
      dataIndex: "internal_notes",
    },
    {
      title: "Listed On",
      dataIndex: "listed_at",
      thStyle: { width: 110 },
    },
    {
      title: "Listed By",
      dataIndex: "listed_by",
      thStyle: { width: 100 },
    },
    {
      title: "Inventory Tag",
      dataIndex: "inventory_tag",
      thStyle: { width: 100 },
    },
    {
      title: "PO Tag",
      dataIndex: "po_tags",
      thStyle: { width: 80 },
    },
    {
      title: "TB2.0",
      dataIndex: "tb_placement_check",
      thStyle: { width: 50 },
    },
    {
      title: "",
      dataIndex: "inventory_id",
      thStyle: { width: 40 },
      tdClassName: "text-center",
      tdStyle: { width: 40 },
    },
    {
      title: "",
      dataIndex: "broadcast",
      thStyle: { width: 40 },
      tdClassName: "text-center",
      tdStyle: { width: 40 },
    },
    {
      title: "",
      dataIndex: "split",
      thStyle: { width: 40, minWidth: 50 },
      tdClassName: "text-center",
      tdStyle: { width: 40 },
    },
  ];

  // const secondaryMarketplaceHeaderRender = (headerData:IMarketplace) =>{
  //   if(data){
  //     const _competitorPrice = competitorPricesListData.find(each => each.event_id ===  data[0].event_id && each.provider.toLocaleLowerCase() === headerData.name_alias.toLocaleLowerCase());  
  //     return (
  //       <div>
  //         <div>
  //           <a
  //             rel="noopener noreferrer"
  //             href={headerData.secondary_marketplace_url}
  //             style={{ color: 'inherit' }}
  //             target={"_blank"}
  //             >
  //                 {headerData.name}
  //             </a>
  //           {(_competitorPrice && _competitorPrice.metadata && _competitorPrice.metadata.length > 0) &&
  //             <span> ({_competitorPrice.metadata.length})</span>
  //           }
  //         </div>
  //         {_competitorPrice &&
  //           <div>
  //             {_competitorPrice.hasError &&
  //               <Tooltip title={_competitorPrice.messege}>
  //                 <CloseCircleOutlined style={{color: '#9F2533', fontSize: '11px', lineHeight: 'unset', marginRight: 5}}/>
  //               </Tooltip>
  //             }
  //             {_competitorPrice.updated_on &&
  //               <span>{formatDateGivenFormatString(_competitorPrice.updated_on, AppSetting.EVENT_DATE_FORMAT)} &nbsp;</span>
  //             }
              
  //             {(!_competitorPrice.isLoading && (!_competitorPrice?.updated_on || Boolean(getDateDiff( getCustomDate(new Date(_competitorPrice?.updated_on as string )), getCurrentDate() ))) ) &&

  //               <Tooltip title="Force Refresh">
  //                 <span className="da icon-refresh-blue icon-sm pointer" style={{cursor: 'pointer'}} onClick={()=>{
  //                   fetchCompetitorPrice(currentEventData?.event_id, currentEventData?.venue_id , headerData.name_alias.toLocaleLowerCase(), headerData.secondary_marketplace_url, true)
  //                 }}></span>
  //               </Tooltip>
  //             }
  //             {_competitorPrice.isLoading &&
  //               <Spin style={{fontSize: '1px'}} indicator={<LoadingOutlined spin style={{color: '#223A5E', fontSize: '13px', lineHeight: 'unset'}} />} />
  //             }
            
  //           </div>
  //         }
  //       </div>
  //     )
  //   }
  // }

  // if(currentEventData?.po_details?.secondaryMarketplace && currentEventData?.po_details?.secondaryMarketplace.length){
  //   const secondaryMarketplaceFiltered = currentEventData?.po_details?.secondaryMarketplace?.filter((eachMarket)=>eachMarket.secondary_marketplace_url && eachMarket.secondary_marketplace_url !== '');
  //   if(secondaryMarketplaceFiltered.length){
  //     const marketPlaceZoneHeaders:CommonTableHeader[] = [];
  //     secondaryMarketplaceFiltered.forEach((each, index)=>{
  //       marketPlaceZoneHeaders.push(
  //         {
  //           title: secondaryMarketplaceHeaderRender(each),
  //           dataIndex: `${each?.name_alias}`,
  //           thClassName: "availabilityTh",
  //           tdClassName: "competitive",
  //           width: 161,
  //           miscData: index
  //         },
  //       )
  //     });

  //     poTableHeaders.splice( 10, 0, ...marketPlaceZoneHeaders )
  //   }
  // }

  const onCloseSplitInventoryModal = (splitData: ISplitDatum[]) => {
    let broadcasted_inventory_count = 0,
      unbroadcasted_inventory_count = 0;
    splitData.forEach((eachInventory) =>
      eachInventory.broadcast === true
        ? broadcasted_inventory_count++
        : unbroadcasted_inventory_count++
    );
    setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
      if (prev) {
        const _prev = { ...prev };
        // update original active listings
        let currentEventPoLineDetailsActive = _prev.events
          .find((event) => event.event_id === splitData[0]?.event_id)
          ?.po_details?.activeListings.find(
            (eachPoLine) =>
              eachPoLine.purchase_line_id === splitData[0]?.purchase_line_id
          );
       
        if (currentEventPoLineDetailsActive) {
          currentEventPoLineDetailsActive.unbroadcasted_inventory_count =
            unbroadcasted_inventory_count;
          currentEventPoLineDetailsActive.broadcasted_inventory_count =
            broadcasted_inventory_count;
            if(currentEventPoLineDetailsActive.is_checked){
              currentEventPoLineDetailsActive.is_checked = broadcasted_inventory_count !== 0 
            }
        }
        // update edited active listings
        let currentEventPoLineDetailsEdited = _prev.events
          .find((event) => event.event_id === splitData[0]?.event_id)
          ?.po_details?.editedActiveListings.find(
            (eachPoLine) =>
              eachPoLine.purchase_line_id === splitData[0]?.purchase_line_id
          );
        if (currentEventPoLineDetailsEdited) {
          currentEventPoLineDetailsEdited.unbroadcasted_inventory_count =
            unbroadcasted_inventory_count;
          currentEventPoLineDetailsEdited.broadcasted_inventory_count =
            broadcasted_inventory_count;
          if(currentEventPoLineDetailsEdited.is_checked){
            currentEventPoLineDetailsEdited.is_checked = broadcasted_inventory_count !== 0 
          }
        }
        return _prev;
      }
      return prev;
    });
  };

  return (
    <React.Fragment>
      <table className="zonePOTbl">
        <thead>
          <tr>
            {poTableHeaders.map((eachHeader) => {
              return (
                <React.Fragment
                  key={`poTable_${eachHeader.dataIndex}_${eachHeader.title}`}
                >
                  {(!eachHeader.allowedPermissions ||
                    hasPermissions(eachHeader.allowedPermissions)) && (
                    <th
                      className={`${
                        eachHeader.thClassName ? eachHeader.thClassName : ""
                      }`}
                      style={{
                        backgroundColor: "#F7DDB5",
                        borderRadius: "0",
                        textTransform: "unset",
                        ...eachHeader.thStyle,
                      }}
                    >
                      {eachHeader.title}
                    </th>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((data: IActiveListing) => (
              <React.Fragment key={`row_${data.inventory_id}}`}>
                <AllZoneListingPoTableDataRow
                  poTableHeaders={poTableHeaders}
                  data={data}
                  otherData={otherData}
                  setShowSplitView={setShowSplitView}
                  setCurrentClickedInventory={setCurrentClickedInventory}
                  setAllZoneListings={setAllZoneListings}
                  rowSaveClickHandler={rowSaveClickHandler}
                  checkIsAllSelected={checkIsAllSelected}
                  // competitorPricesListData={competitorPricesListData}
                  currentEventData={currentEventData}
                />
              </React.Fragment>
            ))}
          {(!data || data.length === 0) && (
            <tr className="tableNoResultFound">
              <td colSpan={45}>
                <div className="noResultFound noresult-po">No Result Found</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showSplitView && (
        <SplitInventoryView
          inventory_id={currentClickedInventory}
          showSplitView={showSplitView}
          setShowSplitView={setShowSplitView}
          onCloseGetSplitInventoryData={onCloseSplitInventoryModal}
        />
      )}
    </React.Fragment>
  );
};

export default AllZoneListingPoTable;
