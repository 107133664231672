import { HighRiskCategory } from "models/enums/highRiskCategory";
import { IHighRiskListings } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import ListingAlertsCard from "./ListingAlertsCard";

const PricingIssue: React.FC<{
  widgetId: number;
}> = ({ widgetId }) => {
  const [isLoadingPricingIssue, setIsLoadingPricingIssue] = useState(false);
  const [pricingIssueFetchError, setPricingIssueFetchError] =
    useState<ICustomError | null>(null);
  const [pricingIssueData, setPricingIssueData] =
    useState<IHighRiskListings | null>(null);

  const getPricingIssue = async () => {
    setIsLoadingPricingIssue(true);
    try {
      const response =
        await new OperationsDashboardService().getHighRiskListings({
          risk_type: HighRiskCategory.pricing_issue,
        });
      setPricingIssueData(response.data.data);
      setIsLoadingPricingIssue(false);
      setPricingIssueFetchError(null);
    } catch (error: any) {
      setIsLoadingPricingIssue(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getPricingIssue",
      });
      setPricingIssueFetchError({
        ...error,
        refreshAction: getPricingIssue,
      });
    }
  };

  useEffect(() => {
    getPricingIssue();
  }, []);

  return (
    <div className="highRiskCardCol">
      <ListingAlertsCard
        errorObj={pricingIssueFetchError}
        title={"PRICING ISSUES"}
        isLoading={isLoadingPricingIssue}
        data={pricingIssueData}
        id={widgetId}
      />
    </div>
  );
};

export default PricingIssue;
