import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

import {
    actions,
    userData,
    isUserDataLoading,
  } from "models/Slices/GlobalSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Forbidden } from "routes/Forbidden";
import PageLoader from "utils/sharedComponents/PageLoader";

const useRbac = () =>{
    const { user } = useAuth0();
    const dispatch = useAppDispatch();
    const userDetails =  useAppSelector(userData);
    const isLoadingRbac = useAppSelector(isUserDataLoading);
    const auth0UserDetails = user;
    
    useEffect(()=>{
        if(!userDetails){
            dispatch(actions.getUserDataAction());
        }
    }, [userDetails, dispatch]);

    const hasPermissions = (permissions: string[]) => {
        if(!userDetails?.permissions && userDetails?.permissions.length === 0) {
            return false;
        }
        return userDetails?.permissions.some(value => permissions.includes(value));
    }

    const hasRoles = (roles: string[]) => {
        if(!userDetails?.permissions && userDetails?.permissions.length === 0) {
            return false;
        }
        return userDetails?.role_alias_list.some(value => roles.includes(value));
    }

    return { isLoadingRbac, userDetails, hasPermissions, hasRoles, auth0UserDetails};
}

const withRbac = (WrappedComponent: React.ComponentType, params?:{allowedPermissions?: string[], isAccessFromPage?: boolean}) => {
    return (props: any) => {
      const {isLoadingRbac, userDetails, hasPermissions, hasRoles, auth0UserDetails} = useRbac();
      return (
        <React.Fragment>
            {!isLoadingRbac &&
                <React.Fragment>
                    {params && params.allowedPermissions &&
                        <React.Fragment>
                            {hasPermissions(params?.allowedPermissions) &&
                                <WrappedComponent {...props} userDetails={userDetails} auth0UserDetails={auth0UserDetails} hasPermissions={hasPermissions} hasRoles={hasRoles} isLoadingRbac={isLoadingRbac} />
                            }
                            {!hasPermissions(params.allowedPermissions) &&
                                <Forbidden isAccessFromPage={params.isAccessFromPage} />
                            }
                        </React.Fragment>
                    }

                    {(!params || !params.allowedPermissions) &&
                        <WrappedComponent {...props} userDetails={userDetails} auth0UserDetails={auth0UserDetails} hasPermissions={hasPermissions} hasRoles={hasRoles} isLoadingRbac={isLoadingRbac} />
                    }
                </React.Fragment>
            }
            {isLoadingRbac &&
                <PageLoader />
            }
        </React.Fragment>
      );
    };
}

const RBAC: React.FC<{
    allowedPermissions?: string[],
    allowedRoles?: string[],
  }> = ({ allowedPermissions, allowedRoles, children }) => {
    const {isLoadingRbac, hasPermissions, hasRoles} = useRbac();

    const checkRoleAndPermission = () => {
        if(isLoadingRbac) {
            return false;
        }
        else if(allowedPermissions && allowedRoles && (hasPermissions(allowedPermissions) && hasRoles(allowedRoles))){
            return true;
        }
        else if(allowedPermissions && !allowedRoles && hasPermissions(allowedPermissions)){
            return true;
        }
        else if(allowedRoles && !allowedPermissions && hasRoles(allowedRoles)){
            return true;
        }
        else{
            return false;
        }
    }

    if(checkRoleAndPermission()){
        return <React.Fragment>{children}</React.Fragment>
    }
    else{
        return null;
    }
};

export {
    useRbac,
    withRbac,
    RBAC,
}