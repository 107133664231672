import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Select, InputNumber, Button, message } from "antd";
import { useAppSelector } from "app/hooks";
import {
  skyboxUsers,
  roles,
  splitTypes,
  brokers,
  buyerTags,
  listerTags,
  ticketbashBrokers,
  skyboxVendors,
} from "models/Slices/FilterSlice";
import { UserService } from "services";
import { LoggerService } from "sharedServices/api/logger.service";
import { UserDetailsData } from "models/interfaces";
import { filterSortByRanking } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import UserProfileImageEdit from "./UserProfileImageEdit";

const userService = new UserService();

const { Option } = Select;

const NewUserForm: React.FC<{
  isModalVisible: boolean;
  onSuccess: Function;
  closeModal: Function;
  userData: any;
  setUserData: Function;
  oldUserData?: UserDetailsData;
}> = ({
  isModalVisible,
  onSuccess,
  closeModal,
  userData,
  setUserData,
  oldUserData,
}) => {

  const brokerListSkyBox = useAppSelector(brokers);
  const skyBoxUserList = useAppSelector(skyboxUsers);
  const rolesList = useAppSelector(roles);
  const splitTypeList = useAppSelector(splitTypes);
  const buyerTagList = useAppSelector(buyerTags);
  const listerTagList = useAppSelector(listerTags);
  const ticketbashBrokerList = useAppSelector(ticketbashBrokers);
  const vendorsList = useAppSelector(skyboxVendors);

  const [canChangeUserEmail, setCanChangeUserEmail] = useState(true);
  const [isFetchingAuth0UserDetails, setIsFetchingAuth0UserDetails] =
    useState(false);
  const [isSubmittingUser, setIsSubmittingUser] = useState(false);
  const [selectedRoleAlias, setSelectedRoleAlias] = useState<Array<string>>([]);
  const [inputValue, setInputValue] = useState("");
  const [newProfileImageString, setNewProfileImageString] = useState<string | null>(null);
  const [newProfileImageName, setNewProfileImageName] = useState<string | null>(null);

  const skyboxUserListData = skyBoxUserList.filter((skyBoxUser) => {
    return !skyBoxUser.userId || skyBoxUser.value === userData.skyboxUserId;
  });

  const handleOnSearch = useCallback((value: React.SetStateAction<string>) => {
    setInputValue(value);
  },[inputValue]);

  useEffect(() => {
    if (oldUserData && /^auth0.*$/.test(oldUserData.id)) {
      getUserAuth0Data(oldUserData.id);
    }
  }, [oldUserData]);

  const getUserAuth0Data = async (umUserId: string) => {
    try {
      setIsFetchingAuth0UserDetails(true);
      const response = await userService.getAuth0UserDetails(umUserId);
      setIsFetchingAuth0UserDetails(false);
      if (response.data.data.userAuth0Data.email_verified) {
        setCanChangeUserEmail(false);
      }
    } catch (error) {
      closeModal();
      message.error({
        content: "Failed to fetch user details.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
  };

  const [markupTypes] = useState([
    {
      value: 1, //percentage
      label: "%",
    },
    {
      value: 2, //actual_value
      label: "$",
    },
  ]);

  useEffect(() => {
    let _selectedRoleAlias: string[] = [];
    rolesList.forEach((eachRole) => {
      if (eachRole.name_alias && userData.roleIds.indexOf(eachRole.id) >= 0) {
        return _selectedRoleAlias.push(eachRole.name_alias);
      }
    });
    setSelectedRoleAlias(_selectedRoleAlias);
  }, [userData, rolesList]);

  const hasBrokerRole = () => {
    if (selectedRoleAlias.indexOf("broker") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const hasBuyerRole = () => {
    if (selectedRoleAlias.indexOf("buyer") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const hasListerRole = () => {
    if (selectedRoleAlias.indexOf("lister") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const hasSeatMapCreatorRole = () => {
    if (selectedRoleAlias.indexOf("seatMapCreator") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const hasCheckerRole = () => {
    if (selectedRoleAlias.indexOf("checker") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filedName = event.target.name;
    const filedValue: any = event.target.value;
    setUserData((prev: any) => ({ ...prev, [filedName]: filedValue }));
  };

  const changeSkyBoxUser = (skyboxUserId?: number) => {
    const _userData = { ...userData };
    if (skyboxUserId) {
      const skyboxUserFind = skyBoxUserList.find((eachUser) => {
        return eachUser.value === skyboxUserId;
      });
      if (skyboxUserFind && !userData.email) {
        _userData.email = skyboxUserFind.label;
      }
      _userData.skyboxUserId = skyboxUserId;
    } else {
      _userData.skyboxUserId = null;
    }
    setUserData(_userData);
  };

  const changeRole = (role: any) => {
    let _roleIds = userData.roleIds;
    if (role.target.checked) {
      if (_roleIds.indexOf(role.target.value) < 0) {
        _roleIds = [..._roleIds, role.target.value];
      }
    } else {
      _roleIds = userData.roleIds.filter(
        (item: string) => item !== role.target.value
      );
    }

    setUserData((prev: any) => ({ ...prev, roleIds: _roleIds }));
  };

  const hasSelectedRole = (roleId: string) => {
    return (
      userData &&
      userData.roleIds.find((eachRoleId: string) => eachRoleId === roleId)
    );
  };

  const onDefaultListingQuantityChange = (value: number) => {
    setUserData((prev: any) => ({ ...prev, defaultListingQuantity: value }));
  };

  const onDefaultMarkupChange = (value: number) => {
    setUserData((prev: any) => ({
      ...prev,
      defaultMarkup: value ? value : null,
    }));
  };

  const onMinMarkupChange = (value: number) => {
    setUserData((prev: any) => ({
      ...prev,
      minMarkup: value ? value : null,
    }));
  };

  const onBrokerTagChange = (value: number[]) => {
    setInputValue("");
    setUserData((prev: any) => ({
      ...prev,
      brokerTagId: value?.length ? value : null,
    }));
  };

  const onVendorChange = useCallback((value: number) => {
    setInputValue("");
    setUserData((prev: any) => ({
      ...prev,
      vendor: value ? value : null,
    }));
  },[inputValue, userData]);

  const onChangeDefaultSplitType = (value: number) => {
    setUserData((prev: any) => ({
      ...prev,
      defaultSplitType: value ? value : null,
    }));
  };

  const onChangeDefaultInHandDays = (value: number) => {
    setUserData((prev: any) => ({
      ...prev,
      defaultInHandDays: value ? value : null,
    }));
  };

  const onBuyerTagChange = (value: number) => {
    setInputValue("");
    setUserData((prev: any) => ({ ...prev, buyerTagId: value ? value : null }));
  };

  const onListerTagChange = (value: number) => {
    setInputValue("");
    setUserData((prev: any) => ({
      ...prev,
      listerTagId: value ? value : null,
    }));
  };

  const onAssignToBroker = (value: string) => {
    setUserData((prev: any) => ({
      ...prev,
      brokerUserId: value ? value : null,
    }));
  };

  const isValidated = () => {
    if (!userData.firstName || userData.firstName.trim() === "") {
      return false;
    } else if (!userData.lastName || userData.lastName.trim() === "") {
      return false;
    } else if (hasBrokerRole() && userData.defaultMarkupType === 1 && (userData.defaultMarkup < userData.minMarkup)) {
      return false;
    } else if (!userData.email || userData.email.trim() === "") {
      return false;
    } else if (userData.email !== "") {
      var validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!userData.email.trim().match(validRegex)) {
        return false;
      }
    }

    if (!userData.roleIds || !userData.roleIds.length) {
      return false;
    }

    if (hasBrokerRole()) {
      if (userData.brokerTagId === null ||
         !userData.brokerTagId?.length ||
         !userData.defaultListingQuantity ||
        isNaN(userData.defaultListingQuantity) ||
        !userData.defaultMarkup ||
        isNaN(userData.defaultMarkup)
      ) {
        return false;
      }
    }
    if (hasBuyerRole()) {
      if (!userData.buyerTagId) {
        return false;
      }
      if (!hasBrokerRole() && (!userData.brokerUserId || userData.brokerUserId === userData.umUserId)) {
        return false;
      }
    }
    if (hasListerRole() || hasSeatMapCreatorRole() || hasCheckerRole()) {
      if (hasListerRole() && !userData.listerTagId) {
        return false;
      }
      if (!hasBrokerRole() && (!userData.brokerUserId || userData.brokerUserId === userData.umUserId)) {
        return false;
      }
    }
    return true;
  };

  const saveUser = async () => {
    let _userData = { ...userData };
    _userData.firstName = _userData.firstName.trim();
    _userData.lastName = _userData.lastName.trim();
    _userData.email = _userData.email.trim();
    _userData.oldProfilePicturePublicPath = oldUserData?.profile_picture_public_path ?? null;
    _userData.oldProfilePicturePath = oldUserData?.profile_picture_path ?? null;
    _userData.newProfileImageString = newProfileImageString;
    _userData.newProfileImageName = newProfileImageName;

    if (!hasBrokerRole()) {
      _userData.brokerTagId = null;
      _userData.defaultListingQuantity = null;
      _userData.defaultInHandDays = null;
      _userData.defaultSplitType = null;
      _userData.defaultMarkupType = null;
      _userData.defaultMarkup = null;
      _userData.minMarkup = null;
      _userData.internalNotes = null;
      _userData.vendor = null;
    }
    if (!hasBuyerRole()) {
      _userData.buyerTagId = null;
    }
    if (!hasListerRole()) {
      _userData.listerTagId = null;
    }
    if ((!hasBuyerRole() && !hasListerRole() && !hasSeatMapCreatorRole() && !hasCheckerRole()) || hasBrokerRole()) {
      _userData.brokerUserId = null;
    }

    _userData.selectedRoleAlias = selectedRoleAlias;
    if (oldUserData && oldUserData.team_user && !hasBrokerRole()) {
      const teamUser = oldUserData.team_user;
      if (teamUser.length > 1) {
        message.error({
          content: `There are members in the broker's team. Please reassign them before changing the role.`,
          className: "toastMsg savedFailed",
        });
        return false;
      }
    }

    setIsSubmittingUser(true);

    try {
      const result = await userService.createOrUpdateUser(_userData);
      if (result.data.data.success) {
        onSuccess(true);
        message.success({
          content: "User successfully saved.",
          className: "toastMsg savedSuccess",
        });
      } else {
        message.error({
          content: result.data.data.message,
          className: "toastMsg savedFailed",
        });
      }
      setIsSubmittingUser(false);
    } catch (error) {
      new LoggerService().log({
        payload: error,
        function_name: "saveUser",
      });
      message.error({
        content: "Something went wrong.",
        className: "toastMsg savedFailed",
      });
      setIsSubmittingUser(false);
    }
  };

  const setFilterOptions =  (input: string, option: any) => {
    return option?.label
      ?.toLowerCase()
      .includes(input?.toLowerCase())
      ? true
      : false;
  };

  const dropdownOnSelectHandler = useCallback(() => {
    setInputValue("");
  },[inputValue]);

  const onDropdownVisibleChangeHandler = useCallback(
    (open: boolean) => {
      open && setInputValue("");
    },
    [inputValue]
  );

  const filterOptionHandler = useCallback(
    (input: string, option: any) => setFilterOptions(input, option),
    [setFilterOptions]
  );

  const minMarkupChangeHandler = useCallback((value)=>{
    if(value){
      onMinMarkupChange(value);
    }
  },[onMinMarkupChange]);

  return (
    <LoadingSpinner isLoading={isFetchingAuth0UserDetails}>
      {isModalVisible && (
        <div className="">
          {!oldUserData && <h3>Add New User</h3>}
          {oldUserData && <h3>Edit User Info &amp; Settings</h3>}

          <div className="">
            {!oldUserData && <h5>General Info</h5>}
            <div className="ant-row">
              <div className="ant-col ant-col-xs-17 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                <div className="inputGroup">
                  <label>Skybox User ID</label>
                  <Select
                    showSearch
                    value={userData.skyboxUserId}
                    placeholder="Select"
                    onChange={changeSkyBoxUser}
                    allowClear={userData.skyboxUserId ? true : false}
                    onSelect={dropdownOnSelectHandler}
                    onDropdownVisibleChange={onDropdownVisibleChangeHandler}
                    onSearch={handleOnSearch}
                    filterOption={filterOptionHandler}
                    filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
                    options={skyboxUserListData}
                  />
                </div>
              </div>
              <div className="ant-col ant-col-xs-7 ant-col-sm-12 ant-col-lg-16 ant-col-xl-16 text-right">
                <div className="profileImgWrap">
                  <label className="profileImgLabel">Profile Image</label>
                  <UserProfileImageEdit 
                  oldProfilePicturePublicPath={oldUserData?.profile_picture_public_path}
                  onChange={(newImageString: string, imageFileName: string)=>{
                    setNewProfileImageString(newImageString);
                    setNewProfileImageName(imageFileName);
                  }}
                  />
                </div>
              </div>
            </div>

            <div className="ant-row">
              <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                <div className="inputGroup">
                  <label>
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Your first name"
                    onChange={onInputChange}
                    value={userData.firstName}
                    name="firstName"
                    type="text"
                    className="ant-input"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                <div className="inputGroup">
                  <label>
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Your last name"
                    onChange={onInputChange}
                    value={userData.lastName}
                    name="lastName"
                    type="text"
                    className="ant-input"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                <div className="inputGroup">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Your email address"
                    value={userData.email}
                    onChange={onInputChange}
                    name="email"
                    type="email"
                    className="ant-input"
                    maxLength={150}
                    disabled={!canChangeUserEmail}
                  />
                </div>
              </div>
            </div>

            <div className="ant-row">
              <div className="ant-col ant-col-xl-24">
                <div className="inputGroup">
                  <label>
                    Roles <span className="text-danger">*</span>
                  </label>
                  <ul className="checkboxList checklistForEditUser ant-row">
                    {rolesList.map((eachRole) => {
                      return (
                        <li key={eachRole.id} className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                          <Checkbox
                            onChange={changeRole}
                            value={eachRole.id}
                            checked={hasSelectedRole(eachRole.id)}
                          >
                            {eachRole.name}
                          </Checkbox>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="ant-row">
              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Broker Tag <span className="text-danger">*</span>
                    </label>
                    <Select
                      mode="multiple"
                      placeholder="Select"
                      showSearch
                      onSelect={dropdownOnSelectHandler}
                      onDropdownVisibleChange={onDropdownVisibleChangeHandler}
                      onSearch={handleOnSearch}
                      allowClear={userData.brokerTagId ? true : false}
                      filterOption={filterOptionHandler}
                      filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
                      onChange={onBrokerTagChange}
                      defaultValue={
                        userData.brokerTagId?.length > 0
                          ? userData.brokerTagId
                          : []
                      }
                      options={brokerListSkyBox}
                    />
                  </div>
                </div>
              )}

              {hasBuyerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Buyer Tag <span className="text-danger">*</span>
                    </label>
                    <Select
                      showSearch
                      placeholder="Select"
                      allowClear={userData.buyerTagId ? true : false}
                      value={userData.buyerTagId}
                      onChange={onBuyerTagChange}
                      onSelect={dropdownOnSelectHandler}
                      onDropdownVisibleChange={onDropdownVisibleChangeHandler}
                      onSearch={handleOnSearch}
                      filterOption={filterOptionHandler}
                      filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
                      options={buyerTagList}
                    />
                  </div>
                </div>
              )}

              {hasListerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Lister Tag <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      allowClear={userData.listerTagId ? true : false}
                      value={userData.listerTagId}
                      showSearch
                      onChange={onListerTagChange}
                      onSearch={handleOnSearch}
                      filterOption={filterOptionHandler}
                      filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
                      options={listerTagList}
                    />
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Default Listing Quantity{" "}
                      <span className="text-danger">*</span>
                    </label>
                    {/* <input placeholder="Default Listing Quantity"  type="number"  min={1} className="ant-input" maxLength={5}/> */}
                    <InputNumber
                      precision={0}
                      min={1}
                      max={userData.max_tickets_allowed}
                      step={1}
                      value={userData.defaultListingQuantity}
                      onChange={(value)=>{
                        if(value){
                          onDefaultListingQuantityChange(value);
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Default In Hand Date (Days) {" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      value={
                        userData.defaultInHandDays
                          ? userData.defaultInHandDays
                          : 3
                      }
                     onChange={onChangeDefaultInHandDays}
                    >

                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>
                      <Option value={6}>6</Option>
                      <Option value={7}>7</Option>
                    </Select>
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>Default Split Type</label>
                    <Select
                      placeholder="Select"
                      defaultValue={1}
                      value={
                        userData.defaultSplitType
                          ? userData.defaultSplitType
                          : 1
                      }
                      onChange={onChangeDefaultSplitType}
                      allowClear={userData.defaultSplitType ? true : false}
                      options={splitTypeList}
                    />
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Default Markup <span className="text-danger">*</span>
                    </label>
                    <div className="groupAddon">
                      <span
                        className="addon"
                        onClick={() => {
                          const _userData = { ...userData };
                          _userData.defaultMarkupType =
                            _userData.defaultMarkupType === 1 ? 2 : 1;
                          if (
                            _userData.defaultMarkup &&
                            _userData.defaultMarkupType === 1 &&
                            _userData.defaultMarkup > 100
                          ) {
                            _userData.defaultMarkup = 100;
                          }
                          setUserData(_userData);
                        }}
                      >
                        {
                          markupTypes.find(
                            (eachType) =>
                              eachType.value === userData.defaultMarkupType
                          )?.label
                        }
                      </span>

                      <InputNumber
                        onChange={(value)=>{
                          if(value){
                            onDefaultMarkupChange(value);
                          }
                        }}
                        value={userData.defaultMarkup}
                        precision={2}
                        min={0.1}
                        max={userData.defaultMarkupType === 1 ? 100 : 900000}
                      />
                    </div>
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Default Vendor
                    </label>
                    <Select
                      placeholder="Select"
                      showSearch
                      onSelect={dropdownOnSelectHandler}
                      onDropdownVisibleChange={onDropdownVisibleChangeHandler}
                      onSearch={handleOnSearch}
                      allowClear={userData.vendor ? true : false}
                      filterOption={filterOptionHandler}
                      filterSort={(optionA: any, optionB: any) => filterSortByRanking(optionA, optionB, inputValue)}
                      onChange={onVendorChange}
                      defaultValue={
                        userData.vendor && !isNaN(userData.vendor)
                          ? userData.vendor
                          : []
                      }
                      options={vendorsList}
                    />
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Default Internal Notes
                    </label>
                    <input
                      placeholder="Internal notes"
                      value={userData.internalNotes}
                      onChange={onInputChange}
                      name="internalNotes"
                      type="text"
                      className="ant-input"
                      maxLength={1000}
                      disabled={false}
                    />
                  </div>
                </div>
              )}

              {hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Min. Markup %
                    </label>
                    <div className="groupAddon">
                      <InputNumber
                        onChange={minMarkupChangeHandler}
                        value={userData.minMarkup}
                        precision={2}
                        min={1}
                        max={100}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(hasBuyerRole() || hasListerRole() || hasSeatMapCreatorRole() || hasCheckerRole()) && !hasBrokerRole() && (
                <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-lg-8 ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Assign to Broker <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      allowClear={userData.brokerUserId ? true : false}
                      value={
                        userData.brokerUserId !== userData.umUserId
                          ? userData.brokerUserId
                          : null
                      }
                      onChange={onAssignToBroker}
                    >
                      {ticketbashBrokerList.map((eachBrokerUser) => {
                        return (
                          <React.Fragment key={eachBrokerUser.email}>
                            {eachBrokerUser.id !== userData.umUserId && (
                              <Option value={eachBrokerUser.id}>
                                {eachBrokerUser.first_name +
                                  " " +
                                  eachBrokerUser.last_name}
                              </Option>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="editUserInfoFooter">
            {!isSubmittingUser && (
              <Button
                className="linkBtn"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </Button>
            )}
            <Button
              type="primary"
              disabled={!isValidated() || isSubmittingUser}
              className="ant-btn btnOk"
              onClick={saveUser}
              loading={isSubmittingUser}
            >
              SAVE
            </Button>
          </div>
        </div>
      )}
    </LoadingSpinner>
  );
};

export default NewUserForm;
