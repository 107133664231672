import { Layout, Menu } from "antd";
import { isMobile } from "react-device-detect";
import {
  isHamMenuVisible,
  isLeftPanelVisible,
  toggleHamMenu,
  toggleLeftPanel
} from "models/Slices/GlobalSlice";

import { useAppSelector, useAppDispatch } from "app/hooks";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRbac } from "features/rbac/rbac";
import { IMenu } from "models/interfaces";
import { useEffect, useState } from "react";
import {
  LeftNavMenuKeysEnum,
  LeftNavRoutesEnum
} from "models/enums/leftNavRoutes";
import LogoForMob from "assets/images/logo-white.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { initialMenu } from "./LayoutUtils";
const { SubMenu } = Menu;
const { Sider } = Layout;

const AppLeftPanel: React.FC<{
  defaultOpenKeys: string[];
  setDefaultOpenKeys: Function;
  selectedKeys: string[];
  setSelectedKeys: Function;
}> = ({
  defaultOpenKeys,
  setDefaultOpenKeys,
  selectedKeys,
  setSelectedKeys
}) => {
  const { hasPermissions, isLoadingRbac } = useRbac();
  const { logout, user } = useAuth0();
  const leftPanelActive = useAppSelector(isLeftPanelVisible);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const hamMenuActive = useAppSelector(isHamMenuVisible);

  const [menu, setMenu] = useState<IMenu[]>([]);

  useEffect(() => {
    if (!isLoadingRbac) {
      initialMenu.forEach((eachMenu) => {
        const children = eachMenu.child.filter((eachChild) => {
          return (
            eachChild.allowed_permissions &&
            hasPermissions(eachChild.allowed_permissions)
          );
        });
        if (children !== undefined && children.length > 0) {
          eachMenu.navigationLink = children[0].navigationLink;
          eachMenu.child = isMobile
            ? children.filter(
                (eachChild) => eachChild.show_in_mobile_view === true
              )
            : children;
          if (eachMenu.child.length > 0) {
            setMenu((prev) => {
              prev.push(eachMenu);
              return prev;
            });
          }
        }
      });
      menu.sort((a, b) => a.displayOrder - b.displayOrder);
    }
  }, [isLoadingRbac]);

  useEffect(() => {
    const path = location.pathname;
    switch (path.split("/")[1]) {
      case "dashboard":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.DASHBOARD]);
        break;
      case "zones":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.ZONES]);
        break;
      case "trading":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.TRADING]);
        break;
      case "housekeeping":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.HOUSEKEEPING]);
        setSelectedKeys([LeftNavRoutesEnum.HOUSEKEEPING_EVENT_MAPPING]);
        break;
      case "messages":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.MESSAGES]);
        break;
      case "insights":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.INSIGHTS]);
        break;
      case "reports":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.INSIGHTS]);
        setSelectedKeys([LeftNavRoutesEnum.INSIGHTS_REPORTS]);
        break;
      case "settings":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.SETTINGS]);
        break;
      case "purchases":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.PURCHASES]);
        setSelectedKeys([LeftNavRoutesEnum.PURCHASE_QUEUE]);
        break;
      case "shipping":
        setDefaultOpenKeys([LeftNavMenuKeysEnum.SHIPPING]);
        setSelectedKeys([LeftNavRoutesEnum.SHIPPING_QUEUE]);
        break;
      default:
        setDefaultOpenKeys([LeftNavMenuKeysEnum.DASHBOARD]);
        break;
    }

    if (path.split("/")[2] === "cancelled-shows") {
      setSelectedKeys([LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS]);
    } else {
      path.split("/")[1] !== "reports" &&
        path.split("/")[1] !== "purchases" &&
        path.split("/")[1] !== "" &&
        setSelectedKeys([path]);
    }
  }, [location.pathname]);

  return (
    <Sider
      width={170}
      className={`sidebarSiderWrap mobNav ${hamMenuActive ? "active" : ""}`}
      collapsed={leftPanelActive}
      onCollapse={(collapsed) => {
        console.log(collapsed);
      }}
    >
      <div className="sidebarHeader">
        <button
          type="button"
          className={"toggleSidebarBtn" + (leftPanelActive ? " active" : "")}
          onClick={() => {
            dispatch(toggleLeftPanel(leftPanelActive));
          }}
        >
          <i className="da icon-arrow-back-circle" />
        </button>
        <span
          className={`closeSidebarBtn ${hamMenuActive ? "active" : ""}`}
          onClick={() => {
            dispatch(toggleHamMenu(hamMenuActive));
          }}
        >
          <i className="da icon-close-white" />
        </span>
      </div>
      <div className="sidebarLogo">
        <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
          <img src={LogoForMob} alt="" title="Ticket Bash" />
        </a>
      </div>
      <Menu
        mode="inline"
        className="leftMenu"
        triggerSubMenuAction={"click"}
        defaultOpenKeys={defaultOpenKeys}
        openKeys={defaultOpenKeys}
        selectedKeys={selectedKeys}
        onOpenChange={(openKeys) => {
          if (openKeys.length > 0) {
            setDefaultOpenKeys([openKeys[openKeys.length - 1].toString()]);
          }
        }}
        onClick={(a) => {
          setSelectedKeys([a.key]);
          setDefaultOpenKeys([a.keyPath[1]]);
        }}
      >
        {menu &&
          menu.map((subMenu) => (
            <SubMenu
              key={subMenu.key}
              title={subMenu.menuTitle}
              icon={<i className={subMenu.iconClass} />}
              onTitleClick={() => {
                subMenu.navigationLink && history.push(subMenu.navigationLink);
                setSelectedKeys(subMenu.key);
              }}
              popupClassName={"hideSubMenu"}
            >
              {subMenu.child.map((menuItem) => (
                <Menu.Item key={menuItem.key}>
                  {menuItem.navigationLink && (
                    <Link to={menuItem.navigationLink}>
                      {menuItem.menuTitle}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
      </Menu>
      <div className="leftPanelFooter forMob">
        <span className="avatarIcon" title={user?.nickname}>
          <i className="da icon-user-w" />
        </span>
        <span
          className="logOut"
          onClick={() =>
            logout({
              returnTo: window.location.origin
            })
          }
        >
          Logout
        </span>
      </div>
    </Sider>
  );
};

export default AppLeftPanel;
