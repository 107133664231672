import { Tooltip, Typography } from "antd";
import { FixedColumnDirection } from "models/enums/table";
import { IDaysTo } from "models/interfaces";
import React from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";

export const showBrokerOrLister = (
  brokersOrLister: string[] | null
) => {
  if (brokersOrLister && brokersOrLister.length) {
    return addToolTip(brokersOrLister, "top", "default", 15, true);
  }
  return <span>Unknown</span>;
};

const getDaysToJsx = (days_to_purchase: IDaysTo) => {
  return (
    <Tooltip
        placement="left"
        title={days_to_purchase.tooltip_text}
      >
        <div
          className={
            "daysToEvent daysToEventData " +
            days_to_purchase.class
          }
        >
          <span style={{ cursor: "default" }}>{days_to_purchase.days}</span>
        </div>
      </Tooltip>
  );
};

export const deliveredTicketsHeaders = [
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    canSort: true,
    width: 100,
    fixed: FixedColumnDirection.left,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Line ID",
    dataIndex: "invoice_line_id",
    canSort: true,
    width: 130,
    fixed: FixedColumnDirection.left,
    dataRender: (basePoNumber: number[] | null) => {
      const poNumber = basePoNumber?.filter((v, i, a) => a.indexOf(v) === i);
      return (
        <React.Fragment>
          {poNumber && poNumber?.length > 1 && (
            <Tooltip
              title={poNumber.map((each: number, index: number) => {
                return (
                  <React.Fragment>
                    {`${each}`}
                    {`${index < poNumber.length - 1 ? ", " : ""}`}
                  </React.Fragment>
                );
              })}
              className="nowrapTxt"
            >
              <span style={{ cursor: "default" }}>Multiple</span>
            </Tooltip>
          )}
          {poNumber && poNumber?.length === 1 && (
              `${poNumber[0]}`
          )}
        </React.Fragment>
      );
    },
  },
  {
    title: "Invoice Date",
    dataIndex: "invoice_date",
    canSort: true,
    width: 100,
    fixed: FixedColumnDirection.left,
    dataRender: (invoiceDate: string) =>
      formatDateGivenFormatString(invoiceDate, "ddd, MMM DD, YYYY"),
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoice_tags",
    canSort: true,
    width: 150,
    dataRender: (invoiceTags: string) =>
      invoiceTags?.split(' ').join(', '),
  },
  {
    title: "Notes",
    dataIndex: "notes",
    canSort: true,
    width: 150,
    dataRender: (notes: string) =>
      notes,
  },
  {
    title: "Event Name",
    dataIndex: "event_name",
    canSort: true,
    width: 180,
    tdClassName: "text-capitalize",
    dataRender: (event_name: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.event_id}
      >
        {event_name?.length > 40
          ? addToolTip(event_name, "right", "pointer", 40, false, {
              textTransform: "capitalize",
            })
          : event_name}
      </a>
    ),
  },
  {
    title: "EVENT DATE & TIME",
    dataIndex: "event_date",
    canSort: true,
    width: 180,
    thClassName: "text-left dateTimeCol",
    dataRender: (data: string) =>
      formatDateGivenFormatString(data, "ddd, MMM DD, YYYY h:mm A"),
  },
  {
    title: "PO Number",
    dataIndex: "purchase_id",
    canSort: true,
    width: 120,
    thClassName: "text-left eventDateTimeTh",
    dataRender: (basePoNumber: number[] | null) => {
      const poNumber = basePoNumber?.filter((v, i, a) => a.indexOf(v) === i);
      return (
        <React.Fragment>
          {poNumber && poNumber?.length > 1 && (
            <Tooltip
              title={poNumber.map((each: number, index: number) => {
                return (
                  <React.Fragment>
                    <Typography.Link
                      target="_blank"
                      rel="noreferrer"
                      href={AppSetting.PURCHASE_ID_URL + each}
                      underline={true}
                      style={{ color: "#1890ff" }}
                    >
                      {`${each}`}
                    </Typography.Link>
                    {`${index < poNumber.length - 1 ? ", " : ""}`}
                  </React.Fragment>
                );
              })}
              className="nowrapTxt"
            >
              <span style={{ cursor: "default" }}>Multiple</span>
            </Tooltip>
          )}
          {poNumber && poNumber?.length === 1 && (
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + poNumber[0]}
              underline={true}
            >
              {`${poNumber[0]}`}
            </Typography.Link>
          )}
        </React.Fragment>
      );
    },
  },
  {
    title: "Broker",
    dataIndex: "broker_name",
    canSort: true,
    thClassName: "text-left brokerCol",
    dataRender: (data: string[] | null) =>
    showBrokerOrLister(data),
  },
  {
    title: "Lister",
    dataIndex: "lister_name",
    canSort: true,
    thClassName: "text-left listerCol",
    dataRender: (data: string[] | null) =>
    showBrokerOrLister(data),
  },
  {
    title: "Buyer",
    dataIndex: "buyer_name",
    canSort: true,
    thClassName: "text-left listerCol",
    dataRender: (buyer_name: string | null) =>
      buyer_name !== null ? showBrokerOrLister([buyer_name]) : "Unknown",
  },
  {
    title: "Purchase Date",
    dataIndex: "purchase_date",
    canSort: true,
    width: 150,
    tdClassName: "text-center",
    dataRender: (purchase_date: string | null) =>
      purchase_date !== null ?
      formatDateGivenFormatString(purchase_date, "ddd, MMM DD, YYYY") : '-',
  },
  {
    title: "Days taken to Purchase",
    dataIndex: "days_to_purchase",
    canSort: true,
    width: 120,
    thClassName: "text-right dayToEventCol",
    tdClassName: "text-center",
    tdStyle: {position: 'relative'} as React.CSSProperties,
    dataRender: (days_to_purchase: IDaysTo | null) =>
      days_to_purchase !== null ? getDaysToJsx(days_to_purchase) : "-",
  },
  {
    title: "Shipper",
    dataIndex: "shipper_name",
    canSort: true,
    thClassName: "text-left listerCol",
    dataRender: (shipper_name: string | null) =>
      shipper_name !== null ? showBrokerOrLister([shipper_name]) : "Unknown",
  },
  {
    title: "Delivery Date",
    dataIndex: "delivery_date",
    canSort: true,
    width: 150,
    tdClassName: "text-center",
    dataRender: (delivery_date: string | null) =>
      delivery_date !== null ?
      formatDateGivenFormatString(delivery_date, "ddd, MMM DD, YYYY") : '-',
  },
  {
    title: "Days taken to Deliver",
    dataIndex: "days_to_deliver",
    canSort: true,
    width: 120,
    thClassName: "text-right dayToEventCol",
    tdClassName: "text-center",
    tdStyle: {position: 'relative'} as React.CSSProperties,
    dataRender: (days_to_deliver: IDaysTo | null) =>
      days_to_deliver !== null ? getDaysToJsx(days_to_deliver) : "-",
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillment_status",
    canSort: true,
  }
];
