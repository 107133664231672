import { message, Spin } from 'antd';
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { UserService } from 'services';
import { LoadingOutlined } from '@ant-design/icons';
import { LoggerService } from 'sharedServices/api/logger.service';
const userService = new UserService();

const loaderIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

const ResendInviteLink: React.FC<{userId: string}> = ({
    userId
}) => {

    const [isLinkAvailable, setIsLinkAvailable] = useState(false);
    const [isFetchingAuth0UserData, setIsFetchingAuth0UserData] = useState(false);

    useEffect(() => {
        if(userId && /^auth0.*$/.test(userId)){
            setIsFetchingAuth0UserData(true);
            userService.getAuth0UserDetails(userId).then(responce=>{
                setIsFetchingAuth0UserData(false);
                if(!responce.data.data.userAuth0Data.email_verified){
                    setIsLinkAvailable(true)
                }
            })
            .catch((error)=>{
                setIsFetchingAuth0UserData(false);
                console.log(error);
            });
        }
    }, []);

    const resendInvite = async(e: React.MouseEvent<HTMLElement>) =>{
        const key = 'SendingInvite';
        e.preventDefault();
        try {
            message.loading({ content: 'Sending invite...', duration: 0,  key: key, className: 'toastMsg loadingMsg'});
            await userService.resendInvite(userId);
            message.success({ content: 'Invite has been sent successfully.', key, duration: 5, className: 'toastMsg inviteSuccess'});
        } catch (error) {
            message.error({ content: 'Failed to send invite.', key, duration: 5, className: 'toastMsg savedFailed'});
            new LoggerService().log({
                payload: error,
                function_name: "deleteUser",
            });
        }
        
    }

    return (
        <div className="resentLink">
            {isLinkAvailable && !isFetchingAuth0UserData && 
                <Link to="#/" onClick={(e) => resendInvite(e)}>Resend Invite</Link>
            }
            {!isLinkAvailable && isFetchingAuth0UserData &&
                <Spin indicator={loaderIcon} />
            }
        </div>
    )
}

export default ResendInviteLink
