import { Layout, Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import { usePageTitle } from "hooks";

const InboxPage: React.FC = () => {
  usePageTitle("Messages");
  return (
      <Layout className="mainInnerLayout">
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row>
              <Col span={24}>
                <h3>Coming Soon Messages Page</h3>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
  );
};

export default InboxPage;
