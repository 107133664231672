import { Tooltip, Typography } from "antd";
import { FixedColumnDirection } from "models/enums/table";
import { addToolTip, daysToEventColorCheck, formatAccountingValues } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { AppSetting } from "utils/appSettings";
import { trigger } from "sharedServices";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import React from "react";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";

const commonWidth = 100;

export const pendingZoneFillsHeaders = [
  {
    title: "Purchase Assignment",
    dataIndex: "id",
    width: 95,
    fixed: FixedColumnDirection.left,
    thClassName: "eventDateTimeTh",
    tdClassName: "eventDateTimeTd",
    allowedPermissions: [
      ERbacPermissions.ZONES_PENDING_ZONE_FILLS_ASSIGNMENT_CTA_ENABLE
    ],
    dataRender: (id: number, otherData: any) => {
      return (
        <React.Fragment>
          <RBAC allowedPermissions={[ERbacPermissions.ZONES_PENDING_ZONE_FILLS_ASSIGNMENT_CTA_ENABLE]}>
            <Tooltip placement="right" title={()=>{
              if(otherData.data.singleData.if_completed_assignment){
                return 'Purchase Assignment already completed';
              }else if(!otherData.data.singleData.buyer_list){
                return 'Venue must be associated with a broker who has a buyer team';
              }
              else{
                return 'Purchase Assignment';
              }
            }}>
              {!otherData.data.singleData.if_completed_assignment && otherData.data.singleData.buyer_list &&
                <Typography.Link
                  className="fillSeatDisable text-center"
                  onClick={() => {
                    trigger("purchaseAssignmentAction:click", {
                      action: "updateAssignment",
                      data: {
                        itemId: id,
                        data: otherData.data.singleData
                      },
                    });
                  }}
                >
                  <i className="da icon-ticket-o"></i>
                </Typography.Link>
              }
              {(otherData.data.singleData.if_completed_assignment || !otherData.data.singleData.buyer_list) &&
                <Typography.Link className="fillSeatDisable text-center" disabled >
                  <i className="da icon-ticket-o"></i>
                </Typography.Link>
              }
            </Tooltip>
          </RBAC>
        </React.Fragment>
      )
    }
  },
  {
    title: "TB-2.0",
    dataIndex: "is_platform_listing",
    canSort: true,
    width: 75,
    fixed: FixedColumnDirection.left,
    dataRender: (isPlatformListing: boolean) => (
      `${isPlatformListing ? "Yes" : "-"}`
    ),
  },
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    width: commonWidth,
    canSort: true,
    fixed: FixedColumnDirection.left,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Date",
    dataIndex: "invoice_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceDate: string) =>
      invoiceDate && formatDateGivenFormatString(invoiceDate, "ddd, MMM DD, YYYY"),
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    dataRender: (customer: string) => (
      <div className="text-capitalize">{customer}</div>
    ),
  },
  {
    title: "Event Name",
    dataIndex: "event_name",
    canSort: true,
    thClassName: "eventNameTh",
    tdClassName: "eventNameTd",
    dataRender: (eventName: string, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.event_id}
        className="text-capitalize"
      >
        {eventName}
      </a>
    ),
  },
  {
    title: "Days to Event",
    dataIndex: "days_to_event",
    canSort: true,
    thClassName: "text-right dayToEventCol",
    tdClassName: "positionRel",
    dataRender: (data: number) => (
      <Tooltip
        placement="left"
        title={daysToEventColorCheck(data, "list").toolTipText}
      >
        <div
          className={
            "daysToEvent daysToEventData " +
            daysToEventColorCheck(data, "list").className
          }
        >
          <span style={{ cursor: "default" }}>{data}</span>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Event Date & Time",
    dataIndex: "event_date_and_time",
    canSort: true,
    thClassName: "eventDateTimeTh",
    tdClassName: "eventDateTimeTd",
    dataRender: (eventDate: string) =>
      eventDate && formatDateGivenFormatString(eventDate, "ddd, MMM DD, YYYY hh:mm A"),
  },
  {
    title: "In-Hand Date",
    dataIndex: "in_hand_date",
    width: commonWidth,
    canSort: true,
    dataRender: (inHandDate: string) =>
      inHandDate && formatDateGivenFormatString(inHandDate, "ddd, MMM DD, YYYY"),
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    thClassName: "eventSectionTh",
    tdClassName: "eventSectionTd",
    dataRender: (section: string) =>
      <div className="text-capitalize">{section}</div>
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
    dataRender: (row: string) => <div className="text-upper">{row}</div>,
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    canSort: true,
    thClassName: "text-right",
    width: 50,
    dataRender: (quantity: number) => (
      <div className="text-right">
        {quantity}
      </div>
    ),
  },
  {
    title: "Unit Sale Price",
    dataIndex: "unit_sale_price",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right eventDateTimeTh",
    dataRender: (unitSalePrice: number, otherData: any) => (
      <div className={`text-right ${unitSalePrice < 0 ? "text-danger" : ""}`}>
        {unitSalePrice > 0 && otherData.data.singleData.currency_code
          ? FormatCurrencyUnit(
            formatAccountingValues(unitSalePrice),
            otherData.data.singleData.currency_code.toUpperCase()
          )
          : formatAccountingValues(unitSalePrice)}
      </div>
    ),
  },
  {
    title: "Sale Value",
    dataIndex: "sale_value",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (saleValue: number, otherData: any) => (
      <div className={`text-right ${saleValue < 0 ? "text-danger" : ""}`}>
        {saleValue > 0 && otherData.data.singleData.currency_code
          ? FormatCurrencyUnit(
            formatAccountingValues(saleValue),
            otherData.data.singleData.currency_code.toUpperCase()
          )
          : formatAccountingValues(saleValue)}
      </div>
    ),
  },
  {
    title: "Payment Status",
    dataIndex: "payment_status",
    canSort: true,
    width: 50,
    thClassName: "eventDateTimeTh",
    dataRender: (paymentStatus: string) => <div className="text-capitalize">{paymentStatus}</div>
  },
  {
    title: "Stock Type",
    dataIndex: "stock_type",
    canSort: true,
    width: 50,
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventory_tags",
    canSort: false,
    width: commonWidth,
    dataRender: (inventoryTags: any) =>
      (inventoryTags && inventoryTags.length) >= 18 ? (
        addToolTip(inventoryTags, "right", "default", 15)
      ) : (
        <div className="text-capitalize">{inventoryTags}</div>
      ),
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoice_tags",
    canSort: false,
    width: commonWidth,
    dataRender: (invoiceTags: any) => {
      return invoiceTags?.includes(" ") ? (
        addToolTip(invoiceTags.split(" "), "right", "default", 15, true)
      ) : (
        <div className="text-capitalize">{invoiceTags}</div>
      );
    },
  }
];