import { Tooltip } from "antd";
import { chartFilterType } from "models/types/Reports/chartFilterType";
import { graphType } from "models/types/Reports/graphType";
import { Link } from "react-router-dom";

const GraphFilters: React.FC<{
  chartFilterHandler: Function;
  chartTypeHandler: Function;
  chartType: graphType;
  activeFilter: chartFilterType;
}> = ({
  chartFilterHandler,
  chartTypeHandler,
  chartType,
  activeFilter,
}) => {
  return (
    <div className="chartBtmBar flx justifyContentBetween">
      <ul>
        <li>
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              chartFilterHandler("day");
            }}
            className={activeFilter === "day" ? "active" : ""}
          >
            Day
          </Link>
        </li>
        <li>
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              chartFilterHandler("week");
            }}
            className={activeFilter === "week" ? "active" : ""}
          >
            Week
          </Link>
        </li>
        <li>
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              chartFilterHandler("month");
            }}
            className={activeFilter === "month" ? "active" : ""}
          >
            Month
          </Link>
        </li>
        <li>
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              chartFilterHandler("quarter");
            }}
            className={activeFilter === "quarter" ? "active" : ""}
          >
            Quarter
          </Link>
        </li>
        <li>
          <Link
            to="#/"
            onClick={(e) => {
              e.preventDefault();
              chartFilterHandler("year");
            }}
            className={activeFilter === "year" ? "active" : ""}
          >
            Year
          </Link>
        </li>
      </ul>
      <div className="chartIconList">
        <Tooltip title="Line Graph">
          <button 
          onClick={()=>chartTypeHandler('line')}
          className={chartType === 'line' ? 'active' : ''}
          >
            <i className="da icon-line-chart"></i>
          </button>
        </Tooltip>
        <Tooltip title="Bar Graph">
          <button
            className={chartType === 'column' ? 'active' : ''}
            onClick={()=>chartTypeHandler('column')}
          >
            <i className="da icon-bar-chart-o"></i>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default GraphFilters;
