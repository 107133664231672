import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sections from "./Widgets/CheckedSectionCount";
import Splits from "./Widgets/SplitCount";
import Venues from "./Widgets/AssignedSectionAndVenueCount";

const CheckersPerformanceAnalysis = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Checkers</h3>
                    <section className="ant-layout mainInnerLayout">
                        <Sections />
                    </section>
                    <section className="ant-layout mainInnerLayout">
                        <Splits />
                    </section>
                    <section className="ant-layout mainInnerLayout">
                        <Venues />
                    </section>
                </div>
            </Content>
        </Layout>
    )
}

export default CheckersPerformanceAnalysis
