export const getWidth = (countValue: number, largestCount: number) => {
  return `${(countValue / largestCount) * 100}%`;
};

export const formatValues = (value: number) => {
  if (value < 1000) {
    return value;
  } else if (value >= 1000 && value < 1000000) {
    return Math.round(value / 1000) + "K";
  } else if (value >= 1000000 && value < 1000000000) {
    return Math.round(value / 1000000) + "M";
  } else {
    return Math.round(value / 1000000000) + "B";
  }
};
