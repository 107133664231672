import React, { useEffect, useState } from 'react';
import { Layout, Pagination } from 'antd';
import CommonReportTable from 'utils/table/CommonReportTable';
import InventoryTabs from '../InventoryTabs';
import { ICustomError } from 'models/interfaces/ICustomError';
import { AppSetting } from 'utils/appSettings';
import { HouseKeepingService } from 'services';
import { LoggerService } from 'sharedServices';
import CustomError from 'controls/CustomError/CustomError';
import { ICancelledInventoryData, IFilterOptions } from 'models/interfaces';
import { cancelledInventoryHeaders } from './cancelledInventoryUtils';
import { formatThousandSeparator } from 'utils/commonFunction';

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const CancelledInventoryTable: React.FC<{
  path: string;
  searchQuery: string | null;
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
}> = ({ path, searchQuery, filterObject }) => {
  const [queryParams, setQueryParams] = useState<any>(null);
  const [cancelledInventoryData, setCancelledInventoryData] = useState<
  ICancelledInventoryData | null
  >(null);
  const [showLoader, setShowLoader] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const [defaultQueryParams] = useState<any>({
    client_zone: AppSetting.TIMEZONE,
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "cancellation_date",
    sort_order: "desc",
    keywords: ""
  });

  const getCancelledInventoryData = async (query: any) => {
    try {
      setShowLoader(true);
      const response = await new HouseKeepingService().getCancelledInventoriesList({
        ...query,
      });
      
      setCancelledInventoryData(response.data.data);
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getActiveInventoryData",
      });

      setDataFetchError({ ...error, refreshAction: getCancelledInventoryData });
    }
  };

  useEffect(() => {
    filterObject && getCancelledInventoryData(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
      });
    }
  }, [filterObject]);

  // useEffect(() => {
  //   if (defaultQueryParams) {
  //     setQueryParams({
  //       ...defaultQueryParams,
  //       ...queryParams,
  //       page_index: DEFAULT_PAGE_INDEX,
  //     });
  //   }
  // }, [defaultQueryParams]);

  useEffect(() => {
    if (searchQuery !== null) {
      setQueryParams({
        ...queryParams,
        page_index: DEFAULT_PAGE_INDEX,
        keywords: searchQuery,
      });
    }
  }, [searchQuery]);

  const getViewTable = () => {
    if (!showLoader &&
      cancelledInventoryData &&
      cancelledInventoryData?.total) {
      return (
        <ul className="innerHeaderRight">
          <li>
            <h4>
              {
                cancelledInventoryData?.total === 1
                  ? `${formatThousandSeparator(cancelledInventoryData?.total)} Record Found`
                  : `${formatThousandSeparator(cancelledInventoryData?.total)} Records Found`
              }
            </h4>
          </li>
          <li>
            <Pagination
              className='pagination'
              hideOnSinglePage={true}
              defaultCurrent={1}
              current={queryParams?.page_index}
              total={cancelledInventoryData?.total}
              pageSize={PAGE_SIZE}
              onChange={(pageNumber) =>
                setQueryParams({
                  ...queryParams,
                  page_index: pageNumber,
                })
              }
              showSizeChanger={false}
            />
          </li>
        </ul>
      );
    }
    return (
      <div style={{ height: 21 }}></div>
    );
  }

  return dataFetchError ? (
    <CustomError {...dataFetchError} />
  ) : (
    <Content className="mainContainer relative">
      <div className="cancelledShowsPagination">
        { getViewTable() }
      </div>
      <div className="tabContainer removeTblLRSpc">
        <InventoryTabs path={path} />
        <div className=''>
          <CommonReportTable
            tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
            onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
              setQueryParams({
                ...queryParams,
                sort_column: sortIndex,
                sort_order: sortOrder,
              });
            }}
            sortField={{
              dataIndex: queryParams?.sort_column,
              order: queryParams?.sort_order,
            }}
            data={
              cancelledInventoryData?.data ?? []
            }
            headers={cancelledInventoryHeaders}
            headerData={
                cancelledInventoryData?.aggregate.map((data: any) => {
                  return { ...data, cancellation_date: "Total" };
                })
              }
            loading={showLoader}
          />
        </div>
      </div>
    </Content>
  );
};

export default CancelledInventoryTable;
