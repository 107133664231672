import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useAppSelector } from "app/hooks";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import {
  IBulkListingFormData,
  IEventMetadata,
  IListElement,
} from "models/interfaces";
import { splitTypes } from "models/Slices/FilterSlice";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { filterSortByRanking, formatThousandSeparator } from "utils/commonFunction";
import { getDateObject, getOffsetDate } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const flaggedStockTypes = [1, 2, 4, 5, 7];

const setFilterOptions = (
  input: string,
  option: IListElement | undefined
) => {
  if (option?.label?.toLowerCase().includes(input?.toLowerCase())) {
    return true;
  }
  return false;
}

const BulkPlacementForm: React.FC<{
  isLoading: boolean;
  eventMetadata: IEventMetadata | null;
  setIsLoading: Function;
  setShowSetPricingForm: Function;
  setBulkPlacementFormData: Function;
  bulkPlacementFormData: IBulkListingFormData | null;
  setZoneListingFetchError: Function;
}> = ({
  isLoading,
  eventMetadata,
  setIsLoading,
  setShowSetPricingForm,
  bulkPlacementFormData,
  setBulkPlacementFormData,
  setZoneListingFetchError,
}) => {
  const [form] = Form.useForm();
  const _defaultMarkupType = Form.useWatch('defaultMarkupType', form);
  const history = useHistory();
  const [seatTypesMaster, setSeatTypesMaster] = useState<IListElement[]>([]);
  const [attributesMaster, setAttributesMaster] = useState<IListElement[]>([]);
  const [disclosuresMaster, setDisclosuresMaster] = useState<IListElement[]>(
    []
  );
  const [brokerTags, setBrokerTags] = useState<IListElement[]>([]);
  const [listerTags, setListerTags] = useState<IListElement[]>([]);
  const [vendors, setVendors] = useState<IListElement[]>([]);
  const [stockTypesMaster, setStockTypesMaster] = useState<IListElement[]>([]);

  const [maxTicketsAllowed, setMaxTicketsAllowed] = useState<string | null>(
    null
  );
  const splitTypesMaster = useAppSelector(splitTypes);
  const [disableElectronicTransferSwitch, setDisableElectronicTransferSwitch] =
    useState<boolean>();
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [markupTypes] = useState([
    {
      value: 1, //percentage
      label: "%",
    },
    {
      value: 2, //actual_value
      label: "$",
    },
  ]);
  const [inHandDaysOptions, setInHandDaysOptions] = useState<IListElement[]>(
    []
  );
  const [markupLabel, setMarkupLabel] = useState<string|undefined>();

  const handleOnSearch = (value: React.SetStateAction<string>) => {
    setInputValue(value);
  };

  const markupTypeChangeHandler = () => {
    const temp = form.getFieldValue("defaultMarkupType");
    if(temp === 1) {
      form.setFieldsValue({"defaultMarkupType": 2});
    } else if(temp === 2) {
      form.setFieldsValue({"defaultMarkupType": 1});
      if(form.getFieldValue("defaultMarkup") > 100){
        form.setFieldsValue({"defaultMarkup": 100})
      }
    }

    setMarkupLabel(markupTypes.find(
      (eachType) =>
        eachType.value ===
        form.getFieldValue("defaultMarkupType")
    )?.label);
  }

  const fetchZoneListingData = async () => {
    setIsLoading(true);
    try {
      if (eventMetadata?.venue_id) {
        const res = await new ZonesService().getZoneDetailsForZoneListing({
          venue_id: eventMetadata.venue_id,
        });
        const zoneDetailsResponseData = res.data.data;
        setStockTypesMaster(zoneDetailsResponseData.stock_type_master);
        setSeatTypesMaster(
          zoneDetailsResponseData.seat_type_master.filter(
            (eachSeatType) => eachSeatType?.label?.toLowerCase() !== "piggyback"
          )
        );
        setAttributesMaster(
          zoneDetailsResponseData.inventory_attributes_master
        );
        setDisclosuresMaster(
          zoneDetailsResponseData.ticket_disclosures_master.filter(
            (eachTicketDisclosure) =>
              eachTicketDisclosure?.label?.toLowerCase() !== "piggy back seats"
          )
        );
        setVendors(zoneDetailsResponseData.vendor);

        setBrokerTags(zoneDetailsResponseData.zone_details?.broker_tags);
        setListerTags(zoneDetailsResponseData.zone_details?.lister_tags);
        if(!form.getFieldValue('broker_tag_id') && zoneDetailsResponseData.zone_details?.broker_tags && zoneDetailsResponseData.zone_details?.broker_tags.length === 1){
          form.setFieldsValue({
            broker_tag_id: zoneDetailsResponseData.zone_details?.broker_tags[0].value
          })
        }
        if(!form.getFieldValue('lister_tag_id') && zoneDetailsResponseData.zone_details?.lister_tags && zoneDetailsResponseData.zone_details?.lister_tags.length === 1){
          form.setFieldsValue({
            lister_tag_id: zoneDetailsResponseData.zone_details?.lister_tags[0].value
          })
        }

        setMaxTicketsAllowed(
          zoneDetailsResponseData.zone_details.max_tickets_allowed
        );
        setInHandDaysOptions(() => {
          let temp: IListElement[] = [];
          for (let i = 1; i < 16; i++) {
            temp.push({
              label: i.toString(),
              value: i,
            });
          }
          return temp;
        });
        setBulkPlacementFormData((prev: IBulkListingFormData) => ({
          listing_quantity:
            prev && prev.listing_quantity
              ? prev.listing_quantity
              : zoneDetailsResponseData?.zone_details?.default_listing_quantity ??
                6,
          split_type_id:
            prev && prev.split_type_id
              ? prev.split_type_id
              : zoneDetailsResponseData?.zone_details?.split_type ?? 1,
          low_seat: prev && prev.low_seat ? prev.low_seat : 999,
          internal_notes: prev && prev.internal_notes ? prev.internal_notes : zoneDetailsResponseData?.markup_details?.defaultInternalNotes,
          stock_type_id:
            prev && prev.stock_type_id
              ? prev.stock_type_id
              : eventMetadata.stock_type_id,
          in_hand_days:
          prev && prev.in_hand_days
            ? prev.in_hand_days
            : zoneDetailsResponseData?.markup_details?.defaultInHandDays ?? 3,
          seat_type_id: prev && prev.seat_type_id ? prev.seat_type_id : 1,
          electronic_transfer:
            prev && prev.electronic_transfer ? prev.electronic_transfer : false,
          broadcast:
            prev && prev.broadcast !== undefined ? prev.broadcast : true,
          defaultMarkupType:
            prev && prev.defaultMarkupType
              ? prev.defaultMarkupType
              : zoneDetailsResponseData?.markup_details?.defaultMarkupType ?? 1,
          defaultMarkup:
            prev && prev.defaultMarkup
              ? prev.defaultMarkup
              : zoneDetailsResponseData?.markup_details?.defaultMarkup ?? 1,
          minMarkup: zoneDetailsResponseData?.markup_details?.minMarkup ?? 0,
          zone_seating: prev && prev.zone_seating ? prev.zone_seating : true,
          hide_seat_numbers:
            prev && prev.hide_seat_numbers ? prev.hide_seat_numbers : true,
          vendor_id: prev && prev.vendor_id
          ? prev.vendor_id
          : zoneDetailsResponseData?.markup_details?.default_vendor_id ?? null,
        }));
        const tempMarkupType = bulkPlacementFormData?.defaultMarkupType ?? zoneDetailsResponseData?.markup_details?.defaultMarkupType ?? 1;
        setMarkupLabel(markupTypes.find((each)=>each.value === tempMarkupType)?.label);
      }
      setIsLoading(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "fetchZoneListingData",
      });
      setZoneListingFetchError({
        ...error,
        refreshAction: fetchZoneListingData,
      });
      setIsLoading(false);
    }
  };

  const cancelHandler = () => {
    if (isValueChanged) {
      const header = (
        <span className="text-danger">{"All unsaved data will be lost."}</span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => {
          setIsValueChanged(false);
          history.goBack();
        },
        () => console.log("cancelled"),
        "400px"
      );
    } else {
      setIsValueChanged(false);
      history.goBack();
    }
  };

  const onFinish = (values: IBulkListingFormData) => {
    if (values) {
      setBulkPlacementFormData((prev: any) => ({
        ...prev,
        ...values,
        stock_type: stockTypesMaster
          .find((type) => type.value === values.stock_type_id)
          ?.name?.toUpperCase(),
        seat_type: seatTypesMaster
          .find((type) => type.value === values.seat_type_id)
          ?.label.toUpperCase(),
        split_type: splitTypesMaster
          .find((splitType) => splitType.value === values.split_type_id)
          ?.label.toUpperCase(),
        broker_tag: brokerTags.find(
          (tag) => tag.value === values?.broker_tag_id
        )?.label,
        lister_tag: listerTags.find(
          (tag) => tag.value === values?.lister_tag_id
        )?.label,
      }));
      setShowSetPricingForm(true);
    }
  };

  useEffect(() => {
    fetchZoneListingData();
  }, [eventMetadata]);

  useEffect(() => {
    form.setFieldsValue({
      ...bulkPlacementFormData,
      in_hand_date: getDateObject(
        getOffsetDate(
          OffsetMode.SUBTRACT,
          5,
          OffsetType.DAYS,
          "YYYY-MM-DD",
          getDateObject(bulkPlacementFormData?.in_hand_date)
        )
      ),
    });
  }, [bulkPlacementFormData]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className="innerHeader">
        <Row className="alignItemsCenter">
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <h3 id="" className="invtDetailsTitle">Inventory Details</h3>
          </Col>
        </Row>
      </div>
      <Form
        name="bulkListingForm"
        form={form}
        autoComplete="on"
        onFinish={onFinish}
        onValuesChange={() => setIsValueChanged(true)}
      >
        <div className="editUserInfoForm formBg">
          <div className="ant-row">
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="ant-row">
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Quantity <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name="listing_quantity"
                      rules={[{ required: true, message: "", type: "number" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <InputNumber
                        id="listing_quantity"
                        precision={0}
                        min={1}
                        max={parseInt(maxTicketsAllowed ?? "8")}
                        step={1}
                        controls={false}
                        className={`inputTxtRight`}
                      />
                    </Form.Item>
                    <span className="labelBottom">
                      Max. Tickets Allowed: {maxTicketsAllowed ?? 8}
                    </span>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Markup <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <div className="groupAddon">
                      <Form.Item
                        name="defaultMarkupType"
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <span
                          className="addon"
                          onClick={markupTypeChangeHandler}
                        >
                          {
                            markupLabel
                          }
                        </span>
                      </Form.Item>
                      <Form.Item
                        name="defaultMarkup"
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <InputNumber
                          value={bulkPlacementFormData?.defaultMarkup}
                          precision={2}
                          min={1}
                          controls={false}
                          max={
                            _defaultMarkupType === 1
                              ? 100
                              : 9999
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6} className="notDisplayForMob"></Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Broadcast <span className="req">*</span>
                  </label>
                  <div className="inputGroup" style={{ marginTop: "15px" }}>
                    <Form.Item
                      name={"broadcast"}
                      wrapperCol={{ xl: { span: 24 } }}
                      valuePropName="checked"
                      shouldUpdate={(prev, cur) =>
                        prev.broadcast !== cur.broadcast
                      }
                    >
                      <Switch
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        className="yesNo"
                        id="broadcastStatus"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Low Seat <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"low_seat"}
                      rules={[{ required: true, message: "", type: "number" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <InputNumber
                        precision={0}
                        min={1}
                        step={1}
                        controls={false}
                        className={`inputTxtRight`}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    In Hand (Days) <span className="req">*</span>
                    <Tooltip title="Days required for ticket procurement">
                      <i className="da icon-info-fill-dark infoTooltip"></i>
                    </Tooltip>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"in_hand_days"}
                      rules={[{ required: true, message: "" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        value={bulkPlacementFormData?.in_hand_days}
                        options={inHandDaysOptions}
                        showSearch
                        filterOption={true}
                        optionFilterProp={"label"}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6} className="notDisplayForMob"></Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Stock Type <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"stock_type_id"}
                      rules={[{ required: true, message: "", type: "number" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        value={bulkPlacementFormData?.stock_type_id}
                        options={stockTypesMaster}
                        showSearch
                        filterOption={true}
                        optionFilterProp={"label"}
                        onChange={(value) => {
                          form.setFieldsValue({
                            electronic_transfer: false,
                          });
                          flaggedStockTypes.includes(value)
                            ? setDisableElectronicTransferSwitch(true)
                            : setDisableElectronicTransferSwitch(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Split Type <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"split_type_id"}
                      rules={[{ required: true, message: "", type: "number" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        value={bulkPlacementFormData?.split_type_id}
                        options={splitTypesMaster}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>Seat Type</label>
                  <div className="inputGroup">
                    <Form.Item
                      name={"seat_type_id"}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        value={bulkPlacementFormData?.seat_type_id}
                        options={seatTypesMaster}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>Disclosures</label>
                  <div className="inputGroup">
                    <Form.Item
                      name={"disclosures"}
                      wrapperCol={{ xl: { span: 24 } }}
                      shouldUpdate={(prev, cur) =>
                        JSON.stringify(prev.disclosures) !==
                        JSON.stringify(cur.disclosures)
                      }
                    >
                      <Select
                        labelInValue
                        mode="multiple"
                        placeholder="Select"
                        allowClear={false}
                        options={disclosuresMaster}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>Attributes</label>
                  <div className="inputGroup">
                    <Form.Item
                      name={"attributes"}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        labelInValue
                        mode="multiple"
                        placeholder="Select"
                        allowClear={false}
                        options={attributesMaster}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>Electronic Transfer</label>
                  <div className="inputGroup" style={{ marginTop: "15px" }}>
                    <Form.Item
                      name={"electronic_transfer"}
                      wrapperCol={{ xl: { span: 24 } }}
                      valuePropName="checked"
                      shouldUpdate={(prev, cur) =>
                        prev.electronic_transfer !== cur.electronic_transfer
                      }
                    >
                      <Switch
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        className="yesNo"
                        disabled={disableElectronicTransferSwitch}
                        id="electronicTransferStatus"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Vendors <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"vendor_id"}
                      wrapperCol={{ xl: { span: 24 } }}
                      rules={[{ required: true, message: "", type: "number" }]}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        options={vendors}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>
                    Broker Tag <span className="req">*</span>
                  </label>
                  <div className="inputGroup removeErrorDiv">
                    <Form.Item
                      name={"broker_tag_id"}
                      rules={[{ required: true, message: "" }]}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        options={brokerTags}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <label>Lister Tag</label>
                  <div className="inputGroup">
                    <Form.Item
                      name={"lister_tag_id"}
                      wrapperCol={{ xl: { span: 24 } }}
                    >
                      <Select
                        placeholder="Select"
                        allowClear={false}
                        options={listerTags}
                        showSearch
                        onSearch={handleOnSearch}
                        filterOption={(input, option) =>
                          setFilterOptions(input, option)
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          filterSortByRanking(optionA, optionB, inputValue)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={1}>
              <div className="dividerVertical"></div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={7}>
              <div className="textAreaWrap">
                <label>Public Notes</label>
                <div className="inputGroup ">
                  <Form.Item
                    name={"public_notes"}
                    noStyle={true}
                    rules={[{ message: "", type: "string" }]}
                  >
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      maxLength={1000}
                      showCount={{
                        formatter: ({ count, maxLength }) =>
                          maxLength
                            ? `${formatThousandSeparator(
                                (maxLength ?? 0) - count
                              )} Remaining`
                            : ""
                      }}
                      allowClear
                    />
                  </Form.Item>
                </div>
                <label>Internal Notes </label>
                <div className="inputGroup">
                  <Form.Item
                    name={"internal_notes"}
                    noStyle={true}
                    rules={[{ message: "", type: "string" }]}
                  >
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      maxLength={1000}
                      showCount={{
                        formatter: ({ count, maxLength }) =>
                          maxLength
                            ? `${formatThousandSeparator(
                                (maxLength ?? 0) - count
                              )} Remaining`
                            : ""
                      }}
                      allowClear
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </div>
        </div>
        <div className="modalCustomFooter">
          <Form.Item noStyle={true}>
            <Button
              className="linkBtn btnBgTransparent"
              onClick={cancelHandler}
            >
              CANCEL
            </Button>
            <Button className="ant-btn btnSave" htmlType="submit">
              CONTINUE
            </Button>
          </Form.Item>
        </div>
      </Form>
    </LoadingSpinner>
  );
};

export default BulkPlacementForm;
