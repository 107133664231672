import React, { useEffect, useState } from "react";
import { OperationsDashboardService } from "services";
import { LoggerService } from "sharedServices";
import { IPendingZoneFillsCountsOrSalesDatum } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CustomError from "controls/CustomError/CustomError";
import { getWidth, formatValues } from "./commonWidgetFunctions";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { Typography } from "antd";
import { getEncodedData } from "utils/commonFunction";

const WidgetListingsCountsAndSales: React.FC<{
  ifCountsWidget: boolean;
  functionType: string;
  defaultFilterParams: Object;
}> = ({ ifCountsWidget, functionType, defaultFilterParams }) => {
  const [widgetCountsAndSalesdata, setWidgetCountsAndSalesData] =
    useState<IPendingZoneFillsCountsOrSalesDatum | null>(null);
  const [showLoader, setShowLoader] = useState(true);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [largestCount, setLargestCount] = useState(0);

  const getPendingFillsWidgetData = async () => {
    try {
      setShowLoader(true);
      const response = await new OperationsDashboardService()[functionType]();

      setWidgetCountsAndSalesData(response.data.data.widgetCountsOrSalesData);

      findLargest(response.data.data.widgetCountsOrSalesData);
      setShowLoader(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getPendingFillsWidgetData",
      });

      setDataFetchError({ ...error, refreshAction: getPendingFillsWidgetData });

      setShowLoader(false);
    }
  };

  const findLargest = (countdata: any) => {
    if (countdata) {
      const num1 = countdata[0].risk_high,
        num2 = countdata[0].risk_modarate,
        num3 = countdata[0].risk_low;

      if (num1 >= num2 && num1 >= num3) {
        setLargestCount(num1);
      } else if (num2 >= num1 && num2 >= num3) {
        setLargestCount(num2);
      } else {
        setLargestCount(num3);
      }
    }
  };

  useEffect(() => {
    getPendingFillsWidgetData();
  }, []);

  const formatHeader = (total: number) => {
    return `${!ifCountsWidget ? "$" : ""}${formatValues(total)}`;
  };

  const getIdOrClass = (counstId: string, salesId: string) => {
    return ifCountsWidget ? counstId : salesId;
  };

  const newFiltersApplied = (param: any, title: string) => {
    if (param) {
      return getEncodedData({ ...defaultFilterParams, title: title, ...param });
    }
  };

  const getCountsOrSalesWidgetView = () => {
    return widgetCountsAndSalesdata &&
      widgetCountsAndSalesdata[0]?.total > 0 ? (
      <div className="zoneInsightsCardInnerWrap">
        <div className="zoneInsightsCardLeft">
          <Typography.Link
            id={getIdOrClass("totalCounts", "totalSales")}
            target="_blank"
            rel="noreferrer"
            href={`${
              LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
            }?filter=${newFiltersApplied({ days: "All" }, ifCountsWidget ? "COUNTS (Days to Event)" : "SALES (Days to Event)" )}&isDisabled=${getEncodedData({
              disabled: true
            })}`}
            underline={false}
            className="zoneInsightsCount primary active"
          >
            <span> {formatHeader(widgetCountsAndSalesdata[0]?.total)} </span>
          </Typography.Link>
        </div>
        <div className="zoneInsightsCardRight">
          <div className="progress-bar progress-danger">
            <Typography.Link
              id={getIdOrClass("countsLess15", "salesLess15")}
              target="_blank"
              rel="noreferrer"
              href={`${
                LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
              }?filter=${newFiltersApplied({ days_to_event: 1, days: "<15d" }, ifCountsWidget ? "COUNTS (Days to Event)" : "SALES (Days to Event)")}&isDisabled=${getEncodedData({
                disabled: true
              })}`}
              underline={false}
              className="progress-text haveTooltip"
            >
              <span className="progress-text haveTooltip">&lt;15d</span>
            </Typography.Link>
            <div className="progress-outer">
              <div
                className="progress-bg"
                style={{
                  width: getWidth(
                    widgetCountsAndSalesdata[0]?.risk_high,
                    largestCount
                  ),
                }}
              ></div>
            </div>
            <span
              className={`progress-value ${getIdOrClass(
                "progress-value-counts",
                ""
              )}`}
            >
              {formatValues(widgetCountsAndSalesdata[0]?.risk_high)}
            </span>
          </div>
          <div className="progress-bar progress-warning">
            <Typography.Link
              id={getIdOrClass("countsMore15", "salesMore15")}
              target="_blank"
              rel="noreferrer"
              href={`${
                LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
              }?filter=${newFiltersApplied({ days_to_event: 2, days: "15-30d" }, ifCountsWidget ? "COUNTS (Days to Event)" : "SALES (Days to Event)")}&&isDisabled=${getEncodedData({
                disabled: true
              })}`}
              underline={false}
              className="progress-text haveTooltip"
            >
              <span className="progress-text haveTooltip">15-30d</span>
            </Typography.Link>
            <div className="progress-outer">
              <div
                className="progress-bg"
                style={{
                  width: getWidth(
                    widgetCountsAndSalesdata[0]?.risk_modarate,
                    largestCount
                  ),
                }}
              ></div>
            </div>
            <span
              className={`progress-value ${getIdOrClass(
                "progress-value-counts",
                ""
              )}`}
            >
              {formatValues(widgetCountsAndSalesdata[0]?.risk_modarate)}
            </span>
          </div>
          <div className="progress-bar progress-success">
            <Typography.Link
              id={getIdOrClass("countsMore30", "salesMore30")}
              target="_blank"
              rel="noreferrer"
              href={`${
                LeftNavRoutesEnum["ZONES_PENDING_FILLS"]
              }?filter=${newFiltersApplied({ days_to_event: 3, days: "30+d" }, ifCountsWidget ? "COUNTS (Days to Event)" : "SALES (Days to Event)")}&&isDisabled=${getEncodedData({
                disabled: true
              })}`}
              underline={false}
              className="progress-text haveTooltip"
            >
              <span className="progress-text haveTooltip">30+d</span>
            </Typography.Link>
            <div className="progress-outer">
              <div
                className="progress-bg"
                style={{
                  width: getWidth(
                    widgetCountsAndSalesdata[0]?.risk_low,
                    largestCount
                  ),
                }}
              ></div>
            </div>
            <span
              className={`progress-value ${getIdOrClass(
                "progress-value-counts",
                ""
              )}`}
            >
              {formatValues(widgetCountsAndSalesdata[0]?.risk_low)}
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className="zoneInsightsCardInnerWrap cardCenter">
        <div className="zoneInsightsCardNoData">No Data Found</div>
      </div>
    );
  };

  return (
    <LoadingSpinner isLoading={showLoader}>
      <div className="zoneInsightsCard">
        <h3>
          {ifCountsWidget ? "COUNTS (Days to Event)" : "SALES (Days to Event)"}
        </h3>
        {!showLoader && (
          <React.Fragment>
            {dataFetchError ? (
              <CustomError {...dataFetchError} />
            ) : (
              <React.Fragment>{getCountsOrSalesWidgetView()}</React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </LoadingSpinner>
  );
};

export default WidgetListingsCountsAndSales;
