import { RoutesEnum } from "models/enums";
import { GrossProfitData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { grossProfitType } from "models/types";
import axiosClient from "sharedServices/api/axios.services";

class ShortsPendingService {
    public getShortsPendingData = async (shortsPendingRequest: grossProfitType) => {
        return axiosClient.get<
            IApiBaseResponse<GrossProfitData>
        >(RoutesEnum.SHORTS_PENDING, {
            params: shortsPendingRequest
        });
    };
}

export { ShortsPendingService };