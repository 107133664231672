import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import {
  Inventory,
  PricingResolutionMasterData,
  InventoryPricingIssueRes,
} from "models/interfaces/Reports/longInventory.model";
import { ICustomError } from "models/interfaces/ICustomError";
import { LongInventoryService } from "services";
import { LoggerService } from "sharedServices/api/logger.service";
import { useRbac } from "features/rbac/rbac";
import { off, on } from "sharedServices/customEvents/events";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { formatAccountingValues } from "utils/commonFunction";

const { Option } = Select;
const LIPriceIssueResolution: React.FC<{
  getInventoryData: Function;
}> = ({ getInventoryData }) => {
  const [pricingResolution, setPricingResolution] = useState<
    PricingResolutionMasterData[] | null
  >(null);
  const [_getPricingResolutionError, setGetPricingResolutionError] =
    useState<ICustomError | null>(null);
  const [data, setData] = useState<Inventory | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [issueResolution, setIssueResolution] =
    useState<InventoryPricingIssueRes>({});
  const { userDetails } = useRbac();
  const [pricingIssueVisible, setPricingIssueVisible] =
    useState<boolean>(false);

  const getPricingResolutions = async () => {
    setShowLoader(true);
    let response;
    try {
      response = await new LongInventoryService().getPricingResolution();
      const responseData = response.data.data;
      responseData.sort();
      setPricingResolution(responseData);

      setShowLoader(false);
      setGetPricingResolutionError(null);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getPricingResolutions",
      });
      setShowLoader(false);
      setGetPricingResolutionError({
        ...error,
        refreshAction: getPricingResolutions,
      });
    }
  };

  const saveIssueResolution = async () => {
    setShowLoader(true);
    let response;
    try {
      response = await new LongInventoryService().savePricingResolution({
        ...issueResolution,
        ...{ isResolved: !issueResolution.isResolved },
      });

      if (response.data.data) {
        setPricingIssueVisible(false);
        getInventoryData();
      }
      setShowLoader(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getPricingResolutions",
      });
      setShowLoader(false);
      setGetPricingResolutionError({
        ...error,
        refreshAction: getPricingResolutions,
      });
    }
  };

  /********| CUSTOM EVENT HANDLER |*******/
  useEffect(() => {
    if (
      userDetails?.role_alias_list.some((role) =>
        ["admin", "management", "broker"].includes(role)
      )
    ) {
      const actionHandler = (eData: CustomEvent) => {
        setData(eData.detail?.data);
        setPricingIssueVisible(true);

        if (!pricingResolution) getPricingResolutions();
        setIssueResolution({
          ...issueResolution,
          ...{
            resolutionId: eData.detail?.data?.resolutionId,
            resolutionNotes: eData.detail?.data?.resolutionNotes,
            isResolved: eData.detail?.data?.isResolved,
            inventoryId: eData.detail?.data?.inventory_id,
          },
        });
      };
      on("pricingIssue:click", actionHandler);
      return () => {
        off("pricingIssue:click", actionHandler);
        setIssueResolution({
          ...issueResolution,
          ...{
            resolutionId: 0,
            resolutionNotes: "",
            isResolved: undefined,
            inventoryId: 0,
          },
        });
      };
    }
  }, []);

  return (
    <Modal
      title={
        data && data?.isResolved
          ? "Undo Pricing Issue Resolution "
          : "Resolve Pricing Issue "
      }
      visible={pricingIssueVisible}
      footer={false}
      closable={false}
      keyboard={true}
      className="customModal pricingIssueModal"
      width={720}
      centered
    >
      <LoadingSpinner isLoading={showLoader}>
        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="inputGroup text-capitalize eventNameRow">
              <label className="mb0">Event Name:</label>
              <span>{data?.event_name}</span>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="inputGroup">
              <label className="mb0">Days to Event: </label>
              {data?.days_to_event}
            </div>
          </div>
        </div>

        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-xl-12">
            <div className="inputGroup text-capitalize">
              <label className="mb0">Section:</label>
              {data?.section}
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-xl-12">
            <div className="inputGroup text-capitalize">
              <label className="mb0">Row:</label>
              {data?.row}
            </div>
          </div>
        </div>

        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-xl-12">
            <div className="inputGroup text-capitalize">
              <label className="mb0">Seats:</label>
              {data?.seats}
            </div>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-xl-12">
            <div className="inputGroup">
              <label className="mb0">Total Cost:</label>
              {data?.cost_price ? `$${formatAccountingValues(data.cost_price)}`: ''}
            </div>
          </div>
        </div>
        {data && data?.isResolved ? (
          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-xl-24">
              <div className="inputGroup">
                <label className="mb0">Resolution Reason: </label>
                {
                  pricingResolution?.filter(
                    (f) => f.id == issueResolution?.resolutionId
                  )[0].resolution
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-xl-24">
              <div className="inputGroup">
                <label>Resolution Reason:</label>
                <Select
                  placeholder="Select"
                  value={issueResolution?.resolutionId || data?.resolutionId}
                  onChange={(e) => {
                    setIssueResolution({
                      ...issueResolution,
                      ...{ resolutionId: e },
                    });
                  }}
                >
                  {pricingResolution?.map(
                    (item: PricingResolutionMasterData) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.resolution}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </div>
            </div>
          </div>
        )}
        {data && data?.isResolved ? (
          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-xl-24">
              <div className="inputGroup">
                <label>Notes: </label>
                {data?.resolutionNotes}
              </div>
            </div>
          </div>
        ) : (
          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-xl-24">
              <div className="inputGroup">
                <label>Notes:</label>
                <textarea
                  value={
                    issueResolution?.resolutionNotes || data?.resolutionNotes
                  }
                  onChange={(e) => {
                    setIssueResolution({
                      ...issueResolution,
                      ...{ resolutionNotes: e.target.value },
                    });
                  }}
                  style={{ minHeight: "136px" }}
                ></textarea>
              </div>
            </div>
          </div>
        )}

        <div className="modalFooter">
          <button
            type="button"
            className="linkBtn"
            onClick={() => {
              setPricingIssueVisible(false);
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            {...(issueResolution?.resolutionId ? {} : { disabled: true })}
            className="ant-btn btnSave"
            onClick={() => {
              saveIssueResolution();
            }}
          >
            {data && data?.isResolved
              ? "Undo Pricing Issue Resolution"
              : "Not a Pricing Issue"}
          </button>
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

export default LIPriceIssueResolution;
