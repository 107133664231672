import React, { useState, useEffect, useRef } from "react";
import TextArea from "antd/lib/input/TextArea";
import { IEventMetadata } from "models/interfaces";
import NotesHistoryArea from "./NotesHistoryArea";
import { formatThousandSeparator } from "utils/commonFunction";

const NotesArea: React.FC<{
  metadataUpdateData: IEventMetadata | null;
  setMetadataUpdateData: Function;
}> = ({ metadataUpdateData, setMetadataUpdateData }) => {
  const [copiedNote, setCopiedNote] = useState<string | null>(null);
  const textAreaElem = useRef<HTMLElement | null>(null);
  
  const textChangeHandler = (e: any) => {
    setMetadataUpdateData((prev: IEventMetadata | null) => ({
      ...prev,
      notes: e.target.value.trim().length ? e.target.value : null,
    }));
  };

  useEffect(() => {
    if (copiedNote) {
      textChangeHandler({target: {value: copiedNote}});
      if (textAreaElem.current) {
        textAreaElem.current.focus();
        setCopiedNote(null);
      }
    }
  }, [copiedNote]);

  return (
    <div className="textAreaWrap">
      <div className="inputGroup">
        <label>Notes</label>
        <TextArea
          ref={textAreaElem}
          value={metadataUpdateData?.notes ? metadataUpdateData?.notes : ''}
          className="noteTxtArea"
          onChange={textChangeHandler}
          // autoSize={{ minRows: 1, maxRows: 1 }}
          maxLength={1000}
          showCount={{
            formatter: ({ count, maxLength }) =>
              maxLength
                ? `${formatThousandSeparator(
                    (maxLength ?? 0) - count
                  )} Remaining`
                : ""
          }}
          allowClear
          defaultValue={""}
        />
      </div>
      {metadataUpdateData?.notes_history?.length ? (
        <NotesHistoryArea
          zoneRowCount={metadataUpdateData?.zone?.length ?? 0}
          notesHistory={metadataUpdateData?.notes_history ?? []}
          setCopiedNote={setCopiedNote}
        />
      ):''}
    </div>
  );
};

export default NotesArea;
