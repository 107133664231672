import { useEffect, useState } from "react";
import { Layout } from "antd";
import { usePageTitle } from "hooks";
import KanbanBoard from "views/KanbanBoard/KanbanBoard";
import { IFilterOptions } from "models/interfaces";
import { useAppDispatch } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import { actions } from "models/Slices/FilterSlice";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { getOffsetDate } from "utils/formatTimePeriod";
import AutoReloadCounter from "./AutoReloadCounter";

const ShippingPage = () => {
  usePageTitle("Shipping Queue");

  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);

  useEffect(() => {
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (hasPermissions([ERbacPermissions.FILTER_SHIPPER_USER_SELECTION_ALL])) {
      dispatch(actions.getShipperUserData());
    }
  }, [dispatch]);

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };


  /** For Auto or Force Refresh */
  const [doReloadLane, SetDoReloadLane] = useState(false);
  const [resetTimer, SetResetTimer] = useState(false);

  return (
    <Layout className="mainInnerLayout">
      <div className="kanbanBoardTopBar">
        <div style={{display: 'flex'}}>
          <div className="flx alignItemsFlexEnd">
            <h3>Shipping Queue</h3>
          </div>

            <AutoReloadCounter isResetTimer={resetTimer} doReloadLanes={()=>{
              SetDoReloadLane(true);
            }} />
        </div>
        <div>
        <FilterDrawer
          onApplyFilter={filterChanged}
          showBrokerUserDropdown={hasPermissions([
            ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
          ])}
          brokerUserDropdownType="single"
          showShipperUserDropdown={hasPermissions([
            ERbacPermissions.FILTER_SHIPPER_USER_SELECTION_ALL,
          ])}
          shipperUserDropdownType="single"
          showEventDatePicker={true}
          defaultAndValues={{
            preFilledValues: {
              event_date_from:
                getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? null,
              event_date_to:
                getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? null,
            },
            defaultValues: {
              event_date: {
                defaultFrom:
                  getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS) ?? "",
                defaultTo:
                  getOffsetDate(OffsetMode.ADD, 365, OffsetType.DAYS) ?? "",
              },
            },
          }}
        />
        </div>
      </div>
      <KanbanBoard queueType={2} filterObject={filterObject} reloadLane={doReloadLane} resetTimer={()=>{
        SetResetTimer(true);
        setTimeout(()=>{
          SetResetTimer(false);
          SetDoReloadLane(false);
        }, 100);
      }} />
    </Layout>
  );
};

export default ShippingPage;
