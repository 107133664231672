import { RoutesEnum } from "models/enums";
import {
  IDashboardAlertsQueryParams,
  IListerKPIsNewListingGraphData,
  IListerKPIsGraphQueryParams,
  IListerDashboardEventsResponse
} from "models/interfaces";
import { IListerInsightsQueryParams } from "models/interfaces/Dashboard/ListerDashboard.model";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class ListerDashboardService {
  public getListerNewShowsCount = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{newShowsCount: number}>>(
      RoutesEnum.DASHBOARD_LISTER_ALERTS_NEW_SHOWS_COUNT,
      {
        params: query,
      }
    );
  };

  public getListerEventsCount = async (query: IListerInsightsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{eventsCount: number}>>(
      RoutesEnum.DASHBOARD_LISTER_INSIGHTS_EVENTS_COUNT,
      {
        params: query,
      }
    );
  };

  public getListerVenuesCount = async (query: IListerInsightsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{venuesCount: number}>>(
      RoutesEnum.DASHBOARD_LISTER_INSIGHTS_VENUES_COUNT,
      {
        params: query,
      }
    );
  };

  public getListerDashboardZoneListingsCount = async (query: IListerInsightsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<{zoneListingsCount: number}>>(
      RoutesEnum.DASHBOARD_LISTER_INSIGHTS_ZONE_LISTINGS_COUNT,
      {
        params: query,
      }
    );
  };
  
  public getKPIsNewListingsGraphData = async (query: IListerKPIsGraphQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerKPIsNewListingGraphData>>(
      RoutesEnum.DASHBOARD_LISTER_KPIS_NEW_LISTINGS_GRAPH,
      {
        params: query,
      }
    );
  };

  public getNewShowsNoListingDataList = async (query: IDashboardAlertsQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerDashboardEventsResponse>>(
      RoutesEnum.DASHBOARD_LISTER_NEW_SHOWS_NO_LISTING_DATA,
      {
        params: query,
      }
    );
  };

  public getKPIsUnbroadcastsGraphData = async (query: IListerKPIsGraphQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerKPIsNewListingGraphData>>(
      RoutesEnum.DASHBOARD_LISTER_UN_BROADCASTS_GRAPH,
      {
        params: query,
      }
    );
  };

  public getKPIsSeatsOverTimeGraphData = async (query: IListerKPIsGraphQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IListerKPIsNewListingGraphData>>(
      RoutesEnum.DASHBOARD_LISTER_SALES_OVER_TIME_GRAPH,
      {
        params: query,
      }
    );
  };
}
