import { Tooltip } from "antd";
import { CurrencyCode } from "models/enums";
import React from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip, formatAccountingValues, formatPercentageValues } from "utils/commonFunction";
import { FormatCurrencyUnit } from "utils/formatCurrency";

export const chartProperties = {
  json: {
    chart: {
      type: "area",
    },
  },
  normal: {
    fill: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      opacity: 1,
    },
    negativeFill: {
      color: "#B0C0D7",
    },
    stroke: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      lineJoin: "round",
      lineCap: "round",
      thickness: 1,
    },
    negativeStroke: { color: "#B0C0D7" },
  },
  hovered: {
    fill: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
    },
    negativeFill: {
      color: "#B0C0D7",
    },
    stroke: {
      keys: ["#c68105", "#e9a939"],
      angle: 90,
      lineJoin: "round",
      thickness: 2,
    },
    negativeStroke: { color: "#B0C0D7", thickness: 2 },
  },
  selected: {
    fill: { color: "#E39408", opacity: 0.8 },
    negativeFill: { color: "#B0C0D7", opacity: 1 },
    stroke: {
      color: "#E39408",
      thickness: 4,
      lineJoin: "round",
    },
    negativeStroke: { color: "#B0C0D7", thickness: 4 },
    // labels: { enabled: true },
  },
};

export const brokerSummaryHeaders = [
  {
    title: "Broker",
    dataIndex: "broker",
    thClassName: "text-left",
    key: "broker",
    dataRender: (data: string, otherData: any) => {
      let text;
      switch (otherData.data.singleData.brokerId) {
        case 0:
          text = <span>No Inventory tags are available for the records</span>; //no broker
          break;
        case -1:
          text = (
            <span>
              Inventory tag matches with multiple existing broker tags
            </span>
          ); //mismatch
          break;
        case -2:
          text = (
            <span>
              Inventory tag does not match with any existing broker tag
            </span>
          ); //no match
          break;
      }
      return (
        <React.Fragment>
          {otherData.data.singleData.brokerId <= 0 ? (
            <Tooltip placement="topLeft" title={text}>
              <span style={{ textTransform: "capitalize", cursor: "default" }}>
                {data}
              </span>
            </Tooltip>
          ) : (
            <span
              className={"ant-tag info text-left"}
              style={{ textTransform: "capitalize" }}
            >
              {data}
            </span>
          )}
        </React.Fragment>
      );
    },
    dataFooterRender: (data: string, otherData: any) => (
      <div className={`text-left`}>
        {otherData.data._eachData.currencyCode === null ? "" : data}
      </div>
    ),
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticketCost",
    key: "ticketCost",
    thClassName: "text-right",
    dataRender: (ticketCost: number) => (
      <div className={`text-right ${ticketCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketCost)}
      </div>
    ),
    dataFooterRender: (ticketCostFooter: any, otherData: any) => (
      <div
        className={`text-right ${ticketCostFooter < 0 ? "text-danger" : ""}`}
      >
        {parseInt(ticketCostFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketCostFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(ticketCostFooter)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticketSales",
    key: "ticketSales",
    thClassName: "text-right",
    dataRender: (ticketSales: number) => (
      <div className={`text-right ${ticketSales < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketSales)}
      </div>
    ),
    dataFooterRender: (ticketSalesFooter: any, otherData: any) => (
      <div
        className={`text-right ${ticketSalesFooter < 0 ? "text-danger" : ""}`}
      >
        {parseInt(ticketSalesFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(ticketSalesFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(ticketSalesFooter)}
      </div>
    ),
  },
  {
    title: "Profit",
    dataIndex: "pnl_calculated",
    key: "pnl_calculated",
    thClassName: "text-right",
    dataRender: (plCalculated: number) => (
      <div className={`text-right ${plCalculated < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(plCalculated)}
      </div>
    ),
    dataFooterRender: (plCalculatedFooter: any, otherData: any) => (
      <div
        className={`text-right ${plCalculatedFooter < 0 ? "text-danger" : ""}`}
      >
        {parseInt(plCalculatedFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(plCalculatedFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(plCalculatedFooter)}
      </div>
    ),
  },
  {
    title: "Fees",
    dataIndex: "fees",
    key: "fees",
    thClassName: "text-right",
    dataRender: (fees: number) => (
      <div className={`text-right ${fees < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(fees)}
      </div>
    ),
    dataFooterRender: (feesFooter: any, otherData: any) => (
      <div className={`text-right ${feesFooter < 0 ? "text-danger" : ""}`}>
        {parseInt(feesFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(feesFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(feesFooter)}
      </div>
    ),
  },
  {
    title: "Gross Profit",
    dataIndex: "grossProfit",
    key: "grossProfit",
    thClassName: "text-right",
    dataRender: (grossProfit: number) => (
      <div className={`text-right ${grossProfit < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(grossProfit)}
      </div>
    ),
    dataFooterRender: (grossProfitFooter: any, otherData: any) => (
      <div
        className={`text-right ${grossProfitFooter < 0 ? "text-danger" : ""}`}
      >
        {parseInt(grossProfitFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(grossProfitFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(grossProfitFooter)}
      </div>
    ),
  },
  {
    title: "Margin %",
    dataIndex: "margin_per_calculated",
    key: "margin_per_calculated",
    thClassName: "text-right",
    dataRender: (margin: number) => (
      <div className={`text-right ${margin < 0 ? "text-danger" : ""}`}>
        {formatPercentageValues(margin * 100)}
      </div>
    ),
    dataFooterRender: (marginFooter: any) => (
      <div className="text-right">
        {Boolean(marginFooter) && formatPercentageValues(marginFooter * 100)}
      </div>
    ),
  },
  {
    title: "Commission %",
    dataIndex: "broker_commission_per",
    key: "broker_commission_per",
    thClassName: "text-right",
    dataRender: (brokerCommPer: number) => (
      <div className={`text-right ${brokerCommPer < 0 ? "text-danger" : ""}`}>
        {formatPercentageValues(brokerCommPer)}
      </div>
    ),
  },
  {
    title: "Commission",
    dataIndex: "broker_commission",
    key: "broker_commission",
    thClassName: "text-right",
    dataRender: (brokerComm: number) => (
      <div className={`text-right ${brokerComm < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(brokerComm)}
      </div>
    ),
    dataFooterRender: (brokerCommFooter: any, otherData: any) => (
      <div className="text-right">
        {parseInt(brokerCommFooter) > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(brokerCommFooter),
              otherData.data._eachData.currencyCode ?? CurrencyCode.Usd
            )
          : formatAccountingValues(brokerCommFooter)}
      </div>
    ),
  },
];

export const topEventsHeader = [
  {
    title: "Event Name",
    dataIndex: "event",
    thClassName: "text-left",
    tdClassName: "x",
    key: "event",
    dataFooterRender: (data: string) => (
      <div className={`text-left`}>{data}</div>
    ),
    dataHeaderRender: (data: string) => (
      <div className={`text-left`}>
        {data}
      </div>
    ),
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.eventId}
        className="text-capitalize"
      >
        {event?.length > 40 ? addToolTip(event,"right", "pointer", 40): event}
      </a>
    ),
  },
  {
    title: "Gross Profit",
    dataIndex: "grossProfit",
    key: "grossProfit",
    thClassName: 'text-right',
    dataRender: (data: string) => (
      <div className={`text-right grossProfitTxt ${parseInt(data) < 0 ? "text-danger" : ""}`}>
        {data}
      </div>
    ),
    dataHeaderRender: (data: string, otherData: any) => (
      <div className={`text-right`}>
        {FormatCurrencyUnit(data, otherData.data._eachData.currencyCode)}
      </div>
    ),
  },
];


