import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Button, message } from 'antd';
import ActiveInventoryTable from './ActiveInventory/ActiveInventoryTable';
import { useLocation } from 'react-router-dom';
import SoldInventoryTable from './SoldInventory/SoldInventoryTable';
import CancelledInventoryTable from './CancelledInventory/CancelledInventoryTable';
import { LeftNavRoutesEnum } from 'models/enums/leftNavRoutes';
import { usePageTitle } from 'hooks';
import { RBAC, useRbac } from 'features/rbac/rbac';
import {
  CancelInventoryDto,
  CancelInventoryBodyDto,
} from 'models/interfaces/HouseKeeping/Inventory.model';
import { showConfirm } from 'utils/commonConfirmation/confirmationPopup';
import { HouseKeepingService } from 'services';
import { getCurrentTime } from 'utils/formatTimePeriod';
import { ERbacPermissions } from 'features/rbac/rbacPermissionsList';
import UnfilledZonesTable from './UnfilledZones/UnfilledZonesTable';
import { IFilterOptions, IReportQueryString } from 'models/interfaces';
import FilterDrawer from 'utils/commonFilter/FilterDrawer';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { actions, dateRange } from 'models/Slices/FilterSlice';
import AppliedFilters from 'utils/commonFilter/AppliedFilters';
import CreateScheduler from 'utils/commonScheduler/createScheduler';
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from 'utils/commonFunction';

const CancelledShows: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const queryParams = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = location.search
    ? decodeQueryStringData(queryParams.report)
    : {
        category_name: 'Exception',
        category_id: 5,
        current_report: 'Cancelled Inventory',
        current_report_id: 13,
      };

  usePageTitle('Cancelled Shows');
  const dispatch = useAppDispatch();
  const { hasPermissions } = useRbac();

  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [selectedInventories, setSelectedInventories] = useState<any[]>([]);
  const { auth0UserDetails } = useRbac();

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);

  const dates = useAppSelector(dateRange);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);

  const [ifShowFilterDrawer, setIfShowFilterDrawer] = useState(false);

  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    dispatch(actions.getAllInventoryCancellationTypesData());
    dispatch(actions.getRecipientFilterData());
    // if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
    //   dispatch(actions.getBrokerUserData());
    // }
  }, []);

  useEffect(() => {
    if (dates?.length > 0) {
      setDefaultDateRange({
        preFilledValues: {
          pl_date_from: dates[0]?.pl_date_from,
          pl_date_to: dates[0]?.pl_date_to,
        },
        defaultValues: {
          pl_date: {
            defaultFrom: dates[0]?.pl_date_from,
            defaultTo: dates[0]?.pl_date_to,
          },
        },
      });
      setIfShowFilterDrawer(true);
    }
  }, [dates]);

  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch('');
    setSearchQuery('');
  };

  const cancelInventory = () => {
    let cancelInventories: CancelInventoryDto[] = [];
    for (let item of selectedInventories) {
      cancelInventories.push({
        purchase_id: item?.poNo,
        lineItem_id: item?.poLineNo,
        target_url: '',
        line_type: item?.line_type,
        line_item_type: item?.line_item_type,
        quantity: item?.quantity,
        amount: item?.totalCost,
        description: `TB2.0: Cancelled show credit note on ${getCurrentTime()} EST  against Purchase order line id ${
          item.poLineNo
        } , Qty=${item.quantity}, Amount = ${item?.totalCost}`,
      });
    }

    let payload: CancelInventoryBodyDto = {
      cancel_inventory: cancelInventories,
      mail_to: auth0UserDetails?.email || '',
    };
    new HouseKeepingService().postCancelInventory(payload);
    message.success({
      content:
        'Your request successfully saved. we will send a status email after completion',
      className: 'toastMsg savedSuccess',
    });
  };

  const handleInventory = (p: string) => {
    switch (p) {
      case LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE:
        return {
          title: 'Active Inventory',
          component: (
            <ActiveInventoryTable
              path={p}
              selectedInventories={selectedInventories}
              setSelectedInventories={setSelectedInventories}
              searchQuery={searchQuery}
            />
          ),
          placeholder:
            'Search by PO No, Event Name, Invoice Mapping ID, Internal Notes',
        };
      case LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_SOLD:
        return {
          title: 'Sold Inventory',
          component: (
            <SoldInventoryTable path={path} searchQuery={searchQuery} />
          ),
          placeholder: 'Search by PO Number, Event Name or Invoice Id',
        };
      case LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_UNFILLED:
        return {
          title: 'Unfilled Zones',
          component: (
            <UnfilledZonesTable path={path} searchQuery={searchQuery} />
          ),
          placeholder: 'Search by PO Number, Event Name or Invoice Id',
        };
      case LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED:
        return {
          title: 'Cancelled Inventory',
          component: (
            <CancelledInventoryTable
              path={path}
              searchQuery={searchQuery}
              filterObject={filterObject}
              appliedFilterValues={filtersAppliedWithValues}
            />
          ),
          placeholder: 'Search by Event Name, Invoice ID, PO Number, Venue',
        };
    }
  };
  const cancelHandler = (_data: any) => {
    console.log('CANCELLED');
    message.warning({
      content: 'Operation cancelled by user',
      duration: 5,
      className: 'toastMsg savedFailed',
    });
  };

  return (
    <>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col span={8}>
            <h2>{handleInventory(path)?.title}</h2>
          </Col>
          <Col span={7}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder={handleInventory(path)?.placeholder}
                  value={search ? search : ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col span={9}>
            <div className="rightFilter flx flx-center">
              {path === LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_ACTIVE &&
                selectedInventories.length > 0 && (
                  <RBAC
                    allowedPermissions={[
                      ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_CANCEL_INVENTORY,
                    ]}
                  >
                    <Button
                      className="btnPrimary cancelInventoryBtn"
                      onClick={() => {
                        const header = (
                          <span>
                            Are you sure you want to <strong>cancel</strong>{' '}
                            selected{' '}
                            {selectedInventories?.length > 1
                              ? 'inventories'
                              : 'inventory'}
                            ?
                          </span>
                        );
                        const poNos =
                          'Purchase Line ID: ' +
                          selectedInventories
                            .map((it) => {
                              return it.poLineNo;
                            })
                            .join(',');
                        const body = (
                          <div>
                            <div className="delMessageBody">
                              <p>{poNos}</p>
                            </div>
                            <div className="smlErrorTxt">
                              This action cannot be undone.
                            </div>
                          </div>
                        );
                        console.log(poNos);
                        showConfirm(
                          header,
                          body,
                          null,
                          cancelInventory,
                          cancelHandler,
                        );
                      }}
                    >
                      Cancel Inventory
                    </Button>
                  </RBAC>
                )}
              {ifShowFilterDrawer && (
                <React.Fragment>
                  {path ===
                    LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED && (
                    <RBAC
                      allowedPermissions={[
                        ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_SCHEDULE,
                      ]}
                    >
                      <CreateScheduler
                        schedulerTitle={'Create Schedule'}
                        reportData={reportQueryString}
                        filterData={filterObject}
                      />
                    </RBAC>
                  )}
                  <FilterDrawer
                    disabled={
                      path ===
                      LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED
                        ? false
                        : true
                    }
                    onApplyFilter={filterChanged}
                    showPLDatePicker={true}
                    defaultAndValues={defaultDateRange}
                    // showBrokerUserDropdown={hasPermissions([
                    //   ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                    // ])}
                    current_report_id={13}
                    showBrokerDropdown={hasPermissions([
                      ERbacPermissions.FILTER_BROKER_TAGS_VIEW_ALL,
                      ERbacPermissions.FILTER_BROKER_TAGS_VIEW_TEAM,
                    ])}
                    brokerDropdownType="single"
                    onApplyFilterWithValues={setFilterAppliedWithValues}
                    showInventoryCancellationTypeDropdown={true}
                    inventoryCancellationTypeDropdownType="single"
                    showExcludeZeroValuedInventory={true}
                  />
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="innerHeader">
        <Row className="flx justifyContentBetween alignItemsCenter">
          <Col xl={12} xxl={16}>
            {path ===
              LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS_CANCELLED && (
              <AppliedFilters appliedFilters={filtersAppliedWithValues} />
            )}
          </Col>
        </Row>
      </div>
      <Layout className="mainInnerLayout pb-0 reduceTopSpace">
        {handleInventory(path)?.component}
      </Layout>
    </>
  );
};

export default CancelledShows;
