import { Card, Row, Col, Tooltip } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IReportMenus } from "models/interfaces";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getEncodedData } from "utils/commonFunction";

const ReportCategories: React.FC<{
  categoryName: string;
  categoryIcon: string;
  categoryId: number;
  reports: IReportMenus[];
  favoriteUnfavoriteReport: Function;
  hasFavorite: Function;
}> = (props) => {
  const [toggle, setToggle] = useState(true);
  const reportsRight = props.reports?.filter(
    (_report, index) => index % 2 !== 0
  );
  const reportsLeft = props.reports?.filter((_report, index) => index % 2 === 0);

  const renderReportList = (reports: IReportMenus[]) => {
    return reports?.map((report) => (
      <li key={report.id}>
        <RBAC allowedPermissions={[ERbacPermissions.REPORT_DASHBOARD_MARK_FAVORITE]}>
          <div className="toggleBookmark">
            <Tooltip
              placement="top"
              title={
                props.hasFavorite(report.id)
                  ? "Remove from Favorites"
                  : "Add to Favorites"
              }
            >
              <i
                className={`da ${
                  props.hasFavorite(report.id)
                    ? "icon-bookmark"
                    : "icon-bookmark-o"
                }`}
                id={report.id.toString()}
                onClick={() => {
                  props.favoriteUnfavoriteReport(report.id);
                }}
              ></i>
            </Tooltip>
          </div>
        </RBAC>
        <div className="bookmarkTxt">
          <Link
            to={`${report.link}?report=${getEncodedData({
              category_name: props.categoryName,
              category_id: props.categoryId,
              current_report: report.name,
              current_report_id: report.id,
              reports: props.reports,
            })}`}
            // target={"_blank"}
          >
            {report.name}
          </Link>
        </div>
      </li>
    ));
  };

  return (
    <div className="allReportsCardWrap">
      <Card className={`customCard ${toggle ? "expand" : "collapse"}`}>
        <div className="customCardHeader flx alignItemsCenter">
          <div className="flx alignItemsCenter">
            <span className="circleStyle1">
              <i className={`da zone-icon-size ${props.categoryIcon}`}></i>
            </span>
            <h3>{props.categoryName}</h3>
          </div>
          <Tooltip title={toggle ? "Collapse" : "Expand"}>
            <div
              className="right"
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <i className="da icon-arrow-up-circle toggle"></i>
            </div>
          </Tooltip>
        </div>
        <div className="customCardBody">
          <Row>
            <Col xs={24} sm={12} className="pr20">
              <ul className="reportListing">{renderReportList(reportsLeft)}</ul>
            </Col>
            <Col xs={24} sm={12} className="pl20">
              <ul className="reportListing">
                {renderReportList(reportsRight)}
              </ul>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default ReportCategories;
