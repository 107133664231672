import { TimePicker } from "antd";
import {
  IScheduleListResponse,
  ISchedulerDatum,
} from "models/interfaces/Scheduler/scheduler.model";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  getDefaultTimeInCustomTimeZone,
  getHoursInCustomTimeZone,
  getTimeInCustomTimeZone,
  getTimeSchedulerDisplayFormat,
} from "utils/formatTimePeriod";
import { AppSetting } from "utils/appSettings";

const timeZoneString = AppSetting.TIMEZONE;

const TimeSelector: React.FC<{
  existingScheduleData: IScheduleListResponse | null;
  scheduleData: ISchedulerDatum;
  setScheduleData: Function;
  setShowTimeDropdown: Function;
  showTimeDropdown: boolean;
  reset: boolean;
  setResetToDefaults: Function;
}> = ({
  existingScheduleData,
  setScheduleData,
  setShowTimeDropdown,
  showTimeDropdown,
  reset,
  setResetToDefaults,
}) => {
  const timeOfDay = existingScheduleData?.scheduleDetails.timeOfDay
    ? existingScheduleData?.scheduleDetails.timeOfDay
    : getHoursInCustomTimeZone(
        getDefaultTimeInCustomTimeZone(timeZoneString),
        timeZoneString
      );
  const runtime =
  getTimeInCustomTimeZone(new Date().toISOString().substring(0, new Date().toISOString().indexOf('T')+1) + timeOfDay.substring(0, timeOfDay.indexOf("-")), timeZoneString);
  const [timeInput, setTimeInput] = useState(
    existingScheduleData
      ? runtime
      : getDefaultTimeInCustomTimeZone(timeZoneString)
  );
  const [displayString, setDisplayString] = useState("select time");

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          showTimeDropdown
        ) {
          setShowTimeDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, showTimeDropdown]);
  }

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    setTimeInput(
      existingScheduleData
        ? runtime
        : getDefaultTimeInCustomTimeZone(timeZoneString)
    );
    setResetToDefaults(false);
  }, [reset === true]);

  useEffect(() => {
    setDisplayString(getTimeSchedulerDisplayFormat(timeInput));
    setScheduleData((prev: any)=>({
      ...prev,
      dayOfMonth: existingScheduleData?.scheduleDetails.dayOfMonth,
      dayOfWeek: existingScheduleData?.scheduleDetails.dayOfWeek,
      timeOfDay: getHoursInCustomTimeZone(timeInput, timeZoneString),
    }));
    setShowTimeDropdown(false);
  }, [timeInput]);

  
  return (
    <React.Fragment>
      <span className="customTooltipWrap timeSelector">
        <Link
          to="#/"
          onClick={(e) => {
            e.preventDefault();
            setShowTimeDropdown(!showTimeDropdown);
          }}
        >
          {displayString}
        </Link>
        <span> {timeInput.format("z")}</span>
        <div
          className="customTooltip"
          style={showTimeDropdown ? { display: "block" } : { display: "none" }}
          ref={wrapperRef}
        >
          <TimePicker
            use12Hours={true}
            format={"hh A"}
            disabledHours={() => [0,12]}
            open={showTimeDropdown}
            showNow={false}
            style={{ width: "100px" }}
            allowClear={false}
            bordered={false}
            value={timeInput}
            hideDisabledOptions={true}
            onChange={(timeString) =>
              setTimeInput(getTimeInCustomTimeZone(timeString, timeZoneString))
            }
            onOk={() => setShowTimeDropdown(!showTimeDropdown)}
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLInputElement
            }
          />
        </div>
      </span>
    </React.Fragment>
  );
};

export default TimeSelector;
