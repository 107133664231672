import { Button, Layout, Select, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CustomError from 'controls/CustomError/CustomError';
import { usePageTitle } from 'hooks';
import {
  IBulkListingFormData,
  IBulkListingQueryString,
  IEventMetadata,
  IMaster,
} from 'models/interfaces';
import { ICustomError } from 'models/interfaces/ICustomError';
import { actions } from 'models/Slices/FilterSlice';
import React, { useEffect, useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useLocation } from 'react-router';
import { EventService } from 'services';
import { LoggerService } from 'sharedServices';
import { AppSetting } from 'utils/appSettings';
import {
  checkValidUrl,
  decodeQueryStringData,
  titleCase,
  urlQueryParamsToObject,
} from 'utils/commonFunction';
// import {
//   bulkPlacementCurrentSelectedMarketplaceNameAlias,
//   setBulkPlacementCurrentSelectedMarketplaceNameAlias,
// } from 'models/Slices/CompetitorPricesSlice';
import BulkPlacementForm from './BulkPlacementForm';
import BulkPlacementSetPricingForm from './BulkPlacementSetPricingForm';

const BulkPlacementPage: React.FC = () => {
  usePageTitle('Inventory Listing: Bulk Placement');
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const currentSelectedMarketplaceNameAlias = useAppSelector(
  //   bulkPlacementCurrentSelectedMarketplaceNameAlias,
  // );
  const [isLoading, setIsLoading] = useState(false);
  const [eventMetadata, setEventMetadata] = useState<IEventMetadata | null>(
    null,
  );
  const [zoneAvailabilityMaster, setZoneAvailabilityMaster] = useState<
    IMaster[]
  >([]);
  const [secondaryMarketplaceMaster, setSecondaryMarketplaceMaster] = useState<
    IMaster[]
  >([]);
  const [bulkPlacementFormData, setBulkPlacementFormData] =
    useState<IBulkListingFormData | null>(null);
  const [showSetPricingForm, setShowSetPricingForm] = useState(false);
  const [zoneListingFetchError, setZoneListingFetchError] =
    useState<ICustomError | null>(null);
  const [doDisabledAllInputsAndCtas, setDoDisabledAllInputsAndCtas] =
    useState(false);
  const [filterQueryString, setFilterQueryString] =
    useState<IBulkListingQueryString>();

  const getEventMetadata = async (event_id: number) => {
    setIsLoading(true);
    try {
      const response =
        await new EventService().getEventMetadataForCreateNewZoneListing({
          event_id: event_id,
        });

      setEventMetadata(response.data.data.metadata);
      setZoneAvailabilityMaster(response.data.data.zoneAvailabilityMaster);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: 'fetchZoneListingData',
      });
      setZoneListingFetchError({
        ...error,
        refreshAction: getEventMetadata,
      });
      setIsLoading(false);
    }
  };

  const updateDefaultMarkupValue = (
    markupType: number,
    markupValue: number,
  ) => {
    if (bulkPlacementFormData) {
      setBulkPlacementFormData({
        ...bulkPlacementFormData,
        defaultMarkupType: markupType,
        defaultMarkup: markupValue,
      });
    }
  };

  useEffect(() => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes('event')) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.event) {
        let filterQueryString = decodeQueryStringData(queryObject.event);
        setFilterQueryString(filterQueryString);
        if (filterQueryString.event_id) {
          getEventMetadata(filterQueryString.event_id);
        }
      }
      dispatch(actions.getSplitTypeData());
    }
  }, []);

  const renderVenueName = () => {
    const venueUrl = eventMetadata?.venue_url ? (
      <a
        target="_blank"
        rel="noreferrer"
        href={
          checkValidUrl(eventMetadata?.venue_url)
            ? eventMetadata?.venue_url
            : `https://${eventMetadata?.venue_url}`
        }
        className="text-capitalize eventNameVenue venueNameData"
      >
        {titleCase(eventMetadata?.venue_name)}
      </a>
    ) : (
      titleCase(eventMetadata?.venue_name)
    );
    return (
      <React.Fragment>
        {venueUrl}, {eventMetadata?.venue_location}
      </React.Fragment>
    );
  };

  return (
    <IdleTimerProvider timeout={1000 * AppSetting.IDLE_TIME_TIMEOUT_IN_SECONDS}>
      <div className="mainHeading">
        <div className="flx alignItemsCenter justifyContentBetween flx-1">
          <div className="flx alignItemsCenter">
            {showSetPricingForm && eventMetadata && bulkPlacementFormData && (
              <div className="backBtn">
                <Tooltip title={'Back to Inventory Details Form'}>
                  <Button
                    className="btnBgTransparent mr-10"
                    onClick={() => setShowSetPricingForm(false)}
                    disabled={doDisabledAllInputsAndCtas}
                  >
                    <i className="da icon-back-top"></i>
                  </Button>
                </Tooltip>
              </div>
            )}
            <h2 className="mb0 mr-10">BULK PLACEMENT</h2>
            <div className="flx">
              <span className="dateTimeTxt">
                <b>
                  {eventMetadata?.event_name &&
                    titleCase(eventMetadata?.event_name)}
                </b>
              </span>
              <span className="dateTimeTxt">{renderVenueName()}</span>
              {eventMetadata?.venue_fees && (
                <span>
                  <Tooltip title="Fees have been manually added from Edit Venue screen">
                    <i className="da icon-venue-fees" />
                  </Tooltip>
                  <span>Venue Fees: </span>
                  <span>${eventMetadata.venue_fees}</span>
                </span>
              )}
            </div>
          </div>
          {/* <div className="flx alignItemsCenter">
            {showSetPricingForm &&
              secondaryMarketplaceMaster &&
              secondaryMarketplaceMaster.length > 0 && (
                <React.Fragment>
                  <label className="mr-10 mb0 linkGreen">
                    Secondary Marketplace
                  </label>

                  <Select
                    style={{ width: 120 }}
                    value={currentSelectedMarketplaceNameAlias}
                    className="selecrSmall mr0 competitiveDropdown"
                    size="small"
                    onChange={(value: string) => {
                      dispatch(
                        setBulkPlacementCurrentSelectedMarketplaceNameAlias(
                          value,
                        ),
                      );
                    }}
                    placeholder="Select"
                    options={secondaryMarketplaceMaster}
                  />
                </React.Fragment>
              )}
          </div> */}
        </div>
      </div>
      {zoneListingFetchError ? (
        <CustomError {...zoneListingFetchError} />
      ) : (
        <React.Fragment>
          {!showSetPricingForm && (
            <Layout className="mainInnerLayout">
              <BulkPlacementForm
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                eventMetadata={eventMetadata}
                setShowSetPricingForm={setShowSetPricingForm}
                bulkPlacementFormData={bulkPlacementFormData}
                setBulkPlacementFormData={setBulkPlacementFormData}
                setZoneListingFetchError={setZoneListingFetchError}
              />
            </Layout>
          )}
          {showSetPricingForm && eventMetadata && bulkPlacementFormData && (
            <Layout className="mainInnerLayout pb-0">
              <BulkPlacementSetPricingForm
                eventMetadata={eventMetadata}
                zoneAvailabilityMaster={zoneAvailabilityMaster}
                bulkPlacementFormData={bulkPlacementFormData}
                onChangedDisabledAllInputsAndCtas={
                  setDoDisabledAllInputsAndCtas
                }
                // setSecondaryMarketplaceMaster={setSecondaryMarketplaceMaster}
                updateDefaultMarkupValue={updateDefaultMarkupValue}
                // setCurrentSelectedMarketplaceNameAlias={(value) => {
                //   if (!currentSelectedMarketplaceNameAlias) {
                //     dispatch(
                //       setBulkPlacementCurrentSelectedMarketplaceNameAlias(
                //         value,
                //       ),
                //     );
                //   }
                // }}
                filterQueryString={filterQueryString}
              />
            </Layout>
          )}
        </React.Fragment>
      )}
    </IdleTimerProvider>
  );
};

export default BulkPlacementPage;
