import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory: FC<{}> = ({ children }) => {
  const domain: any = process.env.REACT_APP_AUTH0_DOMAIN?.toString();
  const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID?.toString();
  const audience: any = process.env.REACT_APP_AUTH0_AUDIENCE?.toString();
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;