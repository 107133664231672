import { Button, message, Modal, Tooltip, Typography } from "antd";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { MetaDataZoneActiveListing } from "models/interfaces";
import { useState } from "react";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { titleCase, twoDecimalPlacesNumber } from "../../../../utils/commonFunction";

const BroadcastFromZoneAvailability: React.FC<{
  activeListingData: MetaDataZoneActiveListing | null;
  refreshListings: Function;
}> = ({ activeListingData, refreshListings }) => {
  const { hasPermissions } = useRbac();
  const [openBroadcastConfirmBox, setOpenBroadcastConfirmBox] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const getToolTipMessage = () => {
    let toolTipMessage = "Change Broadcast Status";
    if (
      !hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION])
    ) {
      toolTipMessage = activeListingData?.broadcast
        ? "Broadcasted"
        : "Un-Broadcasted";
    }
    return toolTipMessage;
  };

  const doUpdateBroadcastStatus = async (
    data: MetaDataZoneActiveListing | null | undefined
  ) => {
    if (data) {
      setIsUpdatingStatus(true);
      const updatingBroadcastStatusKey = "updatingBroadcastStatusKey";
      message.loading({
        content: "Updating broadcast status...",
        duration: 0,
        key: updatingBroadcastStatusKey,
        className: "toastMsg loadingMsg",
      });
      let isBroadcastStatusUpdated = false;
      let updatedStatusResult = null;
      try {
        await new ZonesService().updateInventoryBroadcastStatus(
            data.inventory_id,
            !data.broadcast,
            data.purchase_line_id
          );
        isBroadcastStatusUpdated = true;
        message.success({
          content: "Broadcast status has been updated successfully!",
          key: updatingBroadcastStatusKey,
          duration: 3,
          className: "toastMsg savedSuccess",
        });
      } catch (error) {
        message.error({
          content: "Broadcast status has failed to update.",
          duration: 5,
          key: updatingBroadcastStatusKey,
          className: "toastMsg savedFailed",
        });
        new LoggerService().log({
          payload: error,
          function_name: "doUpdateBroadcastStatus",
        });
        setOpenBroadcastConfirmBox(false);
        setIsUpdatingStatus(false);
      }

      if (isBroadcastStatusUpdated) {
        let syncingZoneInventoryKey = "syncingZoneInventoryKey";
        message.loading({
          content: "Syncing zone listings from SkyBox...",
          duration: 0,
          key: syncingZoneInventoryKey,
          className: "toastMsg loadingMsg",
        });
        try {
          await new ZonesService().refreshZoneInventoryMVW();
          message.success({
            content: "Zone listing has been synced successfully!",
            key: syncingZoneInventoryKey,
            duration: 5,
            className: "toastMsg savedSuccess",
          });
          if (refreshListings) {
            refreshListings();
          }

          setOpenBroadcastConfirmBox(false);
        } catch (error) {
          message.error({
            content: "Broadcast status has failed to update.",
            duration: 5,
            key: syncingZoneInventoryKey,
            className: "toastMsg savedFailed",
          });
          new LoggerService().log({
            payload: error,
            function_name: "doUpdateBroadcastStatus",
          });
        }
        setOpenBroadcastConfirmBox(false);
        setIsUpdatingStatus(false);
      }
    }
  };

  return (
    <div>
      <Tooltip title={getToolTipMessage()}>
        <Typography.Link
          onClick={() => {
            if (
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
            ) {
              setOpenBroadcastConfirmBox(true);
            }
          }}
        >
          <i
            className={
              activeListingData?.broadcast
                ? "da icon-network"
                : "da icon-network-red"
            }
          />
        </Typography.Link>
      </Tooltip>
      <Modal
        closable={false}
        width={350}
        title={false}
        footer={false}
        visible={openBroadcastConfirmBox}
      >
        <div className="modalHeader modalHeaderCenter">
          Are you sure you want to{" "}
          <span>
            {activeListingData?.broadcast ? "un-broadcast" : "broadcast"}
          </span>{" "}
          this listing?
        </div>
        <div className="modalCustomBody">
          <div className="customTbl scroll-y fixedHeaderFooter createSplitTbl">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 80, minWidth: 80 }}>ZONE / SECTION</th>
                  <th style={{ width: 60, minWidth: 60 }}>ROW</th>
                  <th
                    style={{ width: 40, minWidth: 40 }}
                    className="text-right"
                  >
                    QTY
                  </th>
                  <th
                    style={{ width: 75, minWidth: 75 }}
                    className="text-right"
                  >
                    UNIT LIST PRICE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{titleCase(activeListingData?.section)}</td>
                  <td>{titleCase(activeListingData?.row)}</td>
                  <td className="text-right">{activeListingData?.quantity}</td>
                  <td className="text-right">
                    ${twoDecimalPlacesNumber(activeListingData?.list_price)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modalCustomFooter">
          <Button
            className="linkBtn linkBtnColor customLinkBtn"
            onClick={() => {
              setOpenBroadcastConfirmBox(false);
            }}
            disabled={isUpdatingStatus}
          >
            CANCEL
          </Button>
          <Button
            className="btnOk"
            disabled={isUpdatingStatus}
            onClick={() => doUpdateBroadcastStatus(activeListingData)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default BroadcastFromZoneAvailability;
