import { useAppDispatch, useAppSelector } from "app/hooks";
import CustomError from "controls/CustomError/CustomError";
import { usePageTitle } from "hooks";
import { IEventQueryString } from "models/interfaces";
import {
  dataFetchError,
  eventManagementActions,
  eventMetadata,
  hasError
} from "models/Slices/EventManagementSlice";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Unauthorized } from "routes/Unauthorized";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";
import AvailabilityTrend from "./AvailabilityTrend";
import EditEventAvailability from "./EditEventAvailability";

const EditEventPage: React.FC = () => {
  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);
  const eventQueryString: IEventQueryString = decodeQueryStringData(
    queryParams.event
  );
  
  usePageTitle("Edit Event");
  const dispatch = useAppDispatch();
  const error = useAppSelector(hasError);
  const errorObj = useAppSelector(dataFetchError);
  const metaData = useAppSelector(eventMetadata);

  const fetchMetadata = () => {
    dispatch(eventManagementActions.getEventMetadata({
      event_id: eventQueryString.event_id,  
      functionName: fetchMetadata.toString(),
    }));

  };

  const fetchMetadataAuditHistory = () => {
    dispatch(eventManagementActions.getMetadataAuditHistory({
      event_id: eventQueryString.event_id,  
      functionName: "getMetadataAuditHistory",
    }));
  };

  const fetchTrendGraphData = () => {
    dispatch(eventManagementActions.getMetadataTrendData({
      event_id: eventQueryString.event_id,  
      functionName: "getMetadataTrendData",
    }));
  }

  useEffect(()=>{
    fetchMetadata();
  },[]);

  useEffect(()=>{
    if(metaData !== null && !error) {
      fetchMetadataAuditHistory();
      fetchTrendGraphData();
    }
  },[metaData]);
  
  return (
    <React.Fragment>
      {error && (
        errorObj?.code?.toString() === '401' ? <Unauthorized/> :
        <CustomError {...errorObj}/>
      )}
      {!error && (
        <React.Fragment>
          <h2>EDIT EVENT</h2>
          <EditEventAvailability eventId={eventQueryString.event_id} onCancelMetadataUpdate={()=>{
            console.log("Hi")
            window.close();
          }} />
          {metaData?.id && <AvailabilityTrend />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditEventPage;
