enum ReportRoutesEnum {
    GROSS_PROFIT_REPORT = "/reports/profit-gross",
    PROFIT_DASHBOARD_REPORT = "/reports/profit-dashboard",
    LONG_INVENTORY_REPORT = "/reports/inventory-long",
    LONG_INVENTORY_NOT_LISTED_REPORT = "/reports/inventory-long-not-listed",
    LONG_INVENTORY_SCORECARD_REPORT = "/reports/long-inventory-scorecard-broker",
    BROKER_TAG_ISSUES_REPORT = "/reports/broker-tags-issues",
    CONSIGNMENT_SALES_REPORT = "/reports/consignment-sales",
    SHORTS_PENDING_DELIVERY_REPORT = "/reports/orders-pending-delivery",
    GROSS_PROFIT_CANCELLED_REPORT = "/reports/gross-profit-cancelled",
    CONSIGNMENT_PENDING_BUY_IN_REPORT = "/reports/consignment-pending-buy-in",
    INVOICES_NEEDING_ATTENTION = "/reports/invoices-needing-attention",
    RECEIVABLES_DETAILS = "/reports/receivables-wholesale-consignments",
    RECEIVABLES_RETAIL = "/reports/receivables-retail",
    RECEIVABLES_DETAILS_OLD = "/reports/receivables-wholesale-consignments-old",
    RECEIVABLES_RETAIL_OLD = "/reports/receivables-retail-old",
    ACTIVE_ZONE_LISTING_REPORT = "/reports/active-zone-listing-report",
    OTHER_COSTS_AND_CREDITS = "/reports/other-costs-and-credits-report"
}

export {ReportRoutesEnum};