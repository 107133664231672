import { Layout, Row} from "antd";
import { Content } from "antd/lib/layout/layout";
import barChart from "../../../../../assets/images/bar-chart.svg";
import NewListings from './NewListings';
import GeoLocationListing from './GeoLocationListing';
import PurchaseOrders from './PurchaseOrders';
import ListingsByEventDate from './ListingsByEventDate';

const Kpis = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>KPIs</h3>
                    <Row className="removeRowBtmSpc">
                        <NewListings barChart={ barChart } />
                        <GeoLocationListing barChart={ barChart } />
                        <PurchaseOrders barChart={ barChart } />
                        <ListingsByEventDate barChart={ barChart } />
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default Kpis
