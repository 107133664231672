import { Button, Layout, message, Select } from "antd";
import CustomError from "controls/CustomError/CustomError";
import { usePageTitle } from "hooks";
import { IBrokerTags } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useEffect, useState } from "react";
import { BrokerCommissionService } from "services";
import { LoggerService } from "sharedServices";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { getRandomValue } from "utils/commonFunction";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommissionTable from "./CommissionTable";
const { Option } = Select;

const Commissions: React.FC = () => {
  usePageTitle("Commissions");
  const [tagsWithCommission, setTagsWithCommission] = useState<IBrokerTags[]>(
    []
  );
  const [tagsWithoutCommission, setTagsWithoutCommission] = useState<
    IBrokerTags[]
  >([]);
  const [showLoader, setShowLoader] = useState(false);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );
  const [originData, setOriginData] = useState<IBrokerTags[]>([]);
  const [allowSave, setAllowSave] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);

  const getAllBrokerTags = async () => {
    try {
      setShowLoader(true);
      const response =
        await new BrokerCommissionService().getBrokerTagsAndCommissions();
      if (response.data.statusCode === 200) {
        const _tagsWithCommission = response.data.data.tagsWithCommission.map(
          (eachTag: IBrokerTags) => {
            return {
              ...eachTag,
              key:
                new Date().getTime().toString() +
                getRandomValue("number"),
            };
          }
        );
        setTagsWithCommission(_tagsWithCommission);
        setOriginData(_tagsWithCommission);
        setTagsWithoutCommission(response.data.data.tagsWithoutCommission);
      }
      setShowLoader(false);
      setDataFetchError(null);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getAllBrokerTags",
      });
      setDataFetchError({
        ...error,
        refreshAction: getAllBrokerTags,
      });
    }
  };

  useEffect(() => {
    getAllBrokerTags();
  }, []);

  useEffect(() => {
    if (JSON.stringify(tagsWithCommission) === JSON.stringify(originData)) {
      setDataChanged(false);
    } else {
      setDataChanged(true);
    }
  }, [tagsWithCommission, originData]);

  const cancelHandler = () => {
    if(dataChanged) {
      const header = (
        <span className="text-danger">{"All unsaved data will be lost."}</span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => setOriginData(tagsWithCommission),
        () => console.log("cancelled"),
        "400px"
      );
    }
    else{
      setOriginData(tagsWithCommission);
    }
  };

  const saveHandler = async () => {
    try {
      setDataSaving(true);
      const response =
        await new BrokerCommissionService().updateBrokerTagsCommission(
          originData
        );
      if (response.data.statusCode === 200) {
        setDataSaving(false);
        message.success({
          content: "Updated broker commission details.",
          duration: 5,
          className: "toastMsg savedSuccess",
        });
        getAllBrokerTags();
      }
      setAllowSave(false);
    } catch (error) {
      setAllowSave(false);
      message.error({
        content: "Failed to update broker commission details.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
  };

  return (
    <React.Fragment>
      <h2 id="commissions">COMMISSIONS</h2>
      <Layout className="mainInnerLayout commissionsPageWrap">
        <h3>Set Commissions</h3>
        {dataFetchError ? (
          <CustomError {...dataFetchError} />
        ) : (
          <LoadingSpinner isLoading={showLoader}>
            <div className="ant-row">
              <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-16 ant-col-xl-12">
                {!showLoader && (
                  <React.Fragment>
                    <CommissionTable
                      tagsWithCommission={tagsWithCommission}
                      tagsWithoutCommission={tagsWithoutCommission}
                      setAllowSave={setAllowSave}
                      originData={originData}
                      setOriginData={setOriginData}
                    />

                    <div className="text-right btnGroupWrap">
                      <button
                        type="button"
                        className="cancelStyle2"
                        onClick={cancelHandler}
                        disabled={!allowSave || !dataChanged}
                      >
                        CANCEL
                      </button>
                      <Button
                        className="ant-btn btnSave"
                        disabled={!allowSave || !dataChanged}
                        onClick={saveHandler}
                        loading={dataSaving}
                      >
                        SAVE
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </LoadingSpinner>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Commissions;
