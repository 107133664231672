import React from "react";
import { Typography } from "antd";
import { FixedColumnDirection } from "models/enums/table";
import { addToolTip, formatAccountingValues } from "utils/commonFunction";
import { formatDateGivenFormatString, getCustomDate } from "utils/formatTimePeriod";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import { AppSetting } from "utils/appSettings";

const commonWidth = 100;

export const cancelledInventoryHeaders = [
  {
    title: "Cancellation Date",
    dataIndex: "cancellation_date",
    width: 160,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (cancellationDate: string) =>
      cancellationDate &&
      getCustomDate(cancellationDate, AppSetting.REPORT_DATE_FORMAT),
    dataHeaderRender: (data: string) => <div className="text-left">{data}</div>,
  },
  {
    title: "P&L Date",
    dataIndex: "pl_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (profitLossDate: string) =>
      profitLossDate && getCustomDate(profitLossDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Inventory Type",
    dataIndex: "inventory_type",
    canSort: true,
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    tdClassName: 'text-capitalize',
    dataRender: (inventoryType: any) =>
      (inventoryType && inventoryType.length) >= 18 ? (
        addToolTip(inventoryType,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{inventoryType}</div>
      ),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventory_tag",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (inventoryTags: any) => {
      return inventoryTags?.includes(" ") ? (
        addToolTip(inventoryTags.split(" "),"right", "default", 15, true, {textTransform: "capitalize"})
      ) : (
        <div>{inventoryTags}</div>
      );
    },
  },
  {
    title: "Invoice ID",
    dataIndex: "invoice_id",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoice_tag",
    canSort: true,
    width: commonWidth,
    dataRender: (invoiceTags: any) => {
      return invoiceTags?.includes(" ") ? (
        addToolTip(invoiceTags.split(" "),"right", "default", 15, true)
      ) : (
        <div>{invoiceTags}</div>
      );
    },
  },
  {
    title: "Invoice Line ID",
    dataIndex: "invoice_line_id",
    width: commonWidth,
    canSort: true,
  },
  {
    title: "Invoice Line Date",
    dataIndex: "invoice_line_date",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceLineDate: string) =>
      invoiceLineDate && getCustomDate(invoiceLineDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Credit Note",
    dataIndex: "invoice_credit_note",
    canSort: true,
    width: commonWidth,
    dataRender: (creditNote: any) =>
      (creditNote && creditNote.length) >= 18 ? (
        addToolTip(creditNote,"right", "default", 18, false)
      ) : (
        <div className="text-capitalize">{creditNote}</div>
      ),
  },
  {
    title: "Invoice Balance",
    dataIndex: "invoice_balance",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (invoiceBalance: number) => (
      <div className={`text-right ${invoiceBalance < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(invoiceBalance)}
      </div>
    ),
    dataHeaderRender: (invoiceBalanceHeader: any) => (
      <div
        className={`text-right ${
          invoiceBalanceHeader < 0 ? "text-danger" : ""
        }`}
      >
        {invoiceBalanceHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(invoiceBalanceHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(invoiceBalanceHeader)}
      </div>
    ),
  },
  {
    title: "Purchase Date",
    dataIndex: "purchase_date",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseDate: string) =>
      purchaseDate && getCustomDate(purchaseDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Purchase ID",
    dataIndex: "purchase_id",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number) => {
      return (
        purchaseId && (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + purchaseId}
              underline={true}
            >
              {`${purchaseId}`}
            </Typography.Link>
          </React.Fragment>
        )
      );
    },
  },
  {
    title: "PO Line ID",
    dataIndex: "purchase_line_id",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "PO Line Date",
    dataIndex: "purchase_line_date",
    width: commonWidth,
    canSort: true,
    dataRender: (poLineDate: string) =>
      poLineDate && getCustomDate(poLineDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Po Tag",
    dataIndex: "purchase_tags",
    width: commonWidth,
    thClassName: "text-left",
    canSort: true,
    dataRender: (poTag: any) => (
      <div className="flx">{poTag && poTag.join(", ")}</div>
    ),
  },
  {
    title: "PO Credit Note",
    dataIndex: "purchase_credit_note",
    canSort: true,
    width: commonWidth,
    dataRender: (poCreditNote: any) =>
      (poCreditNote && poCreditNote.length) >= 18 ? (
        addToolTip(poCreditNote,"right", "default", 18, false)
      ) : (
        <div className="text-capitalize">{poCreditNote}</div>
      ),
  },
  {
    title: "External Reference",
    dataIndex: "external_reference",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (customer: any) =>
      (customer && customer.length) >= 18 ? (
        addToolTip(customer,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{customer}</div>
      ),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (vendor: any) =>
      (vendor && vendor.length) >= 18 ? (
        addToolTip(vendor,"right", "default", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{vendor}</div>
      ),
  },
  {
    title: "Event",
    dataIndex: "event_name",
    canSort: true,
    width: commonWidth,
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.event_id}
        className="text-capitalize"
      >
        {event?.length >= 40 ? addToolTip(event,"right", "pointer", 40, false, {textTransform: "capitalize"}) : event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "event_date",
    canSort: true,
    width: commonWidth,
    dataRender: (eventDate: string) =>
      eventDate && formatDateGivenFormatString(eventDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Venue",
    dataIndex: "venue_name",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
    dataRender: (venue: any) =>
      (venue && venue.length) >= 18 ? (
        addToolTip(venue,"left", "pointer", 18, false, {textTransform: "capitalize"})
      ) : (
        <div>{venue}</div>
      ),
  },
];