import { IGraphData } from "models/interfaces";
import { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import ProfitGraph from "./ProfitGraph";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { AppSetting } from "utils/appSettings";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const BrokerCommissionGraph: React.FC<{
  queryParams: any;
}> = ({ queryParams }) => {
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [brokerCommissionGraphData, setBrokerCommissionGraphData] =
    useState<IGraphData>();
  const [brokerCommissionFetchError, setBrokerCommissionFetchError] = useState<ICustomError | null>(null)

  const getBrokerCommissionGraphData = async () => {
    try {
      if (!queryParams) return false;
      setIsServiceLoading(true);
      const brokerCommissionGraphResponseData =
        await new ProfitDashboardService().getBrokerCommissionGraphData({
          ...queryParams,
          ...{ client_zone: AppSetting.TIMEZONE },
        });
      setBrokerCommissionGraphData(brokerCommissionGraphResponseData.data.data);
      setIsServiceLoading(false);
      setBrokerCommissionFetchError(null)
    } catch (error: any) {
      setIsServiceLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getBrokerCommissionGraphData'
      })
      setBrokerCommissionFetchError({...error, refreshAction: getBrokerCommissionGraphData})
    }
  };

  useEffect(() => {
    getBrokerCommissionGraphData();
    
  }, [queryParams]);

  return (
    brokerCommissionFetchError
    ?
    <CustomError {...brokerCommissionFetchError} />
    :
    <div>
      <h3>
        Broker Commissions
      </h3>
      <LoadingSpinner isLoading={isServiceLoading}>
      <ProfitGraph
        data={brokerCommissionGraphData ?? null}
        currency={queryParams?.currency_Id ?? ""}
        cId={2}
        setIsLoading={setIsServiceLoading}
      />
      </LoadingSpinner>
    </div>
  );
};

export default BrokerCommissionGraph;
