import React, { useCallback, useState } from "react";
import { Modal, Button, message, Upload, Slider } from "antd";
import Cropper from "react-easy-crop";

const UserProfileImageEdit: React.FC<{
  onChange?: Function;
  oldProfilePicturePublicPath?: string | null;
}> = ({ onChange, oldProfilePicturePublicPath }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [oriImageBlob, setOriImageBlob] = useState<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [imageFileName, setImageFileName] = useState<string | null>(null);
  const [croppedImageString, setCroppedImageString] = useState<string | ArrayBuffer | null>(null);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const onCropComplete = useCallback(async (croppedArea, _croppedAreaPixels) => {
    console.log(croppedArea, _croppedAreaPixels);
    setCroppedAreaPixels(_croppedAreaPixels);
  }, []);

  const getCroppedImg = async (imageSrc: string, _crop: any) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    /* setting canvas width & height allows us to resize from the original image resolution */
    canvas.width = 200;
    canvas.height = 200;

    ctx.drawImage(
      image,
      _crop.x,
      _crop.y,
      _crop.width,
      _crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  return (
    <React.Fragment>
      <div className="profileAvatar">
        <div className="profileImg">
          {(!croppedImageString && !oldProfilePicturePublicPath) &&
            <i className="da icon-profile"></i>
          }
          {(oldProfilePicturePublicPath || croppedImageString) &&
            <img src={croppedImageString ? croppedImageString.toString() : oldProfilePicturePublicPath?.toString()} alt="" />
          }          
        </div>
        <div className="profileHoverInfo">
        
          <div className="uploadOption">
            <Upload
              beforeUpload={async (file) => {
                const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                if(!allowedExtensions.exec(file.name.toLocaleLowerCase())){
                  message.error({
                    content: `Please upload image having extensions .jpeg, .jpg, .png only.`,
                    className: "toastMsg savedFailed",
                  });
                }
                const fileSize = file.size / 1024 / 1024; // in MiB
                if(fileSize < 2){
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.addEventListener("load", (event: any) => {
                    const _loadedImageUrl = event.target.result;
                    const image = document.createElement("img");
                    image.src = _loadedImageUrl;
                    image.addEventListener("load", () => {
                      const { width, height } = image;
                      if(width >= 200 && height >= 200){
                        // set image width and height to your state here
                        setOriImageBlob(_loadedImageUrl);
                        setShowEditModal(true);
                        setImageFileName(file.name);
                      }
                      else{
                        message.error({
                            content: `Image height & width should be minimum 200px`,
                            duration: 5,
                            className: "toastMsg savedFailed",
                        });
                      }
                    });
                  });
                }
                else{
                  message.error({
                    content: `File size should be less than 2 MB.`,
                    className: "toastMsg savedFailed",
                  });
                }
                return false;
              }}
              accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
              showUploadList={false}
            >
              <Button className="uploadPhotoBtn">Upload</Button>
            </Upload>
          </div>
        </div>

      </div>
      
      {showEditModal && (
        <Modal
          title="Edit Profile Image"
          width={450}
          closeIcon={<i className="da icon-close"></i>}
          visible={true}
          okButtonProps={{className: 'btnOk'}}
          cancelButtonProps={{className: 'linkBtn cancelRightMargin'}}
          cancelText="CANCEL"
          onOk={async () => {
            const croppedImage: any = await getCroppedImg(
              oriImageBlob,
              croppedAreaPixels
            );

            var reader = new FileReader();
            reader.readAsDataURL(croppedImage);
            reader.onloadend = function () {
              var base64data = reader.result;
              setZoom(1);
              setCrop({ x: 0, y: 0 });
              setOriImageBlob(null);
              if (onChange) {
                onChange(base64data, imageFileName);
              }
              setCroppedImageString(base64data);
              setShowEditModal(false);
            };
          }}
          onCancel={() => {
            setZoom(1);
            setCrop({ x: 0, y: 0 });
            setOriImageBlob(null);
            setShowEditModal(false);
          }}
        >
          <div style={{ position: "relative", width: "100%", height: "50vh" }}>
            {oriImageBlob && (
              <Cropper
                image={oriImageBlob}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
          <div className="zoomSliderWrap">
            <Button className="zoomOutBtn" onClick={()=>setZoom(1)}><i className="da icon-image"></i></Button>
            <Slider className="zoomSlider" tipFormatter={null} min={1} max={3} step={0.1} value={zoom} onChange={(newValue: number)=>setZoom(newValue)} />
            <Button className="zoomInBtn" onClick={()=>setZoom(3)}><i className="da icon-image"></i></Button>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default UserProfileImageEdit;
