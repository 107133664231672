import { Card, Col, Row } from "antd";
import {
  IProfitDashboardType,
  IProfitScore,
  IReportQueryString,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { ProfitDashboardService } from "services/reportServices/profitabilityDashboard.service";
import { numberShorthand, getEncodedData, getRandomValue } from "utils/commonFunction";
import { LoggerService } from "sharedServices/api/logger.service";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

const getCardLabels = (
  scorecardData: any,
  type: string,
  isNumberShorthand: boolean = false
) => {
  if (scorecardData?.profitDashboardResponse[0][type]) {
    if (!isNumberShorthand) {
      return `${scorecardData.profitDashboardResponse[0][type]}`;
    }
    return `$${numberShorthand(
      scorecardData.profitDashboardResponse[0][type]
    )}`;
  }
  return "-";
}

const ProfitScorecard: React.FC<{
  queryParams: IProfitDashboardType | null;
  reportData: IReportQueryString;
}> = ({ queryParams, reportData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scorecardData, setScorecardData] = useState<IProfitScore>();
  const [scorecardDataFetch, setScorecardDataFetch] =
    useState<ICustomError | null>(null);
  const { hasPermissions } = useRbac();
  async function getScorecardData() {
    try {
      if (!queryParams) return false;
      setIsLoading(true);
      const scorecardResponseData =
        await new ProfitDashboardService().getScorecardData({
          ...queryParams,
        });
      setScorecardData(scorecardResponseData.data.data);
      setIsLoading(false);
      setScorecardDataFetch(null);
    } catch (error: any) {
      setIsLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getScorecardData",
      });
      setScorecardDataFetch({ ...error, refreshAction: getScorecardData });
    }
  }

  useEffect(() => {
    queryParams !== null && getScorecardData();
  }, [queryParams]);

  return scorecardDataFetch ? (
    <CustomError {...scorecardDataFetch} />
  ) : (
    <React.Fragment key={getRandomValue("number")}>
      <LoadingSpinner isLoading={isLoading}>
      <Row className="rowSpc-6 mb20">
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-success">
                  {getCardLabels(scorecardData, "ticketSales", true)}
                </h2>
                <p className='scorecard-text'>
                  TICKET SALES
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-success">
                  {getCardLabels(scorecardData, "grossProfit", true)}
                </h2>
                <p className='scorecard-text'>
                  GROSS PROFIT
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-success">
                  {getCardLabels(scorecardData, "quantity")}
                </h2>
                <p className='scorecard-text'>
                  TICKETS SOLD
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-success">
                  {getCardLabels(scorecardData, "orders")}
                </h2>
                <p className='scorecard-text'>
                  ORDERS
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-danger">
                  {getCardLabels(scorecardData, "missing_broker_tags")}
                </h2>
                <p>
                  {Boolean(hasPermissions([
                    ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_ALL,
                    ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_OWN,
                  ]) && scorecardData?.profitDashboardResponse[0].missing_broker_tags) &&
                    <a
                      target="_blank"
                      href={`/reports/broker-tags-issues?report=${getEncodedData(
                        {
                          ...reportData,
                          current_report: "Broker Tags Issues Report",
                          current_report_id: 6,
                        }
                      )}&filter=${getEncodedData({ ...queryParams })}`}
                    >
                      BROKER TAGS MISSING
                    </a>
                  }
                  {!Boolean(hasPermissions([
                    ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_ALL,
                    ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_VIEW_OWN,
                  ]) && scorecardData?.profitDashboardResponse[0].missing_broker_tags) &&
                    <div className="noPermissionText">BROKER TAGS MISSING</div>
                  }
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-danger">
                  {getCardLabels(scorecardData, "short_filled_not_delivered")}
                </h2>
                <p>
                  {Boolean(hasPermissions([
                    ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_ALL,
                    ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_OWN,
                  ]) && scorecardData?.profitDashboardResponse[0]
                  .short_filled_not_delivered) &&
                    <a
                      target="_blank"
                      href={`/reports/orders-pending-delivery?report=${getEncodedData(
                        {
                          ...reportData,
                          current_report: "Orders Pending Delivery",
                          current_report_id: 8,
                        }
                      )}&filter=${getEncodedData({ ...queryParams })}`}
                    >
                      ORDERS PENDING DELIVERY
                    </a>
                  }
                  {!Boolean(hasPermissions([
                    ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_ALL,
                    ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_VIEW_OWN,
                  ]) && scorecardData?.profitDashboardResponse[0]
                  .short_filled_not_delivered) &&
                    <div className="noPermissionText">ORDERS PENDING DELIVERY</div>
                  }
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </LoadingSpinner>
    </React.Fragment>
  );
};

export default ProfitScorecard;
