import { message, Typography } from "antd";
import { IUnfilledZoneData } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { HouseKeepingService } from "services";
import { AppSetting } from "utils/appSettings";
import CancelInvoiceModal from "./CancelInvoiceModal";

const CancelInvoice: React.FC<{
  purchase_line_id: number[];
  invoice_line_id: number;
  initModalData: IUnfilledZoneData;
}> = ({ purchase_line_id, invoice_line_id, initModalData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<IUnfilledZoneData>({
    ...initModalData,
  });
  const [isCancelPending, setIsCancelPending] = useState(false);

  useEffect(() => {
    if (
      modalData?.is_invoice_cancel_in_progress ||
      modalData?.is_purchase_cancel_in_progress
    ) {
      setIsCancelPending(true);
    } else {
      setIsCancelPending(false);
    }
  }, [modalData]);

  const clickHandler = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    const response = await new HouseKeepingService().getUnfilledZoneListItem({
      clientZone: AppSetting.CLIENT_ZONE,
      purchase_line_id,
      invoice_line_id,
    });
    if (response.data.data.unfilledZoneItemDetails) {
      setModalData((prev) => ({
        ...prev,
        ...response.data.data.unfilledZoneItemDetails,
      }));
    }
    if (
      (response.data.data.unfilledZoneItemDetails &&
        response.data.data.unfilledZoneItemDetails
          .is_invoice_cancel_in_progress) ||
      response.data.data.unfilledZoneItemDetails.is_purchase_cancel_in_progress
    ) {
      setIsModalVisible(false);
      setIsCancelPending(true);
      message.warning({
        content:
          "A previous cancel request is already in progress. Please refresh page to update.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    } else {
      setIsModalVisible(true);
      setIsCancelPending(false);
    }
  };

  return (
    <React.Fragment>
      <CancelInvoiceModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setIsCancelPending={setIsCancelPending}
        modalData={modalData}
      />
      <div className="cancelInvoice">
        {!isCancelPending && (
          <Typography.Link underline={true} onClick={(e) => clickHandler(e)}>
            Cancel Invoice
          </Typography.Link>
        )}
        {isCancelPending && <span>Cancel Pending</span>}
      </div>
    </React.Fragment>
  );
};

export default CancelInvoice;
