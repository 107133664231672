import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { widgetClassPerCol } from "./Common/BotPerformanceUtils";
import Charts from "./Common/Charts";
import {
  IBotPerformanceChartList,
  IBotPerformanceChartData,
  IFilterOptions,
} from "models/interfaces";

const BotPerformanceWidgets: React.FC<{
  chartRows: IBotPerformanceChartList;
  filterData: IFilterOptions | null;
}> = ({ chartRows, filterData }) => {
  return (
    <Layout className="mainInnerLayout dashboard">
      <Content className="mainContainer">
        <div className="dashboardCardWrap">
          <h3>{chartRows.chart_row_header}</h3>
          <Row>
            {chartRows.chart_list.map(
              (chartData: IBotPerformanceChartData, index:number) => (
                <Col key={`widget_${index}`} className={widgetClassPerCol[3]}>
                  <Charts chartData={chartData.chart_details} filterData={filterData}/>
                </Col>
              )
            )}
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default BotPerformanceWidgets;
