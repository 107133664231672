import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import CustomError from "controls/CustomError/CustomError";
import { usePageTitle } from "hooks";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import {
  IActiveListingResponseData,
  IPurchaseID,
  IZoneListingsByEventResponseData,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from "utils/commonFunction";
import ActiveListingNonSplitView from "./ActiveListingComponents/ActiveListingNonSplitView";

const ActiveListingPage: React.FC = () => {
  usePageTitle("Active Listings");
  const location = useLocation();
  const history = useHistory();
  const [zoneListingDetailsByEvent, setZoneListingDetailsByEvent] =
    useState<IZoneListingsByEventResponseData>();
  const [activeListingData, setActiveListingData] =
    useState<IActiveListingResponseData>();
  const [isLoadingEventData, setIsLoadingEventData] = useState(false);
  const [isLoadingListingData, setIsLoadingListingData] = useState(false);
  const [activeListingsFetchError, setActiveListingsFetchError] =
    useState<ICustomError | null>(null);
  const [currentPo, setCurrentPo] = useState<IPurchaseID | null>(null);

  const getEventIdFromQueryString = () => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes("event")) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.event) {
        return decodeQueryStringData(queryObject.event).event_id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getZoneIdFromQueryString = () => {
    const locationSearch = location.search;
    if (locationSearch && locationSearch.includes("zone")) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (queryObject.zone) {
        return decodeQueryStringData(queryObject.zone).zone_id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getZoneListingData = async () => {
    setIsLoadingEventData(true);
    try {
      const eventId = getEventIdFromQueryString();
      const zoneId = getZoneIdFromQueryString();

      if (eventId) {
        const res = await new ZonesService().getZoneListingDetailsByEvent({
          event_id: eventId,
          zone_id: zoneId,
        });
        if (res.status === 200) {
          setZoneListingDetailsByEvent(res.data.data);
          updateCurrentPo(res.data.data, currentPo?.purchase_id ?? undefined);
        }
      } else {
        throw new Error("Event id missing from query string");
      }
      setIsLoadingEventData(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getZoneListingData",
      });
      setIsLoadingEventData(false);
      setActiveListingsFetchError({
        ...error,
        refreshAction: getZoneListingData,
      });
    }
  };

  const getActiveListingData = async (purchaseId: number | null) => {
    try {
      setIsLoadingListingData(true);
      const eventId = getEventIdFromQueryString();
      const zoneId = getZoneIdFromQueryString();

      if (eventId) {
        const res = await new ZonesService().getActiveListings({
          event_id: eventId,
          purchase_id: purchaseId,
          zone_id: zoneId,
        });
        setActiveListingData(res.data.data);
      }
      setIsLoadingListingData(false);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getActiveListingData",
      });
      setIsLoadingListingData(false);
      setActiveListingsFetchError({
        ...error,
        refreshAction: getActiveListingData,
      });
    }
  };

  const setUpdatedBroadcastStatus = (
    inventory_id: number,
    broadcast_status: boolean
  ) => {
    if (activeListingData) {
      const _activeListingData = { ...activeListingData };
      const _activeListings = [..._activeListingData.activeListings];
      const findInventory = _activeListings.find((eachListingData) => {
        return eachListingData.inventory_id === inventory_id;
      });
      if (findInventory) {
        findInventory.broadcast = broadcast_status;
        _activeListingData.activeListings = _activeListings;
        setActiveListingData(_activeListingData);
      }
    }
  };

  const refreshListingsAfterDelete = (purchase_id: number | undefined) => {
    if (purchase_id) {
      getZoneListingData();
    }
  };

  const updateCurrentPo = (
    zoneListingData: IZoneListingsByEventResponseData,
    purchase_id?: number
  ) => {
    if (zoneListingData?.total_listings === 0) {
      console.log("redirect");
      history.push(LeftNavRoutesEnum.ZONES_LISTING);
    } else if (zoneListingData?.purchase_ids && purchase_id) {
      let temp = zoneListingData.purchase_ids.find(
        (each) => each.purchase_id === purchase_id
      );
      if (temp) {
        setCurrentPo(temp);
      } else {
        if (zoneListingData.purchase_ids.length > 1) {
          setCurrentPo({ purchase_id: null });
        } else if (zoneListingData.purchase_ids.length === 1) {
          setCurrentPo(zoneListingData.purchase_ids[0]);
        }
      }
    } else if (zoneListingData?.purchase_ids && !purchase_id) {
      if (zoneListingData.purchase_ids.length > 1) {
        setCurrentPo({ purchase_id: null });
      } else if (zoneListingData.purchase_ids.length === 1) {
        setCurrentPo(zoneListingData.purchase_ids[0]);
      }
    }
  };

  useEffect(() => {
    getZoneListingData();
  }, []);

  useEffect(() => {
    currentPo?.purchase_id !== undefined &&
      getActiveListingData(currentPo.purchase_id);
  }, [currentPo]);

  return (
    <React.Fragment>
      {activeListingsFetchError ? (
        <CustomError {...activeListingsFetchError} />
      ) : (
        <React.Fragment>
          <h2 id="">ACTIVE LISTINGS</h2>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer zones">
              <ActiveListingNonSplitView
                isLoadingEventData={isLoadingEventData}
                zoneListingDetailsByEvent={zoneListingDetailsByEvent}
                currentPo={currentPo}
                setCurrentPo={setCurrentPo}
                isLoadingListingData={isLoadingListingData}
                activeListingData={activeListingData}
                setUpdatedBroadcastStatus={setUpdatedBroadcastStatus}
                refreshListingsAfterDelete={refreshListingsAfterDelete}
              />
            </Content>
          </Layout>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ActiveListingPage;
