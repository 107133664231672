import React, { useState } from "react";
import { Modal, Button, message, Tooltip } from "antd";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { addToolTip, twoDecimalPlacesNumber, asyncForEach, titleCase } from "utils/commonFunction";
import { LoggerService } from "sharedServices";
import { EventService, ZonesService } from "services";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";

interface IGroupData{
    id: number;
    numberOfTicket: number;
    value: number;
    isDisabled: boolean;
    inventoryId?: number | null;
    isSuccessSplit?: boolean | null;
}
const SplitListing: React.FC<{
    actionElement: JSX.Element;
    data: {
        eventId: number;
        eventName: string;
        eventDate: string;
        venueName: string;
        venueLocation: string;
        purchaseId: number;
        purchaseLineId: number;
        inventoryId: number;
        quantity: number;
        row: string;
        section: string;
        listPrice: number;
    }
    refreshListings?: Function;
    refreshSplitListings?: Function;
}> = ({ actionElement, data, refreshListings, refreshSplitListings }) => {
    const [showModal, setShowModal] = useState(false);
    const [numberOfGroup, setNumberOfGroup] = useState(0);
    const [groups, setGroups] = useState<IGroupData[]>([]);

    const [disabledAllInputs, setDisabledAllInputs] = useState(false);
    const [currentSplittingGroupId, setCurrentSplittingGroupId] = useState<number | null>(null);
    const [isSyncingFromSkyBox, setIsSyncingFromSkyBox] = useState(false);

    const handleChangeGroup = (_numberOfGroup: number) =>{
        setNumberOfGroup(_numberOfGroup);
        const groupData = [];
        for(let i=1; i<= _numberOfGroup; i++){
            let numberOfTicket = 0;
            let value = 0;
            let isDisabled = i === 1 ? false : true;
            if(_numberOfGroup === data.quantity){
                numberOfTicket = 1;
                value = 1;
                isDisabled = false;
            }
            else{
                numberOfTicket = (i === 1) ? ((data.quantity-_numberOfGroup)+1) : 0
            }
            groupData.push({
                id: i,
                numberOfTicket: numberOfTicket,
                value: value,
                isDisabled: (i === _numberOfGroup) ? true : isDisabled,
                inventoryId: null,
                isSuccessSplit: null
            })
        }
        setGroups(groupData);
    }

    const doSplit = async () => {
        setDisabledAllInputs(true);
        const _groups = [...groups];
        let isAnySplitFailed = false;
        const splittingListingMessageKey = 'splittingListingMessageKey';
        message.loading({
            content: "Splitting inventory...",
            duration: 0,
            key: splittingListingMessageKey,
            className: "toastMsg loadingMsg",
        });

        try {
            let split_inventory_ids = [data.inventoryId];
            await asyncForEach(_groups, async (eachGroup: IGroupData) => {
                setCurrentSplittingGroupId(eachGroup.id);
                const findCurrentGroup = _groups.find(eachGroupFind => eachGroupFind.id === eachGroup.id);
                if(findCurrentGroup && !isAnySplitFailed){
                    if(!findCurrentGroup.isDisabled){
                        try {
                            const splitResponse = await new ZonesService().splitListInventory(data.inventoryId, eachGroup.value);
                            findCurrentGroup.inventoryId = splitResponse.data.data.new_inventory_id;
                            findCurrentGroup.isSuccessSplit = true;
                            split_inventory_ids = [...split_inventory_ids, splitResponse.data.data.new_inventory_id];
                        } catch (error) {
                            isAnySplitFailed = true;
                            findCurrentGroup.isSuccessSplit = false;
                            new LoggerService().log({
                                payload: error,
                                message: 'Failed to split listing',
                                function_name: "doSplit",
                            });
                        }
                    }
                    else{
                        findCurrentGroup.inventoryId = data.inventoryId;
                        findCurrentGroup.isSuccessSplit = true;
                    }
                }
                setGroups(_groups);
            });
            const meta_data = {
                event_id: data.eventId,
                po_line_id: data.purchaseLineId,
                inventory_id: data.inventoryId,
                split_inventory_ids
            }
            await new EventService().saveSplitMetadata(meta_data);
        } catch (error) {
            new LoggerService().log({
              payload: error,
              message: 'Failed to split listing',
              function_name: "doSplit",
            });
        }

        setCurrentSplittingGroupId(null);

        if(_groups.filter(each => each.isSuccessSplit).length){
            message.success({
                content: "Inventory has been split successfully!",
                key: splittingListingMessageKey,
                duration: 5,
                className: "toastMsg savedSuccess",
            });

            const updatingKey = 'syncingPurchaseToPlatform';
            message.loading({
                content: "Syncing zone listings from SkyBox. This may take a while...",
                duration: 0,
                key: updatingKey,
                className: "toastMsg loadingMsg",
            });
            setIsSyncingFromSkyBox(true);
            try {
                await new ZonesService().refreshPurchaseLine(data.purchaseLineId);
                message.success({
                    content: "Zone listing has been synced successfully!",
                    key: updatingKey,
                    duration: 5,
                    className: "toastMsg savedSuccess",
                });

                if(refreshListings){
                    refreshListings(data.purchaseId, data.purchaseLineId)
                }
                if(refreshSplitListings){
                    refreshSplitListings()
                }
                doCloseSplitModal();
            } catch (error) {
                message.error({
                    content: "Syncing has failed but zone listing data will be available within one hour.",
                    duration: 10,
                    key: updatingKey,
                    className: "toastMsg savedFailed",
                });
                await new LoggerService().log({
                    payload: error,
                    message: 'Failed to split listing',
                    function_name: "doSplit",
                });
            }
            setIsSyncingFromSkyBox(false);
        }
        else{
            setDisabledAllInputs(false);
            message.error({
                content: "Failed to split inventory.",
                duration: 10,
                key: splittingListingMessageKey,
                className: "toastMsg savedFailed",
            });
        }
    }

    const doCloseSplitModal = () =>{
        setNumberOfGroup(0);
        setGroups([]);
        setShowModal(false);
        setCurrentSplittingGroupId(null);
        setDisabledAllInputs(false);
    }

    return (
        <React.Fragment>
            {data.quantity > 1 &&
                <span onClick={()=>{setShowModal(true)}}>{actionElement}</span>
            }
            <Modal closable={false} width={750} title={false} footer={false} visible={showModal}>
                <div className="modalHeader">Create Split</div>
                <div className="modalCustomBody">
                    <div className="customTbl scroll-y fixedHeaderFooter createSplitTbl">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 130, minWidth: 130 }}>EVENT NAME</th>
                                    <th style={{ width: 120, minWidth: 120  }}>EVENT DATE & TIME</th>
                                    <th style={{ width: 150, minWidth: 150  }}>VENUE</th>
                                    <th style={{ width: 100, minWidth: 100  }}>ZONE / SECTION</th>
                                    <th style={{ width: 80, minWidth: 80 }}>ROW</th>
                                    <th style={{ width: 50, minWidth: 50 }} className="text-right">QTY</th>
                                    <th style={{ width: 75, minWidth: 75 }} className="text-right">UNIT LIST PRICE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.eventName && data.eventName?.length > 31 ? addToolTip(titleCase(data.eventName),"right", "cursor", 31) : titleCase(data.eventName)}</td>
                                    <td>{data.eventDate
                                        ? `${formatDateGivenFormatString(
                                            data.eventDate,
                                            "ddd, MMM DD, YYYY"
                                            )} at ${formatDateGivenFormatString(
                                                data.eventDate,
                                            "h:mm A"
                                            )}`
                                        : ""}
                                        </td>
                                    <td>{titleCase(data.venueName)}</td>
                                    <td>{data.section ? data.section.toUpperCase() : ""}</td>
                                    <td>{data.row ? data.row.toUpperCase() : ""}</td>
                                    <td className="text-right">{data.quantity}</td>
                                    <td className="text-right">${twoDecimalPlacesNumber(data.listPrice)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="selectNewListingGroup">
                        <span>Split This Listing Into</span>
                        <select disabled={disabledAllInputs} value={numberOfGroup} onChange={e => handleChangeGroup(parseInt(e.target.value)) }>
                            <option value={0}>select</option>
                            {
                                Array.from(Array(data.quantity).keys()).slice(1, data.quantity).map(eachData=>{
                                    return <option key={eachData} value={eachData+1}>{eachData+1}</option>
                                })
                            }
                        </select>
                        <span>Groups</span>
                    </div>
                    {(groups && groups.length > 0) &&
                    
                    <div className="newListingQtyWrap">
                        <div className="title">New Listing Quantity</div>
                        <div className="newListingQtyInnerWrap">
                            {
                                groups.map((currentEachGroup, currentEachGroupIndex)=>{
                                    return (
                                        <LoadingSpinner isLoading={currentEachGroup.id === currentSplittingGroupId}>
                                        <div key={'group-'+currentEachGroup.id} className="newListingGroupBox">
                                            <div style={{position: 'absolute', top: 6, left: 6}}>
                                                {currentEachGroup.isSuccessSplit === true &&
                                                    <Tooltip title="Success">
                                                        <i className="da icon-tick-circle"></i>
                                                    </Tooltip>
                                                }
                                                {currentEachGroup.isSuccessSplit === false &&
                                                    <Tooltip title="Failed">
                                                        <i className="da icon-cross-circle"></i>
                                                    </Tooltip>
                                                }
                                            </div>
                                            
                                            <span className="groupByLbl">Group By</span>
                                            <select 
                                            value={currentEachGroup.value} 
                                            disabled={currentEachGroup.isDisabled || disabledAllInputs}
                                            onChange={(e)=>{
                                                if(parseInt(e.target.value)){
                                                    const _groups = [...groups];
                                                    let totalSelectedQuantity = 0;
                                                    _groups.forEach((eachGroup)=>{
                                                        if(eachGroup.id === currentEachGroup.id){
                                                            eachGroup.value = parseInt(e.target.value);
                                                        }
                                                        if(eachGroup.id <= currentEachGroup.id){
                                                            totalSelectedQuantity = totalSelectedQuantity + eachGroup.value;
                                                        }
                                                        
                                                        const remainingQuantity = (((data.quantity-totalSelectedQuantity) - (_groups.length - currentEachGroup.id)) + 1);
                                                        if(eachGroup.id > currentEachGroup.id){
                                                            eachGroup.isDisabled = true;
                                                            eachGroup.numberOfTicket = 0;
                                                            eachGroup.value = 0;
                                                            
                                                            if(currentEachGroup.id + 1 === eachGroup.id){
                                                                eachGroup.numberOfTicket = remainingQuantity;
                                                                eachGroup.isDisabled = false;
                                                            }
                                                            if(currentEachGroupIndex+1 === _groups.length-1){
                                                                eachGroup.value = remainingQuantity;
                                                                eachGroup.isDisabled = true;
                                                            }
                                                        }
                                                    });
                                                    setGroups(_groups);
                                                }
                                            }}
                                            >
                                                <option value={0}>select</option>
                                                {
                                                    Array.from(Array(currentEachGroup.numberOfTicket).keys()).map(eachData=>{
                                                        return <option key={'op-'+eachData+1} value={eachData+1}>{eachData+1}</option>
                                                    })
                                                }
                                            </select>
                                            <span>Tickets</span>
                                        </div>
                                        </LoadingSpinner>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    }
                </div>
                <div className="modalCustomFooter modalFooterBoth">
                    <div></div>
                    <div>
                    {(currentSplittingGroupId === null && !isSyncingFromSkyBox) &&
                        <Button className="discardBtn" onClick={doCloseSplitModal}>CANCEL</Button>
                    }                    
                    <Button className="ant-btn btnOk" onClick={doSplit} disabled={(disabledAllInputs || !groups || groups.length < 1 || groups.filter(eachGroup => eachGroup.value).length !== groups.length)}>Save Split</Button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default SplitListing;