import React from "react";
import {
  setIsLoadingVenues,
  venueManagementActions,
} from "models/Slices/VenuesManagementSlice";
import { VenueService } from "services";
import { useAppDispatch } from "app/hooks";
import { LoggerService } from "sharedServices";
import { Tooltip } from "antd";

const ToggleFavorite: React.FC<{ isFavorite: boolean; venueData: any }> = ({
  isFavorite,
  venueData,
}) => {
  const dispatch = useAppDispatch();

  const handleToggle = async () => {
    dispatch(setIsLoadingVenues(true));

    const venueId = venueData.data.singleData.venueId;
    const operationType = isFavorite ? 0 : 1;

    try {
      const response = await new VenueService().addRemoveFavorite(
        venueId,
        operationType
      );

      if (response.data.data) {
        dispatch(
          venueManagementActions.getVenueListData({
            functionName: "getVenueDetails",
          })
        );
      }
    } catch (error: any) {
      dispatch(setIsLoadingVenues(false));
      await new LoggerService().log({
        payload: error,
        function_name: "handleVenueToggle",
      });
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        title={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
      >
        <i
          onClick={handleToggle}
          className={`da ${isFavorite ? "icon-bookmark" : "icon-bookmark-o"}`}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default ToggleFavorite;
