import { Layout } from "antd";
import React, { useState } from "react";
import ReportLayout from "../ReportLayout";
import { IProfitDashboardType, IReportQueryString } from "models/interfaces";
import ProfitScorecard from "./ProfitScorecard";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import BrokerSummaryTable from "./BrokerSummaryTable";
import GrossProfitGraph from "./GrossProfitGraph";
import GrossProfitPeriodTable from "./GrossProfitPeriodTable";
import BrokerCommissionGraph from "./BrokerCommissionGraph";
import BrokerCommissionPeriodTable from "./BrokerCommissionPeriodTable";
import TopEventsTable from "./TopEventsTable";
import { formatTimePeriod } from "utils/formatTimePeriod";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";
import {useLocation} from 'react-router-dom'
import { usePageTitle } from "hooks";

const GrossProfitSnapshotReport: React.FC = () => {
  usePageTitle("Gross Profit Snapshot");
  const [filterObject, setFilterObject] = useState<IProfitDashboardType | null>(
    null
  );
  const location = useLocation();
  const queryParams = urlQueryParamsToObject(location.search);

  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryParams.report
  );

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  return (
    <div
      className={"fixedFilterArea"}
    >
      <div className="mainHeading grossProfitSnapshot">
        <div>
          <h2>Gross Profit Snapshot</h2>
        </div>
        
        <div className="rightFilter">
          <span>
            {filterObject?.pl_date_from && filterObject?.pl_date_to
              ? formatTimePeriod(
                  filterObject?.pl_date_from,
                  filterObject?.pl_date_to
                )
              : "loading..."}
          </span>
          <FilterDrawer
            current_report_id={reportQueryString.current_report_id}
            showPLDatePicker={true}
            showCurrencyDropdown={true}
            currencyDropdownType={"single"}
            onApplyFilter={filterChanged}
          />
        </div>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout">
          <h3>Gross Profit Summary</h3>

          <ProfitScorecard reportData={reportQueryString} queryParams={filterObject} />
          <BrokerSummaryTable queryParams={filterObject} />
        </Layout>
        <Layout className="mainInnerLayout">
          <GrossProfitGraph queryParams={filterObject} />
          <GrossProfitPeriodTable queryParams={filterObject} />
        </Layout>
        <Layout className="mainInnerLayout">
          <BrokerCommissionGraph queryParams={filterObject} />
          <BrokerCommissionPeriodTable queryParams={filterObject} />
        </Layout>
        <TopEventsTable queryParams={filterObject} />
      </ReportLayout>
    </div>
  );
};

export default GrossProfitSnapshotReport;
