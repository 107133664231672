import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IListElement } from "models/interfaces";
import React from "react";

const SelectSeatMapCreators: React.FC<{
  assignedSeatMapCreators: IListElement[];
  seatMapCreatorIds: string[] | null;
  setSeatMapCreatorIds: Function;
}> = ({ assignedSeatMapCreators, seatMapCreatorIds, setSeatMapCreatorIds }) => {
  const { hasPermissions } = useRbac();
  const seatMapCreatorSelectionHandler = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (seatMapCreatorIds) {
      if (isChecked) {
        if (!seatMapCreatorIds.includes(value)) {
          setSeatMapCreatorIds([...seatMapCreatorIds, value]);
        }
      } else {
        const ind = seatMapCreatorIds.findIndex((id) => id === value);
        seatMapCreatorIds.splice(ind, 1);

        setSeatMapCreatorIds(seatMapCreatorIds);
      }
    } else {
      setSeatMapCreatorIds([value]);
    }
  };
  return (
    <React.Fragment>
      {assignedSeatMapCreators && seatMapCreatorIds && assignedSeatMapCreators?.length > 0 && (
        <div id="clmSeatMapCreators" className="ant-col ant-col-xs-24 ant-col-sm-24">
          <div className="inputGroup">
            <label>Seat Map Creators</label>
            <ul className={`checkboxList ant-row${!hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_SEAT_MAP_CREATOR_SELECTION,
                      ]) ? " elementDisabled" : ""}`}>
              {assignedSeatMapCreators?.map((data: any, ind: any) => (
                <li key={'seatMapCreator_'+ind} className="ant-col ant-col-xs-24 ant-col-sm-8 ant-col-lg-8 ant-col-xl-8">
                  <Checkbox
                    defaultChecked={data.checked}
                    disabled={
                      !hasPermissions([
                        ERbacPermissions.VENUE_DETAILS_EDIT_SEAT_MAP_CREATOR_SELECTION,
                      ])
                    }
                    value={data.value}
                    onChange={seatMapCreatorSelectionHandler}
                  >
                    {data.label}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectSeatMapCreators;
