import React, { useState, useEffect } from "react";
import { Col, Row, Tooltip, Typography } from "antd";
import {
  IFilterOptions,
  IListerSeatAvailabilityData,
  IListerSeatAvailabilityQueryParams
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import { LoggerService } from "sharedServices";
import CustomError from "controls/CustomError/CustomError";
import { CheckerDashboardService } from "services";
import RefreshIcon from "../../../../assets/images/reload-icon-white.svg";
import SeatAvailabilityCommon from "./SeatAvailabilityCommon";
import { defaultParams, widgetClassPerCol } from "views/Dashboard/CommonComponents/CommonFile/commonFile";

const SeatAvailability: React.FC<{
  filterObject: IFilterOptions | null;
}> = ({ filterObject }) => {
  const [queryParams, setQueryParams] = useState<
    IListerSeatAvailabilityQueryParams | null
  >(null);
  const [listerSeatAvailabilityData, setListerSeatAvailabilityData] = useState<
    IListerSeatAvailabilityData | null
  >(null);
  const [showLoader, setShowLoader] = useState(true);
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const [defaultQueryParams] = useState<IListerSeatAvailabilityQueryParams>({
    ...defaultParams
  });

  const getListerSeatAvailabilityData = async (query: IListerSeatAvailabilityQueryParams) => {
    try {
      setShowLoader(true);
      const response = await new CheckerDashboardService().getCheckerSeatAvailabilityList({
        ...query,
      });

      setListerSeatAvailabilityData(response.data.data);
      setShowLoader(false);
    } catch (error: any) {
      setShowLoader(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getListerSeatAvailabilityData",
      });

      setDataFetchError({ ...error, refreshAction: getListerSeatAvailabilityData });
    }
  };

  useEffect(() => {
    queryParams && getListerSeatAvailabilityData(queryParams);
  }, [queryParams]);


  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...filterObject
      });
    }
  }, [defaultQueryParams, filterObject]);

  return (
    <div className="dashboardCardWrap dashboardMidWrap">
      <div className="headingWithLoader">
        <h3>Update Seat Availability</h3>
        <Tooltip title="Reload" placement="right">
          <Typography.Link onClick={() => queryParams && getListerSeatAvailabilityData(queryParams)}>
            <img
              src={RefreshIcon}
              alt="Refresh"
              style={{ verticalAlign: "middle", marginLeft: "10px" }}
            />
          </Typography.Link>
        </Tooltip>
      </div>
      {dataFetchError ? (
        <CustomError {...dataFetchError} />
      ) : (
        <Row>
          <Col className={widgetClassPerCol[3]}>
            <SeatAvailabilityCommon
              eventData={listerSeatAvailabilityData?.overdueData.slice(0, 50)}
              ifLengthExceeds={listerSeatAvailabilityData?.overdueData && listerSeatAvailabilityData?.overdueData.length > 50 ? true : false }
              ifShowingHyperlinkedLabel={false}
              showLoader={showLoader}
              queryParams={queryParams}
              label="Overdue"
              paramObject={{seat_availability_update: 1,exclude_sold_out_events: true, exclude_cancelled_events: true}}
              textLimits={{ browser: 21, mobile: 19 }}
              tagStyleType="noOfDays"
              labelColorClass="text-danger"
            />
          </Col>
          <Col className={widgetClassPerCol[3]}>
            <SeatAvailabilityCommon
              eventData={listerSeatAvailabilityData?.todayData.slice(0, 50)}
              ifLengthExceeds={listerSeatAvailabilityData?.todayData && listerSeatAvailabilityData?.todayData.length > 50 ? true : false }
              ifShowingHyperlinkedLabel={false}
              showLoader={showLoader}
              queryParams={queryParams}
              label="Today"
              paramObject={{seat_availability_update: 2,exclude_sold_out_events: true, exclude_cancelled_events: true}}
              textLimits={{ browser: 31, mobile: 28 }}
              labelColorClass="text-danger"
            />
          </Col>
          <Col className={widgetClassPerCol[3]}>
            <SeatAvailabilityCommon
              eventData={listerSeatAvailabilityData?.upcomingData.slice(0, 50)}
              ifLengthExceeds={listerSeatAvailabilityData?.upcomingData && listerSeatAvailabilityData?.upcomingData.length > 50 ? true : false }
              ifShowingHyperlinkedLabel={false}
              showLoader={showLoader}
              queryParams={queryParams}
              label="Upcoming"
              paramObject={{seat_availability_update: 3,exclude_sold_out_events: true, exclude_cancelled_events: true}}
              textLimits={{ browser: 21, mobile: 19 }}
              tagStyleType="nameOfDays"
              labelColorClass="text-success"
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default SeatAvailability;
