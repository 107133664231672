import React from "react";
import { useRbac } from "features/rbac/rbac";
import { CommonTableHeader } from "models/interfaces";

const TableDataRow: React.FC<{
  headers: CommonTableHeader[];
  singleData: { [key: string]: any };
  selectedRow: { [key: string]: any } | null;
  calculateStickyProperty: Function;
  onRowClicked: Function;
  otherData?: any;
}> = ({
  headers,
  singleData,
  selectedRow,
  calculateStickyProperty,
  onRowClicked,
  otherData,
}) => {
  const { hasPermissions } = useRbac();
  const thSelectionClass = () => {
    let classNames = [];
    if (
      singleData.activeSelectedClass &&
      singleData.activeSelectedClass !== ""
    ) {
      classNames.push(singleData.activeSelectedClass);
    }
    if (singleData === selectedRow) {
      classNames.push("blueSelected");
    }
    return classNames.join(" ");
  };

  const tdClassRender = (eachHeader: CommonTableHeader) =>{
    let className = calculateStickyProperty(eachHeader).class;
    if(eachHeader.tdClassName){
      className = className+' '+eachHeader.tdClassName;
    }
    return className;
  }

  const getRowElems = (eachHeader: any) => {
    if (singleData[eachHeader.dataIndex] !== undefined) {
      if (eachHeader.dataRender) {
        return eachHeader.dataRender(singleData[eachHeader.dataIndex], {
          data: { singleData },
          otherData: otherData,
        });
      }
      return singleData[eachHeader.dataIndex];
    }
    return "";
  }

  return (
    <tr
      onClick={() => {
        onRowClicked(singleData, false);
      }}
      className={thSelectionClass()}
    >
      {headers.map((eachHeader, i) => {
        return (
          <React.Fragment key={`${i}_row_${eachHeader.key}}`}>
            {(!eachHeader.allowedPermissions || hasPermissions(eachHeader.allowedPermissions)) &&
              <td
                //className={calculateStickyProperty(eachHeader).class + eachHeader.tdClassName !== undefined ? " "+eachHeader.tdClassName : ''}
                className={tdClassRender(eachHeader)}
                style={calculateStickyProperty(eachHeader, eachHeader.tdStyle).style}
                onClick={() => {
                  if(eachHeader.canSelectRow){
                    onRowClicked(singleData, true);
                  }
                }}
              >
                { getRowElems(eachHeader) }
              </td>
            }
          </React.Fragment>
        );
      })}
    </tr>
  );
};

export default TableDataRow;
