import { INotesHistory } from "models/interfaces";
import React from "react";
import EachNote from "./EachNote";

const NotesHistoryArea: React.FC<{
  zoneRowCount: number;
  notesHistory: INotesHistory[];
  setCopiedNote: Function;
}> = ({ zoneRowCount, notesHistory, setCopiedNote }) => {
  
  return (
    <React.Fragment>
      {notesHistory.length > 0 ? (
        <div className="notesWithHistory">
          <ul
            className="userCard"
            style={{
              minHeight: `${zoneRowCount > 0 ? zoneRowCount * 40 + 60 : 211}px`,
              maxHeight: `${zoneRowCount * 40 + 60}px`
            }}
          >
            {notesHistory.map((eachNote) => (
              <EachNote 
                eachNote={eachNote}
                setCopiedNote={setCopiedNote}
              />
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default NotesHistoryArea;
