import { RoutesEnum } from "models/enums";
import {
  ActiveInventoryData,
  IInventoryListItemQueryParams,
  IInventoryQueryParams,
  ISoldInventoryData,
  ISoldInventoryListItem,
  CancelInventoryBodyDto,
  ICancelInvoiceBodyDto,
  IUnfilledZonesResponse,
  IUnfilledZoneItemQueryParams,
  ICancelledInventoryData,
  ICancelledInventoryQueryParams
} from "models/interfaces";
import { EventMappingListResponseData, VenuesWithUnmappedEventData, IEventMappingListQueryParams } from "models/interfaces/HouseKeeping/EventMapping.model";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class HouseKeepingService {
  public getActiveInventoryList = async (query: IInventoryQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ActiveInventoryData>>(
      RoutesEnum.ACTIVE_INVENTORY,
      {
        params: query,
      }
    );
  };

  public getSoldInventoryList = async (query: IInventoryQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISoldInventoryData>>(
      RoutesEnum.SOLD_INVENTORY,
      {
        params: query,
      }
    );
  };

  public getSoldInventoryListItem = async (
    query: IInventoryListItemQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<ISoldInventoryListItem>>(
      RoutesEnum.SOLD_INVENTORY_LIST_ITEM,
      {
        params: query,
      }
    );
  };

  public postCancelInventory = async (body: CancelInventoryBodyDto) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.CANCEL_INVENTORY,
      body
    );
  };

  /** This service is used in CancelledShows/Sold Inventory - cta for cancel, and CancelledShows/Unfilled Zones - cta for cancel */
  public cancelInvoice = async (body: ICancelInvoiceBodyDto) => {
    return axiosClient.post<IApiBaseResponse>(RoutesEnum.CANCEL_INVOICE, body);
  };

  public getUnfilledZonesList = async (query: IInventoryQueryParams) => {
    return axiosClient.get<IApiBaseResponse<IUnfilledZonesResponse>>(
      RoutesEnum.UNFILLED_ZONES,
      {
        params: query,
      }
    );
  };

  public getUnfilledZoneListItem = async (
    query: IUnfilledZoneItemQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse>(
      RoutesEnum.UNFILLED_ZONE_LIST_ITEM,
      {
        params: query,
      }
    );
  };

  public getCancelledInventoriesList = async (query: ICancelledInventoryQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ICancelledInventoryData>>(
      RoutesEnum.CANCELLED_INVENTORY,
      {
        params: query,
      }
    );
  };

  public getVenuesWithUnmappedEvent = async (query: null) => {
    return axiosClient.get<IApiBaseResponse<VenuesWithUnmappedEventData>>(
      RoutesEnum.VENUES_WITH_UNMAPPED_EVENT,
      {
        params: query,
      }
    );
  };

  public getAllEventsForMapping = async (query: IEventMappingListQueryParams) => {
    return axiosClient.get<IApiBaseResponse<EventMappingListResponseData>>(
      RoutesEnum.EVENTS_FOR_MAPPING,
      {
        params: query,
      }
    );
  };

  public updateScrapedEvents = async (body: any) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPDATE_SCRAPING_EVENTS,
      body
    );
  };
}
