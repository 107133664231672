import { Typography } from "antd";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { FixedColumnDirection } from "models/enums/table";
import React from "react";
import { AppSetting } from "utils/appSettings";
import { addToolTip, formatAccountingValues, formatNumericValues } from "utils/commonFunction";
import { formatDateGivenFormatString, getCustomDate } from "utils/formatTimePeriod";
import CancelInvoice from "./Actions/CancelInvoice";

const commonWidth = 100;

export const soldInventoryHeaders = [
  {
    title: "",
    dataIndex: "invLineId",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    dataRender: (_data: number, allData: any) => (
      <RBAC
        allowedPermissions={[
          ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_CANCEL_INVOICE,
        ]}
      >
        {!allData.data.singleData.is_invoice_cancel_in_progress &&
          !allData.data.singleData.is_purchase_cancel_in_progress && (
            <CancelInvoice
              poLineId={allData.data.singleData.poLineId}
              invLineId={allData.data.singleData.invLineId}
              initModalData={allData.data.singleData}
            />
          )}
        {allData.data.singleData.is_invoice_cancel_in_progress ||
          (allData.data.singleData.is_purchase_cancel_in_progress && (
            <span>Cancel Pending</span>
          ))}
      </RBAC>
    ),
  },
  {
    title: "P&L Date",
    dataIndex: "plDate",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (plDate: string) =>
      plDate && getCustomDate(plDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Invoice ID",
    dataIndex: "invoiceId",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoiceId: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoiceId}
      >
        {invoiceId}
      </a>
    ),
  },
  {
    title: "Invoice Line ID",
    dataIndex: "invLineId",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
  },
  {
    title: "Invoice Line Date",
    dataIndex: "invoiceLineDate",
    width: commonWidth,
    canSort: true,
    dataRender: (invoiceLineDate: string) =>
      invoiceLineDate && getCustomDate(invoiceLineDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Purchase Date",
    dataIndex: "purchaseDate",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseDate: string) =>
      purchaseDate && getCustomDate(purchaseDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currencyCode",
    canSort: true,
    width: commonWidth,
    dataRender: (data: any) => <div className="text-upper">{data}</div>,
  },
  {
    title: "Ticket Cost",
    dataIndex: "ticketCost",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketCost: number) => (
      <div className={`text-right ${ticketCost < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketCost)}
      </div>
    ),
  },
  {
    title: "Ticket Sales",
    dataIndex: "ticketSales",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (ticketSales: number) => (
      <div className={`text-right ${ticketSales < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(ticketSales)}
      </div>
    ),
  },
  {
    title: "P & L (API)",
    dataIndex: "p&lApi",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (plApi: number) => (
      <div className={`text-right ${plApi < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(plApi)}
      </div>
    ),
  },
  {
    title: "Profit (Loss)",
    dataIndex: "profitLoss",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (profitLossCalculated: number, all: any) => {
      const allData = all.data.singleData;
      let redTag = "";

      if (allData["p&l"] !== allData["p&l_calculated"])
        redTag += " ant-tag danger";

      return (
        <div
          className={`text-right ${
            profitLossCalculated < 0 ? "text-danger" : ""
          }`}
        >
          <span className={`${redTag}`}>
            {formatAccountingValues(profitLossCalculated)}
          </span>
        </div>
      );
    },
  },
  {
    title: "Gross Profit",
    dataIndex: "grossProfit",
    canSort: true,
    width: commonWidth,
    thClassName: "text-right",
    dataRender: (grossProfit: number) => (
      <div className={`text-right ${grossProfit < 0 ? "text-danger" : ""}`}>
        <b>{formatAccountingValues(grossProfit)}</b>
      </div>
    ),
  },
  {
    title: "External Reference",
    dataIndex: "externalRef",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (customer: any) =>
      (customer && customer.length) >= 18 ? addToolTip(customer,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{customer}</div>,
  },
  {
    title: "Purchase ID",
    dataIndex: "purchaseId",
    canSort: true,
    width: commonWidth,
    dataRender: (purchaseId: number[]) =>
      purchaseId &&
      purchaseId.length &&
      purchaseId.map((id, index) => {
        return (
          <React.Fragment>
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={AppSetting.PURCHASE_ID_URL + id}
              underline={true}
            >
              {`${id}`}
            </Typography.Link>
            {`${index < purchaseId.length - 1 ? ", " : ""}`}
          </React.Fragment>
        );
      }),
  },
  {
    title: "PO Line ID",
    dataIndex: "poLineId",
    canSort: true,
    width: commonWidth,
    dataRender: (poLineId: number[]) =>
      poLineId && poLineId.length && poLineId.join(", "),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (vendor: any) =>
      (vendor && vendor.length) >= 18 ? addToolTip(vendor, "right", "default", 18, false, {textTransform: "capitalize"}) : <div>{vendor}</div>,
  },
  {
    title: "Event",
    dataIndex: "event",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (event: any, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.eventId}
        className="text-capitalize"
      >
        {event?.length >= 40 ? addToolTip(event,"right", "pointer", 40, false, {textTransform: "capitalize"}) : event}
      </a>
    ),
  },
  {
    title: "Event Date",
    dataIndex: "event_date_time",
    canSort: true,
    width: commonWidth,
    dataRender: (eventDate: string) =>
      eventDate && formatDateGivenFormatString(eventDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Venue",
    dataIndex: "venue",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (venue: any) =>
      (venue && venue.length) >= 18 ? addToolTip(venue,"right", "default", 18, false, {textTransform: "capitalize"}) : <div>{venue}</div>,
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
    tdClassName: 'text-capitalize',
    dataRender: (section: any) =>
      (section && section.length) >= 18 ? addToolTip(section,"right", "default", 18) : <div>{section}</div>,
  },
  {
    title: "Row",
    dataIndex: "row",
    canSort: true,
    width: commonWidth,
    dataRender: (row: any) => <div className='text-upper'>{row}</div>,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    canSort: true,
    width: commonWidth,
    dataRender: (seats: any) =>
      (seats && seats.length) > 15 ? addToolTip(seats) : <div>{seats}</div>,
  },
  {
    title: "Internal Notes",
    dataIndex: "internalNotes",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
  },
  {
    title: "Fulfillment Status",
    dataIndex: "fulfillmentStatus",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentStatus: any) => <div className='text-capitalize'>{fulfillmentStatus}</div>
  },
  {
    title: "Fulfillment Date",
    dataIndex: "fulfillmentDate",
    canSort: true,
    width: commonWidth,
    dataRender: (fulfillmentDate: string) =>
      fulfillmentDate && getCustomDate(fulfillmentDate, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventoryTags",
    canSort: true,
    width: commonWidth,
    dataRender: (inventoryTags: any) => <div>{inventoryTags}</div>
  },
  {
    title: "Invoice Tags",
    dataIndex: "invoiceTags",
    canSort: true,
    width: commonWidth,
  },
  {
    title: "Invoice Payment Method",
    dataIndex: "PaymentType",
    canSort: true,
    width: commonWidth,
    dataRender: (invoicePaymentMethod: any) => <div className='text-capitalize'>{invoicePaymentMethod}</div>
  },
];