/* eslint-disable */
import { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import barChart from "../../assets/images/bar-chart.svg";
import paiChart from "../../assets/images/pai-chart.svg";
import AvailabilityTrendChart from "../../assets/images/availability-trend-chart.svg";
import inboxAvatar from "../../assets/images/user-sm-thumb-img.png";
import inboxAvatar2 from "../../assets/images/user-mid-thumb-img.png";
import {
  Layout,
  Menu,
  Breadcrumb,
  Dropdown,
  Row,
  Col,
  Card,
  Pagination,
  Table,
  Tag,
  Drawer,
  Input,
  Button,
  Select,
  Radio,
  Checkbox,
  DatePicker,
  Modal,
  Tooltip,
  Switch,
  Tabs,
  Space,
  InputNumber} from "antd";
import moment from "moment";
import TextArea from "rc-textarea";
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const menu = (
  <Menu>
    <Menu.Item>Rajib Sahoo</Menu.Item>
    <Menu.Item>Logout</Menu.Item>
  </Menu>
);
const messageMenu = (
  <Menu className="dropdownMsg">
    <div>
      <div className="ddHeader">Message Options</div>
      <ul>
        <li><a href="#">New Message</a></li>
        <li><a href="#" className="active">Inbox Message<span>(2)</span></a></li>
      </ul>
    </div>
  </Menu>
);
const inboxActionDropdown = (
  <Menu className="dropdownMenu" style={{ minWidth: '210px' }}>
    <li><a href="#" className="active">Mark as read</a></li>
    <li><a href="#">Archive</a></li>
    <li><a href="#">Close Thread</a></li>
  </Menu>
);
const sortOptionClick = (
  <Menu>
    <Menu.Item>Days to Event</Menu.Item>
    <Menu.Item>Active<br />Listings</Menu.Item>
    <Menu.Item>Event</Menu.Item>
    <Menu.Item>Venue</Menu.Item>
    <Menu.Item>Broker</Menu.Item>
  </Menu>
);

const filterDropdown = (
  <div className="dropdownFilterWrap" style={{ minWidth: '300px' }}>
    <div className="dropdownFilterHeader">
      <span>FILTER OPTIONS</span>
      <span className="da icon-close-white"></span>
    </div>
    <div className="dark">
     
        <div className="inputWrap">
          <div className="checkboxGroup">
            <Checkbox>Not Delivered</Checkbox>
          </div>
        </div>
   
        <div className="inputWrap">
          <div className="checkboxGroup">
            <Checkbox>Delivery Delay / Will Call</Checkbox>
          </div>
        </div>
  
        <div className="inputWrap">
          <div className="checkboxGroup">
            <Checkbox>Waiting on Hard Ticketts</Checkbox>
          </div>
        </div>
    
      <div className="formEnd">
          <div className="btnsRight">
            <Button className="linkBtn">RESET</Button>
            <Button className="linkBtn">CANCEL</Button>
            <Button className="btnOk">APPLY</Button>
          </div>
        </div>
      
    </div>
  </div>
);

const { Column } = Table;
const columns = [
  {
    title: "PO Date",
    dataIndex: "podate",
  },
  {
    title: "Po Num",
    dataIndex: "ponum",
  },
  {
    title: "Alert",
    dataIndex: "alert",
  },
];

const data = [
  {
    key: "1",
    podate: "08-30-2021",
    ponum: <a href="#/">64544202</a>,
    alert: <Tag className="brownTag">15 days</Tag>,
  },
  {
    key: "2",
    podate: "08-30-2021",
    ponum: <a href="#/" onClick={(e) => e.preventDefault()}>64544202</a>,
    alert: <Tag className="flirtTag">Cost Missing</Tag>,
  },
  {
    key: "3",
    podate: "08-30-2021",
    ponum: <a href="#/" onClick={(e) => e.preventDefault()}>64544202</a>,
    alert: <Tag className="info">Card Details Missing</Tag>,
  },
];

const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";


export function StyleGuidePage() {
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalVisible, setIsModalVisibles] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [isModalVisible5, setIsModalVisible5] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [isModalVisible7, setIsModalVisible7] = useState(false);
  const [isModalVisible8, setIsModalVisible8] = useState(false);
  const [isModalVisible9, setIsModalVisible9] = useState(false);
  const [isModalVisible10, setIsModalVisible10] = useState(false);
  const [isModalVisible11, setIsModalVisible11] = useState(false);
  const [isModalVisible12, setIsModalVisible12] = useState(false);
  const [isModalVisible13, setIsModalVisible13] = useState(false);
  const [isModalVisible14, setIsModalVisible14] = useState(false);
  const [isModalVisible15, setIsModalVisible15] = useState(false);
  const [isModalVisible16, setIsModalVisible16] = useState(false);
  const [isModalVisible17, setIsModalVisible17] = useState(false);
  const [isModalVisible18, setIsModalVisible18] = useState(false);
  const [isModalVisible19, setIsModalVisible19] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      title: <div className="confirmHeader">Are you sure you want to <strong>delete</strong> this schedule?</div>,
      // icon: <ExclamationCircleOutlined />,
      content: <div className="confirmBody">Long Inventory - Not Listed Report</div>,
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('CANCEL');
      },
    });
  }

  const showUnBroadcastModal = () => {
    setIsModalVisibles(true);
  };
  const handleCancel = () => {
    setIsModalVisibles(false);
  };

  const showDeleteCardModal = () => {
    setIsModalVisible2(true);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  const showAvailabilityTrendModal = () => {
    setIsModalVisible3(true);
  };
  const AvailabilityTrendCancel = () => {
    setIsModalVisible3(false);
  };
  const showEditUserInfoModal = () => {
    setIsModalVisible4(true);
  };
  const editUserInfoCancel = () => {
    setIsModalVisible4(false);
  };
  const showEditListingModal = () => {
    setIsModalVisible5(true);
  };
  const editListingCancel = () => {
    setIsModalVisible5(false);
  };

  const showFillSeatsFormModal = () => {
    setIsModalVisible6(true);
  };
  const fillSeatsFormCancel = () => {
    setIsModalVisible6(false);
  };

  const showEditVenueModal = () => {
    setIsModalVisible7(true);
  };
  const editVenueModalCancel = () => {
    setIsModalVisible7(false);
  };

  const showCreateNewListingModal = () => {
    setIsModalVisible8(true);
  };
  const createNewListingCancel = () => {
    setIsModalVisible8(false);
  };

  const showComposeNewMessageModal = () => {
    setIsModalVisible9(true);
  };
  const composeNewMessageCancel = () => {
    setIsModalVisible9(false);
  };

  const showCreateMessageModal = () => {
    setIsModalVisible10(true);
  };
  const composeCreateMessageCancel = () => {
    setIsModalVisible10(false);
  };

  const showCancelInventoryModal = () => {
    setIsModalVisible11(true);
  };
  const cancelInventoryModalCancel = () => {
    setIsModalVisible11(false);
  };

  const showCancelInvoiceModal = () => {
    setIsModalVisible12(true);
  };
  const cancelInvoiceModalCancel = () => {
    setIsModalVisible12(false);
  };

  const showFinalizeListingModal = () => {
    setIsModalVisible13(true);
  };
  const cancelFinalizeListing = () => {
    setIsModalVisible13(false);
  };

  const showInventoryListingFormModal = () => {
    setIsModalVisible14(true);
  };
  const cancelInventoryListingForm = () => {
    setIsModalVisible14(false);
  };

  const showCreateSplitModal = () => {
    setIsModalVisible15(true);
  };
  const cancelCreateSplitModal = () => {
    setIsModalVisible15(false);
  };

  const showAuditHistoryModal = () => {
    setIsModalVisible16(true);
  };
  const cancelAuditHistoryModal = () => {
    setIsModalVisible16(false);
  };
  const showEditListing2Modal = () => {
    setIsModalVisible17(true);
  };
  const cancelEditListing2Modal = () => {
    setIsModalVisible17(false);
  };

  const showCompetitiveModal = () => {
    setIsModalVisible18(true);
  };
  const cancelCompetitiveModal = () => {
    setIsModalVisible18(false);
  };

  const showNotesHistoryModal = () => {
    setIsModalVisible19(true);
  };
  const cancelNotesHistoryModal = () => {
    setIsModalVisible19(false);
  };

  

  return (
    <div className="mainWrapper fixedFilterArea">
      <Header className="header">
        <div className="headerLeft">
          <a href="#" className="logo">
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>

          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="">
              <span>Reports</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Listing: Long Inventory Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <ul className="headerRight">
          <li>
            <div>
              <i className="da icon-bell" />
            </div>
          </li>
          <li>
            <div>
              <i className="da icon-question" />
            </div>
          </li>
          <li>
            <div>
              <i className="da icon-user" />
            </div>
          </li>
          <li className="username">
            <Dropdown overlay={menu} trigger={["click"]} className="dropdown">
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="dropdown-btn"
              >
                Username <i className="da icon-arrow-down" />
              </a>
            </Dropdown>
          </li>
        </ul>
      </Header>
      <div className="bodyWrapperx">
        {/* <Sider width={150} className="sidebarSiderWrap">
          <div className="sidebarHeader">
            <button type="button" className="toggleSidebarBtn">
              <i className="da icon-arrow-back-circle" />
            </button>
          </div>
          <Menu defaultSelectedKeys={["1"]} mode="inline" className="leftMenu">
            <Menu.Item key="1" icon={<i className="da icon-home" />}>
              Dashboard
            </Menu.Item>
            <SubMenu
              key="sub1"
              icon={<i className="da icon-zone" />}
              title="Zones"
            >
              <Menu.Item key="2">Zone Listings</Menu.Item>
              <Menu.Item key="3">Pending<br />Fills</Menu.Item>
              <Menu.Item key="4">Insights</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<i className="da icon-event" />}
              title="Venues &amp; Events"
            >
              <Menu.Item key="events1">Events1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub4"
              icon={<i className="da icon-message" />}
              title="Message"
            >
              <Menu.Item key="message">Message1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub5"
              icon={<i className="da icon-report" />}
              title="Reports"
            >
              <Menu.Item key="reports">Reports1</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub6"
              icon={<i className="da icon-settings" />}
              title="Settings"
            >
              <Menu.Item key="settings1">Settings1</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider> */}

        <Layout className="contentMainLayout" style={{ paddingTop: "50px" }}>
          <ul className="anchorToPoint">
            <li>
              <a href="#typography">Typography</a>
            </li>
            <li>
              <a href="#buttons">Buttons</a>
            </li>
            <li>
              <a href="#tags">Tags</a>
            </li>
            <li>
              <a href="#filterDrawer">Filter Drawer</a>
            </li>
            <li>
              <a href="#icons">Icons</a>
            </li>
            <li>
              <a href="#confirmationDialog">Confirmation modal dialog</a>
            </li>
            <li>
              <a href="#alert">Alert</a>
            </li>
            <li>
              <a href="#reportsListing">Reports Listing</a>
            </li>
            <li>
              <a href="#inventoryReportTables">Inventory Report Table</a>
            </li>
            <li>
              <a href="#schedulerTable">Scheduler Table</a>
            </li>
            <li>
              <a href="#grossProfit">Gross Profit Report</a>
            </li>
            <li>
              <a href="#chart">Chart</a>
            </li>
            <li>
              <a href="#schedulerModal">Create New Scheduler Modal</a>
            </li>
            <li>
              <a href="#userManagement">User Management</a>
            </li>
            <li>
              <a href="#zones">Active Zones</a>
            </li>
            <li>
              <a href="#pendingZone">Pending Zones</a>
            </li>
            <li>
              <a href="#venues">Venues</a>
            </li>
            <li>
              <a href="#events">Events</a>
            </li>
            <li>
              <a href="#editEventSection">Edit Events</a>
            </li>
            <li>
              <a href="#createNewListingID">Create New Listing</a>
            </li>
            <li>
              <a href="#emailTempReportSchedule">
                Email Template Report Schedule
              </a>
            </li>
            <li>
              <a href="#message">Message</a>
            </li>
            <li>
              <a href="#error404Page">404 Error</a>
            </li>
            <li>
              <a href="#cancelInventory">Cancel Inventory</a>
            </li>
            <li>
              <a href="#commissions">Commissions</a>
            </li>
            <li>
              <a href="#lister-dashboard">lister Dashboard</a>
            </li>
            <li>
              <a href="#seat-map-creator-dashboard">
                Seat map creator Dashboard
              </a>
            </li>
            <li>
              <a href="#zoneInsights">Zone Insights</a>
            </li>
            <li>
              <a href="#purchaseQueue">Purchase Queue</a>
            </li>
            <li>
              <a href="#eventMaping">Event Maping</a>
            </li>
            <li>
              <a href="#operations-dashboard">Operations Dashboard</a>
            </li>
            <li>
              <a href="#sales-dashboard">Sales Dashboard</a>
            </li>
            <li>
              <a href="#bulkPlacementID">Bulk Placement</a>
            </li>
            <li>
              <a href="#NotesHistoryModal">Notes History Modal</a>
            </li>
            
          </ul>
          <Layout className="mainInnerLayout">
            <Card className="customCard" id="typography">
              <div className="customCardBody">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ width: "50%" }}>
                      <h3>Typography: Roboto</h3>
                      <h1>h1</h1>Roboto Bold: / 700 Titlecase 50 px [Secondary
                      pages]
                      <br />
                      <br />
                      <h2>h2</h2>Roboto Bold / 700 Black: Uppercase 25 px [Page
                      Title, List Price]
                      <br />
                      <br />
                      <h3>h3</h3>Roboto Bold: Title Case 20 px [Section Titles]
                      <br />
                      <br />
                      <h4>h4</h4> Roboto Bold: Title Case 15 px [Content
                      Headings]
                      <br />
                      <br />
                    </td>
                    <td>
                      <h3>Text Colors</h3>
                      <p className="text-primary">Text primary</p>
                      <p className="text-success">Text success</p>
                      <p className="text-danger">Text danger</p>
                      <p className="text-warning">Text warning</p>
                    </td>
                    <td>
                      <h3>Text Align</h3>
                      <p className="text-left">Text align Left</p>
                      <p className="text-center">Text align Center</p>
                      <p className="text-right">Text align Right</p>
                    </td>
                  </tr>
                </table>
              </div>
            </Card>
            <Card className="customCard" id="buttons">
              <div className="customCardBody">
                <h3>Buttons and Switch</h3>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ width: "50%" }}>
                      <div className="flx alignItemsCenter">
                        <a href="#/" className="linkBtn">
                          RESET
                        </a>{" "}
                        &nbsp; &nbsp;
                        <a href="#/" className="linkBtn">
                          CANCEL
                        </a>{" "}
                        &nbsp; &nbsp;
                        <Button className="btnOk">OK</Button>
                      </div>
                      <br />
                      <h3>Discard &amp; SAVE buttons </h3>
                      <div className="flx alignItemsCenter">
                        <a href="#/" className="discardBtn">
                          <i className="da icon-trash"></i>
                          <span>DISCARD</span>
                        </a>{" "}
                        &nbsp; &nbsp;
                        <Button className="btnSave">SAVE</Button>
                      </div>
                      <br />
                      <h3>Hyperlinks</h3>
                      <a href="#/">Hyperlinks</a>
                    </td>
                    <td>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked
                      />
                      <br />
                      <br />
                      <Button
                        className="ant-btn upload"
                        icon={<i className="da icon-upload"></i>}
                      >
                        Click to upload
                      </Button>
                      <br />
                      <button type="button" className="ant-btn upload">
                        <i className="da icon-home"></i>
                        <span>Go to dashboard</span>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </Card>
            <Card className="customCard" id="tags">
              <div className="customCardBody">
                <h3>Tags</h3>
                <div>
                  <Tag className="danger">Danger</Tag>
                  <Tag className="brown">Brown</Tag>
                  <Tag className="info">Info</Tag>
                  <Tag className="success">Success</Tag>
                  <Tag className="pink">Pink</Tag>
                  <Tag className="warning">Warning</Tag>
                  <Tag className="secondary">Secondary</Tag>
                </div>
              </div>
            </Card>
            <Card className="customCard" id="filterDrawer">
              <div className="customCardBody">
                <h3>FILTER DRAWER</h3>
                <Button className="btnPrimary" onClick={showDrawer}>
                  FILTER OPTIONS
                </Button>
                <Drawer
                  width={600}
                  className="customDrawer dark"
                  placement="right"
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                >
                  <div className="drawerHeader">
                    <h5>FILTER OPTIONS</h5>
                    <div>
                      <Button>
                        <i className="da icon-arrow-listview-lite"></i>
                      </Button>
                      <Button onClick={onClose}>
                        <i className="da icon-arrow-right-lite"></i>
                      </Button>
                    </div>
                  </div>
                  <h2>All Listings</h2>
                  <div className="inputWrap">
                    <label>EVENT DATE RANGE</label>
                    <div className="rangePickerWrap">
                      <RangePicker
                        defaultValue={[
                          moment("2019-09-03", dateFormat),
                          moment("2019-11-22", dateFormat),
                        ]}
                        disabled={[false, false]}
                      />
                    </div>
                  </div>
                  <div className="inputWrap">
                    <label>BROKER</label>
                    <Select defaultValue="BrokerName">
                      <Option value="BrokerName">Broker Name</Option>
                      <Option value="option2">Option 2</Option>
                    </Select>
                  </div>
                  <div className="inputWrap">
                    <label>LISTER</label>
                    <Select defaultValue="listerName">
                      <Option value="listerName">Lister Name</Option>
                      <Option value="option2">Option 2</Option>
                    </Select>
                  </div>
                  <div className="inputWrap">
                    <label>STATE</label>
                    <Select defaultValue="stateName">
                      <Option value="stateName">State Name</Option>
                      <Option value="option2">Option 2</Option>
                    </Select>
                  </div>
                  <div className="inputWrap">
                    <label>Availability</label>
                    <div className="radioGroup">
                      <Radio.Group>
                        <Radio value={1}>LOW</Radio>
                        <Radio value={2}>MEDIUM</Radio>
                        <Radio value={3}>HIGH</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="inputWrap">
                    <div className="checkboxGroup">
                      <Checkbox>
                        Pending
                        <br />
                        Fillss
                      </Checkbox>
                    </div>
                  </div>
                  <div className="inputWrap">
                    <div className="checkboxGroup">
                      <Checkbox>Pending Fulfillment</Checkbox>
                    </div>
                  </div>
                  <div className="inputWrap">
                    <div className="checkboxGroup">
                      <Checkbox>TB2.0 Placement</Checkbox>
                    </div>
                  </div>
                  <div className="formEnd">
                    <div className="btnsRight">
                      <a href="#/" className="linkBtn">
                        RESET
                      </a>
                      <a href="#/" className="linkBtn">
                        CANCEL
                      </a>
                      <Button className="btnOk">APPLY</Button>
                    </div>
                  </div>
                </Drawer>
              </div>
            </Card>
            <Card className="customCard" id="icons">
              <div className="customCardBody">
                <h3>Icons</h3>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ width: "50%" }}>
                      <ul className="iconset">
                        <li>
                          <i className="da icon-bell"></i> da icon-bell
                        </li>
                        <li>
                          <i className="da icon-question"></i> da icon-question
                        </li>
                        <li>
                          <i className="da icon-user"></i> da icon-user
                        </li>
                        <li>
                          <i className="da icon-arrow-down"></i> da
                          icon-arrow-down
                        </li>
                        <li>
                          <i className="da icon-arrow-back-circle"></i> da
                          icon-arrow-back-circle
                        </li>
                        <li>
                          <i className="da icon-arrow-up-circle"></i> da
                          icon-arrow-up-circle
                        </li>
                        <li>
                          <i className="da icon-arrow-down-circle"></i> da
                          icon-arrow-down-circle
                        </li>
                        <li>
                          <i className="da icon-search"></i> da icon-search
                        </li>
                        <li>
                          <i className="da icon-bookmark-o"></i> da
                          icon-bookmark-o
                        </li>
                        <li>
                          <i className="da icon-bookmark"></i> da icon-bookmark
                        </li>
                        <li>
                          <i className="da icon-bookmark-dark"></i> da
                          icon-bookmark-dark
                        </li>
                        <li>
                          <i className="da icon-dashboard"></i> da
                          icon-dashboard
                        </li>
                        <li>
                          <i className="da icon-listing"></i> da icon-listing
                        </li>
                        <li>
                          <i className="da icon-profitability"></i> da
                          icon-profitability
                        </li>
                        <li>
                          <i className="da icon-play-o"></i> da icon-play-o
                        </li>
                        <li>
                          <i className="da icon-calendar-o"></i> da
                          icon-calendar-o
                        </li>
                        <li>
                          <i className="da icon-listview"></i> da icon-listview
                        </li>
                        <li>
                          <i className="da icon-event-o"></i> da icon-event-o
                        </li>
                        <li>
                          <i className="da icon-pencil-o"></i> da icon-pencil-o
                        </li>
                        <li>
                          <i className="da icon-trash-o"></i> da icon-trash-o
                        </li>
                        <li>
                          <i className="da icon-add-user-o"></i> da
                          icon-add-user-o
                        </li>
                        <li>
                          <i className="da icon-play"></i> da icon-play
                        </li>
                        <li>
                          <i className="da icon-profile"></i> da icon-profile
                        </li>
                        <li>
                          <i className="da icon-user-role"></i> da
                          icon-user-role
                        </li>
                        <li>
                          <i className="da icon-fictionalCharacter-red"></i> da
                          icon-fictionalCharacter-red
                        </li>
                        <li>
                          <i className="da icon-fictionalCharacter-green"></i>{" "}
                          da icon-fictionalCharacter-green
                        </li>
                        <li>
                          <i className="da icon-th-large"></i> da icon-th-large
                        </li>
                        <li>
                          <i className="da icon-th"></i> da icon-th
                        </li>
                        <li>
                          <i className="da icon-th-list"></i> da icon-th-list
                        </li>
                        <li>
                          <i className="da icon-comment"></i> da icon-comment
                        </li>
                        <li>
                          <i className="da icon-user-o"></i> da icon-user-o
                        </li>
                        <li>
                          <i className="da icon-sorting"></i> da icon-sorting
                        </li>
                        <li>
                          <i className="da icon-info-fill"></i> da
                          icon-info-fill
                        </li>
                        <li>
                          <i className="da icon-info-fill-dark"></i> da
                          icon-info-fill-dark
                        </li>
                        <li>
                          <i className="da icon-ticket-o"></i> da icon-ticket-o
                        </li>
                        <li>
                          <i className="da icon-braille"></i> da icon-braille
                        </li>
                        <li>
                          <i className="da icon-chart-up-red"></i> da
                          icon-chart-up-red
                        </li>
                        <li>
                          <i className="da icon-chart-up-green"></i> da
                          icon-chart-up-green
                        </li>
                        <li>
                          <i className="da icon-chart-up-yellow"></i> da
                          icon-chart-up-yellow
                        </li>
                        <li>
                          <i className="da icon-add-layer-blue"></i> da
                          icon-add-layer-blue
                        </li>
                        <li>
                          <i className="da icon-calendar-o"></i> da
                          icon-calendar-o
                        </li>
                        <li>
                          <i className="da icon-network"></i> da icon-network
                        </li>
                        <li>
                          <i className="da icon-network-red"></i> da
                          icon-network-red
                        </li>
                        <li>
                          <i className="da icon-create-message"></i> da
                          icon-create-message
                        </li>
                        <li>
                          <i className="da icon-pdf"></i> da icon-pdf
                        </li>
                        <li>
                          <i className="da icon-kebab-v"></i> da icon-kebab-v
                        </li>
                        <li>
                          <i className="da icon-reply"></i> da icon-reply
                        </li>
                        <li>
                          <i className="da icon-calendar"></i> da icon-calendar
                        </li>
                        <li>
                          <i className="da icon-trash-disabled"></i> da
                          icon-trash-disabled
                        </li>
                        <li>
                          <i className="da icon-save"></i> da icon-save
                        </li>
                        <li>
                          <i className="da icon-cancel"></i> da icon-cancel
                        </li>
                        <li>
                          <i className="da icon-line-chart-dark"></i> da
                          icon-line-chart-dark
                        </li>
                        <li>
                          <i className="da icon-bar-chart-dark"></i> da
                          icon-bar-chart-dark
                        </li>
                        <li>
                          <i className="da icon-broadcast-unbroadcast"></i> da
                          icon-broadcast-unbroadcast
                        </li>
                      </ul>
                    </td>
                    <td
                      style={{
                        width: "100%",
                        background: "#1A2A43",
                        padding: "20px",
                      }}
                    >
                      <ul className="iconset" style={{ color: "#fff" }}>
                        <li>
                          <i className="da icon-home"></i> da icon-home
                        </li>
                        <li>
                          <i className="da icon-zone"></i> da icon-zone
                        </li>
                        <li>
                          <i className="da icon-event"></i> da icon-event
                        </li>
                        <li>
                          <i className="da icon-message"></i> da icon-message
                        </li>
                        <li>
                          <i className="da icon-purchases"></i> da
                          icon-purchases
                        </li>
                        <li>
                          <i className="da icon-housekeeping"></i> da
                          icon-housekeeping
                        </li>
                        <li>
                          <i className="da icon-shipping"></i> da icon-shipping
                        </li>
                        <li>
                          <i className="da icon-report"></i> da icon-report
                        </li>
                        <li>
                          <i className="da icon-settings"></i> da icon-settings
                        </li>
                        <li>
                          <i className="da icon-sort"></i> da icon-sort
                        </li>
                        <li>
                          <i className="da icon-sort-asc"></i> da icon-sort-asc
                        </li>
                        <li>
                          <i className="da icon-sort-desc"></i> da
                          icon-sort-desc
                        </li>
                        <li>
                          <i className="da icon-upload"></i> da icon-upload
                        </li>
                        <li>
                          <i className="da icon-sorting-lite"></i> da
                          icon-sorting-lite
                        </li>
                        <li>
                          <i className="da icon-trash-w-o"></i> da
                          icon-trash-w-o
                        </li>
                        <li>
                          <i className="da icon-exclamation-circle"></i> da
                          icon-exclamation-circle
                        </li>
                        <li>
                          <i className="da icon-success-save"></i> da
                          icon-success-save
                        </li>
                        <li>
                          <i className="da icon-ticket-o-w"></i> da
                          icon-ticket-o-w
                        </li>
                        <li>
                          <i className="da icon-add-layer"></i> da
                          icon-add-layer
                        </li>
                        <li>
                          <i className="da icon-chart-up-white"></i> da
                          icon-chart-up-white
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </Card>

            <Card className="customCard" id="confirmationDialog">
              <div className="customCardBody">
                <h3>Confirmation modal dialog</h3>
                <Button className="btnSave" onClick={showConfirm}>
                  Confirm
                </Button>
              </div>
            </Card>

            <Card className="customCard" id="alert">
              <div className="customCardBody">
                <h3>Alert</h3>
                <div className="alert successAlert">
                  <i className="da icon-trash-w-o"></i>Schedule has been
                  successfully deleted.
                </div>
                <div className="alert failedAlert" style={{ display: "none" }}>
                  <i className="da icon-exclamation-circle"></i>Failed to delete
                  schedule.
                </div>
              </div>
            </Card>
          </Layout>

          <Layout className="mainInnerLayout" id="chart">
            <h3>Gross Margin</h3>

            <div className="chartContainer">
              <div>
                <img src={barChart} alt="" />
              </div>
            </div>
            <div className="chartBtmBar flx justifyContentBetween">
              <ul>
                <li>
                  <a href="#/" className="active">
                    Day
                  </a>
                </li>
                <li>
                  <a href="#/">Week</a>
                </li>
                <li>
                  <a href="#/">Month</a>
                </li>
                <li>
                  <a href="#/">Quarter</a>
                </li>
                <li>
                  <a href="#/">Year</a>
                </li>
              </ul>
              <div className="chartIconList">
                <button>
                  <i className="da icon-line-chart"></i>
                </button>
                <button className="active">
                  <i className="da icon-bar-chart-o"></i>
                </button>
              </div>
            </div>
          </Layout>

          <h2 id="reportsListing">Report</h2>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row>
                  <Col span={6}>
                    <h3>All Reports</h3>
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input type="search" placeholder="Search report..." />
                      <span>&times;</span>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
              </div>
              <div className="allReportsCardWrap">
                <Card className="customCard">
                  <div className="customCardHeader flx alignItemsCenter">
                    <div className="flx alignItemsCenter">
                      <span className="circleStyle1">
                        <i className="da icon-bookmark-o"></i>
                      </span>
                      <h3>Favorites</h3>
                    </div>
                    <div className="right">
                      <i className="da icon-arrow-up-circle toggle"></i>
                    </div>
                  </div>
                  <div className="customCardBody">
                    <Row>
                      <Col span={12} className="pr20">
                        <ul className="reportListing">
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark-o"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark-o"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col span={12} className="pl20">
                        <ul className="reportListing">
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark-o"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark-o"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                          <li>
                            <div className="toggleBookmark">
                              <i className="da icon-bookmark-o"></i>
                            </div>
                            <div className="bookmarkTxt">
                              <a href="#">Long Inventory Snapshot</a>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
              <Card className="customCard" style={{ display: "none" }}>
                <div className="customCardHeader flx alignItemsCenter">
                  <div className="flx alignItemsCenter">
                    <span className="circleStyle1">
                      <i className="da icon-dashboard"></i>
                    </span>
                    <h2>Dashboard</h2>
                  </div>
                  <div className="right">
                    <i className="da icon-arrow-up-circle toggle"></i>
                  </div>
                </div>
                <div className="customCardBody">
                  <Row>
                    <Col span={12} className="pr20">
                      <ul className="reportListing ">
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark"></i>
                          </a>
                          <div>
                            <a href="#/">Overall Report</a>
                          </div>
                        </li>
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark-o"></i>
                          </a>
                          <div>
                            <a href="#/">Fifth Long Report</a>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Card>

              <Card className="customCard" style={{ display: "none" }}>
                <div className="customCardHeader flx alignItemsCenter">
                  <div className="flx alignItemsCenter">
                    <span className="circleStyle1">
                      <i className="da icon-listing"></i>
                    </span>
                    <h2>Listing</h2>
                  </div>
                  <div className="right">
                    <i className="da icon-arrow-up-circle toggle"></i>
                  </div>
                </div>
                <div className="customCardBody">
                  <Row>
                    <Col span={12} className="pr20">
                      <ul className="reportListing ">
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark-o"></i>
                          </a>
                          <div>
                            <a href="#/">Long Inventory Score Card</a>
                          </div>
                        </li>
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark"></i>
                          </a>
                          <div>
                            <a href="#/">Long Inventory Report</a>
                          </div>
                        </li>
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark-o"></i>
                          </a>
                          <div>
                            <a href="#/">Long Inventory - Not Listed Report</a>
                          </div>
                        </li>
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark-o"></i>
                          </a>
                          <div>
                            <a href="#/">Sixth Long Report</a>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col span={12} className="pl20">
                      <ul className="reportListing">
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark-o"></i>
                          </a>
                          <div>
                            <a href="#/">Seventh Long Report</a>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Card>
              <Card className="customCard" style={{ display: "none" }}>
                <div className="customCardHeader flx alignItemsCenter">
                  <div className="flx alignItemsCenter">
                    <span className="circleStyle1">
                      <i className="da icon-profitability"></i>
                    </span>
                    <h2>Profitability</h2>
                  </div>
                  <div className="right">
                    <i className="da icon-arrow-up-circle toggle"></i>
                  </div>
                </div>
                <div className="customCardBody">
                  <Row>
                    <Col span={12} className="pr20">
                      <ul className="reportListing ">
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark"></i>
                          </a>
                          <div>
                            <a href="#/">Gross Profit Report</a>
                          </div>
                        </li>
                        <li>
                          <a href="#/" className="toggleBookmark">
                            <i className="da icon-bookmark"></i>
                          </a>
                          <div>
                            <a href="#/">Eighth Long Report</a>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Content>
          </Layout>

          {/* Long Inventory Report */}

          <Layout className="mainInnerLayout pb-0">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row>
                  <Col span={6}>
                    <h3 id="inventoryReportTables">Long Inventory Report</h3>
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input type="search" placeholder="Search report..." />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>548 Records Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                      <li>
                        <button type="button">
                          <i className="da icon-calendar-o"></i>
                        </button>
                      </li>
                      <li>
                        <button>
                          <i className="da icon-listview"></i>
                        </button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="removeTblLRSpc">
                <div
                  id="table-scroll"
                  className="table-scroll"
                  style={{ maxHeight: "200px" }}
                >
                  <table
                    id="main-table"
                    className="main-table fixedHeaderFooter"
                  >
                    <thead>
                      <tr>
                        <th
                          className="colLeftSticky"
                          scope="col"
                          style={{ left: "0px" }}
                        >
                          PO DATE
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th
                          scope="col"
                          className="colLeftSticky"
                          style={{ left: "84px" }}
                        >
                          PO NUM
                          <i className="da sortIcon icon-sort-asc" />
                        </th>
                        <th scope="col" className="text-left">
                          Alert
                          <i className="da sortIcon  icon-sort-desc" />
                        </th>
                        <th scope="col">
                          Broker
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Buyer
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          PO Tag
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Event ID
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Event Name
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Event Date &amp; Time
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Days to Event
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Venue Name
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Venue Location
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Section
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Row
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Seats
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          In-hand Date
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Qty
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Currency
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Unit Cost
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          List Price
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          List Price
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Last Price Update
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Days Old
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Purchase Term
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Payment Method
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Credit Card Group
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col">
                          Credit Card
                          <i className="da sortIcon icon-sort" />
                        </th>
                        {/* <th className="colRightSticky" scope="col">Credit Card</th> */}
                      </tr>
                      <tr className="subheader">
                        <th
                          className="colLeftSticky"
                          scope="col"
                          style={{ left: "0px", top: "48px" }}
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="colLeftSticky"
                          style={{ left: "84px", top: "48px" }}
                        ></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}>
                          22,515.94
                        </th>
                        <th scope="col" style={{ top: "48px" }}>
                          12,515.94
                        </th>
                        <th scope="col" style={{ top: "48px" }}>
                          2,51.94
                        </th>
                        <th scope="col" style={{ top: "48px" }}>
                          22,515.94
                        </th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                        <th scope="col" style={{ top: "48px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="colLeftSticky fW700">10-03-2019</td>
                        <td className="colLeftSticky" style={{ left: "84px" }}>
                          <a target="_blank" href="#">
                            42255848
                          </a>
                        </td>
                        <td>
                          <span className="ant-tag danger">15 days</span>
                          <span className="ant-tag secondary">
                            Cost Missing
                          </span>
                          <span className="ant-tag warning">
                            Card Details Missing
                          </span>
                        </td>
                        <td>corporate</td>
                        <td>1Ticket</td>
                        <td>sad</td>
                        <td>
                          <a target="_blank" href="#">
                            3173473
                          </a>
                        </td>
                        <td>Dear Evan Hansen (Re..</td>
                        <td>03-31-2022, 07:30 PM</td>
                        <td>157</td>
                        <td>
                          <span className="tooltipWrapper">
                            Mortensen Hall at Th...
                          </span>
                        </td>
                        <td>Hartford, CT, US</td>
                        <td>MEZZANINE D</td>
                        <td>Q</td>
                        <td>310, 311</td>
                        <td>05-21-2020</td>
                        <td>2</td>
                        <td>USD</td>
                        <td>69.50</td>
                        <td>0.00</td>
                        <td>
                          <span className="tooltipWrapper">
                            postponed keepdancin...
                          </span>
                        </td>
                        <td>10-03-2019, 02:57 PM</td>
                        <td>752</td>
                        <td>NET0</td>
                        <td>CREDITCARD</td>
                        <td>15098-AMEX</td>
                        <td>32416-AMEX-Ted Winsl...</td>
                      </tr>
                      <tr>
                        <td className="colLeftSticky fW700">10-03-2019</td>
                        <td className="colLeftSticky" style={{ left: "84px" }}>
                          <a target="_blank" href="#">
                            42255848
                          </a>
                        </td>
                        <td>
                          <span className="ant-tag danger">15 days</span>
                          <span className="ant-tag warning">
                            Card Details Missing
                          </span>
                          <span className="ant-tag pink">
                            Pending
                            <br />
                            Fills
                          </span>
                        </td>
                        <td>corporate</td>
                        <td>1Ticket</td>
                        <td>sad</td>
                        <td>
                          <a target="_blank" href="#">
                            3173473
                          </a>
                        </td>
                        <td>Dear Evan Hansen (Re..</td>
                        <td>03-31-2022, 07:30 PM</td>
                        <td>157</td>
                        <td>
                          <span className="tooltipWrapper">
                            Mortensen Hall at Th...
                          </span>
                        </td>
                        <td>Hartford, CT, US</td>
                        <td>MEZZANINE D</td>
                        <td>Q</td>
                        <td>310, 311</td>
                        <td>05-21-2020</td>
                        <td>2</td>
                        <td>USD</td>
                        <td>69.50</td>
                        <td>0.00</td>
                        <td>
                          <span className="tooltipWrapper">
                            postponed keepdancin...
                          </span>
                        </td>
                        <td>10-03-2019, 02:57 PM</td>
                        <td>752</td>
                        <td>NET0</td>
                        <td>CREDITCARD</td>
                        <td>15098-AMEX</td>
                        <td>32416-AMEX-Ted Winsl...</td>
                      </tr>
                      <tr>
                        <td className="colLeftSticky fW700">10-03-2019</td>
                        <td className="colLeftSticky" style={{ left: "84px" }}>
                          <a target="_blank" href="#">
                            42255848
                          </a>
                        </td>
                        <td>
                          <span className="ant-tag danger">15 days</span>
                          <span className="ant-tag secondary">
                            Cost Missing
                          </span>
                          <span className="ant-tag warning">
                            Card Details Missing
                          </span>
                        </td>
                        <td>corporate</td>
                        <td>1Ticket</td>
                        <td>sad</td>
                        <td>
                          <a target="_blank" href="#">
                            3173473
                          </a>
                        </td>
                        <td>Dear Evan Hansen (Re..</td>
                        <td>03-31-2022, 07:30 PM</td>
                        <td>157</td>
                        <td>
                          <span className="tooltipWrapper">
                            Mortensen Hall at Th...
                          </span>
                        </td>
                        <td>Hartford, CT, US</td>
                        <td>MEZZANINE D</td>
                        <td>Q</td>
                        <td>310, 311</td>
                        <td>05-21-2020</td>
                        <td>2</td>
                        <td>USD</td>
                        <td>69.50</td>
                        <td>0.00</td>
                        <td>
                          <span className="tooltipWrapper">
                            postponed keepdancin...
                          </span>
                        </td>
                        <td>10-03-2019, 02:57 PM</td>
                        <td>752</td>
                        <td>NET0</td>
                        <td>CREDITCARD</td>
                        <td>15098-AMEX</td>
                        <td>32416-AMEX-Ted Winsl...</td>
                      </tr>
                      <tr>
                        <td className="colLeftSticky fW700">10-03-2019</td>
                        <td className="colLeftSticky" style={{ left: "84px" }}>
                          <a target="_blank" href="#">
                            42255848
                          </a>
                        </td>
                        <td>
                          <span className="ant-tag danger">15 days</span>
                          <span className="ant-tag warning">
                            Card Details Missing
                          </span>
                          <span className="ant-tag pink">
                            Pending
                            <br />
                            Fills
                          </span>
                        </td>
                        <td>corporate</td>
                        <td>1Ticket</td>
                        <td>sad</td>
                        <td>
                          <a target="_blank" href="#">
                            3173473
                          </a>
                        </td>
                        <td>Dear Evan Hansen (Re..</td>
                        <td>03-31-2022, 07:30 PM</td>
                        <td>157</td>
                        <td>
                          <span className="tooltipWrapper">
                            Mortensen Hall at Th...
                          </span>
                        </td>
                        <td>Hartford, CT, US</td>
                        <td>MEZZANINE D</td>
                        <td>Q</td>
                        <td>310, 311</td>
                        <td>05-21-2020</td>
                        <td>2</td>
                        <td>USD</td>
                        <td>69.50</td>
                        <td>0.00</td>
                        <td>
                          <span className="tooltipWrapper">
                            postponed keepdancin...
                          </span>
                        </td>
                        <td>10-03-2019, 02:57 PM</td>
                        <td>752</td>
                        <td>NET0</td>
                        <td>CREDITCARD</td>
                        <td>15098-AMEX</td>
                        <td>32416-AMEX-Ted Winsl...</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th
                          className="colLeftSticky"
                          scope="col"
                          style={{ left: "0px" }}
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="colLeftSticky"
                          style={{ left: "84px" }}
                        ></th>
                        <th scope="col"></th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">Event Date &amp; Time</th>
                        <th scope="col">Days to Event</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col">$43,447.00</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">$43,447.00</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </Content>
          </Layout>
          <h2 id="schedulerTable">Scheduler Table</h2>
          <Layout className="mainInnerLayout  pb-0">
            <div className="removeTblLRSpc">
              <div
                className="customTbl scroll-y fixedHeaderFooter"
                style={{ maxHeight: "150px" }}
              >
                <table className="main-table">
                  <thead>
                    <tr>
                      <th style={{ width: "42px" }}></th>
                      <th scope="col" className="text-left">
                        REPORT NAME
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" className="text-left">
                        REPORT TITLE
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" className="text-left">
                        FREQUENCY
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" className="text-left">
                        RECIPIENTS
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" className="text-left">
                        NEXT RUN TIME
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" className="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="inactive">
                      <td>
                        <a href="#">
                          <i className="da icon-play" />
                        </a>
                      </td>
                      <td>Long Inventory - Not Listed Report</td>
                      <td>Long Inventory - Report Title</td>
                      <td>Monthly</td>
                      <td>Thomas Gucciardo</td>
                      <td>Tue, Aug 31, 2021 at 3:14 PM</td>
                      <td>
                        <div className="tblActions">
                          <div>
                            <i className="da icon-power-off-danger" />
                          </div>
                          <div>
                            <i className="da icon-pencil-o" />
                          </div>
                          <div>
                            <i className="da icon-trash-o" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#">
                          <i className="da icon-play" />
                        </a>
                      </td>
                      <td>
                        <b>Long Inventory - Not Listed Report</b>
                      </td>
                      <td>Long Inventory - Report Title</td>
                      <td>Monthly</td>
                      <td>Thomas Gucciardo</td>
                      <td>Tue, Aug 31, 2021 at 3:14 PM</td>
                      <td>
                        <div className="tblActions">
                          <div>
                            <i className="da icon-power-off-danger" />
                          </div>
                          <div>
                            <i className="da icon-pencil-o" />
                          </div>
                          <div>
                            <i className="da icon-trash-o" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="inactive">
                      <td>
                        <a href="#">
                          <i className="da icon-play" />
                        </a>
                      </td>
                      <td>Long Inventory - Not Listed Report</td>
                      <td>Long Inventory - Report Title</td>
                      <td>Monthly</td>
                      <td>Thomas Gucciardo</td>
                      <td>Tue, Aug 31, 2021 at 3:14 PM</td>
                      <td>
                        <div className="tblActions">
                          <div>
                            <i className="da icon-power-off-danger" />
                          </div>
                          <div>
                            <i className="da icon-pencil-o" />
                          </div>
                          <div>
                            <i className="da icon-trash-o" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#">
                          <i className="da icon-play" />
                        </a>
                      </td>
                      <td>
                        <b>Long Inventory - Not Listed Report</b>
                      </td>
                      <td>Long Inventory - Report Title</td>
                      <td>Monthly</td>
                      <td>Thomas Gucciardo</td>
                      <td>Tue, Aug 31, 2021 at 3:14 PM</td>
                      <td>
                        <div className="tblActions">
                          <div>
                            <i className="da icon-power-off-danger" />
                          </div>
                          <div>
                            <i className="da icon-pencil-o" />
                          </div>
                          <div>
                            <i className="da icon-trash-o" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Layout>

          <h2 id="grossProfit">GROSS PROFIT SNAPSHOT</h2>
          <Layout className="mainInnerLayout  pb-0">
            <div className="flx justifyContentBetween">
              <h3>Gross Profit Summary</h3>
              <a href="#/">
                <i className="da icon-listview size24"></i>
              </a>
            </div>
            <p>Date: Aug 01-23, 2021</p>
            <div className="ant-row rowSpc-6 mb20">
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className=" text-primary">$110,322</h2>
                    <p>
                      <a href="#/">TICKET SALES</a>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className=" text-success">$29,900</h2>
                    {/* <p><a href="#/">GROSS MARGIN GROSS MARGIN</a></p> */}
                    <p>
                      <div className="noPermissionText">
                        GROSS MARGIN GROSS MARGIN
                      </div>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className=" text-success">23,719</h2>
                    <p>
                      <a href="#/">TICKETS SOLD</a>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className=" text-warning">$523</h2>
                    <p>
                      <a href="#/">NO. OF ORDERS</a>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="text-danger">6</h2>
                    <p>
                      <a href="#/">TICKETS SOLD</a>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="ant-col ant-col-xl-4">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="sold">12</h2>
                    <p>
                      <a href="#/">TICKETS SOLD</a>
                    </p>
                  </div>
                </Card>
              </div>
            </div>
            <div className="removeTblLRSpc">
              <div className="customTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-left">
                        NAME &amp; TAG
                        <i className="da sortIcon  icon-sort-desc"></i>
                      </th>
                      <th scope="col" className="text-left">
                        EMAIL<i className="da sortIcon icon-sort"></i>
                      </th>
                      <th scope="col" className="text-left">
                        ROLE<i className="da sortIcon icon-sort"></i>
                      </th>
                      <th scope="col" className="text-left">
                        BROKER TEAM<i className="da sortIcon icon-sort"></i>
                      </th>
                      <th scope="col" className="text-left">
                        STATUS<i className="da sortIcon icon-sort"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          Doug Russo &nbsp;
                          <span className="ant-tag info">doug</span>
                        </div>
                      </td>
                      <td>doug.russo@ticketbash.com</td>
                      <td>Broker</td>
                      <td>
                        Listers: Beatriz J, Jason G<br />
                        Buyers: Rose PBroker
                      </td>
                      <td>Active</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout" id="schedulerModal">
            <h3>Create New Scheduler</h3>
            <Button
              type="primary"
              className="ant-btn btnSave"
              onClick={() => setShowModal(true)}
            >
              Create New Scheduler Modal
            </Button>
            <Modal
              title="Create New Scheduler"
              visible={showModal}
              footer={false}
              // onOk={() => setShowModal(false)}
              // onCancel={() => setShowModal(false)}
              className="customModal"
              width={820}
              centered
            >
              <div className="ant-row">
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Report Name</label>
                    <Input placeholder="input placeholder" />
                  </div>
                </div>
                <div className="ant-col pr20 ant-col-xl-19">
                  <div className="inputGroup ">
                    <label>Report Title</label>
                    <Input placeholder="input placeholder" />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-5">
                  <div className="inputGroup">
                    <label>Frequency</label>
                    <Select placeholder="Select" allowClear>
                      <Option value="option1">Option1</Option>
                      <Option value="option2">Option2</Option>
                      <Option value="option3">Option3</Option>
                    </Select>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Data</label>
                    <div>
                      Report to be sent on <a href="#">3rd</a> of every month at{" "}
                      <a href="#">9AM</a> IST and ends{" "}
                      <span className="customTooltipWrap">
                        <a href="#">Never</a>
                        <div className="customTooltip">
                          <h6>Ends</h6>
                          <ul className="endsList">
                            <li className="active">
                              <span className="listBox"></span>
                              <b>Never</b>
                            </li>
                            <li>
                              <span className="listBox"></span>
                              <b>On</b> 31, March 2019
                            </li>
                            <li>
                              <span className="listBox"></span>
                              <b>After</b> 10 Occurrences
                            </li>
                          </ul>
                        </div>
                      </span>
                      .
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Filter</label>
                    <div className="filters">
                      <span className="ant-tag info">
                        <b>Date Range:</b> This Month
                      </span>
                      <span className="ant-tag info">
                        <b>Broker:</b> Doug Russo
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Recipients</label>
                    <div className="">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        defaultValue={["Option1", "Option2"]}
                        //onChange={handleChange}
                      >
                        <Option value="option1">Option1</Option>
                        <Option value="option2">Option2</Option>
                        <Option value="option3">Option3</Option>
                      </Select>
                    </div>
                  </div>

                  <div className="inputGroup">
                    <textarea
                      className="txtArea"
                      placeholder="Add a custom message here (optional)"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modalFooter">
                <button
                  type="button"
                  className="linkBtn"
                  onClick={() => setShowModal(false)}
                >
                  CANCEL
                </button>
                <button type="button" className="ant-btn btnSave">
                  SAVE
                </button>
              </div>
            </Modal>
          </Layout>

          <h2 id="userManagement">USER MANAGEMENT</h2>
          <Layout className="mainInnerLayout">
            <div className="innerHeader">
              <div className="ant-row">
                <div className="ant-col ant-col-xl-6">
                  <h3>All Users</h3>
                </div>
                <div className="ant-col ant-col-xl-10">
                  <div className="switchToggle flx alignItemsCenter">
                    <span>Show Inactive Users</span>
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      defaultChecked
                    />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="innerHeaderRight">
                    <button className="newUserBtn" type="button">
                      <i className="da icon-add-user-o"></i>New User
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="customTbl userManagementTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-left">
                        NAME &amp; TAG
                        <i className="da sortIcon  icon-sort" />
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "260px" }}
                      >
                        EMAIL
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "220px" }}
                      >
                        ROLE
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "260px" }}
                      >
                        BROKER TEAM
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        STATUS
                        <i className="da sortIcon icon-sort" />
                      </th>
                      <th scope="col" style={{ width: "130px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          Doug Russo &nbsp;
                          <span className="ant-tag info">doug</span>
                        </div>
                      </td>
                      <td>doug.russo@ticketbash.com</td>
                      <td>Broker</td>
                      <td>
                        Listers: Beatriz J, Jason G<br />
                        Buyers: Rose PBroker
                      </td>
                      <td>Active</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-event-o" />
                          </a>
                          <a href="#" onClick={showEditUserInfoModal}>
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-trash-o" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr className="selected">
                      <td>
                        <div>
                          Doug Russo <a href="#">Resend Invite</a>
                        </div>
                      </td>
                      <td>doug.russo@ticketbash.com</td>
                      <td>Broker</td>
                      <td>
                        Listers: Beatriz J, Jason G<br />
                        Buyers: Rose PBroker
                      </td>
                      <td>Active</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-event-o" />
                          </a>
                          <a
                            href="#"
                            className="active"
                            onClick={showEditUserInfoModal}
                          >
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-trash-o" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr className="disabled">
                      <td>
                        <div>
                          Doug Russo <a href="#">Resend Invite</a>
                        </div>
                      </td>
                      <td>doug.russo@ticketbash.com</td>
                      <td>Broker</td>
                      <td>
                        Listers: Beatriz J, Jason G<br />
                        Buyers: Rose PBroker
                      </td>
                      <td>Active</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-event-o" />
                          </a>
                          <a href="#" onClick={showEditUserInfoModal}>
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-trash-o" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          Doug Russo &nbsp;
                          <span className="ant-tag info">doug</span>
                        </div>
                      </td>
                      <td>doug.russo@ticketbash.com</td>
                      <td>Broker</td>
                      <td>
                        Listers: Beatriz J, Jason G<br />
                        Buyers: Rose PBroker
                      </td>
                      <td>Active</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-event-o" />
                          </a>
                          <a href="#" onClick={showEditUserInfoModal}>
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-trash-o" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <Modal
              closable={false}
              width={870}
              title={false}
              footer={false}
              visible={isModalVisible4}
              centered
              className="editModal"
            >
              <div className="modalHeader">Edit User Info &amp; Settings</div>
              <div className="modalCustomBody">
                <div className="editUserInfoForm">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>First Name</label>
                        <input
                          placeholder="First Name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Last Name</label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Email</label>
                        <input
                          placeholder="Email"
                          type="email"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Tag</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Roles</label>
                        <ul className="checkboxList">
                          <li>
                            <Checkbox>Admin</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Management</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Developer</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Broker</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Buyer</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Lister</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Shipper</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Accounting</Checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Commission %</label>
                        <Input
                          placeholder="Commission %"
                          suffix="%"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Default Listing Quantity</label>

                        <input
                          placeholder="Default Listing Quantity"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Default Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-11">
                      <div className="inputGroup">
                        <label>Default Markup</label>
                        <Input
                          addonBefore="$"
                          placeholder="Default Markup"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <div className="ant-row">
                  <div className="ant-col ant-col-xl-22">
                    <Button className="linkBtn" onClick={editUserInfoCancel}>
                      CLOSE
                    </Button>
                    <button type="button" className="ant-btn btnSave">
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </Layout>

          <h2>NEW USER</h2>
          <Layout className="mainInnerLayout manageProfInner">
            <h3>Add New User</h3>
            <div className="ant-row ">
              <div className="ant-col ant-col-xl-24">
                <div className="createUserForm">
                  <h5>General Info</h5>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Skybox User ID</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>First Name</label>
                        <input
                          placeholder="Your first name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Last Name</label>
                        <input
                          placeholder="Your last name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Email</label>
                        <input
                          placeholder="Your email address"
                          type="email"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Roles</label>
                        <ul className="checkboxList">
                          <li>
                            <Checkbox>Admin</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Management</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Developer</Checkbox>
                          </li>
                          <li>
                            <Checkbox checked>Broker</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Buyer</Checkbox>
                          </li>
                          <li>
                            <Checkbox checked>Lister</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Shipper</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Accounting</Checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Tag</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Lister</Option>
                          <Option value="option2">Broker</Option>
                          <Option value="option3">Buyer</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Commission %</label>
                        <Input
                          placeholder="Commission %"
                          suffix="%"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Listing Quantity</label>
                        <input
                          placeholder="Default Listing Quantity"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Markup</label>
                        <Input
                          addonBefore="$"
                          placeholder="Default Markup"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Assign to Broker</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editUserInfoFooter">
                  <button type="button" className="linkBtn">
                    CANCEL
                  </button>
                  <button type="button" className="ant-btn btnSave">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Layout>

          <h2>VENUES</h2>
          <Layout className="mainInnerLayout">
            <div className="ant-row">
              <div className="ant-col ant-col-xl-12">
                <h3>Broker: Doug Russo</h3>
                <div className="customTbl tblContWordBreak">
                  <table className="main-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-left">
                          VENUE NAME
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "250px" }}
                        >
                          LOCATION
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col" style={{ width: "100px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="selected">
                        <td>
                          <strong>McCallum Theatre General Admission</strong>
                        </td>
                        <td>
                          Palace Theater - Waterbury Sarasota, Florida, United
                          States
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-link" />
                            </a>
                            <a href="#" className="active">
                              <i className="da icon-time" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>TPAC Liddy Doenges Theatre</strong>
                        </td>
                        <td>
                          Alabama Theatre Sarasota, Florida, United States
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-link" />
                            </a>
                            <a href="#">
                              <i className="da icon-time" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>TPAC Chapman Music Hall</strong>
                        </td>
                        <td>
                          Van Wezel Performing Arts Hall Sarasota, Florida,
                          United States
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-link" />
                            </a>
                            <a href="#">
                              <i className="da icon-time" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Hillsboro Baseball Stadium</strong>
                        </td>
                        <td>
                          INTRUST Bank Arena Sarasota, Florida, United States
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-link" />
                            </a>
                            <a href="#">
                              <i className="da icon-time" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="ant-col ant-col-xl-6">
                <h3>Audit History</h3>
                <div className="headingStyle3">
                  Venue: McCallum Theatre General Admission
                </div>
                <div className="customTbl noHeaderTbl">
                  <table className="main-table">
                    <tr>
                      <td>Aug 31, 2021: Assigned to Rose P by Thomas G</td>
                    </tr>
                    <tr>
                      <td>Aug 31, 2021: Assigned to Rose P by Thomas G</td>
                    </tr>
                    <tr>
                      <td>Aug 31, 2021: Assigned to Rose P by Thomas G</td>
                    </tr>
                    <tr>
                      <td>Aug 31, 2021: Assigned to Rose P by Thomas G</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </Layout>

          <h2>MANAGE PROFILE</h2>
          <Layout className="mainInnerLayout manageProfInner">
            <h3>Your User Profile</h3>
            <div className="ant-row ">
              <div className="ant-col ant-col-xl-24">
                <div className="createUserForm">
                  <h5>Your Profile Picture</h5>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                      <div className="profilePicWrap">
                        <div className="profileImg">
                          <i
                            className="da icon-profile"
                            style={{ display: "block" }}
                          ></i>
                          <img src="" alt="" style={{ display: "none" }} />
                        </div>

                        <div className="inputGroup">
                          <label>Upload Photo</label>
                          <div>
                            <Button
                              className="ant-btn upload"
                              icon={<i className="da icon-upload"></i>}
                            >
                              Click to upload
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>First Name</label>
                        <input
                          placeholder="First Name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Last Name</label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Email</label>
                        <input
                          placeholder="Email"
                          readOnly={true}
                          type="email"
                          className="ant-input readOnly"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Tag</label>
                        <div>
                          <span className="ant-tag info">username</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editUserInfoFooter">
                  <button type="button" className="linkBtn">
                    CANCEL
                  </button>
                  <button type="button" className="ant-btn btnSave">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Layout>
          <Layout className="mainInnerLayout manageProfInner">
            <h3>My Settings</h3>
            <div className="ant-row ">
              <div className="ant-col ant-col-xl-24">
                <div className="createUserForm">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Listing Quantity</label>
                        <input
                          placeholder="Default Listing Quantity"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Default Markup</label>
                        <Input
                          addonBefore="$"
                          placeholder="Default Markup"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Internal Notes</label>
                        <textarea style={{ minHeight: "136px" }}></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <p>
                          <b>Lister Team:</b> Beatriz J, Jason Gatsby
                        </p>
                        <p>
                          <b>Buyer Team:</b> Rose Parisi, David D
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editUserInfoFooter">
                  <button type="button" className="linkBtn">
                    CANCEL
                  </button>
                  <button type="button" className="ant-btn btnSave">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Layout>
          <Layout className="mainInnerLayout">
            <h3>Password</h3>
            <div className="ant-row ">
              <div className="ant-col ant-col-xl-24">
                <div className="createUserForm">
                  <h5>Change Password</h5>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Current Password</label>
                        <Input.Password placeholder="Your current password" />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>New Password</label>
                        <Input.Password placeholder="Your new password" />
                      </div>
                    </div>
                  </div>
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-7">
                      <div className="inputGroup">
                        <label>Confirm New Password</label>
                        <Input.Password placeholder="Re-confirm new passoword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editUserInfoFooter">
                  <button type="button" className="linkBtn">
                    CANCEL
                  </button>
                  <button type="button" className="ant-btn btnSave">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Layout>

          <h2 id="zones">ZONE LISTINGS</h2>
          <Layout className="mainInnerLayout">
            <div className="collapsible active">
              <div className="collapsibleHeader">
                <div>
                  <h3>High Risk Listings</h3>
                  <i className="da icon-arrow-down-circle"></i>
                </div>
                <button type="button">
                  <i className="da icon-listview"></i>
                </button>
              </div>
              <div className="collapsibleBody">
                <div className="eventCardWrap">
                  <div className="seatAvailableLogicTopBar"></div>
                  <div className="eventCardContent">
                    <div className="lastUpdated">
                      <span>Last updated 21 days ago by Beatriz J</span>
                    </div>
                    <div className="eventCardBody">
                      <div className="eventCardBodyInnerGrid">
                        <div>
                          <p>
                            <a href="#">RESCHEDULED - Mary Chapin Carpenter</a>
                            <br />
                            Sat, Aug 28, 2021 at 7:30 PM
                          </p>
                          <p>
                            Van Wezel Performing Arts Hall <br />
                            New York, NY, USA
                          </p>
                        </div>
                        <div className="actions">
                          <button>
                            <i className="da icon-th"></i>
                          </button>
                          <button>
                            <i className="da icon-pencil-o"></i>
                          </button>
                          <button>
                            <i className="da icon-comment"></i>
                          </button>
                        </div>
                      </div>
                      <div className="ranking">
                        <div className="rankingInner negative">
                          <span className="rankNumber">3</span>
                          <div>
                            Days <br />
                            to event
                          </div>
                        </div>
                        <div className="rankingInner default">
                          <span className="rankNumber">4</span>
                          <a href="#">
                            Active
                            <br />
                            Listings
                          </a>
                        </div>
                        <div className="rankingInner positive">
                          <span className="rankNumber">2</span>
                          <a href="#">
                            Pending
                            <br />
                            Fills
                          </a>
                        </div>
                        <div className="rankingInner negative">
                          <span className="rankNumber">1</span>
                          <div>
                            Pricing
                            <br />
                            Issue
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Rose Parisi</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                </div>
                <div className="eventCardWrap">
                  <div className="seatAvailableLogicTopBar"></div>
                  <div className="eventCardContent">
                    <div className="lastUpdated">
                      <span>Last updated 21 days ago by Beatriz J</span>
                    </div>
                    <div className="eventCardBody">
                      <div className="eventCardBodyInnerGrid">
                        <div>
                          <p>
                            <a href="#">RESCHEDULED - Mary Chapin Carpenter</a>
                            <br />
                            Sat, Aug 28, 2021 at 7:30 PM
                          </p>
                          <p>
                            Van Wezel Performing Arts Hall <br />
                            New York, NY, USA
                          </p>
                        </div>
                        <div className="actions">
                          <button>
                            <i className="da icon-th"></i>
                          </button>
                          <button>
                            <i className="da icon-pencil-o"></i>
                          </button>
                          <button>
                            <i className="da icon-comment"></i>
                          </button>
                        </div>
                      </div>
                      <div className="ranking">
                        <div className="rankingInner default">
                          <span className="rankNumber">80</span>
                          <div>
                            Days <br />
                            to event
                          </div>
                        </div>
                        <div className="rankingInner default">
                          <span className="rankNumber">7</span>
                          <a href="#">
                            Active
                            <br />
                            Listings
                          </a>
                        </div>
                        <div className="rankingInner positive">
                          <span className="rankNumber">2</span>
                          <a href="#">
                            Pending
                            <br />
                            Fills
                          </a>
                        </div>
                        <div className="rankingInner negative">
                          <span className="rankNumber">1</span>
                          <div>
                            Pricing
                            <br />
                            Issue
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Rose Parisi</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                </div>
                <div className="eventCardWrap danger">
                  <div className="seatAvailableLogicTopBar"></div>
                  <div className="eventCardContent">
                    <div className="lastUpdated">
                      <span>
                        Last updated 21 days ago by
                        <i className="da icon-fictionalCharacter-red"></i>
                      </span>
                    </div>
                    <div className="eventCardBody">
                      <div className="eventCardBodyInnerGrid">
                        <div>
                          <p>
                            <a href="#">
                              An Intimate Evening with David Foster: Hitman Tour
                            </a>
                            <br />
                            Fri, Oct 08, 2021 at 8:00 PM
                          </p>
                          <p>
                            Van Wezel Performing Arts Hall <br />
                            New York, NY, USA
                          </p>
                        </div>
                        <div className="actions">
                          <button>
                            <i className="da icon-th"></i>
                          </button>
                          <button>
                            <i className="da icon-pencil-o"></i>
                          </button>
                          <button>
                            <i className="da icon-comment"></i>
                          </button>
                        </div>
                      </div>
                      <div className="ranking">
                        <div className="rankingInner default">
                          <span className="rankNumber">44</span>
                          <a href="#">
                            Days <br />
                            to event
                          </a>
                        </div>
                        <div className="rankingInner default">
                          <span className="rankNumber">4</span>
                          <a href="#">
                            Active
                            <br />
                            Listings
                          </a>
                        </div>
                        <div className="rankingInner positive">
                          <span className="rankNumber">2</span>
                          <a href="#">
                            Pending
                            <br />
                            Fills
                          </a>
                        </div>
                        <div className="rankingInner negative">
                          <span className="rankNumber">1</span>
                          <a href="#">
                            Pricing
                            <br />
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Rose Parisi</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                </div>

                <div className="eventCardWrap success">
                  <div className="seatAvailableLogicTopBar"></div>
                  <div className="eventCardContent">
                    <div className="lastUpdated">
                      <span>
                        Last updated 21 days ago by
                        <i className="da icon-fictionalCharacter-green"></i>
                      </span>
                    </div>
                    <div className="eventCardBody">
                      <div className="eventCardBodyInnerGrid">
                        <div>
                          <p>
                            <a href="#">
                              An Intimate Evening with David Foster: Hitman Tour
                            </a>
                            <br />
                            Fri, Oct 08, 2021 at 8:00 PM
                          </p>
                          <p>
                            Van Wezel Performing Arts Hall <br />
                            New York, NY, USA
                          </p>
                        </div>
                        <div className="actions">
                          <button>
                            <i className="da icon-th"></i>
                          </button>
                          <button>
                            <i className="da icon-pencil-o"></i>
                          </button>
                          <button>
                            <i className="da icon-comment"></i>
                          </button>
                        </div>
                      </div>
                      <div className="ranking">
                        <div className="rankingInner default">
                          <span className="rankNumber">44</span>
                          <a href="#">
                            Days <br />
                            to event
                          </a>
                        </div>
                        <div className="rankingInner default">
                          <span className="rankNumber">4</span>
                          <a href="#">
                            Active
                            <br />
                            Listings
                          </a>
                        </div>
                        <div className="rankingInner positive">
                          <span className="rankNumber">2</span>
                          <a href="#">
                            Pending
                            <br />
                            Fills
                          </a>
                        </div>
                        <div className="rankingInner negative">
                          <span className="rankNumber">1</span>
                          <a href="#">
                            Pricing
                            <br />
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Rose Parisi</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                </div>

                <div className="eventCardWrap success">
                  <div className="seatAvailableLogicTopBar"></div>
                  <div className="eventCardContent">
                    <div className="lastUpdated">
                      <span>
                        Last updated 21 days ago by{" "}
                        <i className="da icon-fictionalCharacter-green"></i>
                      </span>
                    </div>
                    <div className="eventCardBody">
                      <div className="eventCardBodyInnerGrid">
                        <div>
                          <p>
                            <a href="#">
                              An Intimate Evening with David Foster: Hitman Tour
                            </a>
                            <br />
                            Fri, Oct 08, 2021 at 8:00 PM
                          </p>
                          <p>
                            Van Wezel Performing Arts Hall <br />
                            New York, NY, USA
                          </p>
                        </div>
                        <div className="actions">
                          <button>
                            <i className="da icon-th"></i>
                          </button>
                          <button>
                            <i className="da icon-pencil-o"></i>
                          </button>
                          <button>
                            <i className="da icon-comment"></i>
                          </button>
                        </div>
                      </div>
                      <div className="ranking">
                        <div className="rankingInner default">
                          <span className="rankNumber">44</span>
                          <a href="#">
                            Days <br />
                            to event
                          </a>
                        </div>
                        <div className="rankingInner default">
                          <span className="rankNumber">4</span>
                          <a href="#">
                            Active
                            <br />
                            Listings
                          </a>
                        </div>
                        <div className="rankingInner positive">
                          <span className="rankNumber">2</span>
                          <a href="#">
                            Pending
                            <br />
                            Fills
                          </a>
                        </div>
                        <div className="rankingInner negative">
                          <span className="rankNumber">1</span>
                          <a href="#">
                            Pricing
                            <br />
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Rose Parisi</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout">
            <Content className="mainContainer zones">
              <div className="innerHeader">
                <Row>
                  <Col span={4}>
                    <h3 id="inventoryReportTables">All Zone Listings</h3>
                  </Col>
                  <Col span={2}>
                    <Switch
                      checkedChildren={<i className="da icon-th-list"></i>}
                      unCheckedChildren={<i className="da icon-th-large"></i>}
                    />
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input
                        type="search"
                        placeholder="Search event name, venue name, broker, po#"
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>548 Records Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                      <li>
                        <div className="sortingDd active">
                          <button type="button">
                            <i className="da icon-sorting"></i>
                          </button>
                          <div className="dropdown">
                            <div className="sortingDdHeading">
                              <span>SORT OPTIONS</span>
                              <div>
                                <button type="button">
                                  <i className="da icon-sorting-lite"></i>
                                </button>
                                <button type="button">
                                  <i className="da icon-arrow-right-lite"></i>
                                </button>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#" className="active">
                                  Days to Event
                                </a>
                              </li>
                              <li>
                                <a href="#" className="">
                                  Active Listings
                                </a>
                              </li>
                              <li>
                                <a href="#" className="">
                                  Event
                                </a>
                              </li>
                              <li>
                                <a href="#" className="">
                                  Venue
                                </a>
                              </li>
                              <li>
                                <a href="#" className="">
                                  Broker
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <Dropdown overlay={sortOptionClick} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <i className="da icon-sorting"></i>
                        </a>
                      </Dropdown> */}
                      </li>
                      <li>
                        <button>
                          <i className="da icon-listview"></i>
                        </button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="zonesGridView ">
                <div className="listRow">
                  <div className="eventCardWrap warning">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <div>
                              Pricing
                              <br />
                              Issue
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>
                          Last updated 21 days ago by{" "}
                          <i className="da icon-fictionalCharacter-red"></i>
                        </span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                An Intimate Evening with David Foster: Hitman
                                Tour
                              </a>
                              <br />
                              Fri, Oct 08, 2021 at 8:00 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap warning">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner efault">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <div>
                              Pricing
                              <br />
                              Issue
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap warning">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">3</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>
                          Last updated 21 days ago by{" "}
                          <i className="da icon-fictionalCharacter-green"></i>
                        </span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                An Intimate Evening with David Foster: Hitman
                                Tour
                              </a>
                              <br />
                              Fri, Oct 08, 2021 at 8:00 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">44</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <a href="#">
                              Pricing
                              <br />
                              Issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap danger">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner warningRank">
                            <span className="rankNumber">44</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap success">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">17</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">7</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <div>
                              Pricing
                              <br />
                              Issue
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap warning">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">3</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>
                          Last updated 21 days ago by{" "}
                          <i className="da icon-fictionalCharacter-green"></i>
                        </span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                An Intimate Evening with David Foster: Hitman
                                Tour
                              </a>
                              <br />
                              Fri, Oct 08, 2021 at 8:00 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">100</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">26</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <a href="#">
                              Pricing
                              <br />
                              Issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap success">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>
                          Last updated 21 days ago by{" "}
                          <i className="da icon-fictionalCharacter-green"></i>
                        </span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">77</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">9</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap success">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">32</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">5</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">2</span>
                            <a href="#">
                              Pending
                              <br />
                              Fills
                            </a>
                          </div>
                          <div className="rankingInner negative">
                            <span className="rankNumber">1</span>
                            <div>
                              Pricing
                              <br />
                              Issue
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>

                  <div className="eventCardWrap success">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">17</span>
                            <div>
                              Days <br />
                              to event
                            </div>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">7</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>

          <h2 id="">ZONE LISTINGS</h2>
          <Layout className="mainInnerLayout zones">
            <div className="innerHeader">
              <Row>
                <Col span={4}>
                  <h3 id="inventoryReportTables">All Zone Listings</h3>
                </Col>
                <Col span={2}>
                  <Switch
                    checkedChildren={<i className="da icon-th-list"></i>}
                    unCheckedChildren={<i className="da icon-th-large"></i>}
                  />
                </Col>
                <Col span={6}>
                  <div className="search">
                    <input
                      type="search"
                      placeholder="Search event name, venue name, broker, po#"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>312 Records Found</h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        defaultCurrent={1}
                        total={50}
                      />
                    </li>
                    <li>
                      <button type="button">
                        <i className="da icon-sorting"></i>
                      </button>
                    </li>
                    <li>
                      <button>
                        <i className="da icon-listview"></i>
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="removeTblLRSpc">
              <div className="customTbl zoneListViewTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th style={{ width: "8px", padding: "0" }}></th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "130px" }}
                      >
                        <div>
                          LAST UPDATED
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "" }}
                      >
                        <div>
                          EVENT NAME &amp; VENUE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "87px" }}
                      >
                        <div>
                          DAYS TO EVENT
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "120px" }}
                      >
                        <div>
                          DATE &amp; TIME
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "130px" }}
                      >
                        <div>
                          BROKER
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "130px" }}
                      >
                        <div>
                          LISTER
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          ACTIVE
                          <br />
                          LISTINGS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          PENDING
                          <br />
                          FILLS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "110px" }}
                      >
                        <div>
                          PRICING
                          <br />
                          ISSUE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "130px" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="warningBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        21 days ago by Beatriz J
                      </td>
                      <td>
                        <a href="#">
                          <strong>Come From Away</strong>
                        </a>
                        <br />
                        Van Wezel Performing Arts Hall: New York, NY, USA
                      </td>
                      <td className="daysToEvent">
                        <div>94</div>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM
                      </td>
                      <td>Thomas Gucciardo</td>
                      <td>Beatriz J</td>
                      <td className="daysToEvent">
                        <a href="#" className="underline">
                          2
                        </a>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="defaultBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        10 days ago by{" "}
                        <i className="da icon-fictionalCharacter-green"></i>
                      </td>
                      <td>
                        <a href="#">
                          <strong>
                            Dinner catered by Mattison's - Crosby, Colvin &amp;
                            Cohn{" "}
                          </strong>
                        </a>
                        <br />
                        Van Wezel Performing Arts Center: New York, NY, USA
                      </td>
                      <td className="dangerBg daysToEvent">
                        <div>3</div>
                      </td>
                      <td>
                        Sat, Aug 28, 2021
                        <br />
                        07:30 PM
                      </td>
                      <td>Rose Parisi</td>
                      <td>Beatriz J</td>
                      <td></td>
                      <td className="successBg daysToEvent">
                        <a href="#">2</a>
                      </td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="warningBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        21 days ago by Beatriz J
                      </td>
                      <td>
                        <a href="#">
                          <strong>Come From Away</strong>
                        </a>
                        <br />
                        Van Wezel Performing Arts Hall: New York, NY, USA
                      </td>
                      <td className="daysToEvent">
                        <div>94</div>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM
                      </td>
                      <td>Thomas Gucciardo</td>
                      <td>Beatriz J</td>
                      <td className="daysToEvent">
                        <a href="#" className="underline">
                          7
                        </a>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="dangerBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        10 days ago by{" "}
                        <i className="da icon-fictionalCharacter-red"></i>
                      </td>
                      <td>
                        <a href="#">
                          <strong>RESCHEDULED - Mary Chapin Carpenter</strong>
                        </a>
                        <br />
                        Wolf Trap Filene Center: New York, NY, USA
                      </td>
                      <td className="warningBg daysToEvent">
                        <div>17</div>
                      </td>
                      <td>
                        Sat, Aug 28, 2021
                        <br />
                        07:30 PM
                      </td>
                      <td></td>
                      <td></td>
                      <td className="daysToEvent"></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="defaultBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        21 days ago by Beatriz J
                      </td>
                      <td>
                        <a href="#">
                          <strong>Come From Away</strong>
                        </a>
                        <br />
                        Van Wezel Performing Arts Hall: New York, NY, USA
                      </td>
                      <td className="daysToEvent">
                        <div>94</div>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM
                      </td>
                      <td>Rose Parisi</td>
                      <td>Beatriz J</td>
                      <td className="daysToEvent">
                        <a href="#" className="underline">
                          2
                        </a>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="successBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        10 days ago by Beatriz J
                      </td>
                      <td>
                        <a href="#">
                          <strong>TOBYMAC HITS DEEP TOUR</strong>
                        </a>
                        <br />
                        INTRUST Bank Arena: New York, NY, USA
                      </td>
                      <td className="daysToEvent">
                        <div>111</div>
                      </td>
                      <td>
                        Sat, Oct 09, 2021
                        <br />
                        06:00 PM
                      </td>
                      <td>Rose Parisi</td>
                      <td></td>
                      <td className="daysToEvent">
                        <a href="#" className="underline">
                          6
                        </a>
                      </td>
                      <td></td>
                      <td className="dangerBg daysToEvent">
                        <div>4</div>
                      </td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="defaultBg" style={{ padding: "0" }}></td>
                      <td className="latestUpdateCol">
                        7 days ago by Beatriz J
                      </td>
                      <td>
                        <a href="#">
                          <strong>Bee Gees Gold</strong>
                        </a>
                        <br />
                        Alabama Theatre: New York, NY, USA
                      </td>
                      <td className="daysToEvent">
                        <div>65</div>
                      </td>
                      <td>
                        Sat, Aug 28, 2021
                        <br />
                        07:30 PM
                      </td>
                      <td>Rose Parisi</td>
                      <td>Beatriz J</td>
                      <td className="daysToEvent"></td>
                      <td></td>
                      <td className="daysToEvent">
                        <div></div>
                      </td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-th" />
                          </a>
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Layout>
          <h2 id="">ACTIVE LISTINGS</h2>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer zones">
              <div className="innerHeader">
                <button type="button">
                  <i className="da icon-back-top"></i>
                </button>
              </div>
              <div className="zonesGridView activeListingWrap">
                <div className="listRow">
                  <div className="activeListingLeft">
                    <div className="eventCardWrap active">
                      <div className="seatAvailableLogicTopBar"></div>
                      <div className="eventCardContent">
                        <div className="eventCardBody">
                          <div className="eventCardBodyInnerGrid">
                            <div>
                              <p>
                                <a href="#">
                                  RESCHEDULED - Mary Chapin Carpenter
                                </a>
                                <br />
                                Sat, Aug 28, 2021 at 7:30 PM
                              </p>
                              <p>
                                Van Wezel Performing Arts Hall <br />
                                New York, NY, USA
                              </p>
                            </div>
                            <div className="actions">
                              <button>
                                <i className="da icon-th"></i>
                              </button>
                              <button>
                                <i
                                  className="da icon-pencil-o"
                                  onClick={showEditListingModal}
                                ></i>
                              </button>
                              <button>
                                <i className="da icon-comment"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ranking">
                            <div className="rankingInner negative">
                              <span className="rankNumber">3</span>
                              <div>
                                Days <br />
                                to event
                              </div>
                            </div>
                            <div className="rankingInner default">
                              <span className="rankNumber">4</span>
                              <a href="#">
                                Active
                                <br />
                                Listings
                              </a>
                            </div>
                            <div className="rankingInner positive">
                              <span className="rankNumber">2</span>
                              <a href="#">
                                Pending
                                <br />
                                Fills
                              </a>
                            </div>
                            <div className="rankingInner negative">
                              <span className="rankNumber">1</span>
                              <div>
                                Pricing
                                <br />
                                Issue
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i>
                            <span>Rose Parisi</span>
                          </div>
                          <div>
                            <i className="da icon-user-role"></i>
                            <span>Beatriz J</span>
                          </div>
                        </div>
                        <div className="eventCardFooter2 fontRegular">
                          <div>
                            Last scraped on:
                            <br />
                            <span className="fontRegular">
                              Tue, Oct 20, 2020
                            </span>
                          </div>
                          <div className="text-right">
                            Spider Health:{" "}
                            <span className="fontBold text-success">GOOD</span>
                            <br />
                            Status: <span>Onsale</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardWrap noTopbar moreListing">
                      <a className="readMore" href="#">
                        ( 7 ) more listings
                      </a>
                      <a className="readMore" href="#">
                        ( 7 ) more listings
                      </a>
                      <a className="readMore" href="#">
                        ( 7 ) more listings
                      </a>
                      <a className="readMore" href="#">
                        ( 7 ) more listings
                      </a>
                      <a className="readMore" href="#">
                        ( 7 ) more listings
                      </a>
                    </div>
                  </div>
                  <div className="activeListingRight">
                    <div className="listRow">
                      <div className="eventCardWrap activeListing noTopbar">
                        <div className="eventCardContent">
                          <div className="eventCardHeader">
                            <a href="#" className="poNumber">
                              PO# 45721456
                            </a>
                            <div className="actions">
                              <button>
                                <i className="da icon-comment"></i>
                              </button>
                              <button>
                                <i className="da icon-network-red"></i>
                              </button>
                              <button onClick={showEditListingModal}>
                                <i className="da icon-pencil-o"></i>
                              </button>
                              <button>
                                <i className="da icon-trash-o"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ribbon error">
                            <i className="da icon-chart-up-white-2"></i>
                            <span>No seats left</span>
                          </div>
                          <div className="zoneSeatingWrap">
                            <div>
                              <h2>$65.00</h2>
                              <p>EXP COST: $140</p>
                              <p className="text-danger">
                                List Price less than Cost
                              </p>
                            </div>
                            <div className="zoneSeating">
                              <span>
                                Zone
                                <br />
                                Seating
                              </span>
                              <i className="da icon-checked-circle"></i>
                            </div>
                          </div>
                          <div className="eventCardBody">
                            <div className="ranking">
                              <div className="rankingInner yellow">
                                <span className="rankNumber">3</span>
                                <div>Qty</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">Left Rear</span>
                                <div>Section</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99</span>
                                <div>Row(s)</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99 - 106</span>
                                <div>Seats</div>
                              </div>
                            </div>
                          </div>
                          <div className="eventCardFooter">
                            <div>
                              <i className="da icon-user-o"></i>
                              <span>Thomas Gucciardo</span>
                            </div>
                            <div>
                              <i className="da icon-user-role"></i>
                              <span>Beatriz J</span>
                            </div>
                          </div>
                          <div className="eventCardFooter2 fontRegular">
                            <div>
                              Created: Sep 10, 2020 12:25 PM
                              <br />
                              <span>kim@ticketbash.com</span>
                            </div>
                            <div>
                              <span className="fontBold">TB2.0</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="eventCardWrap activeListing noTopbar">
                        <div className="eventCardContent">
                          <div className="eventCardHeader">
                            <a href="#" className="poNumber">
                              PO# 45721456
                            </a>
                            <div className="actions">
                              <button>
                                <i className="da icon-comment"></i>
                              </button>
                              <button>
                                <i className="da icon-network"></i>
                              </button>
                              <button onClick={showEditListingModal}>
                                <i className="da icon-pencil-o"></i>
                              </button>
                              <button>
                                <i className="da icon-trash-o"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ribbon success">
                            <i className="da icon-chart-up-green"></i>
                            <span>25/32 seats left</span>
                          </div>
                          <div className="zoneSeatingWrap">
                            <div>
                              <h2>$65.00</h2>
                              <p>EXP COST: $140</p>
                              <p className="text-danger">
                                List Price less than Cost
                              </p>
                            </div>
                            <div className="zoneSeating">
                              <span>
                                Zone
                                <br />
                                Seating
                              </span>
                              <i className="da icon-checked-circle"></i>
                            </div>
                          </div>
                          <div className="eventCardBody">
                            <div className="ranking">
                              <div className="rankingInner yellow">
                                <span className="rankNumber">3</span>
                                <div>Qty</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">Left Rear</span>
                                <div>Section</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99</span>
                                <div>Row(s)</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99 - 106</span>
                                <div>Seats</div>
                              </div>
                            </div>
                          </div>
                          <div className="eventCardFooter">
                            <div>
                              <i className="da icon-user-o"></i>
                              <span>Thomas Gucciardo</span>
                            </div>
                            <div>
                              <i className="da icon-user-role"></i>
                              <span>Beatriz J</span>
                            </div>
                          </div>
                          <div className="eventCardFooter2 fontRegular">
                            <div>
                              Created: Sep 10, 2020 12:25 PM
                              <br />
                              <span>kim@ticketbash.com</span>
                            </div>
                            <div>
                              <span className="fontBold">TB2.0</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="eventCardWrap activeListing noTopbar">
                        <div className="eventCardContent">
                          <div className="eventCardHeader">
                            <a href="#" className="poNumber">
                              PO# 45721456
                            </a>
                            <div className="actions">
                              <button>
                                <i className="da icon-comment"></i>
                              </button>
                              <button>
                                <i className="da icon-network"></i>
                              </button>
                              <button>
                                <i className="da icon-pencil-o"></i>
                              </button>
                              <button>
                                <i className="da icon-trash-o"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ribbon danger">
                            <i className="da icon-chart-up-red"></i>
                            <span>Few seats left</span>
                          </div>
                          <div className="zoneSeatingWrap">
                            <div>
                              <h2>$65.00</h2>
                              <p>EXP COST: $140</p>
                              <p className="text-danger">
                                List Price less than Cost
                              </p>
                            </div>
                            <div className="zoneSeating">
                              <span>
                                Zone
                                <br />
                                Seating
                              </span>
                              <i className="da icon-checked-circle"></i>
                            </div>
                          </div>
                          <div className="eventCardBody">
                            <div className="ranking">
                              <div className="rankingInner yellow">
                                <span className="rankNumber">3</span>
                                <div>Qty</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">Left Rear</span>
                                <div>Section</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99</span>
                                <div>Row(s)</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99 - 106</span>
                                <div>Seats</div>
                              </div>
                            </div>
                          </div>
                          <div className="eventCardFooter">
                            <div>
                              <i className="da icon-user-o"></i>
                              <span>Thomas Gucciardo</span>
                            </div>
                            <div>
                              <i className="da icon-user-role"></i>
                              <span>Beatriz J</span>
                            </div>
                          </div>
                          <div className="eventCardFooter2 fontRegular">
                            <div>
                              Created: Sep 10, 2020 12:25 PM
                              <br />
                              <span>kim@ticketbash.com</span>
                            </div>
                            <div>
                              <span className="fontBold">TB2.0</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="eventCardWrap activeListing noTopbar">
                        <div className="eventCardContent">
                          <div className="eventCardHeader">
                            <a href="#" className="poNumber">
                              PO# 45721456
                            </a>
                            <div className="actions">
                              <button>
                                <span className="commentNotification">1</span>
                                <i className="da icon-comment"></i>
                              </button>
                              <button>
                                <i className="da icon-network"></i>
                              </button>
                              <button>
                                <i className="da icon-pencil-o"></i>
                              </button>
                              <button>
                                <i className="da icon-trash-o"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ribbon success">
                            <i className="da icon-chart-up-green"></i>
                            <span>Many seats left</span>
                          </div>
                          <div className="zoneSeatingWrap">
                            <div>
                              <h2>$65.00</h2>
                              <p>EXP COST: $140</p>
                              <p className="text-danger">
                                List Price less than Cost
                              </p>
                            </div>
                            <div className="zoneSeating">
                              <span>
                                Zone
                                <br />
                                Seating
                              </span>
                              <i className="da icon-checked-circle"></i>
                            </div>
                          </div>
                          <div className="eventCardBody">
                            <div className="ranking">
                              <div className="rankingInner yellow">
                                <span className="rankNumber">3</span>
                                <div>Qty</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">Left Rear</span>
                                <div>Section</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99</span>
                                <div>Row(s)</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99 - 106</span>
                                <div>Seats</div>
                              </div>
                            </div>
                          </div>
                          <div className="eventCardFooter">
                            <div>
                              <i className="da icon-user-o"></i>
                              <span>Thomas Gucciardo</span>
                            </div>
                            <div>
                              <i className="da icon-user-role"></i>
                              <span>Beatriz J</span>
                            </div>
                          </div>
                          <div className="eventCardFooter2 fontRegular">
                            <div>
                              Created: Sep 10, 2020 12:25 PM
                              <br />
                              <span>kim@ticketbash.com</span>
                            </div>
                            <div>
                              <span className="fontBold">TB2.0</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="eventCardWrap noTopbar">
                        <div className="eventCardContent">
                          <div className="eventCardHeader">
                            <a href="#" className="poNumber">
                              PO# 45721456
                            </a>
                            <div className="actions">
                              <button>
                                <i className="da icon-comment"></i>
                              </button>
                              <button>
                                <i className="da icon-network"></i>
                              </button>
                              <button>
                                <i className="da icon-pencil-o"></i>
                              </button>
                              <button>
                                <i className="da icon-trash-o"></i>
                              </button>
                            </div>
                          </div>
                          <div className="ribbon secondary">
                            <i className="da icon-chart-up-green"></i>
                            <span>Availability Unknown</span>
                          </div>
                          <div className="zoneSeatingWrap">
                            <div>
                              <h2>$65.00</h2>
                              <p>EXP COST: $140</p>
                              <p className="text-danger">
                                List Price less than Cost
                              </p>
                            </div>
                            <div className="zoneSeating">
                              <span>
                                Zone
                                <br />
                                Seating
                              </span>
                              <i className="da icon-checked-circle"></i>
                            </div>
                          </div>
                          <div className="eventCardBody">
                            <div className="ranking">
                              <div className="rankingInner yellow">
                                <span className="rankNumber">3</span>
                                <div>Qty</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">Left Rear</span>
                                <div>Section</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99</span>
                                <div>Row(s)</div>
                              </div>
                              <div className="rankingInner yellow">
                                <span className="rankNumber">99 - 106</span>
                                <div>Seats</div>
                              </div>
                            </div>
                          </div>
                          <div className="eventCardFooter">
                            <div>
                              <i className="da icon-user-o"></i>
                              <span>Thomas Gucciardo</span>
                            </div>
                            <div>
                              <i className="da icon-user-role"></i>
                              <span>Beatriz J</span>
                            </div>
                          </div>
                          <div className="eventCardFooter2 fontRegular">
                            <div>
                              Created: Sep 10, 2020 12:25 PM
                              <br />
                              <span>kim@ticketbash.com</span>
                            </div>
                            <div>
                              <span className="fontBold">TB2.0</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                closable={false}
                width={1050}
                title={false}
                footer={false}
                visible={isModalVisible5}
                centered
                className="editModal"
              >
                <div className="modalHeader">Edit Listing</div>
                <div className="modalCustomBody">
                  <div className="editUserInfoForm">
                    <div className="ant-row">
                      <div className="ant-col ant-col-xl-16">
                        <div className="ant-row">
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                Quantity of Tickets{" "}
                                <span className="req">*</span>
                              </label>
                              <input
                                placeholder=""
                                type="text"
                                className="ant-input"
                              />
                              <span className="labelBottom">
                                Max. Tickets Allowed: 8
                              </span>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                List Price ($) <span className="req">*</span>
                              </label>
                              <input
                                placeholder=""
                                type="text"
                                className="ant-input text-right"
                              />
                              <span className="labelBottom">Ticket Cost:</span>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Section</label>
                              <input
                                placeholder="Section"
                                type="text"
                                className="ant-input"
                              />
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                Row <span className="req">*</span>
                              </label>
                              <input
                                placeholder=""
                                type="text"
                                className="ant-input"
                              />
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                Low Seat <span className="req">*</span>
                              </label>
                              <input
                                placeholder=""
                                type="text"
                                className="ant-input"
                              />
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>In Hand Date</label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">
                                  3 days before event
                                </Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Broker Tag</label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Corporate</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                Stock Type <span className="req">*</span>
                              </label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Mobile Transfer</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>

                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Split Type</label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Default</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Seat Type</label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Consecutive</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>
                                Disclosures <span className="req">*</span>
                              </label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Option1</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                              {/* <span className="requiredFieldMsg">Required</span> */}
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Attributes</label>
                              <Select placeholder="Select" allowClear>
                                <Option value="option1">Option1</Option>
                                <Option value="option2">Option2</Option>
                                <Option value="option3">Option3</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Electronic Transfer</label>
                              <div>
                                <Switch
                                  checkedChildren="YES"
                                  unCheckedChildren="NO"
                                  className="yesNo"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-6">
                            <div className="inputGroup">
                              <label>Vendors</label>
                              <input
                                placeholder="Default Vendor"
                                type="text"
                                className="ant-input"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-col ant-col-xl-1">
                        <div className="dividerVertical"></div>
                      </div>
                      <div className="ant-col ant-col-xl-7">
                        <div className="textAreaWrap">
                          <div className="inputGroup">
                            <label>
                              Public Notes{" "}
                              <i className="da icon-info-fill notesInfo"></i>
                            </label>
                            <TextArea></TextArea>
                          </div>
                          <div className="inputGroup">
                            <label>
                              Internal Notes{" "}
                              <i className="da icon-info-fill notesInfo"></i>
                            </label>
                            <TextArea></TextArea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modalCustomFooter">
                  <Button className="linkBtn" onClick={editListingCancel}>
                    CANCEL
                  </Button>
                  <Button className="ant-btn btnSave">SAVE</Button>
                </div>
              </Modal>
            </Content>
          </Layout>

          <h2>Un-Broadcast and Delete Listing Card Modal</h2>
          <Layout className="mainInnerLayout">
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showUnBroadcastModal}
            >
              Un-Broadcast Listing Card Modal
            </Button>
            &nbsp;&nbsp;
            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={isModalVisible}
            >
              <div className="modalHeader modalHeaderCenter">
                Are you sure you want to <span>un-broadcast</span> this listing?
              </div>

              <div className="eventCardWrap activeListing unBroadcastModal">
                <div className="eventCardContent">
                  <div className="ribbon danger">
                    <i className="da icon-chart-up-red"></i>
                    <span className="flx-1 text-right">5/32 seats left</span>
                  </div>
                  <div className="zoneSeatingWrap">
                    <div>
                      <h2>$65.00</h2>
                      <p>EXP COST: $140</p>
                      <p className="text-danger">List Price less than Cost</p>
                    </div>
                    <div className="zoneSeating">
                      <span>
                        Zone
                        <br />
                        Seating
                      </span>
                      <i className="da icon-checked-circle"></i>
                    </div>
                  </div>
                  <div className="eventCardBody">
                    <div className="ranking">
                      <div className="rankingInner yellow">
                        <span className="rankNumber">18</span>
                        <div>Qty</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">Left Rear</span>
                        <div>Section</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">29</span>
                        <div>Row(s)</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">99 - 106</span>
                        <div>Seats</div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Thomas Gucciardo</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                  <div className="eventCardFooter2">
                    <div>
                      Created: Sep 10, 2020 12:25 PM
                      <br />
                      <span>kim@ticketbash.com</span>
                    </div>
                    <div>
                      <span className="fontBold">TB2.0</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn linkBtnColor" onClick={handleCancel}>
                  CANCEL
                </Button>
                <Button className="btnOk">OK</Button>
              </div>
            </Modal>
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showDeleteCardModal}
            >
              Delete Listing Card Modal
            </Button>
            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={isModalVisible2}
            >
              <div className="modalHeader modalHeaderCenter">
                Are you sure you want to <span>delete</span> this listing?
              </div>
              <div className="eventCardWrap activeListing unBroadcastModal">
                <div className="eventCardContent">
                  <div className="ribbon danger">
                    <i className="da icon-chart-up-red"></i>
                    <span className="flx-1 text-right">5/32 seats left</span>
                  </div>
                  <div className="zoneSeatingWrap">
                    <div>
                      <h2>$65.00</h2>
                      <p>EXP COST: $140</p>
                      <p className="text-danger">List Price less than Cost</p>
                    </div>
                    <div className="zoneSeating">
                      <span>
                        Zone
                        <br />
                        Seating
                      </span>
                      <i className="da icon-checked-circle"></i>
                    </div>
                  </div>
                  <div className="eventCardBody">
                    <div className="ranking">
                      <div className="rankingInner yellow">
                        <span className="rankNumber">18</span>
                        <div>Qty</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">Left Rear</span>
                        <div>Section</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">29</span>
                        <div>Row(s)</div>
                      </div>
                      <div className="rankingInner yellow">
                        <span className="rankNumber">99 - 106</span>
                        <div>Seats</div>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardFooter">
                    <div>
                      <i className="da icon-user-o"></i>
                      <span>Thomas Gucciardo</span>
                    </div>
                    <div>
                      <i className="da icon-user-role"></i>
                      <span>Beatriz J</span>
                    </div>
                  </div>
                  <div className="eventCardFooter2">
                    <div>
                      Created: Sep 10, 2020 12:25 PM
                      <br />
                      <span>kim@ticketbash.com</span>
                    </div>
                    <div>
                      <span className="fontBold">TB2.0</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtnColor"
                  onClick={handleCancel2}
                >
                  CANCEL
                </Button>
                <Button className="btnOk">OK</Button>
              </div>
            </Modal>
          </Layout>

          <Layout className="mainInnerLayout">
            <h3>Availability Trend Modal</h3>
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showAvailabilityTrendModal}
            >
              Availability Trend
            </Button>
            <Modal
              closable={false}
              width={570}
              title={false}
              footer={false}
              visible={isModalVisible3}
            >
              <div className="modalHeader">Availability Trend</div>
              <div className="modalCustomBody">
                <img src={AvailabilityTrendChart} alt="" />
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn" onClick={AvailabilityTrendCancel}>
                  CLOSE
                </Button>
              </div>
            </Modal>
          </Layout>

          <h2 id="pendingZone">PENDING ZONE FILLS</h2>
          <Layout className="mainInnerLayout zones">
            <div className="innerHeader">
              <Row>
                <Col span={4}>
                  <h3 id="inventoryReportTables">All Pending Fills</h3>
                </Col>
                <Col span={2}>
                  <Switch
                    checkedChildren={<i className="da icon-th-list"></i>}
                    unCheckedChildren={<i className="da icon-th-large"></i>}
                  />
                </Col>
                <Col span={6}>
                  <div className="search">
                    <input
                      type="search"
                      placeholder="Search event name, venue name, broker, po#"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>548 Records Found</h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        defaultCurrent={1}
                        total={50}
                      />
                    </li>
                    <li>
                      <button type="button" className="flx">
                        <i
                          className="da icon-sorting"
                          style={{ width: "17px", height: "17px" }}
                        ></i>
                      </button>
                    </li>
                    <li>
                      <button className="flx">
                        <i
                          className="da icon-listview"
                          style={{ width: "20px", height: "17px" }}
                        ></i>
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="removeTblLRSpc">
              <div className="customTbl scroll-x scroll-y fixedHeaderFooter pendingZoneTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "110px" }}
                      >
                        <div>
                          FILL SEAT
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "150px" }}
                      >
                        <div>
                          INVOICE ID
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "130px" }}
                      >
                        <div>
                          INVOICE DATE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "220px" }}
                      >
                        <div>
                          CUSTOMER
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "320px" }}
                      >
                        <div>
                          EVENT NAME
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "87px" }}
                      >
                        <div>
                          DAYS TO EVENT
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "120px" }}
                      >
                        <div>
                          EVENT DATE &amp; TIME
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "120px" }}
                      >
                        <div>
                          IN-HAND DATE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "80px" }}
                      >
                        <div>
                          QTY
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "160px" }}
                      >
                        <div>
                          ZONE / SECTION
                          <br />
                          LISTINGS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "160px" }}
                      >
                        <div>
                          ROW
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "80px" }}
                      >
                        <div>
                          VALUE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "130px" }}
                      >
                        <div>
                          PAYMENT STATUS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "130px" }}
                      >
                        <div>
                          STOCK TYPE
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "120px" }}
                      >
                        <div>
                          INVENTORY TAGS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "120px" }}
                      >
                        <div>
                          INVOICE TAGS
                          <i className="da sortIcon icon-sort" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="text-center selected"
                        onClick={showFillSeatsFormModal}
                      >
                        <a href="#">
                          <i className="da icon-ticket-o-w"></i>
                        </a>
                      </td>
                      <td className="eventName">
                        <a href="#">20170630-4072-00</a>
                      </td>
                      <td>Sat, Nov 27, 2021</td>
                      <td>Justin Lambaer</td>
                      <td>
                        <a href="#" className="fontBold text-primary">
                          An Intimate Evening with David Foster: Hitman Tour
                        </a>
                      </td>
                      <td className="successBg daysToEvent">
                        <span>44</span>
                      </td>
                      <td className="text-right">
                        <span className="fontBold">Sat, Nov 27, 2021</span>
                        <br />
                        02:00 PM
                      </td>
                      <td>Mon, Oct 11, 2021</td>
                      <td className="daysToEvent">
                        <a href="#" className="text-primary">
                          8
                        </a>
                      </td>
                      <td className="fontBold">REAR ORCHESTRA PRIME</td>
                      <td className="fontBold text-center">z</td>
                      <td className="fontBold text-center">$80.00</td>
                      <td className="fontBold">PENDING</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <a href="#">
                          <i className="da icon-ticket-o"></i>
                        </a>
                      </td>
                      <td className="eventName">
                        <a href="#">20170630-4072-00</a>
                      </td>
                      <td>Sat, Nov 27, 2021</td>
                      <td>Justin Lambaer</td>
                      <td>
                        <a href="#" className="fontBold text-primary">
                          An Intimate Evening with David Foster: Hitman Tour
                        </a>
                      </td>
                      <td className="warningBg daysToEvent">
                        <span>44</span>
                      </td>
                      <td className="text-right">
                        <span className="fontBold">Sat, Nov 27, 2021</span>
                        <br />
                        02:00 PM
                      </td>
                      <td>Mon, Oct 11, 2021</td>
                      <td className="daysToEvent">
                        <a href="#" className="text-primary">
                          8
                        </a>
                      </td>
                      <td className="fontBold">REAR ORCHESTRA PRIME</td>
                      <td className="fontBold text-center">z</td>
                      <td className="fontBold text-center">$80.00</td>
                      <td className="fontBold">PENDING</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <a href="#">
                          <i className="da icon-ticket-o"></i>
                        </a>
                      </td>
                      <td className="eventName">
                        <a href="#">20170630-4072-00</a>
                      </td>
                      <td>Sat, Nov 27, 2021</td>
                      <td>Justin Lambaer</td>
                      <td>
                        <a href="#" className="fontBold text-primary">
                          An Intimate Evening with David Foster: Hitman Tour
                        </a>
                      </td>
                      <td className="dangerBg daysToEvent">
                        <span>44</span>
                      </td>
                      <td className="text-right">
                        <span className="fontBold">Sat, Nov 27, 2021</span>
                        <br />
                        02:00 PM
                      </td>
                      <td>Mon, Oct 11, 2021</td>
                      <td className="daysToEvent">
                        <a href="#" className="text-primary">
                          8
                        </a>
                      </td>
                      <td className="fontBold">REAR ORCHESTRA PRIME</td>
                      <td className="fontBold text-center">z</td>
                      <td className="fontBold text-center">$80.00</td>
                      <td className="fontBold">PENDING</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              closable={false}
              width={760}
              title={false}
              footer={false}
              visible={isModalVisible6}
              centered
              className="editModal"
            >
              <div className="modalCustomBody">
                <div className="editUserInfoForm">
                  <div className="fillSeatsFormHeader">
                    <div className="left">
                      <h3>Come From Away</h3>
                      <h4>Sat, Nov 27, 2021 at 02:00 PM</h4>
                      <p>
                        Van Wezel Performing Arts Hall <br />
                        New York, NY, USA
                      </p>
                    </div>
                    <div className="">
                      <div className="ranking">
                        <div className="yellow">
                          <span className="rankNumber">18</span>
                          <div>Qty</div>
                        </div>
                        <div className="yellow">
                          <span className="rankNumber">Left Rear</span>
                          <div>Section</div>
                        </div>
                        <div className="yellow">
                          <span className="rankNumber">29</span>
                          <div>Row(s)</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Section</label>
                        <input
                          placeholder="Section"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Row</label>
                        <input
                          placeholder="Row"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Inventory Tags</label>
                        <input
                          placeholder="Tag"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Exclude Inventory Tags</label>
                        <input
                          placeholder="Tag"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Min QTY</label>
                        <input
                          placeholder="Min QTY"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Max QTY</label>
                        <input
                          placeholder="Max QTY"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn" onClick={fillSeatsFormCancel}>
                  CLOSE
                </Button>
                <button type="button" className="ant-btn btnSave">
                  SAVE
                </button>
              </div>
            </Modal>
          </Layout>

          <h2 id="editEventSection">EDIT EVENT</h2>
          <Layout className="mainInnerLayout pb-0">
            <h3>Edit Event Availability</h3>
            <div className="editEvent removeTblLRSpc">
              <div className="fillSeatsFormHeader">
                <div className="left">
                  <h3>Come From Away</h3>
                  <p>
                    Sat, Nov 27, 2021 at 02: 00 PM{" "}
                    <Tag className="success">94d</Tag>
                  </p>
                </div>
                <div className="text-right">
                  <p>
                    Van Wezel Performing Arts Hall <br />
                    New York, NY, USA
                  </p>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xl-14">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-13">
                      <div className="inputGroup">
                        <label>Seat Map URL</label>
                        <div className="flx alignItemsCenter">
                          <input
                            placeholder="https://example.net/afternoon/boundary"
                            type="text"
                            className="ant-input"
                          />
                          <a href="#" className="ml-8">
                            Preview
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Stock Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Mobile Transfer</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-5">
                      <div className="inputGroup">
                        <label>Set Reminder</label>
                        <div className="flx alignItemsCenter">
                          <input
                            placeholder="Set Reminder"
                            type="text"
                            className="ant-input text-right"
                          />
                          <span className="ml-8">days</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="customTbl">
                    <table className="main-table dragableTbl">
                      <thead>
                        <tr>
                          <th style={{ width: "30px" }}></th>
                          <th>ZONE / SECTION</th>
                          <th className="text-right">ROWS</th>
                          <th className="text-right" style={{ width: "110px" }}>
                            COST
                          </th>
                          <th
                            className="text-center"
                            style={{ width: "130px" }}
                          >
                            AVAILABILITY
                          </th>
                          <th style={{ width: "50px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="dragable"></div>
                          </td>
                          <td>
                            <strong>LFET MIDDLE</strong>
                          </td>
                          <td className="text-right">18</td>
                          <td className="text-right">$153.00</td>
                          <td>
                            <div className="text-center">
                              <Tag className="success">High</Tag>
                            </div>
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="dragable"></div>
                          </td>
                          <td>
                            <strong>LEFT REAR</strong>
                          </td>
                          <td className="text-right">28</td>
                          <td className="text-right">$123.00</td>
                          <td>
                            <div className="text-center">
                              <Tag className="warning">Medium</Tag>
                            </div>
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="dragable"></div>
                          </td>
                          <td>
                            <strong>LFET REAR</strong>
                          </td>
                          <td className="text-right">18</td>
                          <td className="text-right">$153.00</td>
                          <td>
                            <div className="text-center">
                              <Tag className="danger">Low</Tag>
                            </div>
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="dragable"></div>
                          </td>
                          <td>
                            <strong>LFET REAR</strong>
                          </td>
                          <td className="text-right">18</td>
                          <td className="text-right">$153.00</td>
                          <td>
                            <div className="text-center">
                              <Tag className="success">High</Tag>
                            </div>
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="dragable"></div>
                          </td>
                          <td>
                            <Input
                              className="inputSml"
                              placeholder="Zone / Section"
                            />
                          </td>
                          <td>
                            <Input
                              className="inputSml text-right"
                              placeholder="Row/s"
                            />
                          </td>
                          <td>
                            <Input
                              className="inputSml text-right"
                              placeholder="$"
                            />
                          </td>
                          <td>
                            <div className="text-center">
                              <Tag className="success">High</Tag>
                            </div>
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-disabled"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-10">
                  <div className="textAreaWrap">
                    <div className="inputGroup">
                      <label>Notes</label>
                      <TextArea></TextArea>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-14">
                  <div className="addRow">
                    <a href="#">NEW ROW</a>
                  </div>
                </div>
              </div>
              <div className="ant-row btmRow">
                <div className="ant-col ant-col-xl-24 alignSelfEnd text-right">
                  <button type="button" className="linkBtn">
                    CANCEL
                  </button>
                  <button type="button" className="ant-btn btnSave">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout">
            <div className="ant-row">
              <div className="ant-col ant-col-xl-17">
              <h3>Secondary Market Info</h3>
              <div className="editEvent mb20">
                <div className="inputGroup">
                  <label>Stubhub</label>
                  <input placeholder="" type="text" className="ant-input" />
                </div>
                <div className="inputGroup">
                  <label>Vivid Seats</label>
                  <input placeholder="" type="text" className="ant-input" />
                </div>
                <div className="inputGroup">
                  <label>TicketNetwork</label>
                  <input placeholder="" type="text" className="ant-input" />
                </div>
              </div>

                <h3>Availability Trend</h3>
                <div className="chartWrap">
                  <img
                    src={AvailabilityTrendChart}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="ant-col ant-col-xl-7">
                <h3>Revision History</h3>
                <div
                  className="customTbl scroll-y revisionHistoryTbl"
                  style={{ maxHeight: "355px" }}
                >
                  <table className="main-table">
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                    <tr>
                      <td>Tue, Aug 31, 2021 at 3:14 PM by Rose Parisi</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2 id="venues">VENUES</h2>
            </div>
            <div className="flx alignItemsCenter">
              <Select placeholder="Bulk Assign" allowClear>
                <Option value="option1">Rose Parisi</Option>
                <Option value="option2">Doug Russo</Option>
                <Option value="option3">Dan Lunde</Option>
              </Select>
              <a href="#">
                <i className="da icon-listview listviewIcon"></i>
              </a>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <div className="innerHeader">
              <Row>
                <Col span={6}>
                  <h3 id="inventoryReportTables">All Venues</h3>
                </Col>
                <Col span={6}>
                  <div className="search">
                    <input
                      type="search"
                      placeholder="Search event, venue, broker, city, state"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>312 Venues Found</h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        defaultCurrent={1}
                        total={50}
                      />
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="removeTblLRSpc">
              <div className="customTbl zoneListViewTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th style={{ width: "40px" }}></th>
                      <th style={{ width: "60px" }}>
                        <div>
                          <i className="da icon-bookmark-dark"></i>
                          <i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "170px" }}
                      >
                        <div>
                          VENUE NAME<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "150px" }}
                      >
                        <div>
                          VENUE LOCATION
                          <i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "110px" }}
                      >
                        <div>
                          DATE ADDED<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "140px" }}
                      >
                        <div>
                          BROKER<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "130px" }}
                      >
                        <div>
                          LISTER<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "90px" }}
                      >
                        <div>
                          EVENT COUNT<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "90px" }}
                      >
                        <div>
                          UNIQUE EVENTS<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          WEB SCRAPING<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          LAST SCRAPED<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <a href="#" className="booxmark">
                          <i className="da icon-bookmark-o"></i>
                        </a>
                      </td>
                      <td>
                        <a href="#">William H. Mortensen Hall</a>
                      </td>
                      <td>Sarasota, Florida, United States</td>
                      <td>Nov 27, 2021</td>
                      <td>Thomas Gucciardo</td>
                      <td>
                        Beatriz J<br />
                        Sohini Banerjee
                      </td>
                      <td className="daysToEvent vTop">
                        <a href="#">22</a>
                      </td>
                      <td className="daysToEvent vTop">5</td>
                      <td className="text-center">
                        <i className="da icon-fictionalCharacter-red"></i>
                      </td>
                      <td>Jul 23, 2021</td>
                      <td>
                        <div className="tblActions">
                          <a href="#" onClick={showEditVenueModal}>
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <a href="#" className="booxmark">
                          <i className="da icon-bookmark-o"></i>
                        </a>
                      </td>
                      <td>
                        <a href="#">William H. Mortensen Hall</a>
                      </td>
                      <td>Sarasota, Florida, United States</td>
                      <td>Nov 27, 2021</td>
                      <td>Thomas Gucciardo</td>
                      <td>
                        Beatriz J<br />
                        Sohini Banerjee
                      </td>
                      <td className="daysToEvent vTop">
                        <a href="#">22</a>
                      </td>
                      <td className="daysToEvent vTop">7</td>
                      <td className="vMiddle text-center">
                        <i className="da icon-fictionalCharacter-red"></i>
                      </td>
                      <td>Jul 23, 2021</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <a href="#" className="booxmark">
                          <i className="da icon-bookmark-o"></i>
                        </a>
                      </td>
                      <td>
                        <a href="#">William H. Mortensen Hall</a>
                      </td>
                      <td>Sarasota, Florida, United States</td>
                      <td>Nov 27, 2021</td>
                      <td>Thomas Gucciardo</td>
                      <td>
                        Beatriz J<br />
                        Sohini Banerjee
                      </td>
                      <td className="daysToEvent vTop">
                        <a href="#">22</a>
                      </td>
                      <td className="daysToEvent vTop">6</td>
                      <td className="vMiddle text-center"></td>
                      <td>Jul 23, 2021</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                            <span className="commentNotification">1</span>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <a href="#" className="booxmark">
                          <i className="da icon-bookmark-o"></i>
                        </a>
                      </td>
                      <td>
                        <a href="#">William H. Mortensen Hall</a>
                      </td>
                      <td>Sarasota, Florida, United States</td>
                      <td>Nov 27, 2021</td>
                      <td>Thomas Gucciardo</td>
                      <td>
                        Beatriz J<br />
                        Sohini Banerjee
                      </td>
                      <td className="daysToEvent vTop">
                        <a href="#">22</a>
                      </td>
                      <td className="daysToEvent vTop">4</td>
                      <td className="vMiddle text-center">
                        <i className="da icon-fictionalCharacter-green"></i>
                      </td>
                      <td>Jul 23, 2021</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-pencil-o" />
                          </a>
                          <a href="#">
                            <i className="da icon-comment" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <Modal
              closable={false}
              width={760}
              title={false}
              footer={false}
              visible={isModalVisible7}
              centered
              className="editModal"
            >
              <div className="modalCustomBody">
                <div className="editUserInfoForm">
                  <h3>Edit Venue</h3>

                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Broker</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Rose Parisi</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Roles</label>
                        <ul className="checkboxList">
                          <li>
                            <Checkbox>Rose Parisi</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Beatriz</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Sohini Banerjee</Checkbox>
                          </li>
                          <li>
                            <Checkbox>Doug Russo</Checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Venue URL Link</label>
                        <input
                          placeholder="Rose Parisi"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Seating Intelligence</label>
                        <div className="radioList">
                          <Radio.Group className="radioStyleDark radioGroupList">
                            <Radio value={1}>Manual</Radio>
                            <Radio value={2}>Web Scraping</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Data Source</label>
                        <input
                          placeholder="Data Source"
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Notes</label>
                        <TextArea
                          placeholder="Notes"
                          style={{ minHeight: "150px" }}
                        ></TextArea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn" onClick={editVenueModalCancel}>
                  CLOSE
                </Button>
                <button type="button" className="ant-btn btnOk">
                  SAVE
                </button>
              </div>
            </Modal>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2 id="events">UPCOMING EVENTS</h2>
            </div>
            <div className="flx alignItemsCenter">
              <Select placeholder="Bulk Assign" allowClear>
                <Option value="option1">Rose Parisi</Option>
                <Option value="option2">Doug Russo</Option>
                <Option value="option3">Dan Lunde</Option>
              </Select>
              <a href="#">
                <i className="da icon-listview listviewIcon"></i>
              </a>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row>
                  <Col span={4}>
                    <h3 id="inventoryReportTables">Events</h3>
                  </Col>
                  <Col span={2}>
                    <Switch
                      checkedChildren={<i className="da icon-th-list"></i>}
                      unCheckedChildren={<i className="da icon-th-large"></i>}
                    />
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input
                        type="search"
                        placeholder="Search event, venue, broker, city, state"
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>312 Events Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="zonesGridView eventsGridView">
                <div className="listRow">
                  <div className="eventCardWrap">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive disabled">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap success">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                        <i className="da icon-fictionalCharacter-red"></i>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">The Band’s Visit, Thursday</a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap warning">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">America</a>{" "}
                              <Tag className="success">New</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap danger">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                <b>RESCHEDULED - Mary Chapin Carpenter</b>
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">Sarah Brightman</a>{" "}
                              <Tag className="danger">Not on POS</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap success">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by </span>
                        <i className="da icon-fictionalCharacter-green"></i>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">A Tribute to Aretha</a>{" "}
                              <Tag className="danger">Not on POS</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap warning">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">America</a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap danger">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                <b>RESCHEDULED - Mary Chapin Carpenter</b>
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2>UPCOMING EVENTS</h2>
            </div>
            <div className="flx alignItemsCenter">
              <Select placeholder="Bulk Assign" allowClear>
                <Option value="option1">Rose Parisi</Option>
                <Option value="option2">Doug Russo</Option>
                <Option value="option3">Dan Lunde</Option>
              </Select>
              <a href="#">
                <i className="da icon-listview listviewIcon"></i>
              </a>
            </div>
          </div>
          <Layout className="mainInnerLayout zoneListViewTbl upCominglistview">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row>
                  <Col span={4}>
                    <h3 id="inventoryReportTables">Events</h3>
                  </Col>
                  <Col span={2}>
                    <Switch
                      checkedChildren={<i className="da icon-th-list"></i>}
                      unCheckedChildren={<i className="da icon-th-large"></i>}
                    />
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input
                        type="search"
                        placeholder="Search event, venue, broker, city, state"
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>312 Events Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div className="removeTblLRSpc">
                <div className="customTbl ">
                  <table className="main-table">
                    <thead>
                      <tr>
                        <th style={{ width: "20px", padding: "0" }}></th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "130px" }}
                        >
                          <div>
                            LAST UPDATED
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "" }}
                        >
                          <div>
                            EVENT NAME &amp; VENUE
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "120px" }}
                        >
                          <div>
                            DATE ADDED
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "87px" }}
                        >
                          <div>
                            DAYS TO EVENT
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "120px" }}
                        >
                          <div>
                            DATE &amp; TIME
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "130px" }}
                        >
                          <div>
                            BROKER
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "130px" }}
                        >
                          <div>
                            LISTER
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "100px" }}
                        >
                          <div>
                            ACTIVE
                            <br />
                            LISTINGS
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "130px" }}
                        ></th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "90px" }}
                        >
                          <div>
                            NEW
                            <br />
                            LISTING
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="warningBg" style={{ padding: "0" }}></td>
                        <td className="latestUpdateCol">
                          21 days ago by Beatriz J
                        </td>
                        <td>
                          <a href="#">
                            <strong>RESCHEDULED - Mary Chapin Carpenter</strong>
                          </a>
                          <br />
                          Van Wezel Performing Arts Hall: New York, NY, USA
                        </td>
                        <td>Nov 27, 2021</td>
                        <td className="daysToEvent">
                          <div>94</div>
                        </td>
                        <td>
                          Sat, Nov 27, 2021
                          <br />
                          02:00 PM
                        </td>
                        <td>Thomas Gucciardo</td>
                        <td>Beatriz J</td>
                        <td className="daysToEvent activeListing">
                          <a href="#">2</a>
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-th" />
                            </a>
                            <a href="#">
                              <i className="da icon-pencil-o" />
                            </a>
                            <a href="#">
                              <i className="da icon-comment" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <Button className="createListing">
                            <i className="da icon-add-layer "></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <Tooltip
                          placement="right"
                          title="No Seat Intelligence Available"
                        >
                          <td
                            className="defaultBg haveTooltip"
                            style={{ padding: "0" }}
                          ></td>
                        </Tooltip>
                        <td className="latestUpdateCol">
                          21 days ago by Beatriz J
                        </td>
                        <td>
                          <a href="#">
                            <strong>The Band’s Visit, Thursday</strong>
                          </a>
                          <br />
                          Wolf Trap Filene Center: New York, NY, USA
                        </td>
                        <td>Nov 27, 2021</td>
                        <Tooltip
                          placement="bottom"
                          title="Event is less than 14 days away"
                        >
                          <td className="daysToEvent dangerBg haveTooltip">
                            <div>3</div>
                          </td>
                        </Tooltip>
                        <td>
                          Sat, Nov 27, 2021
                          <br />
                          02:00 PM
                        </td>
                        <td>Thomas Gucciardo</td>
                        <td>Beatriz J</td>
                        <td className="daysToEvent activeListing">
                          <a href="#">2</a>
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-pencil-o" />
                            </a>
                            <a href="#">
                              <i className="da icon-comment" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <Button className="createListing" disabled>
                            <i className="da icon-add-layer "></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <Tooltip placement="right" title="prompt text">
                          <td
                            className="warningBg haveTooltip"
                            style={{ padding: "0" }}
                          ></td>
                        </Tooltip>
                        <td className="latestUpdateCol">
                          10 days ago by{" "}
                          <i className="da icon-fictionalCharacter-green"></i>
                        </td>
                        <td>
                          <a href="#">
                            <strong>America</strong>
                          </a>{" "}
                          <Tag className="success tagSmall">New</Tag>
                          <br />
                          Wolf Trap Filene Center: New York, NY, USA
                        </td>
                        <td>Nov 27, 2021</td>
                        <td className="daysToEvent">
                          <div>84</div>
                        </td>
                        <td>
                          Sat, Nov 27, 2021
                          <br />
                          02:00 PM
                        </td>
                        <td>Thomas Gucciardo</td>
                        <td>Beatriz J</td>
                        <td className="daysToEvent">
                          <a href="#">2</a>
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-th" />
                            </a>
                            <a href="#">
                              <i className="da icon-pencil-o" />
                            </a>
                            <a href="#">
                              <i className="da icon-comment" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <Button className="createListing">
                            <i className="da icon-add-layer "></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td className="dangerBg" style={{ padding: "0" }}></td>
                        <td className="latestUpdateCol">
                          21 days ago by Beatriz J{" "}
                        </td>
                        <td>
                          <a href="#">
                            <strong>Sarah Brightman</strong>
                          </a>{" "}
                          <Tag className="danger tagSmall">Not on POS</Tag>
                          <br />
                          Wolf Trap Filene Center: New York, NY, USA
                        </td>
                        <td>Nov 27, 2021</td>
                        <td className="daysToEvent">
                          <div>10</div>
                        </td>
                        <td>
                          Sat, Nov 27, 2021
                          <br />
                          02:00 PM
                        </td>
                        <td>Thomas Gucciardo</td>
                        <td>Beatriz J</td>
                        <td className="daysToEvent">
                          <a href="#">2</a>
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-th" />
                            </a>
                            <a href="#">
                              <i className="da icon-pencil-o" />
                            </a>
                            <a href="#">
                              <i className="da icon-comment" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <Button className="createListing">
                            <i className="da icon-add-layer "></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <Tooltip
                          placement="right"
                          title="High Availability (at least 50% seats available)"
                        >
                          <td
                            className="successBg haveTooltip"
                            style={{ padding: "0" }}
                          ></td>
                        </Tooltip>
                        <td className="latestUpdateCol">
                          10 days ago by{" "}
                          <i className="da icon-fictionalCharacter-red"></i>
                        </td>
                        <td>
                          <a href="#">
                            <strong>America</strong>
                          </a>
                          <br />
                          Wolf Trap Filene Center: New York, NY, USA
                        </td>
                        <td>Nov 27, 2021</td>
                        <td className="daysToEvent warningBg">
                          <div>17</div>
                        </td>
                        <td>
                          Sat, Nov 27, 2021
                          <br />
                          02:00 PM
                        </td>
                        <td>Thomas Gucciardo</td>
                        <td>Beatriz J</td>
                        <td className="daysToEvent">
                          <a href="#">2</a>
                        </td>
                        <td>
                          <div className="tblActions">
                            <a href="#">
                              <i className="da icon-th" />
                            </a>
                            <a href="#">
                              <i className="da icon-pencil-o" />
                            </a>
                            <a href="#">
                              <i className="da icon-comment" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <Button className="createListing">
                            <i className="da icon-add-layer "></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Content>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2 id="venues">Events</h2>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer">
              <div className="eventsFormWrap">
                <div className="eventsForm">
                  <h3>Hi Chris!</h3>
                  <div className="inputGroup">
                    <label>What event are you looking for?</label>
                    <input
                      placeholder="Search for event or venue name"
                      type="text"
                      className="ant-input"
                    />
                  </div>
                  <div className="flx justifyContentBetween alignItemsCenter">
                    <div>
                      <a href="#" className="selectDateRange">
                        <i className="da icon-calendar-o"></i>Select event date
                        range
                      </a>
                    </div>
                    <button type="button" className="ant-btn btnStyle2">
                      SEARCH
                    </button>
                  </div>
                  <div className="eventDateRangeSec active">
                    <h4>EVENT DATE RANGE</h4>
                    <div className="eventDateRangeToggle">
                      <div className="eventDateRangeToggleInner">
                        <div className="ant-row">
                          <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                              <label>Date from:</label>
                              <input
                                placeholder="MM/DD/YYYY"
                                type="text"
                                className="ant-input"
                              />
                            </div>
                          </div>
                          <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                              <label>Date to:</label>
                              <input
                                placeholder="MM/DD/YYYY"
                                type="text"
                                className="ant-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formFooter">
                      <button type="button" className="ant-btn linkBtn">
                        RESET
                      </button>
                      <button type="button" className="ant-btn linkBtn">
                        CANCEL
                      </button>
                      <button type="button" className="ant-btn btnStyle2">
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>

          <div className="mainHeading" id="createNewListingID">
            <div>
              <h2>Create New Listing</h2>
            </div>
            <div className="flx alignItemsCenter">
              <button type="button" className="btnSecondary">
                Bulk Placement
              </button>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <div className="innerHeader">
              <Row className="alignItemsCenter">
                <Col span={6}>
                  <h3 id="inventoryReportTables">
                    <a href="#">The Band’s Visit</a>
                  </h3>
                </Col>
                <Col span={14}>
                  <span className="dateTimeTxt">
                    Thu, Nov 18, 2021 at 7:30 PM
                  </span>
                  <span>Van Wezel Performing Arts Hall, New York, NY, USA</span>
                </Col>
                <Col span={4} className="text-right">
                  <button
                    type="button"
                    onClick={showCreateNewListingModal}
                    className="btnSecondary"
                  >
                    Create Listing
                  </button>
                </Col>
              </Row>
            </div>

            <div className="">
              <div className="customTbl createListingTbl">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th style={{ width: "40px" }}></th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ minWidth: "170px" }}
                      >
                        <div>
                          ZONE / SECTION
                          <i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "150px" }}
                      >
                        <div>
                          ROW<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          COST<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "120px" }}
                      >
                        <div>
                          AVAILABILITY<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          GROUP<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "90px" }}
                      >
                        <div>
                          QUANTITY<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "90px" }}
                      >
                        <div>
                          PRICE<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "120px" }}>
                        <div>
                          <div>VIVID 20</div>
                          <div>11/23/22 &nbsp;<span className="da icon-refresh icon-sm pointer"></span></div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "120px" }}>
                        <div>
                          <div>Ticket Network 17</div>
                          <div>11/23/22 &nbsp;<span className="da icon-refresh icon-sm pointer"></span></div>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          ROW<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "100px" }}
                      >
                        <div>
                          STOCK TYPE<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "60px" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <b>Mezzanine B</b>
                      </td>
                      <td>
                        <b>A, B, C, D, E, F, G, H, J</b>
                      </td>
                      <td className="costCol">$73.00</td>
                      <td className="availabilityCol">
                        <div className="availabilityGraphArea dangerAvailability">
                          <span>59/63</span>
                          <i className="da icon-chart-up-red"></i>
                        </div>
                      </td>
                      <td className="text-center">
                        <a href="#">1</a>
                      </td>
                      <td className="text-center">8</td>
                      <td className="text-right">$165.00</td>
                      <td className="competitive">
                        <div className="competitiveInner flx flexColumn">
                          <div className="mb5 text-md">12 listings</div>
                          <div className="competitiveValue">$100-150</div>
                        </div>
                      </td>
                      <td className="competitive">
                        <div className="competitiveInner flx alignItemsCenter">
                          <div className="flx flexColumn flx-1">
                            <div className="mb5 text-md">12 listings</div>
                            <div className="competitiveValue">$100-150</div>
                          </div>
                          <span onClick={showCompetitiveModal} className="da icon-search-view icon-md pointer"></span>
                        </div>
                      </td>
                      <td className="text-center">99</td>
                      <td>Mobile Transfer</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-add-layer-blue"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Orchestra B</b>
                      </td>
                      <td className="noAvailableTxt">No available rows</td>
                      <td className="costCol">$89.00</td>
                      <td className="availabilityCol">
                        <div className="availabilityGraphArea tbd">
                          <span>N/A</span>
                        </div>
                      </td>
                      <td className="text-center">
                        <a href="#">2</a>
                      </td>
                      <td className="text-center">8</td>
                      <td className="text-right">$165.00</td>
                      <td className="competitive">
                        <div className="competitiveInner flx flexColumn">
                          <div className="mb5 text-md">12 listings</div>
                          <div className="competitiveValue">$100-150</div>
                        </div>
                      </td>
                      <td className="competitive">
                        <div className="competitiveInner flx alignItemsCenter">
                          <div className="flx flexColumn flx-1">
                            <div className="mb5 text-md">12 listings</div>
                            <div className="competitiveValue">$100-150</div>
                          </div>
                          <span onClick={showCompetitiveModal} className="da icon-search-view icon-md pointer"></span>
                        </div>
                      </td>
                      <td className="text-center">99</td>
                      <td>Mobile Transfer</td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-add-layer-blue"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <b>Mezzanine B</b>
                      </td>
                      <td>
                        <b>DD, EE, FF, GG</b>
                      </td>
                      <td className="costCol">$73.00</td>
                      <td className="availabilityCol">
                        <div className="availabilityGraphArea successAvailability">
                          <span>21/35</span>
                          <i className="da icon-chart-up-green"></i>
                        </div>
                      </td>
                      <td className="text-center">
                        <a href="#">3</a>
                      </td>
                      <td className="text-center">8</td>
                      <td className="text-right">$165.00</td>
                      <td className="competitive">
                        <div className="competitiveInner flx flexColumn">
                          <div className="mb5 text-md">12 listings</div>
                          <div className="competitiveValue">$100-150</div>
                        </div>
                      </td>
                      <td className="competitive">
                        <div className="competitiveInner flx alignItemsCenter">
                          <div className="flx flexColumn flx-1">
                            <div className="mb5 text-md">12 listings</div>
                            <div className="competitiveValue">$100-150</div>
                          </div>
                          <span onClick={showCompetitiveModal} className="da icon-search-view icon-md pointer"></span>
                        </div>
                      </td>
                      <td className="text-center">99</td>
                      <td></td>
                      <td>
                        <div className="tblActions">
                          <a href="#">
                            <i className="da icon-add-layer-blue"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Pit</b>
                      </td>
                      <td className="noAvailableTxt">No available rows</td>
                      <td className="costCol">$102.00</td>
                      <td className="availabilityCol">
                        <div className="availabilityGraphArea none">
                          <span>None</span>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="competitive">
                        <div className="competitiveInner flx flexColumn">
                          <div className="mb5 text-md">12 listings</div>
                          <div className="competitiveValue">$100-150</div>
                        </div>
                      </td>
                      <td className="competitive">
                        <div className="competitiveInner flx alignItemsCenter">
                          <div className="flx flexColumn flx-1">
                            <div className="mb5 text-md">12 listings</div>
                            <div className="competitiveValue">$100-150</div>
                          </div>
                          <span onClick={showCompetitiveModal} className="da icon-search-view icon-md pointer"></span>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        <b>Orchestra C</b>
                      </td>
                      <td>
                        <b>N, P, Q, R, S, T, U, V, W, X, Y, Z</b>
                      </td>
                      <td className="costCol">$89.00</td>
                      <td className="availabilityCol">
                        <div className="availabilityGraphArea warningAvailability">
                          <span>79/154</span>
                          <i className="da icon-chart-up-yellow"></i>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="competitive">
                        <div className="competitiveInner flx flexColumn">
                          <div className="mb5 text-md">12 listings</div>
                          <div>$100-150</div>
                        </div>
                      </td>
                      <td className="competitive">
                        <div className="competitiveInner flx alignItemsCenter">
                          <div className="flx flexColumn flx-1">
                            <div className="mb5 text-md">12 listings</div>
                            <div>$100-150</div>
                          </div>
                          <span onClick={showCompetitiveModal} className="da icon-search-view icon-md pointer"></span>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


            <Modal
              closable={true}
              width={932}
              title={false}
              footer={false}
              visible={isModalVisible18}
              centered
              className=""
              onCancel={cancelCompetitiveModal}
            >
                {/* <div className="flx alignItemsCenter justifyContentBetween">
                  <span onClick={cancelCompetitiveModal}>x</span>
                </div> */}
                <div className="flx flexRow">
                  <div className="flx-1">
                    <h3>Vivid (20 listings)</h3>
                    <div className="flx flexWrap btnList">
                      <Button className="btn rounded-outline btnWidth active">All</Button>
                      <Button className="btn rounded-outline btnWidth">Balc</Button>
                      <Button className="btn rounded-outline btnWidth">Orch</Button>
                      <Button className="btn rounded-outline btnWidth">Orch Full</Button>
                      <Button className="btn rounded-outline btnWidth">Orch Full</Button>
                    </div>

                    <div className="customTbl noHeaderTbl competitiveTbl mt-10">
                      <table className="main-table">
                        <tbody>
                        <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                        </tbody>
                      </table>
                    </div>
                                     
                  </div>
                  <div className="px-20">
                  <div className="v-devider h-100"></div>
                  </div>
                  <div className="flx-1">
                    <h3>Ticket Network (17 listings)</h3>
                    <div className="flx flexWrap btnList">
                      <Button className="btn rounded-outline btnWidth active">All</Button>
                      <Button className="btn rounded-outline btnWidth">Balc</Button>
                      <Button className="btn rounded-outline btnWidth">Orch</Button>
                      <Button className="btn rounded-outline btnWidth">Orch Full</Button>
                      <Button className="btn rounded-outline btnWidth">Orch Full</Button>
                    </div>

                    <div className="customTbl noHeaderTbl competitiveTbl mt-10">
                      <table className="main-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                          <tr>
                            <td>
                              <div className="text-lg">$65.00</div></td>
                            <td>
                              <p className="competitiveValue">Left Upper Orch | Row N</p>
                              1-6 Tickets, Zone Seating
                            </td>                      
                          </tr>
                        </tbody>
                      </table>
                    </div>
                                     
                  </div>
                </div>             
            </Modal>



            <Modal
              closable={false}
              width={1050}
              title={false}
              footer={false}
              visible={isModalVisible8}
              centered
              className="editModal"
            >
              <div className="modalCustomBody">
                <h3>Inventory Listing Form</h3>
                <div className="editUserInfoForm">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-16">
                      <div className="ant-row">
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              Quantity of Tickets <span className="req">*</span>
                            </label>
                            <input
                              placeholder=""
                              type="text"
                              className="ant-input"
                            />
                            <span className="labelBottom">
                              Max. Tickets Allowed: 8
                            </span>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              List Price ($) <span className="req">*</span>
                            </label>
                            <input
                              placeholder=""
                              type="text"
                              className="ant-input text-right"
                            />
                            <span className="labelBottom">Ticket Cost:</span>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Section</label>
                            <input
                              placeholder="Mezzanine A"
                              type="text"
                              className="ant-input"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              Row <span className="req">*</span>
                            </label>
                            <input
                              placeholder="Row"
                              type="text"
                              className="ant-input text-right"
                            />
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              Low Seat <span className="req">*</span>
                            </label>
                            <input
                              placeholder=""
                              type="text"
                              className="ant-input"
                            />
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              In Hand Date <span className="req">*</span>
                            </label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">
                                5 days before event
                              </Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6"></div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              Stock Type <span className="req">*</span>
                            </label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Mobile Transfer</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Split Type</label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Default</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Seat Type</label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Consecutive</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Disclosures</label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Option1</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Attributes</label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Option1</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Electronic Transfer</label>
                            <div>
                              <Switch
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                className="yesNo"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Vendors</label>
                            <input
                              placeholder="Corporate Zone"
                              type="text"
                              className="ant-input"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>
                              Broker Tag <span className="req">*</span>
                            </label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Option1</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="ant-col ant-col-xl-6">
                          <div className="inputGroup">
                            <label>Lister Tag</label>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Option1</Option>
                              <Option value="option2">Option2</Option>
                              <Option value="option3">Option3</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-1">
                      <div className="dividerVertical"></div>
                    </div>
                    <div className="ant-col ant-col-xl-7">
                      <div className="textAreaWrap">
                        <div className="inputGroup">
                          <label>
                            Public Notes{" "}
                            <i className="da icon-info-fill-dark notesInfo"></i>
                          </label>
                          <TextArea></TextArea>
                        </div>
                        <div className="inputGroup">
                          <label>
                            Internal Notes{" "}
                            <i className="da icon-info-fill-dark notesInfo"></i>
                          </label>
                          <TextArea></TextArea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalFooterStyle2">
                <Checkbox>Apply to all</Checkbox>
                <div>
                  <Button className="linkBtn" onClick={createNewListingCancel}>
                    CANCEL
                  </Button>
                  <button type="button" className="ant-btn btnOk">
                    SAVE
                  </button>
                </div>
              </div>
            </Modal>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2>BULK PLACEMENT</h2>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <div className="innerHeader">
              <Row>
                <Col span={6}>
                  <h3 id="">Inventory Details</h3>
                </Col>
                <Col span={18}>
                  <span className="dateTimeTxt">
                    <b>The Band’s Visit</b>
                  </span>
                  <span>Van Wezel Performing Arts Hall, New York, NY, USA</span>
                </Col>
              </Row>
            </div>

            <div className="editUserInfoForm formBg">
              <div className="ant-row">
                <div className="ant-col ant-col-xl-16">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Quantity <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                        <span className="labelBottom">
                          Max. Tickets Allowed: 8
                        </span>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Markup %</label>
                        <input
                          placeholder="Percentage"
                          type="text"
                          className="ant-input text-right"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6"></div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Broadcast <span className="req">*</span>
                        </label>
                        <div>
                          <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            className="yesNo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Low Seat <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          In Hand Date <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">5 days before event</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6"></div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Stock Type <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Mobile Transfer</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>

                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Default</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Seat Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Consecutive</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Disclosures</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Attributes</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Electronic Transfer</label>
                        <div>
                          <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            className="yesNo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Vendor</label>
                        <input
                          placeholder="Corporate Zone"
                          type="text"
                          className="ant-input"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Broker Tag <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Lister Tag</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-1">
                  <div className="dividerVertical"></div>
                </div>
                <div className="ant-col ant-col-xl-7">
                  <div className="textAreaWrap">
                    <div className="inputGroup">
                      <label>
                        Public Notes{" "}
                        <i className="da icon-info-fill-dark notesInfo"></i>
                      </label>
                      <TextArea></TextArea>
                    </div>
                    <div className="inputGroup">
                      <label>
                        Internal Notes{" "}
                        <i className="da icon-info-fill-dark notesInfo"></i>
                      </label>
                      <TextArea></TextArea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modalCustomFooter">
              <Button
                className="linkBtn cancelStyle2"
                onClick={editListingCancel}
              >
                CANCEL
              </Button>
              <Button className="ant-btn btnSave">CONTINUE</Button>
            </div>
          </Layout>

          <div className="mainHeading" id="bulkPlacementID">
            <div className="flx alignItemsCenter justifyContentBetween flx-1">
              <h2>BULK PLACEMENT</h2>
              <div className="flx alignItemsCenter">
                <label className="mr-10 mb0 linkGreen">Competitive</label>
                <Select placeholder="Select" className="selecrSmall mr0 competitiveDropdown" size="small">
                  <Option value="option1">Vivid</Option>
                  <Option value="option2">Ticket Network</Option>
                </Select>
              </div>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <div className="mb20">       
            <Row>
                <Col span={6} className="pt-10">
                <div className="bodyCheckbox checkboxListV">
                  <Checkbox>Hide all unselected Rows and Columns</Checkbox>
                  <Checkbox>Uncheck all cells with existing placements</Checkbox>
                </div>
                </Col>
                <Col span={12}>
                  <div className="bodyCheckbox">
                    <div className="flx alignItemsCenter">
                      <div className="mr-10"><Checkbox>Absolute ($)</Checkbox></div>
                      <div className="mr-10"><Checkbox className="checkbox">Relative (%)</Checkbox></div>
                      <div className="flx alignItemsCenter">
                        <label className="mr-10 mb0 textNoWrap text-primary">Set $</label>
                        <input placeholder="Amount" type="text" className="ant-input" />
                        <a href="#" className="color-blue ml-10">APPLY</a>
                      </div>
                    </div>
                    <Checkbox>Round off values to Zero decimals</Checkbox>
                  </div>
                </Col>
                <Col span={6} className="text-right right">
                    <a href="#" className="linkBtnStyle2">
                      CANCEL
                    </a>
                    <Button onClick={showCreateNewListingModal} className="btnStyle2">Create Listing</Button>
                  </Col>
                </Row>
              </div>         
            <div className="removeTblLRSpc">
              <div className="customTbl setPricingTbl bulkPlacementTblWrap">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th style={{ width: "40px" }}></th>
                      <th scope="col" className="text-left" style={{ minWidth: "" }} >
                        <div className="tblSort">
                          DATE/TIME<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "90px" }}
                      >
                        <div className="tblSort">
                          DAYS TO EVENT<i className="da sortIcon icon-sort"></i>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "180px" }}
                      >
                        <div className="flx alignItemsCenter">
                          <Checkbox className="mr-10"></Checkbox>
                          <div>
                            ORCHESTRA C<br />
                            <span>A, B, C, D, E, F, G, H, J, K</span>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "180px" }}
                      >
                       <div className="flx alignItemsCenter">
                        <Checkbox className="mr-10"></Checkbox>
                        <div>
                            ORCHESTRA D<br />
                            <span>N, P, Q, R, S, T, U, V, W...Z</span>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "180px" }}
                      >
                        <div className="flx alignItemsCenter">
                        <Checkbox className="mr-10"></Checkbox>
                        <div>
                          ORCHESTRA F<br />
                          <span>S, T, U, V, W, X</span>
                        </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "180px" }}
                      >
                        <div className="flx alignItemsCenter">
                        <Checkbox className="mr-10"></Checkbox>
                        <div>
                          MEZZANINE B<br />
                          <span>A, B, C, D, E, F, G, H, J</span>
                        </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ width: "180px" }}
                      >
                       <div className="flx alignItemsCenter">
                        <Checkbox className="mr-10"></Checkbox>
                        <div>
                          ORCHESTRA C<br />
                          <span>A, B, C, D, E, F, G, H, J, K</span>
                        </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="setPricingTblTopRow">
                      <td></td>
                      <td>Row</td>
                      <td></td>
                      <td>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </td>
                      <td>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </td>
                      <td>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </td>
                      <td>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </td>
                      <td>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM<i className="da icon-external-link pointer ml-5 icon-sm"></i>
                        <div className="linkGreen">20 listings on <a href="#" className="linkGreen">Vivid</a> (11/22) <i className="da icon-refresh-green icon-sm pointer ml-5"></i></div>
                      </td>
                      <td className="tblTextStyle1 text-right text-danger">
                        4
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody flx flexColumn haveFooter success">
                            <div className="flx alignItemsCenter w-100">
                              <div className="flx alignItemsCenter flx-1">
                                <Checkbox></Checkbox>
                                <i className="da icon-exclamation-warning pointer ml-10"></i>
                              </div>
                              <span className="InputNumWidth">
                              <InputNumber size="small" min={1} max={10} defaultValue={1} bordered={false} className="inputBorderBottom"/>
                              </span>
                            </div>
                            <div className="pricingCardInnerFooter flx alignItemsCenter justifyContentBetween">
                              <span>12 listings</span>
                              <span>$150-$275</span>
                              <i className="da icon-search-view pointer"></i>
                            </div>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <div className="flx alignItemsCenter"><span className="numberCircle mr-5">3</span> <span>$73.00</span></div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody danger noData"></div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody warning">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM
                      </td>
                      <td className="tblTextStyle1 text-right">84</td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default"></div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox></Checkbox>
                      </td>
                      <td>
                        Sat, Nov 27, 2021
                        <br />
                        02:00 PM
                      </td>
                      <td className="tblTextStyle1 text-right text-warning">
                        20
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody success">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody danger noData"></div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody success">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody warning">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pricingCard">
                          <div className="pricingCardBody default">
                            <Checkbox></Checkbox>
                            <span>$165.00</span>
                          </div>
                          <div className="pricingCardFooter">
                            <span>58/63</span>
                            <span>$73.00</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2 id="message">UPCOMING EVENTS</h2>
            </div>
            <div className="flx alignItemsCenter">
              <a href="#">
                <i className="da icon-listview listviewIcon"></i>
              </a>
            </div>
          </div>
          <Layout className="mainInnerLayout">
            <Content className="mainContainer">
              <div className="innerHeader">
                <Row>
                  <Col span={4}>
                    <h3 id="">Events</h3>
                  </Col>
                  <Col span={2}>
                    <Switch
                      checkedChildren={<i className="da icon-th-list"></i>}
                      unCheckedChildren={<i className="da icon-th-large"></i>}
                    />
                  </Col>
                  <Col span={6}>
                    <div className="search">
                      <input
                        type="search"
                        placeholder="Search event, venue, broker, city, state"
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <ul className="innerHeaderRight">
                      <li>
                        <h4>312 Events Found</h4>
                      </li>
                      <li>
                        <Pagination
                          className="pagination"
                          defaultCurrent={1}
                          total={50}
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="zonesGridView eventsGridView">
                <div className="listRow">
                  <div className="eventCardWrap">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                RESCHEDULED - Mary Chapin Carpenter
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <Dropdown overlay={messageMenu} trigger={["click"]}>
                              <button onClick={(e) => e.preventDefault()}>
                                <i className="da icon-comment"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive disabled">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap success">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by</span>
                        <i className="da icon-fictionalCharacter-red"></i>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">The Band’s Visit, Thursday</a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <Dropdown overlay={messageMenu} trigger={["click"]}>
                              <button onClick={(e) => e.preventDefault()}>
                                <i className="da icon-comment"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap warning">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">America</a>{" "}
                              <Tag className="success">New</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <Dropdown overlay={messageMenu} trigger={["click"]}>
                              <button onClick={(e) => e.preventDefault()}>
                                <i className="da icon-comment"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap danger">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                <b>RESCHEDULED - Mary Chapin Carpenter</b>
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <Dropdown overlay={messageMenu} trigger={["click"]}>
                              <button onClick={(e) => e.preventDefault()}>
                                <i className="da icon-comment"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">Sarah Brightman</a>{" "}
                              <Tag className="danger">Not on POS</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap success">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by</span>
                        <i className="da icon-fictionalCharacter-green"></i>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">A Tribute to Aretha</a>{" "}
                              <Tag className="danger">Not on POS</Tag>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap warning">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">America</a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                  <div className="eventCardWrap danger">
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by Beatriz J</span>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">
                                <b>RESCHEDULED - Mary Chapin Carpenter</b>
                              </a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner negative">
                            <span className="rankNumber">3</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
            <Button
              type="primary"
              className="ant-btn btnSave"
              onClick={showComposeNewMessageModal}
            >
              Compose New Message Modal
            </Button>
            <Modal
              closable={false}
              width={932}
              title={false}
              footer={false}
              visible={isModalVisible9}
              centered
              className=""
            >
              <div className="composeNewMessage composeNewMessageModal">
                <div className="composeLeft">
                  <h5>Compose New Message</h5>
                  <div className="eventCardWrap success">
                    <div className="seatAvailableLogicTopBar"></div>
                    <div className="eventCardContent">
                      <div className="lastUpdated">
                        <span>Last updated 21 days ago by</span>
                        <i className="da icon-fictionalCharacter-red"></i>
                      </div>
                      <div className="eventCardBody">
                        <div className="eventCardBodyInnerGrid">
                          <div>
                            <p>
                              <a href="#">A Tribute to Aretha</a>
                              <br />
                              Sat, Aug 28, 2021 at 7:30 PM
                            </p>
                            <p>
                              Van Wezel Performing Arts Hall
                              <br />
                              New York, NY, USA
                            </p>
                          </div>
                          <div className="actions">
                            <button>
                              <i className="da icon-th"></i>
                            </button>
                            <button>
                              <i className="da icon-pencil-o"></i>
                            </button>
                            <button>
                              <i className="da icon-comment"></i>
                            </button>
                          </div>
                        </div>
                        <div className="ranking">
                          <div className="rankingInner default">
                            <span className="rankNumber">84</span>
                            <a href="#">
                              Days <br />
                              to event
                            </a>
                          </div>
                          <div className="rankingInner default">
                            <span className="rankNumber">4</span>
                            <a href="#">
                              Active
                              <br />
                              Listings
                            </a>
                          </div>
                          <div className="rankingInner positive">
                            <span className="rankNumber">
                              <i className="da icon-chart-up-white"></i>
                            </span>
                            <a href="#">
                              Seating
                              <br />
                              Info
                            </a>
                          </div>
                          <div className="rankingInner primary-lite">
                            <span className="rankNumber">
                              <i className="da icon-add-layer"></i>
                            </span>
                            <a href="#">
                              New
                              <br />
                              Listing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="composeRight">
                  <h5>Compose New Message</h5>
                  <div className="inputGroup">
                    <label>Subject Title</label>
                    <input
                      placeholder="Subject"
                      type="text"
                      className="ant-input"
                    />
                  </div>
                  <div className="textareaWrap">
                    <textarea style={{}}></textarea>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn" onClick={composeNewMessageCancel}>
                  CLOSE
                </Button>
                <button type="button" className="ant-btn btnOk">
                  SAVE
                </button>
              </div>
            </Modal>
          </Layout>

          <h2>Compose New Message</h2>
          <Layout className="mainInnerLayout">
            <div className="composeNewMessage">
              <div className="composeLeft">
                <h5>Compose New Message</h5>
                <div className="eventCardStyle2">
                  <div className="title">
                    <a href="#">William H. Mortensen Hall</a>
                  </div>
                  <div className="cardBodyWrap">
                    <div className="location">
                      Sarasota, Florida, United States
                    </div>
                    <div className="lastUpdate">
                      <span>Sarasota, Florida, United States</span>{" "}
                      <i className="da icon-fictionalCharacter-red"></i>
                    </div>
                    <div className="ranking">
                      <div className="rankingInner default">
                        <span className="rankNumber">22</span>
                        <a href="#">Event Count</a>
                      </div>
                      <div className="rankingInner default">
                        <span className="rankNumber">5</span>
                        <a href="#">Unique Events</a>
                      </div>
                    </div>

                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-o"></i>
                        <span>Rose Parisi</span>
                      </div>
                    </div>
                    <div className="eventCardFooter">
                      <div>
                        <i className="da icon-user-role"></i>
                        <span>Beatriz J</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="composeRight">
                <h5>Compose New Message</h5>
                <div className="inputGroup">
                  <label>Subject Title</label>
                  <input
                    placeholder="Subject"
                    type="text"
                    className="ant-input"
                  />
                </div>
                <div className="textareaWrap">
                  <textarea style={{}}></textarea>
                </div>
              </div>
            </div>
          </Layout>

          <div className="mainHeading">
            <div>
              <h2 id="message">MESSAGES</h2>
            </div>
            <div className="flx alignItemsCenter">
              <button
                type="button"
                className="btnStyle2"
                onClick={showCreateMessageModal}
              >
                <i className="da icon-create-message"></i>New Message
              </button>
            </div>
          </div>
          <Modal
            closable={false}
            width={612}
            title={false}
            footer={false}
            visible={isModalVisible10}
            centered
            className=""
          >
            <div className="">
              <h3>Compose New Message</h3>
              <div className="">
                <div className="inputGroup">
                  <label>Identifier</label>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="select"
                    //defaultValue={['Option1']}
                  >
                    <Option value="option1" label="Option1">
                      Option1
                    </Option>
                    <Option value="option2" label="Option2">
                      Option2
                    </Option>
                    <Option value="option3" label="Option3">
                      Option3
                    </Option>
                  </Select>
                </div>
                <div className="inputGroup">
                  <label>Subject Title</label>
                  <input
                    placeholder="Title"
                    type="text"
                    className="ant-input"
                  />
                </div>
                <div className="inputGroup">
                  <label>Subject Title</label>
                  <textarea style={{ minHeight: "230px" }}></textarea>
                </div>
              </div>
            </div>
            <div className="modalCustomFooter">
              <Button className="linkBtn" onClick={composeCreateMessageCancel}>
                CANCEL
              </Button>
              <button type="button" className="ant-btn btnOk">
                SEND
              </button>
            </div>
          </Modal>

          <Layout className="mainInnerLayout">
            <div className="innerHeader">
              <Row>
                <Col span={6}>
                  <h3 id="inventoryReportTables">Inbox</h3>
                </Col>
                <Col span={6}>
                  <div className="search">
                    <input
                      type="search"
                      placeholder="Search event, venue, broker, city, state"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>312 Listings Found</h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        defaultCurrent={1}
                        total={50}
                      />
                    </li>
                    <li>
                      <button type="button" className="flx">
                        <i
                          className="da icon-sorting"
                          style={{ width: "17px", height: "17px" }}
                        ></i>
                      </button>
                    </li>
                    <li>
                      <button className="flx">
                        <i
                          className="da icon-listview"
                          style={{ width: "20px", height: "17px" }}
                        ></i>
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div className="removeTblLRSpc">
              <div className="inboxContainer">
                <div className="inboxSmSide">
                  <ul className="inboxSideList">
                    <li>
                      <div className="itemBookmark">
                        <i className="da icon-bookmark-outlile"></i>
                      </div>
                      <div className="itemContent">
                        <div className="itemContentTop">
                          <div className="itemContentTopLeft">
                            <div className="userHead">
                              <span className="inboxAvatar">
                                <img src={inboxAvatar} alt="" title="" />
                              </span>
                              <span className="inboxUserName">
                                Thomas Gucciardo
                              </span>
                            </div>
                            <div className="inboxMsgTitle">
                              Confirm Rescheduled Event Date
                            </div>
                          </div>
                          <div className="itemContentTopRight">
                            <div className="invoiceNo">
                              <a href="#">Invoice# 45721456</a>
                            </div>
                            <div>Tue, Aug 31, 2021 at 3:14 PM</div>
                            <div>16 minutes ago</div>
                          </div>
                        </div>
                        <div className="msgSortDescWrap">
                          Hi Rose Parisi,
                          <br />
                          <div className="msgSortDesc">
                            Could you help me double check availability for this
                            event, especially for
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemBookmark">
                        <i className="da icon-bookmark-outlile"></i>
                      </div>
                      <div className="itemContent">
                        <div className="itemContentTop">
                          <div className="itemContentTopLeft">
                            <div className="userHead">
                              <span className="inboxAvatar">
                                <img src={inboxAvatar} alt="" title="" />
                              </span>
                              <span className="inboxUserName">
                                Thomas Gucciardo
                              </span>
                            </div>
                            <div className="inboxMsgTitle">
                              Confirm Rescheduled Event Date
                            </div>
                          </div>
                          <div className="itemContentTopRight">
                            <div className="invoiceNo">
                              <a href="#">Invoice# 45721456</a>
                            </div>
                            <div>Tue, Aug 31, 2021 at 3:14 PM</div>
                            <div>16 minutes ago</div>
                          </div>
                        </div>
                        <div className="msgSortDescWrap">
                          Hi Rose Parisi,
                          <br />
                          <div className="msgSortDesc">
                            Could you help me double check availability for this
                            event, especially for
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="selected">
                      <div className="itemBookmark">
                        <i className="da icon-bookmark-outlile"></i>
                      </div>
                      <div className="itemContent">
                        <div className="itemContentTop">
                          <div className="itemContentTopLeft">
                            <div className="userHead">
                              <span className="inboxAvatar">
                                <img src={inboxAvatar} alt="" title="" />
                              </span>
                              <span className="inboxUserName">
                                Thomas Gucciardo
                              </span>
                            </div>
                            <div className="inboxMsgTitle">
                              Confirm Rescheduled Event Date
                            </div>
                          </div>
                          <div className="itemContentTopRight">
                            <div className="invoiceNo">
                              <a href="#">Invoice# 45721456</a>
                            </div>
                            <div>Tue, Aug 31, 2021 at 3:14 PM</div>
                            <div>16 minutes ago</div>
                          </div>
                        </div>
                        <div className="msgSortDescWrap">
                          Hi Rose Parisi,
                          <br />
                          <div className="msgSortDesc">
                            Could you help me double check availability for this
                            event, especially for
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemBookmark">
                        <i className="da icon-bookmark-outlile"></i>
                      </div>
                      <div className="itemContent">
                        <div className="itemContentTop">
                          <div className="itemContentTopLeft">
                            <div className="userHead">
                              <span className="inboxAvatar">
                                <img src={inboxAvatar} alt="" title="" />
                              </span>
                              <span className="inboxUserName">
                                Thomas Gucciardo
                              </span>
                            </div>
                            <div className="inboxMsgTitle">
                              Confirm Rescheduled Event Date
                            </div>
                          </div>
                          <div className="itemContentTopRight">
                            <div className="invoiceNo">
                              <a href="#">Invoice# 45721456</a>
                            </div>
                            <div>Tue, Aug 31, 2021 at 3:14 PM</div>
                            <div>16 minutes ago</div>
                          </div>
                        </div>
                        <div className="msgSortDescWrap">
                          Hi Rose Parisi,
                          <br />
                          <div className="msgSortDesc">
                            Could you help me double check availability for this
                            event, especially for
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemBookmark">
                        <i className="da icon-bookmark-outlile"></i>
                      </div>
                      <div className="itemContent">
                        <div className="itemContentTop">
                          <div className="itemContentTopLeft">
                            <div className="userHead">
                              <span className="inboxAvatar">
                                <img src={inboxAvatar} alt="" title="" />
                              </span>
                              <span className="inboxUserName">
                                Thomas Gucciardo
                              </span>
                            </div>
                            <div className="inboxMsgTitle">
                              Confirm Rescheduled Event Date
                            </div>
                          </div>
                          <div className="itemContentTopRight">
                            <div className="invoiceNo">
                              <a href="#">Invoice# 45721456</a>
                            </div>
                            <div>Tue, Aug 31, 2021 at 3:14 PM</div>
                            <div>16 minutes ago</div>
                          </div>
                        </div>
                        <div className="msgSortDescWrap">
                          Hi Rose Parisi,
                          <br />
                          <div className="msgSortDesc">
                            Could you help me double check availability for this
                            event, especially for
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="inboxLgSide">
                  <div className="inboxHead">
                    <div className="inboxHeadLeft">
                      <div className="inboxHeadTitle">
                        CC Reconciliation - Sep 2021
                      </div>
                      <div>
                        Thomas Gucciardo, Rose Parisi, Beatriz J, Doug Russo,
                        Katherine Torres, Ed Cooper
                      </div>
                    </div>
                    <div className="inboxHeadRight">
                      <div className="invoiceNo">
                        <a href="#">Invoice# 45721456</a>
                      </div>
                      <Dropdown
                        overlay={inboxActionDropdown}
                        trigger={["click"]}
                      >
                        <span onClick={(e) => e.preventDefault()}>
                          <i className="da icon-kebab-v"></i>
                        </span>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="inboxRightSideContArea">
                    <ul className="inboxRightList">
                      <li className="">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="open">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="inboxBodyContainer">
                          <div className="inboxBodyLeft">
                            <div className="eventCardStyle2 cardSmll">
                              <div className="title">
                                <a href="#">William H. Mortensen Hall</a>
                              </div>
                              <div className="cardBodyWrap">
                                <div className="location">
                                  Sarasota, Florida, United States
                                </div>
                                <div className="lastUpdate">
                                  <span>Sarasota, Florida, United States</span>{" "}
                                  <i className="da icon-fictionalCharacter-red"></i>
                                </div>
                                <div className="ranking">
                                  <div className="rankingInner default">
                                    <span className="rankNumber">22</span>
                                    <a href="#">Event Count</a>
                                  </div>
                                  <div className="rankingInner default">
                                    <span className="rankNumber">5</span>
                                    <div>Unique Events</div>
                                  </div>
                                </div>

                                <div className="eventCardFooter">
                                  <div>
                                    <i className="da icon-user-o"></i>
                                    <span>Rose Parisi</span>
                                  </div>
                                </div>
                                <div className="eventCardFooter">
                                  <div>
                                    <i className="da icon-user-role"></i>
                                    <span>Beatriz J</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inboxBodyRight">
                            <div className="inboxBodyRightTopCont">
                              <p>Hi Beatriz,</p>
                              <p>
                                I have assigned this event to you. Can you:
                                Update seat and pricing information for the
                                event.
                              </p>
                              <p>
                                Let me know once done and we can then identify
                                which events to create listings for.
                              </p>
                            </div>
                            <div className="inboxFooter">
                              <div className="left">
                                <p>
                                  <a href="#">
                                    <i className="da icon-pdf"></i>
                                    <span>
                                      WilliamHMortensenHall_Event_1.pdf
                                    </span>
                                  </a>
                                </p>
                                <p>
                                  <a href="#">
                                    <i className="da icon-pdf"></i>
                                    <span>
                                      WilliamHMortensenHall_Event_2.pdf
                                    </span>
                                  </a>
                                </p>
                              </div>
                              <div className="right">
                                <a href="#" className="replyLink">
                                  <i className="da icon-reply"></i>REPLY
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="open">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="inboxBodyContainer">
                          <div className="inboxBodyLeft">
                            <div className="eventCardWrap success">
                              <div className="eventCardContent">
                                <div className="lastUpdated">
                                  <span>Last updated 21 days ago by</span>
                                  <i className="da icon-fictionalCharacter-green"></i>
                                </div>
                                <div className="eventCardBody">
                                  <div className="eventCardBodyInnerGrid">
                                    <div>
                                      <p>
                                        <a href="#">A Tribute to Aretha</a>
                                        <br />
                                        Sat, Aug 28, 2021 at 7:30 PM
                                      </p>
                                      <p>
                                        Van Wezel Performing Arts Hall
                                        <br />
                                        New York, NY, USA
                                      </p>
                                    </div>
                                  </div>
                                  <div className="ranking">
                                    <div className="rankingInner default">
                                      <span className="rankNumber">3</span>
                                      <div>
                                        Days <br />
                                        to event
                                      </div>
                                    </div>
                                    <div className="rankingInner default">
                                      <span className="rankNumber">4</span>
                                      <a href="#">
                                        Active
                                        <br />
                                        Listings
                                      </a>
                                    </div>
                                    <div className="rankingInner positive">
                                      <span className="rankNumber">
                                        <i className="da icon-chart-up-white"></i>
                                      </span>
                                      <a href="#">
                                        Seating
                                        <br />
                                        Info
                                      </a>
                                    </div>
                                    <div className="rankingInner primary-lite">
                                      <span className="rankNumber">
                                        <i className="da icon-add-layer"></i>
                                      </span>
                                      <a href="#">
                                        New
                                        <br />
                                        Listing
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="eventCardFooter">
                                <div>
                                  <i className="da icon-user-o"></i>
                                  <span>Rose Parisi</span>
                                </div>
                                <div>
                                  <i className="da icon-user-role"></i>
                                  <span>Beatriz J</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inboxBodyRight">
                            <div className="inboxBodyRightTopCont">
                              <p>Hi Beatriz,</p>
                              <p>
                                I have assigned this event to you. Can you:
                                Update seat and pricing information for the
                                event.
                              </p>
                              <p>
                                Let me know once done and we can then identify
                                which events to create listings for.
                              </p>
                            </div>
                            <div className="inboxFooter">
                              <div className="left">
                                <p>
                                  <a href="#">
                                    <i className="da icon-pdf"></i>
                                    <span>TheBandsVisitThursday_Info.pdf</span>
                                  </a>
                                </p>
                              </div>
                              <div className="right">
                                <a href="#" className="replyLink">
                                  <i className="da icon-reply"></i>REPLY
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="open">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="inboxBodyContainer">
                          <div className="inboxBodyLeft">
                            <div className="eventCardStyle2">
                              <div className="title">
                                <a href="#">Invoice# 45721456</a>
                                <span>Tue, Aug 17, 2021</span>
                              </div>
                              <div className="cardBodyWrap">
                                <div>
                                  <b>Mary Chapin Carpenter</b>
                                  <br />
                                  Sat, Aug 28, 2021 at 7:30 PM
                                </div>
                                <div className="location">
                                  Van Wezel Performing Arts Hall
                                  <br />
                                  New York, NY, USA
                                </div>
                                <div className="innerCardTbl">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Qty</th>
                                        <th>
                                          Unit
                                          <br />
                                          Cost
                                        </th>
                                        <th>Cost</th>
                                        <th>
                                          Selling
                                          <br />
                                          Price
                                        </th>
                                        <th>
                                          Sale
                                          <br />
                                          Value
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>18</td>
                                        <td>$140.00</td>
                                        <td>$140.00</td>
                                        <td>$2,520.00</td>
                                        <td>$450.00</td>
                                      </tr>
                                      <tr>
                                        <td>18</td>
                                        <td>$140.00</td>
                                        <td>$140.00</td>
                                        <td>$2,520.00</td>
                                        <td>$450.00</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cardBtmStyle1">
                                  <div className="left">
                                    <div>Invoice Tag</div>
                                    <Tag className="info-outline">
                                      Corporate
                                    </Tag>
                                    <div>Inventory Tag</div>
                                    <Tag className="info-outline">
                                      tb_delivered
                                    </Tag>
                                  </div>
                                  <div className="right">
                                    <div className="consignmentBtn">
                                      <span>Consignment</span>
                                      <i className="da icon-checked-circle"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inboxBodyRight">
                            <div className="inboxBodyRightTopCont">
                              <p>Hi Beatriz,</p>
                              <p>
                                I have assigned this event to you. Can you:
                                Update seat and pricing information for the
                                event.
                              </p>
                              <p>
                                Let me know once done and we can then identify
                                which events to create listings for.
                              </p>
                            </div>
                            <div className="inboxFooter">
                              <div className="left">
                                <p>
                                  <a href="#">
                                    <i className="da icon-pdf"></i>
                                    <span>PO_432535.pdf</span>
                                  </a>
                                </p>
                              </div>
                              <div className="right">
                                <a href="#" className="replyLink">
                                  <i className="da icon-reply"></i>REPLY
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="open">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="inboxBodyContainer">
                          <div className="inboxBodyLeft">
                            <div className="eventCardWrap activeListing2 ">
                              <div className="eventCardContent">
                                <div className="ribbon danger">
                                  <a href="#" className="poNo">
                                    PO# 45721456
                                  </a>
                                  <span>5% 5/32 seats left</span>
                                </div>
                                <div>
                                  <b>Mary Chapin Carpenter</b>
                                  <br />
                                  Sat, Aug 28, 2021 at 7:30 PM
                                </div>
                                <div className="location">
                                  Van Wezel Performing Arts Hall
                                  <br />
                                  New York, NY, USA
                                </div>
                                <div className="zoneSeatingWrap">
                                  <div>
                                    <h2>$65.00</h2>
                                    <div className="expCost">
                                      <p>EXP COST: $140</p>
                                      <p className="text-danger">
                                        List Price less than Cost
                                      </p>
                                    </div>
                                  </div>
                                  <div className="zoneSeating">
                                    <span>
                                      Zone
                                      <br />
                                      Seating
                                    </span>
                                    <i className="da icon-checked-circle"></i>
                                  </div>
                                </div>
                                <div className="eventCardBody">
                                  <div className="ranking">
                                    <div className="rankingInner yellow">
                                      <span className="rankNumber">18</span>
                                      <div>Qty</div>
                                    </div>
                                    <div className="rankingInner yellow">
                                      <span className="rankNumber">
                                        Left Rear
                                      </span>
                                      <div>Section</div>
                                    </div>
                                    <div className="rankingInner yellow">
                                      <span className="rankNumber">29</span>
                                      <div>Row(s)</div>
                                    </div>
                                    <div className="rankingInner yellow">
                                      <span className="rankNumber">
                                        99 - 106
                                      </span>
                                      <div>Seats</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="eventCardFooter">
                                  <div>
                                    <i className="da icon-user-o"></i>
                                    <span>Thomas Gucciardo</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inboxBodyRight">
                            <div className="inboxBodyRightTopCont">
                              <p>Hi Beatriz,</p>
                              <p>
                                I have assigned this event to you. Can you:
                                Update seat and pricing information for the
                                event.
                              </p>
                              <p>
                                Let me know once done and we can then identify
                                which events to create listings for.
                              </p>
                            </div>
                            <div className="inboxFooter">
                              <div className="left">
                                <p>
                                  <a href="#">
                                    <i className="da icon-pdf"></i>
                                    <span>PO_432535.pdf</span>
                                  </a>
                                </p>
                              </div>
                              <div className="right">
                                <a href="#" className="replyLink">
                                  <i className="da icon-reply"></i>REPLY
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="replyPanel">
                          Reply to message (Developer can manage)
                        </div>
                      </li>
                      <li className="">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="listWrap">
                          <div className="itemBookmark">
                            <i className="da icon-bookmark-outlile"></i>
                          </div>
                          <div className="itemContent">
                            <div className="itemContentTop">
                              <div className="itemContentTopLeft">
                                <div className="userHead">
                                  <span className="inboxAvatar">
                                    <img src={inboxAvatar2} alt="" title="" />
                                  </span>
                                  <div className="inboxuserHeadContWrap">
                                    <div className="inboxUserDateTime">
                                      <span className="inboxUserName">
                                        Thomas Gucciardo
                                      </span>
                                      <span className="emailDateTime">
                                        Tue, Aug 31, 2021 at 3:14 PM
                                      </span>
                                    </div>
                                    <div className="msgSortDesc">
                                      Hi Rose Parisi, Could you help me double
                                      check availability for this event,
                                      especially for sections ORCHESTRA
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemContentTopRight">
                                <div className="fontBold">
                                  Tue, Aug 31, 2021 at 3:14 PM
                                </div>
                                <div>(15 hours ago)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Layout>

          <h2 id="emailTempReportSchedule">Email Template Report Schedule</h2>
          <Layout className="mainInnerLayout">
            <div
              className="emailTempReportSchedule"
              style={{
                width: "520px",
                margin: "0 auto",
                padding: "36px",
                backgroundColor: "#F8F9FB",
                borderRadius: "10px",
              }}
            >
              <table>
                <tr>
                  <td
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "1rem",
                      color: "#4171B9",
                      fontWeight: "bold",
                    }}
                  >
                    A new schedule has been saved!
                  </td>
                </tr>
                <tr>
                  <td style={{ height: "30px" }}></td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontFamily: "Roboto",
                      fontSize: ".75rem",
                      color: "#4171B9",
                      fontWeight: "normal",
                    }}
                  >
                    A new schedule has been generated in TicketBash in{" "}
                    <span style={{ fontWeight: "bold" }}>Report Schedules</span>
                    . To view the scheduled report, see the document attached
                    below.
                  </td>
                </tr>
                <tr>
                  <td style={{ height: "24px" }}></td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "0.625rem",
                      color: "#4171B9",
                      fontWeight: "normal",
                    }}
                  >
                    TicketBash Support
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "0.625rem",
                      color: "#4171B9",
                      fontWeight: "normal",
                    }}
                  >
                    https://ticketbash.com/
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>
                    <a href="#" className="logo">
                      <img
                        src="https://storage.googleapis.com/tikcketbash_images/email-logo.png"
                        alt=""
                        title="Ticket Bash"
                      />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </Layout>

          <h2 id="error404Page">ERROR</h2>
          <Layout className="mainInnerLayout">
            <h3>404 Error</h3>
            <div className="errorContent">
              <div className="errorContentInner">
                <span className="error-img"></span>
                <div>
                  <h3>Something's wrong.</h3>
                  <p>
                    You’ve landed on a 404 error. This could mean that you’ve
                    clicked on a bad link, or entered an invalid URL.
                  </p>

                  <p>Try going back to the previous page.</p>
                </div>
              </div>
            </div>
          </Layout>

          <div className="mainHeading">
            <Row className="flx-1">
              <Col span={6}>
                <h2 id="cancelInventory">ACTIVE INVENTORY</h2>
              </Col>
              <Col span={6}>
                <div className="search">
                  <input type="search" placeholder="Search" value="" />
                </div>
              </Col>
              <Col span={12}>
                <div className="cancelInventoryRight">
                  <Button
                    className="btnSecondary"
                    onClick={showCancelInventoryModal}
                  >
                    Cancel Inventory
                  </Button>
                  <a href="#">
                    <i className="da icon-listview listviewIcon"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <Layout className="mainInnerLayout pb-0 cancelInventory">
            <Content className="mainContainer">
              <div className="innerHeader">
                <ul className="innerHeaderRight">
                  <li>
                    <h4>548 Records Found</h4>
                  </li>
                  <li>
                    <Pagination
                      className="pagination"
                      defaultCurrent={1}
                      total={50}
                    />
                  </li>
                </ul>
              </div>
              <div className="tabContainer removeTblLRSpc">
                <ul className="tabsNav">
                  <li className="active">
                    <a href="#">Active Inventory</a>
                  </li>
                  <li>
                    <a href="#">Sold Inventory</a>
                  </li>
                  <li>
                    <a href="#">Cancelled Inventory</a>
                  </li>
                </ul>
                <div
                  className="customTbl scroll-y scroll-x fixedHeaderFooter"
                  style={{ maxHeight: "calc(100vh - 220px)" }}
                >
                  <table className="main-table fixedHeaderFooter">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            PO DATE
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right">
                          <div className="tblHeadColWrap">
                            PO NUM
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right">
                          <div className="tblHeadColWrap">
                            PO LINE ID
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            BROKER
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            PO TAG
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right">
                          <div className="tblHeadColWrap">
                            EVENT ID
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left evntNameCol">
                          <div className="tblHeadColWrap">
                            EVENT NAME
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            BUYER
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            EVENT DATE &amp; TIME
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left evuNameCol">
                          <div className="tblHeadColWrap">
                            VENUE NAME
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left sectionCol">
                          <div className="tblHeadColWrap">
                            SECTION
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            ROW
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right">
                          <div className="tblHeadColWrap">
                            SEATS
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right">
                          <div className="tblHeadColWrap">
                            QTY
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            CUR
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right txtBreak">
                          <div className="tblHeadColWrap">
                            UNIT COST
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right txtBreak">
                          <div className="tblHeadColWrap">
                            TOTAL COST
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-right txtBreak">
                          <div className="tblHeadColWrap">
                            OUTSTANDING BALANCE
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            INTERNAL NOTES
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            LAST PRICE UPDATE
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left txtBreak">
                          <div className="tblHeadColWrap">
                            DAYS OLD
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left txtBreak">
                          <div className="tblHeadColWrap">
                            PUR. ITEM
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left txtBreak">
                          <div className="tblHeadColWrap">
                            PAYMENT METHOD
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left">
                          <div className="tblHeadColWrap">
                            CREDIT CARD GRP
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                        <th className="text-left creditCardCol">
                          <div className="tblHeadColWrap">
                            CREDIT CARD
                            <i className="da sortIcon icon-sort" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Checkbox disabled checked></Checkbox>
                        </td>
                        <td>
                          <strong>08-30-2021</strong>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>no broker</td>
                        <td>doug</td>
                        <td>
                          <div className="text-right">
                            <a href="#">3653172</a>
                          </div>
                        </td>
                        <td>US Open Tennis Parking</td>
                        <td>danielle@ticketbash.com</td>
                        <td>09-01-2021, 07:01 PM</td>
                        <td>Arthur Ashe Stadium Parking</td>
                        <td>PARKING LOT H</td>
                        <td>PARKING LOT H</td>
                        <td>
                          <div className="text-right">99</div>
                        </td>
                        <td>
                          <div className="text-right">8</div>
                        </td>
                        <td>USD</td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>myown</td>
                        <td>08-30-2021, 11:19 AM</td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>NET10</td>
                        <td>CREDITCARD</td>
                        <td>N/A</td>
                        <td>83986-AMEX-Vin Grillo-3057</td>
                      </tr>
                      <tr className="blueSelected">
                        <td>
                          <Checkbox></Checkbox>
                        </td>
                        <td>
                          <strong>08-30-2021</strong>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>no broker</td>
                        <td>doug</td>
                        <td>
                          <div className="text-right">
                            <a href="#">3653172</a>
                          </div>
                        </td>
                        <td>US Open Tennis Parking</td>
                        <td>danielle@ticketbash.com</td>
                        <td>09-01-2021, 07:01 PM</td>
                        <td>Arthur Ashe Stadium Parking</td>
                        <td>PARKING LOT H</td>
                        <td>PARKING LOT H</td>
                        <td>
                          <div className="text-right">99</div>
                        </td>
                        <td>
                          <div className="text-right">8</div>
                        </td>
                        <td>USD</td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>myown</td>
                        <td>08-30-2021, 11:19 AM</td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>NET10</td>
                        <td>CREDITCARD</td>
                        <td>N/A</td>
                        <td>83986-AMEX-Vin Grillo-3057</td>
                      </tr>
                      <tr>
                        <td>
                          <Checkbox></Checkbox>
                        </td>
                        <td>
                          <strong>08-30-2021</strong>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>no broker</td>
                        <td>doug</td>
                        <td>
                          <div className="text-right">
                            <a href="#">3653172</a>
                          </div>
                        </td>
                        <td>US Open Tennis Parking</td>
                        <td>danielle@ticketbash.com</td>
                        <td>09-01-2021, 07:01 PM</td>
                        <td>Arthur Ashe Stadium Parking</td>
                        <td>PARKING LOT H</td>
                        <td>PARKING LOT H</td>
                        <td>
                          <div className="text-right">99</div>
                        </td>
                        <td>
                          <div className="text-right">8</div>
                        </td>
                        <td>USD</td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>myown</td>
                        <td>08-30-2021, 11:19 AM</td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>NET10</td>
                        <td>CREDITCARD</td>
                        <td>N/A</td>
                        <td>83986-AMEX-Vin Grillo-3057</td>
                      </tr>
                      <tr>
                        <td>
                          <Checkbox></Checkbox>
                        </td>
                        <td>
                          <strong>08-30-2021</strong>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">
                            <a href="#">64544202</a>
                          </div>
                        </td>
                        <td>no broker</td>
                        <td>doug</td>
                        <td>
                          <div className="text-right">
                            <a href="#">3653172</a>
                          </div>
                        </td>
                        <td>US Open Tennis Parking</td>
                        <td>danielle@ticketbash.com</td>
                        <td>09-01-2021, 07:01 PM</td>
                        <td>Arthur Ashe Stadium Parking</td>
                        <td>PARKING LOT H</td>
                        <td>PARKING LOT H</td>
                        <td>
                          <div className="text-right">99</div>
                        </td>
                        <td>
                          <div className="text-right">8</div>
                        </td>
                        <td>USD</td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>
                          <div className="text-right">0.00</div>
                        </td>
                        <td>myown</td>
                        <td>08-30-2021, 11:19 AM</td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>NET10</td>
                        <td>CREDITCARD</td>
                        <td>N/A</td>
                        <td>83986-AMEX-Vin Grillo-3057</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Content>
          </Layout>

          <Modal
            className="cancelInventModal"
            closable={false}
            width={390}
            title={false}
            footer={false}
            visible={isModalVisible11}
          >
            <div className="modalHeader">
              Are you sure you want to remove the selected inventories?
            </div>
            <div className="delMessageBody">
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
              <p>PO NUM: 64544202</p>
            </div>
            <div className="smlErrorTxt">This action cannot be undone.</div>
            <div className="modalCustomFooter">
              <a
                href="#"
                className="discardBtn"
                onClick={cancelInventoryModalCancel}
              >
                CANCEL
              </a>
              <Button className="btnOk">CONFIRM CANCELLATION</Button>
            </div>
          </Modal>

          <div className="mainHeading">
            <Row className="flx-1">
              <Col span={6}>
                <h2 id="cancelInventory">SOLD INVENTORY</h2>
              </Col>
              <Col span={6}>
                <div className="search">
                  <input type="search" placeholder="Search" value="" />
                </div>
              </Col>
              <Col span={12}>
                <div className="cancelInventoryRight">
                  <a href="#">
                    <i className="da icon-listview listviewIcon"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <Layout className="mainInnerLayout pb-0 cancelInventory">
            <Content className="mainContainer">
              <div className="innerHeader">
                <ul className="innerHeaderRight">
                  <li>
                    <h4>548 Records Found</h4>
                  </li>
                  <li>
                    <Pagination
                      className="pagination"
                      defaultCurrent={1}
                      total={50}
                    />
                  </li>
                </ul>
              </div>
              <div className="tabContainer removeTblLRSpc">
                <ul className="tabsNav">
                  <li>
                    <a href="#">Active Inventory</a>
                  </li>
                  <li className="active">
                    <a href="#">Sold Inventory</a>
                  </li>
                  <li>
                    <a href="#">Cancelled Inventory</a>
                  </li>
                </ul>
                <div className="">
                  <div
                    className="customTbl scroll-y scroll-x fixedHeaderFooter"
                    style={{ maxHeight: "calc(100vh - 220px)" }}
                  >
                    <table className="main-table fixedHeaderFooter">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-left">
                            <div className="tblHeadColWrap">
                              INVOICE ID
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              INVOICE LINE DATE
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              PURCHASE DATE
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              QTY
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              CURRENCY
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              TICKET COST
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              TICKET SALES
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              P &amp; L (API)
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              P &amp; L (API) &amp; TIME
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                          <th className="text-right">
                            <div className="tblHeadColWrap">
                              P &amp; L (API)
                              <i className="da sortIcon icon-sort" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="cancelInvoice">
                              <a href="#" onClick={showCancelInvoiceModal}>
                                Cancel Invoice
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">
                              <a href="#">64544202</a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">8</div>
                          </td>
                          <td>
                            <div className="text-right">USD</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                        </tr>
                        <tr className="blueSelected">
                          <td>
                            <div className="cancelInvoice">
                              <a href="#" onClick={showCancelInvoiceModal}>
                                Cancel Invoice
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">
                              <a href="#">64544202</a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">8</div>
                          </td>
                          <td>
                            <div className="text-right">USD</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="cancelInvoice">
                              <a href="#" onClick={showCancelInvoiceModal}>
                                Cancel Invoice
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">
                              <a href="#">64544202</a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">8</div>
                          </td>
                          <td>
                            <div className="text-right">USD</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="cancelInvoice">
                              <a href="#" onClick={showCancelInvoiceModal}>
                                Cancel Invoice
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">
                              <a href="#">64544202</a>
                            </div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">12-22-2021</div>
                          </td>
                          <td>
                            <div className="text-right">8</div>
                          </td>
                          <td>
                            <div className="text-right">USD</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                          <td>
                            <div className="text-right">0.00</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
          <Modal
            className="cancelInvoicePurchaseOrderModal"
            closable={false}
            width={1008}
            title={false}
            footer={false}
            visible={isModalVisible12}
          >
            <div className="modalCustomBody">
              <h3>Cancel Invoice &amp; Purchase Order</h3>
              <div className="inputGroup">
                <label>Invoice No: 43782396</label>
                <div
                  className="customTbl scroll-y scroll-x fixedHeaderFooter"
                  style={{ maxHeight: "calc(100vh - 220px)" }}
                >
                  <table className="main-table fixedHeaderFooter">
                    <thead>
                      <tr>
                        <th className="text-left">EVENT</th>
                        <th className="text-right lowSeatCol">LOW SEAT</th>
                        <th className="text-right highSeatCol">HIGH SEAT</th>
                        <th className="text-right unitConstCol">UNIT COST</th>
                        <th className="text-right totalCostCol">TOTAL COST</th>
                        <th className="text-right qtyCol">QTY</th>
                        <th className="text-right unitSaleCol">UNIT SALE</th>
                        <th className="text-right totalSaleCol">TOTAL SALE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <a className="eventNameVenue" href="#">
                              <strong>A Christmas Carol - Cacelled</strong>
                            </a>
                            <br />
                            Sat, Aug 28, 2021
                            <br />
                            07:30 PM
                          </div>
                        </td>
                        <td>
                          <div className="text-right">31</div>
                        </td>
                        <td>
                          <div className="text-right">32</div>
                        </td>
                        <td>
                          <div className="text-right">$186.55</div>
                        </td>
                        <td>
                          <div className="text-right">$373.10</div>
                        </td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>
                          <div className="text-right">$190.00</div>
                        </td>
                        <td>
                          <div className="text-right">$380.00</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="inputGroup">
                <label>Invoice Credit Note</label>
                <div
                  className="customTbl scroll-y scroll-x fixedHeaderFooter"
                  style={{ maxHeight: "calc(100vh - 220px)" }}
                >
                  <table className="main-table fixedHeaderFooter">
                    <thead>
                      <tr>
                        <th className="text-left">INVOICE ID</th>
                        <th className="text-left">INVOICE LINE ID</th>
                        <th className="text-left descCol">DESCRIPTION</th>
                        <th className="text-right qtyCol">QTY</th>
                        <th className="text-right">UNIT COST</th>
                        <th className="text-right">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <a className="eventNameVenue" href="#">
                              <strong>43782396</strong>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div>
                            <a className="eventNameVenue" href="#">
                              <strong>43782396</strong>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <TextArea className="desc-textarea">asas</TextArea>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>
                          <div className="text-right">- $190.00</div>
                        </td>
                        <td>
                          <div className="text-right">- $380.00</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="inputGroup">
                <label>PO Credit Note</label>
                <div
                  className="customTbl scroll-y scroll-x fixedHeaderFooter"
                  style={{ maxHeight: "calc(100vh - 220px)" }}
                >
                  <table className="main-table fixedHeaderFooter">
                    <thead>
                      <tr>
                        <th className="text-left">PO#</th>
                        <th className="text-left">PURCHASE LINE ID</th>
                        <th className="text-left descCol">DESCRIPTION</th>
                        <th className="text-right qtyCol">QTY</th>
                        <th className="text-right">UNIT COST</th>
                        <th className="text-right">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <a className="eventNameVenue" href="#">
                              <strong>43782396</strong>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div>
                            <a className="eventNameVenue" href="#">
                              <strong>43782396</strong>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <TextArea className="desc-textarea">asas</TextArea>
                          </div>
                        </td>
                        <td>
                          <div className="text-right">2</div>
                        </td>
                        <td>
                          <div className="text-right">- $186.55</div>
                        </td>
                        <td>
                          <div className="text-right">- $373.10</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modalCustomFooter">
              <a
                href="#"
                className="discardBtn"
                onClick={cancelInvoiceModalCancel}
              >
                CANCEL
              </a>
              <Button className="btnOk">CONFIRM CANCELLATION</Button>
            </div>
          </Modal>

          <h2 id="commissions">COMMISSIONS</h2>
          <Layout className="mainInnerLayout">
            <h3>Commissions</h3>
            <div className="">
              <div className="ant-row">
                <div className="ant-col ant-col-xl-15">
                  <div className="customTbl">
                    <table className="main-table dragableTbl">
                      <thead>
                        <tr>
                          <th style={{ width: "46%" }}>BROKER TAG</th>
                          <th>COMMISSION</th>
                          <th style={{ width: "100px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Lister</Option>
                              <Option value="option2">Broker</Option>
                              <Option value="option3">Buyer</Option>
                            </Select>
                          </td>
                          <td>
                            <Input
                              placeholder="Commission"
                              suffix="%"
                              defaultValue=""
                            />
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Lister</Option>
                              <Option value="option2">Broker</Option>
                              <Option value="option3">Buyer</Option>
                            </Select>
                          </td>
                          <td>
                            <Input
                              placeholder="Commission"
                              suffix="%"
                              defaultValue=""
                            />
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Select placeholder="Select" allowClear>
                              <Option value="option1">Lister</Option>
                              <Option value="option2">Broker</Option>
                              <Option value="option3">Buyer</Option>
                            </Select>
                          </td>
                          <td>
                            <Input
                              placeholder="Commission"
                              suffix="%"
                              defaultValue=""
                            />
                          </td>
                          <td>
                            <div className="tblActions text-center">
                              <a href="#">
                                <i className="da icon-trash-o"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="addRow">
                    <a href="#">NEW ROW</a>
                  </div>
                  <div className="text-right btnGroupWrap">
                    <button type="button" className="cancelStyle2">
                      CANCEL
                    </button>
                    <button type="button" className="ant-btn btnSave">
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
          <div id="lister-dashboard">
            <div className="mainHeading">
              <Row className="flx-1">
                <Col className="ant-col-sm-18 ant-col-xxl-12">
                  <h2>LISTER DASHBOARD</h2>
                </Col>
                <Col className="ant-col-sm-6 ant-col-xxl-12">
                  <div className="rightFilter">
                    <a href="#">
                      <i className="da icon-listview listviewIcon"></i>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Alerts</h3>
                  <div className="ant-row">
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">35</h2>
                          <a href="#">NEW SHOWS (NO LISTINGS)</a>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-danger">23</h2>
                          <a href="#">HIGH RISK LISTINGS</a>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard comingsoon">
                          <h2 className="text-danger">12</h2>
                          <a href="#">UNREAD MESSAGES</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboardCardWrap">
                  <div className="headingWithLoader">
                    <h3>New Shows (No Listings)</h3>
                  </div>
                  <div className="dashboardCard2">
                    <div className="dashboardCardHeader text-danger">
                      Create New Listings
                    </div>
                    <div className="dashboardCardBody">
                      <ul>
                        <li>
                          <p>
                            Scool of Rock, Dec 07, 2021, 3:00pm
                            <Button className="createListing cLBtn">
                              <i className="da icon-add-layer"></i>
                            </Button>
                          </p>
                        </li>
                        <li>
                          <p>
                            Mary Chapin Carpenter, Dec 08, 2021, 6:00pm
                            <Button className="createListing cLBtn">
                              <i className="da icon-add-layer"></i>
                            </Button>
                          </p>
                        </li>
                        <li>
                          <p>
                            Mary Chapin Carpenter, Dec 10, 2021, 8:00pm
                            <Button className="createListing cLBtn">
                              <i className="da icon-add-layer"></i>
                            </Button>
                          </p>
                        </li>
                        <li>
                          <p>
                            Mary Chapin Carpenter, Dec 08, 2021, 6:00pm
                            <Button className="createListing cLBtn">
                              <i className="da icon-add-layer"></i>
                            </Button>
                          </p>
                        </li>
                        <li>
                          <p>
                            Mary Chapin Carpenter, Dec 10, 2021, 8:00pm
                            <Button className="createListing cLBtn">
                              <i className="da icon-add-layer"></i>
                            </Button>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Alerts</h3>
                  <Row>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">35</h2>
                          <a href="#">NEW SHOWS</a>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard comingsoon">
                          <h2 className="text-success">23</h2>
                          <a href="#">UNREAD MESSAGES</a>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-danger">12</h2>
                          <a href="#">HIGH RISK LISTINGS</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="dashboardCardWrap">
                  <h3>Update Seat Availability</h3>
                  <Row>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCardHeader text-danger">
                          Overdue
                        </div>
                        <div className="dashboardCardBody">
                          <ul>
                            <li>
                              <p>Scool of Rock, Dec 07, 2021, 3:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 08, 2021, 6:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 10, 2021, 8:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCardHeader text-danger">
                          Today
                        </div>
                        <div className="dashboardCardBody">
                          <ul>
                            <li>
                              <p>Scool of Rock, Dec 07, 2021, 3:00pm</p>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 08, 2021, 6:00pm</p>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 10, 2021, 8:00pm</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCardHeader text-success">
                          Upcoming
                        </div>
                        <div className="dashboardCardBody">
                          <ul>
                            <li>
                              <p>Scool of Rock, Dec 07, 2021, 3:00pm</p>
                              <span>
                                <Tag className="success w72">Tomorrow</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 08, 2021, 6:00pm</p>
                              <span>
                                <Tag className="success w72">Thursday</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 10, 2021, 8:00pm</p>
                              <span>
                                <Tag className="success w72">Friday</Tag>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Insights</h3>
                  <Row>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">63</h2>
                          <a href="#">VENUES</a>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">3,147</h2>
                          <a href="#">EVENTS</a>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-lg-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-danger">1,201</h2>
                          <a href="#">ACTIVE LISTINGS</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>KPIs</h3>
                  <Row className="removeRowBtmSpc">
                    <Col className="ant-col-xs-24 ant-col-xl-12">
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>New Listings</h5>
                            <div className="graphDetail">
                              <div className="titleLerge">30 Listings</div>
                              <div className="resultOther">
                                <span className="">5</span>
                                <span className="negTrend posDown">12.50%</span>
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-xl-12">
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>New Listings</h5>
                            <div className="graphDetail">
                              <div className="titleLerge">30 Listings</div>
                              <div className="resultOther">
                                <span className="negTrend negUp">5</span>
                                <span className="negTrend negDown">12.50%</span>
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-xl-12">
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>New Listings</h5>
                            <div className="graphDetail">
                              <div className="titleLerge">30 Listings</div>
                              <div className="resultOther">
                                <span className="">5</span>
                                <span className="negTrend posDown">12.50%</span>
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="ant-col-xs-24 ant-col-xl-12">
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>New Listings</h5>
                            <div className="graphDetail">
                              <div className="titleLerge">30 Listings</div>
                              <div className="resultOther">
                                <span className="negTrend negUp">5</span>
                                <span className="negTrend negDown">12.50%</span>
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
          </div>
          <div id="seat-map-creator-dashboard">
            <div className="mainHeading">
              <Row className="flx-1">
                <Col className="ant-col-sm-18 ant-col-xxl-12">
                  <h2>SEAT MAP CREATOR DASHBOARD</h2>
                </Col>
                <Col className="ant-col-sm-6 ant-col-xxl-12">
                  <div className="rightFilter">
                    <a href="#">
                      <i className="da icon-listview listviewIcon"></i>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Alerts</h3>
                  <div className="ant-row">
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">35</h2>
                          <a href="#">NEW SHOWS (NO SEAT MAP)</a>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-danger">23</h2>
                          <a href="#">ALL OTHER SHOWS (NO SEAT MAP)</a>
                        </div>
                      </div>
                    </div>
                    <div className="ant-col-xs-24 ant-col-sm-8">
                      <div className="dashboardCard2">
                        <div className="dashboardCard comingsoon">
                          <h2 className="text-danger">12</h2>
                          <a href="#">UNREAD MESSAGES</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboardCardWrap">
                  <h3>Update Seat Availability</h3>
                  <Row>
                    <Col xs={24} sm={24} md={12}>
                      <div className="dashboardCard2">
                        <div className="dashboardCardHeader text-danger">
                          New Shows (last 30 days)
                        </div>
                        <div className="dashboardCardBody">
                          <ul>
                            <li>
                              <p>Scool of Rock, Dec 07, 2021, 3:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 08, 2021, 6:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 10, 2021, 8:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div className="dashboardCard2">
                        <div className="dashboardCardHeader text-danger">
                          All Other Shows
                        </div>
                        <div className="dashboardCardBody">
                          <ul>
                            <li>
                              <p>Scool of Rock, Dec 07, 2021, 3:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 08, 2021, 6:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                            <li>
                              <p>Mary Chapin Carpenter, Dec 10, 2021, 8:00pm</p>
                              <span>
                                <Tag className="danger">7d</Tag>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Insights</h3>
                  <Row>
                    <Col xs={24} sm={24} md={8}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">63</h2>
                          <a href="#">VENUES</a>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">3,147</h2>
                          <a href="#">EVENTS</a>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard comingsoon">
                          <h2 className="text-danger">1,201</h2>
                          <a href="#">ACTIVE LISTINGS</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>KPIs</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Seating Structure Created</h5>
                            <div className="graphDetail">
                              <div className="titleLerge">
                                30 Structures Created
                              </div>
                              <div className="resultOther">
                                <span className="">5</span>
                                <span className="negTrend posDown">12.50%</span>
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
          </div>

          <Layout className="mainInnerLayout">
            <h3>Inventory Listing Form Modal</h3>
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showInventoryListingFormModal}
            >
              Inventory Listing Form
            </Button>
            <Modal
              closable={false}
              width={730}
              title={false}
              footer={false}
              visible={isModalVisible14}
            >
              <div className="modalHeader">Inventory Listing Form</div>
              <div className="modalCustomBody">
                <div className="editUserInfoForm">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Quantity of Tickets <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                        <span className="labelBottom">
                          Max. Tickets Allowed: 8
                        </span>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          List Price ($) <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input text-right"
                        />
                        <span className="labelBottom">Ticket Cost:</span>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Section</label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Row <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Low Seat <span className="req">*</span>
                        </label>
                        <input
                          placeholder=""
                          type="text"
                          className="ant-input"
                        />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>In Hand Date</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">5 days before event</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6"></div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Stock Type <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Mobile Transfer</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>

                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Default</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Seat Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Consecutive</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Disclosures</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Attributes</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Electronic Transfer</label>
                        <div>
                          <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            className="yesNo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter modalFooterBoth">
                <div>
                  <Checkbox>Apply to all</Checkbox>
                </div>
                <div>
                  <Button
                    className="linkBtn"
                    onClick={cancelInventoryListingForm}
                  >
                    CANCEL
                  </Button>
                  <Button className="ant-btn btnOk">Save</Button>
                </div>
              </div>
            </Modal>
            <br></br>
            <h3>Finalize Listing Modal</h3>
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showFinalizeListingModal}
            >
              Finalize Listing
            </Button>
            <Modal
              closable={false}
              width={730}
              title={false}
              footer={false}
              visible={isModalVisible13}
            >
              <div className="modalHeader">Finalize Listing</div>
              <div className="modalCustomBody">
                <div className="editUserInfoForm">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Vendor</label>
                        <Input placeholder="Basic usage" disabled />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Broker Tag</label>
                        <Select placeholder="Select">
                          <Option value="option1">Option1</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-8">
                      <div className="inputGroup">
                        <label>Lister Tag</label>
                        <Select placeholder="Select">
                          <Option value="option1">Option1</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Public Notes</label>
                        <Input.TextArea style={{ minHeight: 100 }} />
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-24">
                      <div className="inputGroup">
                        <label>Internal Notes</label>
                        <Input.TextArea style={{ minHeight: 100 }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter">
                <Button className="linkBtn" onClick={cancelFinalizeListing}>
                  CANCEL
                </Button>
                <Button className="ant-btn btnOk">CREATE LISTING</Button>
              </div>
            </Modal>
          </Layout>

          <Layout id="zoneInsights" className="mainInnerLayout zoneInsights">
            <h3>ZONE INSIGHTS</h3>
            <div className="zoneInsightsCardRow">
              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTINGS &lt;14d</h3>
                  <span className="resetCard disabled"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>86</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning active">
                        <span className="progress-text active">
                          Corporate (62)
                        </span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTINGS: Low Availability</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>127</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Corporate (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTINGS: Pricing Issues</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>7</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Corporate (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTINGS: Pricing Issues</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>7</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Corporate (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTINGS</h3>
                  <div className="zoneInsightsCardInnerWrap cardCenter">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardNoData">
                      No listings in this criteria
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout zoneInsights">
            <h3>ZONE INSIGHTS</h3>
            <div className="zoneInsightsCardRow pendingZoneRow">
              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>PENDING ZONE FILLS</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary">
                        <span>406</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-danger">
                        <span className="progress-text">&lt;15d (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-success">
                        <span className="progress-text">30+d (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>PENDING ZONE FILLS</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary">
                        <span>$265k</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">&lt;15d (21.5k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">30+d (85.0k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>PENDING ZONE FILLS: Broker</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary">
                        <span>$265k</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Corporate (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Tommy (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">62</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>PENDING ZONE FILLS</h3>
                  <div className="zoneInsightsCardInnerWrap cardCenter">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardNoData">
                      No listings in this criteria
                    </div>
                  </div>
                </div>
              </div>
              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <div className="zoneInsightsCardInnerWrap cardCenter">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardNoData">
                      <div className="errorContentInner">
                        <span className="error-img"></span>
                        <div>
                          <h3>Something's wrong.</h3>
                          <p>
                            You’ve landed on a 404 error. This could mean that
                            you’ve clicked on a bad link, or entered an invalid
                            URL.
                          </p>

                          <p>Try going back to the previous page.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout zoneInsights">
            <h3>Create Split Modal</h3>

            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showCreateSplitModal}
            >
              CreateSplit
            </Button>
            <Modal
              closable={false}
              width={730}
              title={false}
              footer={false}
              visible={isModalVisible15}
            >
              <div className="modalHeader">Create Split</div>
              <div className="modalCustomBody">
                <div className="customTbl scroll-y fixedHeaderFooter createSplitTbl">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: 120 }}>Event Name</th>
                        <th style={{ width: 115 }}>Event Date and Time</th>
                        <th style={{ width: 150 }}>Venue</th>
                        <th>Zone, Section, Quantity</th>
                        <th style={{ width: 100 }} className="text-right">
                          Unit List Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Third Eye Blind</td>
                        <td>Sat, Jul 16, 2022 at 12:00 AM</td>
                        <td>1004 Nw 65th Avenue</td>
                        <td>GA1, Vip Tent Pass, 8</td>
                        <td className="text-right">$10.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="selectNewListingGroup">
                  <span>Split Each Listing Into</span>
                  <select>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <span>Groups</span>
                </div>
                <div className="newListingQtyWrap">
                  <div className="title">New Listing Quantity</div>
                  <div className="newListingQtyInnerWrap">
                    <div className="newListingGroupBox">
                      <span>Group By</span>
                      <select>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                      <span>Tickets</span>
                    </div>
                    <div className="newListingGroupBox">
                      <span>Group By</span>
                      <select>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                      <span>Tickets</span>
                    </div>
                    <div className="newListingGroupBox">
                      <span>Group By</span>
                      <select>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                      <span>Tickets</span>
                    </div>
                    <div className="newListingGroupBox">
                      <span>Group By</span>
                      <select>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                      <span>Tickets</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFooter modalFooterBoth">
                <div></div>
                <Button className="linkBtn" onClick={cancelCreateSplitModal}>
                  CANCEL
                </Button>
                <Button className="ant-btn btnOk">Save Split</Button>
              </div>
            </Modal>
          </Layout>

          <h2 id="purchaseQueue">Purchase</h2>
          <Layout className="mainInnerLayout">
            <h3>Purchase Queue - Rose Parisi</h3>
            <div className="scroll-x">
              <div className="rowWrap">
                <div className="col4 rightDeviderV">
                  <div className="purchaseInnerWrap">
                    <div className="purchaseCardHeader needToBuy">
                      NEED TO BUY (47)
                    </div>
                    <div className="purchaseCardWrap">
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">
                            <span>!</span> 43023767
                          </a>
                          <div className="kebabDropdownWrap">
                            <span className="da icon-kebab-v"></span>
                            <ul className="kebabDropdown kebabMenuActive">
                              <li>
                                <div className="accHead">Mark as Purchased</div>
                                <div className="accBody">sss</div>
                              </li>
                              <li className="active">
                                <div className="accHead">Move to On-Sale</div>
                                <div className="accBody">
                                  <div className="moveOnSaleWrap">
                                    <Radio.Group
                                      className="moveOnSaleRadioGrp radioStyleDark"
                                    >
                                      <Space direction="vertical">
                                        <Radio value={1}>
                                          <DatePicker size="small" />
                                        </Radio>
                                        <Radio value={2}>On Sale TBD</Radio>
                                      </Space>
                                    </Radio.Group>
                                    <div className="moveOnSaleRow btnGroupFooter">
                                      <a href="#" className="cancelStyle2">
                                        CANCEL
                                      </a>
                                      <Button className="btnUpdate">
                                        Update
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="active">
                                <div className="accHead">Move to Issues</div>
                                <div className="accBody">
                                  <div className="moveOnSaleWrap">
                                    <div className="moveOnSaleRow">
                                      <Select defaultValue="Select">
                                        <Option value="select">Select</Option>
                                        <Option value="cancelled-show">
                                          Cancelled Show
                                        </Option>
                                      </Select>
                                    </div>
                                    <div className="moveOnSaleRow">
                                      <TextArea placeholder="Note" rows={4} />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="accHead">Change Buyer</div>
                                <div className="accBody">asdasd</div>
                              </li>
                              <li>
                                <div className="accHead">
                                  Mark as High Priority
                                </div>
                                <div className="accBody">asdasd</div>
                              </li>
                              <li>
                                <div className="accHead">
                                  View Audit History
                                </div>
                                <div className="accBody">asdasd</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner success">
                              <span className="rankNumber">2d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">4d</span>
                              <div>In-hand date</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar warning"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">43023767</a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner error">
                              <span className="rankNumber">2d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">7d</span>
                              <div>To in-hand</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">4d</span>
                              <div>Overdue</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar warning"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">43023767</a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner error">
                              <span className="rankNumber">2d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">4d</span>
                              <div>To in-hand</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col4 rightDeviderV">
                  <div className="purchaseInnerWrap">
                    <div className="purchaseCardHeader needToBuy">
                      PURCHASED (NEED TO BUY-IN) (2)
                    </div>
                    <div className="purchaseCardWrap">
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar warning"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">43023767</a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner error">
                              <span className="rankNumber">2d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">7d</span>
                              <div>To in-hand</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">4d</span>
                              <div>Overdue</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>

                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">
                            <span>!</span> 43023767
                          </a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner success">
                              <span className="rankNumber">7d</span>
                              <div>To in-hand</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">4d</span>
                              <div>Overdue</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col4 rightDeviderV">
                  <div className="purchaseInnerWrap">
                    <div className="purchaseCardHeader onSale">
                      ON-SALE (12)
                    </div>
                    <div className="purchaseCardWrap">
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">43023767</a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner">
                              <span className="rankNumber">Dec 12</span>
                              <div>On Sale</div>
                            </div>

                            <div className="rankingInner success">
                              <span className="rankNumber">37d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">Dec 04</span>
                              <div>Reminder</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">43023767</a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner">
                              <span className="rankNumber">TBD</span>
                              <div>On Sale</div>
                            </div>

                            <div className="rankingInner success">
                              <span className="rankNumber">37d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">Dec 04</span>
                              <div>Reminder</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col4 rightDeviderV">
                  <div className="purchaseInnerWrap">
                    <div className="purchaseCardHeader issues">
                      <span>ISSUES (7)</span>
                      <div>
                      <Dropdown
                        overlay={filterDropdown}
                        trigger={["click"]}
                      >
                        <span className="clickFilter" onClick={(e) => e.preventDefault()}>
                          <i className="da icon-filter"></i>
                        </span>
                      </Dropdown>
                      </div>
                    </div>
                    <div className="purchaseCardWrap">
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">
                            43023767 <span>(Sold out show)</span>
                          </a>
                          <div className="kebabDropdownWrap">
                            <span className="da icon-kebab-v"></span>
                            <ul
                              className="kebabDropdown"
                              style={{ display: "none" }}
                            >
                              <li>asda</li>
                            </ul>
                          </div>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner success">
                              <span className="rankNumber">37d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">37d</span>
                              <div>In-hand date</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">Dec 04</span>
                              <div>Reminder</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i> <span>Rose</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col4">
                  <div className="purchaseInnerWrap">
                    <div className="purchaseCardHeader issues">
                      CHANGE IN DELIVERY (16)
                    </div>
                    <div className="purchaseCardWrap">
                      <div className="purchaseCard">
                        <div className="purchaseCardTopBar success"></div>
                        <div className="purchaseCardTop">
                          <a className="purcheseId">
                            43023767 <span>(Sold out show)</span>
                          </a>
                        </div>
                        <div className="purcheseTags">
                          <div className="ranking">
                            <div className="rankingInner success">
                              <span className="rankNumber">37d</span>
                              <div>To event</div>
                            </div>

                            <div className="rankingInner liteGreen">
                              <span className="rankNumber">37d</span>
                              <div>In-hand date</div>
                            </div>

                            <div className="rankingInner error">
                              <span className="rankNumber">Dec 04</span>
                              <div>Reminder</div>
                            </div>
                          </div>
                        </div>
                        <div className="eventCardFooter">
                          <div>
                            <i className="da icon-user-o"></i>{" "}
                            <span>Unassigned</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Layout>

          <Layout className="mainInnerLayout zoneInsights">
            <h3>Audit History Modal</h3>

            <Button
              type="primary"             
              onClick={showAuditHistoryModal}
            >
              Audit History
            </Button>
            <Modal
              closable={true}
              width={900}
              title={false}
              footer={false}
              visible={isModalVisible16}
              onCancel={cancelAuditHistoryModal}
            >
              <div className="modalHeader">Audit History</div>
              <div className="modalCustomBody">
                <ul className="auditHistoryList">
                  <li>
                    <div>Beatriz J moved the invoice to On-Sale on August 9, 2022 9:09 AM</div>
                  </li>
                  <li>
                    <div>Beatriz J moved the invoice to On-Sale on August 9, 2022 9:09 AM</div>
                  </li>
                  <li>
                    <div>Beatriz J moved the invoice to On-Sale on August 9, 2022 9:09 AM</div>
                  </li>
                  <li>
                    <div>Beatriz J moved the invoice to On-Sale on August 9, 2022 9:09 AM</div>
                  </li>
                  <li>
                    <div>Beatriz J moved the invoice to On-Sale on August 9, 2022 9:09 AM</div>
                    <div className="noteBlk">
                      <div><strong>Note:</strong></div>
                      <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* onClick={cancelAuditHistoryModal} */}
            </Modal>
          </Layout>

          <h2 id="eventMaping">EVENT MAPPING</h2>
          <Layout className="mainInnerLayout">
            
            <Row>
              <Col xs={24} sm={24} md={8} lg={6} className="eventMapLeftTbl">
                <div className="customDivTbl">
                  <div className="divTblHead">
                    <div className="divTblHeadCol withSearch">
                      <div className="divTblHeadVanueName">
                        Venue
                        <i className="da sortIcon icon-sort" />
                      </div>
                      <div className="search">
                        <Input type="search" placeholder=""></Input>
                      </div>
                    </div>
                  </div>
                  <div className="divTblBodyRow active">
                    <div className="divTblBodyCol">
                      Arthur Ashe Stadium Parking (3)
                    </div>
                  </div>
                  <div className="divTblBodyRow">
                    <div className="divTblBodyCol">
                      Northwell Health at Jones Beach (10)
                    </div>
                  </div>
                  <div className="divTblBodyRow">
                    <div className="divTblBodyCol">
                    Bonnaroo Manchester Farm (1)
                    </div>
                  </div>
                  <div className="divTblBodyRow">
                    <div className="divTblBodyCol">
                    Abravanel Hall (12)
                    </div>
                  </div>
                  <div className="divTblBodyRow">
                    <div className="divTblBodyCol">
                    Wolf Trap Filene Center (3)
                    </div>
                  </div>
                  <div className="divTblBodyRow">
                    <div className="divTblBodyCol">
                    Van Wezel Performing Arts Hall (2)
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={16} lg={12} id="dddd">
              <div className="customTbl tblContWordBreak">
                  <table className="main-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-left">
                        Scraped Event Name
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "250px" }}
                        >
                          SKYBOX Event
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th scope="col" style={{ width: "150px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        Tommy Emmanuel
                        </td>
                        <td>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          Bonobo
                        </td>
                        <td>
                          Bonobo Live
                        </td>
                        <td className="text-center">
                          <Button className="btnOk">Un-map</Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Tommy Emmanuel
                        </td>
                        <td>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                        </td>
                        <td></td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
                <div>
                <h3 className="mt-20">Mapped Events</h3>
                <div className="customTbl tblContWordBreak">
                  <table className="main-table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "30px" }}></th>
                        <th scope="col" className="text-left">
                        Scraped Event
                          <i className="da sortIcon icon-sort" />
                        </th>
                        <th
                          scope="col"
                          className="text-left"
                          style={{ width: "300px" }}
                        >
                          SKYBOX Event
                          <i className="da sortIcon icon-sort" />
                        </th>
                        {/* <th scope="col" style={{ width: "150px" }}></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        <i className="da icon-gear"></i>
                        </td>
                        <td>
                        Scraped Event 1
                        </td>
                        <td>
                        Skybox Event 1
                        </td>
                        {/* <td className="text-center">
                        <Button className="btnOk">Button text</Button>
                        </td> */}
                      </tr>
                      <tr>
                        <td>
                        <i className="da icon-wrench"></i>
                        </td>
                        <td>
                        Scraped Event 2
                        </td>
                        <td>
                        Skybox Event 3
                        </td>
                        {/* <td className="text-center">
                        <Button className="btnOk">Button text</Button>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
              </Col>
            </Row>
          </Layout>

          <Layout className="mainInnerLayout zoneInsights">
            <h3>Audit History Modal</h3>

            <Button
              type="primary"             
              onClick={showEditListing2Modal}
            >
              Edit Listing Modal
            </Button>
            <Modal
              closable={true}
              width={1024}
              title={false}
              footer={false}
              visible={isModalVisible17}
              onCancel={cancelEditListing2Modal}
            >
              <div className="modalHeader">Edit Listing</div>
              <div className="modalCustomBody">
              <div className="ant-row">
                <div className="ant-col ant-col-xl-16">
                  <div className="ant-row">
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Quantity
                        </label>
                        <input placeholder="Quantity" type="text" className="ant-input" disabled/>
                      </div>
                    </div>
                   
                    <div className="ant-col ant-col-xl-6">
                      <label>Section</label>
                      <input placeholder="Section" type="text" className="ant-input"  disabled/>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <label>Row</label>
                      <input placeholder="Row" type="text" className="ant-input"  disabled/>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>List Price ($) <span className="req">*</span></label>
                        <input placeholder="List Price" type="text"  className="ant-input" />                        
                      </div>
                    </div>
                    
                    {/* <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Low Seat <span className="req">*</span>
                        </label>
                        <div className="lblValue">1000.00</div>
                      </div>
                    </div> */}
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          In Hand Date <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">5 days before event</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Stock Type <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Mobile Transfer</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>

                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Split Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Default</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Seat Type</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Consecutive</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Disclosures</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>Attributes</label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Broker Tag <span className="req">*</span>
                        </label>
                        <Select placeholder="Select" allowClear>
                          <Option value="option1">Option1</Option>
                          <Option value="option2">Option2</Option>
                          <Option value="option3">Option3</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup">
                        <label>
                          Broadcast <span className="req">*</span>
                        </label>
                        <div className="">
                          <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            className="yesNo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-col-xl-6">
                      <div className="inputGroup mb0">
                        <label>Electronic Transfer</label>
                        <div className="lblValue">
                          <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            className="yesNo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-1">
                  <div className="dividerVertical"></div>
                </div>
                <div className="ant-col ant-col-xl-7">
                  <div className="textAreaWrap height100">
                    <div className="inputGroup height100">
                      <label>
                        Public Notes{" "}
                        <i className="da icon-info-fill-dark notesInfo"></i>
                      </label>
                      <TextArea></TextArea>
                    </div>
                    <div className="inputGroup height100">
                      <label>
                        Internal Notes{" "}
                        <i className="da icon-info-fill-dark notesInfo"></i>
                      </label>
                      <TextArea></TextArea>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {/* onClick={cancelAuditHistoryModal} */}
              <div className="modalCustomFooter">             
                <div>
                  <Button className="linkBtn" onClick={cancelEditListing2Modal}>
                    CANCEL
                  </Button>
                  <Button className="ant-btn btnOk">Save</Button>
                </div>
              </div>
            </Modal>
          </Layout>


          <div id="operations-dashboard">
            <div className="mainHeading">
              <Row className="flx-1">
                <Col className="ant-col-sm-18 ant-col-xxl-12">
                  <h2>Operations DASHBOARD</h2>
                  <h3 className="text-upper sectionHeading">Inventory and Trading</h3>
                </Col>
                <Col className="ant-col-sm-6 ant-col-xxl-12">
                  <div className="rightFilter">
                    <a href="#">
                      <i className="da icon-listview listviewIcon"></i>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout id="zoneInsights" className="mainInnerLayout zoneInsights">
            <h3>Listing Alerts</h3>
            <div className="zoneInsightsCardRow">
              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>HIGH RISK LISTING (&lt;7d)</h3>
                  <span className="resetCard disabled"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>10.1K</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning active">
                        <span className="progress-text active">
                        Broker K
                        </span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">5.2K</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Noorain (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">5.2K</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">
                        Prad Bro
                        </span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "60%" }}
                          ></div>
                        </div>
                        <span className="progressValue">5.2K</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Test-Qa</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "40%" }}
                          ></div>
                        </div>
                        <span className="progressValue">4.2K</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">6.2K</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>CANCELLED EVENTS</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>127</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Broker K.</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">168</span>
                      </div>                      
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Noorain</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Prad Bro</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">40</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Nn Hh</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">34</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">23</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>LOW AVAILABILITY</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>7</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Noorain</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">431</span>
                      </div>
                      
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Prad Bro.</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">122</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Test-Qa</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">312</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Ticketal</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">402</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">417</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             
              <div className="highRiskCardCol">
                <div className="zoneInsightsCard">
                  <h3>PRICING ISSUES</h3>                  
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount danger">
                        <span>7</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Corporate (62)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Vin (41)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Doug (29)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d (10.2k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "27%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">Others (45)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "45%" }}
                          ></div>
                        </div>
                        <span className="progressValue">12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </Layout>
            
          <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>KPIs</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>New Listings</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Listing Based on Geo Location</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Purchase Orders</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            
                            <div className="graphDetail">
                              <div className="titleLerge alignSelfStart">
                              <h5>Listings by Event Date</h5>
                              187 Listings
                              </div>
                              <div className="text-right">
                                <label>Event date range</label>                                
                                  <RangePicker className="chartDateRange" />                                  
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>


            <div className="mainHeading">
              <h3 className="text-upper sectionHeading">purchase and shipping</h3>
            </div>

            <Layout id="zoneInsights" className="mainInnerLayout zoneInsights">
            <h3>Pending Fills Alerts</h3>
            <div className="zoneInsightsCardRow pendingZoneRow">

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>COUNT (Days to Event)</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary active">
                        <span>777</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-danger">
                        <span className="progress-text">&lt;15d</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">95</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">98</span>
                      </div>
                      <div className="progress-bar progress-success">
                        <span className="progress-text">30+d</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">562</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>SALES (Days to Event) </h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary active">
                        <span>$481K</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">&lt;15d (21.5k)</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">95K</span>
                      </div>
                      <div className="progress-bar progress-warning">
                        <span className="progress-text">15-30d</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">98K</span>
                      </div>
                      <div className="progress-bar progress-success">
                        <span className="progress-text">30+d</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">562K</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="zoneInsightsCardCol">
                <div className="zoneInsightsCard">
                  <h3>SALES (Brokers)</h3>
                  <span className="resetCard"></span>
                  <div className="zoneInsightsCardInnerWrap">
                    {/* cardCenter */}
                    <div className="zoneInsightsCardLeft">
                      <div className="zoneInsightsCount primary active">
                        <span>$449K</span>
                      </div>
                    </div>
                    <div className="zoneInsightsCardRight">
                      <div className="progress-bar progress-dark">
                        <span className="progress-text">Prad Bro.</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                        <span className="progressValue">23K</span>
                      </div>
                      <div className="progress-bar progress-dark">
                        <span className="progress-text">Noorain...</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "41%" }}
                          ></div>
                        </div>
                        <span className="progressValue">98K</span>
                      </div>
                      <div className="progress-bar progress-dark">
                        <span className="progress-text">Others</span>
                        <div className="progress-outer">
                          <div
                            className="progress-bg"
                            style={{ width: "29%" }}
                          ></div>
                        </div>
                        <span className="progressValue">435K</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </Layout>
            
          <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Purchase Queue</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">Need to Buy</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">Need to Buy ($)</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">Need to Buy-IN</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">Issues</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Shipping Queue</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">pending sell-out</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5 className="uppercase">issues</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                            <img
                                src={paiChart}
                                alt=""
                                style={{ width: "50%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Purchase and Shipping Department Performance</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Purchases</h5>
                            <div className="titleLerge text-left">337 Purchases</div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Purchases Compared Across Buyers</h5>
                            <div className="titleLerge text-left">246 Purchases</div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Invoices Moved to Shipping</h5>
                            <div className="titleLerge text-left">75 Invoices</div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Invoices Delivered</h5>
                            <div className="titleLerge text-left">55 Invoices</div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Pending Fills vs Sold-out vs. Delivered</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
            </div>


            <div id="sales-dashboard">
            <div className="mainHeading">
              <Row className="flx-1">
                <Col className="ant-col-sm-18 ant-col-xxl-12">
                  <h2>SALES DASHBOARD</h2>                  
                </Col>
                <Col className="ant-col-sm-6 ant-col-xxl-12">
                  <div className="rightFilter">
                    <a href="#">
                      <i className="da icon-listview listviewIcon"></i>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Insights</h3>
                  <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success"><a className="text-success innerCardHeading" href="#">56</a></h2>
                          <p className="innerCardSubHeading">ORDERS</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">
                            <a className="text-success innerCardHeading" href="#">$232</a>
                          </h2>
                          <p className="innerCardSubHeading">TICKET SALES</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">
                            <a className="text-success innerCardHeading" href="#">$98.73</a>
                          </h2>
                          <p className="innerCardSubHeading">GROSS PROFIT</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                      <div className="dashboardCard2">
                        <div className="dashboardCard">
                          <h2 className="text-success">
                            <a className="text-success innerCardHeading" href="#">103</a>
                          </h2>
                          <p className="innerCardSubHeading">TICKETS SOLD</p>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="removeRowBtmSpc mt-20">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Profitability Snapshot</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Sales Based on Geo Location</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </Col>                   
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">                            
                            <div className="graphDetail">
                              <div className="titleLerge alignSelfStart">
                              <h5>Sales by Event Date Range</h5>
                              $198 Sales
                              </div>
                              <div className="text-right">
                                <label>Event date range</label>                                
                                  <RangePicker className="chartDateRange" />                                  
                              </div>
                            </div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
            
            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Orders</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>No. of Orders</h5>
                            <div className="titleLerge text-left">56 Orders</div>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Orders by Sale Type</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Orders by Sale Category</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>                   
                  </Row>
                
                  <h3 className="mt-20">Sales</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Sales by Broker</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Sales by Sale Type</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Sales by Sale Category</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    
                  </Row>

                  <h3 className="mt-20">Profit</h3>
                  <Row className="removeRowBtmSpc">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Profit by Broker</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                          <div className="chartTopbar">
                            <h5>Profit by Sale Type</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              <div className="chartIconList">
                                <button>
                                  <i className="da icon-line-chart-dark"></i>
                                </button>
                                <button className="active">
                                  <i className="da icon-bar-chart-dark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                      <div className="dashboardChartCard">
                        <div className="dashboardCard mainChartWidget">
                        <div className="chartTopbar">
                            <h5>Profit by Sale Category</h5>
                          </div>
                          <div className="chartBody">
                            <div>
                              <img
                                src={barChart}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                          <div className="chartFooter">
                            <div className="chartBtmBar">
                              <ul>
                                <li>
                                  <a href="#" className="active">
                                    Day
                                  </a>
                                </li>
                                <li>
                                  <a href="#/">Week</a>
                                </li>
                                <li>
                                  <a href="#/">Month</a>
                                </li>
                                <li>
                                  <a href="#/">Quarter</a>
                                </li>
                                <li>
                                  <a href="#/">Year</a>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    
                  </Row>
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout dashboard">
              <Content className="mainContainer">
                <div className="dashboardCardWrap">
                  <h3>Sales by Customer Type</h3>
                  <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                    <div className="customTbl scroll-y wordBreakThTd">
                      <table className="main-table">
                        <thead>
                          <tr>
                            <th scope="col" className="text-left" style={{ minWidth: "110px" }}>
                            Customer Type 
                            </th>
                            <th scope="col" className="text-right" style={{ width: "80px", minWidth: "80px"  }}>
                            Tickets Sold
                            </th>
                            <th scope="col" className="text-right" style={{ width: "80px",minWidth: "80px"  }}>
                            Ticket Sales
                            </th>
                            <th scope="col" className="text-right" style={{ width: "80px",minWidth: "80px"  }}>
                            Gross Profit
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>                     
                            <td className="eventName">
                              <a href="#" className="text-primary">Stubhub (1ticket)</a>
                            </td>
                            <td className="text-right">58</td>
                            <td className="text-right">$196</td>
                            <td className="text-right">$68</td>                      
                          </tr>
                          <tr>                     
                            <td className="eventName">
                              <a href="#" className="text-primary">Stubhub (1ticket)</a>
                            </td>
                            <td className="text-right">58</td>
                            <td className="text-right">$196</td>
                            <td className="text-right">$68</td>                      
                          </tr>
                          <tr>                     
                            <td className="eventName">
                              <a href="#" className="text-primary">Stubhub (1ticket)</a>
                            </td>
                            <td className="text-right">58</td>
                            <td className="text-right">$196</td>
                            <td className="text-right">$68</td>                      
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </Col>
                    
                  </Row>
                  
                </div>
              </Content>
            </Layout>

            <Layout className="mainInnerLayout" id="NotesHistoryModal">
            <h3>Notes History Modal</h3>
            
            <Button
              type="primary"
              style={{ width: "300px" }}
              onClick={showNotesHistoryModal}
            >
              Notes History
            </Button>
            <Modal
              closable={true}
              width={800}
              title={false}
              footer={false}
              visible={isModalVisible19}
              //onClick={cancelNotesHistoryModal}
            >
              <div className="modalHeader">Notes History</div>
              <div>
                <label className="mb-0">Invoice ID:</label>&nbsp;<a href="#" className="text-primary">49992194</a>
                </div>
              <p>
                <strong>Hamilton</strong> (Thu, Aug 18, 2022 07:30 PM)
              </p>
              
              <div className="modalCustomBody">
                <div className="customTbl scroll-y fixedHeaderFooter textVTop"  style={{maxHeight: "400px"}}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: 150 }}>Name</th>
                        <th style={{ width: 180 }}>Date</th>
                        <th>Notes description</th>                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>John Smith</strong></td>
                        <td>Sat, Jul 16, 2022 at 12:00 AM</td>
                        <td>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </td>
                        </tr>
                        <tr>
                        <td><strong>John Smith</strong></td>
                        <td>Sat, Jul 16, 2022 at 12:00 AM</td>
                        <td>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
              <div className="modalCustomFooter modalFooterBoth">
                <div></div>
                <div>
                  <Button className="linkBtn linkBtnColor" onClick={cancelNotesHistoryModal}>CANCEL</Button>
                  <Button className="ant-btn btnOk">SAVE</Button>
              </div>
              </div>
            </Modal>
          </Layout>


            </div>
        </Layout>
      </div>
    </div>
  );
}
