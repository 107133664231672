import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IAuditHistory, IEventListQueryParams, IEventListResponseData, IEventMetadata, IMaster, IMetadataTrendData } from "models/interfaces";
import { EventService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import { getRandomValue } from "utils/commonFunction";

const PAGE_SIZE = AppSetting.PAGE_SIZE;

let initialState: {
  eventResponseListData: IEventListResponseData;
  eventMetadata: IEventMetadata | null;
  zoneAvailabilityMaster: IMaster[];
  stockTypeMaster: IMaster[];
  metadataAuditData: IAuditHistory[];
  metadataTrendData: IMetadataTrendData | null;
  queryParams: IEventListQueryParams;
  isLoadingEvents: boolean;
  isLoadingMetadata: boolean;
  isLoadingAuditHistory: boolean;
  isLoadingTrendData: boolean;
  hasError: boolean;
  dataFetchError: any;
} = {
  eventResponseListData: {
    eventDetailsData: [],
    total: 0,
  },
  eventMetadata: null,
  zoneAvailabilityMaster: [],
  stockTypeMaster: [],
  metadataAuditData: [],
  metadataTrendData: null,
  queryParams: {
    sort_column: "days_to_event",
    sort_order: "asc",
    page_size: PAGE_SIZE,
    page_index: 1,
    search_text: null,
    event_date_from: null,
    event_date_to: null,
    broker_user_Id: null,
    lister_user_Id: null,
    seat_map_creator_user_id: null,
    checker_user_id: null,
    state_Id: null,
    seat_intelligence_Id: null,
    new_shows_no_seat_intelligence: false,
    new_shows_no_listing: false
  },
  isLoadingEvents: false,
  isLoadingMetadata: false,
  isLoadingTrendData: false,
  isLoadingAuditHistory: false,
  hasError: false,
  dataFetchError: null,
};

const getEventListData = createAsyncThunk(
  "events/getEventListData",
  async (args: any, thunkApi) => {
    try {
      const state: any = thunkApi.getState(); //get updated state tree
      let params = state.eventsManagement;

      const response = await new EventService().getEventDetailsData({
        ...params.queryParams,
      });
      return response.data.data;
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getEventListData",
      });

      return thunkApi.rejectWithValue({
        code: error.code,
        message: error.message,
        refreshAction: args.functionName,
      });
    }
  }
);

const getEventMetadata = createAsyncThunk(
  "events/getEventMetadata",
  async (
    args: {
      event_id: number;
      functionName: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const eventResponse = await new EventService().getEventMetadata({
        event_id: args.event_id,
      });
      const responseData = eventResponse.data.data;
      responseData.metadata?.zone?.forEach((eachZone) => {
        eachZone.key =
          new Date().getTime().toString() + getRandomValue("number");
      });
      return responseData;
    } catch (error: any) {
      console.log(error);
      await new LoggerService().log({
        payload: error,
        function_name: "getEventMetadata",
      });
      return rejectWithValue({
        code: error.code,
        message: error.message,
        refreshAction: args.functionName,
      });
    }
  }
);

const getMetadataAuditHistory = createAsyncThunk(
  "events/getMetadataAuditHistory",
  async (args: { event_id: number; functionName: string }) => {
    try {
      const response = await new EventService().getMetadataAuditHistory({
        event_id: args.event_id,
      });
      return response.data.data;
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getMetadataAuditHistory",
      });

      return { ...error, refreshAction: `${args.functionName}` };
    }
  }
);

const getMetadataTrendData = createAsyncThunk(
  "events/getMetadataTrendData",
  async (args: {event_id: number, functionName: string}) => {
    try {
      const response = await new EventService().getMetadataTrendData({
        event_id: args.event_id
      });
      return response.data.data;
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getMetadataTrendData",
      });

      return { ...error, refreshAction: `${args.functionName}` };
    }
  }
)

export const eventManagementSlice = createSlice({
  name: "eventManagement",
  initialState,
  reducers: {
    resetEventSlice: () => initialState,
    setQueryParams: (state, action: PayloadAction<IEventListQueryParams>) => {
      const newParams = action.payload;
      const existingParams = JSON.parse(JSON.stringify(state.queryParams));
      const updatedParams = { ...existingParams, ...newParams };
      state.queryParams = updatedParams;
    },
    setIsLoadingEvents: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEvents = action.payload ?? false;
    },
    setEventMetadata: (state, action: PayloadAction<IEventMetadata>) => {
      const newMetadata = action.payload;
      const oldMetadata = JSON.parse(JSON.stringify(state.eventMetadata));
      const updatedMetadata = { ...oldMetadata, ...newMetadata };
      state.eventMetadata = updatedMetadata;
    },
    setIsLoadingMetadata: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMetadata = action.payload ?? false;
    },
    setIsLoadingAuditHistory: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAuditHistory = action.payload ?? false;
    },
    /** Update Upcoming Event List From OutSite */
    updateUpcomingEventList: (state, action: PayloadAction<any>) => {
      const eventData = action.payload;
      if(eventData.event_id){
        const oldEventData = {...state.eventResponseListData};
        if(oldEventData.eventDetailsData.find(eachEvent => eachEvent.event_id === eventData.event_id)){
          const oldEvents = [...oldEventData.eventDetailsData];
          let findEventIndex = oldEvents.findIndex(eachEvent => eachEvent.event_id === eventData.event_id); 
          oldEvents[findEventIndex] = {...oldEvents[findEventIndex], ...eventData};
          oldEventData.eventDetailsData = oldEvents;
          state.eventResponseListData = oldEventData;
        }
      }
    },
  },
  extraReducers: (builder) => {
    //Get event list data
    builder.addCase(getEventListData.pending, (state) => {
      state.isLoadingEvents = true;
    });
    builder.addCase(getEventListData.fulfilled, (state, action) => {
      state.hasError = false;
      state.isLoadingEvents = false;
      state.eventMetadata = null;
      state.eventResponseListData = {...action.payload};
    });
    builder.addCase(getEventListData.rejected, (state, action: any) => {
      state.hasError = true;
      state.isLoadingEvents = false;
      state.dataFetchError = {...action.payload};
    });

    // Get event metadata
    builder.addCase(getEventMetadata.pending, (state) => {
      state.isLoadingMetadata = true;
    });
    builder.addCase(getEventMetadata.fulfilled, (state, action) => {
      state.hasError = false;
      state.eventMetadata = action.payload.metadata ?? null;
      state.zoneAvailabilityMaster = action.payload.zoneAvailabilityMaster;
      state.stockTypeMaster = action.payload.stockTypeMaster;
      state.isLoadingMetadata = false;
    });
    builder.addCase(getEventMetadata.rejected, (state, action: any) => {
      state.hasError = true;
      state.isLoadingMetadata = false;
      state.dataFetchError = {...action.payload};
    });

    //Get event metadata audit history
    builder.addCase(getMetadataAuditHistory.pending, (state) => {
      state.isLoadingAuditHistory = true;
    });
    builder.addCase(getMetadataAuditHistory.fulfilled, (state, action) => {
      state.hasError = false;
      state.metadataAuditData = action.payload.auditHistory;
      state.isLoadingAuditHistory = false;
    });
    builder.addCase(getMetadataAuditHistory.rejected, (state, action) => {
      state.hasError = true;
      state.dataFetchError = action.payload;
    });

    //Get trend data
    builder.addCase(getMetadataTrendData.pending, (state) => {
      state.isLoadingTrendData = true;
    });
    builder.addCase(getMetadataTrendData.fulfilled, (state, action) => {
      state.hasError = false;
      state.metadataTrendData = action.payload;
      state.isLoadingTrendData = false;
    });
    builder.addCase(getMetadataTrendData.rejected, (state, action) => {
      state.hasError = true;
      state.dataFetchError = action.payload;
    });
  },
});

const eventListData = (state: RootState) =>
  state.eventsManagement.eventResponseListData;
const queryParams = (state: RootState) => state.eventsManagement.queryParams;
const isLoadingEvents = (state: RootState) =>
  state.eventsManagement.isLoadingEvents;
const hasError = (state: RootState) => state.eventsManagement.hasError;
const dataFetchError = (state: RootState) => state.eventsManagement.dataFetchError;
const eventMetadata = (state: RootState) => state.eventsManagement.eventMetadata;
const metadataAuditData = (state: RootState) => state.eventsManagement.metadataAuditData;
const metadataTrendData = (state: RootState) => state.eventsManagement.metadataTrendData;
const zoneAvailabilityMaster = (state: RootState) => state.eventsManagement.zoneAvailabilityMaster;
const stockTypeMaster = (state: RootState) => state.eventsManagement.stockTypeMaster;
const isLoadingMetadata = (state: RootState) => state.eventsManagement.isLoadingMetadata;
const isLoadingAuditHistory = (state: RootState) => state.eventsManagement.isLoadingAuditHistory;
const isLoadingTrendData = (state: RootState) => state.eventsManagement.isLoadingTrendData;

export {
  eventListData,
  queryParams,
  isLoadingEvents,
  hasError,
  dataFetchError,
  eventMetadata,
  metadataAuditData,
  metadataTrendData,
  zoneAvailabilityMaster,
  stockTypeMaster,
  isLoadingMetadata,
  isLoadingAuditHistory,
  isLoadingTrendData,
};

export const eventManagementActions = { getEventListData, getEventMetadata, getMetadataAuditHistory, getMetadataTrendData };
export const {
  resetEventSlice,
  setQueryParams,
  setIsLoadingEvents,
  setEventMetadata,
  updateUpcomingEventList,
  setIsLoadingMetadata,
  setIsLoadingAuditHistory,
} = eventManagementSlice.actions;
export default eventManagementSlice.reducer;
