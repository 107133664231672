import { Tooltip } from "antd";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import {
  EventsWithActiveZoneListingsQueryParams,
  IAllZoneListingTableHeader,
  IEventsWithActiveZoneListingsResponse,
  IEventWithActiveZoneListings,
} from "models/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import {
  daysToEventColorCheck,
  formatNumericValues,
  getEncodedData,
  getRandomValue,
  getSeatAvailabilityDetails,
  getUserName,
} from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { showBrokerOrSeatMapCreatorCheckerLister } from "views/Trading/Events/eventsUtils";
import AllZoneListingPoTable from "./AllZoneListingPoTable";

interface IAllZoneListingTableDataRow {
  headers: IAllZoneListingTableHeader[];
  singleData: IEventWithActiveZoneListings;
  onRowClicked: Function;
  otherData?: IEventWithActiveZoneListings[];
  allZoneListings: IEventsWithActiveZoneListingsResponse;
  setAllZoneListings: Function;
  rowSaveClickHandler: Function;
  queryParams: EventsWithActiveZoneListingsQueryParams;
  urlParams: any;
  searchText: string | null;
  // fetchCompetitorPrice: Function;
  purchaseLineId?: string | null;
}

const AllZoneListingTableDataRow: React.FC<IAllZoneListingTableDataRow> = ({
  headers,
  singleData,
  onRowClicked,
  otherData,
  allZoneListings,
  setAllZoneListings,
  rowSaveClickHandler,
  queryParams,
  urlParams,
  searchText,
  // fetchCompetitorPrice,
  purchaseLineId,
}) => {
  const { hasPermissions } = useRbac();
  const [isPoLoading, setIsPoLoading] = useState(false);
  const [isEventRowExpanded, setIsEventRowExpanded] = useState(false);

  useEffect(()=>{
    if(purchaseLineId){
      setIsEventRowExpanded(true);
      getPoDetails(urlParams?.event_id);
    } else {
      setIsEventRowExpanded(false);
    }
  },[queryParams, purchaseLineId]);

  const getPoDetails = useCallback(
    async (event_id: number) => {

      try {
        setIsPoLoading(true);
        const response = await new ZonesService().getActiveListings({
          event_id: event_id,
          purchase_id: null,
          purchase_line_id: purchaseLineId,
          search_text: searchText,
          show_tb_placements: queryParams.show_tb_placements ?? false,
          low_or_no_availability_listings_only: queryParams.low_or_no_availability_listings_only ?? false,
          high_risk_listings_only: queryParams.high_risk_listings_only ?? false,
          show_broadcasted_listings: queryParams.show_broadcasted_listings ?? false,
          ...urlParams
        });
        const responseData = response.data.data;

        if (allZoneListings) {
          setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
            if (prev) {
              const _prev = { ...prev };
              if (_prev) {
                let _event = _prev.events?.find(
                  (event) => event.event_id === event_id
                );
                if (_event && responseData?.activeListings) {
                  _event.po_details = {
                    activeListings: responseData?.activeListings,
                    editedActiveListings: [
                      ...responseData?.activeListings?.map((each) => ({
                        ...each,
                      })),
                    ],
                    secondaryMarketplace: responseData?.secondaryMarketplace,
                  };
                  _event.po_details_fetch_error = null;
                }
              }
              return _prev;
            }
          });
        }
        setIsPoLoading(false);
        // if(singleData?.po_details?.secondaryMarketplace && singleData?.po_details?.secondaryMarketplace.length){
        //   singleData?.po_details?.secondaryMarketplace?.filter((eachMarket:any)=>eachMarket.secondary_marketplace_url && eachMarket.secondary_marketplace_url !== '').forEach((each:any) => {
        //     fetchCompetitorPrice(singleData?.event_id, singleData?.venue_id , each?.name_alias.toLocaleLowerCase(), each?.secondary_marketplace_url, false)
        //   })
        // }
      } catch (error: any) {
        if (allZoneListings) {
          setAllZoneListings((prev: IEventsWithActiveZoneListingsResponse) => {
            if (prev) {
              let _event = prev.events.find(
                (event) => event.event_id === event_id
              );
              if (_event) {
                _event.po_details_fetch_error = {
                  ...error,
                  refreshAction: getPoDetails,
                };
              }
            }
            return prev;
          });
        }
        setIsPoLoading(false);
        await new LoggerService().log({
          payload: error,
          function_name: "getPoDetails",
        });
      }
    },
    [allZoneListings, setAllZoneListings]
  );

  const tdClassRender = (eachHeader: IAllZoneListingTableHeader) => {
    let className = "";
    if (eachHeader.tdClassName) {
      className = className + " " + eachHeader.tdClassName;
    }
    return className;
  };

  const getEventAvailabilityJsx = (availabilityDetails: {
    color: string;
    title: string;
  }) => {
    return (
      <Tooltip placement="right" title={availabilityDetails.title}>
        <div
          className={`seatAviaileLogicBar ${availabilityDetails.color} haveTooltip`}
          style={{ padding: "0" }}
        >
          &nbsp;
        </div>
      </Tooltip>
    );
  };

  const getLastUpdatedJsx = (
    lastUpdatedData: {
      compare_date: number;
      first_name: string | null;
      last_name: string | null;
    },
    last_scraped_status: boolean | null,
    desktop_scraped_success: boolean,
    is_from_desktop_app: boolean,
    web_scraped_success: boolean,
    scraping_status_id: number
  ) => {
    // let userName =
    //   last_scraped_status !== null ? (
    //     <span>
    //       &nbsp;&nbsp;
    //       {
    //         is_from_desktop_app ?  ( desktop_scraped_success && <i className="da icon-desktop-app"></i> )
    //         :
    //         ( last_scraped_status === true && <i className="da icon-fictionalCharacter-green"></i> )
    //       }
    //     </span>
    //   ) : (
    //     getUserName(lastUpdatedData)
    //   );


      // let userName = last_scraped_status !== null ? (
      //   <span>
      //     &nbsp;&nbsp;
      //     {
      //       is_from_desktop_app ?  ( desktop_scraped_success && <i className="da icon-desktop-app"></i> )
      //       :
      //       ( last_scraped_status === true && <i className="da icon-fictionalCharacter-green"></i> )
      //     }
      //   </span>
      // ) : (
      //   getUserName(lastUpdatedData)
      // );
    if( lastUpdatedData.compare_date === null ) {
      return <></>;
    }

    let userName;

    if( is_from_desktop_app ) {
      userName = desktop_scraped_success ? <i className="da icon-desktop-app-success"></i> : ''
    } else if( web_scraped_success === true ) {
      userName = <i className="da icon-fictionalCharacter-green"></i>
    } else if( scraping_status_id === 2 ) {
        userName = getUserName(lastUpdatedData)
    }
    
    if( userName ){
      if (  lastUpdatedData.compare_date > 1) {
        return (
          <div className="lastUpdatedtext">
            {`${lastUpdatedData.compare_date} days ago by  `}{userName}
          </div>
        );
      } else if (lastUpdatedData.compare_date === 1) {
        return (
          <div className="lastUpdatedtext">
            {`${lastUpdatedData.compare_date} day ago by  `}{userName}
          </div>
        );
      } else if (lastUpdatedData.compare_date === 0) {
        return <div className="lastUpdatedtext">Today by{'  '}{userName}</div>;
      }
    }
    
  };

  const getEventAndVenueJsx = (
    venue_fees: number,
    event_id: number,
    event_name: string,
    venue_name: string,
    venue_location: string
  ) => {
    return (
      <div className="text-capitalize">
        <a
          target={"_blank"}
          className="eventNameVenue"
          rel="noopener noreferrer"
          href={AppSetting.EVENT_URL + event_id}
        >
          <strong>{event_name}</strong>
        </a>
        <br />
        {venue_name}: {venue_location}
        <br/>
        {
          Boolean(venue_fees) &&
          <span>
            <Tooltip title="Fees have been manually added from Edit Venue screen"><i className="da icon-venue-fees" /></Tooltip>
            <span>Venue Fees: </span>
            <span>${venue_fees}</span>
          </span>
        }
      </div>
    );
  };

  const getDaysToEventJsx = (days_to_event: number) => {
    return (
      <Tooltip
        placement="left"
        title={daysToEventColorCheck(days_to_event, "list").toolTipText}
      >
        <div
          className={
            "daysToEvent daysToEventData " +
            daysToEventColorCheck(days_to_event, "list").className
          }
        >
          <span style={{ cursor: "default" }}>{days_to_event}</span>
        </div>
      </Tooltip>
    );
  };

  const getPOLineCountJsx = (active_listings: number) => {
    return (
      <div className={"daysToEvent daysToEventData "}>
        {formatNumericValues(active_listings)}
      </div>
    );
  };

  const getPendingFillsCountJsx = (pending_fills: number) => {
    return (
      <div className={"daysToEvent daysToEventData "}>
        {formatNumericValues(pending_fills)}
      </div>
    );
  };

  const getPricingIssueCountJsx = (pricing_issue: number) => {
    return (
      <div className={"daysToEvent daysToEventData "}>
        {formatNumericValues(pricing_issue)}
      </div>
    );
  };

  const getExpandIconTooltipMessage = () => {
    if (
      isEventRowExpanded
    ) {
      return "Hide Details";
    }
    return "Show Details";
  };

  const toggleClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const event_id = Number(e.currentTarget.id.split("_")[1]);
    const currentEvent = otherData?.find(
      (event) => event.event_id === event_id
    );
    setIsEventRowExpanded((prev) => !prev);
    if (currentEvent && !currentEvent.po_details && !isPoLoading) {
      getPoDetails(event_id);
    }
  };

  const getToggleIconJsx = (event_id: number) => {
    return (
      <Tooltip title={getExpandIconTooltipMessage()}>
        <div onClick={toggleClickHandler} id={"eventID_" + event_id}>
          <i
            className={`da ${
              // otherData?.find((element) => element.event_id === event_id)
              //   ?.is_expanded
              isEventRowExpanded ? "icon-arrow-up-2" : "icon-arrow-down-2"
            } toggle`}
          />
        </div>
      </Tooltip>
    );
  };

  const getEditMetadataJsx = (singleData: IEventWithActiveZoneListings) => {
    return (
      <div className="text-right">
        <div className="tblActions">
          <RBAC allowedPermissions={[ERbacPermissions.EVENT_METADATA_EDIT]}>
            <Tooltip title="Edit Event Metadata" placement="left">
              <Link
                to={`${
                  LeftNavRoutesEnum.ZONES_LISTING_EDIT_EVENT_AVAILABILITY
                }?event=${getEncodedData({
                  event_id: singleData.event_id,
                })}`}
                target={"_blank"}
              >
                <i className="da icon-pencil-o" />
              </Link>
            </Tooltip>
          </RBAC>
        </div>
      </div>
    );
  };

  const getRowElements = (eachHeader: IAllZoneListingTableHeader) => {
    if (singleData[eachHeader.dataIndex] !== undefined) {
      switch (eachHeader.dataIndex) {
        case "seat_availability_score":
          const availabilityDetails = getSeatAvailabilityDetails(
            singleData.seat_availability_score
          );
          return getEventAvailabilityJsx(availabilityDetails);
        case "event_id":
          return getToggleIconJsx(singleData.event_id);
        case "updated_on":
          const lastUpdatedData = JSON.parse(singleData.updated_by);
          return getLastUpdatedJsx(
            lastUpdatedData,
            singleData.last_scraped_status,
            singleData.is_desktop_final_success,
            singleData.is_from_desktop_app,
            singleData.is_web_final_success,
            singleData.scraping_status_id
          );
        case "event_name":
          return getEventAndVenueJsx(
            singleData.venue_fees,
            singleData.event_id,
            singleData.event_name,
            singleData.venue_name,
            singleData.venue_location
          );
        case "days_to_event":
          return getDaysToEventJsx(singleData.days_to_event);
        case "event_date":
          return formatDateGivenFormatString(
            singleData.event_date,
            "ddd, MMM DD, YYYY h:mm A"
          );
        case "broker_name":
          return showBrokerOrSeatMapCreatorCheckerLister(
            singleData.broker_name
          );
        case "lister_name":
          return showBrokerOrSeatMapCreatorCheckerLister(
            singleData.lister_name
          );
        case "active_listings":
          return getPOLineCountJsx(singleData.active_listings);
        case "pending_fills":
          return getPendingFillsCountJsx(singleData.pending_fills);
        case "pricing_issue":
          return getPricingIssueCountJsx(singleData.pricing_issue);
        case "venue_id":
          return getEditMetadataJsx(singleData);
        default:
          return singleData[eachHeader.dataIndex];
      }
    }
    return "";
  };

  return (
    <React.Fragment>
      <tr
        onClick={() => {
          onRowClicked(singleData);
        }}
      >
        {headers.map((eachHeader) => {
          return (
            <React.Fragment key={`row_${getRandomValue("mixed")}}`}>
              {(!eachHeader.allowedPermissions ||
                hasPermissions(eachHeader.allowedPermissions)) && (
                <td className={tdClassRender(eachHeader)}>
                  {getRowElements(eachHeader)}
                </td>
              )}
            </React.Fragment>
          );
        })}
      </tr>
      {isEventRowExpanded ? (
        <tr>
          <td colSpan={30} style={{ padding: 0 }}>
            <LoadingSpinner isLoading={isPoLoading ?? false}>
              <AllZoneListingPoTable
                data={singleData.po_details?.editedActiveListings ?? null}
                currentEventData={singleData}
                // fetchCompetitorPrice={fetchCompetitorPrice}
                otherData={allZoneListings}
                allZoneListings={allZoneListings}
                setAllZoneListings={setAllZoneListings}
                rowSaveClickHandler={rowSaveClickHandler}
                haveVenueFees={Boolean(singleData.venue_fees)}
              />
            </LoadingSpinner>
          </td>
        </tr>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const areEqual = (
  prevProps: IAllZoneListingTableDataRow,
  nextProps: IAllZoneListingTableDataRow
) => {
  if (prevProps.allZoneListings === nextProps.allZoneListings) {
    return true; // donot re-render
  }
  return false; // will re-render
};

export default React.memo(AllZoneListingTableDataRow, areEqual);
// export default AllZoneListingTableDataRow;

