import { Button, message, Modal, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IActiveListing, ISplitDatum } from "models/interfaces";
import React, { useState } from "react";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import ActiveListingPoCard from "../ActiveListingPoCard";
import ActiveListingPoLineCard from "../ActiveListingPoLineCard";

const ActiveListingBroadcastStatusChange: React.FC<{
  eventId: number;
  eventName: string;
  eventDate: string;
  venueName: string;
  venueLocation: string;
  activeListingData?: IActiveListing;
  setUpdatedBroadcastStatus?: Function;
  isPoLineCard?: boolean;
  splitData?: ISplitDatum;
  placement?: TooltipPlacement;
  onCloseActiveListingModal?: Function;
}> = ({
  eventId,
  eventName,
  eventDate,
  venueName,
  venueLocation,
  activeListingData,
  setUpdatedBroadcastStatus,
  isPoLineCard,
  splitData,
  placement,
  onCloseActiveListingModal
}) => {
  const [openBroadcastConfirmBox, setOpenBroadcastConfirmBox] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const { hasPermissions } = useRbac();

  const doUpdateBroadcastStatus = async (data: IActiveListing | ISplitDatum | undefined) => {
    if (data) {
      setIsUpdatingStatus(true);
      const updatingBroadcastStatusKey = "updatingBroadcastStatusKey";
      message.loading({
        content: "Updating broadcast status...",
        duration: 0,
        key: updatingBroadcastStatusKey,
        className: "toastMsg loadingMsg",
      });
      let isBroadcastStatusUpdated = false;
      let updatedStatusResult = null;
      try {
        updatedStatusResult =
          await new ZonesService().updateInventoryBroadcastStatus(
            data.inventory_id,
            !data.broadcast,
            data.purchase_line_id
          );
        isBroadcastStatusUpdated = true;
        message.success({
          content: "Broadcast status has been updated successfully!",
          key: updatingBroadcastStatusKey,
          duration: 3,
          className: "toastMsg savedSuccess",
        });
        onCloseActiveListingModal && onCloseActiveListingModal()
      } catch (error) {
        message.error({
          content: "Broadcast status has failed to update.",
          duration: 5,
          key: updatingBroadcastStatusKey,
          className: "toastMsg savedFailed",
        });
        new LoggerService().log({
          payload: error,
          function_name: "doUpdateBroadcastStatus",
        });
        setOpenBroadcastConfirmBox(false);
        setIsUpdatingStatus(false);
      }

      if (isBroadcastStatusUpdated) {
        if (setUpdatedBroadcastStatus) {
          setUpdatedBroadcastStatus(
            updatedStatusResult?.data.data.inventory_id,
            updatedStatusResult?.data.data.broadcast_status
          );
        }

        setOpenBroadcastConfirmBox(false);


        // let syncingZoneInventoryKey = "syncingZoneInventoryKey";
        // message.loading({
        //   content: "Syncing zone listings from SkyBox...",
        //   duration: 0,
        //   key: syncingZoneInventoryKey,
        //   className: "toastMsg loadingMsg",
        // });
        // try {
        //   await new ZonesService().refreshZoneInventoryMVW();
        //   message.success({
        //     content: "Zone listing has been synced successfully!",
        //     key: syncingZoneInventoryKey,
        //     duration: 5,
        //     className: "toastMsg savedSuccess",
        //   });
        //   if (setUpdatedBroadcastStatus) {
        //     setUpdatedBroadcastStatus(
        //       updatedStatusResult?.data.data.inventory_id,
        //       updatedStatusResult?.data.data.broadcast_status
        //     );
        //   }

        //   setOpenBroadcastConfirmBox(false);
        // } catch (error) {
        //   message.error({
        //     content: "Broadcast status has failed to update.",
        //     duration: 5,
        //     key: syncingZoneInventoryKey,
        //     className: "toastMsg savedFailed",
        //   });
        //   new LoggerService().log({
        //     payload: error,
        //     function_name: "doUpdateBroadcastStatus",
        //   });
        // }
        // setOpenBroadcastConfirmBox(false);
        setIsUpdatingStatus(false);
      }
    }
  };

  const getToolTipMessage = () => {
    let toolTipMessage = "Change Broadcast Status";
    if (
      !hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION])
    ) {
      toolTipMessage = activeListingData?.broadcast
        ? "Broadcasted"
        : "Un-Broadcasted";
    }
    return toolTipMessage;
  };

  const getActionStyleClass = () => {
    let _className = "cardNotificationIcon ";
    if (
      !hasPermissions([ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION])
    ) {
      _className = _className + "notClickable";
    }
    return _className;
  };

  return (
    <React.Fragment>
      {
        placement ?
        <Tooltip title={getToolTipMessage()} placement={placement}>
          <Typography.Link
          onClick={() => {
            console.log(
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
            );

            if (
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
            ) {
              setOpenBroadcastConfirmBox(true);
            }
          }}
          className={getActionStyleClass()}
        >
          <i
            className={
              activeListingData?.broadcast || splitData?.broadcast
                ? "da icon-network"
                : "da icon-network-red"
            }
          />
        </Typography.Link>
        </Tooltip>
      :
      <Tooltip title={getToolTipMessage()}>
        <Typography.Link
          onClick={() => {
            console.log(
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
            );

            if (
              hasPermissions([
                ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
              ])
            ) {
              setOpenBroadcastConfirmBox(true);
            }
          }}
          className={getActionStyleClass()}
        >
          <i
            className={
              activeListingData?.broadcast || splitData?.broadcast
                ? "da icon-network"
                : "da icon-network-red"
            }
          />
        </Typography.Link>
      </Tooltip>
      }
      <Modal
        closable={false}
        width={360}
        title={false}
        footer={false}
        visible={openBroadcastConfirmBox}
      >
        <div className="modalHeader modalHeaderCenter">
          Are you sure you want to{" "}
          <span>
            {activeListingData?.broadcast || splitData?.broadcast ? "un-broadcast" : "broadcast"}
          </span>{" "}
          this listing?
        </div>
        <div className="modalActiveListingPoCard">
        {isPoLineCard && splitData ? (
            <ActiveListingPoLineCard
              splitData={splitData}
            />
          ) : (
            <ActiveListingPoCard
              eventId={eventId}
              eventName={eventName}
              eventDate={eventDate}
              venueName={venueName}
              venueLocation={venueLocation}
              activeListingData={activeListingData}
              showCardActionHeader={false}
              disableSplitToggle={true}
            />
          )}
        </div>
        <div className="modalCustomFooter">
          <Button
            className="linkBtn linkBtnColor customLinkBtn"
            onClick={() => {
              setOpenBroadcastConfirmBox(false);
            }}
            disabled={isUpdatingStatus}
          >
            CANCEL
          </Button>
          <Button
            className="btnOk"
            disabled={isUpdatingStatus}
            onClick={()=>isPoLineCard ? doUpdateBroadcastStatus(splitData) : doUpdateBroadcastStatus(activeListingData)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ActiveListingBroadcastStatusChange;
