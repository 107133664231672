import { Button, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { IMenuOption, IMoveToNeedToBuyPostData, ISwimlaneMaster } from "models/interfaces";
import React, { useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { formatThousandSeparator } from "utils/commonFunction";
const swimlane_id = 9;

const MoveToNeedToBuyLowPriority: React.FC<{ 
  menuData: IMenuOption;
  invoice_id: number;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  requestToReloadTheLanes: Function;
}> = ({ menuData, invoice_id, swimlaneData, setShowMenu, handleMenuSelection, selectedMenuId, setSelectedMenuId, requestToReloadTheLanes }) => {

  const [moveToNeedToBuyLowPriorityData, setMoveToNeedToBuyLowPriorityData] = useState<IMoveToNeedToBuyPostData>({
    invoice_id: invoice_id,
    swimlane_id: swimlane_id,
    notes: null,
  });
  const notesChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMoveToNeedToBuyLowPriorityData((prev) => ({
      ...prev,
      notes: e.currentTarget.value,
    }));
  };

  const submitMovedToNeedToBuyLowPriority = async () =>{
    const moveNeedToBuyLowPriorityKey = "moveNeedToBuyLowPriorityKey";
    message.loading({
      content: `Moving Invoice from ${swimlaneData.name} to need to buy low priority queue...`,
      duration: 0,
      key: moveNeedToBuyLowPriorityKey,
      className: "toastMsg loadingMsg",
    });

    try {
      setShowMenu(false);

      await new PurchaseAndShippingService().moveToNeedToBuyLowPriority(moveToNeedToBuyLowPriorityData);
      requestToReloadTheLanes([swimlaneData.id, swimlane_id]);
      message.success({
        content: `Invoice #${invoice_id} is moved to need to buy low priority queue`,
        duration: 5,
        key: moveNeedToBuyLowPriorityKey,
        className: "toastMsg savedSuccess",
      });

    }  catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "submitMovedToNeedToBuyLowPriority",
      });
      message.error({
        key: moveNeedToBuyLowPriorityKey,
        content: "Failed to change lane.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
  }

  return (
    <React.Fragment>
      <div className="accHead" onClick={()=>{
        if (selectedMenuId === menuData.menu_id) {
          handleMenuSelection(null);
          setMoveToNeedToBuyLowPriorityData((prev) => ({
            ...prev,
            notes: null,
          }));
        } else {
          handleMenuSelection(menuData.menu_id);
        }
      }}>{menuData.name}</div>
      <div className="accBody">
        <div className="moveOnSaleWrap">
        <div className="moveOnSaleRow">
            <TextArea
              maxLength={1000}
              showCount={{
                formatter: ({ count, maxLength }) =>
                  maxLength ?
                    `${formatThousandSeparator((maxLength ?? 0) - count)} Remaining` :
                    "",
              }}
              placeholder="Note"
              className="moveToIssuesTxtArea"
              rows={4}
              value={moveToNeedToBuyLowPriorityData?.notes ? moveToNeedToBuyLowPriorityData.notes : ""}
              onChange={notesChangeHandler}
              allowClear
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </div>
          <div className="moveOnSaleRow btnGroupFooter">
            <Button className="discardBtn" onClick={() => {
              setSelectedMenuId(null);
              setMoveToNeedToBuyLowPriorityData((prev) => ({
                ...prev,
                notes: null,
              }));
            }}>
              <span>CANCEL</span>
            </Button>
            <Button
              className="btnUpdate"
              onClick={submitMovedToNeedToBuyLowPriority}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoveToNeedToBuyLowPriority;
