import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sections from "./Widgets/CreatedSectionCount";
import GAEvents from "./Widgets/GeneralAdmissionEventsCount";

const SeatmapCreatorsPerformanceAnalysis = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Seatmap Creators</h3>
                    <section className="ant-layout mainInnerLayout">
                        <Sections />
                    </section>
                    <section className="ant-layout mainInnerLayout">
                        <GAEvents />
                    </section>
                </div>
            </Content>
        </Layout>
    )
}

export default SeatmapCreatorsPerformanceAnalysis
