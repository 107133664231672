import React, { useState, useEffect } from "react";
import { Col, Modal, Row, Tooltip } from "antd";
import { IInvoiceDetailModalDatum } from "models/interfaces/Purchases/submenus.model";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { ICustomError } from "models/interfaces/ICustomError";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CustomError from "controls/CustomError/CustomError";
import { AppSetting } from "utils/appSettings";
import { addToolTip, formatAccountingValues, titleCase } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";

const InvoiceDetailModal: React.FC<{
  ifShowInvoiceDetailModal: boolean;
  setIfShowInvoiceDetailModal: Function;
  invoiceId: number;
}> = ({
  ifShowInvoiceDetailModal,
  setIfShowInvoiceDetailModal,
  invoiceId
}) => {
    const [invoiceModalData, setInvoiceModalData] = useState<IInvoiceDetailModalDatum | null>(null);
    const [isLoadingModalData, setIsLoadingModalData] = useState<boolean>(false);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(null);

    const getInvoiceModalData = async () => {
      try {
        setIsLoadingModalData(true);
        const res = await new PurchaseAndShippingService().getInvoiceDetailModalData(invoiceId);
        const response = res.data.data;

        setInvoiceModalData(response.invoiceModalData[0]);

        setIsLoadingModalData(false);
      } catch (error: any) {
        setIsLoadingModalData(false);
        await new LoggerService().log({
          payload: error,
          function_name: "getInvoiceModalData",
        });

        setDataFetchError({ ...error, refreshAction: getInvoiceModalData });
      }
    };

    useEffect(() => {
      if (ifShowInvoiceDetailModal) {
        getInvoiceModalData();
        return;
      }
      setInvoiceModalData(null);
    }, [ifShowInvoiceDetailModal]);

    const setUnitSalePrice = (salePrice: number) => {
      if (salePrice > 0) {
        return FormatCurrencyUnit(
          formatAccountingValues(salePrice),
          CurrencyCode.Usd
        );
      }
      return formatAccountingValues(salePrice);
    }

    return (
      <React.Fragment>
        {ifShowInvoiceDetailModal &&
          <Modal
            closable={true}
            width={600}
            title={false}
            footer={false}
            visible={ifShowInvoiceDetailModal}
            centered
            onCancel={() => setIfShowInvoiceDetailModal(false)}
            bodyStyle={{ minHeight: 200 }}
            className="invoiceDetailModal"
          >
            <LoadingSpinner isLoading={isLoadingModalData}>
              {dataFetchError && (
                <CustomError {...dataFetchError} />
              )}
              {!dataFetchError && !isLoadingModalData && (
                <React.Fragment>
                  <div className="modalHeader">
                    <div className="modalHeadingMain">Invoice Detail</div>
                    <div className="modalHeaderChild">
                      <div>
                      <div className="invoiceId"><label>Invoice Id:</label> <a
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={
                            AppSetting.INVOICE_ID_URL +
                            invoiceModalData?.invoice_id
                          }
                        >
                          {invoiceModalData?.invoice_id}
                        </a></div>
                        <div className="eventDtLft">
                          <a
                            target={"_blank"}
                            rel="noopener noreferrer"
                            href={
                              AppSetting.EVENT_URL +
                              invoiceModalData?.event_id
                            }
                          >
                            {titleCase(invoiceModalData?.event_name)}
                          </a>
                          {Boolean(invoiceModalData?.event_date) &&
                            <span>
                              {` (${formatDateGivenFormatString(
                                invoiceModalData?.event_date,
                                "ddd, MMM DD, YYYY hh:mm A"
                              )})`}
                            </span>
                          }
                        </div>
                        <div className="venueLocation">
                          {Boolean(invoiceModalData?.venue_location) && (
                            <React.Fragment>
                              {titleCase(invoiceModalData?.venue_name)}, {invoiceModalData?.venue_location}
                            </React.Fragment>
                          )}

                          {!Boolean(invoiceModalData?.venue_location) &&
                            Boolean(invoiceModalData?.venue_name.trim().toLowerCase() !== 'to be determined') && (
                              <React.Fragment>
                                {invoiceModalData && invoiceModalData.venue_name.length > 100 ?
                                  addToolTip(titleCase(invoiceModalData?.venue_name), "right", "default", 97) :
                                  titleCase(invoiceModalData?.venue_name)
                                }
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <Row>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Customer:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            <span className="text-capitalize">
                              {invoiceModalData?.customer}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Broker name:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            {invoiceModalData?.broker_name ?? 'Unknown'}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Section:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            <span className="text-capitalize">{invoiceModalData?.section}</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Row:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            <span className="text-upper">{invoiceModalData?.row}</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Qty:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            {invoiceModalData?.quantity}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup invoiceUnitSale">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Unit Sale Price:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            {(invoiceModalData?.sale_price !== undefined && 
                            invoiceModalData?.cost_price !== undefined && 
                            invoiceModalData?.sale_price > invoiceModalData?.cost_price) &&
                              setUnitSalePrice(invoiceModalData.sale_price)
                            }
                            {(invoiceModalData?.sale_price !== undefined && 
                            invoiceModalData?.cost_price !== undefined && 
                            invoiceModalData?.sale_price <= invoiceModalData?.cost_price) &&
                              <React.Fragment>
                                {setUnitSalePrice(invoiceModalData?.sale_price ?? 0)}
                                <Tooltip
                                  title="If ticket cost is more than ticket price, inform the broker." 
                                  placement="right" >
                                    <i className="da icon-exclamation-circle informBroker" />
                                </Tooltip>
                              </React.Fragment>
                            }
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <div className="inputGroup">
                        <Row>
                          <Col xs={12} sm={6} md={10} xl={10}>
                            <label>Ticket stock type:</label>
                          </Col>
                          <Col xs={12} sm={18} md={14} xl={14}>
                            {invoiceModalData?.stock_type}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {invoiceModalData?.venue_url &&
                      <Col xs={24} sm={24} md={24} xl={24}>
                        <div className="primaryWebAddress">
                          <a className="linkColor2"
                            target={"_blank"}
                            rel="noopener noreferrer"
                            href={invoiceModalData.venue_url ?? ''}
                          >
                            Primary website address
                          </a>
                        </div>
                      </Col>
                    }
                    
                  </Row>
                </React.Fragment>
              )
              }
            </LoadingSpinner>
          </Modal>
        }
      </React.Fragment>
    )
  }

export default InvoiceDetailModal;
