import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { LeftNavMenuKeysEnum, LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { IMenu } from "models/interfaces";

export const initialMenu: IMenu[] = [
    {
      displayOrder: 1,
      menuTitle: "Dashboard",
      iconClass: "da icon-home",
      key: LeftNavMenuKeysEnum.DASHBOARD,
      child: [
        {
          menuTitle: "Lister",
          key: LeftNavRoutesEnum.DASHBOARD_LISTER,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_LISTER,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.LISTER_DASHBOARD_VIEW],
        },
        {
          menuTitle: "Checker",
          key: LeftNavRoutesEnum.DASHBOARD_CHECKER,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_CHECKER,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.CHECKER_DASHBOARD_VIEW],
        },
        {
          menuTitle: "Seat Map Creator",
          key: LeftNavRoutesEnum.DASHBOARD_SEATMAPCREATOR,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_SEATMAPCREATOR,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.SEATMAPCREATOR_DASHBOARD_VIEW],
        },
        {
          menuTitle: "Operations",
          key: LeftNavRoutesEnum.DASHBOARD_OPERATIONS,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_OPERATIONS,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.OPERATIONS_DASHBOARD_VIEW],
        },
        {
          menuTitle: "Bot Performance",
          key: LeftNavRoutesEnum.DASHBOARD_BOT_PERFORMANCE,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_BOT_PERFORMANCE,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.BOT_PERFORMANCE_DASHBOARD_VIEW],
        },
        {
          menuTitle: "Performance Analysis",
          key: LeftNavRoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS,
          navigationLink: LeftNavRoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS,
          show_in_mobile_view: true,
          allowed_permissions: [ERbacPermissions.PERFORMANCE_ANALYSIS_DASHBOARD_VIEW],
        },
      ],
    },
    {
      displayOrder: 2,
      menuTitle: "Zones",
      iconClass: "da icon-zone",
      key: LeftNavMenuKeysEnum.ZONES,
      child: [
        {
          menuTitle: "Zone Listings",
          key: LeftNavRoutesEnum.ZONES_LISTING,
          navigationLink: LeftNavRoutesEnum.ZONES_LISTING,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.VENUE_DETAILS_VIEW_ALL,
            ERbacPermissions.VENUE_DETAILS_VIEW_OWN,
            ERbacPermissions.VENUE_DETAILS_VIEW_TEAM,
          ],
        },
        {
          menuTitle: "Pending Fills",
          key: LeftNavRoutesEnum.ZONES_PENDING_FILLS,
          navigationLink: LeftNavRoutesEnum.ZONES_PENDING_FILLS,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW_ALL,
            ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW_OWN
          ],
        },
      ],
    },
    {
      displayOrder: 3,
      menuTitle: "Trading",
      iconClass: "da icon-event",
      key: LeftNavMenuKeysEnum.TRADING,
      child: [
        {
          menuTitle: "Venues",
          key: LeftNavRoutesEnum.TRADING_VENUES,
          navigationLink: LeftNavRoutesEnum.TRADING_VENUES,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.VENUE_DETAILS_VIEW_ALL,
            ERbacPermissions.VENUE_DETAILS_VIEW_OWN,
            ERbacPermissions.VENUE_DETAILS_VIEW_TEAM,
          ],
        },
        {
          menuTitle: "Events",
          key: LeftNavRoutesEnum.TRADING_EVENTS,
          navigationLink: LeftNavRoutesEnum.TRADING_EVENTS,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.EVENT_DETAILS_VIEW_ALL,
            ERbacPermissions.EVENT_DETAILS_VIEW_OWN,
            ERbacPermissions.EVENT_DETAILS_VIEW_TEAM,
          ],
        },
      ],
    },
    {
      displayOrder: 4,
      menuTitle: "Purchases",
      iconClass: "da icon-purchases",
      key: LeftNavMenuKeysEnum.PURCHASES,
      child: [
        {
          menuTitle: "Purchase Queue",
          key: LeftNavRoutesEnum.PURCHASE_QUEUE,
          navigationLink: LeftNavRoutesEnum.PURCHASE_QUEUE,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.PURCHASE_QUEUE_VIEW_ALL,
            ERbacPermissions.PURCHASE_QUEUE_VIEW_TEAM,
            ERbacPermissions.PURCHASE_QUEUE_VIEW_OWN,
          ],
        },
      ]
    },
    {
      displayOrder: 4,
      menuTitle: "Shipping",
      iconClass: "da icon-shipping",
      key: LeftNavMenuKeysEnum.SHIPPING,
      child: [
        {
          menuTitle: "Shipping Queue",
          key: LeftNavRoutesEnum.SHIPPING_QUEUE,
          navigationLink: LeftNavRoutesEnum.SHIPPING_QUEUE,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.SHIPPING_QUEUE_VIEW_ALL,
            ERbacPermissions.SHIPPING_QUEUE_VIEW_TEAM,
            ERbacPermissions.SHIPPING_QUEUE_VIEW_OWN,
          ],
        },
        {
          menuTitle: "Delivered Tickets",
          key: LeftNavRoutesEnum.DELIVERED_TICKETS_REPORT,
          navigationLink: LeftNavRoutesEnum.DELIVERED_TICKETS_REPORT,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.REPORT_DELIVERED_TICKETS_VIEW_ALL
          ],
        },
      ]
    },
    {
      displayOrder: 6,
      menuTitle: "Housekeeping",
      iconClass: "da icon-housekeeping",
      key: LeftNavMenuKeysEnum.HOUSEKEEPING,
      child: [
        // {
        //   menuTitle: "Event Mapping",
        //   key: LeftNavRoutesEnum.HOUSEKEEPING_EVENT_MAPPING,
        //   navigationLink: LeftNavRoutesEnum.HOUSEKEEPING_EVENT_MAPPING,
        //   show_in_mobile_view: false,
        //   allowed_permissions: [
        //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_ALL,
        //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_TEAM,
        //     ERbacPermissions.HOUSEKEEPING_EVENT_MAPPING_VIEW_OWN,
        //   ],
        // },
        {
          menuTitle: "Cancelled Shows",
          key: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS,
          navigationLink: LeftNavRoutesEnum.HOUSEKEEPING_CANCELLED_SHOWS,
          show_in_mobile_view: false,
          allowed_permissions: [
            ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL,
            ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN,
            ERbacPermissions.HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM,
            ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL,
            ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN,
            ERbacPermissions.HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM,
            ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_ALL,
            ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_TEAM,
            ERbacPermissions.HOUSEKEEPING_UNFILLED_ZONES_VIEW_OWN,
            ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_ALL,
            ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_TEAM,
            ERbacPermissions.HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_OWN,
          ],
        },
      ],
    },
    {
      displayOrder: 8,
      menuTitle: "Insights",
      iconClass: "da icon-report",
      key: LeftNavMenuKeysEnum.INSIGHTS,
      child: [
        {
          menuTitle: "Reports",
          key: LeftNavRoutesEnum.INSIGHTS_REPORTS,
          navigationLink: LeftNavRoutesEnum.INSIGHTS_REPORTS,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.REPORT_DASHBOARD_VIEW
          ],
        },
        {
          menuTitle: "Schedules",
          key: LeftNavRoutesEnum.INSIGHTS_SCHEDULES,
          navigationLink: LeftNavRoutesEnum.INSIGHTS_SCHEDULES,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.SCHEDULES_VIEW_ALL,
            ERbacPermissions.SCHEDULES_VIEW_OWN,
            ERbacPermissions.SCHEDULES_VIEW_TEAM,
          ],
        },
      ],
    },
    {
      displayOrder: 9,
      menuTitle: "Settings",
      iconClass: "da icon-settings",
      key: LeftNavMenuKeysEnum.SETTINGS,
      child: [
        {
          menuTitle: "Users",
          key: LeftNavRoutesEnum.SETTINGS_USERS,
          navigationLink: LeftNavRoutesEnum.SETTINGS_USERS,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.USER_DETAILS_VIEW_ALL,
            ERbacPermissions.USER_DETAILS_VIEW_OWN,
            ERbacPermissions.USER_DETAILS_VIEW_TEAM,
          ],
        },
        {
          menuTitle: "Commissions",
          key: LeftNavRoutesEnum.SETTINGS_COMMISSIONS,
          navigationLink: LeftNavRoutesEnum.SETTINGS_COMMISSIONS,
          show_in_mobile_view: true,
          allowed_permissions: [
            ERbacPermissions.SETTINGS_COMMISSIONS_VIEW
          ],
        },
      ],
    },
  ];