import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import ReportLayout from "../ReportLayout";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import GrossProfitTable from "./GrossProfitTable";
import { useLocation } from "react-router";
import CreateScheduler from "utils/commonScheduler/createScheduler";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IFilterOptions, IReportQueryString } from "models/interfaces";
import { usePageTitle } from "hooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { actions, dateRange } from "models/Slices/FilterSlice";
import { decodeQueryStringData, urlQueryParamsToObject } from "utils/commonFunction";

const GrossProfit: React.FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(dateRange);
  useEffect(() => {
    dates.length <= 0 && dispatch(actions.getDateRangeFilterData());
    dispatch(actions.getRecipientFilterData());
    dispatch(actions.getSaleCategoryFilterData());
    dispatch(actions.getCustomerClassificationFilterData());
  }, [dispatch]);
  usePageTitle("Gross Profit");
  const location = useLocation();
  const path = location.pathname;
  const { hasPermissions } = useRbac();
  const queryObj = urlQueryParamsToObject(location.search);
  const reportQueryString: IReportQueryString = decodeQueryStringData(
    queryObj.report
  );

  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [filtersAppliedWithValues, setFilterAppliedWithValues] = useState<any>(null)
  const [search, setSearch] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [ifShowFilterDrawer, setIfShowFilterDrawer] = useState(false);
  const [filterQueryString, setFilterQueryString] = useState<any>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);
  const [outerLoader, setOuterLoader] = useState(false);

  useEffect(() => {
    setOuterLoader(true);
    if (queryObj.hasOwnProperty('filter')) {
      setFilterQueryString(decodeQueryStringData(
        queryObj.filter
      ));
    }
  }, []);

  useEffect(() => {
    if (path.endsWith("delivery")) {
      if (dates.length > 0) {
        if (!filterQueryString) {
          setDefaultDateRange({
            preFilledValues: {
              pl_date_from: dates[0].pl_date_from,
              pl_date_to: dates[0].pl_date_to
            },
            defaultValues: {
              pl_date: {
                defaultFrom: dates[0].pl_date_from,
                defaultTo: dates[0].pl_date_to
              }
            }
          });
          setIfShowFilterDrawer(true);
        } else {
          setDefaultDateRange({
            preFilledValues: {
              pl_date_from: filterQueryString?.pl_date_from ?? dates[0].pl_date_from,
              pl_date_to: filterQueryString?.pl_date_to ?? dates[0].pl_date_to
            },
            defaultValues: {
              pl_date: {
                defaultFrom: dates[0].pl_date_from,
                defaultTo: dates[0].pl_date_to
              }
            }
          });
          setIfShowFilterDrawer(true);
        }
      }
    } else {
      setDefaultDateRange({
        preFilledValues: {
          pl_date_from: filterQueryString?.pl_date_from,
          pl_date_to: filterQueryString?.pl_date_to,
        }
      });
      setIfShowFilterDrawer(true);
    }
  }, [dates]);

  const filterChanged = (filters: any) => {
    setFilterObject({
      ...filters,
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch('');
    setSearchQuery('');
  };

  const getSchedulerPermission = (p: string) => {
    if (p.endsWith('issues'))
      return [ERbacPermissions.REPORT_BROKER_TAGS_ISSUES_SCHEDULE]
    else if (p.endsWith('sales'))
      return [ERbacPermissions.REPORT_CONSIGNMENT_SALES_SCHEDULE]
    else if (p.endsWith('delivery'))
      return [ERbacPermissions.REPORT_SHORTS_PENDING_DELIVERY_SCHEDULE]
    else if (p.endsWith('in'))
      return [ERbacPermissions.REPORT_CONSIGNMENT_PENDING_BUY_IN_SCHEDULE]
    else
      return [ERbacPermissions.REPORT_GROSS_PROFIT_SCHEDULE]
  }

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={24} xl={9} xxl={9} className="gpReportTitle">
            <h2>{reportQueryString.current_report}</h2>
          </Col>
          <Col xs={16} xl={8} xxl={7}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search invoice id, purchase id, or event name..."
                  value={search ? search : ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={8} xl={7} xxl={8}>
            <div className="rightFilter">
              <RBAC allowedPermissions={getSchedulerPermission(path)}>
                <CreateScheduler
                  schedulerTitle={"Create Schedule"}
                  reportData={reportQueryString}
                  filterData={filterObject}
                />
              </RBAC>
              {ifShowFilterDrawer && (
                <FilterDrawer
                  current_report_id={reportQueryString.current_report_id}
                  showPLDatePicker={true}
                  showBrokerDropdown={hasPermissions([ERbacPermissions.FILTER_BROKER_TAGS_VIEW_ALL, ERbacPermissions.FILTER_BROKER_TAGS_VIEW_TEAM])}
                  showSaleCategoryDropdown={!path.endsWith('sales') && !path.endsWith('in')}
                  showCustomerClassificationDropdown={true}
                  showSaleTypeDropdown={true}
                  showNoTicketCheckbox={true}
                  showTbPlacementCheckbox={true}
                  onApplyFilter={filterChanged}
                  onApplyFilterWithValues={(e: any) => setFilterAppliedWithValues(e)}
                  brokerDropdownType="single"
                  saleCategoryDropdownType="single"
                  customerClassificationDropdownType="single"
                  saleTypeDropdownType="single"
                  defaultAndValues={defaultDateRange}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <GrossProfitTable
            filterObject={filterObject}
            appliedFilterValues={filtersAppliedWithValues}
            searchQuery={searchQuery}
            outerLoader={outerLoader}
            setOuterLoader={setOuterLoader}
          />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default GrossProfit;

