import { Button, Checkbox, Select, Switch, Tag, Tooltip } from "antd";
import {
  IEventMetadata,
  InventoryAttributesMaster,
  IZoneListingsByEventResponseData,
} from "models/interfaces";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  checkValidUrl,
  getTagClassForEvents,
  titleCase,
  getEncodedData,
} from "utils/commonFunction";
import { getCustomDate, getCustomTimeFromDate } from "utils/formatTimePeriod";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { RBAC, useRbac } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const EventHeader: React.FC<{
  metadata: IEventMetadata | null;
  metadataUpdateData: IEventMetadata | null;
  setMetadataUpdateData: Function;
  setIsSoldOutActivated: Function;
  isWebScrapingEvent: boolean;
  setIsWebScrapingEvent: Function;
  disableCheckboxes: boolean;
  showWebScrapingCheckbox: boolean;
  showSemiAutomaticScrapingCheckbox: boolean;
}> = ({
  metadata,
  metadataUpdateData,
  setMetadataUpdateData,
  setIsSoldOutActivated,
  isWebScrapingEvent,
  setIsWebScrapingEvent,
  disableCheckboxes,
  showWebScrapingCheckbox,
  showSemiAutomaticScrapingCheckbox,
}) => {
  const { hasPermissions } = useRbac();
  const [venueUrl, setVenueUrl] = useState<string | null>(null);
  const [eventZoneListingDetails, setEventZoneListingDetails] =
    useState<IZoneListingsByEventResponseData | null>(null);
  const [seatMapTbdDropdownData, setSeatMapTbdDropdownData] = useState<
    InventoryAttributesMaster[] | null
  >(null);
  const [seatMapTbdSelection, setSeatMapTbdSelection] = useState<number | null>(
    null
  );
  const [isCheckedSeatMapTbd, setIsCheckedSeatMapTbd] =
    useState<boolean>(false);
  const [isZoneAvailable, setIsZoneAvailable] = useState<boolean>(false);
  const [webScarpingCheckValue, setWebScrapingCheckValue] = useState<boolean>(false);

  const getZoneListingDetailsByEventId = async (event_id: number) => {
    try {
      const zoneListingDetails =
        await new ZonesService().getZoneListingDetailsByEvent({
          event_id: event_id,
        });
      setEventZoneListingDetails(zoneListingDetails.data.data);

      const seatMapTbdData = await new ZonesService().getSeatMapTbdDropdownData(
        {
          event_id: event_id,
        }
      );
      setSeatMapTbdDropdownData(seatMapTbdData.data.data.seat_map_tbd_master);
    } catch (error) {
      await new LoggerService().log({
        payload: error,
        function_name: "getZoneListingDetailsByEventId (page: EventHeader.tsx)",
      });
    }
  };

  useEffect(() => {
    if (!metadata?.venue_url) {
      setVenueUrl(null);
    } else {
      setVenueUrl(
        checkValidUrl(metadata.venue_url)
          ? metadata?.venue_url
          : `https://${metadata?.venue_url}`
      );
    }
    if(metadata?.scraping_status_id) {
      setWebScrapingCheckValue(metadata?.scraping_status_id === 1)
    }
    if (metadata?.event_id) {
      getZoneListingDetailsByEventId(metadata.event_id);
    }

  }, [metadata]);

  useEffect(() => {
    if (
      metadataUpdateData &&
      metadataUpdateData.zone &&
      metadataUpdateData.zone?.length <= 0
    ) {
      setIsCheckedSeatMapTbd(metadataUpdateData.seat_map_tbd ?? false);
      setSeatMapTbdSelection(
        metadataUpdateData.seat_map_tbd
          ? metadataUpdateData.seat_map_tbd_days ?? 1
          : null
      );
      setIsZoneAvailable(false);
    } else {
      setIsZoneAvailable(true);
      if (metadataUpdateData?.seat_map_tbd) {
        setMetadataUpdateData({
          ...metadataUpdateData,
          seat_map_tbd: false,
          seat_map_tbd_days: null,
        });
      }
    }
  }, [metadataUpdateData]);

  const getTooltipTitleForNewListingCTA = () => {
    if (metadata?.event_id && metadata.event_id < 0) {
      return "Not on POS";
    }
    if (!metadata?.is_broker_available || (metadata?.zone?.length ?? 0) <= 0) {
      return "Venue association or zone info is not available";
    }
    return "Create New Listing";
  };

  const seatMapTbdHandler = (value: number) => {
    setSeatMapTbdSelection(value);
    setMetadataUpdateData({
      ...metadataUpdateData,
      seat_map_tbd_days: value,
    });
  };

  const seatMapTbdCheckboxHandler = (value: CheckboxChangeEvent) => {
    let newSeatNapTbdData = { seat_map_tbd: value.target.checked };
    setIsCheckedSeatMapTbd(value.target.checked);
    if (!value.target.checked) {
      newSeatNapTbdData = Object.assign(newSeatNapTbdData, {
        seat_map_tbd_days: null,
      });
    } else {
      newSeatNapTbdData = Object.assign(newSeatNapTbdData, {
        reminder_override: false,
        reminder_in_days: null,
        seat_map_tbd_days: 1,
      });
    }
    setMetadataUpdateData({
      ...metadataUpdateData,
      ...newSeatNapTbdData,
    });
  };

  const soldOutAndCancelledCheckboxHandler = (
    value: CheckboxChangeEvent,
    checkboxType: string
  ) => {
    if (checkboxType === "soldOut") {
      setIsSoldOutActivated(value.target.checked);
      setMetadataUpdateData({
        ...metadataUpdateData,
        is_sold_out: value.target.checked,
      });
      return;
    }
    setMetadataUpdateData({
      ...metadataUpdateData,
      is_event_cancelled: value.target.checked,
    });
  };

  const webScrapingCheckboxHandler = (value: any) => {
    setIsWebScrapingEvent(!webScarpingCheckValue);
    setWebScrapingCheckValue((prev) => !prev)
    console.log(metadataUpdateData)
    setMetadataUpdateData({
      ...metadataUpdateData,
      scraping_status_id: !webScarpingCheckValue === true ? 1 : 2,
    });
  };

  const semiAutomaticScrapingCheckboxHandler = () =>{
    let _scraping_status_id = metadataUpdateData?.scraping_status_id === 2 ? 3 : 2;
    let _has_general_admission_seats = metadataUpdateData?.has_general_admission_seats;
    if(_scraping_status_id === 3){
      _has_general_admission_seats = false;
    }
    setMetadataUpdateData({
      ...metadataUpdateData,
      scraping_status_id: _scraping_status_id,
      has_general_admission_seats: _has_general_admission_seats
    });
  }

  const generalAdmissionOnlyCheckboxHandler = (value: any) => {
    setIsWebScrapingEvent(false);
    setWebScrapingCheckValue(false)
    setMetadataUpdateData({
      ...metadataUpdateData,
      has_general_admission_seats: value.target.checked,
      scraping_status_id: metadata?.scraping_status_id === 1 ? 2 : metadata?.scraping_status_id,
    });
  };

  const getTooltipTextForCheckboxes = (checkboxName: string) => {
    switch (checkboxName) {
      case "web_scraping":
      case "cancelled":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        }
        break;
      case "semi_automatic_scraping":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        }
        break;
      case "sold_out":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        } else if (
          metadataUpdateData &&
          metadataUpdateData.is_event_cancelled
        ) {
          return "Event is cancelled.";
        } else {
          return "";
        }
      case "general_admission_seats_only":
        if (disableCheckboxes) {
          return "A zone record is in edit mode.";
        }
        else {
          return "General Admission Seats Available.";
        }
    }
  };

  const verificationHandler = () => {
    setMetadataUpdateData((prev: IEventMetadata | null)=> {
      const _prev = {...prev};
      if(_prev !== null) {
        _prev.is_verification_pending = !_prev.is_verification_pending;
      }
      return _prev;
    })
  };

  return (
    <div className="fillSeatsFormHeader alignItemsCenter">
      <div className="left">
        <div>
          <div className="metadataTitleWrap">
            <h3 className="flx alignItemsCenter">
              {titleCase(metadata?.event_name)} &nbsp;&nbsp;
              {metadata?.event_id && metadata.event_id < 0 && (
                <Tag className="danger tagSmall">Not on POS</Tag>
              )}
            </h3>
            <p className="mb0">
              (
              {venueUrl ? (
                <a target={"_blank"} rel="noopener noreferrer" href={venueUrl}>
                  {titleCase(metadata?.venue_name)}
                </a>
              ) : (
                titleCase(metadata?.venue_name)
              )}
              , {metadata?.venue_location})
              {metadata?.venue_fees && (
                <span style={{ marginLeft: "5px" }}>
                  <Tooltip title="Fees have been manually added from Edit Venue screen">
                    <i className="da icon-venue-fees" />
                  </Tooltip>
                  <span>Venue Fees: </span>
                  <span>${metadata.venue_fees}</span>
                </span>
              )}
            </p>
          </div>
          <div className="metaTimeDate">
            <div>
              {`${getCustomDate(
                metadata?.event_date,
                "ddd, MMM D, YYYY"
              )} at ${getCustomTimeFromDate(metadata?.event_date)}`}{" "}
              <Tooltip title="Days to event" placement="top">
                <Tag
                  className={getTagClassForEvents(metadata?.days_to_event)}
                  id="eventHeaderDaysToEvent"
                >
                  {metadata?.days_to_event}d
                </Tag>
              </Tooltip>
            </div>

            <div>
              <label>
                {metadata?.scraping_status_id === 1
                  ? "Next Scraping"
                  : "Next Reminder"}{" "}
                On :
                <span className="eventDateLabel" id="nextReminderDate">
                  {metadata?.due_date
                    ? getCustomDate(metadata?.due_date, "ddd, MMM D, YYYY")
                    : "N/A"}
                </span>
              </label>
            </div>
          </div>
        </div>

        {!isZoneAvailable && (
          <div className="seatsMidSec">
            <Tooltip
              title={
                metadataUpdateData?.scraping_status_id === 1
                  ? "Data is scraped"
                  : ""
              }
            >
              <Checkbox
                className="checkWithLbl"
                defaultChecked={isCheckedSeatMapTbd}
                onChange={(value) => seatMapTbdCheckboxHandler(value)}
                disabled={
                  !Boolean(
                    metadata &&
                      metadata.days_to_event &&
                      metadata.days_to_event - 1
                  ) || metadata?.scraping_status_id === 1
                }
              >
                <label>Seat Map TBD</label>
              </Checkbox>
            </Tooltip>
            <div className="selectDdWrap">
              <label>Reminder</label>
              <Select
                placeholder="Select"
                value={seatMapTbdSelection}
                onChange={(value) => seatMapTbdHandler(value)}
                disabled={
                  !isCheckedSeatMapTbd || metadata?.scraping_status_id === 1
                }
                options={seatMapTbdDropdownData ?? []}
              />
            </div>
          </div>
        )}
      </div>
      <div className="createNewListingRight" style={{ display: "flex" }}>
        <RBAC
          allowedPermissions={[ERbacPermissions.EVENT_SEATMAP_VERIFICATION]}
        >
          {metadataUpdateData &&
            metadataUpdateData.scraping_status_id === 3 &&
            metadataUpdateData.is_verification_pending !== undefined && (
              <div className="inputGroup" style={{marginRight: "15px"}}>
                <Tooltip title={"Seat Structure Verification Status"}>
                  <label>Structure Verified?</label>
                </Tooltip>
                <div>
                <Switch
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  className="yesNo"
                  checked={!metadataUpdateData.is_verification_pending}
                  onClick={verificationHandler}
                />
                </div>
              </div>
            )}
        </RBAC>
        <div className="ranking">
          {Object.keys(metadata ?? {}).length > 0 && (
            <div>
              {showSemiAutomaticScrapingCheckbox && (
                <Tooltip
                  title={getTooltipTextForCheckboxes("semi_automatic_scraping")}
                  placement="left"
                >
                  <Checkbox
                    className="checkWithLbl ml0 mr0"
                    checked={
                      metadataUpdateData?.scraping_status_id === 3
                        ? true
                        : false
                    }
                    onClick={() => semiAutomaticScrapingCheckboxHandler()}
                    disabled={disableCheckboxes}
                  >
                    <label>Desktop Scraping</label>
                  </Checkbox>
                </Tooltip>
              )}

              {showWebScrapingCheckbox &&
                metadataUpdateData?.scraping_status_id !== 3 && (
                  <Tooltip
                    title={getTooltipTextForCheckboxes("web_scraping")}
                    placement="left"
                  >
                    <Checkbox
                      className="checkWithLbl ml0 mr0"
                      checked={webScarpingCheckValue}
                      onClick={(value) => webScrapingCheckboxHandler(value)}
                      disabled={
                        disableCheckboxes ||
                        Boolean(
                          metadataUpdateData &&
                            metadataUpdateData.has_general_admission_seats
                        )
                      }
                    >
                      <label>Web Scraping</label>
                    </Checkbox>
                  </Tooltip>
                )}
              <Tooltip
                title={getTooltipTextForCheckboxes("cancelled")}
                placement="left"
              >
                <Checkbox
                  className="checkWithLbl ml0 mr0"
                  defaultChecked={metadata?.is_event_cancelled}
                  onChange={(value) =>
                    soldOutAndCancelledCheckboxHandler(value, "cancelled")
                  }
                  disabled={disableCheckboxes}
                >
                  <label>Cancelled</label>
                </Checkbox>
              </Tooltip>
              <Tooltip
                title={getTooltipTextForCheckboxes("sold_out")}
                placement="left"
              >
                <Checkbox
                  className="checkWithLbl ml0 mr0"
                  defaultChecked={metadata?.is_sold_out}
                  onChange={(value) =>
                    soldOutAndCancelledCheckboxHandler(value, "soldOut")
                  }
                  disabled={
                    disableCheckboxes ||
                    Boolean(
                      metadataUpdateData &&
                        metadataUpdateData.is_event_cancelled
                    )
                  }
                >
                  <label>Sold Out</label>
                </Checkbox>
              </Tooltip>
              <Tooltip
                title={getTooltipTextForCheckboxes(
                  "general_admission_seats_only"
                )}
                placement="left"
              >
                <Checkbox
                  className="checkWithLbl ml0 mr0"
                  checked={metadataUpdateData?.has_general_admission_seats}
                  onClick={(value) =>
                    generalAdmissionOnlyCheckboxHandler(value)
                  }
                  disabled={disableCheckboxes}
                >
                  <label>GA Available</label>
                </Checkbox>
              </Tooltip>
            </div>
          )}
          {metadata?.event_id && eventZoneListingDetails?.eventData && (
            <React.Fragment>
              {eventZoneListingDetails.eventData.pending_fills > 0 && (
                <RBAC
                  allowedPermissions={[
                    ERbacPermissions.ZONES_PENDING_ZONE_FILLS_VIEW,
                  ]}
                >
                  <div className="rankingInner positive">
                    <span className="rankNumber">
                      {eventZoneListingDetails.eventData.pending_fills}
                    </span>
                    <Link
                      to={`${
                        LeftNavRoutesEnum.ZONES_PENDING_FILLS
                      }?pendingfills=${getEncodedData({
                        event_id: eventZoneListingDetails.eventData.event_id,
                        invoice_date:
                          eventZoneListingDetails.eventData.invoice_date,
                      })}`}
                    >
                      Pending Fills
                    </Link>
                  </div>
                </RBAC>
              )}
              {eventZoneListingDetails.eventData.active_listings > 0 && (
                <div className="rankingInner default">
                  <span className="rankNumber">
                    {eventZoneListingDetails.eventData.active_listings}
                  </span>
                  {hasPermissions([
                    ERbacPermissions.ZONES_ACTIVE_LISTING_VIEW,
                  ]) ? (
                    <Link
                      to={`${
                        LeftNavRoutesEnum.ZONE_LISTINGS_ACTIVE_LISTINGS
                      }?event=${getEncodedData({
                        event_id: metadata.event_id,
                      })}`}
                    >
                      Active Listings
                    </Link>
                  ) : (
                    <div>Active Listings</div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
          <RBAC
            allowedPermissions={[ERbacPermissions.EVENT_CREATE_NEW_LISTING]}
          >
            <Tooltip
              title={getTooltipTitleForNewListingCTA()}
              placement={"top"}
            >
              {metadata?.is_broker_available &&
              metadata?.has_seat_structure &&
              metadata?.has_cost_and_availability &&
              metadata?.event_id &&
              metadata.event_id > 0 &&
              (metadata?.zone?.length ?? 0) > 0 ? (
                <Link
                  to={`${
                    LeftNavRoutesEnum.TRADING_EVENTS_CREATE_NEW_ZONE_LISTING
                  }?event=${getEncodedData({
                    event_id: metadata?.event_id,
                  })}`}
                  target={"_blank"}
                  className="leftMergin"
                >
                  <Button className="createListing createListingLinkBtn">
                    <i className="da icon-add-layer " />
                  </Button>
                </Link>
              ) : (
                <Button className="createListing createListingLinkBtn" disabled>
                  <i className="da icon-add-layer" />
                </Button>
              )}
            </Tooltip>
          </RBAC>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
