import axiosClient from "sharedServices/api/axios.services";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { InventoryPricingIssueRes, LongInventoryData, PricingResolutionMasterData } from "models/interfaces";
import { RoutesEnum } from "models/enums";
import { longInventoryType } from "models/types";

class LongInventoryService {
    public longInventoryData = async (longInventoryRequest: longInventoryType) => {
        return axiosClient.get<IApiBaseResponse<LongInventoryData>
        >(RoutesEnum.LONG_INVENTORY, {
            params: longInventoryRequest
        })
    }
    public getPricingResolution = async () => {
        return axiosClient.get<IApiBaseResponse<PricingResolutionMasterData[]>
        >(RoutesEnum.PRICING_RESOLUTIONS, {
            params: null
        })
    }
    
    public savePricingResolution = async (inventoryPricingIssueRes: InventoryPricingIssueRes) => {
        return axiosClient.patch<IApiBaseResponse<boolean>>(
          RoutesEnum.SAVE_PRICING_RESOLUTIONS,
          {
            ...inventoryPricingIssueRes,
          }
        );
      };
}

export { LongInventoryService }