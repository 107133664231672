export enum ERbacPermissions {
  // For Report Dashboard
  REPORT_DASHBOARD_VIEW = "report:dashboard:view",
  REPORT_DASHBOARD_MARK_FAVORITE = "report:dashboard:markFavorite",

  // For Schedules List
  SCHEDULES_VIEW_ALL = "schedules:view:all",
  SCHEDULES_VIEW_OWN = "schedules:view:own",
  SCHEDULES_VIEW_TEAM = "schedules:view:team",
  SCHEDULE_TOGGLE_STATUS = "schedule:toggle:status",
  SCHEDULE_EDIT = "schedule:edit",
  SCHEDULE_DELETE = "schedule:delete",
  SCHEDULE_FORCE_RUN = "schedule:forceRun",

  // For Reports
  REPORT_INVOICES_RESOLVE_ISSUE = "report:invoices:resolveIssue",
  
  // For Gross Profit Report
  REPORT_GROSS_PROFIT_VIEW_ALL = "report:grossProfit:view:all",
  REPORT_GROSS_PROFIT_VIEW_OWN = "report:grossProfit:view:own",
  REPORT_GROSS_PROFIT_SCHEDULE = "report:grossProfit:schedule",
  REPORT_GROSS_PROFIT_EXPORT = "report:grossProfit:export",

  // For Gross Profit Snapshot
  REPORT_GROSS_PROFIT_SNAPSHOT_VIEW_ALL = "report:grossProfitSnapshot:view:all",
  REPORT_GROSS_PROFIT_SNAPSHOT_VIEW_OWN = "report:grossProfitSnapshot:view:own",
  REPORT_GROSS_PROFIT_SNAPSHOT_SCHEDULE = "report:grossProfitSnapshot:schedule",
  REPORT_GROSS_PROFIT_SNAPSHOT_EXPORT = "report:grossProfitSnapshot:export",

  // For Long Inventory Report
  REPORT_LONG_INVENTORY_VIEW_ALL = "report:longInventory:view:all",
  REPORT_LONG_INVENTORY_VIEW_OWN = "report:longInventory:view:own",
  REPORT_LONG_INVENTORY_VIEW_TEAM = "report:longInventory:view:team",
  REPORT_LONG_INVENTORY_SCHEDULE = "report:longInventory:schedule",
  REPORT_LONG_INVENTORY_EXPORT = "report:longInventory:export",

  // For Long Inventory Not Listed Report
  REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_ALL = "report:longInventoryNotListed:view:all",
  REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_OWN = "report:longInventoryNotListed:view:own",
  REPORT_LONG_INVENTORY_NOT_LISTED_VIEW_TEAM = "report:longInventoryNotListed:view:team",
  REPORT_LONG_INVENTORY_NOT_LISTED_SCHEDULE = "report:longInventoryNotListed:schedule",
  REPORT_LONG_INVENTORY_NOT_LISTED_EXPORT = "report:longInventoryNotListed:export",

  // For Long Inventory Snapshot
  REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_ALL = "report:longInventorySnapshot:view:all",
  REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_OWN = "report:longInventorySnapshot:view:own",
  REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_TEAM = "report:longInventorySnapshot:view:team",
  REPORT_LONG_INVENTORY_SNAPSHOT_SCHEDULE = "report:longInventorySnapshot:schedule",
  REPORT_LONG_INVENTORY_SNAPSHOT_EXPORT = "report:longInventorySnapshot:export",
  REPORT_LONG_INVENTORY_SNAPSHOT_VIEW_ALL_BROKER = "report:longInventorySnapshot:view:allBroker",

  // For Broker Tags Issues Report
  REPORT_BROKER_TAGS_ISSUES_VIEW_ALL = "report:brokerTagsIssues:view:all",
  REPORT_BROKER_TAGS_ISSUES_VIEW_OWN = "report:brokerTagsIssues:view:own",
  REPORT_BROKER_TAGS_ISSUES_SCHEDULE = "report:brokerTagsIssues:schedule",
  REPORT_BROKER_TAGS_ISSUES_EXPORT = "report:brokerTagsIssues:export",

  // For Shorts Pending Delivery Report
  REPORT_SHORTS_PENDING_DELIVERY_VIEW_ALL = "report:shortsPendingDelivery:view:all",
  REPORT_SHORTS_PENDING_DELIVERY_VIEW_OWN = "report:shortsPendingDelivery:view:own",
  REPORT_SHORTS_PENDING_DELIVERY_SCHEDULE = "report:shortsPendingDelivery:schedule",
  REPORT_SHORTS_PENDING_DELIVERY_EXPORT = "report:shortsPendingDelivery:export",

  // For Consignment Sales Report
  REPORT_CONSIGNMENT_SALES_VIEW_ALL = "report:consignmentSales:view:all",
  REPORT_CONSIGNMENT_SALES_VIEW_OWN = "report:consignmentSales:view:own",
  REPORT_CONSIGNMENT_SALES_SCHEDULE = "report:consignmentSales:schedule",
  REPORT_CONSIGNMENT_SALES_EXPORT = "report:consignmentSales:export",

  // For Consignment Pending Buy In Report
  REPORT_CONSIGNMENT_PENDING_BUY_IN_VIEW_ALL = "report:consignmentPendingBuyIn:view:all",
  REPORT_CONSIGNMENT_PENDING_BUY_IN_VIEW_OWN = "report:consignmentPendingBuyIn:view:own",
  REPORT_CONSIGNMENT_PENDING_BUY_IN_SCHEDULE = "report:consignmentPendingBuyIn:schedule",
  REPORT_CONSIGNMENT_PENDING_BUY_IN_EXPORT = "report:consignmentPendingBuyIn:export",

  // FOR INVOICES NEEDING ATTENTION
  REPORT_INVOICES_NEEDING_ATTENTION_VIEW_ALL = "report:invoicesNeedingAttention:view:all",
  REPORT_INVOICES_NEEDING_ATTENTION_VIEW_OWN = "report:invoicesNeedingAttention:view:own",
  REPORT_INVOICES_NEEDING_ATTENTION_VIEW_SCHEDULE = "report:invoicesNeedingAttention:schedule",
  REPORT_INVOICES_NEEDING_ATTENTION_VIEW_EXPORT = "report:invoicesNeedingAttention:export",

  // FOR RECEIVABLES DETAILS
  REPORT_RECEIVABLES_DETAILS_VIEW_ALL = "report:receivableDetails:view:all",
  REPORT_RECEIVABLES_DETAILS_VIEW_OWN = "report:receivableDetails:view:own",
  REPORT_RECEIVABLES_DETAILS_VIEW_SCHEDULE = "report:receivableDetails:schedule",
  REPORT_RECEIVABLES_DETAILS_VIEW_EXPORT = "report:receivableDetails:export",

  // FOR RECEIVABLES RETAIL
  REPORT_RECEIVABLES_RETAIL_VIEW_ALL = "report:receivablesRetail:view:all",
  REPORT_RECEIVABLES_RETAIL_VIEW_OWN = "report:receivablesRetail:view:own",
  REPORT_RECEIVABLES_RETAIL_VIEW_SCHEDULE = "report:receivablesRetail:schedule",
  REPORT_RECEIVABLES_RETAIL_VIEW_EXPORT = "report:receivablesRetail:export",

  // FOR ACTIVE ZONE LISTING
  REPORT_ACTIVE_ZONE_LISTING_VIEW_ALL = "report:activeZoneListing:view:all",
  REPORT_ACTIVE_ZONE_LISTING_VIEW_OWN = "report:activeZoneListing:view:own",
  REPORT_ACTIVE_ZONE_LISTING_VIEW_TEAM = "report:activeZoneListing:view:team",
  REPORT_ACTIVE_ZONE_LISTING_VIEW_SCHEDULE = "report:activeZoneListing:schedule",

  // FOR OTHER COSTS AND CREDITS REPORT
  REPORT_OTHER_COSTS_AND_CREDITS_VIEW_ALL = "report:otherCostsAndCredits:view:all",
  REPORT_OTHER_COSTS_AND_CREDITS_VIEW_SCHEDULE = "report:otherCostsAndCredits:schedule",
  REPORT_OTHER_COSTS_AND_CREDITS_EXPORT = "report:otherCostsAndCredits:export",

  // For User Management
  USER_DETAILS_VIEW_ALL = "user:details:view:all",
  USER_DETAILS_VIEW_OWN = "user:details:view:own",
  USER_DETAILS_VIEW_TEAM = "user:details:view:team",
  USER_CREATE = "user:create",
  USER_EDIT = "user:edit",
  USER_DELETE = "user:delete",

  // For Broker Commissions
  SETTINGS_COMMISSIONS_VIEW = "settings:commissions:view",

  // For Venue Details
  VENUE_DETAILS_VIEW_ALL = "trading:venue:view:all",
  VENUE_DETAILS_VIEW_OWN = "trading:venue:view:own",
  VENUE_DETAILS_VIEW_TEAM = "trading:venue:view:team",
  VENUE_DETAILS_EDIT = "trading:venue:edit",
  VENUE_DETAILS_EDIT_BROKER_SELECTION = "trading:venue:edit:brokerSelection",
  VENUE_DETAILS_EDIT_LISTER_SELECTION = "trading:venue:edit:listerSelection",
  VENUE_DETAILS_EDIT_CHECKER_SELECTION = "trading:venue:edit:checkerSelection",
  VENUE_DETAILS_EDIT_SEAT_MAP_CREATOR_SELECTION = "trading:venue:edit:seatMapCreatorSelection",
  VENUE_DETAILS_EDIT_SEAT_INTELLIGENCE_SELECTION = "trading:venue:edit:seatIntelligence",
  VENUE_BULK_ASSIGN_ALL = "trading:venue:bulkAssign:all",
  VENUE_BULK_ASSIGN_TEAM = "trading:venue:bulkAssign:team",

  // For Event Details
  EVENT_DETAILS_VIEW_ALL = "trading:event:view:all",
  EVENT_DETAILS_VIEW_OWN = "trading:event:view:own",
  EVENT_DETAILS_VIEW_TEAM = "trading:event:view:team",
  EVENT_METADATA_EDIT = "trading:event:metadata:edit",
  EVENT_CREATE_NEW_LISTING = "trading:event:createNewListing",
  EVENT_BULK_CREATE_NEW_LISTING = "trading:event:bulkCreateNewListing",
  EVENT_COPYDOWN_METADATA = "trading:event:metadata:copydown",
  EVENT_QUICK_UPDATE = "trading:event:quickUpdate",
  EVENT_SEATMAP_VERIFICATION = "trading:event:verifySeatmap",

  // For Edit Event Page View
  EVENT_METADATA_EDIT_VIEW = "event:metadata:edit:view",

  // For Filters
  FILTER_BROKER_TAGS_VIEW_ALL = "commonFilter:brokerTags:view:all",
  FILTER_BROKER_TAGS_VIEW_TEAM = "commonFilter:brokerTags:view:team",

  FILTER_BROKER_USER_SELECTION_ALL = "commonFilter:broker:all",
  FILTER_SHIPPER_USER_SELECTION_ALL = "commonFilter:shipper:all",
  FILTER_LISTER_USER_SELECTION_ALL = "commonFilter:lister:all",
  FILTER_LISTER_USER_SELECTION_TEAM = "commonFilter:lister:team",
  FILTER_BUYER_USER_SELECTION_ALL = 'commonFilter:buyer:all',
  FILTER_BUYER_USER_SELECTION_TEAM = 'commonFilter:buyer:team',
  FILTER_SEAT_MAP_CREATOR_USER_SELECTION_ALL = "commonFilter:seatMapCreator:all",
  FILTER_SEAT_MAP_CREATOR_USER_SELECTION_TEAM = "commonFilter:seatMapCreator:team",
  FILTER_CHECKER_USER_SELECTION_ALL = "commonFilter:checker:all",
  FILTER_CHECKER_USER_SELECTION_TEAM = "commonFilter:checker:team",
  FILTER_VENUE_SELECTION_ALL = "commonFilter:venue:all",
  FILTER_VENUE_SELECTION_OWN = "commonFilter:venue:own",
  FILTER_SHOW_CATEGORY_INCLUDE_MY_BROKER_CARDS = "commonFilter:showCategory:includeMyBrokerCards",

  FILTER_LISTER_TAGS_ALL = "commonFilter:listerTags:view:all",
  FILTER_LISTER_TAGS_TEAM = "commonFilter:listerTags:view:team",
  FILTER_LISTER_TAGS_OWN = "commonFilter:listerTags:view:own",
  FILTER_SHOW_CATEGORY_VIEW = "commonFilter:showCategory:view",

  // For Housekeeping Cancelled Shows
  // Active Inventory
  HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_ALL = "housekeeping:inventory:active:view:all",
  HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_OWN = "housekeeping:inventory:active:view:own",
  HOUSEKEEPING_INVENTORY_ACTIVE_VIEW_TEAM = "housekeeping:inventory:active:view:team",
  HOUSEKEEPING_INVENTORY_ACTIVE_CANCEL_INVENTORY = "housekeeping:inventory:active:cancelInventory",
  // Sold Inventory
  HOUSEKEEPING_INVENTORY_SOLD_VIEW_ALL = "housekeeping:inventory:sold:view:all",
  HOUSEKEEPING_INVENTORY_SOLD_VIEW_OWN = "housekeeping:inventory:sold:view:own",
  HOUSEKEEPING_INVENTORY_SOLD_VIEW_TEAM = "housekeeping:inventory:sold:view:team",
  HOUSEKEEPING_INVENTORY_SOLD_CANCEL_INVOICE = "housekeeping:inventory:sold:cancelInvoice",
  // Unfilled Zones
  HOUSEKEEPING_UNFILLED_ZONES_VIEW_ALL = "housekeeping:unfilled:zones:view:all",
  HOUSEKEEPING_UNFILLED_ZONES_VIEW_TEAM = "housekeeping:unfilled:zones:view:team",
  HOUSEKEEPING_UNFILLED_ZONES_VIEW_OWN = "housekeeping:unfilled:zones:view:own",
  HOUSEKEEPING_UNFILLED_ZONES_CANCEL_INVOICE = "housekeeping:unfilled:zones:cancelInvoice",
  // Cancelled Inventory
  HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_ALL = "housekeeping:report:cancelledInventory:view:all",
  HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_TEAM = "housekeeping:report:cancelledInventory:view:team",
  HOUSEKEEPING_CANCELLED_INVENTORY_VIEW_OWN = "housekeeping:report:cancelledInventory:view:own",
  HOUSEKEEPING_CANCELLED_INVENTORY_SCHEDULE = "housekeeping:report:cancelledInventory:schedule",
  HOUSEKEEPING_CANCELLED_INVENTORY_EXPORT = "housekeeping:report:cancelledInventory:export",
  // For Housekeeping Event Mapping
  HOUSEKEEPING_EVENT_MAPPING_VIEW_ALL = "housekeeping:eventMapping:view:all",
  HOUSEKEEPING_EVENT_MAPPING_VIEW_TEAM = "housekeeping:eventMapping:view:team",
  HOUSEKEEPING_EVENT_MAPPING_VIEW_OWN = "housekeeping:eventMapping:view:own",

  // Lister Dashboard
  LISTER_DASHBOARD_VIEW = "dashboard:lister:view",

  // Checker Dashboard
  CHECKER_DASHBOARD_VIEW = "dashboard:checker:view",

  // Seat Map Creator Dashboard
  SEATMAPCREATOR_DASHBOARD_VIEW = "dashboard:seatMapCreator:view",

  // Operations Dashboard
  OPERATIONS_DASHBOARD_VIEW = "dashboard:operations:view",

  // Bot Performance Dashboard
  BOT_PERFORMANCE_DASHBOARD_VIEW = "dashboard:botPerformance:view",

  // Performance Analysis Dashboard
  PERFORMANCE_ANALYSIS_DASHBOARD_VIEW = "dashboard:performanceAnalysis:view",
  
  // Zones - High Risk Listings
  ZONES_HIGH_RISK_LISTINGS_VIEW = "zones:highRiskListings:view",

  // Zones - Pending Zone Fills
  ZONES_PENDING_ZONE_FILLS_VIEW = "zones:pendingZoneFills:view",
  ZONES_PENDING_ZONE_FILLS_VIEW_ALL = "zones:pendingZoneFills:view:all",
  ZONES_PENDING_ZONE_FILLS_VIEW_OWN = "zones:pendingZoneFills:view:own",
  ZONES_PENDING_ZONE_FILLS_INSIGHTS_VIEW = "zones:pendingZoneFills:insights:view",
  ZONES_PENDING_ZONE_FILLS_ASSIGNMENT_CTA_ENABLE = "zones:pendingZoneFills:purchaseAssignmentCTA:enable",

  ZONES_ACTIVE_LISTING_VIEW = "zones:activeListings:view",
  ZONES_ACTIVE_LISTING_BROADCAST_ACTION = "zones:activeListing:action:broadcast",
  ZONES_ACTIVE_LISTING_DELETE_ACTION = "zones:activeListing:action:delete",
  ZONES_ACTIVE_LISTING_SPLIT_ACTION = "zones:activeListing:action:splitListing",
  ZONES_SAVE_EDITED_LISTINGS = "zones:allZoneListings:saveEditedListing",
  ZONES_ACTIVE_LISTING_UNBROADCAST_FILTERED_ACTION = "zones:activeListing:action:unbroadcastFiltered",

  // Purchase Queue
  PURCHASE_QUEUE_VIEW_ALL = "purchase:purchaseQueue:view:all",
  PURCHASE_QUEUE_VIEW_TEAM = "purchase:purchaseQueue:view:team",
  PURCHASE_QUEUE_VIEW_OWN = "purchase:purchaseQueue:view:own",
  PURCHASE_QUEUE_MENU_CHANGE_BUYER = "purchase:purchaseQueue:menu:changeBuyer",
  PURCHASE_QUEUE_KEBAB_MENU_CLOSE_ISSUE = "purchase:purchaseQueue:kebabMenu:closeIssue",

  // shipping Queue
  SHIPPING_QUEUE_VIEW_ALL = "shipping:shippingQueue:view:all",
  SHIPPING_QUEUE_VIEW_TEAM = "shipping:shippingQueue:view:team",
  SHIPPING_QUEUE_VIEW_OWN = "shipping:shippingQueue:view:own",
  SHIPPING_QUEUE_MENU_CHANGE_SHIPPER = "shipping:shippingQueue:menu:changeShipper",
  REPORT_DELIVERED_TICKETS_VIEW_ALL = "report:deliveredTickets:view:all",
  SHIPPING_QUEUE_KEBAB_MENU_CLOSE_ISSUE = "shipping:shippingQueue:kebabMenu:closeIssue",
}
