import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useAppSelector } from "app/hooks";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import {
  IEventMetadata,
  IListElement,
  IListingFormData,
  IMarkupData,
  IMultiSelectOptions,
} from "models/interfaces";
import { splitTypes } from "models/Slices/FilterSlice";
import React, { useEffect, useRef, useState } from "react";
import { ZonesService } from "services";
import { off, on } from "sharedServices";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import {
  getCustomDate,
  getDateObject,
  getOffsetDate,
  getCustomTimeFromDate
} from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { 
  addToolTip, 
  getInHandDateValue, 
  getPriorToEventDays, 
  titleCase, 
  twoDecimalPlacesNumber 
} from "utils/commonFunction";
import { AppSetting } from "utils/appSettings";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { CurrencyCode } from "models/enums";
import { getLastRowNameFromRowsRange } from "./createNewListing.utils";
import CreateListingInHandDate from "./CreateListingInHandDate";

let currentCustomZoneData: any | null = null;
const flaggedStockTypes = [1, 2, 4, 5, 7];
const disclosureLabel = "piggy back seats";

const CreateListingModal: React.FC<{
  zones: IListingFormData[];
  setZones: Function;
  stockTypesMaster: IListElement[];
  setStockTypesMaster: Function;
  isLoadingModalData: boolean;
  setIsLoadingModalData: Function;
  listingFormData: IListingFormData | undefined;
  setListingFormData: Function;
  setBrokerTags: Function;
  setListerTags: Function;
  setVendors: Function;
  setDefaultVendor: Function;
  setDefaultInternalNotes: Function;
  applyToAll: boolean;
  setApplyToAll: Function;
  setCurrentZoneId: Function;
  currentZoneId: number|null;
  onGetMaxTicketsAllowed: Function;
  showApplyToAll: boolean;
  eventMetaData: IEventMetadata | null;
}> = ({
  zones,
  setZones,
  stockTypesMaster,
  setStockTypesMaster,
  isLoadingModalData,
  setIsLoadingModalData,
  listingFormData,
  setListingFormData,
  setBrokerTags,
  setListerTags,
  setVendors,
  setDefaultVendor,
  setDefaultInternalNotes,
  applyToAll,
  setApplyToAll,
  setCurrentZoneId,
  currentZoneId,
  onGetMaxTicketsAllowed,
  showApplyToAll,
  eventMetaData
}) => {
  const [form] = Form.useForm();
  const actionRef = useRef<HTMLDivElement | null>(null);
  const [showCreateListingModal, setShowCreateListingModal] = useState(false);
  const [seatTypesMaster, setSeatTypesMaster] = useState<IListElement[]>([]);
  const splitTypesMaster = useAppSelector(splitTypes);
  
  const [attributesMaster, setAttributesMaster] = useState<IListElement[]>([]);
  const [disclosuresMaster, setDisclosuresMaster] = useState<IListElement[]>(
    []
  );
  const [maxTicketsAllowed, setMaxTicketsAllowed] = useState<string | null>(
    null
  );
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [defaultMarkup, setDefaultMarkup] = useState<IMarkupData|null>(null);
  const [isSeatTypePiggyback, setIsSeatTypePiggyBack] = useState(false);
  const [disableElectronicTransferSwitch, setDisableElectronicTransferSwitch] =
    useState<boolean>();
  const [disableSave, setDisableSave] = useState(false);
  const [inHandDateType, setInHandDateType] = useState(0);
  const [ifSaved, setIfSaved] = useState(false);
  const [prevDaysPriorToEvent, setPrevDaysPriorToEvent] = useState<number | null>(null);
  const [isInhandTypeDateRange, setIsInhandTypeDateRange] = useState<boolean>(false);

  const actionHandler = async () => {
    if (currentCustomZoneData.action === "create") {
      try {
        setShowCreateListingModal(true);
        setIsLoadingModalData(true);
        setCurrentZoneId(currentCustomZoneData.data.zoneId);
        const res = await new ZonesService().getZoneDetailsForZoneListing({
          venue_id: currentCustomZoneData.data.eventMetadata.venue_id,
        });
        const zoneDetailsResponseData = res.data.data;
        setStockTypesMaster(zoneDetailsResponseData.stock_type_master);
        setSeatTypesMaster(zoneDetailsResponseData.seat_type_master);
        setAttributesMaster(zoneDetailsResponseData.inventory_attributes_master);
        setDisclosuresMaster(zoneDetailsResponseData.ticket_disclosures_master);
        setBrokerTags(zoneDetailsResponseData.zone_details?.broker_tags);
        setListerTags(zoneDetailsResponseData.zone_details?.lister_tags);
        setVendors(zoneDetailsResponseData.vendor);
        setDefaultVendor(zoneDetailsResponseData.markup_details?.default_vendor_id);
        setDefaultInternalNotes(zoneDetailsResponseData.markup_details?.defaultInternalNotes)
        setMaxTicketsAllowed(zoneDetailsResponseData.zone_details.max_tickets_allowed);
        onGetMaxTicketsAllowed(zoneDetailsResponseData.zone_details.max_tickets_allowed);
        setDefaultMarkup(zoneDetailsResponseData.markup_details);
        let zone = zones.find(
          (eachZone) => eachZone.id === currentCustomZoneData.data.zoneId
        );
        setListingFormData({
          listing_quantity:
            zone?.listing_quantity ??
            zoneDetailsResponseData.zone_details.default_listing_quantity ??
            6,
          split_type_id:
            zone?.split_type_id ??
            zoneDetailsResponseData.zone_details?.split_type ??
            1,
          listing_price: zone?.listing_price ?? calculateListPrice(zoneDetailsResponseData.markup_details, (( zone && zone.cost && zone?.cost > 0 ? (zone.cost ?? 0) + (eventMetaData?.venue_fees ?? 0) : (zone?.cost ?? 0) )), zone?.min_markup_value ?? 0), //replace undefined with ticket_cost + markup
          listing_rows:
            zone?.listing_rows ?? getLastRowNameFromRowsRange(zone?.rows ?? ""), // ?? currentCustomZoneData.data.zoneData.rows,
          low_seat: zone?.low_seat ?? 999,
          stock_type_id:
            zone?.stock_type_id ??
            currentCustomZoneData.data.eventMetadata.stock_type_id,
          in_hand_date:
            zone?.in_hand_date ??
            currentCustomZoneData.data.eventMetadata.event_date,
          seat_type_id: zone?.seat_type_id ?? 1,
          disclosures: zone?.disclosures,
          attributes: zone?.attributes,
          electronic_transfer: zone?.electronic_transfer ?? false,
          vendor_id: zone?.vendor_id,
          broker_tag_id: zone?.broker_tag_id,
          lister_tag_id: zone?.lister_tag_id,
          public_notes: zone?.public_notes,
          internal_notes: zone?.internal_notes,
          listing_second_row: zone?.listing_second_row ?? ""
        });
        if (
          seatTypesMaster
            .find((seatType) => seatType.value === zone?.seat_type_id)
            ?.label.toUpperCase() === "PIGGYBACK"
        ) {
          setIsSeatTypePiggyBack(true);
        } else {
          setIsSeatTypePiggyBack(false);
        }
        if (
          (zone?.stock_type_id &&
            flaggedStockTypes.includes(zone.stock_type_id)) ?? 
          (currentCustomZoneData.data.eventMetadata.stock_type_id &&
            flaggedStockTypes.includes(
              currentCustomZoneData.data.eventMetadata.stock_type_id
            ))
        ) {
          setDisableElectronicTransferSwitch(true);
        } else if (
          (zone?.stock_type_id &&
            !flaggedStockTypes.includes(zone.stock_type_id)) ??
          (currentCustomZoneData.data.eventMetadata.stock_type_id &&
            !flaggedStockTypes.includes(
              currentCustomZoneData.data.eventMetadata.stock_type_id
            ))
        ) {
          setDisableElectronicTransferSwitch(false);
        }
        setIsLoadingModalData(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...listingFormData,
      zone_section: zones.find((zone) => zone.id === currentZoneId)
        ?.zone_section,
      in_hand_date: getInhandDateRange(),
      days_prior_to_event: getPriorToEventDays(
        currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0, 
        defaultMarkup,
        inHandDateType,
        prevDaysPriorToEvent
      ),
    });
  }, [listingFormData]);

  useEffect(() => {
    const handler = (data: CustomEvent) => {
      currentCustomZoneData = data.detail;
      actionRef?.current?.click();
    };
    
    on("zoneListingAction:click", handler);
    return () => {
      off("zoneListingAction:click", handler);
    };
  }, []);

  useEffect(() => {
    if (showCreateListingModal) {
      !ifSaved && inHandDateType !== 1 && setInHandDateType(
        getPriorToEventDays(
          currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0, 
          defaultMarkup,
          inHandDateType,
          prevDaysPriorToEvent,
          true
        )
      );
    } 
  }, [showCreateListingModal]);

  const getInhandDateRange = () => {
    if (isInhandTypeDateRange) {
      return zones.find((zone) => zone.id === currentZoneId)?.in_hand_date ??
      getDateObject(
        getOffsetDate(
          OffsetMode.SUBTRACT,
          getPriorToEventDays(
            currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0, 
            defaultMarkup,
            2,
            null
          ),
          OffsetType.DAYS,
          "YYYY-MM-DD",
          getDateObject(listingFormData?.in_hand_date)
        )
      );
    }

    return getDateObject(
      getOffsetDate(
        OffsetMode.SUBTRACT,
        getPriorToEventDays(
          currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0, 
          defaultMarkup,
          2,
          null
        ),
        OffsetType.DAYS,
        "YYYY-MM-DD",
        getDateObject(currentCustomZoneData?.data?.eventMetadata?.event_date)
      )
    )
  }

  const cancelHandler = () => {
    if (isValueChanged) {
      const header = (
        <span className="text-danger">{"All unsaved data will be lost."}</span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => {
          setIsValueChanged(false);
          setShowCreateListingModal(false);
        },
        () => console.log("cancelled"),
        "400px"
      );
    } else {
      setShowCreateListingModal(false);
      setIsValueChanged(false);
    }
  };

  const onFinish = (values: IListingFormData) => {
    setIfSaved(true);
    if (values) {
      if (applyToAll) {
        setZones((prev: IListingFormData[]) =>
          prev.map((eachZone) =>
            eachZone.is_selected
              ? {
                  ...eachZone,
                  ...values,
                  zone_section: eachZone.zone_section,
                  listing_rows: eachZone.id !== currentZoneId ? eachZone.listing_rows ?? getLastRowNameFromRowsRange(eachZone.rows ?? "") : values.listing_rows,
                  stock_type: stockTypesMaster
                    .find((type) => type.value === values.stock_type_id)
                    ?.name?.toUpperCase(),
                  seat_type: seatTypesMaster
                    .find((type) => type.value === values.seat_type_id)
                    ?.label.toUpperCase(),
                  split_type: splitTypesMaster
                    .find(
                      (splitType) => splitType.value === values.split_type_id
                    )
                    ?.label.toUpperCase(),
                  listing_price:
                    eachZone.id !== currentZoneId
                      ? eachZone.listing_price
                        ? eachZone.listing_price
                        : calculateListPrice(defaultMarkup, (( eachZone && eachZone.cost && eachZone?.cost > 0 ? (eachZone.cost ?? 0) + (eventMetaData?.venue_fees ?? 0) : (eachZone.cost ?? 0) )), eachZone.min_markup_value ?? 0)
                      : values.listing_price,
                  in_hand_date: getInHandDateValue(
                    inHandDateType, 
                    values.days_prior_to_event ?? 0, 
                    values?.in_hand_date, 
                    currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0
                  )
                }
              : eachZone
          )
        );
        setApplyToAll(false);
        setShowCreateListingModal(false);
      } else {
        setZones((prev: IListingFormData[]) =>
          prev.map((eachZone) => {
            if (eachZone.id === currentZoneId) {
              return {
                ...eachZone,
                ...values,
                stock_type: stockTypesMaster
                  .find((type) => type.value === values.stock_type_id)
                  ?.name?.toUpperCase(),
                seat_type: seatTypesMaster
                  .find((type) => type.value === values.seat_type_id)
                  ?.label.toUpperCase(),
                split_type: splitTypesMaster
                  .find((splitType) => splitType.value === values.split_type_id)
                  ?.label.toUpperCase(),
                in_hand_date: getInHandDateValue(
                  inHandDateType, 
                  values?.days_prior_to_event ?? 0, 
                  values?.in_hand_date, 
                  currentCustomZoneData?.data?.eventMetadata.days_to_event ?? 0
                )
              };
            } else {
              return eachZone;
            }
          })
        );
        setPrevDaysPriorToEvent(values.days_prior_to_event ?? null);
        setIsInhandTypeDateRange(inHandDateType === 2);
        setShowCreateListingModal(false);
      }
    }
  };

  useEffect(() => {
    let tempDisclosures: IMultiSelectOptions[] = [];
    if (isSeatTypePiggyback) {
      tempDisclosures = form.getFieldValue("disclosures") ?? [];
      let disclosure = disclosuresMaster.find(
        (each) => each.label.toLowerCase() === disclosureLabel
      );
      if (
        tempDisclosures.length &&
        tempDisclosures.findIndex(
          (temp) => temp.label.toLowerCase() === disclosureLabel
        ) === -1 &&
        disclosure
      ) {
        tempDisclosures.push({
          label: disclosure.label,
          value: disclosure.value.toString(),
          key: disclosure.value.toString(),
        });
      }
      if (!tempDisclosures.length && disclosure) {
        tempDisclosures.push({
          label: disclosure.label,
          value: disclosure.value.toString(),
          key: disclosure.value.toString(),
        });
      }
      form.setFieldsValue({
        disclosures: tempDisclosures,
      });
    } else {
      tempDisclosures =
        form
          .getFieldValue("disclosures")
          ?.filter(
            (each: IMultiSelectOptions) =>
              each.label.toLowerCase() !== disclosureLabel
          ) ?? [];
      form.setFieldsValue({
        listing_second_row: undefined,
        disclosures: tempDisclosures,
      });
    }
  }, [isSeatTypePiggyback]);

  const calculateListPrice = (markupData: IMarkupData|null, ticketCost: number, minListPrice: number ) => {

    if(markupData && markupData.defaultMarkupType && markupData.defaultMarkup) {
      if(markupData.defaultMarkupType === 1 && markupData.defaultMarkup) {
        ticketCost = ticketCost ? (ticketCost + ((markupData.defaultMarkup * ticketCost)/100)) : 0
      }
      else if (markupData.defaultMarkupType === 2 && markupData.defaultMarkup) {
        ticketCost = ticketCost + markupData.defaultMarkup;
      }
    }
    return Math.max(ticketCost, minListPrice)

  };

  const checkForDuplicates = (checked: boolean) => {
    if(checked) {
      const currentZoneSection = form.getFieldValue("zone_section");
      const currentListingRow = form.getFieldValue("listing_rows");
      let temp = zones
        .filter((eachZone) => eachZone.is_selected)
        .map((eachZone) => ({
          ...eachZone,
          listing_rows:
            eachZone.id === currentZoneId ? currentListingRow : eachZone.rows,
        }));
      let duplicates = temp.filter(
        (each) =>
          each.zone_section === currentZoneSection &&
          each.listing_rows === currentListingRow
      );
      if (duplicates?.length > 1) {
        message.error({
          content:
            "Duplicate Zone Section and Listing Row combinations not allowed!",
          duration: 5,
          className: "toastMsg savedFailed",
        });
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    } else {
      setDisableSave(false);
    }
  };

  const getCostWithVenueFees = () => {
    if (zones) {
      const cost = zones.find((zone) => zone.id === currentZoneId)?.cost;
      if (cost) {
        return FormatCurrencyUnit(
          twoDecimalPlacesNumber(
            cost + (eventMetaData?.venue_fees ?? 0)
          ).toLocaleString(),
          CurrencyCode.Usd
        );
      } else {
        return FormatCurrencyUnit(
          twoDecimalPlacesNumber(cost).toLocaleString(),
          CurrencyCode.Usd
        );
      }
    }
  };

  return (
    <React.Fragment>
      <div ref={actionRef} style={{ display: "none" }} onClick={actionHandler}>
        Actions
      </div>
      {showCreateListingModal && (
        <Modal
          closable={false}
          width={730}
          title={false}
          footer={false}
          visible={showCreateListingModal}
          centered
          className="editModal"
        >
          <div className="modalHeader">
            <div className="modalHeadingMain">Inventory Listing Form</div> 
            <div className="modalHeaderChild">
              <div>
                <div>
                  <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={
                      AppSetting.EVENT_URL +
                      currentCustomZoneData?.data?.eventMetadata.event_id
                    }
                  >
                    {currentCustomZoneData?.data?.eventMetadata?.event_name?.length > 40 ? 
                      addToolTip(
                        `${titleCase(currentCustomZoneData?.data?.eventMetadata?.event_name)}`, "right","pointer", 37) : 
                      `${titleCase(currentCustomZoneData?.data?.eventMetadata?.event_name)}`}
                  </a>
                </div>
                <div className="modalHeaderInner">
                  {`${getCustomDate(
                        currentCustomZoneData?.data?.eventMetadata?.event_date,
                        "ddd, MMM D, YYYY"
                      )} at ${getCustomTimeFromDate(
                        currentCustomZoneData?.data?.eventMetadata?.event_date
                      )}`}
                </div>
              </div>
              <div className="modalHeaderChildRight">
                <div>
                  {currentCustomZoneData?.data?.eventMetadata?.venue_name?.length > 40 ? 
                    addToolTip(titleCase(currentCustomZoneData?.data?.eventMetadata?.venue_name),"right", "default", 37) : 
                      titleCase(currentCustomZoneData?.data?.eventMetadata?.venue_name)}
                </div>
                <div className="modalHeaderInner">
                  {currentCustomZoneData?.data?.eventMetadata?.venue_location?.length > 40 ? 
                    addToolTip(currentCustomZoneData?.data?.eventMetadata?.venue_location,"right", "default", 37) : 
                      currentCustomZoneData?.data?.eventMetadata?.venue_location}
                </div>
              </div>
            </div>
          </div>
          
          <LoadingSpinner isLoading={isLoadingModalData}>
            <Form
              className="modalCustomBody"
              name="listingForm"
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setIsValueChanged(true)}
              autoComplete="on"
            >
              <div className="editUserInfoForm">
                <div className="ant-row">
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      Quantity of Tickets <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name="listing_quantity"
                        dependencies={["seat_type_id"]}
                        rules={[
                          { required: true, message: "", type: "number" },
                          () => ({
                            validator(_, value) {
                              if (isSeatTypePiggyback) {
                                if (value % 2 === 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("Value must be even!");
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <InputNumber
                          id="listing_quantity"
                          precision={0}
                          min={1}
                          max={parseInt(maxTicketsAllowed ?? '8')}
                          step={1}
                          controls={false}
                          className={`inputTxtRight`}
                        />
                      </Form.Item>
                      <span className="labelBottom">
                        Max. Tickets Allowed: {maxTicketsAllowed ?? 8}
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      List Price($)<span className="req">*</span> 
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name="listing_price"
                        rules={[
                          { required: true, message: "", type: "number" },
                          () => ({
                            validator(_, value) {
                              
                              let min_markup_value = zones.find(
                                (zone) => zone.id === currentZoneId
                              )?.min_markup_value;
                              
                              if(min_markup_value !== undefined && value < min_markup_value) {
                                return Promise.reject(
                                  "Listing Price cannot be less than min list price"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <InputNumber
                          name="listing_price"
                          precision={2}
                          min={0}
                          controls={false}
                          className={`inputTxtRight`}
                        />
                      </Form.Item>
                      <span className="labelBottom">
                        Ticket Cost: {' '}
                        {getCostWithVenueFees()}
                        { (eventMetaData && eventMetaData?.venue_fees) && <Tooltip title="Venue Fees Included"><i className="da icon-venue-fees" style={{ marginLeft: '5px', height: '14px'}}/></Tooltip>}
                      </span>
                      <span className="labelBottom">
                        Min. List Price: {' '}
                        {FormatCurrencyUnit(twoDecimalPlacesNumber(zones.find((zone) => zone.id === currentZoneId)?.min_markup_value).toLocaleString(), CurrencyCode.Usd)}
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>Section</label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"zone_section"}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Input type="text" disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      Row <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"listing_rows"}
                        rules={[
                          { required: true, message: "", type: "string" },
                          () => ({
                            validator(_, value) {
                              if (!applyToAll) {
                                let zoneAndRow = `${
                                  zones.find(
                                    (zone) => zone.id === currentZoneId
                                  )?.zone_section
                                }${value}`.toLowerCase();
                                let zoneAndRows = zones
                                  ?.filter((zone) => zone.id !== currentZoneId)
                                  ?.map((zone) =>
                                    `${zone.zone_section}${zone.listing_rows}`.toLowerCase()
                                  );
                                if (zoneAndRows.includes(zoneAndRow)) {
                                  return Promise.reject(
                                    "Zone and row combination must be unique!"
                                  );
                                }
                                return Promise.resolve();
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Input
                          type="text"
                          autoComplete="off"
                          onChange={()=>checkForDuplicates(applyToAll)}
                        />
                      </Form.Item>
                      <span className="labelBottom">
                        Row: {" "}
                        {zones.find((zone) => zone.id === currentZoneId)?.rows}
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      Low Seat <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"low_seat"}
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <InputNumber
                          precision={0}
                          min={1}
                          step={1}
                          controls={false}
                          className={`inputTxtRight`}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <CreateListingInHandDate
                        metaData={ currentCustomZoneData?.data?.eventMetadata }
                        inHandDateType={ inHandDateType }
                        setInHandDateType={ setInHandDateType }
                        labelName="In Hand Date"
                        ifRequire={ true }
                      />
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      Stock Type <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"stock_type_id"}
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          value={listingFormData?.stock_type_id}
                          options={stockTypesMaster}
                          showSearch
                          filterOption={true}
                          optionFilterProp={"label"}
                          onChange={(value) => {
                            form.setFieldsValue({
                              electronic_transfer: false,
                            });
                            flaggedStockTypes.includes(value)
                              ? setDisableElectronicTransferSwitch(true)
                              : setDisableElectronicTransferSwitch(false);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      2nd Row{" "}
                      {isSeatTypePiggyback && <span className="req">*</span>}
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"listing_second_row"}
                        dependencies={["seat_type_id"]}
                        rules={[
                          {
                            required: isSeatTypePiggyback,
                            message: "",
                            type: "string",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (isSeatTypePiggyback) {
                                if (getFieldValue("listing_rows") !== value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("Value must be even!");
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Input
                          type="text"
                          className={`text-right`}
                          disabled={!isSeatTypePiggyback}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>
                      Split Type <span className="req">*</span>
                    </label>
                    <div className="inputGroup removeErrorDiv">
                      <Form.Item
                        name={"split_type_id"}
                        rules={[
                          { required: true, message: "", type: "number" },
                        ]}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          value={listingFormData?.split_type_id}
                          options={splitTypesMaster}
                          showSearch
                          filterOption={true}
                          optionFilterProp={"label"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>Seat Type</label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"seat_type_id"}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          placeholder="Select"
                          allowClear={false}
                          value={listingFormData?.seat_type_id}
                          options={seatTypesMaster}
                          showSearch
                          filterOption={true}
                          optionFilterProp={"label"}
                          onChange={(value) => {
                            if (
                              seatTypesMaster
                                .find((seatType) => seatType.value === value)
                                ?.label.toUpperCase() === "PIGGYBACK"
                            ) {
                              setIsSeatTypePiggyBack(true);
                            } else {
                              setIsSeatTypePiggyBack(false);
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>Disclosures</label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"disclosures"}
                        wrapperCol={{ xl: { span: 24 } }}
                        shouldUpdate={(prev, cur) =>
                          JSON.stringify(prev.disclosures) !==
                          JSON.stringify(cur.disclosures)
                        }
                      >
                        <Select
                          labelInValue
                          mode="multiple"
                          placeholder="Select"
                          allowClear={false}
                          options={disclosuresMaster}
                          showSearch
                          filterOption={true}
                          optionFilterProp={"label"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>Attributes</label>
                    <div className="inputGroup">
                      <Form.Item
                        name={"attributes"}
                        wrapperCol={{ xl: { span: 24 } }}
                      >
                        <Select
                          labelInValue
                          mode="multiple"
                          placeholder="Select"
                          allowClear={false}
                          options={attributesMaster}
                          showSearch
                          filterOption={true}
                          optionFilterProp={"label"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <label>Electronic Transfer</label>
                    <div className="inputGroup" style={{ marginTop: "15px" }}>
                      <Form.Item
                        name={"electronic_transfer"}
                        wrapperCol={{ xl: { span: 24 } }}
                        valuePropName="checked"
                        shouldUpdate={(prev, cur) =>
                          prev.electronic_transfer !== cur.electronic_transfer
                        }
                      >
                        <Switch
                          checkedChildren="YES"
                          unCheckedChildren="NO"
                          className="yesNo"
                          disabled={disableElectronicTransferSwitch}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </div>
              </div>
              <div className="modalFooterStyle2">
                {showApplyToAll ? (
                  <Form.Item>
                    <Checkbox onChange={(e) => {
                      checkForDuplicates(e.target.checked);
                      setApplyToAll(e.target.checked);
                    }}>
                      Apply to all
                    </Checkbox>
                  </Form.Item>
                ) : (
                  <div></div>
                )}
                <Form.Item>
                  <Button className="linkBtn" onClick={cancelHandler}>
                    CANCEL
                  </Button>
                  <Button className="ant-btn btnOk" htmlType="submit" disabled={disableSave}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </LoadingSpinner>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CreateListingModal;
