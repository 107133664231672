import { ICustomError } from "../../models/interfaces/ICustomError";
import RefreshIcon from "../../assets/images/reload-icon.svg";
import { Typography } from "antd";

const CustomError: React.FC<ICustomError> = (error) => {
  const executeRefresh = () => {
    if(typeof error.refreshAction === 'string') {
      window.location.reload();
    }
    else {
      if (error.params) {
        error.refreshAction(error.params);
      } else {
        error.refreshAction();
      }
    }
  };
  return (
    <div className="errorContent">
      <div className="errorContentInner">
        <span className="error-img"></span>
        <div>
          <h3>Something's wrong.</h3>
          <p>{error.message}</p>
          <p>
            Please try after sometime or{" "}
            <Typography.Link onClick={executeRefresh}>
              Click to reload
              <img
                src={RefreshIcon}
                alt="Refresh"
                style={{ verticalAlign: "middle", marginLeft: "5px" }}
              />
            </Typography.Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default CustomError;
