import React, { useCallback, useEffect, useState } from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import CommonReportTable from 'utils/table/CommonReportTable';
import { CommonTableHeader, ISectionInfo, ICreatedSetionsTableInfo, tableClasses } from 'models/interfaces';
import { PerformanceAnalysisDashboardService } from 'services';
import PerformanceAnalysisGraphFilters from '../../PerformanceAnalysisGraphFilters';
import moment from "moment";
import CustomError from 'controls/CustomError/CustomError';
import { getTooltipTitle, widget_information } from '../../PerformanceAnalysisPage';
import PerformanceAnalysisInformationTooltip from '../../PerformanceAnalysisInformationTooltip';

const MarginOfPricing: React.FC = () => {

    const DEFAULT_WEEKS = 12;
    const DEFAULT_MONTHS = 12;
    const DEFAULT_MARGIN_OF_PRICING_INFO: ICreatedSetionsTableInfo = {
        headers: [],
        data: [],
        footerData: [],
        tableClass: tableClasses.CUSTOM_TABLE
    }
    const INFORMATION = `For each listing, 
    margin of pricing = ((lp - cp) / lp) *100, 
    where lp stands for Listing Price (latest), cp stands for Cost Price (oldest) 
    .`;
    const DISABLE_CACHING = true;

    const [isServiceLoading, setIsServiceLoading] = useState(false);
    const [marginOfPricingInfo, setMarginOfPricingInfo] = useState<ICreatedSetionsTableInfo>(DEFAULT_MARGIN_OF_PRICING_INFO);
    const [timeUnit, setTimeUnit] = useState<'week' | 'month'>('week');
    const [cachedInfo, setCachedInfo] = useState<{ 'week': ICreatedSetionsTableInfo | null, 'month': ICreatedSetionsTableInfo | null }>({ 'week': null, 'month': null });
    const [error, setError] = useState<any>(null);

    const CommonReportTableInfo = (time_unit: string, data: ISectionInfo[]) => {
        const headers: CommonTableHeader[] = [
            {
                title: "User",
                thClassName: "text-left",
                dataIndex: "user",
                key: "user",
                dataRender: (data: string) => {
                    return (
                        <span
                            className={"ant-tag info text-left"}
                            style={{ textTransform: "capitalize" }}
                        >
                            {data}
                        </span>
                    );
                },
                dataFooterRender: (data: string) => (
                    <div className={`text-left`}>{data}</div>
                )
            }
        ];
        data[0].data.forEach(({ start_date, end_date, period_number }, index) => {
            const TooltipTitle = getTooltipTitle(start_date, end_date);
            headers.push({
                title: timeUnit === 'week' ? 'WK ' + period_number : moment(start_date).format('MMM, YY'),
                thClassName: "text-right",
                dataIndex: index.toString(),
                key: index.toString(),
                tooltipText: TooltipTitle,
                dataRender: (data: number) => {
                    return (
                        <div className="text-right">
                            {data === 0 ? '-' : data}
                        </div>
                    )
                },
                dataFooterRender: (data: number) => (
                    <div className="text-right">
                        {data === 0 ? '-' : data}
                    </div>
                )
            })
        })
        const result = widget_information(data, 'margin_of_pricing');
        const information = {
            headers,
            data: result['data'],
            footerData: result['footerData'],
            tableClass: tableClasses.CUSTOM_TABLE
        };
        setCachedInfo({ ...cachedInfo, [time_unit]: information });
        setMarginOfPricingInfo(information);
    }

    const getMarginOfPricingInfo = useCallback(async () => {
        if ( cachedInfo[timeUnit] && !DISABLE_CACHING ) {
            setMarginOfPricingInfo(cachedInfo[timeUnit] as ICreatedSetionsTableInfo);
        } else {
            setIsServiceLoading(true);
            try {
                setMarginOfPricingInfo(DEFAULT_MARGIN_OF_PRICING_INFO);
                setError(null);
                const response = await new PerformanceAnalysisDashboardService().getListerMarginOfPricing({
                    time_unit: timeUnit,
                    time_value: timeUnit === 'week' ? DEFAULT_WEEKS : DEFAULT_MONTHS,
                });
                const { data } = response.data.data;
                data ? CommonReportTableInfo(timeUnit, data) : setMarginOfPricingInfo(DEFAULT_MARGIN_OF_PRICING_INFO);
                setIsServiceLoading(false);
            } catch (error) {
                setIsServiceLoading(false);
                setError(error);
            }
        }
    }, [timeUnit]);

    useEffect(() => {
        getMarginOfPricingInfo()
        return () => { }
    }, [])

    useEffect(() => {
        getMarginOfPricingInfo()
        return () => { }
    }, [timeUnit])

    return (
        <div className="innerComponent tblStyle2">
            <h4>Margin of Pricing (%) <PerformanceAnalysisInformationTooltip information={INFORMATION} /></h4>
            <LoadingSpinner isLoading={isServiceLoading}>
                {
                    error ?
                        <CustomError
                            {...error}
                            refreshAction={getMarginOfPricingInfo}
                        /> :
                        <CommonReportTable
                            {...marginOfPricingInfo}
                        />
                }
            </LoadingSpinner>
            <br />
            <PerformanceAnalysisGraphFilters
                chartFilterHandler={setTimeUnit}
                activeFilter={timeUnit}
                isLoading={isServiceLoading}
            />
        </div>
    )
}

export default MarginOfPricing