import CustomError from "controls/CustomError/CustomError";
import {
  IFilterOptions,
  IPurchaseQueueMasterData,
  IPurchaseQueueMenuOption,
  ISwimlaneInvoiceList,
  ISwimlaneMaster,
} from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useEffect, useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import KanbanLaneFilter from "../KanbanLaneFilter/KanbanLaneFilter";
import KanbanLaneSort from "../KanbanLaneSort/KanbanLaneSort";
import SwimlanePurchaseCard from "./SwimlanePurchaseCard";
import SwimlaneShippingCard from "./SwimlaneShippingCard";

const Swimlane: React.FC<{
  swimlaneData: ISwimlaneMaster;
  queueType: Number;
  requestedReloadLaneIds: number[] | null;
  requestToReloadTheLaneIds: Function;
  purchaseQueueMaster: IPurchaseQueueMasterData | null;
  kebabMenuOptions: IPurchaseQueueMenuOption[] | null;
  filterObject: IFilterOptions | null;
  setMaxCardCount: Function;
  heightOfDivider: number;
}> = ({
  swimlaneData,
  queueType,
  requestedReloadLaneIds,
  requestToReloadTheLaneIds,
  purchaseQueueMaster,
  kebabMenuOptions,
  filterObject,
  setMaxCardCount,
  heightOfDivider,
}) => {
    const [showLoader, setShowLoader] = useState(true);
    const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
      null
    );
    const [swimLaneInvoiceList, setSwimLaneInvoiceList] = useState<
      ISwimlaneInvoiceList[] | null
    >(null);

    const [filterData, setFilterData] = useState<any>(null);
    const [currentSortOption, setCurrentSortOption] = useState<{
      sort_key: string;
      sort_data_type: string;
      sort_order: string;
    }>({
      sort_key: "event_date_and_time",
      sort_data_type: "date",
      sort_order: "asc",
    });

    const getSwimLaneInvoiceList = async () => {
      setDataFetchError(null);
      try {
        setShowLoader(true);
        const response =
          await new PurchaseAndShippingService().getSwimLaneInvoiceList(
            swimlaneData.id,
            filterObject
          );
        if (response.data.data.invoiceList?.length) {
          setMaxCardCount((prev: number) =>
            response.data.data.invoiceList.length > prev
              ? response.data.data.invoiceList.length
              : prev
          );
        }
        setSwimLaneInvoiceList(response.data.data.invoiceList);
        setShowLoader(false);
      } catch (error: any) {
        setShowLoader(false);
        setDataFetchError({ ...error, refreshAction: getSwimLaneInvoiceList });
        new LoggerService().log({
          payload: error,
          function_name: "getSwimLaneInvoiceList",
        });
      }
    };

    const onApplyFilter = (filter: any) => {
      setFilterData(filter);
    };

    const getFilteredItemList = () => {
      let invoiceList = swimLaneInvoiceList ? [...swimLaneInvoiceList] : [];

      /** For Filter */
      if (filterData) {
        if (
          filterData.purchase_issue_type &&
          filterData.purchase_issue_type.length
        ) {
          invoiceList = invoiceList.filter((eachInvoice) =>
            filterData.purchase_issue_type.includes(eachInvoice.issue_type)
          );
        }
        if (
          filterData.shipping_issue_type &&
          filterData.shipping_issue_type.length
        ) {
          invoiceList = invoiceList.filter((eachInvoice) =>
            filterData.shipping_issue_type.includes(
              eachInvoice.shipping_issue_type
            )
          );
        }
      }

      /** For Sort */
      if (
        currentSortOption.sort_key &&
        ["date", "string", "number", "boolean"].includes(
          currentSortOption.sort_data_type
        )
      ) {
        invoiceList = invoiceList.sort((objA, objB) => {
          let fieldDataA = objA[currentSortOption.sort_key];
          let fieldDataB = objB[currentSortOption.sort_key];

          if (currentSortOption.sort_data_type === "date") {
            fieldDataA = new Date(fieldDataA);
            fieldDataB = new Date(fieldDataB);
          }

          if (currentSortOption.sort_data_type === "string") {
            fieldDataA = fieldDataA ? fieldDataA.toLowerCase() : "";
            fieldDataB = fieldDataB ? fieldDataB.toLowerCase() : "";
          }

          if (currentSortOption.sort_data_type === "number") {
            fieldDataA = fieldDataA ? fieldDataA : 0;
            fieldDataB = fieldDataB ? fieldDataB : 0;
          }

          if (currentSortOption.sort_data_type === "boolean") {
            fieldDataA = fieldDataA ? 1 : 0;
            fieldDataB = fieldDataB ? 1 : 0;
          }

          /** Doing Sort */
          if (currentSortOption.sort_data_type === "string") {
            if (currentSortOption.sort_order === "asc") {
              return fieldDataA > fieldDataB
                ? 1
                : fieldDataB > fieldDataA
                  ? -1
                  : 0;
            } else {
              return fieldDataB > fieldDataA
                ? 1
                : fieldDataA > fieldDataB
                  ? -1
                  : 0;
            }
          } else {
            if (currentSortOption.sort_order === "asc") {
              return fieldDataA - fieldDataB;
            } else {
              return fieldDataB - fieldDataA;
            }
          }
        });
      }

      return invoiceList;
    };

    const requestToReloadTheLanes = (swimlaneIds: number[] | null) => {
      requestToReloadTheLaneIds(swimlaneIds);
    };

    useEffect(() => {
      if (
        requestedReloadLaneIds &&
        requestedReloadLaneIds.includes(swimlaneData.id)
      ) {
        getSwimLaneInvoiceList();
      }
    }, [requestedReloadLaneIds]);

    useEffect(() => {
      if (filterObject) {
        getSwimLaneInvoiceList();
      }
    }, [filterObject]);

    const getHeaderClassName = (swimlaneId: number) => {
      let className = "purchaseCardHeader ";
      if (
        swimlaneId === 1 ||
        swimlaneId === 2 ||
        swimlaneId === 5 ||
        swimlaneId === 6 ||
        swimlaneId === 9
      ) {
        className = className + "needToBuy";
      } else if (swimlaneId === 3) {
        className = className + "onSale";
      } else if (swimlaneId === 4 || swimlaneId === 7 || swimlaneId === 8) {
        className = className + "issues";
      }
      return className;
    };

    const getKanbanFilterJsx = () => {
      if (
        [4, 7].includes(swimlaneData.id) &&
        swimLaneInvoiceList &&
        swimLaneInvoiceList.length
      ) {
        return (
          <KanbanLaneFilter
            purchaseIssueTypeMaster={
              swimlaneData.id === 4
                ? purchaseQueueMaster?.issuesTypeMasterData
                : undefined
            }
            shippingIssueTypeMaster={
              swimlaneData.id === 7
                ? purchaseQueueMaster?.shippingIssuesTypeMasterData
                : undefined
            }
            onApply={onApplyFilter}
          />
        );
      }
    };

    return (
      <div
        className={`${ queueType === 1 ? 'col5' : 'col4' } rightDeviderV`}
        style={{ height: `${heightOfDivider}px` }}
      >
        <div className={getHeaderClassName(swimlaneData.id)}>
          <span>
            {swimlaneData.name} ({getFilteredItemList().length})
          </span>
          <div>
            {swimlaneData.sort_list &&
              swimLaneInvoiceList &&
              swimLaneInvoiceList.length > 1 && (
                <KanbanLaneSort
                  swimlaneData={swimlaneData}
                  currentSortOption={{
                    sort_key: currentSortOption.sort_key,
                    sort_order: currentSortOption.sort_order,
                  }}
                  setCurrentSortOption={setCurrentSortOption}
                />
              )}

            {getKanbanFilterJsx()}
          </div>
        </div>
        <div className="purchaseInnerWrap">

          <LoadingSpinner isLoading={showLoader}>
            <div className="purchaseCardWrap" style={{ minHeight: 500 }}>
              {dataFetchError ? (
                <CustomError {...dataFetchError} />
              ) : (
                <React.Fragment>
                  {getFilteredItemList().map((eachInvoiceData, index) => {
                    if (eachInvoiceData.queue_type === 1) {
                      return (
                        <SwimlanePurchaseCard
                          key={'purchaseCard_'+eachInvoiceData.invoice_id+index}
                          swimlaneData={swimlaneData}
                          eachInvoiceData={eachInvoiceData}
                          requestToReloadTheLanes={requestToReloadTheLanes}
                          purchaseQueueMaster={purchaseQueueMaster}
                          kebabMenuOptions={kebabMenuOptions}
                        />
                      );
                    } else {
                      return (
                        <SwimlaneShippingCard
                          key={'shippingCard_'+eachInvoiceData.invoice_id+index}
                          swimlaneData={swimlaneData}
                          eachInvoiceData={eachInvoiceData}
                          requestToReloadTheLanes={requestToReloadTheLanes}
                          purchaseQueueMaster={purchaseQueueMaster}
                          kebabMenuOptions={kebabMenuOptions}
                        />
                      );
                    }
                  })}
                </React.Fragment>
              )}
            </div>
          </LoadingSpinner>
        </div>
      </div>
    );
  };

export default Swimlane;
