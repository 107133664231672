import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import {
  addToolTip,
  formatAccountingValues,
  getTagClassForZones,
} from "utils/commonFunction";
import { AppSetting } from "utils/appSettings";
import { IZoneAvailabilityMasterDatum } from "models/interfaces";
import { FixedColumnDirection } from "models/enums/table";

const commonWidth = 130;

const getBroadcastTextJSX = (broadcast: number) => {
  switch (broadcast) {
    case 0:
      return <div className="text-capitalize">Un-Broadcasted</div>;
    case 1:
      return <div className="text-capitalize">Broadcasted</div>;
    case 2:
      return <div className="text-capitalize">Partially Broadcasted</div>;
  }
};

export const ActiveZoneListingTableHeaders = [
  {
    title: "Event Name",
    dataIndex: "event_name",
    canSort: true,
    width: 170,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (event: string, otherData: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.EVENT_URL + otherData.data.singleData.event_id}
        className="text-capitalize"
      >
        {event?.length > 20
          ? addToolTip(event, "right", "pointer", 20, false, {
              textTransform: "capitalize",
            })
          : event}
      </a>
    ),
  },
  {
    title: "Event Date & Time",
    dataIndex: "event_date",
    canSort: true,
    width: 160,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    dataRender: (eventDate: string) =>
      eventDate &&
      formatDateGivenFormatString(eventDate, AppSetting.EVENT_DATE_FORMAT),
  },
  {
    title: "Venue Name",
    dataIndex: "venue_name",
    canSort: true,
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (venueName: string) =>
      (venueName && venueName.length) > 20 ? (
        addToolTip(venueName, "right", "default", 20, false, {
          textTransform: "capitalize",
        })
      ) : (
        <div>{venueName}</div>
      ),
  },
  {
    title: "City",
    dataIndex: "city",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
  },
  {
    title: "State",
    dataIndex: "state",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
  },
  {
    title: "Country",
    dataIndex: "country",
    canSort: true,
    width: commonWidth,
    tdClassName: "text-capitalize",
  },
  {
    title: "PO Number",
    dataIndex: "purchase_id",
    width: commonWidth,
    canSort: true,
    thClassName: "text-left",
    dataRender: (purchase_id: number) =>
      isNaN(purchase_id) ? (
        <div>{purchase_id}</div>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={AppSetting.PURCHASE_ID_URL + purchase_id}
        >
          {purchase_id}
        </a>
      ),
  },
  {
    title: "Max Cost",
    dataIndex: "cost_price",
    width: commonWidth,
    canSort: true,
    thClassName: "text-right",
    dataRender: (cost_price: number) => (
      <div className={`text-right ${cost_price < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(cost_price)}
      </div>
    ),
  },
  {
    title: "Selling Price",
    dataIndex: "list_price",
    width: commonWidth,
    canSort: true,
    thClassName: "text-right",
    dataRender: (list_price: number) => (
      <div className={`text-right ${list_price < 0 ? "text-danger" : ""}`}>
        {formatAccountingValues(list_price)}
      </div>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "inventory_quantity",
    width: commonWidth,
    canSort: true,
    thClassName: "text-right",
    tdClassName: "text-capitalize text-right",
  },
  {
    title: "Section",
    dataIndex: "section",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-capitalize",
    dataRender: (section: string) => (
      <div className="text-capitalize">
        {section?.length > 15
          ? addToolTip(section, "right", "default", 15, false, {
              textTransform: "capitalize",
            })
          : section}
      </div>
    ),
  },
  {
    title: "Rows",
    dataIndex: "meta_zone_row",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    tdClassName: "text-upper",
    dataRender: (meta_zone_row: string | null) => {
      if (!meta_zone_row) {
        return <div>-</div>;
      } else {
        return (
          <div>
            {meta_zone_row?.length > 15
              ? addToolTip(meta_zone_row, "right", "default", 15, false, {
                  textTransform: "capitalize",
                })
              : meta_zone_row}
          </div>
        );
      }
    },
  },
  {
    title: "Listed Row",
    dataIndex: "listed_row",
    width: commonWidth,
    canSort: true,
    thClassName: "text-left",
    tdClassName: "text-upper",
    dataRender: (listed_row: string) => (
      <div>
        {listed_row?.length > 15
          ? addToolTip(listed_row, "right", "default", 15, false, {
              textTransform: "capitalize",
            })
          : listed_row}
      </div>
    ),
  },
  {
    title: "Seats",
    dataIndex: "seat_numbers",
    canSort: true,
    width: commonWidth,
    thClassName: "text-left",
    dataRender: (seat_numbers: string, data: any) => (
      <div className="text-capitalize">
        {`${data?.data?.singleData?.low_seat}-${data?.data?.singleData?.high_seat}`}
      </div>
    ),
  },
  {
    title: "Availability",
    dataIndex: "zone_availability_id",
    width: commonWidth,
    canSort: true,
    tdClassName: "text-center",
    dataRender: (zone_availability_id: number | null, data: any) => {
      if (zone_availability_id === null || zone_availability_id === undefined || zone_availability_id === 5 ) {
        return <div>-</div>;
      } else {
        return (
          <div
            className={`${
              getTagClassForZones(zone_availability_id, data?.otherData)
                ?.className
            } ant-tag`}
          >
            {data?.otherData?.find(
                (each: any) => each.value === zone_availability_id
              ).label
            }
          </div>
        );
      }
    },
  },
  {
    title: "Created On",
    dataIndex: "po_date",
    width: commonWidth,
    canSort: true,
    dataRender: (po_date: string) =>
      po_date &&
      formatDateGivenFormatString(po_date, AppSetting.EVENT_DATE_FORMAT),
  },
  {
    title: "Inventory Tags",
    dataIndex: "inventory_tags",
    width: commonWidth,
    canSort: true,
    dataRender: (data: string) => (
      data?.length > 0
          ? addToolTip(data, "right", "pointer", 15, false, {
              textTransform: "capitalize",
            })
          : '-'
    ),
  },
  {
    title: "PO Tags",
    dataIndex: "po_tags",
    width: commonWidth,
    canSort: true,
    dataRender: (data: string[]) => {
      if( data?.length > 0) {
        return addToolTip(data, "right", "default", 15, true)
      }
      return <div>-</div>
    },
  },
  {
    title: "Broadcast Status",
    dataIndex: "broadcast",
    width: commonWidth,
    canSort: true,
    dataRender: (broadcast: number) => getBroadcastTextJSX(broadcast),
  },
  {
    title: "Broker",
    dataIndex: "broker_name_raw",
    dataRender: (data: string[]) => {
      if( data?.length > 0) {
        return addToolTip(data, "left", "default", 15, true)
      }
      return <div>-</div>
    },
  },

  {
    title: "Lister",
    dataIndex: "lister_name_raw",
    dataRender: (data: string[]) => {
      if( data?.length > 0) {
        return addToolTip(data, "left", "default", 15, true)
      }
      return <div>-</div>
    },
  },
  {
    title: "TB2.0 Placement",
    dataIndex: "is_platform_listing",
    width: commonWidth,
    tdClassName: "text-center",
    canSort: true,
    dataRender: (is_platform_listing: boolean) => (
      <div className="text-capitalize">
        {is_platform_listing ? "Yes" : "No"}
      </div>
    ),
  },
];
