import { Layout, message } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useRbac } from "features/rbac/rbac";
import { IEventMetadata, IMarketplace } from "models/interfaces";
import {
  eventManagementActions,
  eventMetadata,
  isLoadingMetadata,
  zoneAvailabilityMaster,
  isLoadingAuditHistory,
  isLoadingTrendData,
  updateUpcomingEventList,
} from "models/Slices/EventManagementSlice";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { EventService } from "services";
import { AppSetting } from "utils/appSettings";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { checkValidUrl } from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import EventHeader from "./EventHeader";
import EventOtherDetails from "./EventOtherDetails";
import NotesArea from "./NotesArea";
import SecondMarketPlace from "./SecondMarketPlace";
import ZoneAvailabilityTable from "./ZoneAvailabilityTable";

const IDLE_TIME_TIMEOUT_IN_SECONDS = AppSetting.IDLE_TIME_TIMEOUT_IN_SECONDS;

const EditEventAvailability: React.FC<{
  eventId: number;
  onCancelMetadataUpdate: Function;
  onSuccessMetadataUpdate?: Function;
  isFromQuickEdit?: boolean;
}> = ({
  eventId,
  onCancelMetadataUpdate,
  onSuccessMetadataUpdate,
  isFromQuickEdit,
}) => {

  const { getTotalElapsedTime, getTotalIdleTime } = useIdleTimer({
    timeout: 1000 * IDLE_TIME_TIMEOUT_IN_SECONDS,
  });

  const { userDetails } = useRbac();
  const dispatch = useAppDispatch();
  const metadata = useAppSelector(eventMetadata);
  const loadingMetadata = useAppSelector(isLoadingMetadata);
  const loadingAuditHistory = useAppSelector(isLoadingAuditHistory);
  const loadingTrendData = useAppSelector(isLoadingTrendData);
  const zoneAvailabilityMasterData = useAppSelector(zoneAvailabilityMaster);

  const [lastTimeDuration, setLastTimeDuration] = useState({
    totalTime: 0,
    idleTime: 0,
  });
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [secondaryMarketAllowSave, setSecondaryMarketAllowSave] = useState(false);
  const [metadataUpdateData, setMetadataUpdateData] = useState<IEventMetadata | null>(null);
  const [marketPlaceInfo, setMarketPlaceInfo] = useState<IMarketplace[]>([]);
  const [allowSave, setAllowSave] = useState(false);
  const [ifScrollAvailable, setIfScrollAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingMetadata, setIsSavingMetadata] = useState(false);
  const [isSoldOutActivated, setIsSoldOutActivated] = useState<boolean | null>(
    null
  );
  const [isWebScrapingEvent, setIsWebScrapingEvent] = useState<boolean>(
    metadata?.scraping_status_id === 1 ? true : false
  );
  const [disableCheckboxes , setDisableCheckboxes] = useState(false);
  const [showWebScrapingCheckbox, setShowWebScrapingCheckbox] = useState(false);
  const [showSemiAutomaticScrapingCheckbox, setShowSemiAutomaticScrapingCheckbox] = useState(false);
  
  useEffect(() => {
    if (!loadingMetadata && !loadingAuditHistory && !loadingTrendData) {
      setIsLoading(false);
      setTimeout(() => {
        getIfShowingScroll();
      }, 200);
    } else {
      setIsLoading(true);
    }
  }, [loadingMetadata, loadingAuditHistory, loadingTrendData]);

  useEffect(() => {

    setMetadataUpdateData(metadata ? {...metadata}:null);

    if(metadata){
      setMarketPlaceInfo(metadata.secondary_marketplace ?? []);
    }
    setIsWebScrapingEvent(metadata?.scraping_status_id === 1 ? true : false);
    setShowWebScrapingCheckbox(metadata?.show_web_scraping_checkbox ? true : false);
    
  }, [metadata]);

  useEffect(() => {
    if (
      metadataUpdateData?.seat_map_url &&
      !checkValidUrl(metadataUpdateData?.seat_map_url) &&
      (metadata?.scraping_status_id === 2 || metadata?.scraping_status_id === 3)
    ) {
      setAllowSave(false);
    } else if (
      metadataUpdateData?.reminder_override &&
      !metadataUpdateData?.reminder_in_days &&
      (metadata?.scraping_status_id === 2 || metadata?.scraping_status_id === 3)
    ) {
      setAllowSave(false);
    } else if (!metadataUpdateData?.stock_type_id && (!metadataUpdateData?.is_sold_out && !metadataUpdateData?.is_event_cancelled)) {
      setAllowSave(false);
    } else {
      setAllowSave(true);
    }

    /** Show and Hide Semi-Automatic Scraping checkbox */
    if(metadataUpdateData && 
      metadataUpdateData.seat_map_url &&
      metadataUpdateData.seat_map_url !== '' && 
      metadata?.desktop_app_provider_list?.find(each =>  metadataUpdateData.seat_map_url.toLowerCase().indexOf(each.domain_name.toLocaleLowerCase()) >= 0)
    ){
      setShowSemiAutomaticScrapingCheckbox(true);
    }
    else{
      setShowSemiAutomaticScrapingCheckbox(false);
      if(metadataUpdateData?.scraping_status_id === 3){
        setMetadataUpdateData({
          ...metadataUpdateData,
          scraping_status_id: 2
        })
      }
    }

  }, [metadata, metadataUpdateData]);


  useEffect(() => {
    checkAllUrls( marketPlaceInfo )
    setMetadataUpdateData(( prev ) => {
      return {
        ...prev,
        'secondary_marketplace' : marketPlaceInfo
      }
    });
  }, [marketPlaceInfo])
  

  const getIfShowingScroll = () => {
    const elem = document.getElementsByClassName("contentMainLayout")[0];
    setIfScrollAvailable(elem.scrollHeight > elem.clientHeight);
  };

  const checkAllUrls = ( urls: IMarketplace[] ) => {
    let flag=1;
    urls.forEach(url => {
      if( url.secondary_marketplace_url === '' ){
        flag = 1;
      }
      else if( !checkValidUrl( url.secondary_marketplace_url ) || !((url.secondary_marketplace_url).includes(url.domain_name) ) ){
        flag = 0
      }
    });
    if( flag ) {
      setSecondaryMarketAllowSave(true)
    } else {
      setSecondaryMarketAllowSave(false)
    }
  }

  const cancelHandler = () => {
    if (JSON.stringify(metadata) === JSON.stringify(metadataUpdateData)) {
      onCancelMetadataUpdate();
    } else {
      const header = (
        <span className="text-danger">{"All unsaved data will be lost."}</span>
      );
      const body = "Continue?";
      showConfirm(
        header,
        body,
        null,
        () => onCancelMetadataUpdate(), //window.close(),
        () => console.log("cancelled"),
        "400px"
      );
    }
  };
  const fetchMetadata = () => {
    dispatch(
      eventManagementActions.getEventMetadata({
        event_id: eventId, //eventQueryString.event_id,
        functionName: "fetchMetadata",
      })
    );
  };

  const saveHandler = async () => {
    setIsSavingMetadata(true);
    setAllowSave(false);
    try {
      if (metadataUpdateData) {
        const currentTotalTime = getTotalElapsedTime();
        const currentTotalIdleTime = getTotalIdleTime();
        const totalTimeInSeconds =
          (currentTotalTime - lastTimeDuration.totalTime) / 1000;
        const idleTimeInSeconds =
          (currentTotalIdleTime - lastTimeDuration.idleTime) / 1000;
              
        setLastTimeDuration({
          totalTime: currentTotalTime,
          idleTime: currentTotalIdleTime,
        });

        const response = await new EventService().saveMetadata({
          ...metadataUpdateData,
          total_time_duration: Math.round(totalTimeInSeconds),
          total_idle_time_duration: Math.round(idleTimeInSeconds),
          stock_type_id: metadataUpdateData.stock_type_id,
          scraping_status_id: isWebScrapingEvent ? 1 : metadataUpdateData.scraping_status_id,
          seat_map_url: metadataUpdateData.seat_map_url??metadataUpdateData.venue_url
        });
        if (response.status === 200) {
          message.success({
            content: "Data updated successfully.",
            duration: 5,
            className: "toastMsg savedSuccess",
            //onClose: () => window.close(),  This tab will close in 5sec...
          });
          fetchMetadata();
          if (isFromQuickEdit) {
            dispatch(
              updateUpcomingEventList({
                event_id: metadataUpdateData.event_id,
                seat_map_url: metadataUpdateData.seat_map_url,
                updated_by: JSON.stringify({
                  compare_date: 0,
                  scraping_status_id: null,
                  first_name: userDetails?.first_name,
                  last_name: userDetails?.last_name ?? "",
                }),
              })
            );
          }
          if (onSuccessMetadataUpdate) {
            onSuccessMetadataUpdate();
          }
          dispatch(
            eventManagementActions.getMetadataTrendData({
              event_id: eventId, //eventQueryString.event_id,
              functionName: "getMetadataTrendData",
            })
          );
          dispatch(
            eventManagementActions.getMetadataAuditHistory({
              event_id: eventId, //eventQueryString.event_id,
              functionName: "getMetadataAuditHistory",
            })
          );
        }
      }
    } catch (error) {
      message.warning({
        content: "Data save failed. Retry after sometime.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
    setIsSavingMetadata(false);
  };

  return (
    <Layout
      className={`mainInnerLayout pb-0 ${
        ifScrollAvailable ? "btmSpaceEditEvent" : ""
      }`}
    >
      <h3>Edit Event Availability</h3>
      <LoadingSpinner isLoading={isLoading}>
        <div className="editEvent removeTblLRSpc">
          {!isFromQuickEdit && (
            <EventHeader
              metadata={metadata}
              metadataUpdateData={metadataUpdateData}
              setMetadataUpdateData={setMetadataUpdateData}
              setIsSoldOutActivated={setIsSoldOutActivated}
              isWebScrapingEvent={isWebScrapingEvent}
              setIsWebScrapingEvent={setIsWebScrapingEvent}
              disableCheckboxes={disableCheckboxes}
              showWebScrapingCheckbox={showWebScrapingCheckbox}
              showSemiAutomaticScrapingCheckbox={showSemiAutomaticScrapingCheckbox}
            />
          )}

          <div
            className="ant-row"
            style={
              isFromQuickEdit
                ? { minHeight: "35vh", maxHeight: "61vh", overflow: "auto" }
                : {}
            }
          >
            <div
              className={
                isFromQuickEdit
                  ? "ant-col-xs-24 ant-col-sm-24 ant-col-sm-24 ant-col-lg-24 ant-col-xl-24"
                  : "ant-col-xs-24 ant-col-sm-24 ant-col-sm-24 ant-col-lg-16 ant-col-xl-16"
              }
            >
              <EventOtherDetails
                metadataUpdateData={metadataUpdateData}
                setMetadataUpdateData={setMetadataUpdateData}
                setAllowSave={setAllowSave}
                isFromQuickEdit={isFromQuickEdit ?? false}
                metadata={metadata}
                setIsSoldOutActivated={setIsSoldOutActivated}
                isWebScrapingEvent={isWebScrapingEvent}
                setIsWebScrapingEvent={setIsWebScrapingEvent}
                disableCheckboxes={disableCheckboxes}
                showWebScrapingCheckbox={showWebScrapingCheckbox}
              />
              {!isLoading && (
                <React.Fragment>
                  {metadata?.scraping_status_id === 1 &&
                    <div className="metaTimeDate">
                      <div>
                        <label>
                          Successfully Scraped On :
                          <span className="eventDateLabel">
                            {metadata?.scraped_succeed_on
                              ? formatDateGivenFormatString(
                                  metadata?.scraped_succeed_on,
                                  "ddd, MMM DD, YYYY h:mm A"
                                )
                              : "Never"}
                          </span>
                        </label>
                      </div>
                      <div>
                        <label>
                          Last Attempted :
                          <span className="eventDateLabel">
                            {metadata?.last_attempted_on
                              ? formatDateGivenFormatString(
                                  metadata?.last_attempted_on,
                                  "ddd, MMM DD, YYYY h:mm A"
                                )
                              : "Never"}
                            &nbsp;&nbsp;
                            {metadata?.last_attempted_on && (
                              <i
                                className={
                                  metadata.last_scraped_status
                                    ? "da icon-fictionalCharacter-green"
                                    : "da icon-fictionalCharacter-red"
                                }
                              ></i>
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                  }

                  <ZoneAvailabilityTable
                    metadata={metadata}
                    zoneAvailabilityMasterData={zoneAvailabilityMasterData}
                    metadataUpdateData={metadataUpdateData}
                    setMetadataUpdateData={setMetadataUpdateData}
                    setDisabledSaveButton={setDisabledSaveButton}
                    getIfShowingScroll={getIfShowingScroll}
                    isFromQuickEdit={isFromQuickEdit}
                    isSoldOutActivated={isSoldOutActivated}
                    setIsSoldOutActivated={setIsSoldOutActivated}
                    isWebScrapingEvent={isWebScrapingEvent}
                    setDisableCheckboxes={setDisableCheckboxes}
                  />
                  {marketPlaceInfo &&
                    <SecondMarketPlace marketPlaceInfo={marketPlaceInfo} doSetMarketPlaceInfo={setMarketPlaceInfo} />
                  }
                </React.Fragment>
              )}
            </div>
            {!isFromQuickEdit && (
              <div className="ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-8 ant-col-xl-8">
                <NotesArea
                  metadataUpdateData={metadataUpdateData}
                  setMetadataUpdateData={setMetadataUpdateData}
                />
              </div>
            )}
          </div>
          <div style={{ height: ifScrollAvailable ? "auto" : 40 }}>
            {(!ifScrollAvailable || isFromQuickEdit) && (
              <div className="btmRow flx alignItemsCenter">
                <div className="rightBlk flexEnd flx-1">
                  {(JSON.stringify(metadata) !==
                    JSON.stringify(metadataUpdateData) ||

                    JSON.stringify(marketPlaceInfo) !==
                    JSON.stringify(metadataUpdateData?.secondary_marketplace)) &&
                    !loadingMetadata &&
                    !isSavingMetadata && (
                      <div className="blinkWrap">
                        <div className="pulsating-circle"></div>
                      </div>
                    )}
                  {(JSON.stringify(metadata) !==
                    JSON.stringify(metadataUpdateData) ||

                    JSON.stringify(marketPlaceInfo) !==
                    JSON.stringify(metadataUpdateData?.secondary_marketplace)) &&
                    

                    !loadingMetadata &&
                    !isSavingMetadata && (
                      <span className="unsavedData">
                        You have unsaved data on the page. Click Save to commit
                        the updates
                      </span>
                    )}
                  <button
                    type="button"
                    className="linkBtn"
                    onClick={cancelHandler}
                  >
                    CANCEL
                  </button>
                  <button
                    disabled={disabledSaveButton || !allowSave  || !secondaryMarketAllowSave}
                    type="button"
                    className="ant-btn btnSave"
                    onClick={saveHandler}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isFromQuickEdit && (
          <React.Fragment>
            {ifScrollAvailable && (
              <div className="floatingFooter">
                {(JSON.stringify(metadata) !==
                    JSON.stringify(metadataUpdateData) ||

                    JSON.stringify(marketPlaceInfo) !==
                    JSON.stringify(metadataUpdateData?.secondary_marketplace)) &&
                  !loadingMetadata &&
                  !isSavingMetadata && (
                    <div className="blinkWrap">
                      <div className="pulsating-circle"></div>
                    </div>
                  )}

                <div className="rightBlk">
                  {(JSON.stringify(metadata) !==
                    JSON.stringify(metadataUpdateData) ||

                    JSON.stringify(marketPlaceInfo) !==
                    JSON.stringify(metadataUpdateData?.secondary_marketplace)) &&

                    !loadingMetadata &&
                    !isSavingMetadata && (
                      <span className="unsavedData">
                        You have unsaved data on the page. Click Save to commit
                        the updates
                      </span>
                    )}
                  <button
                    type="button"
                    className="linkBtn"
                    onClick={cancelHandler}
                  >
                    CANCEL
                  </button>
                  <button
                    disabled={disabledSaveButton || !allowSave || !secondaryMarketAllowSave}
                    type="button"
                    className="ant-btn btnSave"
                    onClick={saveHandler}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </LoadingSpinner>
    </Layout>
  );
};

export default React.memo(EditEventAvailability);
