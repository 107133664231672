import React, { useEffect, useState } from "react";
import { Card, Col, Layout, Row } from "antd";
import { LongInventoryScorecardService } from "services";
import { BrokerHeaders, generateEncodedUrl } from "./CustomBrokerTableUtils";
import CommonReportTable from "utils/table/CommonReportTable";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { useRbac } from "features/rbac/rbac";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import {
  alert_Ids,
  getRandomValue,
  permissionSet,
  setCurrencyFormattedClass,
  setCurrencyFormattedData
} from "utils/commonFunction";

const CustomBroker: React.FC<{
  brokerId: string | null,
  reportData: any | null
}> = ({ brokerId, reportData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [brokerData, setBrokerData] = useState<any | null>();
  const [brokerAggregateData, setBrokerAggregateData] = useState<any | null>();
  const [brokerDataFetchError, setBrokerDataFetchError] = useState<ICustomError | null>(null);
  const { hasPermissions } = useRbac();

  async function getBrokerData() {
    try {
      setIsLoading(true);
      const scorecardResponseData =
        await new LongInventoryScorecardService().longInventoryData({
          ...{
            client_zone: 'America/New_York',
            currency_Id: '1',
            event_date_from: '2020-01-01',
            broker_Id: brokerId
          },
        });
      setBrokerAggregateData(scorecardResponseData.data.data.scorecardReports['aggregate'][0]);
      setBrokerData(scorecardResponseData.data.data);
      setIsLoading(false);
      setBrokerDataFetchError(null);
    } catch (error: any) {
      setIsLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getBrokerData'
      })
      setBrokerDataFetchError({ ...error, refreshAction: getBrokerData });
    }
  }

  useEffect(() => {
    getBrokerData();
  }, []);

  if (!brokerData?.scorecardReports.data) {
    return null;
  }

  const formatLabel = (
    data: any,
    type: string,
    label: string
  ) => {
    if (hasPermissions(permissionSet) && data && data[type]) {
      return (
        <a
          target='_blank'
          rel="noreferrer"
          href={generateEncodedUrl({
            alert_Id: alert_Ids[type],
            broker_Id: brokerId
          }, reportData)}
        >
          {label}
        </a>
      )
    }
    return (
      <div className="noPermissionText">
        {label}
      </div>
    )
  }

  return (
    <Layout className="mainInnerLayout reduceTopSpace">
      {brokerDataFetchError ?
        <CustomError {...brokerDataFetchError} />
        :
        <React.Fragment key={getRandomValue("number")}>
          <h3>Broker: <span style={{ textTransform: 'capitalize' }}>{brokerData?.scorecardReports?.broker}</span></h3>
          <LoadingSpinner isLoading={isLoading}>
            <Row className="rowSpc-6 mb20">
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className={setCurrencyFormattedClass(brokerAggregateData, "capital_applied")}>
                      {setCurrencyFormattedData(brokerAggregateData, "capital_applied", true)}
                    </h2>
                    <p className='scorecard-text'>
                      CAPITAL APPLIED
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className={setCurrencyFormattedClass(brokerAggregateData, "potential_earnings")}>
                      {setCurrencyFormattedData(brokerAggregateData, "potential_earnings", true)}
                    </h2>
                    <p className='scorecard-text'>
                      POTENTIAL EARNINGS
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="text-danger">
                      {setCurrencyFormattedData(brokerAggregateData, "less_than_fifteen")}
                    </h2>
                    <p>
                      {formatLabel(brokerAggregateData, "less_than_fifteen", "EVENTS NEEDING ATTENTION")}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="text-warning">
                      {setCurrencyFormattedData(brokerAggregateData, "fifteen_to_thirty")}
                    </h2>
                    <p>
                      {formatLabel(brokerAggregateData, "fifteen_to_thirty", "EVENTS COMING SOON")}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="text-danger">
                      {setCurrencyFormattedData(brokerAggregateData, "costing_issue")}
                    </h2>
                    <p>
                      {formatLabel(brokerAggregateData, "costing_issue", "LISTING ERRORS")}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
                <Card className="cardStyle2">
                  <div className="cardBody">
                    <h2 className="text-warning">
                      {setCurrencyFormattedData(brokerAggregateData, "card_missing")}
                    </h2>
                    <p>
                      {formatLabel(brokerAggregateData, "card_missing", "MISSING CARD DETAILS")}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <div>
              <CommonReportTable
                headers={BrokerHeaders}
                data={brokerData?.scorecardReports.data ?? null}
                tooltipData={brokerData?.scorecardReports.tooltip[0] ?? null}
                tableHeight={{ maxHeight: 'calc(100vh - 250px)' }}
                footerData={brokerData?.scorecardReports.aggregate}
                tableClass="customTbl scroll-x-y fixedHeaderFooter"
                otherData={
                  {
                    broker_Id: brokerId,
                    reportData,
                    hasPermissions: hasPermissions(permissionSet)
                  }}
              />
            </div>
          </LoadingSpinner>
        </React.Fragment>
      }
    </Layout>
  );
};

export default CustomBroker;
