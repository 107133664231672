import React from "react";
import { useAppSelector } from "app/hooks";
import {
  eventListData,
  isLoadingEvents,
} from "models/Slices/EventManagementSlice";
import EventEachCard from "./EventEachCard";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CardSkeleton from "views/Zones/ZoneListings/CardSkeleton";
import { isMobile } from "react-device-detect";

const EventsGridView: React.FC = () => {

  const eventListDetails = useAppSelector(eventListData);
  const isEventDetailsLoading = useAppSelector(isLoadingEvents);

  return (
    <React.Fragment>
      <LoadingSpinner isLoading={!isMobile && isEventDetailsLoading}>
        {isEventDetailsLoading && (
          <CardSkeleton isLoading={isEventDetailsLoading} />
        )}
        {!isEventDetailsLoading && (
          <div className="zonesGridView eventsGridView">
            {eventListDetails.eventDetailsData &&
            eventListDetails.eventDetailsData.length > 0 ? (
              <div className="listRow">
                {eventListDetails.eventDetailsData.map((eachEvent) => {
                  return (
                    <EventEachCard
                      key={eachEvent.event_id}
                      eachEventData={eachEvent}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="noResultFoundBlock">No Result Found</div>
            )}
          </div>
        )}
      </LoadingSpinner>
    </React.Fragment>
  );
};

export default EventsGridView;
