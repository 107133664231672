import React, { useEffect, useState } from "react";
import { IChartData, IChartDetails, IFilterOptions } from "models/interfaces";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const Charts: React.FC<{
  chartData: IChartData[];
  filterData: IFilterOptions | null;
}> = ({ chartData, filterData }) => {
  const [granularity, setGranularity] = useState("week");
  const [activeChart, setActiveChart] = useState<IChartDetails>(
    chartData && chartData[0]
  );
  const [baseIframeUrl, setBaseIframeUrl] = useState(activeChart.chart_url);
  const [iframeUrl, setIframeUrl] = useState(baseIframeUrl);
  const [loading, setLoading] = useState(true);

  const getParam = () => {
    const payload = {
      granularity: granularity,
      start_date: filterData?.custom_date_from?.toString()?.split('-').join(''),
      end_date : filterData?.custom_date_to?.toString()?.split('-').join('')
    };
    return encodeURIComponent(JSON.stringify(payload));
  };

  useEffect(() => {
    if (activeChart.chart_type !== 'donut' && activeChart.chart_type !== 'insight') {
      setIframeUrl(`${baseIframeUrl}?params=${getParam()}`);
    }
  }, [activeChart, granularity, baseIframeUrl, filterData]);

  const getChartActionsJsx = () => {
    if (
      activeChart.chart_type !== "bar" &&
      activeChart.chart_type !== "bar_with_manual"
    ) {
      return (
        chartData &&
        chartData.length > 1 &&
        chartData.map((data, index) => (
          <button
            onClick={() => {
              setActiveChart(chartData[index]);
              setBaseIframeUrl(data?.chart_url);
            }}
            className={
              activeChart?.chart_class == data?.chart_class ? "active" : ""
            }
            key={data?.chart_type}
          >
            <i className={data?.chart_class}></i>
          </button>
        ))
      );
    }
  };

  const compareWithManualChangeHandler = (e: CheckboxChangeEvent) => {
    let chartDetails = chartData.filter(eachChart => eachChart.chart_type === 'bar')[0];
    if(e.target.checked) {
      chartDetails = chartData.filter(eachChart => eachChart.chart_type === 'bar_with_manual')[0];
    }
    setActiveChart(chartDetails);
    setBaseIframeUrl(chartDetails.chart_url);
  };

  const getCompareWithManualJsx = () => {
    if (
      activeChart.chart_type === "bar" ||
      activeChart.chart_type === "bar_with_manual"
    ) {
      return (
        <div className="botPerfWidgetCheckbox">
          <Checkbox onChange={compareWithManualChangeHandler}/>&nbsp;
          <label className="botPerfWidgetCheckboxLabel">Compare with Manual Scraping</label>
        </div>
      );
    }
  };

  return (
    <div className="dashboardCard2">
      <div className="dashboardCard">
        <div className="chartTopbar">
          <h5>{activeChart?.chart_heading}</h5>
        </div>
        <div className={`chartBody pb-0 ${activeChart.chart_type === 'insight' ? "insightsLookerWrap" : "lookerWrap"}`}>
          <LoadingSpinner isLoading={loading}></LoadingSpinner>
          <iframe
            className="lookerIframe"
            onLoad={(e: any) => {
              setLoading(false);
            }}
            src={iframeUrl}
          />
        </div>
        {getCompareWithManualJsx()}
        {((activeChart && activeChart.granularity) ||
          (chartData && chartData.length > 1)) && (
          <div className="chartFooter">
            <div className="chartBtmBar">
              <ul>
                {activeChart.granularity &&
                  activeChart.granularity.map((data: any) => (
                    <li key={data.key}>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setGranularity(data.value);
                        }}
                        href=""
                        className={data.value == granularity ? "active" : ""}
                      >
                        {data.key}
                      </a>
                    </li>
                  ))}
              </ul>
              <div className={`${activeChart.chart_type !== 'bar' && activeChart.chart_type !== 'bar_with_manual' ? "chartIconList" : ""}`}>
              {getChartActionsJsx()}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Charts;
