import { RoutesEnum } from "models/enums";
import {
  IBulkAssignBodyData,
  IVenueListData,
  IVenueListQueryParams,
  IVenueEditBody,
  IMaster,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class VenueService {
  public getVenueDetailsData = async (
    venueDetailsRequest: IVenueListQueryParams
  ) => {
    return axiosClient.get<IApiBaseResponse<IVenueListData>>(
      RoutesEnum.VENUE_DETAILS,
      {
        params: venueDetailsRequest,
      }
    );
  };

  public addRemoveFavorite = async (venueId: number, operationType: number) => {
    return axiosClient.post<IApiBaseResponse<boolean>>(
      RoutesEnum.VENUE_ADD_REMOVE_FAVORITES,
      {
        venue_id: venueId,
        operation_type: operationType,
      }
    );
  };

  public editVenueDetails = async (venueEditBody: IVenueEditBody) => {
    return axiosClient.post<IApiBaseResponse<boolean>>(RoutesEnum.VENUE_EDIT, {
      ...venueEditBody,
    });
  };

  public getBrokerTeamByBrokerId = async (brokerId: string) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.BROKER_TEAM_BY_BROKER,
      {
        params: {
          brokerId,
        },
      }
    );
  };

  public bulkAssignVenues = async (
    bulkAssignRequestBody: IBulkAssignBodyData
  ) => {
    return axiosClient.patch<IApiBaseResponse<boolean>>(
      RoutesEnum.VENUE_BULK_ASSIGN_BROKER,
      {
        ...bulkAssignRequestBody,
      }
    );
  };

  public getBuyerListByVenueId = async (venue_id: number) => {
    const URL = RoutesEnum.VENUE_BUYER_LIST.replace(
      "{venue_id}",
      venue_id.toString()
    );
    return axiosClient.get<IApiBaseResponse<IMaster[]>>(URL);
  };
}
