import { Modal, Tooltip, Typography } from "antd";
import CustomError from "controls/CustomError/CustomError";
import { RBAC } from "features/rbac/rbac";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { IMiscDatum, ISplitDatum } from "models/interfaces";
import { ICustomError } from "models/interfaces/ICustomError";
import React, { useCallback, useEffect, useState } from "react";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";
import { AppSetting } from "utils/appSettings";
import {
  titleCase,
  twoDecimalPlacesNumber,
} from "utils/commonFunction";
import { formatDateGivenFormatString } from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import ActiveListingBroadcastStatusChange from "../ActiveListingComponents/Actions/ActiveListingBroadcastStatusChange";
import SplitListing from "../ActiveListingComponents/Actions/SplitListing";

const SplitInventoryView: React.FC<{
  showSplitView: boolean;
  setShowSplitView: Function;
  inventory_id?: number;
  isFromQuickEdit?: boolean;
  onCloseGetSplitInventoryData?: Function;
}> = ({
  showSplitView,
  setShowSplitView,
  inventory_id,
  isFromQuickEdit,
  onCloseGetSplitInventoryData
}) => {
  const [isLoadingSplitData, setIsLoadingSplitData] = useState(false);
  const [splitData, setSplitData] = useState<ISplitDatum[] | null>(null);
  const [miscData, setMiscData] = useState<IMiscDatum>();
  const [dataFetchError, setDataFetchError] = useState<ICustomError | null>(
    null
  );

  const setUpdatedBroadcastStatus = (inventory_id: number, broadcast_status: boolean) => {
    if(splitData) {
      const _splitData = [...splitData];
      const findInventory = _splitData.find((eachListingData) => {
        return eachListingData.inventory_id === inventory_id;
      });
      if (findInventory) {
        findInventory.broadcast = broadcast_status;
        setSplitData(_splitData);
      }
    }
  }

  const getSplitListingCta = (splitDatum: ISplitDatum) => {
    if (splitDatum.inventory_quantity > 1) {
      return (
        <SplitListing
          actionElement={
            <Tooltip title="Split">
              <Typography.Link>
                <i className="da icon-split"></i>
              </Typography.Link>
            </Tooltip>
          }
          data={{
            eventId: splitDatum.event_id,
            eventName: splitDatum.event_name,
            eventDate: splitDatum.event_date?.toString(),
            venueName: splitDatum.venue_name,
            venueLocation: splitDatum.venue_location,
            purchaseId: splitDatum.purchase_id,
            purchaseLineId: splitDatum.purchase_line_id,
            inventoryId: splitDatum.inventory_id,
            quantity: splitDatum.inventory_quantity,
            row: splitDatum.row,
            section: splitDatum.section,
            listPrice: splitDatum.list_price
          }}
          refreshListings={refreshListingsAfterSplit}
        />
      );
    }
  };

  const refreshListingsAfterSplit = () => {
    if (splitData && splitData.length) {
      getSplitData(splitData[0]?.inventory_id);
    }
  };

  const getBroadcastCta = (splitDatum: ISplitDatum) => {
      return (
        <ActiveListingBroadcastStatusChange
          splitData={splitDatum}
          setUpdatedBroadcastStatus={setUpdatedBroadcastStatus}
          eventId={splitDatum.event_id}
          eventName={splitDatum.event_name}
          eventDate={splitDatum.event_date?.toString()}
          venueName={splitDatum.venue_name}
          venueLocation={splitDatum.venue_location}
          isPoLineCard={true}
        />
      );
  };

  const SplitInventoryViewDataHeaders = [
    {
      title: "PO Line#",
      dataIndex: "purchase_line_id",
      width: 40,
      thClassName: "text-left",
      dataRender: (
        purchase_line_id: number,
        otherData: { data: { singleData: ISplitDatum } }
      ) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={
            AppSetting.PURCHASE_ID_URL + otherData.data.singleData.purchase_id
          }
        >
          {purchase_line_id}
        </a>
      ),
    },
    {
      title: "SECTION",
      dataIndex: "section",
      width: 60,
      dataRender: (section: string) => (
        <div>{section ? section.toUpperCase() : ""}</div>
      ),
    },
    {
      title: "ROW",
      dataIndex: "row",
      width: 60,
      dataRender: (row: string) => (
        <div>{row ? row.toUpperCase() : ""}</div>
      ),
    },
    {
      title: "QTY",
      dataIndex: "inventory_quantity",
      width: 20,
      thClassName: "text-right",
      dataRender: (inventory_quantity: number) => (
        <div className="text-right">{inventory_quantity}</div>
      ),
    },
    {
      title: "SEATS",
      dataIndex: "seat_numbers",
      width: 80,
    },
    {
      title: "PRICE",
      dataIndex: "list_price",
      width: 70,
      thClassName: "text-right eventDateTimeTh",
      dataRender: (list_price: number) => (
        <div className="text-right">${twoDecimalPlacesNumber(list_price)}</div>
      ),
    },
    {
      title: miscData?.venue_fees ? <div>MAX COST<Tooltip title="Venue Fees Included"><i className="da icon-venue-fees" style={{ marginLeft: '5px', top: '-2px'}}/></Tooltip></div> : "MAX COST",
      dataIndex: "cost_price",
      width: 70,
      thClassName: "text-right eventDateTimeTh",
      dataRender: (cost_price: number) => (
        <div className="text-right">${twoDecimalPlacesNumber(cost_price)}</div>
      ),
    },
    {
      title: "",
      dataIndex: "purchase_line_id",
      thClassName: "text-right actionCol",
      width: 65,
      dataRender: (
        data: number,
        all: { data: { singleData: ISplitDatum } }
      ) => {
        return (
            <div className="text-right">
            <div className="splitViewActions">
                <RBAC
                  allowedPermissions={[
                    ERbacPermissions.ZONES_ACTIVE_LISTING_SPLIT_ACTION,
                  ]}
                >
                  {getSplitListingCta(all.data.singleData)}
                </RBAC>
                <span>{getBroadcastCta(all.data.singleData)}</span>
            </div>
          </div>
        )
      },
    },
  ];

  const getSplitData = useCallback(async (inventory_id: number) => {
    setIsLoadingSplitData(true);
    try {
      const res = await new ZonesService().getSplitInventoryData({
        inventory_id,
      });
      const { data } = res.data;
      setSplitData(data.split_data);
      res.data.data.misc_data && setMiscData(res.data.data.misc_data[0]);
      setIsLoadingSplitData(false);
    } catch (error: any) {
      setIsLoadingSplitData(false);
      await new LoggerService().log({
        payload: error,
        function_name: "getSplitData",
      });
      setDataFetchError({ ...error, refreshAction: getSplitData });
    }
  }, []);

  useEffect(() => {
    if (showSplitView && inventory_id) {
      getSplitData(inventory_id);
    }
  }, [inventory_id, getSplitData, showSplitView]);

  const filterSplitData = () => {
    const originalSplitData = splitData ? [...splitData] : [];
    if(isFromQuickEdit){
      return originalSplitData.filter(eachData => (eachData.is_platform_listing === true && eachData.broadcast === true));
    }
    else{
      return originalSplitData;
    }
  }

  return (
    <Modal
      title={false}
      footer={false}
      visible={showSplitView}
      width={800}
      className="editModal"
      onCancel={() => {
        setShowSplitView(false);
        onCloseGetSplitInventoryData && onCloseGetSplitInventoryData(filterSplitData());
      }}
    >
      <LoadingSpinner isLoading={isLoadingSplitData}>
        <div className="splitViewModal">
          <div className="splitViewModalHeader">{`Split Inventory Details for PO# ${
            splitData && splitData.length ? splitData[0]?.purchase_id : ""
          }`}</div>
          <div className="splitViewModalBody">
            <div className="splitViewEventName">
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={AppSetting.EVENT_URL + miscData?.event_id}
              >
                {titleCase(miscData?.event_name)}
              </a>
              {Boolean(miscData?.event_date) && (
                <span>
                  {` (${formatDateGivenFormatString(
                    miscData?.event_date,
                    "ddd, MMM DD, YYYY hh:mm A"
                  )})`}
                </span>
              )}
            </div>
            <div>
              {Boolean(miscData && miscData.venue_location) && (
                <React.Fragment>
                  {`${miscData?.venue_name}, ${miscData?.venue_location}`}
                </React.Fragment>
              )}

              {!Boolean(miscData && miscData.venue_location) &&
                Boolean(
                  miscData &&
                    miscData.venue_name.trim().toLowerCase() !==
                      "to be determined"
                ) && (
                  <React.Fragment>
                    {titleCase(miscData?.venue_name)}
                  </React.Fragment>
                )}
            </div>
          </div>

          <React.Fragment>
            {dataFetchError && <CustomError {...dataFetchError} />}
            {!dataFetchError && (
              <div
                className="purchaseAssignmentTable"
              >
                <CommonReportTable
                  data={filterSplitData()}
                  headers={SplitInventoryViewDataHeaders}
                  loading={false}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      </LoadingSpinner>
    </Modal>
  );
};

export default SplitInventoryView;
