import { RoutesEnum } from "models/enums";
import { IConversionRateResponse, ISectionQueryParams, ISectionResponse } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class PerformanceAnalysisDashboardService {
  public getCreatedSectionCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_CREATED_SECTIONS,
      {
        params: { ...query },
      }
    );
  };

  public getCheckedSectionCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_CHECKED_SECTIONS,
      {
        params: { ...query },
      }
    );
  };

  public getGAEventsCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_UPDATED_GA_EVENTS_SECTIONS,
      {
        params: { ...query },
      }
    );
  };

  public getSplitCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_SPLIT_INFO,
      {
        params: { ...query },
      }
    );
  };

  public getVenueAssignmentCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_VENUE_ASSIGNMENT_INFO,
      {
        params: { ...query },
      }
    );
  };

  public getListedEventsCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_EVENTS_INFO,
      {
        params: { ...query },
      }
    );
  };

  public getListedSectionsCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_SECTIONS_INFO,
      {
        params: { ...query },
      }
    );
  };

  public getListedGAEventsCount = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_LISTED_GA_EVENTS_INFO,
      {
        params: { ...query },
      }
    );
  };

  public getListerConversionRate = async () => {
    return axiosClient.get<IApiBaseResponse<IConversionRateResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_LISTER_CONVERSION_RATE_INFO
    );
  };

  public getListerMarginOfPricing = async (query: ISectionQueryParams) => {
    return axiosClient.get<IApiBaseResponse<ISectionResponse>>(
      RoutesEnum.DASHBOARD_PERFORMANCE_ANALYSIS_LISTER_MARGIN_OF_PRICING_INFO,
      {
        params: { ...query },
      }
    );
  };

}
