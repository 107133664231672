import { CurrencyCode } from "models/enums";
import { FixedColumnDirection } from "models/enums/table";
import { AppSetting } from "utils/appSettings";
import {
  addToolTip,
  formatAccountingValues,
  formatNumericValues,
  twoDecimalPlacesNumber,
} from "utils/commonFunction";
import { FormatCurrencyUnit } from "utils/formatCurrency";
import { getCustomDate } from "utils/formatTimePeriod";

const commonWidth = 100;
export const OtherCostsAndCreditsTableHeaders = [
  {
    title: "INVOICE ID",
    dataIndex: "invoice_id",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataHeaderRender: (invoiceDateHeader: string) => (
      <div className="text-left">{invoiceDateHeader}</div>
    ),
    dataRender: (invoice_id: any) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={AppSetting.INVOICE_ID_URL + invoice_id}
      >
        {invoice_id}
      </a>
    ),
  },
  {
    title: "INVOICE DATE",
    dataIndex: "invoice_date",
    width: commonWidth,
    fixed: FixedColumnDirection.left,
    canSort: true,
    dataRender: (invoice_date: string) =>
      invoice_date &&
      getCustomDate(invoice_date, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "OTHER COST CREATED DATE",
    dataIndex: "other_cost_created_date",
    width: commonWidth,
    canSort: true,
    dataRender: (other_cost_created_date: string) =>
      other_cost_created_date &&
      getCustomDate(other_cost_created_date, AppSetting.REPORT_DATE_FORMAT),
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    thClassName: "text-left",
    width: 50,
    dataRender: (description: string) => 
    description?.length > 50 ? addToolTip(description, "top", "text", 50) : description
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (qty: number) => (
      <div className={`text-right ${qty < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qty)}
      </div>
    ),
    dataHeaderRender: (qtyHeader: number) => (
      <div className={`text-right ${qtyHeader < 0 ? "text-danger" : ""}`}>
        {formatNumericValues(qtyHeader)}
      </div>
    ),
  },
  {
    title: "UNIT COST",
    dataIndex: "unit_cost",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (unit_cost: number) => (
      <div className={`text-right ${unit_cost < 0 ? "text-danger" : ""}`}>
        {twoDecimalPlacesNumber(unit_cost)}
      </div>
    ),
    dataHeaderRender: (unitCostHeader: any) => (
      <div className={`text-right ${unitCostHeader < 0 ? "text-danger" : ""}`}>
        {unitCostHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(unitCostHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(unitCostHeader)}
      </div>
    ),
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    width: commonWidth,
    thClassName: "text-right",
    canSort: true,
    dataRender: (amount: number) => (
      <div className={`text-right ${amount < 0 ? "text-danger" : ""}`}>
        {twoDecimalPlacesNumber(amount)}
      </div>
    ),
    dataHeaderRender: (amountHeader: any) => (
      <div className={`text-right ${amountHeader < 0 ? "text-danger" : ""}`}>
        {amountHeader > 0
          ? FormatCurrencyUnit(
              formatAccountingValues(amountHeader),
              CurrencyCode.Usd
            )
          : formatAccountingValues(amountHeader)}
      </div>
    ),
  },
  {
    title: "INVOICE TAGS",
    dataIndex: "invoice_tag",
    canSort: true,
    width: commonWidth,
    dataRender: (sale_category: any) => <div>{sale_category?.join(', ')}</div>,
  },
  {
    title: "INVENTORY TAGS",
    dataIndex: "inventory_tag",
    canSort: true,
    width: commonWidth,
    dataRender: (sale_category: any) => <div>{sale_category?.join(', ')}</div>,
  },
  {
    title: "SALE CATEGORY",
    dataIndex: "sale_category",
    canSort: true,
    width: commonWidth,
    dataRender: (sale_category: any) => <div>{sale_category?.join(', ')}</div>,
  },
];
