import { Select } from "antd";
import React from "react";
import { getViewInhandDate, inHandTypes } from "utils/commonFunction";

const CreateListingInHandDate: React.FC<{ 
  metaData: any
  inHandDateType: number;
  setInHandDateType: Function; 
  labelName: string; 
  ifRequire: boolean; 
}> = ({ 
  metaData, 
  inHandDateType, 
  setInHandDateType, 
  labelName, 
  ifRequire
}) => {
  return (
    <React.Fragment>
      <label>
        {labelName} 
        {ifRequire &&
          <span className="req"> *</span>
        }
      </label>
      <div className="inputGroup removeErrorDiv" style={{marginBottom: inHandDateType ? "20px" : ""}}>
        <Select
          placeholder="Select"
          allowClear={false}
          value={inHandTypes[inHandDateType].value}
          options={inHandTypes}
          optionFilterProp="label"
          onChange={(value: any) => {
            setInHandDateType(value);
          }}
          style={{marginBottom: inHandDateType ? "5px" : "0"}}
        />
        {inHandDateType > 0 && metaData && 
          getViewInhandDate(
            inHandDateType, 
            metaData.days_to_event, 
            metaData.event_date)}
      </div>
    </React.Fragment>
  );
}

export default CreateListingInHandDate;