import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { LongInventoryScorecardService } from "services";
import { generateEncodedUrl } from "./CustomBrokerTableUtils";
import { LoggerService } from 'sharedServices/api/logger.service';
import { ICustomError } from 'models/interfaces/ICustomError';
import CustomError from 'controls/CustomError/CustomError';
import { useRbac } from "features/rbac/rbac";
import { IReportQueryString } from "models/interfaces";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import {
  alert_Ids,
  getRandomValue,
  permissionSet,
  setCurrencyFormattedClass,
  setCurrencyFormattedData
} from "utils/commonFunction";

const AllBrokers: React.FC<{
  reportData: IReportQueryString;
}> = ({ reportData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scorecardData, setScorecardData] = useState<any | null>();
  const [allBrokersDataFetchError, setAllBrokersDataFetchError] = useState<ICustomError | null>(null);
  const {hasPermissions} = useRbac();
  
  async function getScorecardData() {
    try {
      setIsLoading(true);
      const scorecardResponseData =
        await new LongInventoryScorecardService().longInventoryData({
            ...{
                client_zone: 'America/New_York',
                currency_Id: '1',
                event_date_from: '2020-01-01',
                broker_Id: ''
            }
        });
      setScorecardData(scorecardResponseData.data.data.scorecardReports['aggregate'][0]);
      setAllBrokersDataFetchError(null)
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      await new LoggerService().log({
        payload: error,
        function_name: 'getScorecardData'
      })
      setAllBrokersDataFetchError({...error, refreshAction: getScorecardData});
    }
  }

  useEffect(() => {
    getScorecardData();
  }, []);

  const formatLabel = (
    data: any,
    type: string,
    label: string
  ) => {
    if (hasPermissions(permissionSet) && data && data[type]) {
      return (
        <a
          target='_blank'
          href={generateEncodedUrl({
            alert_Id: alert_Ids[type]
          }, reportData)}
        >
          {label}
        </a>
      )
    }
    return (
      <div className="noPermissionText">
        {label}
      </div>
    )
  }

  return (
    allBrokersDataFetchError
    ?
    <CustomError {...allBrokersDataFetchError} />
    :
    <React.Fragment key={getRandomValue("number")}>
      <h3>All Brokers</h3>
      <LoadingSpinner isLoading={isLoading}>
      <Row className="rowSpc-6 mb20 rowReduceTopBtm">
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className={setCurrencyFormattedClass(scorecardData, "capital_applied")}>
                  {setCurrencyFormattedData(scorecardData, "capital_applied", true)}
                </h2>
                <p className='scorecard-text'>
                  CAPITAL APPLIED
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
              <h2 className={setCurrencyFormattedClass(scorecardData, "potential_earnings")}>
                  {setCurrencyFormattedData(scorecardData, "potential_earnings", true)}
                </h2>
                <p className='scorecard-text'>
                  POTENTIAL EARNINGS
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
              <h2 className="text-danger">
                  {setCurrencyFormattedData(scorecardData, "less_than_fifteen")}
                </h2>
                <p>
                  {formatLabel(scorecardData, "less_than_fifteen", "EVENTS NEEDING ATTENTION")}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-warning">
                  {setCurrencyFormattedData(scorecardData, "fifteen_to_thirty")}
                </h2>
                <p>
                  {formatLabel(scorecardData, "fifteen_to_thirty", "EVENTS COMING SOON")}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-danger">
                  {setCurrencyFormattedData(scorecardData, "costing_issue")}
                </h2>
                <p>
                  {formatLabel(scorecardData, "costing_issue", "LISTING ERRORS")}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} className="colInrSpace6">
            <Card className="cardStyle2">
              <div className="cardBody">
                <h2 className="text-warning">
                  {setCurrencyFormattedData(scorecardData, "card_missing")}
                </h2>
                <p>
                  {formatLabel(scorecardData, "card_missing", "MISSING CARD DETAILS")}
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </LoadingSpinner>
    </React.Fragment>
  );
};

export default AllBrokers;
