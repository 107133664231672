import { Button, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  IMenuOption,
  IMoveToAddNotesPostData,
  ISwimlaneMaster,
} from "models/interfaces";
import React, { useState } from "react";
import { PurchaseAndShippingService } from "services";
import { LoggerService } from "sharedServices";
import { formatThousandSeparator } from "utils/commonFunction";

const AddNote: React.FC<{
  menuData: IMenuOption;
  invoice_id: number;
  swimlaneData: ISwimlaneMaster;
  setShowMenu: Function;
  handleMenuSelection: Function;
  selectedMenuId: number | null;
  setSelectedMenuId: Function;
  setShowNotesHistory: Function;
}> = ({
  menuData,
  invoice_id,
  swimlaneData,
  setShowMenu,
  handleMenuSelection,
  selectedMenuId,
  setSelectedMenuId,
  setShowNotesHistory
}) => {
  const [notesModal, setNotesModal] = useState(false);
  const [hasHTML, setHasHTML] = useState(false);
  const [hasJS, setHasJS] = useState(false);
  const [moveToAddNotesData, setMoveToAddNotesData] =
    useState<IMoveToAddNotesPostData>({
      invoice_id: invoice_id,
      swimlane_id: swimlaneData.id,
      notes: "",
    });
  const notesChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setMoveToAddNotesData((prev) => ({
      ...prev,
      notes: e.currentTarget.value,
    }));
    setHasHTML(/<([^>]+)>/g.test(e.currentTarget.value));//NOSONAR
    setHasJS(/(?<=\s)on\w+=/.test(e.currentTarget.value));
  };

  const submitMovedToAddNotes = async () => {
    const moveAddNotesKey = "moveAddNotesKey";
    message.loading({
      content: `Adding notes for Invoice in ${swimlaneData.name} ...`,
      duration: 0,
      key: moveAddNotesKey,
      className: "toastMsg loadingMsg",
    });

    try {
      setShowMenu(false);
      setNotesModal(false);
      await new PurchaseAndShippingService().moveToAddNotes(moveToAddNotesData);
      setMoveToAddNotesData((prev) => ({
        ...prev,
        notes: "",
      }));
      message.success({
        content: `Added notes for Invoice in #${invoice_id}`,
        duration: 5,
        key: moveAddNotesKey,
        className: "toastMsg savedSuccess",
      });
      setShowNotesHistory(true)
    } catch (error: any) {
      new LoggerService().log({
        payload: error,
        function_name: "submitMovedToAddNotes",
      });
      message.error({
        key: moveAddNotesKey,
        content: "Failed to add notes.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
    }
  };

  return (
    <React.Fragment>
      <div
        className="accHead"
        onClick={() => {
          if (selectedMenuId === menuData.menu_id) {
            handleMenuSelection(null);
            setMoveToAddNotesData((prev) => ({
              ...prev,
              notes: "",
            }));
          } else {
            handleMenuSelection(menuData.menu_id);
          }
          setNotesModal(true);
        }}
      >
        {menuData.name}
      </div>
      {notesModal && (
        <Modal
          closable={false}
          width={500}
          title={false}
          footer={false}
          visible={notesModal}
          centered
          className="editModal"
          maskClosable={true}
        >
          <div className="modalHeader">
            <div className="modalHeadingMain">Add Note</div>
            <div
              className="modalHeaderChild eventDt"
              style={{ flexDirection: "column" }}
            >
              <div style={{ marginBottom: "12px" }}>
                <h3>Description :</h3>
                <TextArea
                  maxLength={1000}
                  status={ hasHTML || hasJS ? 'error' : '' }
                  showCount={{
                    formatter: ({ count, maxLength }) =>
                      maxLength
                        ? `${formatThousandSeparator(
                            (maxLength ?? 0) - count
                          )} Remaining`
                        : "",
                  }}
                  placeholder="Note"
                  className="moveToIssuesTxtArea"
                  rows={4}
                  value={
                    moveToAddNotesData?.notes ? moveToAddNotesData.notes : ""
                  }
                  onChange={notesChangeHandler}
                  allowClear
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
                <p style={{color: 'red'}}>{ hasHTML || hasJS ? 'Html or Javascript detected' : '' }</p>
              </div>
              <div
                className="btnGroupFooter"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  className="discardBtn"
                  style={{ marginRight: "12px" }}
                  onClick={() => {
                    setSelectedMenuId(null);
                    setNotesModal(false);
                    setMoveToAddNotesData((prev) => ({
                      ...prev,
                      notes: "",
                    }));
                  }}
                >
                  <span>CANCEL</span>
                </Button>
                {moveToAddNotesData &&
                moveToAddNotesData.notes &&
                moveToAddNotesData.notes.length > 0 && !hasHTML && !hasJS ? (
                  <Button className="btnUpdate" onClick={submitMovedToAddNotes}>
                    Save
                  </Button>
                ) : (
                  <Button className="btnUpdate" disabled>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default AddNote;
