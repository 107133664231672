import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Pagination } from "antd";
import {ActiveZoneListingService } from "services";
import {
  IActiveZoneListingData,
  IFilterOptions,
} from "models/interfaces";
import CommonReportTable from "utils/table/CommonReportTable";
import { ActiveZoneListingTableHeaders } from "./activeZoneListingUtils";
import { LoggerService } from "sharedServices/api/logger.service";
import { ICustomError } from "models/interfaces/ICustomError";
import CustomError from "controls/CustomError/CustomError";
import AppliedFilters from "utils/commonFilter/AppliedFilters";
import { AppSetting } from "utils/appSettings";

import { formatReportCount, formatThousandSeparator } from "utils/commonFunction";

const { Content } = Layout;
const DEFAULT_PAGE_INDEX = AppSetting.DEFAULT_PAGE_INDEX;
const PAGE_SIZE = AppSetting.PAGE_SIZE;

const ActiveZoneListingReportTable: React.FC<{
  filterObject: IFilterOptions | null;
  appliedFilterValues: any | null;
  searchQuery: string | null;
}> = ({ filterObject, appliedFilterValues, searchQuery }) => {

  const [defaultQueryParams] = useState<any>({
    client_zone: "America/New_York",
    currency_Id: null,
    page_index: DEFAULT_PAGE_INDEX,
    page_size: PAGE_SIZE,
    sort_column: "po_date",
    sort_order: "desc",
    search_text: "",
  });
  const [activeListingData, setActiveListingData] =
    useState<IActiveZoneListingData | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [queryParams, setQueryParams] = useState<any>(null);
  const [activeListingDataFetchError, setActiveListingDataFetchError] =
    useState<ICustomError | null>(null);

  useEffect(() => {
    if (filterObject !== null && defaultQueryParams) {
      setQueryParams({
        ...defaultQueryParams,
        ...queryParams,
        ...filterObject,
        page_index: DEFAULT_PAGE_INDEX,
        search_text: searchQuery,
      });
    }
  }, [defaultQueryParams, filterObject, searchQuery]);

  const getActiveZoneListingsData = async (query: any) => {
    setShowLoader(true);
    let response: any;
    query = query || queryParams;
    try {
      
        response = await new ActiveZoneListingService().getActiveZoneListings({
          ...query,
        });

      const responseData = response.data.data;

      setShowLoader(false);
      setActiveListingData(responseData);
      setActiveListingDataFetchError(null);
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "getActiveZoneListingsData",
      });
      setShowLoader(false);
      setActiveListingDataFetchError({ ...error, refreshAction: getActiveZoneListingsData });
    }
  };
 

  useEffect(() => {
    queryParams && getActiveZoneListingsData(queryParams);
  }, [queryParams]);

  const setPageSize = () => {
    if (queryParams) {
      return queryParams.page_size;
    }
    return 1;
  }

  return (
    <React.Fragment>
      {activeListingDataFetchError ? (
        <CustomError {...activeListingDataFetchError} />
      ) : (
        <Content className="mainContainer">
          <div className="innerHeader">
            <Row className="flx justifyContentBetween alignItemsCenter">
              <Col span={14}>
                <AppliedFilters appliedFilters={appliedFilterValues} />
              </Col>
              <Col span={10}>
                {!showLoader && activeListingData && activeListingData.count > 0 && (
                  <ul className="innerHeaderRight">
                    <li>
                      <h4>
                        {formatThousandSeparator(activeListingData.count)}
                        {formatReportCount(activeListingData.count)}
                      </h4>
                    </li>
                    <li>
                      <Pagination
                        className="pagination"
                        hideOnSinglePage={true}
                        current={queryParams?.page_index}
                        total={activeListingData.count}
                        pageSize={setPageSize()}
                        onChange={(pageNumber) =>
                          setQueryParams({
                            ...queryParams,
                            page_index: pageNumber,
                          })
                        }
                        showSizeChanger={false}
                      />
                    </li>
                  </ul>
                )}
                {!(!showLoader && activeListingData && activeListingData.count > 0) &&
                  <div style={{ height: 21 }}></div>
                }
              </Col>
            </Row>
          </div>
          <div className="removeTblLRSpc">
            <CommonReportTable
              onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                setQueryParams({
                  ...queryParams,
                  sort_column: sortIndex,
                  sort_order: sortOrder,
                });
              }}
              sortField={{
                dataIndex: queryParams?.sort_column,
                order: queryParams?.sort_order,
              }}
              headers={ActiveZoneListingTableHeaders}
              data={activeListingData?.activeZoneListingData ?? null}
              tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
              loading={showLoader}
              otherData={activeListingData?.zoneAvailabilityMasterData}
            />
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ActiveZoneListingReportTable;
