import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import NeedToBuy from './NeedToBuy';
import NeedToBuyDollar from './NeedToBuyDollar';
import NeedToBuyIn from './NeedToBuyIn';
import Issues from './Issues';

const PurchaseQueue = () => {
    return (
        <Layout className="mainInnerLayout dashboard">
            <Content className="mainContainer">
                <div className="dashboardCardWrap">
                    <h3>Purchase Queue</h3>
                    <Row className="removeRowBtmSpc">
                        <NeedToBuy/>
                        <NeedToBuyDollar/>
                        <NeedToBuyIn/>
                        <Issues/>
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default PurchaseQueue